import type { RunbookResource } from "@octopusdeploy/octopus-server-client";
import type { UnknownQueryParam, PageRouteDefinition, QueryParamValues, QueryParamValuesSetter } from "@octopusdeploy/portal-routes";
import type { ReactElement } from "react";
import type { RunbookContextProps } from "~/areas/projects/components/Runbooks/RunbookContext";
import type { ProjectContextProps } from "~/areas/projects/context/index";
import type { Loader } from "~/routing/pageRegistrations/Loader";
import type { LoadingContentRender, PageRegistration } from "~/routing/pageRegistrations/PageRegistration";
import type { StandardLoaderContext } from "~/routing/pageRegistrations/StandardLoaderContext";
import type { StandardPageContext } from "~/routing/pageRegistrations/StandardPageContext";
import type { PageIdentity } from "./PageIdentity";
export function createLevel1ProjectPageRegistration<RouteParams, QueryParams extends UnknownQueryParam[], LoaderData>(route: PageRouteDefinition<RouteParams, QueryParams>, config: {
    render: (parameters: {
        context: StandardPageContext;
        loaderData: LoaderData;
        routeParams: RouteParams;
        queryParams: QueryParamValues<QueryParams>;
        setQueryParams: QueryParamValuesSetter<QueryParamValues<QueryParams>>;
    }) => ReactElement;
    pageIdentity: PageIdentity;
    loader?: Loader<LoaderData, StandardLoaderContext, RouteParams, QueryParams>;
    loadingTitle?: string;
    loadingContent?: LoadingContentRender;
}): PageRegistration<RouteParams, QueryParams, LoaderData, StandardLoaderContext, StandardPageContext> {
    return {
        route,
        render: config.render,
        pageIdentity: config.pageIdentity,
        verticalNavigationPageArea: "Projects",
        searchKeywords: [],
        loader: config.loader,
        loadingTitle: config.loadingTitle,
        loadingContent: config.loadingContent,
        level: 1,
    };
}
export function createLevel2ProjectPageRegistration<RouteParams, QueryParams extends UnknownQueryParam[], LoaderData>(route: PageRouteDefinition<RouteParams, QueryParams>, config: {
    render: (parameters: {
        context: ProjectPageContext;
        loaderData: LoaderData;
        routeParams: RouteParams;
        queryParams: QueryParamValues<QueryParams>;
        setQueryParams: QueryParamValuesSetter<QueryParamValues<QueryParams>>;
    }) => ReactElement;
    pageIdentity: PageIdentity;
    loader?: Loader<LoaderData, ProjectPageLoaderContext, RouteParams, QueryParams>;
    loadingTitle?: string;
    loadingContent?: LoadingContentRender;
}): PageRegistration<RouteParams, QueryParams, LoaderData, ProjectPageLoaderContext, ProjectPageContext> {
    return {
        route,
        render: config.render,
        pageIdentity: config.pageIdentity,
        verticalNavigationPageArea: "Projects",
        searchKeywords: [],
        loader: config.loader,
        loadingTitle: config.loadingTitle,
        loadingContent: config.loadingContent,
        level: 2,
    };
}
export function createLevel2ProjectRunbookPageRegistration<RouteParams, QueryParams extends UnknownQueryParam[], LoaderData>(route: PageRouteDefinition<RouteParams, QueryParams>, config: {
    render: (parameters: {
        context: ProjectPageContext;
        loaderData: LoaderData;
        routeParams: RouteParams;
        queryParams: QueryParamValues<QueryParams>;
        setQueryParams: QueryParamValuesSetter<QueryParamValues<QueryParams>>;
    }) => ReactElement;
    pageIdentity: PageIdentity;
    loader?: Loader<LoaderData, ProjectRunbookPageLoaderContext, RouteParams, QueryParams>;
    loadingTitle?: string;
    loadingContent?: LoadingContentRender;
}): PageRegistration<RouteParams, QueryParams, LoaderData, ProjectRunbookPageLoaderContext, ProjectRunbookPageContext> {
    return {
        route,
        render: config.render,
        pageIdentity: config.pageIdentity,
        verticalNavigationPageArea: "Projects",
        searchKeywords: [],
        loader: config.loader,
        loadingTitle: config.loadingTitle,
        loadingContent: config.loadingContent,
        level: 2,
    };
}
export interface ProjectPageContext extends StandardPageContext {
    projectContext: ProjectContextProps;
    setShowK8sStatusItem: (showTargetStatusItemBeforeProcess: boolean) => void;
}
export interface ProjectPageLoaderContext extends StandardLoaderContext {
    projectContext: Promise<ProjectContextProps>;
}
export interface ProjectRunbookPageLoaderContext extends ProjectPageLoaderContext {
    runbookContext: Promise<RunbookContextProps & {
        state: {
            runbook: RunbookResource;
        };
    }>;
}
export interface ProjectRunbookPageContext extends ProjectPageContext {
    runbookContext: RunbookContextProps & {
        state: {
            runbook: RunbookResource;
        };
    };
}
