/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import VariableCellIcon, { CellIcons } from "~/areas/variables/VariableCellIcon";
import { repository } from "~/clientInstance";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import { withTheme } from "~/components/Theme";
import type { CertificateIndex } from "~/components/certificates";
import styles from "./style.module.less";
interface ReadonlyCertificateProps {
    certificateIndex?: CertificateIndex;
    certificateId: string;
    certificateName?: string;
    className?: string;
}
// This component is supposed to be styled the same as a <Text /> component.
// This idea being that if they were side by side and the text component was not focused and did not have an underline,
// then they would look identical
const ReadonlyCertificate: React.SFC<ReadonlyCertificateProps> = (props) => withTheme((theme) => {
    const certName = props.certificateName ? props.certificateName : props.certificateIndex && props.certificateIndex[props.certificateId] ? props.certificateIndex[props.certificateId] : props.certificateId;
    return (<div className={styles.container}>
                <VariableCellIcon type={CellIcons.certificate}/>
                <div className={`${props.className} ${styles.content}`} title={certName}>
                    <InternalLink to={links.editCertificatePage.generateUrl({ spaceId: repository.spaceId!, certificateId: props.certificateId })}>{certName}</InternalLink>
                </div>
            </div>);
});
ReadonlyCertificate.displayName = "ReadonlyCertificate"
export default ReadonlyCertificate;
