import { css } from "@emotion/css";
import { IconButton } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { ServiceAccountOidcIdentityResource } from "@octopusdeploy/octopus-server-client/src/repositories/serviceAccountOidcIdentitiesRepository";
import * as React from "react";
import { useEffect, useState } from "react";
import { useGetFieldError } from "~/components/ErrorContext/ErrorContext";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Note, Select, Text, and, required, startsWith, url } from "~/components/form";
import type { DropdownMenuOption } from "~/primitiveComponents/form/Select/DropDownMenu";
import { GitHubActionsIssuer } from "./getIssuerType";
import { parseGitHubActionsSubject } from "./parseGitHubActionsSubject";
type ConfigureGitHubActionsIssuerTypeOidcIdentityProps = {
    oidcIdentity: ServiceAccountOidcIdentityResource;
    setOidcIdentity: (oidcIdentity: ServiceAccountOidcIdentityResource) => void;
};
export type GitHubActionsFilterType = "environment" | "pull_request" | "branch" | "tag";
const gitHubActionsFilterTypes: GitHubActionsFilterType[] = ["branch", "environment", "pull_request", "tag"];
export type GitHubActionsSubjectParseResult = {
    organization: string;
    repository: string;
    filterType: GitHubActionsFilterType;
    filterValue?: string;
};
export function ConfigureGitHubActionsOidcIdentity({ oidcIdentity, setOidcIdentity }: ConfigureGitHubActionsIssuerTypeOidcIdentityProps) {
    const parsedSubject = parseGitHubActionsSubject(oidcIdentity.Subject);
    const [organization, setOrganization] = useState<string>(parsedSubject?.organization ?? "");
    const [repository, setRepository] = useState<string>(parsedSubject?.repository ?? "");
    const [filterType, setFilterType] = useState<GitHubActionsFilterType | undefined>(parsedSubject?.filterType ?? undefined);
    const [filterValue, setFilterValue] = useState<string>(parsedSubject?.filterValue ?? "");
    const [useCustomIssuer, setUseCustomIssuer] = useState<boolean>(oidcIdentity.Issuer !== undefined && oidcIdentity.Issuer !== "" && oidcIdentity.Issuer !== GitHubActionsIssuer);
    const [useCustomSubject, setUseCustomSubject] = useState<boolean>(oidcIdentity.Subject !== undefined && oidcIdentity.Subject !== "" && parseGitHubActionsSubject(oidcIdentity.Subject) === undefined);
    const getFieldError = useGetFieldError();
    useEffect(() => {
        if (!oidcIdentity.Issuer || (!useCustomIssuer && oidcIdentity.Issuer !== GitHubActionsIssuer)) {
            setOidcIdentity({
                ...oidcIdentity,
                Issuer: GitHubActionsIssuer,
            });
        }
    }, [oidcIdentity, setOidcIdentity, useCustomIssuer]);
    useEffect(() => {
        if (useCustomSubject)
            return;
        let subject = "";
        if (filterType !== undefined) {
            let filter = "";
            if (filterType === "branch") {
                if (filterValue)
                    filter = `ref:refs/heads/${filterValue}`;
            }
            else if (filterType === "environment") {
                if (filterValue)
                    filter = `environment:${filterValue}`;
            }
            else if (filterType === "pull_request")
                filter = `pull_request`;
            else if (filterType === "tag") {
                if (filterValue)
                    filter = `ref:refs/tags/${filterValue}`;
            }
            if (organization && repository && filter)
                subject = `repo:${organization}/${repository}:${filter}`;
        }
        if (oidcIdentity.Subject !== subject) {
            setOidcIdentity({
                ...oidcIdentity,
                Subject: subject,
            });
        }
    }, [organization, repository, filterType, filterValue, oidcIdentity, setOidcIdentity, useCustomSubject]);
    function onFilterTypeChange(newValue: string | undefined) {
        setFilterValue("");
        if (newValue === undefined || newValue === "")
            setFilterType(undefined);
        else if (newValue === "environment")
            setFilterType("environment");
        else if (newValue === "pull_request")
            setFilterType("pull_request");
        else if (newValue === "branch")
            setFilterType("branch");
        else if (newValue === "tag")
            setFilterType("tag");
        else
            throw new Error("Unknown filter type");
    }
    function getFilterTypeDescription(filterType?: GitHubActionsFilterType): string {
        if (filterType === undefined)
            return "Select a filter";
        else if (filterType === "branch")
            return "Branch";
        else if (filterType === "environment")
            return "Environment";
        else if (filterType === "pull_request")
            return "Pull Requests";
        else if (filterType === "tag")
            return "Tag";
        else
            throw new Error("Unknown filter type");
    }
    function getFilterTypeHelpText(filterType?: GitHubActionsFilterType): string {
        if (filterType === undefined)
            return "Select a filter";
        else if (filterType === "branch")
            return "Only GitHub Actions workflow runs for this branch will be able to connect using this OIDC identity.";
        else if (filterType === "environment")
            return "Only GitHub Actions workflow runs using this environment will be able to connect using this OIDC identity.";
        else if (filterType === "pull_request")
            return "Only GitHub Actions workflow runs triggered from pull requests will be able to connect using this OIDC identity.";
        else if (filterType === "tag")
            return "Only GitHub Actions workflow runs for this tag will be able to connect using this OIDC identity.";
        else
            throw new Error("Unknown filter type");
    }
    function getSubjectDescription() {
        if (useCustomSubject)
            return (<>
                    The subject of the OIDC identity. This must match the subject provided in the GitHub Actions OIDC token and can use '*' or '?' wildcard characters. Click the cancel icon to generate the subject from the GitHub repository details
                    instead.
                </>);
        if (filterType === "pull_request") {
            return (<>
                    GitHub Actions workflow runs triggered from pull requests in the{" "}
                    {<code>
                            {organization}/{repository}
                        </code>}{" "}
                    repository will be able to connect using this OIDC identity. Click the edit icon to change this if you are customizing the subject claims for your Organization or Repository.
                </>);
        }
        else if (filterType === "environment" && filterValue) {
            {
                return (<>
                        GitHub Actions workflow runs for the {<code>{filterValue}</code>} environment in the{" "}
                        {<code>
                                {organization}/{repository}
                            </code>}{" "}
                        repository will be able to connect using this OIDC identity. Click the edit icon to change this if you are customizing the subject claims for your Organization or Repository.
                    </>);
            }
        }
        else if (filterValue) {
            return (<>
                    GitHub Actions workflow runs for the {<code>{filterValue}</code>} {filterType} in the{" "}
                    {<code>
                            {organization}/{repository}
                        </code>}{" "}
                    repository will be able to connect using this OIDC identity. Click the edit icon to change this if you are customizing the subject claims for your Organization or Repository.
                </>);
        }
        else {
            return (<>
                    Configure the organization, repository and filter for the GitHub Actions connection to generate the subject. Wildcard characters '*' and '?' can be used. Click the edit icon to change this if you are customizing the subject claims
                    for your Organization or Repository.
                </>);
        }
    }
    function filterTypeRequiresValue(filterType?: GitHubActionsFilterType): boolean {
        if (filterType === undefined || filterType === "pull_request")
            return false;
        return true;
    }
    function selectionRenderer(_: string, menuItem: DropdownMenuOption) {
        return <div>{menuItem.text}</div>;
    }
    const styles = {
        container: css({
            display: "flex",
            flexDirection: "column",
            gap: space[8],
        }),
        generatedProperties: css({
            display: "flex",
            flexDirection: "column",
            gap: space[16],
        }),
        generatedProperty: css({
            display: "flex",
            gap: space[8],
        }),
        generatedPropertyIcon: css({
            marginTop: space[16],
        }),
    };
    return (<div className={styles.container}>
            <div>
                <Text key="Name" label="Name" value={oidcIdentity.Name} onChange={(name) => setOidcIdentity({
            ...oidcIdentity,
            Name: name,
        })} validate={required("Name is required")} error={getFieldError("Name")} autoFocus helperText="A unique name for the OIDC identity within this service account."/>
                <div className={styles.generatedProperty}>
                    <Text key="Issuer" label="Issuer" value={oidcIdentity.Issuer} onChange={(issuer) => {
            setOidcIdentity({
                ...oidcIdentity,
                Issuer: issuer,
            });
        }} disabled={!useCustomIssuer} autoFocus={useCustomIssuer} validate={and([required("Issuer is required"), url("Issuer must be a valid url"), startsWith("Issuer must be a valid url using HTTPS", "https")])} error={getFieldError("Issuer")} helperText={useCustomIssuer
            ? "The URL where GitHub Action tokens are issued from. Click the cancel icon to use the issuer URL for GitHub Actions in GitHub Cloud."
            : "The URL where GitHub Action tokens are issued from. Click the edit icon to set the URL if you are using GitHub Actions in GitHub Enterprise."}/>
                    <div className={styles.generatedPropertyIcon}>
                        <IconButton accessibleName="GitHubActionsUseCustomIssuer" icon={useCustomIssuer ? "XmarkIcon" : "PenLineIcon"} onClick={() => setUseCustomIssuer(!useCustomIssuer)}/>
                    </div>
                </div>
                <Text key="Organization" label="Organization" value={organization} onChange={(organization) => {
            setOrganization(organization);
        }} disabled={useCustomSubject} validate={required("Organization is required")} error={getFieldError("Organization")} helperText="The GitHub organization of the repository to connect from."/>
                <Text key="Repository" label="Repository" value={repository} onChange={(repository) => {
            setRepository(repository);
        }} disabled={useCustomSubject} validate={required("Repository is required")} error={getFieldError("Repository")} helperText="The GitHub repository to connect from."/>
                <Select fieldName="Filter" label="Filter" allowClear={true} items={gitHubActionsFilterTypes.map((s) => ({ text: getFilterTypeDescription(s), value: s }))} value={filterType} onChange={onFilterTypeChange} disabled={useCustomSubject} placeholder="Select a filter" validate={required("Filter is required")} sortItems={false} selectionRenderer={selectionRenderer} helperText="The filter for the OIDC connection."/>
                {filterTypeRequiresValue(filterType) && (<Text key="Filter Value" label={getFilterTypeDescription(filterType)} value={filterValue} disabled={useCustomSubject} onChange={(filterValue) => setFilterValue(filterValue)} validate={required(`${getFilterTypeDescription(filterType)} is required`)} error={getFieldError("Filter Value")} helperText={getFilterTypeHelpText(filterType)}/>)}
                <div className={styles.generatedProperty}>
                    <Text key="Subject" label="Subject" value={oidcIdentity.Subject} onChange={(subject) => {
            setOidcIdentity({
                ...oidcIdentity,
                Subject: subject,
            });
        }} disabled={!useCustomSubject} validate={required("Subject is required")} error={getFieldError("Subject")} helperText={getSubjectDescription()}/>
                    <div className={styles.generatedPropertyIcon}>
                        <IconButton accessibleName="GitHubActionsUseCustomSubject" icon={useCustomSubject ? "XmarkIcon" : "PenLineIcon"} onClick={() => setUseCustomSubject(!useCustomSubject)}/>
                    </div>
                </div>
            </div>
            <div>
                <Note>
                    <ExternalLink href="ServiceAccountOidcIdentitiesGitHubActions">Learn more about using OpenID Connect in GitHub Actions with Octopus.</ExternalLink>
                </Note>
            </div>
        </div>);
}
