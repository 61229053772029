import { css } from "@emotion/css";
import MaterialIconButton from "@material-ui/core/IconButton";
import type { IconProps } from "@octopusdeploy/design-system-icons";
import { CaretDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon, ChevronDownIcon, CaretUpIcon, CopyIcon, ExpandIcon, PenLineIcon, PlusIcon, XmarkIcon, ImageIcon, CompressIcon, QuestionCircleIcon, EllipsisVerticalIcon, ArrowUpFromBracketIcon, ArrowRefreshIcon, MagnifyingGlassIcon, EyeOnIcon, EyeOffIcon, LinkOnIcon, LinkOffIcon, ChevronsLeftIcon, CurlyBracketsHashtagIcon, ArrowDownToSquareIcon, ShareIcon, CogIcon, TrashIcon, } from "@octopusdeploy/design-system-icons";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import type { AriaAttributes, ComponentType } from "react";
import React, { useImperativeHandle, useRef } from "react";
export interface IconButtonElement {
    focus: () => void;
}
// New design system icons
export const IconButtonIcons = [
    "ArrowRefreshIcon",
    "ArrowDownToSquareIcon",
    "ArrowUpFromBracketIcon",
    "CaretDownIcon",
    "ChevronLeftIcon",
    "ChevronRightIcon",
    "ChevronUpIcon",
    "ChevronDownIcon",
    "CaretUpIcon",
    "ChevronsLeftIcon",
    "CogIcon",
    "CompressIcon",
    "CopyIcon",
    "CurlyBracketsHashtagIcon",
    "EllipsisVerticalIcon",
    "ExpandIcon",
    "EyeOffIcon",
    "EyeOnIcon",
    "ImageIcon",
    "LinkOnIcon",
    "LinkOffIcon",
    "MagnifyingGlassIcon",
    "PenLineIcon",
    "PlusIcon",
    "QuestionCircleIcon",
    "ShareIcon",
    "TrashIcon",
    "XmarkIcon",
] as const;
export type IconButtonIconType = (typeof IconButtonIcons)[number];
const Icon: Record<IconButtonIconType, ComponentType<IconProps>> = {
    ArrowRefreshIcon,
    ArrowDownToSquareIcon,
    ArrowUpFromBracketIcon,
    CaretDownIcon,
    CaretUpIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronUpIcon,
    ChevronDownIcon,
    ChevronsLeftIcon,
    CogIcon,
    CompressIcon,
    CopyIcon,
    CurlyBracketsHashtagIcon,
    EllipsisVerticalIcon,
    ExpandIcon,
    EyeOffIcon,
    EyeOnIcon,
    ImageIcon,
    LinkOnIcon,
    LinkOffIcon,
    MagnifyingGlassIcon,
    PenLineIcon,
    PlusIcon,
    QuestionCircleIcon,
    ShareIcon,
    TrashIcon,
    XmarkIcon,
};
interface SharedButtonProps extends SupportedAriaAttributes {
    /**
     * The classname to apply to the icon button.
     *
     * @deprecated Please avoid using this. If you need to add margin / other layout concerns
     * use a wrapping element instead. This property will be removed in the near future.
     */
    className?: string;
    /**
     * The style to apply to the icon button.
     *
     * @deprecated Please avoid using this. If you need to add margin / other layout concerns
     * use a wrapping element instead. This property will be removed in the near future.
     */
    style?: React.CSSProperties;
    /**
     * A property to control whether the icon button is enabled.
     */
    disabled?: boolean;
    /**
     * A property passed to MUI to control ripple effects.
     */
    disableRipple?: boolean;
    /**
     * The action to perform when the icon button is clicked.
     */
    onClick?: (event: React.MouseEvent) => void;
    /**
     * The accessible name to use for the icon button
     *
     * All icon buttons should have an accessible name as it simply consists of an icon and no label for the accessibility tree.
     * If a predefined icon is used and no accessible name is provided, then the icon name is used for the accessible name.
     */
    accessibleName?: string;
    /**
     * The tabIndex for the IconButton
     *
     * Navigating to the button using Tab can be disabled by providing a value of -1. Any other value for tabIndex should be
     * avoided if possible.
     */
    tabIndex?: number;
}
interface SupportedAriaAttributes {
    /**
     * Indicates whether the icon button is currently expanded or collapsed.
     */
    "aria-expanded"?: AriaAttributes["aria-expanded"];
    /**
     * Identifies the element (or elements) whose contents or presence are controlled by
     * this icon button.
     */
    "aria-controls"?: AriaAttributes["aria-controls"];
    /**
     * Indicates the availability and type of interactive poup element, such as menu or dialog,
     * that can be triggered by this icon button.
     */
    "aria-haspopup"?: AriaAttributes["aria-haspopup"];
}
interface CustomButtonIconProps extends SharedButtonProps {
    /**
     * A custom react element to use for the icon.
     *
     * A custom react elements need to ensure they set the appropriate color
     * for the icon. For all standard icons that we provide, this isn't necessary,
     * however, if you have a custom svg you will need the fill to be set to
     * `currentColor` for the icon to use the correct color.
     *
     * @example
     * <IconButton customIcon={<MyCustomIcon />} onClick={clickHandler} />
     */
    customIcon: React.ReactElement;
}
interface PredefinedButtonIconProps extends SharedButtonProps {
    /**
     * The predefined icon to use as part of the icon button.
     *
     * @example
     * <IconButton icon={"Cancel"} onClick={clickHandler} />
     */
    icon: IconButtonIconType;
}
export type IconButtonProps = CustomButtonIconProps | PredefinedButtonIconProps;
export const IconButton = React.forwardRef<IconButtonElement, IconButtonProps>((props, ref) => {
    const accessibleName = !props.accessibleName && isPredefinedButtonIconProps(props) ? props.icon : props.accessibleName;
    const icon = isPredefinedButtonIconProps(props) ? <PredefinedIcon icon={props.icon}/> : props.customIcon;
    return (<StyledMaterialIconButton icon={icon} accessibleName={accessibleName} className={props.className} ref={ref} style={props.style} onClick={props.onClick} disabled={props.disabled} disableRipple={props.disableRipple} tabIndex={props.tabIndex} aria-expanded={props["aria-expanded"]} aria-controls={props["aria-controls"]} aria-haspopup={props["aria-haspopup"]}/>);
});
IconButton.displayName = "IconButton";
function isPredefinedButtonIconProps(props: IconButtonProps): props is PredefinedButtonIconProps {
    return "icon" in props && isPredefinedIcon(props.icon);
}
export function isPredefinedIcon(icon: string): icon is IconButtonIconType {
    return icon in Icon;
}
function PredefinedIcon({ icon }: {
    icon: IconButtonIconType;
}) {
    const ResolvedIcon = Icon[icon];
    return <ResolvedIcon size={24}/>;
}
PredefinedIcon.displayName = "PredefinedIcon";
interface StyledMaterialIconButtonProps extends SharedButtonProps {
    icon: React.ReactElement | string;
}
const StyledMaterialIconButton = React.forwardRef<IconButtonElement, StyledMaterialIconButtonProps>(({ disabled, tabIndex, icon, onClick, accessibleName, disableRipple, style, className, "aria-expanded": ariaExpanded, "aria-controls": ariaControls, "aria-haspopup": ariaHasPopup, children }, ref) => {
    const iconButtonRef = useRef<HTMLButtonElement | null>(null);
    useImperativeHandle(ref, () => ({
        focus: () => iconButtonRef.current?.focus(),
    }));
    return (<MaterialIconButton className={className} classes={{ root: iconButtonRootStyles }} disabled={disabled} disableRipple={disableRipple} tabIndex={tabIndex} onClick={onClick} ref={iconButtonRef} style={style} aria-label={accessibleName} aria-expanded={ariaExpanded} aria-controls={ariaControls} aria-haspopup={ariaHasPopup}>
                {icon}
            </MaterialIconButton>);
});
StyledMaterialIconButton.displayName = "StyledMaterialIconButton";
const iconButtonClasses = {
    root: "MuiIconButton-root",
};
const iconButtonRootStyles = css({
    [`&.${iconButtonClasses.root}`]: {
        padding: 0,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        display: "flex",
        color: themeTokens.color.button.icon.secondary,
    },
});
export default IconButton;
