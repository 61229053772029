import type { WorkerPoolResource } from "@octopusdeploy/octopus-server-client";
import { WorkerPoolType } from "@octopusdeploy/octopus-server-client";
export function isCloudWorkerPools(workerPools: WorkerPoolResource[]) {
    // If and only if there are exactly two worker pools: "Hosted Windows" and "Hosted Ubuntu"
    return (workerPools.length === 2 &&
        workerPools.some((pool) => pool.Name === "Hosted Windows" && pool.WorkerPoolType === WorkerPoolType.Dynamic) &&
        workerPools.some((pool) => pool.Name === "Hosted Ubuntu" && pool.WorkerPoolType === WorkerPoolType.Dynamic));
}
export interface CloudWorkerPoolsMetadata {
    OperatingSystem: "Windows" | "Linux";
    SupportedTools: KnownTools[];
}
export type CloudWorkerPool = "Hosted Windows" | "Hosted Ubuntu";
export type KnownTools = "kubectl";
export const knownCloudWorkerPools: Record<CloudWorkerPool, CloudWorkerPoolsMetadata> = {
    "Hosted Windows": {
        OperatingSystem: "Windows",
        SupportedTools: ["kubectl"],
    },
    "Hosted Ubuntu": {
        OperatingSystem: "Linux",
        SupportedTools: [],
    },
};
export function pickPreferredWorkerPoolId(requestedOS: "Windows" | "Linux" | undefined, requestedTools: KnownTools[], workerPools: WorkerPoolResource[]): string | undefined {
    if (!isCloudWorkerPools(workerPools)) {
        return undefined;
    }
    // if we can find exact match by OS and tools otherwise 🤷
    const windowsPool = workerPools.find((pool) => pool.Name === "Hosted Windows");
    const ubuntuPool = workerPools.find((pool) => pool.Name === "Hosted Ubuntu");
    if (windowsPool && (requestedOS === undefined || knownCloudWorkerPools["Hosted Windows"].OperatingSystem === requestedOS) && requestedTools.every((tool) => knownCloudWorkerPools["Hosted Windows"].SupportedTools.includes(tool))) {
        return windowsPool.Id;
    }
    if (ubuntuPool && (requestedOS === undefined || knownCloudWorkerPools["Hosted Ubuntu"].OperatingSystem === requestedOS) && requestedTools.every((tool) => knownCloudWorkerPools["Hosted Ubuntu"].SupportedTools.includes(tool))) {
        return ubuntuPool.Id;
    }
}
export function isWorkerPoolCompatible(selectedWorkerPoolId: string | null, requestedOS: "Windows" | "Linux" | undefined, requestedTools: KnownTools[], workerPools: WorkerPoolResource[]): boolean {
    if (!isCloudWorkerPools(workerPools)) {
        return true;
    }
    const selectedWorkerPool = workerPools.find((pool) => pool.Id === selectedWorkerPoolId);
    if (!selectedWorkerPool) {
        return true; // this should never happen
    }
    const workerPoolMetadata = selectedWorkerPool.Name === "Hosted Windows" ? knownCloudWorkerPools["Hosted Windows"] : knownCloudWorkerPools["Hosted Ubuntu"];
    return (!requestedOS || workerPoolMetadata.OperatingSystem === requestedOS) && requestedTools.every((tool) => workerPoolMetadata.SupportedTools.includes(tool));
}
