import { NavigationButton, NavigationButtonType } from "@octopusdeploy/design-system-components";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action as AnalyticAction } from "~/analytics/Analytics";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import ExternalVideoLink from "~/components/Navigation/ExternalLink/ExternalVideoLink";
import { PermissionCheck } from "~/components/PermissionCheck";
import OnboardingPage from "../../../../components/GettingStarted/OnboardingPage";
export const OnboardingTextForDeploymentTargets = "Deployment targets represent the servers, machines and cloud services where your software and services will be deployed.";
const OnboardingDeploymentTargets = (props: {
    spaceId: string;
    dispatchAction: AnalyticActionDispatcher;
}) => {
    return (<OnboardingPage title="Configure your deployment targets" intro={<span>{OnboardingTextForDeploymentTargets}</span>} learnMore={<>
                    <ExternalLink href="OnboardingDeploymentTargetsLearnMore">Learn more</ExternalLink>
                    <ExternalVideoLink href="OnboardingDeploymentTargetsVideo" hideVideoIcon={true}>
                        Video (5 mins)
                    </ExternalVideoLink>
                </>} actionButtons={<PermissionCheck permission={Permission.MachineCreate} tenant="*" environment="*">
                    <NavigationButton type={NavigationButtonType.Primary} label="Add Deployment Target" href={links.newDeploymentTargetPage.generateUrl({ spaceId: props.spaceId })} onClick={() => props.dispatchAction("Add Deployment Target", { resource: "Deployment Target", action: AnalyticAction.Add })}/>
                </PermissionCheck>}/>);
};
export default OnboardingDeploymentTargets;
