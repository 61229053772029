import { css } from "@emotion/css";
import * as React from "react";
import { IconButton } from "../IconButton";
import type { SimpleMenuItem } from "../MenuItems";
import { SimpleMenu } from "./SimpleMenu";
import { useMenuState } from "./useMenuState";
interface OverflowMenuProps {
    menuItems: SimpleMenuItem[];
}
export const OverflowMenu = ({ menuItems }: OverflowMenuProps) => {
    const [onOpen, menuState, buttonAriaAttributes] = useMenuState();
    return (<>
            <IconButton className={overflowButtonStyles} icon={"EllipsisVerticalIcon"} {...buttonAriaAttributes} onClick={onOpen} accessibleName={"Overflow Menu Button"}/>
            <SimpleMenu menuState={menuState} items={menuItems} accessibleName={"Overflow Menu"}/>
        </>);
};
// Adding following style to make the new OverflowMenu has same style as the OverflowMenu in Portal,
// so that we do not introduce UI change when pulling the OverflowMenu into Design System.
// The Frontend Foundation team will look into the style of OverflowMenu.
const overflowButtonStyles = css({
    height: "2.25rem",
    width: "2.25rem",
    "&.MuiButtonBase-root": {
        margin: "0 0.375rem",
    },
});
