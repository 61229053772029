/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { GitRefResource, VariableSetResource, ControlType, VariableType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { ProcessIdentifier } from "~/areas/projects/components/Process/types";
import { isBlueprintProcessIdentifier, isDeploymentOrRunbookProcessIdentifier } from "~/areas/projects/components/Process/types";
import type { LoadedLibraryVariableSets } from "~/areas/projects/components/Variables/AllVariables/AllVariables";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip";
import { AddVariableIconButton } from "~/components/form/AddVariableIconButton";
import { withBoundField } from "~/components/form/BoundField/BoundField";
import type FormFieldProps from "~/components/form/FormFieldProps";
import Select from "~/primitiveComponents/form/Select/Select";
import { collateVariableNames } from "~/utils/LoadProjectVariables/loadProjectVariables";
import IconButtonList from "../../IconButtonList/IconButtonList";
import InputWithActions from "../../InputWithActions";
export interface BaseVariableSelectProps extends FormFieldProps<string> {
    gitRef: GitRefResource | undefined;
    allowClear?: boolean;
    disabled?: boolean;
    label?: string | JSX.Element;
    error?: string;
    warning?: string;
    processIdentifier: ProcessIdentifier;
    processVariableSet: VariableSetResource;
    libraryVariableSets: LoadedLibraryVariableSets[];
    refreshVariables: (() => Promise<void>) | undefined;
}
interface VariableSelectProps extends BaseVariableSelectProps {
    variableTypes: VariableType[];
    controlTypes: ControlType[];
}
export function VariableSelect(props: VariableSelectProps) {
    const handleChange = (variable: any) => {
        const value = variable === "" ? null : variable;
        if (props.onChange) {
            props.onChange(value);
        }
    };
    const names = collateVariableNames(props.processVariableSet, props.libraryVariableSets, [], props.variableTypes, props.controlTypes);
    const buttons = [];
    if (props.refreshVariables) {
        buttons.push(<IconButtonWithTooltip accessibleName="Refresh variables" toolTipContent={"Refresh"} icon={"ArrowRefreshIcon"} onClick={props.refreshVariables}/>);
    }
    if (isDeploymentOrRunbookProcessIdentifier(props.processIdentifier)) {
        buttons.push(<AddVariableIconButton projectSlugOrId={props.processIdentifier.projectId} spaceId={props.processIdentifier.spaceId}/>);
    }
    else if (isBlueprintProcessIdentifier(props.processIdentifier)) {
        // TODO: Link to process template variables
    }
    return <InputWithActions input={<Select label="Select variable" {...props} allowFilter={true} error={props.error} onChange={handleChange} items={names.map((v) => ({ value: v, text: v }))}/>} actions={<IconButtonList buttons={buttons}/>}/>;
}
export const BoundVariableSelect = withBoundField(VariableSelect);
