import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { ProjectPageTitleAccessory } from "~/areas/projects/components/ProjectPageTitleAccessory";
import { useProjectContext } from "~/areas/projects/context/index";
import { LegacyPaperLayoutVNext, type LegacyPaperLayoutVNextProps } from "~/components/PaperLayout/LegacyPaperLayoutVNext";
import SidebarLayout from "~/components/SidebarLayout/SidebarLayout";
import StringHelper from "~/utils/StringHelper";
import RunbooksNavigationTabs from "../RunbooksNavigationTabs";
export type RunbooksPaperLayoutProps = LegacyPaperLayoutVNextProps & {
    sidebar?: React.ReactNode;
};
const RunbooksPaperLayout: React.FC<RunbooksPaperLayoutProps> = ({ children, sidebar, title = StringHelper.ellipsis, fullWidth = true, ...rest }) => {
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    return (<LegacyPaperLayoutVNext titleAccessory={<ProjectPageTitleAccessory />} breadcrumbsItems={rest.breadcrumbsItems ?? [{ label: "Runbooks", pageUrl: links.projectRunbooksPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }) }]} title={title} fullWidth={fullWidth} {...rest}>
            <RunbooksNavigationTabs />
            <SidebarLayout sideBar={sidebar}>{children}</SidebarLayout>
        </LegacyPaperLayoutVNext>);
};
RunbooksPaperLayout.displayName = "RunbooksPaperLayout"
export default RunbooksPaperLayout;
export { RunbooksPaperLayout };
