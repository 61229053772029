import type { PageAction, PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { ResourceCollection, TagSetResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { sortBy } from "lodash";
import * as React from "react";
import useIsMultiTenancyEnabledFeatureFlag from "~/areas/configuration/hooks/useIsMultiTenancyEnabledFeatureFlag";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import Dialog from "~/components/Dialog/Dialog";
import { useDialogTrigger, type DialogControls } from "~/components/Dialog/DialogTrigger";
import MarkdownDescription from "~/components/MarkdownDescription";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { PageContent } from "~/components/PageContent/PageContent";
import { PagingList } from "~/components/PagingList/PagingList";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import Tag from "~/components/Tag/index";
import { withTheme } from "~/components/Theme";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import { timeOperation, timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import Onboarding from "./Onboarding";
import TagSetsSorter from "./TagSetsSorter";
import styles from "./style.module.less";
interface TagSetsPageProps {
    spaceId: string;
}
interface TagSetsPageInternalProps extends TagSetsPageProps {
    isMultiTenancyEnabled: boolean;
    sortDialogControls: DialogControls;
}
interface TagSetsPageState extends DataBaseComponentState {
    tagSetsResponse?: ResourceCollection<TagSetResource>;
}
class TagSetsResourceList extends PagingList<TagSetResource> {
}
class TagSetsPageInternal extends DataBaseComponent<TagSetsPageInternalProps, TagSetsPageState> {
    constructor(props: TagSetsPageInternalProps) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        await timeOperation(timeOperationOptions.forInitialLoad(), () => this.loadData());
    }
    renderOnBoarding() {
        return <Onboarding />;
    }
    renderTagSets() {
        return (<div>
                <TagSetsResourceList initialData={this.state.tagSetsResponse} onRow={(tagSet) => this.buildTagSetsRow(tagSet)} onRowRedirectUrl={(tagSet) => links.editTagSetPage.generateUrl({ spaceId: this.props.spaceId, tagSetId: tagSet.Id })} onFilter={this.filterTagSets} filterSearchEnabled={true} apiSearchParams={["partialName"]} filterHintText="Filter by name..."/>
            </div>);
    }
    render() {
        if (!this.props.isMultiTenancyEnabled) {
            return <InternalRedirect to={links.tenantsPage.generateUrl({ spaceId: this.props.spaceId })}/>;
        }
        const addTagSetPageAction: PrimaryPageAction = {
            type: "navigate",
            hasPermissions: isAllowed({ permission: Permission.TagSetCreate }),
            label: "Add Tag Set",
            path: links.createTagSetPage.generateUrl({ spaceId: this.props.spaceId }),
        };
        return (<PageContent header={{ title: "Tenant Tag Sets", primaryAction: addTagSetPageAction, pageActions: this.getPageActions() }} busy={this.state.busy} errors={this.errors}>
                {this.renderDialogs()}
                {!this.state.tagSetsResponse ? <div /> : this.state.tagSetsResponse.TotalResults > 0 ? this.renderTagSets() : this.renderOnBoarding()}
            </PageContent>);
    }
    private buildTagSetsRow(tagSet: TagSetResource) {
        return (<div>
                <ListTitle>{tagSet.Name}</ListTitle>
                <div className={styles.helpText}>
                    <MarkdownDescription markup={tagSet.Description}/>
                </div>
                <div>{sortBy(tagSet.Tags, (t) => t.SortOrder).map((t) => withTheme((theme) => <Tag tagName={t.Name} tagColor={t.Color} key={t.Id} description={t.Description} borderColor={theme.divider}/>))}</div>
            </div>);
    }
    private filterTagSets(filter: string, resource: TagSetResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }
    private getPageActions = (): PageAction[] => {
        if (!this.hasTagSets()) {
            return [];
        }
        const sortDialogPageAction: PageAction = {
            type: "button",
            buttonType: "secondary",
            hasPermissions: isAllowed({ permission: Permission.TagSetCreate }),
            label: "Reorder",
            onClick: this.props.sortDialogControls.openDialog,
        };
        return [sortDialogPageAction];
    };
    private renderDialogs = () => {
        if (!this.hasTagSets()) {
            return null;
        }
        return (<Dialog open={this.props.sortDialogControls.isOpen}>
                <TagSetsSorter onSaved={this.loadData}/>
            </Dialog>);
    };
    private hasTagSets = () => {
        return this.state.tagSetsResponse !== undefined && this.state.tagSetsResponse.Items.length > 0;
    };
    private loadData = async () => {
        await this.doBusyTask(async () => {
            this.setState({ tagSetsResponse: await repository.TagSets.list() });
        });
    };
    static displayName = "TagSetsPageInternal";
}
export function TagSetsPage({ spaceId }: TagSetsPageProps) {
    const isMultiTenancyEnabled = useIsMultiTenancyEnabledFeatureFlag();
    const sortDialogControls = useDialogTrigger();
    return <TagSetsPageInternal spaceId={spaceId} isMultiTenancyEnabled={isMultiTenancyEnabled} sortDialogControls={sortDialogControls}/>;
}
