import { FingerprintIcon } from "@octopusdeploy/design-system-icons";
import * as React from "react";
import CopyToClipboard from "~/components/CopyToClipboardButton/CopyToClipboardButton";
import styles from "./style.module.less";
interface ServerThumbprintProps {
    thumbprint: string;
}
class ServerThumbprint extends React.PureComponent<ServerThumbprintProps> {
    render() {
        return (<span className={styles.container}>
                <FingerprintIcon size={24}/>
                <span className={styles.thumbprint}>{this.props.thumbprint}</span>
                <CopyToClipboard value={this.props.thumbprint}/>
            </span>);
    }
    static displayName = "ServerThumbprint";
}
export default ServerThumbprint;
