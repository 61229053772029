import type { ActionProperties, DeploymentActionResource } from "@octopusdeploy/octopus-server-client";
import convertPropertyValueResourceToString from "~/components/convertPropertyValueResourceToString";
export function getProcessTemplateCommitFromAction(action: DeploymentActionResource) {
    return getProcessTemplateCommitFromActionProperties(action.Properties);
}
export function getProcessTemplateSlugFromAction(action: DeploymentActionResource) {
    return getProcessTemplateSlugFromActionProperties(action.Properties);
}
export function getProcessTemplateIdFromAction(action: DeploymentActionResource, uriEncode: boolean = false) {
    return getProcessTemplateIdFromActionProperties(action.Properties, uriEncode);
}
export function getProcessTemplateCommitFromActionProperties(actionProperties: ActionProperties) {
    return convertPropertyValueResourceToString(actionProperties["Octopus.Action.ProcessTemplate.Reference.Commit"]);
}
export function getProcessTemplateSlugFromActionProperties(actionProperties: ActionProperties) {
    return convertPropertyValueResourceToString(actionProperties["Octopus.Action.ProcessTemplate.Reference.Slug"]);
}
export function getProcessTemplateVersionFromActionProperties(actionProperties: ActionProperties) {
    return convertPropertyValueResourceToString(actionProperties["Octopus.Action.ProcessTemplate.Reference.Version"]);
}
export function getProcessTemplateIdFromActionProperties(actionProperties: ActionProperties, uriEncode: boolean = false) {
    const selectedGitRef = convertPropertyValueResourceToString(actionProperties["Octopus.Action.ProcessTemplate.Reference.Commit"]);
    const selectedSlug = convertPropertyValueResourceToString(actionProperties["Octopus.Action.ProcessTemplate.Reference.Slug"]);
    if (selectedGitRef && selectedSlug) {
        return uriEncode ? encodeURIComponent(`${selectedGitRef}|${selectedSlug}`) : `${selectedGitRef}|${selectedSlug}`;
    }
    else {
        return undefined;
    }
}
