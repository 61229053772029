/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { css } from "@emotion/css";
import { RadioButton } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { FeedResource } from "@octopusdeploy/octopus-server-client";
import { PackageAcquisitionLocation } from "@octopusdeploy/octopus-server-client";
import * as _ from "lodash";
import * as React from "react";
import { isRunOnBuiltInWorker, isRunOnDeploymentTarget } from "~/areas/projects/components/Process/Common/CommonProcessHelpers";
import type { RunOn } from "~/areas/projects/components/Process/types";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import Note from "~/primitiveComponents/form/Note/Note";
import { BoundRadioButtonGroup } from "~/primitiveComponents/form/RadioButton/BoundRadioButtonGroup";
import isBound from "../form/BoundField/isBound";
interface PackageDownloadOptionsProps {
    packageAcquisitionLocation: string;
    feed: FeedResource | undefined;
    runOn?: RunOn;
    showNotAcquiredOption?: boolean;
    projectId: string | undefined;
    localNames: string[] | undefined;
    onPackageAcquisitionLocationChanged(packageAcquisitionLocation: string): void;
}
export default class PackageDownloadOptions extends BaseComponent<PackageDownloadOptionsProps, {}> {
    private static radioButtons(locations: PackageAcquisitionLocation[], defaultLocation: PackageAcquisitionLocation): JSX.Element[] {
        const elements = [];
        if (locations.includes(PackageAcquisitionLocation.Server)) {
            elements.push(<RadioButton value={PackageAcquisitionLocation.Server} label="Octopus Server will download the package, then securely upload it to the Tentacles" key={PackageAcquisitionLocation.Server} isDefault={defaultLocation === PackageAcquisitionLocation.Server}/>);
        }
        if (locations.includes(PackageAcquisitionLocation.ExecutionTarget)) {
            elements.push(<RadioButton value={PackageAcquisitionLocation.ExecutionTarget} label="The package will be downloaded directly from the feed on the execution target" key={PackageAcquisitionLocation.ExecutionTarget} isDefault={defaultLocation === PackageAcquisitionLocation.ExecutionTarget}/>);
            elements.push(<Note key={PackageAcquisitionLocation.ExecutionTarget + "_note"}>The execution target will be either a Tentacle or SSH deployment target, or a worker in a pool.</Note>);
        }
        if (locations.includes(PackageAcquisitionLocation.NotAcquired)) {
            elements.push(<RadioButton value={PackageAcquisitionLocation.NotAcquired} label="The package won't be downloaded" key={PackageAcquisitionLocation.NotAcquired} isDefault={defaultLocation === PackageAcquisitionLocation.NotAcquired}/>);
            elements.push(<Note key={PackageAcquisitionLocation.NotAcquired + "_note"}>
                    Package metadata variables will still be available (e.g. <em>Octopus.Action.Package.PackageId</em>)
                </Note>);
        }
        return elements;
    }
    componentDidMount() {
        this.ensureAppropriateDefaultForFeed(this.props.feed!);
    }
    UNSAFE_componentWillReceiveProps(nextProps: PackageDownloadOptionsProps) {
        if (!_.isEqual(this.props.feed, nextProps.feed)) {
            this.ensureAppropriateDefaultForFeed(nextProps.feed!);
        }
    }
    render() {
        const feed = this.props.feed; // This may be null (for example if a variable expression is used for the feed)
        // Determine which acquisition location options to display (first will be the default)
        const locations = this.acquisitionLocations(feed);
        // If there are not at least possible acquisition locations, then there is no selectin' to be done
        if (locations.length < 2) {
            return null;
        }
        // The first supported location is considered the default
        const defaultLocation = locations[0];
        return (<div className={styles.radioButtonGroup}>
                <BoundRadioButtonGroup variableLookup={{
                localNames: this.props.localNames,
            }} value={this.props.packageAcquisitionLocation} resetValue={this.props.packageAcquisitionLocation} onChange={(val) => this.props.onPackageAcquisitionLocationChanged(val)}>
                    {PackageDownloadOptions.radioButtons(locations, defaultLocation)}
                </BoundRadioButtonGroup>
            </div>);
    }
    // Returns available package-acquisition locations, with the default as the first
    private acquisitionLocations(feed: FeedResource | undefined) {
        // If a feed has been selected, we will start with the acquisition locations it supports.
        // Otherwise we have to assume all options are potentially valid
        let locationsSupportedByFeed = feed?.PackageAcquisitionLocationOptions ?? Object.keys(PackageAcquisitionLocation);
        if (this.props.showNotAcquiredOption) {
            // Ensure not acquired is in the list *once*
            locationsSupportedByFeed = _.uniq([...locationsSupportedByFeed, PackageAcquisitionLocation.NotAcquired]);
        }
        return _.chain(locationsSupportedByFeed)
            .filter((location) => {
            switch (location) {
                case PackageAcquisitionLocation.Server:
                    return true;
                case PackageAcquisitionLocation.ExecutionTarget:
                    // If we know the action will run on the server, then we won't show `ExecutionTarget`
                    return !this.actionWillRunOnServer();
                case PackageAcquisitionLocation.NotAcquired:
                    // The parent component can decide whether ot not to show `NotAcquired`
                    return !!this.props.showNotAcquiredOption;
                default:
                    return false;
            }
        })
            .map((location) => location as PackageAcquisitionLocation)
            .value();
    }
    // Ensure we don't have incompatible feed and acquisition-location selected
    private ensureAppropriateDefaultForFeed(feed: FeedResource) {
        // If we don't have a feed, then there's nothing we can do.
        if (!feed) {
            return;
        }
        // If they have bound the acquisition-location to a variable-expression, then they're also on their own
        if (isBound(this.props.packageAcquisitionLocation, false)) {
            return;
        }
        const availableLocations = this.acquisitionLocations(feed).map((x) => x as string);
        // If the selected location isn't a valid option, then select the default
        if (availableLocations.length > 0 && !availableLocations.includes(this.props.packageAcquisitionLocation)) {
            this.props.onPackageAcquisitionLocationChanged(availableLocations[0]);
        }
    }
    private actionWillRunOnServer() {
        const runOn = this.props.runOn;
        if (!runOn || isRunOnDeploymentTarget(runOn)) {
            return false;
        }
        return isRunOnBuiltInWorker(runOn);
    }
    static displayName = "PackageDownloadOptions";
}
const styles = {
    radioButtonGroup: css({
        "& div:nth-child(2):has(.MuiButtonBase-root.MuiIconButton-root)": {
            marginTop: space["16"],
            marginBottom: space["16"],
            alignItems: "flex-start",
        },
    }),
};
