import { cx } from "@emotion/css";
import MaterialDrawer from "@material-ui/core/Drawer";
import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import * as React from "react";
import { ActionButton, ActionButtonType } from "../Button";
import { Divider } from "../Divider";
import { drawerActionListStyles, drawerContentStyles, drawerContainerStyles, drawerHeaderStyles, drawerTitleStyles, drawerSubtitleTitleStyles, wideContainerStyles, narrowContainerStyles } from "./drawerStyles";
interface DrawerPropsCommon {
    /**
     * The content of the drawer.
     */
    children: ReactNode;
    /**
     * If drawer should be visible.
     *
     * Controls open state of the drawer. When this is false the drawer is not displayed.
     */
    isOpen: boolean;
    /**
     * onClose callback.
     *
     * Callback when the drawer is closed via button click or another interaction (for example, pressing the Esc key will close the drawer).
     */
    onClose: () => void;
    /**
     * The title of the drawer.
     */
    title: string;
    /**
     * The subtitle of the drawer
     */
    subtitle?: string;
    /**
     * The layout.
     *
     * Controls if the drawer should have a prepared layout area or leave the content area completely unstyled.
     */
    layout?: "basic" | "none";
    /**
     * Controls whether the drawer appears in a wide (max: 975px) or narrow (max: 730px) configuration
     */
    width?: "wide" | "narrow";
}
export interface DrawerWithCustomActionsProps extends DrawerPropsCommon {
    /**
     * The drawer variant.
     *
     * The customActions variant should be used when the default actions (Cancel and Ok) are not appropriate, and you want to replace all buttons with your own custom actions.
     */
    variant: "customActions";
    /**
     * The custom header actions.
     *
     * Replaces the default actions (Cancel and Ok) with custom actions. When using this prop all primary/secondary button props will be ignored and replaced with customActions.
     */
    customActions?: React.ReactNode[];
}
export interface DrawerWithPrimaryButtonProps extends DrawerPropsCommon {
    /**
     * The drawer variant.
     *
     * The default variant renders customizable primary "Ok" and secondary "Cancel" buttons but doesn't let you add any more extra actions to the header.
     */
    variant: "default";
    /**
     * The primary button action.
     *
     * The callback to trigger when the primary button ("Ok" by default) is clicked.
     */
    onPrimaryButtonClick: () => void;
    /**
     * The label of the primary button.
     *
     * A custom label for the primary button ("Ok" by default).
     */
    primaryButtonLabel?: string;
    /**
     * Disables the primary button.
     *
     * If true, the primary button will be disabled and not clickable.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    isPrimaryButtonDisabled?: Promise<any> | boolean | null;
    /**
     * The custom primary action.
     *
     * ReactNode that replaces the default primary button.
     */
    customPrimaryAction?: React.ReactNode;
}
/** Props used for the {@link Drawer} component */
export type DrawerProps = DrawerWithCustomActionsProps | DrawerWithPrimaryButtonProps;
type DrawerPropsInternal = DrawerPropsCommon & {
    actions: ReactNode[];
};
export const Drawer = ({ isOpen, onClose, title, subtitle, children, width, ...restOfProps }: DrawerProps) => {
    let actions: ReactNode[] = [];
    if (restOfProps.variant === "default") {
        const { onPrimaryButtonClick, primaryButtonLabel, isPrimaryButtonDisabled, customPrimaryAction } = restOfProps;
        actions = [
            <ActionButton key={`Cancel ${title}`} type={ActionButtonType.Secondary} label="Cancel" accessibleName={`Cancel ${title}`} onClick={onClose}/>,
            customPrimaryAction ?? <ActionButton key={`Confirm ${title}`} type={ActionButtonType.Primary} label={primaryButtonLabel ?? "Ok"} accessibleName={`Confirm ${title}`} onClick={onPrimaryButtonClick} disabled={isPrimaryButtonDisabled}/>,
        ];
    }
    else if (restOfProps.variant === "customActions") {
        actions = restOfProps.customActions ?? [];
    }
    return (<DrawerInternal isOpen={isOpen} title={title} subtitle={subtitle} actions={actions} onClose={onClose} width={width ?? "wide"} {...restOfProps}>
            {children}
        </DrawerInternal>);
};
const DrawerInternal = ({ isOpen, title, subtitle, onClose, children, actions, layout = "basic", width }: DrawerPropsInternal) => {
    // This is a hack to forcefully render the drawer as closed first and then open it on the second render to fix animation issues, see https://github.com/mui/material-ui/issues/10587#issuecomment-1557705860
    const [open, setOpen] = useState(false);
    useEffect(() => setOpen(isOpen), [isOpen]);
    return (<MaterialDrawer classes={{ paper: cx(drawerContainerStyles, width === "wide" ? wideContainerStyles : narrowContainerStyles) }} anchor="right" open={open} onClose={onClose} role="dialog">
            <header className={drawerHeaderStyles}>
                <h2 className={drawerTitleStyles}>{title}</h2>
                {subtitle && <span className={drawerSubtitleTitleStyles}>{subtitle}</span>}
                <div className={drawerActionListStyles}>{...actions}</div>
            </header>
            {layout === "basic" && <Divider />}
            <section className={drawerContentStyles}>{children}</section>
        </MaterialDrawer>);
};
