import { Checkbox } from "@octopusdeploy/design-system-components";
import { useState } from "react";
import * as React from "react";
import MaxParallelism from "~/areas/projects/components/Process/Common/MaxParallelism";
import { RollingDeploymentContextualHelp } from "~/components/ContextualHelp/ContextualHelpSnippets";
import { ExpandableFormSection, Summary } from "~/components/form/index";
export type TargetRolesFormSectionProps = {
    expandedByDefault: boolean;
    maxParallelism: string;
    onMaxParallelismChanged: (max: string) => void;
};
export const getRollingDeploymentSummary = (showWindowSize: boolean, maxParallelism: string) => {
    if (showWindowSize) {
        return Summary.summary(<span>
                This step will run on{" "}
                <strong>
                    {maxParallelism} deployment target{maxParallelism === "1" ? "" : "s"}
                </strong>{" "}
                at once
            </span>);
    }
    else {
        return Summary.default("This step doesn\u2019t use a rolling deployment");
    }
};
export const RollingDeploymentExpander = (props: TargetRolesFormSectionProps) => {
    const { expandedByDefault, maxParallelism, onMaxParallelismChanged } = props;
    const [showWindowSize, setShowWindowSize] = useState<boolean>(maxParallelism ? maxParallelism.length > 0 : false);
    // used to make sure the rolling deployment section is expanded when it's shown (irrespective of expandedByDefault)
    const [togglingWindowSize, setTogglingWindowSize] = useState(false);
    return (<ExpandableFormSection isExpandedByDefault={expandedByDefault || togglingWindowSize} summary={getRollingDeploymentSummary(showWindowSize, maxParallelism)} title="Rolling Deployment" errorKey="Octopus.Action.RollingDeployment" help="Configure a rolling deployment to control the number of deployment targets this step can deploy to at once." contextualHelp={<RollingDeploymentContextualHelp />}>
            <Checkbox value={showWindowSize} onChange={() => {
            if (showWindowSize) {
                setShowWindowSize(false);
                onMaxParallelismChanged("");
            }
            else {
                setShowWindowSize(true);
                setTogglingWindowSize(true);
                onMaxParallelismChanged("1");
            }
        }} label="Configure a rolling deployment"/>
            {showWindowSize && <MaxParallelism key="maxparallelism" value={maxParallelism} onChange={(x) => onMaxParallelismChanged(x)}/>}
        </ExpandableFormSection>);
};
