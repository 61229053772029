/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import type { ColumnMeasurements } from "~/primitiveComponents/dataDisplay/ScrollTable/DraggableResizeColumnHandle/DraggableResizeColumnHandle";
import { DraggableResizeColumnHandle } from "~/primitiveComponents/dataDisplay/ScrollTable/DraggableResizeColumnHandle/DraggableResizeColumnHandle";
import styles from "./style.module.less";
interface AlignedScrollTableRowProps {
    cells: JSX.Element[];
    relativeColumnWidthsInPercent: ReadonlyArray<number>;
    minimumColumnWidthsInPx: ReadonlyArray<number>;
    showResizeHandles: boolean;
    onColumnWidthsChanged(newColumnWidths: ReadonlyArray<number>): void;
}
interface AlignedScrollTableRowState {
    isHovered: boolean;
}
class AlignedScrollTableRow extends React.Component<AlignedScrollTableRowProps, AlignedScrollTableRowState> {
    private readonly columnSizeElements: {
        [index: number]: HTMLElement | null;
    } = {};
    constructor(props: AlignedScrollTableRowProps) {
        super(props);
        this.state = {
            isHovered: false,
        };
    }
    render() {
        return (<div className={styles.alignedCellsWrapper} onMouseEnter={() => this.setState({ isHovered: this.props.showResizeHandles })} onMouseLeave={() => this.setState({ isHovered: false })}>
                {this.props.cells.map((c, index) => {
                return (<div style={asPercentWidth(this.props.relativeColumnWidthsInPercent[index])} key={index} className={styles.cellWrapper} ref={(el) => {
                        if (this.props.showResizeHandles) {
                            this.columnSizeElements[index] = el;
                        }
                    }}>
                            {this.props.showResizeHandles && index > 0 && <DraggableResizeColumnHandle columnIndex={index} isHovered={this.state.isHovered} getColumnMeasurements={() => this.getMeasurements(index)}/>}
                            <div className={styles.cellContentWrapper}>{c}</div>
                        </div>);
            })}
            </div>);
    }
    private getMeasurements = (index: number): ColumnMeasurements => {
        const rightColumnIndex = index;
        const leftColumnIndex = index - 1;
        return {
            rightWidth: this.columnSizeElements[rightColumnIndex]!.offsetWidth,
            leftWidth: this.columnSizeElements[leftColumnIndex]!.offsetWidth,
            rightMinimumWidth: this.props.minimumColumnWidthsInPx[rightColumnIndex],
            leftMinimumWidth: this.props.minimumColumnWidthsInPx[leftColumnIndex],
        };
    };
    static displayName = "AlignedScrollTableRow";
}
export function adjustColumnWidths(index: number, deltaX: number, initialColumnMeasurements: ColumnMeasurements, originalColumnWidths: ReadonlyArray<number>) {
    const leftColumnIndex = index - 1;
    const rightColumnIndex = index;
    const newColumnWidths = [...originalColumnWidths];
    newColumnWidths[leftColumnIndex] = getNewColumnWidthInPercent(originalColumnWidths[leftColumnIndex], initialColumnMeasurements.leftWidth, deltaX);
    newColumnWidths[rightColumnIndex] = getNewColumnWidthInPercent(originalColumnWidths[rightColumnIndex], initialColumnMeasurements.rightWidth, -deltaX);
    return newColumnWidths;
    function getNewColumnWidthInPercent(originalColumnWidthInPercent: number, originalColumnWidth: number, changeInColumnWidth: number) {
        const newColumnWidth = originalColumnWidth + changeInColumnWidth;
        return (newColumnWidth / originalColumnWidth) * originalColumnWidthInPercent;
    }
}
function asPercentWidth(percent: number) {
    return { width: `${percent}%` };
}
export default AlignedScrollTableRow;
