/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Moment } from "moment";
import type { CommitDetail, WorkItemLink } from "./packageResource";
import type { ResourceWithId } from "./resource";
// TODO: Add concrete Links
export interface DeploymentResource extends IExecutionResource {
    ReleaseId: string;
    DeploymentProcessId: string;
    ChannelId: string;
    ForcePackageRedeployment: boolean;
}
export interface CreateDeploymentFreezeOverrideCommand {
    CreateDeploymentCommand: DeploymentResource;
    Reason: string;
    FreezeIds: string[];
}
export interface CreateDeploymentFreezeOverrideResponse {
    Deployment: DeploymentResource;
}
export interface BulkRequestedDeployment {
    EnvironmentId: string;
    TenantId?: string;
    SpecificMachineIds: string[];
    ExcludedMachineIds: string[];
    OverrideDeploymentFreezeIds: string[];
    OverrideReason?: string;
}
export interface CreateBulkDeploymentBffCommand {
    ReleaseId: string;
    ProjectId: string;
    ForcePackageDownload: boolean;
    ForcePackageRedeployment: boolean;
    SkipActions: string[];
    UseGuidedFailure: boolean;
    FormValues: any;
    QueueTime?: Moment;
    QueueTimeExpiry?: Moment;
    RequestedDeployments: BulkRequestedDeployment[];
}
export interface CreateBulkDeploymentBffResponse {
    TaskId: string;
}
export interface IExecutionResource extends ResourceWithId {
    EnvironmentId: string;
    TenantId?: string | null;
    ForcePackageDownload: boolean;
    SkipActions: string[];
    SpecificMachineIds: string[];
    ExcludedMachineIds: string[];
    UseGuidedFailure: boolean;
    FormValues: any;
    QueueTime?: Moment;
    QueueTimeExpiry?: Moment;
    ManifestVariableSetId: string;
    TaskId: string;
    ProjectId: string;
    Comments: string;
    Name: string;
    Created: string;
    Changes: ReleaseChanges[];
    ChangesMarkdown: string;
    ExecutionPlanLogContext: ExecutionPlanLogContext | null;
}
export interface ExecutionPlanLogContext {
    Steps: StepLogContext[];
}
export interface StepLogContext {
    Slug: string;
    CorrelationId: string;
}
export enum ChangeRequestSettingsType {
    ServiceNow = "ServiceNow",
    JiraServiceManagement = "JiraServiceManagement"
}
export interface JiraServiceManagementChangeRequestSettings {
    Type: ChangeRequestSettingsType.JiraServiceManagement;
    IssueNumber?: string;
}
export interface ServiceNowChangeRequestSettings {
    Type: ChangeRequestSettingsType.ServiceNow;
    ChangeRequestNumber?: string;
    IsEmergencyChange?: boolean;
}
export type ChangeRequestSettings = JiraServiceManagementChangeRequestSettings | ServiceNowChangeRequestSettings;
export interface CreateDeploymentResourceWithChangeRequestSettings extends CreateDeploymentResource {
    ChangeRequestSettings: ChangeRequestSettings[];
}
export interface DeploymentResourceWithChangeRequestSettings extends DeploymentResource {
    ChangeRequestSettings: ChangeRequestSettings[];
}
export interface CreateDeploymentResourceWithOverrides extends CreateDeploymentResource {
    OverrideDeploymentFreezeIds: string[];
    OverrideReason?: string;
}
export interface CreateDeploymentResource extends CreateDeploymentBaseResource {
    ReleaseId: string;
}
export interface CreateDeploymentBaseResource {
    EnvironmentId: string;
    ProjectId: string;
    TenantId?: string;
    ForcePackageDownload: boolean;
    ForcePackageRedeployment: boolean;
    Priority: PriorityMode;
    SkipActions: string[];
    SpecificMachineIds: string[];
    ExcludedMachineIds: string[];
    UseGuidedFailure: boolean;
    FormValues: any;
    QueueTime?: Moment;
    QueueTimeExpiry?: Moment;
}
export enum PriorityMode {
    LifecycleDefault = "LifecycleDefault",
    On = "On",
    Off = "Off"
}
export interface ReleaseChanges {
    Version: string;
    ReleaseNotes: string | null;
    WorkItems: WorkItemLink[];
    Commits: CommitDetail[];
    BuildInformation: ReleasePackageVersionBuildInformation[];
}
export interface ReleasePackageVersionBuildInformation {
    PackageId: string;
    Version: string;
    BuildEnvironment: string;
    BuildNumber: string;
    BuildUrl: string;
    Branch: string;
    VcsType: string;
    VcsRoot: string;
    VcsCommitNumber: string;
    WorkItems: WorkItemLink[];
    Commits: CommitDetail[];
}
export enum DeploymentMode {
    Untenanted = "Untenanted",
    Tenanted = "Tenanted"
}
