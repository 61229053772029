/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionButton, Checkbox } from "@octopusdeploy/design-system-components";
import type { ChannelResource, ChannelVersionRuleResource, DeploymentActionPackageResource, DeploymentActionResource, DeploymentProcessRepository, GitRefResource, LifecycleResource, ProjectResource, Repository, ValidateGitRefV2Response, } from "@octopusdeploy/octopus-server-client";
import { deploymentActionPackages, displayName, HasGitPersistenceSettings, isGitCommit, isGitTag, OctopusError, TenantedDeploymentMode } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as _ from "lodash";
import * as React from "react";
import { useCallback, useMemo } from "react";
import DesignRule from "~/areas/projects/components/Channels/DesignRule";
import { useTrackSaveChannelEvent } from "~/areas/projects/components/Channels/SaveChannelEvent";
import { type ProjectContextProps } from "~/areas/projects/context";
import { AdvancedTenantTagsSelector } from "~/components/AdvancedTenantSelector/AdvancedTenantSelector";
import { useLegacyDoBusyTask } from "~/components/DataBaseComponent/useLegacyDoBusyTask";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import { Feature, FeatureToggle } from "~/components/FeatureToggle";
import Markdown from "~/components/Markdown";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import type { ConvertedOverflowMenuItems } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import { PageContent } from "~/components/PageContent/PageContent";
import RemovableExpandersList from "~/components/RemovableExpandersList";
import TagsList from "~/components/TagsList/TagsList";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import WarningPanel from "~/components/WarningPanel/WarningPanel";
import { ExpandableFormSection, FormSectionHeading, Note, Summary, Text } from "~/components/form";
import { Form, useForm } from "~/components/form/Form/Form";
import MarkdownEditor from "~/components/form/MarkdownEditor/MarkdownEditor";
import { required } from "~/components/form/Validators";
import * as tenantTagsets from "~/components/tenantTagsets";
import type { TagIndex } from "~/components/tenantTagsets";
import Select from "~/primitiveComponents/form/Select/Select";
import NameSummaryWithSlug from "~/primitiveComponents/form/Slugs/NameSummaryWithSlug";
import SlugEditor from "~/primitiveComponents/form/Slugs/SlugEditor";
import DeploymentActionPackageMultiSelect, { DeploymentActionPackageReferenceDataItem } from "../../../../components/MultiSelect/DeploymentActionPackageMultiSelect";
import InternalLink from "../../../../components/Navigation/InternalLink/InternalLink";
import { ChannelGitProtectionRulesEdit } from "./ChannelGitProtectionRulesEdit";
import { getDeploymentActionGitDependenciesWithDetails } from "./GitProtectionRules/getDeploymentActionGitDependenciesWithDetails";
import styles from "./style.module.less";
export async function channelLookupDataLoader(repository: Repository, projectContext: Promise<ProjectContextProps>): Promise<ChannelLookupData> {
    const lifecycles = repository.Lifecycles.all();
    const tagIndex = tenantTagsets.getTagIndex();
    const { state: { model: project, projectContextRepository, gitRefValidationError }, } = await projectContext;
    const deploymentActions = getDeploymentActionsWithErrorHandlingForGitProjects(project, projectContextRepository.DeploymentProcesses, gitRefValidationError);
    return {
        deploymentActions: await deploymentActions,
        lifecycles: await lifecycles,
        tagIndex: await tagIndex,
    };
}
export interface ChannelLookupData {
    deploymentActions: DeploymentActionResource[] | OctopusError;
    lifecycles: LifecycleResource[];
    tagIndex: TagIndex;
}
type CreateOrEditChannelProps = {
    project: ProjectResource;
    gitRefValidationError: ValidateGitRefV2Response | undefined;
    gitRef: Readonly<GitRefResource> | undefined;
    lookupData: ChannelLookupData;
    channel: ChannelResource | "create new channel";
    pageTitle?: string;
    overflowMenuItems: ConvertedOverflowMenuItems;
};
export function CreateOrEditChannelPageContent({ channel, project, gitRefValidationError, gitRef, lookupData: { deploymentActions, lifecycles, tagIndex }, pageTitle, overflowMenuItems }: CreateOrEditChannelProps) {
    const { status, doBusyTask } = useLegacyDoBusyTask();
    const { navigate } = useSpaceAwareNavigation();
    const isCreatingNewChannel = channel === "create new channel";
    const initialModel = channel === "create new channel"
        ? ({
            Id: null!,
            ProjectId: project.Id,
            SpaceId: "",
            Name: "",
            Description: "",
            IsDefault: false,
            LifecycleId: null!,
            Rules: [],
            TenantTags: [],
            Links: null!,
            GitReferenceRules: [],
            GitResourceRules: [],
        } satisfies ChannelResource)
        : channel;
    const trackSaveChannelEvent = useTrackSaveChannelEvent();
    const { model, setModel, formProps, createSaveAction, getFieldError } = useForm({
        formName: "Save channel",
        initialModel,
        snackbarSaveText: isCreatingNewChannel ? "Channel created" : "Channel details updated",
        onSubmit: async (repository: Repository, channelModel) => await repository.Channels.saveToProject(project, channelModel),
        onSubmitError: (error) => trackSaveChannelEvent(project.Id, error),
        afterSubmit: async () => {
            trackSaveChannelEvent(project.Id);
            if (isCreatingNewChannel) {
                navigate(links.channelsPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }));
            }
        },
    });
    const title = pageTitle ?? model.Name;
    const deploymentActionError = deploymentActions instanceof OctopusError ? deploymentActions : undefined;
    const lifecycleOptions: LifecycleResource[] = useMemo(() => {
        const sortedLifecycles = _.sortBy(lifecycles, (l) => l.Name);
        return [
            {
                Phases: [],
                Name: "Inherit from project",
                Id: inheritFromProjectLifecycleId,
                SpaceId: project.SpaceId,
                ReleaseRetentionPolicy: null!,
                TentacleRetentionPolicy: null!,
                Links: null!,
            },
            ...sortedLifecycles,
        ];
    }, [project.SpaceId, lifecycles]);
    const deploymentActionsOrEmpty = deploymentActions instanceof OctopusError ? [] : deploymentActions;
    const packageActions = deploymentActionPackages(deploymentActionsOrEmpty);
    const gitDependencyActions = getDeploymentActionGitDependenciesWithDetails(deploymentActionsOrEmpty);
    const updateRuleProperty = useCallback(<K extends keyof ChannelVersionRuleResource>(versionRuleIndex: number, propertyName: K, value: ChannelVersionRuleResource[K]) => setModel((prev) => ({
        ...prev,
        Rules: prev.Rules.map((rule, i) => {
            if (i === versionRuleIndex) {
                return {
                    ...rule,
                    [propertyName]: value,
                };
            }
            return rule;
        }),
    })), [setModel]);
    const updateRule = useCallback((index: number, rule: ChannelVersionRuleResource) => setModel((prev) => ({
        ...prev,
        Rules: prev.Rules.map((existingRule, i) => {
            if (i === index) {
                return rule;
            }
            return existingRule;
        }),
    })), [setModel]);
    const handleVersionRuleDeleteByIndex = useCallback((index: number) => setModel((prev) => ({
        ...prev,
        Rules: prev.Rules.filter((_, i) => i !== index),
    })), [setModel]);
    return (<PageContent header={{
            title,
            primaryAction: createSaveAction({ saveButtonLabel: "Save", saveButtonBusyLabel: "Saving" }),
            breadcrumbs: [{ label: "Channels", pageUrl: links.channelsPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }) }],
            overflowActions: overflowMenuItems.menuItems,
        }} legacyStatus={status}>
            {overflowMenuItems.dialogs}
            <Form {...formProps} expandAllOnMount={isCreatingNewChannel}>
                <TransitionAnimation>
                    <ExpandableFormSection errorKey="Name" title="Name" focusOnExpandAll summary={model.Name ? Summary.summary(<NameSummaryWithSlug name={model.Name} slug={model.Slug}/>) : Summary.placeholder("Please enter a name for your channel")} help="Enter a name for your channel.">
                        <Text value={model.Name || ""} onChange={(Name) => setModel((prev) => ({ ...prev, Name }))} label="Channel name" validate={required("Please enter a channel name")} error={getFieldError("Name")} autoFocus={true}/>
                        <Note>
                            A short, memorable, unique name for this channel. Example: <em>1.x Normal, 2.x Beta</em>
                        </Note>

                        {!isCreatingNewChannel && (<SlugEditor value={model.Slug || ""} name={model.Name} originalSlug={channel.Slug ?? ""} onChange={(Slug) => setModel((prev) => ({ ...prev, Slug }))} label="Channel slug" validate={required("Please enter a channel slug")} error={getFieldError("Slug")}/>)}
                    </ExpandableFormSection>
                    <ExpandableFormSection errorKey="description" title="Description" summary={descriptionSummary(model)} help="Enter a description for your channel.">
                        <MarkdownEditor value={model.Description} label="Channel description" onChange={(Description) => setModel((prev) => ({ ...prev, Description }))}/>
                    </ExpandableFormSection>
                    <ExpandableFormSection errorKey="lifecycle" title="Lifecycle" summary={lifecycleSummary(model, lifecycleOptions)} help="Select a lifecycle for your channel.">
                        <Select value={model.LifecycleId || inheritFromProjectLifecycleId} onChange={(lifecycleId) => setModel((prev) => ({
            ...prev,
            LifecycleId: lifecycleId === inheritFromProjectLifecycleId ? null! : lifecycleId!,
        }))} items={lifecycleOptions.map((pg) => ({ value: pg.Id, text: pg.Name }))} label="Lifecycle" sortItems={false}/>
                        <Note>
                            The lifecycle defines how releases can be promoted between environments. Lifecycles can be defined <InternalLink to={links.lifecyclesPage.generateUrl({ spaceId: project.SpaceId })}>here</InternalLink>. If no lifecycle is
                            selected, the default lifecycle for the project will be used.
                        </Note>
                    </ExpandableFormSection>
                    <ExpandableFormSection errorKey="defaultChannel" title="Is Default Channel" summary={model.IsDefault
            ? Summary.summary(<span>
                                          <strong>This channel</strong> will be chosen by default when creating releases
                                      </span>)
            : Summary.summary(<span>
                                          <strong>A different channel</strong> will be chosen by default when creating releases
                                      </span>)} help="This channel will be selected by default when creating releases.">
                        <Checkbox label="Default channel" value={model.IsDefault} onChange={(IsDefault) => setModel((prev) => ({ ...prev, IsDefault }))}/>
                    </ExpandableFormSection>

                    <FormSectionHeading title="Package Version Rules"/>
                    {gitRefValidationError && (<WarningPanel title={`An error has occurred showing steps that use packages in the deployment process: ${gitRefValidationError.Message}. Please select a different branch or tag from the deployment process screen to view steps.`} warnings={[]}/>)}
                    {deploymentActionError && <WarningPanel title={`An error has occurred showing steps that use packages in the deployment process: ${deploymentActionError.ErrorMessage}`} warnings={deploymentActionError.Errors ?? []}/>}
                    <RemovableExpandersList helpElement={<div>
                                Use package version rules to restrict which versions of packages can be used on steps when creating releases in this channel.
                                <Note>
                                    Learn about <ExternalLink href={"ChannelVersionRules"}>channel version rules</ExternalLink>.
                                </Note>
                            </div>} typeDisplayName="Package Version Rule" data={model.Rules} listActions={[
            <ActionButton key="AddVersion" label="Add rule" onClick={() => setModel((prev) => ({
                    ...prev,
                    Rules: [
                        ...prev.Rules,
                        {
                            Id: null!,
                            Tag: "",
                            VersionRange: "",
                            ActionPackages: [],
                            Links: null!,
                        },
                    ],
                }))}/>,
        ]} onRow={(item: ChannelVersionRuleResource, index: number) => {
            return (<VersionRule model={model} packageActions={packageActions} deploymentActions={deploymentActions} item={item} versionRuleIndex={index} hasLoadedSteps={deploymentActionError === undefined} project={project} gitRef={gitRef} gitRefValidationError={gitRefValidationError} updateRuleProperty={updateRuleProperty} updateRule={updateRule}/>);
        }} onRowSummary={(item: ChannelVersionRuleResource) => {
            return ruleSummary(item);
        }} onRowHelp={() => {
            return "Use package version rules to restrict which versions of packages can be used on steps when creating releases in this channel.";
        }} onRemoveRowByIndex={handleVersionRuleDeleteByIndex}/>

                    <ChannelGitProtectionRulesEdit project={project} channel={model} gitRef={gitRef} gitRefValidationError={gitRefValidationError} gitDependencyActions={gitDependencyActions} deploymentActionsError={deploymentActionError} doBusyTask={doBusyTask} onGitReferenceRulesChanged={(GitReferenceRules) => setModel((prev) => ({ ...prev, GitReferenceRules }))} onGitResourceRulesChanged={(GitResourceRules) => setModel((prev) => ({ ...prev, GitResourceRules }))}/>

                    <FeatureToggle feature={Feature.MultiTenancy}>
                        {(project.TenantedDeploymentMode !== TenantedDeploymentMode.Untenanted || model.TenantTags.length > 0) && (<React.Fragment>
                                <FormSectionHeading title="Tenants"/>
                                <ExpandableFormSection errorKey="tenantTags" title="Tenants" summary={model.TenantTags.length > 0
                ? Summary.summary(<span>
                                                      Releases in this channel can only be deployed to certain tenants:
                                                      <TagsList canonicalNames={model.TenantTags} tagIndex={tagIndex}/>
                                                  </span>)
                : Summary.default("Releases in this channel can be deployed to any tenants")} help={"Choose which tenants the releases in this channel apply to."}>
                                    <Note>
                                        Releases in this channel will only be deployed to tenants matching this filter. Clear the filter to make releases in this channel available to all tenants. Learn about{" "}
                                        <ExternalLink href={"TenantsAndChannels"}>tenants and channels</ExternalLink>.
                                    </Note>
                                    <AdvancedTenantTagsSelector emptyFilterMeansAllTenants={true} selectedTenantTags={model.TenantTags} doBusyTask={doBusyTask} onChange={(TenantTags) => setModel((prev) => ({ ...prev, TenantTags }))} showPreviewButton={true}/>
                                </ExpandableFormSection>
                            </React.Fragment>)}
                    </FeatureToggle>
                </TransitionAnimation>
            </Form>
        </PageContent>);
}
const inheritFromProjectLifecycleId = "-1";
interface VersionRuleProps {
    model: ChannelResource;
    packageActions: DeploymentActionPackageResource[];
    deploymentActions: DeploymentActionResource[] | OctopusError;
    item: ChannelVersionRuleResource;
    versionRuleIndex: number;
    hasLoadedSteps: boolean;
    project: ProjectResource;
    gitRefValidationError: ValidateGitRefV2Response | undefined;
    gitRef: Readonly<GitRefResource> | undefined;
    updateRuleProperty: <K extends keyof ChannelVersionRuleResource>(versionRuleIndex: number, propertyName: K, value: ChannelVersionRuleResource[K]) => void;
    updateRule: (index: number, rule: ChannelVersionRuleResource) => void;
}
function VersionRule({ versionRuleIndex, item, hasLoadedSteps, model, packageActions, deploymentActions, project, gitRefValidationError, gitRef, updateRule, updateRuleProperty }: VersionRuleProps) {
    const allSelectedActionPackages = model.Rules.map((r) => r.ActionPackages).reduce((a, b) => a.concat(b), []); // All of the selected packages across all version rules
    function actionIsEqual(a: DeploymentActionPackageResource, b: DeploymentActionPackageResource) {
        return a.DeploymentAction === b.DeploymentAction && (a.PackageReference || "") === (b.PackageReference || "");
    }
    // Once a package is selected it shouldn't show for any other version rules. This is the list
    // of all packages that are not currently selected in any version rules for this channel.
    const autoCompleteActionPackages = packageActions
        .filter((a) => !allSelectedActionPackages.some((b) => actionIsEqual(a, b)))
        .map((x) => new DeploymentActionPackageReferenceDataItem(x, String(packageActions.findIndex((y) => actionIsEqual(x, y)))));
    const itemSelectedActionPackages = packageActions.filter((a) => item.ActionPackages.some((b) => actionIsEqual(a, b))).map((x) => new DeploymentActionPackageReferenceDataItem(x, String(packageActions.findIndex((y) => actionIsEqual(x, y)))));
    // All of the packages that are not available in any steps. This will only be populated for
    // version controlled projects where steps might not exist on the current selected branch.
    const selectedActionPackagesNotInTheProcess = item.ActionPackages.filter((a) => !autoCompleteActionPackages.some((b) => actionIsEqual(a, b.ActionPackage)) && !itemSelectedActionPackages.some((b) => actionIsEqual(a, b.ActionPackage))).map((x) => new DeploymentActionPackageReferenceDataItem(x, displayName(x)));
    const allSelectedActionPackagesForRule = [...itemSelectedActionPackages, ...selectedActionPackagesNotInTheProcess];
    const deploymentActionsOrEmpty = deploymentActions instanceof OctopusError ? [] : deploymentActions;
    const deploymentActionError = deploymentActions instanceof OctopusError ? deploymentActions : undefined;
    return (<div>
            <DeploymentActionPackageMultiSelect disabled={!hasLoadedSteps} error={deploymentActionError === undefined ? undefined : "Error loading package steps"} items={[...autoCompleteActionPackages, ...allSelectedActionPackagesForRule]} value={allSelectedActionPackagesForRule.map((x) => x.Id)} label="Package step(s)" onChange={(actionPackageIndexes) => {
            return updateRuleProperty(versionRuleIndex, "ActionPackages", actionPackageIndexes.map((i) => {
                const actionPackageIndex = Number(i);
                if (Number.isNaN(actionPackageIndex)) {
                    // Action packages references for steps that are not in the process will have the action + package
                    // name as the identifier (Action Name/PackageName). This will only ever happen for version controlled
                    // processes. We need to keep the selection in place, or the save will wipe the rules that were set
                    // on a different branch.
                    return selectedActionPackagesNotInTheProcess.find((ap) => displayName(ap.ActionPackage) == i)!.ActionPackage;
                }
                else {
                    return packageActions[actionPackageIndex];
                }
            }));
        }} openOnFocus={false}/>
            {project.IsVersionControlled && deploymentActionError === undefined && !gitRefValidationError && renderMessage(gitRef)}
            <Text value={item.VersionRange || ""} onChange={(value) => updateRuleProperty(versionRuleIndex, "VersionRange", value)} label="Version range"/>
            <Note>
                Use the <ExternalLink href="NuGetVersioning">NuGet</ExternalLink> or <ExternalLink href="MavenVersioning">Maven</ExternalLink> versioning syntax (depending on the feed type) to specify the range of versions to include.
            </Note>
            <Text value={item.Tag || ""} onChange={(value) => updateRuleProperty(versionRuleIndex, "Tag", value)} label="Pre-release tag"/>
            <Note>
                A regular-expression which will select on the <ExternalLink href="NuGetVersioning">SemVer</ExternalLink> pre-release tag or the <ExternalLink href="MavenVersionParser"> Maven</ExternalLink> qualifier.
            </Note>
            <Note>
                Check our <ExternalLink href="ChannelVersionRuleTags">documentation</ExternalLink> for more information on tags along with examples.
            </Note>
            <div className={styles.designRuleButton}>
                <OpenDialogButton label="Design rule">
                    <DesignRule model={item} project={project} deploymentActions={deploymentActionsOrEmpty} onOkClick={(rule) => updateRule(versionRuleIndex, rule)}/>
                </OpenDialogButton>
            </div>
        </div>);
}
function ruleSummary(rule: ChannelVersionRuleResource) {
    const summarySpan = (<span>
            Applies to <strong>{rule.ActionPackages.map((pkg) => displayName(pkg)).join(", ")}</strong>
            &nbsp;with a version range matching <strong>{rule.VersionRange}</strong>
            {!!rule.Tag && (<span>
                    {" "}
                    and a pre-release tag matching <strong>{rule.Tag}</strong>
                </span>)}
        </span>);
    return Summary.summary(summarySpan);
}
function lifecycleSummary(channel: ChannelResource, lifecycles: LifecycleResource[]) {
    if (channel.LifecycleId) {
        return Summary.summary(lifecycles.find((l) => l.Id === channel.LifecycleId)!.Name);
    }
    return Summary.default("Inherited from project");
}
function renderMessage(gitRef: Readonly<GitRefResource> | undefined) {
    const gitRefCanonical = gitRef?.CanonicalName;
    let gitRefType = "branch";
    if (isGitCommit(gitRefCanonical)) {
        gitRefType = "commit";
    }
    else if (isGitTag(gitRefCanonical)) {
        gitRefType = "tag";
    }
    return (<Note>
            Showing steps from the <code>{gitRef?.Name}</code> {gitRefType}. A different {gitRefType} can be selected from the deployment process screen.
        </Note>);
}
function descriptionSummary(channel: ChannelResource) {
    return channel.Description ? Summary.summary(<Markdown markup={channel.Description}/>) : Summary.placeholder("No channel description provided");
}
async function getDeploymentActionsWithErrorHandlingForGitProjects(project: ProjectResource, repository: DeploymentProcessRepository, gitRefValidationError: ValidateGitRefV2Response | undefined): Promise<DeploymentActionResource[] | OctopusError> {
    try {
        if (gitRefValidationError) {
            return [];
        }
        else {
            const process = await repository.get();
            return _.flatMap(process.Steps, (step) => step.Actions);
        }
    }
    catch (exception) {
        if (HasGitPersistenceSettings(project.PersistenceSettings) && OctopusError.isOctopusError(exception)) {
            // We only want to catch the error if it's a Git project and an Octopus exception. Otherwise, error
            // as usual
            return exception;
        }
        else {
            throw exception;
        }
    }
}
