/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import SvgIcon from "@material-ui/core/SvgIcon";
import { XmarkCircleIcon } from "@octopusdeploy/design-system-icons";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import { logger } from "@octopusdeploy/logging";
import { ActionHandlerCategory } from "@octopusdeploy/octopus-server-client";
import type { ActionTemplateSearchResource } from "@octopusdeploy/octopus-server-client";
import classnames from "classnames";
import * as React from "react";
import Logo from "~/components/Logo/Logo";
import { withTheme } from "~/components/Theme";
import styles from "./ActionTemplateCategory.module.less";
import ActionTemplateCategoryIconHelper from "./ActionTemplateCategoryIconHelper";
interface ActionTemplateCategoryProps {
    category: ActionHandlerCategory;
    name: string;
    templates: ActionTemplateSearchResource[];
    active: boolean;
    isBlurred?: boolean;
    isSelectable?: boolean;
    onCategorySelected: () => void;
}
const ActionTemplateCategory: React.StatelessComponent<ActionTemplateCategoryProps> = (props: ActionTemplateCategoryProps) => {
    let iconUrl: string | null = null;
    let iconSvgPath: JSX.Element | null = null;
    try {
        if (props.category === ActionHandlerCategory.CommunitySubCategory) {
            iconUrl = props.templates && props.templates.length > 0 ? props.templates[0].Links.Logo : null;
        }
        else {
            // We have custom SVG icons for our primary categories (that switch fill based on active state).
            iconSvgPath = ActionTemplateCategoryIconHelper.getCategorySvgElement(props.category, props.active);
        }
    }
    catch (err) {
        logger.info("Failed to find icon for {category}. Falling back to first template's logo.", { category: props.category, categoryName: props.name });
        iconUrl = props.templates && props.templates.length > 0 ? props.templates[0].Links.Logo : null;
        iconSvgPath = null;
    }
    return withTheme((theme) => (<li className={classnames(styles.actionCategory, props.active ? styles.active : styles.inactive, props.isSelectable ? styles.isFiltering : styles.isSelectable, props.isBlurred ? styles.blurred : null)} onClick={props.isSelectable ? undefined : props.onCategorySelected}>
            {iconUrl && <Logo url={iconUrl} size="3.125rem"/>}
            {iconSvgPath && (<SvgIcon htmlColor={props.active ? theme.iconLight : theme.iconDark} viewBox="0 0 70 70" style={{ width: "3.125rem", height: "3.125rem", fill: props.active ? theme.iconLight : theme.iconDark }}>
                    {iconSvgPath}
                </SvgIcon>)}
            <div className={styles.categoryName}>
                <small>{props.name}</small>
            </div>
            {props.active && (<div className={styles.activeIcon}>
                    <XmarkCircleIcon size={24} color={themeTokens.color.icon.primary}/>
                </div>)}
        </li>));
};
ActionTemplateCategory.displayName = "ActionTemplateCategory"
ActionTemplateCategory.displayName = "ActionTemplateCategory";
export default ActionTemplateCategory;
