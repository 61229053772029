import { css } from "@emotion/css";
import { Callout, Tooltip } from "@octopusdeploy/design-system-components";
import { fontSize, fontWeight, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { KubernetesObjectStatus, KubernetesStepStatus } from "@octopusdeploy/octopus-server-client";
import { TaskState } from "@octopusdeploy/octopus-server-client";
import React, { useMemo } from "react";
import type { KubernetesDetailsFilter } from "~/areas/tasks/components/Task/Kubernetes/KubernetesDetailsLayout";
import type { KubernetesStepBaseProps } from "~/areas/tasks/components/Task/Kubernetes/KubernetesStepExpander";
import { EmptyState } from "~/areas/tasks/components/Task/Kubernetes/Snapshot/EmptyState";
import { LastUpdateMessage } from "~/areas/tasks/components/Task/Kubernetes/Snapshot/LastUpdateMessage";
import { ObjectStatus } from "~/areas/tasks/components/Task/Kubernetes/Snapshot/ObjectStatus";
import { sortObjectStatusByObjectType } from "~/areas/tasks/components/Task/Kubernetes/sortObjectStatusByObjectType";
import { Note } from "~/components/form/index";
import { DataTable, DataTableBody, DataTableHeader, DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/index";
import SectionHeading from "~/primitiveComponents/dataDisplay/Section/SectionHeading";
type KubernetesStepSnapshotStatusProps = KubernetesStepBaseProps & {
    stepResourceStatus: KubernetesStepStatus | undefined;
    showObjectDrawer: (kos: KubernetesObjectStatus) => void;
};
const styles = {
    linkStyle: css({
        cursor: "pointer",
    }),
    expandoBodyContainer: css({
        paddingLeft: space[16],
        paddingRight: space[16],
        "@media (min-width @screen-sm)": {
            overflowX: "auto",
        },
    }),
    leftRightContainer: css({
        display: "flex",
        justifyContent: "space-between",
        "> div:last-child": {
            display: "flex",
            justifyContent: "flex-end",
            gap: "10/16rem",
            width: "100%",
        },
        "> div:first-child": {
            display: "flex",
            justifyContent: "flex-start",
            gap: "10/16rem",
            width: "100%",
        },
    }),
    kindContainer: css({
        marginTop: space[16],
        marginBottom: space[16],
        borderLeftWidth: space["1"],
        borderLeftStyle: "solid",
        borderLeftColor: themeTokens.color.border.primary,
        borderRightWidth: space["1"],
        borderRightStyle: "solid",
        borderRightColor: themeTokens.color.border.primary,
        "@media (min-width: @screen-sm)": {
            minWidth: "800px",
        },
    }),
    sectionHeadingTitle: css({
        fontSize: fontSize["medium"],
        fontWeight: fontWeight["700"],
    }),
};
function applyFilter(objects: KubernetesObjectStatus[], filter: KubernetesDetailsFilter) {
    return objects.filter((o) => {
        let showTargetNameFilter = true;
        let showNamespaceFilter = true;
        let nameFilter = true;
        if (filter.targetName && filter.targetName !== o.ClusterName) {
            showTargetNameFilter = false;
        }
        if (filter.namespace && filter.namespace !== o.Namespace) {
            showNamespaceFilter = false;
        }
        if (filter.name && !o.Name.toLocaleLowerCase().includes(filter.name.toLocaleLowerCase())) {
            nameFilter = false;
        }
        return showTargetNameFilter && showNamespaceFilter && nameFilter;
    });
}
export const KubernetesStepSnapshotStatus = (props: KubernetesStepSnapshotStatusProps) => {
    const [data, totalResults, totalFilteredResults] = useMemo(() => {
        const totalResults = props.stepResourceStatus?.KubernetesObjects.length || 0;
        let totalFilteredResult = totalResults;
        if (props.stepResourceStatus) {
            //apply the filter
            const filtererResults = applyFilter(props.stepResourceStatus.KubernetesObjects, props.filter);
            totalFilteredResult = filtererResults.length;
            const dict = Object.entries(filtererResults.reduce<Record<string, KubernetesObjectStatus[]>>((prev, current) => {
                if (prev[current.Kind] === undefined) {
                    prev[current.Kind] = [];
                }
                prev[current.Kind].push(current);
                return prev;
            }, {}));
            sortObjectStatusByObjectType(dict);
            return [dict, totalResults, totalFilteredResult];
        }
        return [[], totalResults, totalFilteredResult];
    }, [props.stepResourceStatus, props.filter]);
    const renderData = (data: object) => {
        if (Array.isArray(data)) {
            return data.join(", ");
        }
        return data.toString();
    };
    const isUnsuccessfulTask = props.taskState === TaskState.Failed || props.taskState === TaskState.Canceled || props.taskState === TaskState.TimedOut;
    const showResourceStatus = !(props.isAtomicHelmStep && isUnsuccessfulTask);
    return (<div className={styles.expandoBodyContainer}>
            {props.stepResourceStatus && (<div className={styles.leftRightContainer}>
                    <div aria-label="Last Updated">
                        <Note>
                            <LastUpdateMessage activityElement={props.stepActivity} lastObjectStatusUpdate={props.stepResourceStatus.LastUpdated}/>
                        </Note>
                    </div>
                    {totalResults > totalFilteredResults && (<div aria-label="Filter Counter">
                            <Note>
                                {totalFilteredResults} of {totalResults} objects match your filters
                            </Note>
                        </div>)}
                </div>)}
            <EmptyState status={props.stepActivity.Status} isResourceStatusEnabled={props.isResourceStatusEnabled} hasData={totalResults > 0}/>
            {!showResourceStatus && (<Callout type={"warning"} title={"Object status unavailable"}>
                    The Helm <code>--atomic</code> flag on this step rolled back to a previous revision. We can’t determine the status accurately because objects from different revisions might share the same names.
                </Callout>)}
            {data.map(([kind, objects]) => (<div className={styles.kindContainer} key={kind}>
                    <SectionHeading title={<div className={styles.sectionHeadingTitle}>{kind}</div>}/>
                    <DataTable title={kind}>
                        <DataTableHeader>
                            <DataTableRow>
                                {showResourceStatus && (<DataTableHeaderColumn aria-label="Status Column" width="10px">
                                        &nbsp;
                                    </DataTableHeaderColumn>)}
                                <DataTableHeaderColumn width={"20%"}>Name</DataTableHeaderColumn>
                                {Object.keys(objects[0].Data).map((k) => (<DataTableHeaderColumn key={k} width={"10%"}>
                                        {k}
                                    </DataTableHeaderColumn>))}
                                <DataTableHeaderColumn>{/* Empty column used for auto alignment */}</DataTableHeaderColumn>
                                <DataTableHeaderColumn width={"15%"}>Target Name</DataTableHeaderColumn>
                                <DataTableHeaderColumn width={"15%"}>Namespace</DataTableHeaderColumn>
                            </DataTableRow>
                        </DataTableHeader>
                        <DataTableBody>
                            {objects.map((obj) => (<DataTableRow key={`${obj.Name}-${obj.ClusterName}=${obj.Namespace}`}>
                                    {showResourceStatus && (<DataTableRowColumn>
                                            <ObjectStatus status={obj.Status} stepStatus={props.stepActivity.Status}/>
                                        </DataTableRowColumn>)}
                                    <DataTableRowColumn>
                                        {obj.ManifestId ? (<Tooltip content="View object details">
                                                <a className={styles.linkStyle} onClick={() => props.showObjectDrawer(obj)}>
                                                    {obj.Name}
                                                </a>
                                            </Tooltip>) : (<span>{obj.Name}</span>)}
                                    </DataTableRowColumn>
                                    {Object.values(obj.Data).map((v, i) => (<DataTableRowColumn key={i}>{renderData(v)}</DataTableRowColumn>))}
                                    <DataTableRowColumn>{/* Empty column used for auto alignment */}</DataTableRowColumn>
                                    <DataTableRowColumn>{obj.ClusterName}</DataTableRowColumn>
                                    <DataTableRowColumn>{obj.Namespace}</DataTableRowColumn>
                                </DataTableRow>))}
                        </DataTableBody>
                    </DataTable>
                </div>))}
        </div>);
};
