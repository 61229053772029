import React, { useCallback, useState } from "react";
import type { PageDefinition } from "./pageId";
export interface PageDetailProps {
    page: PageDefinition;
}
const fallbackPageDefinition: PageDefinition = { Id: "App", Name: "App", Area: "App" };
const CurrentPageContext = React.createContext<PageDefinition>(fallbackPageDefinition);
export type VerticalNavigationPageArea = "Projects" | "Insights" | "Tasks" | "Configuration" | "User Profile" | "Dev Tools" | "Blueprints";
const VerticalNavigationPageAreaContext = React.createContext<VerticalNavigationPageArea | undefined>(undefined);
interface GlobalPageDetails {
    pageId: string;
    pageArea: string;
    pageName: string;
    verticalNavigationPageArea: VerticalNavigationPageArea;
}
type UpdateGlobalPageDetailsCallback = (pageDetails: GlobalPageDetails) => void;
const UpdateGlobalPageDetailsContext = React.createContext<UpdateGlobalPageDetailsCallback | undefined>(undefined);
interface GlobalPageProviderProps {
    children: React.ReactNode;
}
export function GlobalPageProvider({ children }: GlobalPageProviderProps) {
    const [currentPage, setCurrentPage] = useState<PageDefinition>(fallbackPageDefinition);
    const [verticalNavigationPageArea, setVerticalNavigationPageArea] = useState<VerticalNavigationPageArea | undefined>();
    const updateGlobalPageDetailsCallback = useCallback(({ pageId, pageName, pageArea, verticalNavigationPageArea }: GlobalPageDetails) => {
        setCurrentPage({ Id: pageId, Name: pageName, Area: pageArea });
        setVerticalNavigationPageArea(verticalNavigationPageArea);
    }, []);
    return (<CurrentPageContext.Provider value={currentPage}>
            <VerticalNavigationPageAreaContext.Provider value={verticalNavigationPageArea}>
                <UpdateGlobalPageDetailsContext.Provider value={updateGlobalPageDetailsCallback}>{children}</UpdateGlobalPageDetailsContext.Provider>
            </VerticalNavigationPageAreaContext.Provider>
        </CurrentPageContext.Provider>);
}
interface PageProviderProps {
    page: PageDefinition;
    children: React.ReactNode;
}
export function PageProvider({ page, children }: PageProviderProps) {
    return <CurrentPageContext.Provider value={page}>{children}</CurrentPageContext.Provider>;
}
export function usePage() {
    const page = React.useContext(CurrentPageContext);
    if (!page) {
        throw new Error("The Current Page context has not been setup. Please check the component hierarchy and ensure the appropriate context exists above this component.");
    }
    return page;
}
export function useVerticalNavigationPageArea() {
    const verticalNavigationPageArea = React.useContext(VerticalNavigationPageAreaContext);
    return verticalNavigationPageArea;
}
export function useUpdateGlobalPageDetails() {
    const updateGlobalPageDetails = React.useContext(UpdateGlobalPageDetailsContext);
    if (!updateGlobalPageDetails) {
        throw new Error("The UpdateGlobalPageDetailsContext has not been set up. Please check the component hierarchy and ensure the appropriate context exists above this component.");
    }
    return updateGlobalPageDetails;
}
