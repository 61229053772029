import type { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";
import * as Icon from "./BaseIcons";
export enum OctopusIconType {
    Environment,
    WorkerPool,
    Machine,
    DynamicWorkerPool,
    Worker
}
interface OctopusIconProps {
    iconType: OctopusIconType;
    className?: string;
    style?: React.CSSProperties;
    color?: string;
}
export const OctopusIcon: React.StatelessComponent<OctopusIconProps> = (props) => {
    const propsForIcon: SvgIconProps = {
        className: props.className,
        style: props.style,
    };
    if (props.color) {
        // There appears to be a bug in Material-UI's icons, in that you cannot just set the htmlColor like it says in their documentation,
        // you also need to set the fill colour before things will actually kick in, which is why you'll see both the htmlColor and style/fill being set on
        // each change.
        propsForIcon.htmlColor = props.color;
        propsForIcon.style = { ...propsForIcon.style, fill: props.color };
    }
    switch (props.iconType) {
        case OctopusIconType.Environment:
            return <Icon.EnvironmentIcon fontSize="small" {...propsForIcon}/>;
        case OctopusIconType.WorkerPool:
            return <Icon.WorkerPoolIcon fontSize="small" {...propsForIcon}/>;
        case OctopusIconType.Machine:
            return <Icon.MachineIcon fontSize="small" {...propsForIcon}/>;
        case OctopusIconType.DynamicWorkerPool:
            return <Icon.DynamicWorkerPoolIcon fontSize="small" {...propsForIcon}/>;
        case OctopusIconType.Worker:
            return <Icon.WorkerIcon {...propsForIcon}/>;
    }
};
OctopusIcon.displayName = "OctopusIcon"
