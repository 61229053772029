import type { ErrorInfo } from "@octopusdeploy/design-system-components";
import type { ValidationError } from "@octopusdeploy/octopus-react-client";
import { isValidationError } from "@octopusdeploy/octopus-react-client";
import { OctopusError } from "@octopusdeploy/octopus-server-client";
import { type Errors } from "../DataBaseComponent/Errors";
export function createErrorInfoFromError(error: Error): ErrorInfo {
    // For some reason, webstorm thinks that the following type guard is unsound, even though OctopusError inherits from Error
    // noinspection SuspiciousTypeOfGuard
    if (error instanceof OctopusError) {
        return createErrorInfoFromOctopusError(error);
    }
    if (isValidationError(error)) {
        return createErrorInfoFromValidationError(error);
    }
    return {
        message: error.message,
        details: (error.stack?.split(" at ") ?? []).map((detail) => ({ text: detail })),
    };
}
function createErrorInfoFromValidationError(err: ValidationError): ErrorInfo {
    return {
        message: err.message,
        details: [],
    };
}
function createErrorInfoFromOctopusError(err: OctopusError): ErrorInfo {
    return {
        message: err.ErrorMessage,
        details: (err.Errors || []).map((detail, index) => ({
            text: detail,
            link: err.ParsedHelpLinks && err.ParsedHelpLinks?.length > index ? err.ParsedHelpLinks[index] : undefined,
        })),
        help: {
            text: err.HelpText,
            link: err.HelpLink,
        },
    };
}
export function createErrorInfoFromErrors(err: Errors | Error[]): ErrorInfo[] {
    if (Array.isArray(err)) {
        return err.map(createErrorInfoFromError);
    }
    return [
        {
            message: err.message,
            details: (err.errors || []).map((detail, index) => ({
                text: detail,
                link: err.parsedHelpLinks && err.parsedHelpLinks?.length > index ? err.parsedHelpLinks[index] : undefined,
            })),
            help: {
                text: err.helpText,
                link: err.helpLink,
            },
        },
    ];
}
