/* Generated file. Do not modify. Please check more details in build/generate.ts */
import * as React from "react";
import type { IconProps } from "../IconTypes";
import { IconWrapper } from "../IconWrapper";
export function CodeBranchIcon(props: IconProps) {
    return (<IconWrapper {...props}>
            {<>
                    <path fillRule="evenodd" clipRule="evenodd" d="M10.771 12.5A5.002 5.002 0 0 1 1 11a5 5 0 0 1 9.771-1.5H14a7.5 7.5 0 0 1 7.5 7.5v6a4.5 4.5 0 0 0 4.5 4.5h3.229A5.002 5.002 0 0 1 39 29a5 5 0 0 1-9.771 1.5H26a7.5 7.5 0 0 1-7.5-7.5v-6a4.5 4.5 0 0 0-4.5-4.5zM8 11a2 2 0 1 1-4 0 2 2 0 0 1 4 0m28 18a2 2 0 1 1-4 0 2 2 0 0 1 4 0"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M23.5 11A1.5 1.5 0 0 1 25 9.5h4.229A5.002 5.002 0 0 1 39 11a5 5 0 0 1-9.771 1.5H25a1.5 1.5 0 0 1-1.5-1.5M34 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4"/>
                </>}
        </IconWrapper>);
}
