import * as React from "react";
import Tasks from "~/areas/tasks/components/Tasks/Tasks";
import { PageContent } from "~/components/PageContent/PageContent";
interface TenantTasksProps {
    tenantId: string;
}
export const TenantTasksPage: React.FC<TenantTasksProps> = ({ tenantId }) => {
    return (<Tasks restrictToTenantId={tenantId} renderLayout={({ busy, errors, hasLoadedOnce, children }) => {
            return (<PageContent header={{ title: "Tasks" }} busy={busy} errors={errors} fullWidth={true}>
                        {children}
                    </PageContent>);
        }}/>);
};
TenantTasksPage.displayName = "TenantTasksPage"
