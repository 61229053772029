/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { css } from "@emotion/css";
import { Button, buttonImportanceLevels } from "@octopusdeploy/design-system-components";
import { ExclamationTriangleIcon, TimeCircleIcon, XmarkIcon } from "@octopusdeploy/design-system-icons";
import { colorScales, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { EnvironmentResource, RunbookResource, RunRunbookActionResource, TriggerResource, TriggerScheduleResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import _ from "lodash";
import * as React from "react";
import { useState } from "react";
import { MissingChip } from "~/components/Chips";
import Chip from "~/components/Chips/Chip";
import { ChipIconWrapper } from "~/components/Chips/ChipIconWrapper";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import Note from "~/primitiveComponents/form/Note/Note";
import ScheduledTriggerDescriptionHelper from "~/utils/ScheduledTriggerDescriptionHelper";
import { logger } from "../../../../../../../../packages/logging/src";
const MINIMUM_TO_DISPLAY = 8;
interface ScheduledTriggersBarInternalProps {
    runbookTriggers: TriggerResource[];
    environments: EnvironmentResource[];
    projectSlug: string;
    spaceId: string;
    hasRunbookProcess: boolean;
    runbookId?: string; // Required if on NewRunbookRunListPage
    runbooksInProject?: RunbookResource[]; // Required if on OperationsOverviewPage
}
export function ScheduledTriggersBarInternal({ runbookTriggers, environments, projectSlug, spaceId, hasRunbookProcess, runbookId, runbooksInProject }: ScheduledTriggersBarInternalProps) {
    const [isCollapsed, setIsCollapsed] = useState(true);
    if (environments.length < 1) {
        return null;
    }
    const styles = {
        container: css({
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            margin: space[16],
            gap: space[32],
        }),
    };
    const noTriggersMessage = <Note style={{ marginBottom: "0px" }}>No triggers are currently setup for {runbooksInProject ? "Runbooks." : "this Runbook."}</Note>;
    return (<div className={styles.container}>
            <NextScheduleRunsTitle numberOfRuns={runbookTriggers.length}/>
            {runbookTriggers.length === 0 && noTriggersMessage}
            <div>{<TriggerChips triggers={runbookTriggers} environments={environments} isCollapsed={isCollapsed} projectSlug={projectSlug} runbooksInProject={runbooksInProject}/>}</div>
            {runbookTriggers.length === 0 && hasRunbookProcess && <AddScheduledTriggersButton spaceId={spaceId} projectSlug={projectSlug} runbookId={runbookId}/>}
            {runbookTriggers.length > MINIMUM_TO_DISPLAY && <ShowAllButton isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed}/>}
        </div>);
}
interface NextScheduledRunsTitleProps {
    numberOfRuns: number;
}
function NextScheduleRunsTitle({ numberOfRuns }: NextScheduledRunsTitleProps) {
    const styles = {
        container: css({
            display: "flex",
            alignItems: "center",
            gap: space[8],
            minWidth: "fit-content",
        }),
        title: css({
            margin: "0",
        }),
    };
    const displayNumberOfRuns = numberOfRuns > 0 ? `(${numberOfRuns})` : "";
    return (<div className={styles.container}>
            <TimeCircleIcon size={24} color={themeTokens.color.icon.tertiary}/>
            <h4 className={styles.title}>Scheduled Triggers {displayNumberOfRuns}</h4>
        </div>);
}
const buttonStyles = {
    container: css({
        marginLeft: "auto",
        marginRight: space[12],
    }),
};
interface AddScheduledTriggersButtonProps {
    spaceId: string;
    projectSlug: string;
    runbookId?: string;
}
function AddScheduledTriggersButton({ spaceId, projectSlug, runbookId }: AddScheduledTriggersButtonProps) {
    const spaceAwareNavigation = useSpaceAwareNavigation();
    const createTriggerUrl = runbookId ? links.projectRunbookCreateScheduledTriggerForRunbookPage.generateUrl({ spaceId, projectSlug, runbookId }) : links.projectRunbookCreateScheduledTriggerPage.generateUrl({ spaceId, projectSlug });
    return (<div className={buttonStyles.container}>
            <Button label={"Add Scheduled Triggers"} onClick={() => spaceAwareNavigation.navigate(createTriggerUrl)} importance={buttonImportanceLevels[4]}/>
        </div>);
}
interface ShowAllButtonProps {
    isCollapsed: boolean;
    setIsCollapsed: (isCollapsed: (prev: boolean) => boolean) => void;
}
function ShowAllButton({ isCollapsed, setIsCollapsed }: ShowAllButtonProps) {
    const label = isCollapsed ? "Show All" : "Show Less";
    return (<div className={buttonStyles.container}>
            <Button label={label} onClick={() => setIsCollapsed((prev: boolean) => !prev)} importance={buttonImportanceLevels[4]}/>
        </div>);
}
interface TriggerChipsProps {
    triggers: TriggerResource[];
    environments: EnvironmentResource[];
    isCollapsed: boolean;
    projectSlug: string;
    runbooksInProject?: RunbookResource[];
}
function TriggerChips({ triggers, environments, isCollapsed, projectSlug, runbooksInProject }: TriggerChipsProps) {
    const spaceAwareNavigation = useSpaceAwareNavigation();
    // TODO: Add link to the trigger & add styling for disabled triggers
    const triggersToDisplay = isCollapsed ? triggers.slice(0, MINIMUM_TO_DISPLAY) : triggers;
    const triggerChips = triggersToDisplay.map((trigger) => {
        const runbookId = (trigger.Action as unknown as RunRunbookActionResource).RunbookId;
        const runbook = runbooksInProject?.find((runbook) => runbook.Id === runbookId);
        const triggerUrl = links.projectRunbookEditScheduledTriggerPage.generateUrl({ spaceId: trigger.SpaceId, projectSlug, triggerId: trigger.Id });
        // Handle case if no runbook exists for the trigger. (Only relevant to Operations Page.)
        if (runbooksInProject && !runbook) {
            const icon = (<ChipIconWrapper backgroundColor={themeTokens.color.icon.danger}>
                    {/* Needs to be white for both dark and light themes. */}
                    <XmarkIcon size={16} color={colorScales.white}/>
                </ChipIconWrapper>);
            return <MissingChip lookupId={runbookId} icon={icon} text={trigger.Name} to={triggerUrl}/>;
        }
        const icon = (<ChipIconWrapper>
                <TimeCircleIcon size={16}/>
            </ChipIconWrapper>);
        const disabledIcon = (<ChipIconWrapper backgroundColor={themeTokens.color.icon.attention}>
                <ExclamationTriangleIcon size={16} color={themeTokens.color.icon.inverse}/>
            </ChipIconWrapper>);
        return (<Chip description={<TriggerChipDescription trigger={trigger} environments={environments} runbookName={runbook?.Name}/>} icon={trigger.IsDisabled ? disabledIcon : icon} onClick={() => spaceAwareNavigation.navigate(triggerUrl)}>
                {trigger.Name}
            </Chip>);
    });
    return <div>{triggerChips}</div>;
}
interface TriggerChipDescriptionProps {
    trigger: TriggerResource;
    environments: EnvironmentResource[];
    runbookName?: string;
}
function TriggerChipDescription({ trigger, environments, runbookName }: TriggerChipDescriptionProps) {
    const scheduleDescription = ScheduledTriggerDescriptionHelper.getScheduleDescription(trigger.Filter as TriggerScheduleResource);
    const destinationEnvironmentIds = (trigger.Action as RunRunbookActionResource).EnvironmentIds;
    const destinationEnvsNames = destinationEnvironmentIds.flatMap((id) => {
        const destinationEnvs = _.filter(environments, (env) => {
            if (env.Id === id)
                return true;
        }) as EnvironmentResource[];
        if (destinationEnvs.length === 0) {
            logger.error("Missing Environment", { triggerId: trigger.Id, environmentId: id });
            return ["Missing Environment"];
        }
        return destinationEnvs.map((env) => env.Name);
    });
    // Chips to appear on NewRunbookRunsListPage
    if (!runbookName) {
        return (<span>
                {trigger.IsDisabled && "Disabled: "} Run {scheduleDescription} on {formatListWithAnd(destinationEnvsNames)}.
            </span>);
    }
    // Chips to appear on OperationsOverviewPage
    return (<span>
            {trigger.IsDisabled && "Disabled: "} Run Runbook {runbookName} {scheduleDescription} on {formatListWithAnd(destinationEnvsNames)}.
        </span>);
}
function formatListWithAnd(list: string[]) {
    if (list.length === 1)
        return list[0];
    const allButLast = _.initial(list).join(", ");
    const lastItem = _.last(list);
    return `${allButLast} & ${lastItem}`;
}
