/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import type { StepIconProps } from "@material-ui/core";
import { Step, StepLabel, Stepper } from "@material-ui/core";
import { ActionButton, ActionButtonType, BooleanRadioButtonGroup, BooleanRadioButton, Callout } from "@octopusdeploy/design-system-components";
import type { ProjectResource, GitBranch, GitRef } from "@octopusdeploy/octopus-server-client";
import { HasGitPersistenceSettings, isGitBranch, toGitBranch, toGitRefShort } from "@octopusdeploy/octopus-server-client";
import type { MigrateProjectRunbooksToGitCommand } from "@octopusdeploy/octopus-server-client/src/resources/migrateProjectRunbooksToGitCommand";
import type { MigrateProjectRunbooksToGitSummary } from "@octopusdeploy/octopus-server-client/src/resources/migrateProjectRunbooksToGitSummary";
import { ProjectGitRefSelector } from "app/areas/projects/components/GitRefSelector/ProjectGitRefSelector";
import classNames from "classnames";
import pluralize from "pluralize";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { Action, useProjectScopedAnalyticTrackedActionDispatch, useProjectScopedAnalyticActionDispatch } from "~/analytics/Analytics";
import type { ActionEvent, AnalyticErrorCallback } from "~/analytics/Analytics";
import { useProjectContext } from "~/areas/projects/context";
import { repository } from "~/clientInstance";
import { ActionListContainer } from "~/components/ActionList/ActionList";
import BusyFromPromise from "~/components/BusyFromPromise";
import Chip from "~/components/Chips/Chip";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import DataBaseComponent, { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import { CustomWizardDialogLayout, SmallDialogFrame } from "~/components/DialogLayout/Custom";
import { DialogLayout } from "~/components/DialogLayout/DialogLayout";
import ExternalLink from "~/components/Navigation/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink";
import { ErrorPanel, Note, Text } from "~/components/form";
import { DataTable, DataTableBody, DataTableHeader, DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import StringHelper from "~/utils/StringHelper";
import { GitRefDropDownMode } from "../../GitRefDropDown/GitRefDropDown";
import { getFormattedCommitMessage } from "../../VersionControl/CommitMessageWithDetails";
import styles from "./style.module.less";
enum ExecutionState {
    NotStarted = "NotStarted",
    Executing = "Executing",
    Success = "Success",
    Error = "Error"
}
interface MigrateProjectRunbooksDialogProps {
    open: boolean;
    close: () => void;
}
class MigrateProjectRunbooksWizard extends DataBaseComponent<MigrateProjectRunbooksDialogProps> {
    constructor(props: MigrateProjectRunbooksDialogProps) {
        super(props);
        this.state = {};
    }
    render() {
        return <MigrateProjectRunbooksWizardInternal {...this.props} doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors}/>;
    }
    static displayName = "MigrateProjectRunbooksWizard";
}
interface MigrateProjectRunbooksDialogInternalProps extends MigrateProjectRunbooksDialogProps {
    doBusyTask: DoBusyTask;
    busy?: Promise<void>;
    errors?: Errors;
}
function useMigrationSummary(doBusyTask: DoBusyTask, project: ProjectResource) {
    const [migrationSummary, setMigrationSummary] = useState<MigrateProjectRunbooksToGitSummary>();
    useDoBusyTaskEffect(doBusyTask, async () => {
        const summary = await repository.Projects.getMigrateRunbooksToGitSummary(project);
        setMigrationSummary(summary);
    }, [project]);
    return migrationSummary;
}
function useMigrationExecutor(doBusyTask: DoBusyTask, project: ProjectResource, setExecutionState: (state: ExecutionState) => void, setServerTaskId: (serverTaskId?: string) => void) {
    const trackAction = useProjectScopedAnalyticTrackedActionDispatch(project.Id);
    const migrator = useCallback((command: MigrateProjectRunbooksToGitCommand) => {
        const ev: ActionEvent = {
            action: Action.Save,
            resource: "Runbooks",
            isDefaultBranch: command.AnalyticsInfo.IsDefaultBranch,
            commitMessage: command.AnalyticsInfo.IsDefaultCommitMessage,
        };
        return trackAction("Move Runbooks to Git", ev, async (analyticErrorCallback: AnalyticErrorCallback) => {
            await doBusyTask(async () => {
                const result = await repository.Projects.migrateRunbooksToGit(project, command);
                setServerTaskId(result.ServerTaskId);
                setExecutionState(ExecutionState.Success);
            }, {
                onError: (error) => {
                    analyticErrorCallback(error);
                    setExecutionState(ExecutionState.Error);
                },
            });
        });
    }, [doBusyTask, project, setExecutionState, setServerTaskId, trackAction]);
    return migrator;
}
const MigrateProjectRunbooksWizardInternal: React.FC<MigrateProjectRunbooksDialogInternalProps> = (props: MigrateProjectRunbooksDialogInternalProps) => {
    const defaultCommitSummary = "Migrate project runbooks to Git";
    const stepNames = ["Getting started", "Select a branch", "Review & migrate"];
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    if (!HasGitPersistenceSettings(project.PersistenceSettings)) {
        throw new Error("Can not migrate runbooks for Database projects");
    }
    const defaultBranch: GitBranch = toGitBranch(project.PersistenceSettings.DefaultBranch);
    const [executionState, setExecutionState] = useState<ExecutionState>(ExecutionState.NotStarted);
    const [serverTaskId, setServerTaskId] = useState<string | undefined>(undefined);
    const [createNewBranch, setCreateNewBranch] = useState(false);
    const [existingBranchGitRef, setExistingBranchGitRef] = useState<GitRef>(defaultBranch);
    const [newBranchName, setNewBranchName] = useState("");
    const [isUsingDefaultBranch, setIsUsingDefaultBranch] = useState<Boolean>(true);
    useEffect(() => {
        const usingDefaultBranch = !createNewBranch && isGitBranch(existingBranchGitRef) && existingBranchGitRef === defaultBranch;
        setIsUsingDefaultBranch(usingDefaultBranch);
    }, [createNewBranch, existingBranchGitRef, defaultBranch]);
    const [commitSummary, setCommitSummary] = useState("");
    const [commitDetails, setCommitDetails] = useState("");
    const migrationSummary = useMigrationSummary(props.doBusyTask, project);
    const executeMigration = useMigrationExecutor(props.doBusyTask, project, setExecutionState, setServerTaskId);
    const [canProgress, setCanProgress] = useState<CanProgress>({});
    useEffect(() => {
        if (createNewBranch && StringHelper.isNullOrWhiteSpace(newBranchName)) {
            setCanProgress({ 1: false });
        }
        else {
            setCanProgress({ 1: true });
        }
    }, [createNewBranch, newBranchName]);
    const migrate = () => {
        if (!isGitBranch(existingBranchGitRef)) {
            throw new Error(`Selected GitRef is not a branch: ${existingBranchGitRef}`);
        }
        setExecutionState(ExecutionState.Executing);
        const branch = createNewBranch ? newBranchName : existingBranchGitRef;
        const formattedCommitMessage = getFormattedCommitMessage({
            summary: commitSummary,
            details: commitDetails,
        }, defaultCommitSummary);
        const command: MigrateProjectRunbooksToGitCommand = {
            Branch: branch,
            CreateBranch: createNewBranch,
            CommitMessage: formattedCommitMessage,
            AnalyticsInfo: {
                IsDefaultBranch: branch === defaultBranch,
                IsDefaultCommitMessage: !!commitSummary,
            },
        };
        executeMigration(command);
    };
    const dispatchAction = useProjectScopedAnalyticActionDispatch(project.Id);
    const refreshAndClose = () => {
        if (executionState === ExecutionState.NotStarted) {
            dispatchAction("Cancel Moving Runbooks", { resource: "Runbooks", action: Action.Cancel });
        }
        else if (executionState === ExecutionState.Error) {
            // The user has tried to migrate, hit an error and then canceled.
            dispatchAction("Cancel Moving Runbooks", { resource: "Runbooks", action: Action.Cancel });
        }
        if (executionState === ExecutionState.Success || executionState === ExecutionState.Error) {
            props.doBusyTask(async () => {
                if (executionState === ExecutionState.Success) {
                    const branch = createNewBranch ? toGitBranch(newBranchName) : existingBranchGitRef;
                    await projectContext.actions.onBranchSelected(project, branch);
                }
                await projectContext.actions.refreshModel();
                props.close();
            });
        }
        props.close();
    };
    const runbooks = GetUniqueCombinedRunbookSummaries(migrationSummary);
    return (<CustomWizardDialogLayout frame={SmallDialogFrame} open={props.open} close={refreshAndClose} renderPage={(renderProps) => <MigrateProjectRunbooksWizardLayout {...renderProps} executionState={executionState} stepNames={stepNames} execute={migrate} busy={props.busy} canProgress={canProgress} errors={props.errors}/>}>
            <MigrateProjectRunbooksWizardStep>
                {migrationSummary && (<Callout title="Ready to migrate" type={"success"}>
                        This project is ready to migrate Runbooks to Git
                    </Callout>)}
                <p>You are about to migrate your project Runbooks to Git. Octopus will convert the following Runbooks:</p>

                <RunbooksTable runbooks={runbooks}/>

                <p>
                    This process cannot be reversed. Read the <ExternalLink href="ConfigAsCodeRunbooks">Config as Code Runbook documentation</ExternalLink> for more details.
                </p>
            </MigrateProjectRunbooksWizardStep>
            <MigrateProjectRunbooksWizardStep>
                <p> Choose a branch to migrate the Runbooks to: </p>
                <BooleanRadioButtonGroup key="branchOptions" onChange={setCreateNewBranch} value={createNewBranch}>
                    <BooleanRadioButton key="existingBranch" value={false} label="Existing branch"/>
                    {!createNewBranch && (<div>
                            <ProjectGitRefSelector style={"white"} key={`${existingBranchGitRef}`} initialGitRef={existingBranchGitRef} onChange={setExistingBranchGitRef} mode={GitRefDropDownMode.BranchesOnly} allowBranchCreation={false} project={project}/>
                        </div>)}
                    <BooleanRadioButton key="newBranch" value={true} label="New branch"/>
                    {createNewBranch && (<div>
                            <Text 
        // There is some weird business going on with the height of the text box. I think it has to do with the
        // lack of label and usePlaceholderAsLabel=false. After clearing the text the size briefly increases to
        // 37px. Fixing the height to stop it bouncing around.
        style={{ marginTop: "0", height: "37px" }} key="newBranchName" id="newBranchName" name="NewBranchName" value={newBranchName} onChange={setNewBranchName} placeholder="New branch name" accessibleName="New branch name" usePlaceholderAsLabel={false}/>
                            <Note>
                                The new branch will be based on default branch (<code>{toGitRefShort(defaultBranch)}</code>) in your repository
                            </Note>
                        </div>)}
                </BooleanRadioButtonGroup>
            </MigrateProjectRunbooksWizardStep>
            <MigrateProjectRunbooksWizardStep>
                {(executionState === ExecutionState.NotStarted || executionState === ExecutionState.Executing) && (<>
                        <h3>Review Migration</h3>
                        <MigrationReviewList summary={migrationSummary} createNewBranch={createNewBranch} branchName={createNewBranch ? newBranchName : existingBranchGitRef} defaultBranch={defaultBranch}/>
                        <h3>Enter a Commit Message</h3>
                        <Text key="summary" id="summary" name="Summary" label="Summary" placeholder={defaultCommitSummary} value={commitSummary} disabled={executionState === ExecutionState.Executing} onChange={setCommitSummary} autoFocus={true} accessibleName={"Commit summary"} customMargins="0 0 0.5rem 0"/>
                        <Text key="details" id="details" name="Details" label="Optional description" disabled={executionState === ExecutionState.Executing} value={commitDetails} onChange={setCommitDetails} multiline={true} accessibleName={"Optional commit description"}/>
                    </>)}
                {executionState === ExecutionState.Success && (<>
                        <Callout title="Success" type={"success"}>
                            <p>Runbooks have been migrated to your Git repository successfully.</p>
                            {serverTaskId && (<p>
                                    The history for these Runbooks is being migrated in a <InternalLink to={`/${project.SpaceId}/tasks/${serverTaskId}`}> Task </InternalLink>. You may continue to use Runbooks as normal while the Task finishes the
                                    history migration.
                                </p>)}
                        </Callout>
                        <h3> Next step </h3>
                        <p>
                            Runbooks have only been migrated to the <code>{createNewBranch ? newBranchName : existingBranchGitRef}</code> branch. You will need to merge this branch with other branches that need these runbooks.
                        </p>
                        <p>
                            Need help? See <ExternalLink href="ConfigAsCodeRunbooks">Git runbooks documentation</ExternalLink> for more details.
                        </p>
                    </>)}
                {executionState === ExecutionState.Error && (<>
                        <p>The migration has failed. Review the configuration and try again. </p>
                        <p>
                            Need help? See <ExternalLink href="ConfigAsCodeRunbooks">Git runbook documentation</ExternalLink> for more details.
                        </p>
                    </>)}
            </MigrateProjectRunbooksWizardStep>
        </CustomWizardDialogLayout>);
};
MigrateProjectRunbooksWizardInternal.displayName = "MigrateProjectRunbooksWizardInternal"
interface InfoNoteProps {
    children?: React.ReactNode;
}
const InfoNote: React.FC<InfoNoteProps> = (props: InfoNoteProps) => {
    return (<Note className={styles.infoNote}>
            <em className={classNames(styles.icon, "fa-solid", "fa-info-circle")} aria-hidden="true"/>
            {props.children}
        </Note>);
};
InfoNote.displayName = "InfoNote"
interface MigrationReviewListProps {
    summary?: MigrateProjectRunbooksToGitSummary;
    createNewBranch: boolean;
    branchName: string | undefined;
    defaultBranch: string;
}
interface CombinedRunbookSummary {
    RunbookId: string;
    RunbookName: string;
    IsPublished: boolean;
    IsDraft: boolean;
}
const GetUniqueCombinedRunbookSummaries = (runbooks?: MigrateProjectRunbooksToGitSummary): CombinedRunbookSummary[] => {
    const result: CombinedRunbookSummary[] = [];
    if (!runbooks) {
        return result;
    }
    for (const publishedRunbook of runbooks.PublishedRunbooks) {
        result.push({
            RunbookId: publishedRunbook.RunbookId,
            RunbookName: publishedRunbook.RunbookName,
            IsPublished: true,
            IsDraft: false,
        });
    }
    for (const draftRunbook of runbooks.DraftRunbooks) {
        const existingRunbook = result.find((x) => x.RunbookId === draftRunbook.RunbookId);
        if (existingRunbook) {
            existingRunbook.IsDraft = true;
        }
        else {
            result.push({
                RunbookId: draftRunbook.RunbookId,
                RunbookName: draftRunbook.RunbookName,
                IsPublished: false,
                IsDraft: true,
            });
        }
    }
    return result;
};
const MigrationReviewList: React.FC<MigrationReviewListProps> = (props: MigrationReviewListProps) => {
    if (!props.summary) {
        return <></>;
    }
    const anyPublihsedRunbooks = props.summary.PublishedRunbooks.length > 0;
    const anyDraftRunbooks = props.summary.DraftRunbooks.length > 0;
    const newBranchItem = (<li>
            New branch <code>{props.branchName}</code> will be created from <code>{toGitRefShort(props.defaultBranch)}</code>
        </li>);
    const publihsedRunbooksSummaryItem = (<li>
            {props.summary?.PublishedRunbooks?.length} {pluralize("Runbook", props.summary?.PublishedRunbooks?.length)} will be migrated to files in runbooks directory on the <code>{toGitRefShort(props.branchName)}</code> branch
        </li>);
    const emptyRunbooksSummaryItem = (<li>
            No Runbook files will be created on <code>{toGitRefShort(props.branchName)}</code>. There are no Runboks to migrate.
        </li>);
    const draftRunbooksSummaryItem = (<li>
            {props.summary?.DraftRunbooks?.length} {pluralize("Runbook", props.summary?.DraftRunbooks?.length)} will be migrated to files in runbooks/migrated-drafts directory on the <code>{toGitRefShort(props.branchName)}</code> branch
        </li>);
    return (<ul className={styles.migrationReviewList}>
            {props.createNewBranch && newBranchItem}
            {anyPublihsedRunbooks ? publihsedRunbooksSummaryItem : emptyRunbooksSummaryItem}
            {anyDraftRunbooks && draftRunbooksSummaryItem}
        </ul>);
};
MigrationReviewList.displayName = "MigrationReviewList"
const RunbooksTable = (props: {
    runbooks: CombinedRunbookSummary[];
}) => {
    if (props.runbooks.length === 0) {
        return <></>;
    }
    return (<div className={styles.runbookList}>
            <DataTable>
                <DataTableHeader>
                    <DataTableRow>
                        <DataTableHeaderColumn>
                            <h4>Runbook</h4>
                        </DataTableHeaderColumn>
                        <DataTableHeaderColumn width={5}>
                            <h4>Type</h4>
                        </DataTableHeaderColumn>
                    </DataTableRow>
                </DataTableHeader>
                <DataTableBody>
                    {props.runbooks.map((runbook) => (<DataTableRow key={runbook.RunbookId}>
                            <DataTableRowColumn>
                                <div className={styles.actionName}>{runbook.RunbookName}</div>
                            </DataTableRowColumn>
                            <DataTableRowColumn className={styles.runbookTypeColumn}>
                                {runbook.IsPublished && <Chip>Published</Chip>}
                                {runbook.IsDraft && <Chip>Draft</Chip>}
                            </DataTableRowColumn>
                        </DataTableRow>))}
                </DataTableBody>
            </DataTable>
        </div>);
};
const MigrateProjectRunbooksWizardStep: React.FC<{
    children: React.ReactNode;
}> = (props: {
    children: React.ReactNode;
}) => {
    return <div className={styles.wizardStepContentContainer}> {props.children} </div>;
};
MigrateProjectRunbooksWizardStep.displayName = "MigrateProjectRunbooksWizardStep"
interface MigrateProjectRunbooksWizardLayoutProps extends MigrateProjectRunbooksWizardStepListProps, MigrateProjectRunbooksWizardLayoutButtonsProps {
    busy?: Promise<void>;
    errors?: Errors;
    content: React.ReactNode;
}
const MigrateProjectRunbooksWizardLayout: React.FC<MigrateProjectRunbooksWizardLayoutProps> = (props: MigrateProjectRunbooksWizardLayoutProps) => {
    return (<DialogLayout title={"Migrate Runbooks To Git"} actions={<MigrateProjectRunbooksWizardButtons {...props}/>} busy={props.busy} closeDialog={props.close}>
            <MigrateProjectRunbooksWizardStepper {...props}/>
            <div className={styles.wizardContent}>
                {props.errors && (<ErrorPanel message={props.errors.message} errors={props.errors.errors} parsedHelpLinks={props.errors.parsedHelpLinks} helpText={props.errors.helpText} helpLink={props.errors.helpLink} statusCode={props.errors.statusCode}/>)}
                {props.content}
            </div>
        </DialogLayout>);
};
MigrateProjectRunbooksWizardLayout.displayName = "MigrateProjectRunbooksWizardLayout"
interface MigrateProjectRunbooksWizardStepListProps {
    currentPageIndex: number;
    pageCount: number;
    stepNames: string[];
    executionState: ExecutionState;
}
const MigrateProjectRunbooksWizardStepper: React.FC<MigrateProjectRunbooksWizardStepListProps> = (props: MigrateProjectRunbooksWizardStepListProps) => {
    return (<div className={styles.wizardStepper}>
            <Stepper alternativeLabel orientation="horizontal" activeStep={props.currentPageIndex}>
                {props.stepNames.map((name, index) => {
            const isFinalStep = index === props.stepNames.length - 1;
            const error = isFinalStep && props.executionState === ExecutionState.Error;
            const completed = isFinalStep && props.executionState === ExecutionState.Success;
            const stepIconBaseProps = isFinalStep ? { error, completed } : {};
            const stepIconProps: Partial<StepIconProps> = {
                ...stepIconBaseProps,
                classes: {
                    completed: styles.wizardIconStepCompleted,
                },
            };
            return (<Step key={index}>
                            <StepLabel StepIconProps={stepIconProps}>{name}</StepLabel>
                        </Step>);
        })}
            </Stepper>
        </div>);
};
MigrateProjectRunbooksWizardStepper.displayName = "MigrateProjectRunbooksWizardStepper"
interface CanProgress {
    [key: number]: boolean;
}
interface MigrateProjectRunbooksWizardLayoutButtonsProps {
    currentPageIndex: number;
    canProgress: CanProgress;
    busy?: Promise<void>;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
    moveNext: () => void;
    movePrevious: () => void;
    close: () => void;
    executionState: ExecutionState;
    execute: () => void | Promise<void>;
}
const MigrateProjectRunbooksWizardButtons: React.FC<MigrateProjectRunbooksWizardLayoutButtonsProps> = (props: MigrateProjectRunbooksWizardLayoutButtonsProps) => {
    const isOnFinalPage = !props.hasNextPage;
    const canProgressCurrentPage = props.canProgress[props.currentPageIndex] === undefined || props.canProgress[props.currentPageIndex];
    return (<ActionListContainer>
            {props.executionState === ExecutionState.NotStarted && <ActionButton type={ActionButtonType.Secondary} label="Cancel" onClick={props.close}/>}
            {props.executionState === ExecutionState.NotStarted && props.hasPreviousPage && <ActionButton type={ActionButtonType.Secondary} label="Previous" onClick={props.movePrevious}/>}
            {isOnFinalPage && props.executionState === ExecutionState.NotStarted && <ActionButton type={ActionButtonType.Primary} label="Migrate" onClick={props.execute}/>}
            {isOnFinalPage && props.executionState === ExecutionState.Executing && <ActionButton disabled={true} type={ActionButtonType.Primary} label="Migrating"/>}
            {isOnFinalPage && props.executionState === ExecutionState.Success && <ActionButton type={ActionButtonType.Primary} label="Done" onClick={props.close}/>}
            {isOnFinalPage && props.executionState === ExecutionState.Error && <ActionButton type={ActionButtonType.Primary} label="Close" onClick={props.close}/>}
            {!isOnFinalPage && <BusyFromPromise promise={props.busy}>{(busy) => <ActionButton disabled={busy || !canProgressCurrentPage} type={ActionButtonType.Primary} label={"Next"} onClick={props.moveNext}/>}</BusyFromPromise>}
        </ActionListContainer>);
};
MigrateProjectRunbooksWizardButtons.displayName = "MigrateProjectRunbooksWizardButtons"
export default MigrateProjectRunbooksWizard;
