import { css } from "@emotion/css";
import { colorScales, space } from "@octopusdeploy/design-system-tokens";
import React, { useState, createRef } from "react";
import { PopoverBasic, type PopoverBasicProps } from "./PopoverBasic";
export interface PopoverBasicHelpProps extends Omit<PopoverBasicProps, "open" | "anchorEl"> {
    onOpen?: () => void;
}
export function PopoverBasicHelp({ onOpen, ...props }: PopoverBasicHelpProps): JSX.Element {
    const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null);
    const ref = createRef<HTMLDivElement>();
    const preventPrematureClose = (event: React.MouseEvent) => {
        event.stopPropagation();
    };
    const handleIconClick = () => {
        anchorEl ? handleClose() : handleOpen();
    };
    const handleOpen = () => {
        setAnchorEl(ref.current);
        onOpen?.();
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (<div ref={ref} tabIndex={0} onClick={preventPrematureClose} className={styles.popoverBasicHelpContainer}>
            <div onClick={handleIconClick} className={styles.infoIconContainer}>
                <InfoPopoverIcon />
            </div>
            <PopoverBasic onClose={handleClose} anchorEl={anchorEl} open={!!anchorEl} {...props}/>
        </div>);
}
const styles = {
    popoverBasicHelpContainer: css({
        display: "inline-block",
        position: "relative",
        bottom: "-7px",
        "&:focus div": {
            boxShadow: `inset 0 0 0 2px ${colorScales.blue[300]}`,
            borderRadius: "50%",
        },
    }),
    infoIconContainer: css({
        width: space[24],
        height: space[24],
    }),
};
function InfoPopoverIcon() {
    // This is a snowflake component and should not be widely used, please try the icons in design-system-icons package first.
    return (<svg className={infoIconStyles} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9999 21.6C6.6749 21.6 2.3999 17.325 2.3999 12C2.3999 6.71252 6.6749 2.40003 11.9999 2.40003C17.2874 2.40003 21.5999 6.71253 21.5999 12C21.5999 17.325 17.2874 21.6 11.9999 21.6Z" fill={colorScales.blue[200]}/>
            <path d="M10.5187 15L11.4187 15L11.4187 12.6L10.5187 12.6C9.99365 12.6 9.61865 12.225 9.61865 11.7C9.61865 11.2125 9.99365 10.8 10.5187 10.8L12.3187 10.8C12.8062 10.8 13.2187 11.2125 13.2187 11.7L13.2187 15L13.5187 15C14.0062 15 14.4187 15.4125 14.4187 15.9C14.4187 16.425 14.0062 16.8 13.5187 16.8L10.5187 16.8C9.99365 16.8 9.61865 16.425 9.61865 15.9C9.61865 15.4125 9.99365 15 10.5187 15ZM12.0187 9.60001C11.3437 9.60001 10.8187 9.07501 10.8187 8.40001C10.8187 7.76251 11.3437 7.20001 12.0187 7.20001C12.6562 7.20001 13.2187 7.76251 13.2187 8.40001C13.2187 9.07501 12.6562 9.60001 12.0187 9.60001Z" fill={colorScales.blue[500]}/>
        </svg>);
}
const infoIconStyles = css({
    width: "24px",
    height: "24px",
});
