import { Level1PageLoading, Level2PageLoading } from "@octopusdeploy/design-system-components";
import type { ApiOperationStatus } from "@octopusdeploy/octopus-react-client";
import { useAggregateAPIOperationStatus } from "@octopusdeploy/octopus-react-client";
import React from "react";
import { usePageRegistration } from "~/routing/pageRegistrations/PageRegistrationContext";
import StringHelper from "~/utils/StringHelper";
import { type Errors } from "../DataBaseComponent/Errors";
import { createErrorInfoFromError, createErrorInfoFromErrors } from "./createErrorInfo";
export interface PageLoadingProps {
    loadingTitle?: string;
    /**
     * @deprecated Pages should use the new data access hooks. Busy state will be handled automatically.
     */
    legacyStatus?: ApiOperationStatus;
    errors?: Errors | Error[];
}
export function PageLoading({ loadingTitle, errors: legacyErrors, legacyStatus }: PageLoadingProps) {
    const pageRegistration = usePageRegistration();
    const { errors } = useAggregateAPIOperationStatus(legacyStatus);
    const legacyErrorInfo = legacyErrors ? createErrorInfoFromErrors(legacyErrors) : undefined;
    const combinedErrorInfos = errors.map(createErrorInfoFromError);
    if (legacyErrorInfo && legacyErrorInfo.length > 0) {
        combinedErrorInfos.push(...legacyErrorInfo);
    }
    if (pageRegistration === null) {
        throw new Error("A page registration is required to use PageLoading. If you are running a test, specify a pageRegistration in the configuration object for renderWithTestAppContext");
    }
    if (pageRegistration.level === 1) {
        return <Level1PageLoading errors={combinedErrorInfos}/>;
    }
    return <Level2PageLoading loadingTitle={loadingTitle ?? StringHelper.ellipsis} errors={combinedErrorInfos}/>;
}
