import { NavigationButton, NavigationButtonType } from "@octopusdeploy/design-system-components";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import OnboardingPage from "~/components/GettingStarted/OnboardingPage";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import { PermissionCheck } from "~/components/PermissionCheck";
export const OnboardingTextForWorkers = "Workers let you delegate work to a machine other than the Octopus server for increased speed, scale and security.";
const WorkersOnboarding = (props: {
    spaceId: string;
}) => {
    return (<OnboardingPage title="Configure workers to execute deployment tasks on behalf of the Octopus Server" intro={<span>{OnboardingTextForWorkers}</span>} learnMore={<>
                    <ExternalLink href="OnboardingWorkersLearnMore">Learn more</ExternalLink>
                </>} actionButtons={<PermissionCheck permission={Permission.MachineCreate} tenant="*" environment="*">
                    <NavigationButton type={NavigationButtonType.Primary} label="Add Worker" href={links.newWorkerMachinePage.generateUrl({ spaceId: props.spaceId })}/>
                </PermissionCheck>}/>);
};
export default WorkersOnboarding;
