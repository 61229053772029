import type { DeploymentProcessResource, GitRef, GitRefResource, Repository, ValidateGitRefV2Response } from "@octopusdeploy/octopus-server-client";
import React from "react";
import useDeploymentProcessState from "~/areas/projects/components/Process/useDeploymentProcessState";
import { ProjectPageTitleAccessory } from "~/areas/projects/components/ProjectPageTitleAccessory";
import type { ProjectContextProps } from "~/areas/projects/context/index";
import ProcessListLayoutDataLoader from "./ProcessListLayoutLoader";
export async function deploymentProcessLoader(repository: Repository, projectContext: Promise<ProjectContextProps>): Promise<LoaderData> {
    /*
    We should not be retrieving the gitRef from projectContext. Ideally this is done in a similar way to runbooks which store the gitRef in query params instead of a route param.
    Since deployment processes still use route params we'd be doubling up on validation requests, so we're just using the validation result from projectContext.
     */
    const { state: { model: project, gitRef }, } = await projectContext;
    const deploymentProcess = await repository.DeploymentProcesses.get(project.Id, gitRef?.CanonicalName);
    return {
        process: deploymentProcess,
    };
}
interface LoaderData {
    process: DeploymentProcessResource;
    gitRefValidationResult?: ValidateGitRefV2Response | undefined;
}
export interface DeploymentProcessPageProps {
    loaderData: LoaderData;
    projectContext: ProjectContextProps;
}
export function DeploymentProcessListPage({ loaderData, projectContext }: DeploymentProcessPageProps) {
    const { model: project, gitRef } = projectContext.state;
    const { deploymentProcessIdentifier, deploymentProcess, reloadDeploymentProcess, modifyDeploymentProcess } = useDeploymentProcessState(project, loaderData.process, gitRef?.CanonicalName);
    const onProcessSave = (gitRef: GitRefResource | string | undefined) => projectContext.actions.onProjectUpdated(project, gitRef);
    const changeGitRef = (gitRef: string) => projectContext.actions.changeGitRef(gitRef);
    const refreshModel = (gitRef?: GitRef) => projectContext.actions.refreshModel(gitRef);
    return (<ProcessListLayoutDataLoader project={project} onProcessSave={onProcessSave} changeGitRef={changeGitRef} refreshModel={refreshModel} gitRef={gitRef?.CanonicalName} gitRefResource={gitRef} processIdentifier={deploymentProcessIdentifier} process={deploymentProcess} reloadProcess={reloadDeploymentProcess} modifyProcess={modifyDeploymentProcess} releaseCreationPackageStepId={project.ReleaseCreationStrategy.ReleaseCreationPackageStepId} titleAccessory={<ProjectPageTitleAccessory />}/>);
}
