import { Callout } from "@octopusdeploy/design-system-components";
import type { GitRefResource, VariableSetResource } from "@octopusdeploy/octopus-server-client";
import { ControlType, VariableType, Permission } from "@octopusdeploy/octopus-server-client";
import React from "react";
import type { FailedPermissionCheck } from "~/areas/projects/components/Process/ProcessStepsLayoutTypes";
import { isFailedPermissionCheck } from "~/areas/projects/components/Process/ProcessStepsLayoutTypes";
import type { ProcessIdentifier } from "~/areas/projects/components/Process/types";
import type { LoadedLibraryVariableSets } from "~/areas/projects/components/Variables/AllVariables/AllVariables";
import type { FormFieldProps } from "~/components/form";
import { BoundVariableSelect, VariableSelect } from "~/components/form/VariableSelect/VariableSelect";
interface BaseVariableInputProps extends FormFieldProps<string> {
    gitRef: GitRefResource | undefined;
    label: string;
    defaultValueIndicator: JSX.Element | undefined;
    allowClear: boolean;
    processIdentifier: ProcessIdentifier;
    processVariableSet: VariableSetResource | FailedPermissionCheck;
    libraryVariableSets: LoadedLibraryVariableSets[] | FailedPermissionCheck;
    refreshVariables: (() => Promise<void>) | undefined;
}
export interface VariableInputProps extends BaseVariableInputProps {
    variableTypes: VariableType[];
    controlTypes: ControlType[];
    showBoundField?: boolean;
}
const VariableInput: React.FC<VariableInputProps> = (props) => {
    const { value, gitRef, label, defaultValueIndicator, allowClear, processIdentifier, processVariableSet, libraryVariableSets, refreshVariables, variableTypes, controlTypes, showBoundField, ...rest } = props;
    const formProps = { ...rest, label };
    if (isFailedPermissionCheck(processVariableSet) || isFailedPermissionCheck(libraryVariableSets)) {
        return <Callout title={`${Permission.VariableView} and ${Permission.LibraryVariableSetView} are required to view selected variables`} type={"warning"}/>;
    }
    return showBoundField ? (<React.Fragment>
            <BoundVariableSelect value={value} processIdentifier={processIdentifier} processVariableSet={processVariableSet} libraryVariableSets={libraryVariableSets} gitRef={gitRef} allowClear={allowClear} refreshVariables={refreshVariables} variableTypes={variableTypes} controlTypes={controlTypes} {...formProps}/>
            {defaultValueIndicator}
        </React.Fragment>) : (<React.Fragment>
            <VariableSelect value={value} processIdentifier={processIdentifier} processVariableSet={processVariableSet} libraryVariableSets={libraryVariableSets} gitRef={gitRef} allowClear={allowClear} refreshVariables={refreshVariables} variableTypes={variableTypes} controlTypes={controlTypes} {...formProps}/>
            {defaultValueIndicator}
        </React.Fragment>);
};
VariableInput.displayName = "VariableInput"
export function CertificateVariableInput(props: BaseVariableInputProps) {
    return <VariableInput {...props} variableTypes={[VariableType.Certificate]} controlTypes={[ControlType.Certificate]}/>;
}
export function AwsAccountVariableInput(props: BaseVariableInputProps) {
    return <VariableInput {...props} variableTypes={[VariableType.AmazonWebServicesAccount]} controlTypes={[ControlType.AmazonWebServicesAccount]} showBoundField={true}/>;
}
export function AzureAccountVariableInput(props: BaseVariableInputProps) {
    return <VariableInput {...props} variableTypes={[VariableType.AzureAccount]} controlTypes={[ControlType.AzureAccount]} showBoundField={true}/>;
}
export function GoogleCloudAccountVariableInput(props: BaseVariableInputProps) {
    return <VariableInput {...props} variableTypes={[VariableType.GoogleCloudAccount]} controlTypes={[ControlType.GoogleCloudAccount]} showBoundField={true}/>;
}
export function UsernamePasswordAccountVariableInput(props: BaseVariableInputProps) {
    return <VariableInput {...props} variableTypes={[VariableType.UsernamePasswordAccount]} controlTypes={[ControlType.UsernamePasswordAccount]} showBoundField={true}/>;
}
export function GenericOidcAccountVariableInput(props: BaseVariableInputProps) {
    return <VariableInput {...props} variableTypes={[VariableType.GenericOidcAccount]} controlTypes={[ControlType.GenericOidcAccount]} showBoundField={true}/>;
}
export function WorkerPoolVariableInput(props: BaseVariableInputProps) {
    return <VariableInput {...props} variableTypes={[VariableType.WorkerPool]} controlTypes={[ControlType.WorkerPool]}/>;
}
