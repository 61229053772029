import { css } from "@emotion/css";
import { Level2PageLayout, Tooltip } from "@octopusdeploy/design-system-components";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ActionTemplateResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import type { AnyAction } from "redux";
import type { ThunkDispatch } from "redux-thunk";
import { repository } from "~/clientInstance";
import { useLegacyDoBusyTask } from "~/components/DataBaseComponent/useLegacyDoBusyTask";
import { SkeletonLoadingLayout } from "~/components/SkeletonLoadingLayout/SkeletonLoadingLayout";
import { useCreatePageSideNavLink } from "~/hooks/useCreatePageSideNavLink";
import type { GlobalState } from "~/store/reducers";
import { actionTemplateFetch } from "../../../reducers/libraryArea";
interface ActionTemplateLayoutProps {
    spaceId: string;
    templateId: string;
    children: React.ReactNode;
}
export default function ActionTemplateLayout({ spaceId, templateId, children }: ActionTemplateLayoutProps) {
    const { doBusyTask, status: legacyDoBusyTaskStatus } = useLegacyDoBusyTask();
    const dispatch: ThunkDispatch<GlobalState, void, AnyAction> = useDispatch();
    const actionTemplate = useSelector((state: GlobalState) => {
        const { currentActionTemplate } = state.libraryArea;
        // The current action template in redux state may not be the one we want.
        // Checking the id matches will prevent the incorrect template being rendered.
        // Ideally we should clear this redux state at an appropriate time or better yet, remove the redux dependency here.
        return currentActionTemplate?.id === templateId ? currentActionTemplate : undefined;
    });
    const dispatchFetchActionTemplate = React.useCallback((actionTemplate: ActionTemplateResource) => {
        dispatch(actionTemplateFetch(actionTemplate));
    }, [dispatch]);
    React.useEffect(() => {
        doBusyTask(async () => {
            const actionTemplate = await repository.ActionTemplates.get(templateId);
            dispatchFetchActionTemplate(actionTemplate);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const createPageSideNavLink = useCreatePageSideNavLink();
    const navLinks = [
        createPageSideNavLink("Details", links.editStepTemplatePage.generateUrl({ spaceId, templateId }), true),
        createPageSideNavLink(<UsageNavbarItemLabel totalPendingUpdates={actionTemplate?.pendingUpdates}/>, links.stepTemplateUsagePage.generateUrl({ spaceId, templateId })),
    ];
    if (!actionTemplate?.name) {
        return (<main>
                <SkeletonLoadingLayout errors={legacyDoBusyTaskStatus.errors}/>
            </main>);
    }
    return (<main>
            <Level2PageLayout navItems={navLinks} header={{
            title: actionTemplate.name || "New step template",
            breadcrumbs: [{ label: "Step Templates", pageUrl: links.stepTemplatesPage.generateUrl({ spaceId }) }],
            logo: actionTemplate.logo ? { href: actionTemplate.logo, accessibleName: "Logo" } : undefined,
        }}>
                {children}
            </Level2PageLayout>
        </main>);
}
interface UsageWithPendingUpdatesLabelProps {
    totalPendingUpdates: number | undefined;
}
function UsageNavbarItemLabel({ totalPendingUpdates = 0 }: UsageWithPendingUpdatesLabelProps) {
    return (<div className={usageWithPendingUpdatesLabelStyles}>
            Usage
            {totalPendingUpdates > 0 && <PendingUsages totalPendingUpdates={totalPendingUpdates}/>}
        </div>);
}
function PendingUsages({ totalPendingUpdates }: React.PropsWithChildren<{
    totalPendingUpdates: number;
}>) {
    const tooltipContent = `${totalPendingUpdates} action ${pluralize(totalPendingUpdates, "template usage has", "template usages have")} pending changes.`;
    return (<Tooltip content={tooltipContent}>
            <em className={"fa-solid fa-triangle-exclamation"} style={{ color: themeTokens.color.callout.text.warning }}/>
        </Tooltip>);
}
function pluralize(count: number, singular: string, plural: string) {
    return count > 1 ? plural : singular;
}
const usageWithPendingUpdatesLabelStyles = css({
    display: "flex",
    flexDirection: "row",
    gap: space[8],
    alignItems: "center",
});
