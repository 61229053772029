import { createInstance, Identify } from "@amplitude/analytics-browser";
import type { BrowserClient, DestinationPlugin, Event as AnalyticsEvent } from "@amplitude/analytics-types";
import { logger } from "@octopusdeploy/logging";
import { getResolver } from "@octopusdeploy/octopus-server-client";
import type { AnalyticSession } from "@octopusdeploy/portal-analytics";
import URI from "urijs";
import { anonymizeString } from "~/analytics/anonymizeString";
import type { ThemePaletteType } from "~/theme/index";
import { resolveApplicationRootUri } from "~/utils/resolveApplicationRootUri";
export class AmplitudeSession implements AnalyticSession {
    public static async create(installationId: string, userId: string, initialTheme: ThemePaletteType, onAnalyticsEventSent: (event: AnalyticsEvent) => void): Promise<AmplitudeSession> {
        const amplitudeInstance: BrowserClient = createInstance();
        amplitudeInstance.add(createDevToolsPlugin(onAnalyticsEventSent));
        // The real API token is injected in the Telemetry Proxy before forwarding to Amplitude, but Amplitude.JS requires one anyway.
        await amplitudeInstance.init("dummy-token", {
            defaultTracking: false,
            serverUrl: resolveTelemetryEndpointFrom(window.location),
        }).promise;
        window.amplitude = amplitudeInstance;
        return new AmplitudeSession(amplitudeInstance, installationId, userId, initialTheme);
    }
    private constructor(private readonly amplitudeInstance: BrowserClient, private readonly installationId: string, userId: string, initialTheme: ThemePaletteType) {
        amplitudeInstance.setUserId(userId);
        this.setTheme(initialTheme);
    }
    track<EventProperties extends {}>(name: string, event: EventProperties): void {
        this.amplitudeInstance.track(name, { ...event });
    }
    end() {
        try {
            this.amplitudeInstance.reset();
        }
        catch (err) {
            logger.error(err, "(Amplitude) error ending session");
        }
    }
    setTheme(theme: ThemePaletteType) {
        const identify = new Identify();
        identify.set("Theme", theme);
        this.amplitudeInstance.identify(identify);
    }
    anonymize(value: string): string {
        return anonymizeString(`${this.installationId}-${value}`);
    }
}
function createDevToolsPlugin(onAnalyticsEventSent: (event: AnalyticsEvent) => void): DestinationPlugin {
    return {
        type: "destination",
        name: "octopus-dev-tools",
        execute(event) {
            onAnalyticsEventSent(event);
            return Promise.resolve({
                code: 200,
                event,
                message: "Event forwarded to dev tools",
            });
        },
    };
}
export function resolveTelemetryEndpointFrom(location: Location | string) {
    const baseUri = resolveApplicationRootUri(location);
    const endpointUri = new URI(getResolver(`${baseUri}`)("~/api/telemetry/process"));
    return `${endpointUri.protocol()}://${endpointUri.host()}/${endpointUri.segment().join("/")}`;
}
