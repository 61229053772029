import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import ExternalVideoLink from "~/components/Navigation/ExternalLink/ExternalVideoLink";
import OnboardingPage from "../../../../components/GettingStarted/OnboardingPage";
const Onboarding = (props: {
    actionButtons: React.ReactNode;
}) => {
    return (<OnboardingPage title="Add environments to group the deployment targets you will be deploying to" intro={<span>Environments group deployment targets so your software can be promoted through your deployment lifecycle. For instance, from Development to Test and finally into Production.</span>} learnMore={<>
                    <ExternalLink href="OnboardingEnvironmentsLearnMore">Learn more</ExternalLink>
                    <ExternalVideoLink href="OnboardingEnvironmentsVideo" hideVideoIcon={true}>
                        Video (2 mins)
                    </ExternalVideoLink>
                </>} actionButtons={props.actionButtons}/>);
};
export default Onboarding;
