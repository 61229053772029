import { css } from "@emotion/css";
import { Checkbox, PopoverBasicHelp } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { HelmChartUpgradeProperties } from "@octopusdeploy/legacy-action-properties";
import type { KubernetesStatusCheckProperties } from "@octopusdeploy/legacy-action-properties/dist/src/KubernetesStatusCheckComponentProperties";
import React, { useState } from "react";
import type { FullNameOf, ShortNameOf } from "~/components/Actions/kubernetes/kubernetesStatusCheckComponent";
import type { ActionEditProps } from "~/components/Actions/pluginRegistry";
import Chip from "~/components/Chips/Chip";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import { withTheme } from "~/components/Theme/index";
import { ExpandableFormSection, Note, Summary } from "~/components/form/index";
type HelmStepVerificationFormSectionProps = Pick<ActionEditProps<HelmChartUpgradeProperties>, "properties" | "setProperties" | "expandedByDefault">;
const waitForJobsStyle = css({
    marginLeft: space[32],
});
const verificationContainerStyle = css({
    marginTop: space[16],
});
const bulletStyle = css({
    listStyleType: "disc",
});
const RecommendedOptionChip = () => withTheme((theme) => (<Chip noTooltip={true} backgroundColor={theme.successBackground} labelColor={theme.successText}>
            Recommended
        </Chip>));
const waitForJobsHelp = (<>
        <div>Octopus will wait until Jobs have been completed before marking the step as successful.</div>
        <div>
            It will wait for as long as the <code>--timeout</code>
        </div>
    </>);
const waitForJobsHelpLink = <ExternalLink href="HelmUpgradeOptions">Helm docs</ExternalLink>;
const stepVerificationHelp = (<>
        <div>
            The Helm <code>--wait</code> flag waits until the following conditions are true to mark the step as successful:
        </div>
        <ul className={bulletStyle}>
            <li>All Pods are in the ready state</li>
            <li>PVCs are bound</li>
            <li>Deployments have minimum Pods in the ready state</li>
            <li>Services have an IP address (and Ingress if a LoadBalancer)</li>
        </ul>
        <div>
            It will wait for as long as the <code>--timeout</code>
        </div>
    </>);
const stepVerificationHelpLink = <ExternalLink href="HelmUpgradeOptions">Helm docs</ExternalLink>;
const stepVerificationKosHelpLink = <ExternalLink href="KOS">Octopus docs</ExternalLink>;
type FullNames = keyof KubernetesStatusCheckProperties;
type ShortNames = {
    [key in FullNames]: ShortNameOf<key>;
}[FullNames];
const toFullName = <T extends ShortNames>(shortName: T): FullNameOf<T> => `Octopus.Action.Kubernetes.${shortName}`;
export const HelmStepVerificationFormSection = (props: HelmStepVerificationFormSectionProps) => {
    const getKubernetesVariable = <T extends ShortNames>(shortName: T): HelmChartUpgradeProperties[FullNameOf<T>] => props.properties[toFullName(shortName)];
    const setKubernetesVariable = <T extends ShortNames>(shortName: T, value: HelmChartUpgradeProperties[FullNameOf<T>]) => props.setProperties({ [toFullName(shortName)]: value });
    const getDefaultVerificationState = () => {
        if (getKubernetesVariable("ResourceStatusCheck") === "True") {
            return true;
        }
        if (getKubernetesVariable("ResourceStatusCheck") === "False") {
            return false;
        }
        //if we don't have a value, it's either a new step or an older step being upgraded, check additional args
        const additionalArgs = props.properties["Octopus.Action.Helm.AdditionalArgs"];
        //if we have additional args with atomic or wait, let's assume they want validation checking
        if (additionalArgs && (additionalArgs.indexOf("--atomic") >= 0 || additionalArgs.search(/(--wait)(?!-)/) >= 0)) {
            setKubernetesVariable("ResourceStatusCheck", "True");
            //if they are already using wait for jobs, also set that
            if (additionalArgs.indexOf("--wait-for-jobs") >= 0) {
                setKubernetesVariable("WaitForJobs", "True");
            }
            return true;
        }
        return false;
    };
    const [isVerificationEnabled, setIsVerificationEnabled] = useState<boolean>(getDefaultVerificationState());
    const [isWaitForJobsEnabled, setIsWaitForJobsEnabled] = useState<boolean>(getKubernetesVariable("WaitForJobs") === "True");
    const setIsEnabled = (isEnabled: boolean) => {
        setKubernetesVariable("ResourceStatusCheck", isEnabled ? "True" : "False");
        setIsVerificationEnabled(isEnabled);
    };
    const setWaitForJobsEnabled = (isEnabled: boolean) => {
        setKubernetesVariable("WaitForJobs", isEnabled ? "True" : "False");
        setIsWaitForJobsEnabled(isEnabled);
    };
    const getSummary = () => {
        const summary = isVerificationEnabled ? Summary.default : Summary.summary;
        return summary(<span>{isVerificationEnabled ? "Check that Kubernetes objects are running successfully" : "Don't do any verification checks"}</span>);
    };
    return (<ExpandableFormSection title={"Step Verification"} summary={getSummary()} errorKey="Octopus.Action.Kubernetes.ResourceStatusCheck" help={<span>Choose how Octopus will determine if your step was successful.</span>} contextualHelp={<PopoverBasicHelp placement={"right-start"} description={stepVerificationHelp} link1={stepVerificationKosHelpLink} link2={stepVerificationHelpLink}/>} isExpandedByDefault={props.expandedByDefault}>
            <div className={verificationContainerStyle}>
                <Checkbox value={isVerificationEnabled} onChange={(x) => setIsEnabled(x)} noMargin={true} label={<>
                            <span>
                                Use Helm <code>--wait</code> to determine success
                                <RecommendedOptionChip />
                            </span>
                        </>}/>
            </div>
            <div className={waitForJobsStyle}>
                <Note>When enabled, also gives real-time status updates of the Kubernetes objects while this step is running.</Note>
                {isVerificationEnabled && (<div>
                        <Checkbox value={isWaitForJobsEnabled} onChange={(x) => setWaitForJobsEnabled(x)} label={<>
                                    <span>
                                        Enable <code>--wait-for-jobs</code>
                                    </span>
                                    &nbsp;
                                    <PopoverBasicHelp placement="right-start" description={waitForJobsHelp} link1={waitForJobsHelpLink}/>
                                </>}/>
                    </div>)}
            </div>
        </ExpandableFormSection>);
};
