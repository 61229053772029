/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CircularProgress } from "@octopusdeploy/design-system-components";
import type { WorkerPoolResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { repository } from "~/clientInstance";
import OpenDialogIconButton from "~/components/Dialog/OpenDialogIconButton";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip";
import InputWithActions from "~/components/InputWithActions/InputWithActions";
import type { SpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/SpaceAwareNavigation";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import type { OctopusTheme } from "~/components/Theme";
import { withTheme } from "~/components/Theme";
import type FormFieldProps from "~/components/form/FormFieldProps";
import { WorkerPoolIcon } from "~/primitiveComponents/dataDisplay/Icon";
import Select from "~/primitiveComponents/form/Select/Select";
import WorkerPoolSelector from "./WorkerPoolSelector";
import styles from "./style.module.less";
interface WorkerPoolSelectProps extends FormFieldProps<string> {
    allowClear?: boolean;
    disabled?: boolean;
    label?: string | JSX.Element;
    error?: string;
    warning?: string;
    hideSearch?: boolean;
    validate?(value: string): string;
    onValidate?(value: string): void;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
    items: () => Promise<WorkerPoolResource[]>;
    onRequestRefresh(): Promise<boolean>;
}
interface WorkerPoolSelectInternalProps extends WorkerPoolSelectProps {
    spaceAwareNavigation: SpaceAwareNavigation;
}
interface TextState {
    error?: string;
    showSearchDialog: boolean;
    pools: WorkerPoolResource[];
    isDataLoaded: boolean;
    busy: boolean; //TODO: move busy back out into props and use a HOC/Render prop component to manage this state
}
function WorkerPoolSelect(props: WorkerPoolSelectProps) {
    const navigation = useSpaceAwareNavigation();
    return <WorkerPoolSelectInternal spaceAwareNavigation={navigation} {...props}/>;
}
const toggleBusy = (value?: boolean) => (prev: TextState, props: WorkerPoolSelectInternalProps) => ({ ...prev, busy: value ? value : !prev.busy });
class WorkerPoolSelectInternal extends React.Component<WorkerPoolSelectInternalProps, TextState> {
    constructor(props: WorkerPoolSelectInternalProps) {
        super(props);
        this.state = {
            error: null!,
            showSearchDialog: false,
            pools: [],
            isDataLoaded: false,
            busy: false,
        };
    }
    async componentDidMount() {
        await this.loadData();
    }
    async loadData() {
        try {
            this.setState(toggleBusy(true));
            await this.props.doBusyTask(async () => {
                const pools = await this.props.items();
                this.setState({ pools, isDataLoaded: true });
            });
        }
        finally {
            this.setState(toggleBusy(false));
        }
    }
    handleChange = (poolId: string | undefined) => {
        const value = poolId === "" ? null : poolId;
        if (this.props.validate) {
            const result = this.props.validate(value!);
            this.setState({ error: result });
            if (this.props.onValidate) {
                this.props.onValidate(result);
            }
        }
        this.props.onChange!(value!);
    };
    getItems(theme: OctopusTheme) {
        return this.state.pools.map((pool) => {
            return {
                value: pool.Id,
                text: pool.Name,
                icon: <WorkerPoolIcon />,
            };
        });
    }
    selectionRenderer = (poolId: string) => {
        const pool = this.state.pools.find((p) => p.Id === poolId);
        if (!pool) {
            return poolId;
        }
        return (<div>
                <span className={styles.selectedIcon}>
                    <WorkerPoolIcon />
                </span>
                {pool.Name}
            </div>);
    };
    render() {
        if (!this.state.isDataLoaded) {
            return <CircularProgress size="small"/>;
        }
        const { onChange, onValidate, doBusyTask, onRequestRefresh, hideSearch, ...otherProps } = this.props;
        return withTheme((theme) => (<InputWithActions input={<Select label="Select worker pool" {...otherProps} allowFilter={true} onChange={this.handleChange} items={this.getItems(theme)} selectionRenderer={this.selectionRenderer} sortItems={false}/>} busy={this.state.busy} actions={<>
                        <SearchDialogButton selectedPoolId={this.props.value} onSelect={(pool) => this.handleChange(pool.Id)} hide={hideSearch}/>
                        <IconButtonWithTooltip disabled={this.state.busy} onClick={this.refreshData} toolTipContent={"Refresh"} icon={"ArrowRefreshIcon"} accessibleName={"Refresh"}/>
                        <IconButtonWithTooltip toolTipContent={"Add"} onClick={this.goToWorkerPools} icon={"PlusIcon"} accessibleName={"Add"}/>
                    </>}/>));
    }
    private goToWorkerPools = () => {
        this.props.spaceAwareNavigation.open(links.workerPoolsPage.generateUrl({ spaceId: repository.spaceId! }));
    };
    private refreshData = async () => {
        try {
            this.setState({ busy: true });
            await this.props.doBusyTask(async () => {
                await this.props.onRequestRefresh();
                const pools = await this.props.items();
                this.setState({ pools });
            });
        }
        finally {
            this.setState({ busy: false });
        }
    };
    static displayName = "WorkerPoolSelectInternal";
}
interface SearchDialogButtonProps {
    selectedPoolId: string;
    onSelect: (pool: WorkerPoolResource) => void;
    hide?: boolean;
}
function SearchDialogButton({ selectedPoolId, onSelect, hide }: SearchDialogButtonProps) {
    if (hide) {
        return null;
    }
    return (<OpenDialogIconButton toolTipContent="Search" wideDialog={true} icon={"MagnifyingGlassIcon"} accessibleName={"Search"}>
            <WorkerPoolSelector onSelected={onSelect} selectedWorkerPoolId={selectedPoolId}/>
        </OpenDialogIconButton>);
}
export default WorkerPoolSelect;
