/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import { Callout } from "@octopusdeploy/design-system-components";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ActionTemplateResource, ActionTemplateUsageResource, ActionUpdateResultResource, ActionProperties, PackageReference, ActionTemplateParameterResource, ActionUpdateRemovedPackageUsage, ActionsUpdateProcessResource, } from "@octopusdeploy/octopus-server-client";
import { isResourceControlType, ActionUpdateOutcome, ActionUpdatePackageUsedBy, ControlType, ProcessType } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { keyBy, uniq, flatMap, reduce, groupBy } from "lodash";
import pluralize from "pluralize";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { PackageSourceItems } from "~/components/ActionTemplateParameterInput/ActionTemplateParameterInput";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import SimpleDataTable from "~/components/SimpleDataTable";
import ExpansionButtons from "~/components/form/Sections/ExpansionButtons";
import { FormSection } from "~/components/form/index";
import { ControlledTabsContainer } from "~/primitiveComponents/navigation/Tabs";
import TabItem from "~/primitiveComponents/navigation/Tabs/TabItem";
import ActionTemplateParameterInputExpandableFormElement from "../../../../components/ActionTemplateParameterInput/ActionTemplateParameterInputExpandableFormElement";
import { ActionTemplateUsageProcessLink, ActionTemplateUsageStepLink } from "./ActionTemplateUsageLinks";
import styles from "./style.module.less";
type Tab = "auto" | "defaults" | "manual" | "packages";
type Default = ActionTemplateUsageResource & {
    NamesOfNewParametersMissingDefaultValue: string[];
};
type Manual = ActionTemplateUsageResource & {
    ManualMergeRequiredReasonsByPropertyName: Record<string, string[]>;
};
type Packages = ActionTemplateUsageResource & {
    RemovedPackageUsages: ActionUpdateRemovedPackageUsage[];
};
interface MergeConflictResolutionDialogState extends DataBaseComponentState {
    showAutoDetails: boolean;
    showDefaultsDetails: boolean;
    showManualDetails: boolean;
    showPackagesDetails: boolean;
    auto: ActionTemplateUsageResource[];
    defaults: Default[];
    manual: Manual[];
    packages: Packages[];
    defaultPropertyValues: ActionProperties;
    appliesToSingleStep: boolean;
    newParametersMissingDefaultValue: ActionTemplateParameterResource[];
    tab: Tab;
    sourceItems: PackageSourceItems;
    redirectTo: string;
}
interface MergeConflictResolutionDialogProps {
    usages: ActionTemplateUsageResource[];
    mergeResults: ActionUpdateResultResource[];
    actionTemplate: ActionTemplateResource;
}
export default class MergeConflictResolutionDialog extends DataBaseComponent<MergeConflictResolutionDialogProps, MergeConflictResolutionDialogState> {
    constructor(props: MergeConflictResolutionDialogProps) {
        super(props);
        const mergeResultsByActionId = keyBy(props.mergeResults, "Id");
        const auto = props.usages.filter((usage) => mergeResultsByActionId[usage.ActionId].Outcome === ActionUpdateOutcome.Success);
        const defaults = props.usages
            .filter((usage) => mergeResultsByActionId[usage.ActionId].Outcome === ActionUpdateOutcome.DefaultParamterValueMissing)
            .map((usage) => {
            return {
                ...usage,
                NamesOfNewParametersMissingDefaultValue: mergeResultsByActionId[usage.ActionId].NamesOfNewParametersMissingDefaultValue,
            };
        });
        const namesOfNewParameterMissingDefaultValue = uniq<string>(flatMap(defaults.map((d) => d.NamesOfNewParametersMissingDefaultValue)));
        const newParametersMissingDefaultValue = props.actionTemplate.Parameters.filter((p) => namesOfNewParameterMissingDefaultValue.indexOf(p.Name) !== -1);
        const defaultPropertyValues: ActionProperties = newParametersMissingDefaultValue.reduce((acc: Record<string, string>, p) => {
            const type = p.DisplaySettings["Octopus.ControlType"];
            const name = p.Name;
            if (type === ControlType.Package && props.actionTemplate.Packages[0]) {
                acc[name] = props.actionTemplate.Packages[0].PackageId;
            }
            else {
                acc[name] = null!;
            }
            return acc;
        }, {});
        const manual = props.usages
            .filter((usage) => mergeResultsByActionId[usage.ActionId].Outcome === ActionUpdateOutcome.ManualMergeRequired)
            .map((usage) => {
            return {
                ...usage,
                ManualMergeRequiredReasonsByPropertyName: mergeResultsByActionId[usage.ActionId].ManualMergeRequiredReasonsByPropertyName,
            };
        });
        const packages = props.usages
            .filter((usage) => mergeResultsByActionId[usage.ActionId].Outcome === ActionUpdateOutcome.RemovedPackageInUse)
            .map((usage) => {
            return {
                ...usage,
                RemovedPackageUsages: mergeResultsByActionId[usage.ActionId].RemovedPackageUsages,
            };
        });
        const appliesToSingleStep = auto.length + manual.length + defaults.length + packages.length === 1;
        const tab = auto.length ? "auto" : defaults.length ? "defaults" : manual.length ? "manual" : "packages";
        this.state = {
            redirectTo: null!,
            showAutoDetails: false,
            showDefaultsDetails: false,
            showManualDetails: false,
            showPackagesDetails: false,
            auto,
            defaults,
            manual,
            packages,
            defaultPropertyValues,
            appliesToSingleStep,
            newParametersMissingDefaultValue,
            tab,
            sourceItems: {
                items: props.actionTemplate.Packages || [],
                feeds: [],
                onRequestRefresh: this.refreshFeeds,
                setPackages: this.setPackages,
            },
        };
    }
    setPackages = (packages: PackageReference[]) => {
        this.setState({
            sourceItems: {
                ...this.state.sourceItems,
                items: packages,
            },
        });
    };
    refreshFeeds = async () => {
        await this.doBusyTask(async () => {
            const feedsGetter = repository.Feeds.all();
            try {
                const feeds = await feedsGetter;
                this.setState({
                    sourceItems: {
                        ...this.state.sourceItems,
                        feeds,
                    },
                });
            }
            catch (error) {
                if (error.StatusCode !== 404) {
                    throw error;
                }
            }
        });
    };
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const feedsGetter = repository.Feeds.all();
            try {
                const feeds = await feedsGetter;
                this.setState({
                    sourceItems: {
                        ...this.state.sourceItems,
                        feeds,
                    },
                });
            }
            catch (error) {
                if (error.StatusCode !== 404) {
                    throw error;
                }
            }
        });
    }
    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={false}/>;
        }
        return (<SaveDialogLayout onSaveClick={this.onSave} hideSave={this.state.tab === "manual" || this.state.tab === "packages"} title={this.state.appliesToSingleStep ? "Update step" : "Update all projects"} saveButtonLabel={this.state.appliesToSingleStep ? "Update" : "Update all"} errors={this.errors} busy={this.state.busy}>
                <Callout type={"information"} title={`${this.state.appliesToSingleStep ? "The step" : "Some steps"} couldn't be automatically updated`}>
                    See <ExternalLink href="StepTemplateUpdate">our documentation</ExternalLink> for more information regarding possible reasons why the update couldn't be done automatically.
                </Callout>
                {this.isOnlyOneTab() ? this.renderWithoutTabs() : this.renderWithTabs()}
            </SaveDialogLayout>);
    }
    private renderWithTabs() {
        return (
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        <ControlledTabsContainer value={this.state.tab} onChange={(value) => this.setState({ tab: value as Tab })}>
                {this.state.auto.length ? (<TabItem label={`No changes required (${this.state.auto.length})`} value="auto">
                        {this.renderAuto()}
                    </TabItem>) : null}
                {this.state.defaults.length ? (<TabItem label={`New values required (${this.state.defaults.length})`} value="defaults">
                        {this.renderDefaults()}
                    </TabItem>) : null}
                {this.state.manual.length ? (<TabItem label={`Manual update required (${this.state.manual.length})`} value="manual" warning={"Usages can't be automatically updated"}>
                        {this.renderManual()}
                    </TabItem>) : null}
                {this.state.packages.length ? (<TabItem label={`Package conflicts (${this.state.packages.length})`} value="packages" warning={"Usages can't be automatically updated"}>
                        {this.renderPackages()}
                    </TabItem>) : null}
            </ControlledTabsContainer>);
    }
    private renderWithoutTabs() {
        return (<>
                {this.renderAuto()}
                {this.renderDefaults()}
                {this.renderManual()}
                {this.renderPackages()}
            </>);
    }
    private renderAuto() {
        if (this.state.auto.length === 0) {
            return null;
        }
        return (<div className={newStyles.tabContainer}>
                <div>The following {this.state.auto.length === 1 ? "usage doesn't" : "usages don't"} have any changes that require review.</div>
                <SimpleDataTable data={this.state.auto} headerColumns={["Project", "Process", "Step", "Version"]} onRow={(u: ActionTemplateUsageResource) => [
                <InternalLink to={links.projectRootRedirect.generateUrl({ spaceId: this.props.actionTemplate.SpaceId, projectSlug: u.ProjectSlug })} openInSelf={false}>
                            {u.ProjectName}
                        </InternalLink>,
                <ActionTemplateUsageProcessLink spaceId={this.props.actionTemplate.SpaceId} usage={u}/>,
                <ActionTemplateUsageStepLink spaceId={this.props.actionTemplate.SpaceId} usage={u}/>,
                u.Version,
            ]}/>
            </div>);
    }
    private renderDefaults() {
        if (this.state.defaults.length === 0) {
            return null;
        }
        return (<div className={newStyles.tabContainer}>
                <div>
                    The following {pluralize("usage", this.state.defaults.length)} will have new parameters after updating. You can optionally provide a default value for any new parameters that support them. If you want to provide different values
                    for each step, please update each step manually.
                </div>
                <div className={newStyles.defaultsHeadings}>Parameters</div>
                <div>
                    <ExpansionButtons />
                    {this.state.newParametersMissingDefaultValue.map((param) => {
                if (isResourceControlType(param.DisplaySettings["Octopus.ControlType"])) {
                    return (<FormSection title={<span className={newStyles.preventedDefaultValueParameter}>{param.Name}</span>} help={<span className={newStyles.preventedDefaultValueParameter}>This parameter type doesn't accept default values</span>}></FormSection>);
                }
                return (<ActionTemplateParameterInputExpandableFormElement parameter={param} key={param.Name} sourceItems={this.state.sourceItems} doBusyTask={this.doBusyTask} value={this.state.defaultPropertyValues[param.Name]} onChange={(x) => this.setState((state) => ({ defaultPropertyValues: { ...state.defaultPropertyValues, [param.Name]: x } }))} customHelpText={(label) => `Provide a default value for ${label}`} actionType={this.props.actionTemplate.ActionType}/>);
            })}
                </div>
                <div className={newStyles.defaultsHeadings}>Steps</div>
                <SimpleDataTable data={this.state.defaults} headerColumns={["Project", "Process", "Step", "Parameters", "Version"]} onRow={(u: Default) => [
                <InternalLink to={links.projectRootRedirect.generateUrl({ spaceId: this.props.actionTemplate.SpaceId, projectSlug: u.ProjectSlug })} openInSelf={false}>
                            {u.ProjectName}
                        </InternalLink>,
                <ActionTemplateUsageProcessLink spaceId={this.props.actionTemplate.SpaceId} usage={u}/>,
                <ActionTemplateUsageStepLink spaceId={this.props.actionTemplate.SpaceId} usage={u}/>,
                <ul className={styles.reasons}>
                            {u.NamesOfNewParametersMissingDefaultValue.map((nameOfNewParameterMissingDefaultValue) => (<li key={nameOfNewParameterMissingDefaultValue}>{nameOfNewParameterMissingDefaultValue}</li>))}
                        </ul>,
                u.Version,
            ]}/>
            </div>);
    }
    private renderPackages() {
        if (this.state.packages.length === 0) {
            return null;
        }
        return (<div className={newStyles.tabContainer}>
                <div>
                    {this.state.packages.length === 1 ? "This step is" : "These steps are"} in a project that references a package on the step which has been removed in the latest version of the step template. These references must be removed before
                    the step can be updated.
                </div>
                <SimpleDataTable data={this.state.packages} headerColumns={["Project", "Process", "Step", "Referenced By", "Version"]} onRow={(u: Packages) => [
                <InternalLink to={links.projectRootRedirect.generateUrl({ spaceId: this.props.actionTemplate.SpaceId, projectSlug: u.ProjectSlug })} openInSelf={false}>
                            {u.ProjectName}
                        </InternalLink>,
                <ActionTemplateUsageProcessLink spaceId={this.props.actionTemplate.SpaceId} usage={u}/>,
                <ActionTemplateUsageStepLink spaceId={this.props.actionTemplate.SpaceId} usage={u}/>,
                <ul className={styles.reasons}>
                            {u.RemovedPackageUsages.map((pkg, index) => (<li key={`packageConflicts-${index}`}>{this.renderRemovedPackageUsageReason(pkg, u.ProjectSlug)}</li>))}
                        </ul>,
                u.Version,
            ]}/>
            </div>);
    }
    private renderManual() {
        if (this.state.manual.length === 0) {
            return null;
        }
        return (<div className={newStyles.tabContainer}>
                <div>
                    {this.state.manual.length === 1 ? "This step" : "These steps"} must be updated manually because we don't have enough information to update {this.state.manual.length === 1 ? "it" : "them"} automatically.
                </div>
                <SimpleDataTable data={this.state.manual} headerColumns={["Project", "Process", "Step", "Reasons", "Version", ""]} onRow={(u: Manual) => [
                <InternalLink to={links.projectRootRedirect.generateUrl({ spaceId: this.props.actionTemplate.SpaceId, projectSlug: u.ProjectSlug })} openInSelf={false}>
                            {u.ProjectName}
                        </InternalLink>,
                <ActionTemplateUsageProcessLink spaceId={this.props.actionTemplate.SpaceId} usage={u}/>,
                <ActionTemplateUsageStepLink spaceId={this.props.actionTemplate.SpaceId} usage={u}/>,
                <ul className={styles.reasons}>
                            {Object.keys(u.ManualMergeRequiredReasonsByPropertyName).map((property, index) => (<li key={`manualMerge-${index}`}>
                                    {property && `${property}: `}
                                    {u.ManualMergeRequiredReasonsByPropertyName[property].join()}
                                </li>))}
                        </ul>,
                u.Version,
                <InternalLink to={u.ProcessType === ProcessType.Runbook
                        ? links.projectRunbookProcessStepsPage.generateUrl({ spaceId: this.props.actionTemplate.SpaceId, projectSlug: u.ProjectSlug, processId: u.ProcessId, runbookId: u.RunbookId }, { actionId: u.ActionId })
                        : links.deploymentProcessStepsPage.generateUrl({ spaceId: this.props.actionTemplate.SpaceId, projectSlug: u.ProjectSlug }, { actionId: u.ActionId })} openInSelf={false}>
                            Update
                        </InternalLink>,
            ]}/>
            </div>);
    }
    private onSave = async () => {
        return this.doBusyTask(async () => {
            const usages = [...this.state.auto, ...this.state.defaults];
            const usagesByProcessId = groupBy(usages, (x) => x.ProcessId);
            const initialUpdateValue: ActionsUpdateProcessResource = null!; //needed since our ts rules don't allow us to specify the seed value type inline
            const updates = Object.keys(usagesByProcessId).reduce((prev: ActionsUpdateProcessResource[], processId) => {
                return [
                    ...prev,
                    reduce(usagesByProcessId[processId], (update, usage) => {
                        return !update ? { ProcessType: usage.ProcessType, ProcessId: usage.ProcessId, ProjectId: usage.ProjectId, ActionIds: [usage.ActionId] } : { ...update, ActionIds: [...update.ActionIds, usage.ActionId] };
                    }, initialUpdateValue),
                ];
            }, []);
            await repository.ActionTemplates.updateActions(this.props.actionTemplate, updates, this.state.defaultPropertyValues);
        });
    };
    private stepCount = () => {
        return this.state.auto.length + this.state.defaults.length + this.state.manual.length + this.state.packages.length;
    };
    private isOnlyOneTab = () => {
        const anyAuto = this.state.auto.length ? 1 : 0;
        const anyDefaults = this.state.defaults.length ? 1 : 0;
        const anyManual = this.state.manual.length ? 1 : 0;
        const anyPackages = this.state.packages.length ? 1 : 0;
        return anyAuto + anyDefaults + anyManual + anyPackages === 1;
    };
    private renderRemovedPackageUsageReason = (pkgUsage: ActionUpdateRemovedPackageUsage, projectSlug: string) => {
        switch (pkgUsage.UsedBy) {
            case ActionUpdatePackageUsedBy.ProjectVersionStrategy:
                return (<React.Fragment>
                        Versioning strategy in project{" "}
                        <InternalLink to={links.projectSettingsPage.generateUrl({ spaceId: this.props.actionTemplate.SpaceId, projectSlug })} openInSelf={false}>
                            {pkgUsage.UsedByName}
                        </InternalLink>
                    </React.Fragment>);
            case ActionUpdatePackageUsedBy.ProjectReleaseCreationStrategy:
                return (<React.Fragment>
                        Release creation strategy in project{" "}
                        <InternalLink to={links.deploymentTriggersPage.generateUrl({ spaceId: this.props.actionTemplate.SpaceId, projectSlug })} openInSelf={false}>
                            {pkgUsage.UsedByName}
                        </InternalLink>
                    </React.Fragment>);
            case ActionUpdatePackageUsedBy.ChannelRule:
                return (<React.Fragment>
                        Rule in channel{" "}
                        <InternalLink to={links.channelRedirect.generateUrl({ spaceId: this.props.actionTemplate.SpaceId, channelId: pkgUsage.UsedById })} openInSelf={false}>
                            {pkgUsage.UsedByName}
                        </InternalLink>
                    </React.Fragment>);
        }
    };
    static displayName = "MergeConflictResolutionDialog";
}
const newStyles = {
    defaultsHeadings: css({
        font: text.heading.small,
        paddingTop: space[8],
    }),
    tabContainer: css({
        paddingTop: space[12],
        display: "flex",
        flexDirection: "column",
        gap: space[8],
    }),
    preventedDefaultValueParameter: css({
        color: themeTokens.color.text.disabled,
    }),
};
