import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip/index";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
interface AddVariableIconButtonProps {
    projectSlugOrId: string;
    spaceId: string;
}
export function AddVariableIconButton({ spaceId, projectSlugOrId }: AddVariableIconButtonProps) {
    const navigate = useSpaceAwareNavigation();
    const navigateToAddProjectVariablePage = () => {
        const link = links.variablesPage.generateUrl({ spaceId, projectSlug: projectSlugOrId });
        navigate.open(link);
    };
    return <IconButtonWithTooltip toolTipContent={"Add"} icon={"PlusIcon"} accessibleName={"Add"} onClick={navigateToAddProjectVariablePage}/>;
}
