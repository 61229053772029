import { Button, Callout } from "@octopusdeploy/design-system-components";
import type { EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import React, { useState } from "react";
import { useProjectContext } from "~/areas/projects/context/index";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import DeleteDialogLayout from "~/components/Dialog/DeleteDialogLayout";
import Dialog from "~/components/Dialog/Dialog";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import { Text } from "~/components/form/index";
interface ClientIdentifierDialogProps {
    doBusyTask: DoBusyTask;
    busy: Promise<void> | undefined;
    errors: Errors | undefined;
    environments: EnvironmentResource[];
}
interface ManageClientIdentifierState {
    isRotationPromptDialogOpen: boolean;
    rotationConfirmationValue: string;
    rotationState: "Rotating" | "NotRotating";
}
export default ({ doBusyTask, busy, errors, environments }: ClientIdentifierDialogProps) => {
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    const [state, setState] = useState<ManageClientIdentifierState>({
        isRotationPromptDialogOpen: false,
        rotationConfirmationValue: "",
        rotationState: "NotRotating",
    });
    useDoBusyTaskEffect(doBusyTask, async () => {
        const rotationState = await repository.FeatureToggles.getClientIdentifierRotationState(project);
        setState((prevState) => ({ ...prevState, rotationState: rotationState.RotationState }));
    }, []);
    const showRotationPromptDialog = () => {
        setState((prevState) => ({ ...prevState, isRotationPromptDialogOpen: true }));
    };
    const confirmationOnChange = (value: string) => {
        setState((prevState) => ({ ...prevState, rotationConfirmationValue: value }));
    };
    const rotateClientIdentifiers = async () => {
        await doBusyTask(async () => {
            const completeRotation = state.rotationState === "Rotating";
            await repository.FeatureToggles.rotateClientIdentifierSigningKey(project, completeRotation);
            setState((prevState) => ({ ...prevState, rotationState: prevState.rotationState === "Rotating" ? "NotRotating" : "Rotating" }));
        });
    };
    const rotationDialog = (label: string, prompt: string) => {
        return (<>
                <Button label={label} onClick={() => showRotationPromptDialog()} importance="destructive"/>
                <Dialog open={state.isRotationPromptDialogOpen}>
                    <DeleteDialogLayout title={prompt} onClose={() => {
                setState((prevState) => ({ ...prevState, rotationConfirmationValue: "", isRotationPromptDialogOpen: false }));
            }} deleteButtonDisabled={state.rotationConfirmationValue !== "Rotate"} onDeleteClick={() => rotateClientIdentifiers()} deleteButtonLabel={label} renderContent={() => (<>
                                <Callout title="This is a destructive action" type={"danger"}>
                                    This action <strong>cannot</strong> be undone.
                                </Callout>
                                <div>
                                    <p>
                                        Please type in <strong>Rotate</strong> to confirm.
                                    </p>
                                    <Text value={state.rotationConfirmationValue} onChange={confirmationOnChange}/>
                                </div>
                            </>)}/>
                </Dialog>
            </>);
    };
    return (<OkDialogLayout title={"Rotate Client Identifiers"} busy={busy} errors={errors} onOkClick={() => { }} hideCancel={true}>
            <React.Fragment>
                {state.rotationState === "Rotating" && (<>
                        <Callout title="This is a destructive action" type={"warning"}>
                            You are part way through rotating your client identifiers. Instructions for performing a safe regeneration are available in our{" "}
                            <ExternalLink href={"https://oc.to/RotatingClientIdentifiers"}>Rotating Client Identifiers</ExternalLink> documentation.
                            <br />
                            <br />
                            Completing the rotation will invalidate your pre-existing client identifiers. Only do this once you have redeployed the newly rotated client identifier through all environments.
                        </Callout>
                        <br />
                        {rotationDialog("Complete Rotation", "Are you sure you want to complete rotating your client identifiers?")}
                    </>)}
                {state.rotationState === "NotRotating" && (<>
                        <Callout title="This is a destructive action" type={"warning"}>
                            Rotating your client identifiers is a two-step process. Instructions for performing a safe rotation are available in our{" "}
                            <ExternalLink href={"https://oc.to/RotatingClientIdentifiers"}>Rotating Client Identifiers</ExternalLink> documentation.
                            <br />
                            <br />
                            <b>You should only use this function if your client identifier has leaked and you need to rotate it to ensure your feature toggles remain secure.</b>
                        </Callout>
                        <br />
                        {rotationDialog("Rotate", "Are you sure you want to rotate your client identifiers?")}
                    </>)}
            </React.Fragment>
        </OkDialogLayout>);
};
