import type { ProjectResource, TenantVariableResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import ProjectTemplatesInlineContent from "~/areas/projects/components/Variables/TenantVariables/ProjectTemplatesInlineContent";
import type { TenantVariablesPageSaveAction } from "~/areas/projects/components/Variables/TenantVariables/ProjectTenantVariablesPageContent";
import ProjectTenantVariablesPageContent from "~/areas/projects/components/Variables/TenantVariables/ProjectTenantVariablesPageContent";
import { useProjectTemplatesState } from "~/areas/projects/components/Variables/TenantVariables/useProjectTemplatesState";
import type { ChangedProjectTemplateVariableValue, ProjectTenantVariableValueChangeTracker } from "~/areas/projects/components/Variables/TenantVariables/useProjectTenantVariableValueChangeTracker";
import { useProjectTenantVariableValueChangeTracker } from "~/areas/projects/components/Variables/TenantVariables/useProjectTenantVariableValueChangeTracker";
import { useTenantVariableFiltersData } from "~/areas/projects/components/Variables/TenantVariables/useTenantVariableFiltersData";
import { useTenantVariableResourceOptions } from "~/areas/projects/components/Variables/TenantVariables/useTenantVariableResourceOptions";
import { repository } from "~/clientInstance";
import { type DoBusyTask } from "~/components/DataBaseComponent/index";
import { useLegacyDoBusyTask } from "~/components/DataBaseComponent/useLegacyDoBusyTask";
interface ProjectTemplatesPageInternalProps {
    project: ProjectResource;
}
export default function ProjectTemplatesPageInternal({ project }: ProjectTemplatesPageInternalProps) {
    const { status: legacyStatus, doBusyTask } = useLegacyDoBusyTask();
    const contentState = useProjectTemplatesState(project, doBusyTask);
    const filterData = useTenantVariableFiltersData(project.Id, doBusyTask);
    const variableResourceOptions = useTenantVariableResourceOptions(doBusyTask);
    const valueChangeTracker = useProjectTenantVariableValueChangeTracker();
    const saveAction: TenantVariablesPageSaveAction = {
        onClick: async () => {
            await saveProjectTemplateVariableValues(doBusyTask, valueChangeTracker);
            await contentState.actions.onSaveProjectTemplates();
            valueChangeTracker.reset();
        },
        disabled: !valueChangeTracker.hasAny(),
    };
    return (<ProjectTenantVariablesPageContent legacyStatus={legacyStatus} project={project} doBusyTask={doBusyTask} saveAction={saveAction} page={"project"} warnings={contentState.warnings}>
            {project ? <ProjectTemplatesInlineContent project={project} doBusyTask={doBusyTask} contentState={contentState} filterData={filterData} variableResourceOptions={variableResourceOptions} variableValueTracker={valueChangeTracker}/> : null}
        </ProjectTenantVariablesPageContent>);
}
async function saveProjectTemplateVariableValues(doBusyTask: DoBusyTask, valueTracker: ProjectTenantVariableValueChangeTracker) {
    return doBusyTask(async () => {
        const changedTenants = valueTracker.changedTenants();
        const tenantVariablesPromises = Array.from(changedTenants).map((id) => repository.Tenants.getVariablesByTenantId(id));
        const tenantVariables = await Promise.all(tenantVariablesPromises);
        const tenantVariablesLookup: {
            [tenantId: string]: TenantVariableResource;
        } = {};
        for (const tenantVariable of tenantVariables) {
            tenantVariablesLookup[tenantVariable.TenantId] = tenantVariable;
        }
        const modifiedTenantVariables = modifyTenantVariables(tenantVariablesLookup, valueTracker.values());
        const savingPromises = modifiedTenantVariables.map((variables) => repository.Tenants.setVariablesByTenantId(variables.TenantId, variables));
        await Promise.all(savingPromises);
    });
}
function modifyTenantVariables(tenantVariables: {
    [tenantId: string]: TenantVariableResource;
}, changes: ChangedProjectTemplateVariableValue[]): TenantVariableResource[] {
    const modified = new Map<string, TenantVariableResource>();
    for (const { projectId, templateId, tenantId, environmentId, value } of changes) {
        const variables = tenantVariables[tenantId];
        variables.ProjectVariables[projectId].Variables[environmentId][templateId] = value ?? "";
        modified.set(tenantId, variables);
    }
    return Array.from(modified.values());
}
