import type { DeploymentActionResource } from "@octopusdeploy/octopus-server-client";
import { getProcessTemplateIdFromAction } from "~/areas/projects/components/Process/Blueprints/processTemplateId";
import { client, repository } from "~/clientInstance";
export default function getActionLogoUrl(action: DeploymentActionResource | null) {
    if (!action || !action.Properties) {
        return "";
    }
    const templateId = action.Properties["Octopus.Action.Template.Id"];
    const version = action.Properties["Octopus.Action.Template.Version"];
    const stepPackageVersion = action.StepPackageVersion;
    if (action.ActionType === "Octopus.ProcessTemplate" && repository.spaceId) {
        const processTemplateId = getProcessTemplateIdFromAction(action, true);
        if (processTemplateId) {
            return `~/bff/processtemplates/${processTemplateId}/icon`;
        }
    }
    if (stepPackageVersion !== undefined) {
        const majorVersion = stepPackageVersion.split(".")[0];
        return client.resolveLinkTemplate("ActionTemplateVersionedLogo", { typeOrId: action.ActionType, version: majorVersion });
    }
    return templateId ? client.resolveLinkTemplate("ActionTemplateVersionedLogo", { typeOrId: templateId, version }) : client.resolveLinkTemplate("ActionTemplateLogo", { typeOrId: action.ActionType });
}
