import { Tooltip } from "@octopusdeploy/design-system-components";
import { MessageDotsIcon } from "@octopusdeploy/design-system-icons";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
interface TemplateHelpTextProps {
    text: string | null;
}
export function TemplateHelpText({ text }: TemplateHelpTextProps) {
    if (!text) {
        return null;
    }
    return (<Tooltip content={text}>
            <MessageDotsIcon size={24} color={themeTokens.color.icon.tertiary}/>
        </Tooltip>);
}
