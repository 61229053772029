import { css, cx } from "@emotion/css";
import type { DropdownButtonState } from "@octopusdeploy/design-system-components";
import { OctopusLogo, resetStyles, useIsMobile, Tooltip } from "@octopusdeploy/design-system-components";
import { ChevronDownIcon, ChevronUpIcon } from "@octopusdeploy/design-system-icons";
import { borderRadius, colorScales, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { SpaceResource } from "@octopusdeploy/octopus-server-client";
import type { ValuesAsKeys } from "@octopusdeploy/type-utils";
import React, { useCallback, useEffect, useState } from "react";
import { ErrorAlert } from "../Alert/ErrorAlert";
import { DropdownButton } from "../DropdownButton";
import { SpaceCallToActionPanel } from "./SpaceCallToActionPanel";
import { SpaceLogo } from "./SpaceLogo";
import { SpaceSearchPanel } from "./SpaceSearchPanel/SpaceSearchPanel";
import { useTrackSpaceSwitcherOpenEffect, useTrackSpaceSwitcherStartedSearchEffect, useTrackAddNewSpace, useTrackSpaceSelectedCallback } from "./analytics/useTrackSpaceSwitcherInteraction";
export const SpaceCreationRestriction = {
    NoPermission: "No Permission",
    LicenseLimitReached: "License Limit Reached",
} as const;
export type SpaceCreationRestrictionValue = ValuesAsKeys<typeof SpaceCreationRestriction>;
export interface ControlledSpaceSwitcherProps {
    currentSpace: SpaceResource | undefined;
    spaces: SpaceResource[];
    isLoading: boolean;
    onNewSpaceRequested: () => void;
    onUnlockMoreSpacesRequested: () => void;
    spaceCreationRestriction?: SpaceCreationRestrictionValue;
    onFetchSpacesRequested: () => void;
    dropdownButtonState: DropdownButtonState;
    error: Error | undefined;
    onCloseError: () => void;
}
export function ControlledSpaceSwitcher({ currentSpace, onNewSpaceRequested, onUnlockMoreSpacesRequested, onFetchSpacesRequested, spaces, isLoading, spaceCreationRestriction, dropdownButtonState, error, onCloseError }: ControlledSpaceSwitcherProps) {
    const { isOpen, closeDropdown } = dropdownButtonState;
    const [searchValue, setSearchValue] = useState("");
    useEffect(() => {
        if (isOpen) {
            onFetchSpacesRequested();
        }
    }, [isOpen, onFetchSpacesRequested]);
    useEffect(() => {
        if (!isOpen) {
            setSearchValue("");
        }
    }, [isOpen]);
    const trackAddNewSpace = useTrackAddNewSpace();
    const onNewSpaceRequestedWithAnalytics = useCallback(() => {
        trackAddNewSpace();
        onNewSpaceRequested();
    }, [onNewSpaceRequested, trackAddNewSpace]);
    useTrackSpaceSwitcherOpenEffect(isOpen);
    useTrackSpaceSwitcherStartedSearchEffect(searchValue, isOpen);
    const trackSpaceSelected = useTrackSpaceSelectedCallback();
    const handleSpaceClicked = useCallback(() => {
        trackSpaceSelected();
        closeDropdown();
    }, [closeDropdown, trackSpaceSelected]);
    const loadedCurrentSpace = currentSpace ? spaces?.find((space) => space.Id === currentSpace.Id) : undefined;
    const latestCurrentSpace = loadedCurrentSpace ?? currentSpace;
    const hasMultipleSpaces = spaces !== undefined && spaces.length > 1;
    const errorAlert = error ? <ErrorAlert error={error} onClose={onCloseError} accessibleName="Space switcher error"/> : undefined;
    let dropdownContent = undefined;
    const isWithinLicenseLimit = spaceCreationRestriction !== SpaceCreationRestriction.LicenseLimitReached;
    if (hasMultipleSpaces) {
        dropdownContent = (<SpaceSearchPanel searchValue={searchValue} onSearchValueChanged={setSearchValue} spaces={spaces} canCreateNewSpace={spaceCreationRestriction === undefined} isWithinLicenseLimit={isWithinLicenseLimit} onNewSpaceRequested={onNewSpaceRequestedWithAnalytics} onUnlockMoreSpacesRequested={onUnlockMoreSpacesRequested} isLoading={isLoading} errorAlert={errorAlert} onSpaceSelected={handleSpaceClicked}/>);
    }
    else if (spaceCreationRestriction !== SpaceCreationRestriction.NoPermission) {
        const callToActionLabel = isWithinLicenseLimit ? "Create new space" : "Unlock more spaces";
        const onCallToActionRequested = isWithinLicenseLimit ? onNewSpaceRequested : onUnlockMoreSpacesRequested;
        dropdownContent = <SpaceCallToActionPanel callToActionButtonLabel={callToActionLabel} onCallToActionRequested={onCallToActionRequested} isLoading={isLoading} errorAlert={errorAlert}/>;
    }
    const dropdownButtonCanOpen = !!dropdownContent;
    const isMobile = useIsMobile();
    return (<>
            <DropdownButton.Button className={cx(spaceButtonRootStyles, { [spaceButtonRootLargeStyles]: !isMobile })} dropdownButtonState={dropdownButtonState} disabled={!dropdownButtonCanOpen} accessibleName="Space switcher">
                <Tooltip display="block" content={latestCurrentSpace?.Name}>
                    <div className={spaceButtonContentContainerStyles}>
                        <SpaceImage currentSpace={latestCurrentSpace}/>
                        {!isMobile && <SpaceContent currentSpace={latestCurrentSpace}/>}
                        {dropdownButtonCanOpen && (isOpen ? <ChevronUpIcon size={24}/> : <ChevronDownIcon size={24}/>)}
                    </div>
                </Tooltip>
            </DropdownButton.Button>
            {isOpen && dropdownButtonCanOpen && (<DropdownButton.Dropdown dropdownButtonState={dropdownButtonState} accessibleName="Space switcher dropdown">
                    {dropdownContent}
                </DropdownButton.Dropdown>)}
        </>);
}
function SpaceImage({ currentSpace }: {
    currentSpace: SpaceResource | undefined;
}) {
    return <div className={spaceImageStyles}>{currentSpace ? <SpaceLogo space={currentSpace}/> : <OctopusLogo />}</div>;
}
function SpaceContent({ currentSpace }: {
    currentSpace: SpaceResource | undefined;
}) {
    return <>{currentSpace && <span className={spaceContentStyles}>{currentSpace.Name}</span>}</>;
}
const spaceButtonRootStyles = css({
    ...resetStyles.button,
    font: text.regular.bold.medium,
    boxSizing: "border-box",
    transitionTimingFunction: "ease-out",
    transitionProperty: "background-color, border-color, outline, color, fill",
    transitionDuration: "0.2s",
    borderRadius: borderRadius.small,
    paddingRight: space[8],
    height: "2.25rem",
    color: themeTokens.color.text.secondary,
    background: themeTokens.color.spaceSwitcher.background.default,
    ":hover": {
        background: themeTokens.color.spaceSwitcher.background.hover,
        color: themeTokens.color.spaceSwitcher.text.hover,
    },
    ":focus-visible": {
        boxShadow: themeTokens.shadow.focused,
    },
    ":active, &[aria-expanded='true']": {
        background: themeTokens.color.spaceSwitcher.background.pressed,
        color: themeTokens.color.spaceSwitcher.text.pressed,
    },
});
const spaceButtonRootLargeStyles = css({
    width: "13.5rem",
});
const spaceButtonContentContainerStyles = css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: space[16],
});
const spaceImageStyles = css({
    boxSizing: "border-box",
    alignSelf: "stretch",
    aspectRatio: "1",
    borderRadius: borderRadius.small,
    minWidth: "2.25rem",
    maxWidth: "2.25rem",
    overflow: "clip",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    backgroundColor: colorScales.white,
    boxShadow: themeTokens.shadow.extraSmall,
});
const spaceContentStyles = css({
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
});
