import { css } from "@emotion/css";
import { CustomSnackbar, IconButton } from "@octopusdeploy/design-system-components";
import { colorScales, space, textTheme } from "@octopusdeploy/design-system-tokens";
import type { LogEvent } from "@octopusdeploy/logging";
import React, { useEffect, useState } from "react";
import { DevOnly } from "~/components/DevTools/DevOnly";
import { useLogEvents, useRemoveAllLogEvents } from "~/components/LogErrors/LogEventCapturer";
export function LogErrorDisplayer() {
    return (<DevOnly>
            <DevOnlyLogErrorDisplayer />
        </DevOnly>);
}
function DevOnlyLogErrorDisplayer() {
    const logEvents = useLogEvents();
    const removeAllLogEvents = useRemoveAllLogEvents();
    const [errorMessage, setErrorMessage] = useState<string>("");
    useEffect(() => {
        // If the log errors have just become 0, then the snackbar should hide.
        // However, we don't want to immediately change the message text until the snackbar has animated away, so keep the old error message around a bit longer.
        const errors = logErrors(logEvents);
        if (errors.length !== 0) {
            setErrorMessage(buildErrorMessage(errors));
        }
    }, [logEvents]);
    return (<>
            <CustomSnackbar open={logErrors(logEvents).length > 0}>
                <div className={alertMessage}>
                    {errorMessage}
                    <IconButton icon="XmarkIcon" onClick={removeAllLogEvents} accessibleName="Close"/>
                </div>
            </CustomSnackbar>
        </>);
}
function logErrors(logEvents: LogEvent[]): LogEvent[] {
    return logEvents.filter((m) => m.logLevel === "error" || m.logLevel === "fatal");
}
function buildErrorMessage(errors: LogEvent[]) {
    if (errors.length > 1) {
        return `There are ${errors.length} errors in the console`;
    }
    else if (errors.length === 1) {
        return `There is 1 error in the console`;
    }
    else {
        return "";
    }
}
const alertMessage = css({
    background: colorScales.red[500],
    color: colorScales.white,
    font: textTheme.textRegularBoldMedium,
    display: "flex",
    alignItems: "center",
    gap: space[8],
    padding: space[16],
    borderRadius: "4px",
    "& svg": {
        fill: colorScales.white,
    },
});
