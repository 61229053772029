import type { PageSideNavLink } from "@octopusdeploy/design-system-components";
import type { LinkEvent, LinkHref } from "@octopusdeploy/portal-routes";
import { nameForLink, useDispatchLinkClicked } from "@octopusdeploy/portal-routes";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
export interface CreatePageSideNavLink {
    (label: string | JSX.Element, path: LinkHref, exact?: boolean): PageSideNavLink;
    (label: string | JSX.Element, path: LinkHref, exact?: boolean, permission?: PermissionCheckProps): PageSideNavLink | null;
}
export function useCreatePageSideNavLink(): CreatePageSideNavLink {
    const dispatchLinkClicked = useDispatchLinkClicked();
    function createPageSideNavLink(label: string | JSX.Element, path: LinkHref, exact?: boolean): PageSideNavLink;
    function createPageSideNavLink(label: string | JSX.Element, path: LinkHref, exact?: boolean, permission?: PermissionCheckProps): PageSideNavLink | null {
        if (!permission || isAllowed(permission)) {
            return {
                label,
                path,
                exact: !!exact,
                onClick: (label: string, href: string) => {
                    const linkEvent: LinkEvent = {
                        linkLabel: label,
                        documentUrl: href,
                        linkLocation: "Page Side Nav",
                    };
                    dispatchLinkClicked(nameForLink(), linkEvent);
                },
            };
        }
        return null;
    }
    return createPageSideNavLink;
}
