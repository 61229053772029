import { css } from "@emotion/css";
import { borderRadius, themeTokens, text } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { PageHeaderSecondary } from "../PageHeaderSecondary";
import { LinearProgress } from "../Progress/LinearProgress";
import { ErrorPanel, type ErrorInfo } from "./ErrorPanel";
interface Level2PageLoadingProps {
    loadingTitle: string;
    errors?: ErrorInfo[];
}
export function Level2PageLoading({ loadingTitle, errors }: Level2PageLoadingProps) {
    return (<div className={pageContentStyles.container}>
            <LinearProgress variant={"indeterminate"} show={errors === undefined || errors.length === 0}/>
            <PageHeaderSecondary title={loadingTitle ?? "..."}/>
            {errors?.map((error, index) => (<ErrorPanel key={index} error={error}/>))}
        </div>);
}
const pageContentStyles = {
    container: css({
        display: "flex",
        flexDirection: "column",
        borderRadius: borderRadius.small,
        border: `1px solid ${themeTokens.color.border.primary}`,
        minHeight: 640,
        marginLeft: "auto",
        marginRight: "auto",
        font: text.regular.default.medium,
        maxWidth: 1280,
    }),
};
