/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import { PopoverBasicHelp } from "@octopusdeploy/design-system-components";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import { repository } from "~/clientInstance";
import ExternalLink from "../Navigation/ExternalLink";
import InternalLink from "../Navigation/InternalLink";
export default function PackageSelectorPopover() {
    return (<PopoverBasicHelp placement="right-start" width="narrow" description={<div>
                    <div>
                        You can upload packages directly to the{" "}
                        <InternalLink to={links.builtInRepositoryPage.generateUrl({ spaceId: repository.spaceId! })} openInSelf={false} showOpensInNewTabIcon={true}>
                            Octopus Built-in Package Repository
                        </InternalLink>
                        , or source them using{" "}
                        <InternalLink to={links.feedsPage.generateUrl({ spaceId: repository.spaceId! })} openInSelf={false} showOpensInNewTabIcon={true}>
                            External Feeds
                        </InternalLink>
                        .
                    </div>
                    <br />
                    <div className={styles.learnMoreSection}>
                        <div>Learn more:</div>
                        <ul>
                            <li>
                                <ExternalLink href="DocumentationPackaging" openInSelf={false}>
                                    Packaging applications
                                </ExternalLink>
                            </li>
                            <li>
                                <ExternalLink href="DynamicPackageFeeds" openInSelf={false}>
                                    Dynamically selecting packages at deployment time
                                </ExternalLink>
                            </li>
                        </ul>
                    </div>
                </div>}/>);
}
const styles = {
    learnMoreSection: css({
        ul: {
            margin: 0,
        },
        li: {
            listStyleType: "disc",
            listStylePosition: "outside",
            marginLeft: space["24"],
            color: themeTokens.color.text.link.default,
        },
    }),
};
