import { Level2PageLayout, LinearProgress, PageHeaderPrimary } from "@octopusdeploy/design-system-components";
import type { InsightsReportResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { compact } from "lodash";
import * as React from "react";
import type { ReportFilter } from "~/areas/insights/components/Reports/ReportMetricsLayout/useReportFilters";
import type { Errors } from "~/components/DataBaseComponent/index";
import Markdown from "~/components/Markdown";
import { ErrorPanel } from "~/components/form/index";
import { useCreatePageSideNavLink } from "~/hooks/useCreatePageSideNavLink";
export interface InsightsReportLayoutProps {
    report: InsightsReportResource;
    reportFilter?: ReportFilter;
    children: React.ReactNode;
}
export function InsightsReportLayout({ report, children, reportFilter }: InsightsReportLayoutProps) {
    const createPageSideNavLink = useCreatePageSideNavLink();
    const sidebarLinks = [
        createPageSideNavLink("Overview", links.insightsReportOverviewPage.generateUrl({ spaceId: report.SpaceId, reportId: report.Id }, reportFilter)),
        createPageSideNavLink("Deployment frequency", links.insightsReportDeploymentFrequencyPage.generateUrl({ spaceId: report.SpaceId, reportId: report.Id }, reportFilter)),
        createPageSideNavLink("Deployment lead time", links.insightsReportDeploymentLeadTimePage.generateUrl({ spaceId: report.SpaceId, reportId: report.Id }, reportFilter)),
        createPageSideNavLink("Deployment failure rate", links.insightsReportDeploymentFailureRatePage.generateUrl({ spaceId: report.SpaceId, reportId: report.Id }, reportFilter)),
        createPageSideNavLink("Mean time to recovery", links.insightsReportDeploymentMeanTimeToRecoveryPage.generateUrl({ spaceId: report.SpaceId, reportId: report.Id }, reportFilter)),
        createPageSideNavLink("Deployment duration", links.insightsReportDeploymentDurationPage.generateUrl({ spaceId: report.SpaceId, reportId: report.Id }, reportFilter)),
        createPageSideNavLink("Settings", links.insightsReportSettingsPage.generateUrl({ spaceId: report.SpaceId, reportId: report.Id })),
    ];
    const computedSidebarLinks = compact(sidebarLinks);
    return (<main>
            <Level2PageLayout description={<Markdown markup={report.Description}/>} navItems={computedSidebarLinks} header={{
            breadcrumbs: [{ label: "Insights", pageUrl: links.insightReportListPage.generateUrl({ spaceId: report.SpaceId }) }],
            title: report.Name,
            logo: { href: report.Links.Logo, accessibleName: `${report.Name} logo` },
        }}>
                {children}
            </Level2PageLayout>
        </main>);
}
export interface InsightsReportLoadingLayoutProps {
    errors: Errors | undefined;
    busy: boolean;
}
export function InsightsReportLoadingLayout({ busy, errors }: InsightsReportLoadingLayoutProps) {
    return (<main>
            <PageHeaderPrimary title="Insights"/>
            <LinearProgress variant={"indeterminate"} show={busy}/>
            {errors && <ErrorPanel message={errors.message} errors={errors.errors} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink}/>}
        </main>);
}
