import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import type { GitBranchResource, GitPersistenceSettings, GitRef, GitRefResource, PagingCollection } from "@octopusdeploy/octopus-server-client";
import { isGitBranch, Permission, toGitBranch } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { BranchSelectorGeneric } from "~/areas/projects/components/BranchSelector/BranchSelectorGeneric";
import { RefTypes } from "~/areas/projects/components/GitRefDropDown/GitRefDropDown";
import type { GitRefOption } from "~/areas/projects/components/GitRefDropDown/GitRefOption";
import type { GenericGitRefSelectorLoadedItems } from "~/areas/projects/components/GitRefSelector/GenericGitRefSelector";
import { repository } from "~/clientInstance";
export interface PlatformHubPageHeaderBranchSelectorProps {
    gitPersistenceSettings: GitPersistenceSettings;
    gitRef: GitRef | undefined;
    setGitRef: (gitRef: string) => void;
}
export function PlatformHubPageHeaderBranchSelector(props: PlatformHubPageHeaderBranchSelectorProps) {
    return (<>
            <div className={branchSelectorContainer}>
                <BranchSelectorGeneric defaultBranch={props.gitPersistenceSettings.DefaultBranch} onCreateBranch={(newBranchName, parentGitRef) => repository.PlatformHubRepository.createBranch(newBranchName, parentGitRef)} onLoadItems={(refType, selectedGitRef) => loadItems(refType, selectedGitRef, props.gitPersistenceSettings)} onSearchItems={(refType, searchTerm) => searchItems(refType, searchTerm, props.gitPersistenceSettings)} permission={Permission.PlatformHubEdit} setGitRef={props.setGitRef} gitRef={props.gitRef}/>
            </div>
        </>);
}
const loadItems = async (refType: RefTypes, selectedGitRef: GitRef | undefined, gitPersistenceSettings: GitPersistenceSettings): Promise<GenericGitRefSelectorLoadedItems> => {
    let resources: PagingCollection<GitRefResource> | null = null;
    let items: GitRefOption[] = [];
    if (refType === RefTypes.Branches) {
        const branchResources: PagingCollection<GitBranchResource> = await repository.PlatformHubRepository.getBranches();
        resources = branchResources;
        items = await getBranchesList(gitPersistenceSettings, branchResources.Items, selectedGitRef);
    }
    else if (refType === RefTypes.Tags) {
        resources = await repository.PlatformHubRepository.getTags();
        items = resources.Items.map((t) => ({
            value: t.CanonicalName,
            isDefault: false,
            canWrite: false,
        }));
    }
    return {
        items,
        totalCount: resources?.TotalResults ?? 0,
    };
};
const searchItems = async (refType: RefTypes, searchTerm: string, gitPersistenceSettings: GitPersistenceSettings): Promise<GitRefOption[]> => {
    if (refType === RefTypes.Tags) {
        const tagResources = await repository.PlatformHubRepository.searchTags(searchTerm);
        return tagResources.Items.map((t) => ({
            value: t.CanonicalName,
            isDefault: false,
            canWrite: false,
        }));
    }
    else {
        // Fallback to branches
        return getFilteredProjectBranchesList(gitPersistenceSettings, searchTerm);
    }
};
const getBranchesList = async (gitPersistenceSettings: GitPersistenceSettings, branchResources: GitBranchResource[], selectedRef: GitRef | undefined): Promise<GitRefOption[]> => {
    let branches = branchResources;
    // Add the currently selected branch to the list (if necessary)
    if (selectedRef && isGitBranch(selectedRef) && !branches.find((item) => item.CanonicalName === selectedRef)) {
        const selectedBranchResource = await repository.PlatformHubRepository.tryGetBranch(gitPersistenceSettings.DefaultBranch, selectedRef);
        // It may be that the current branch has been deleted directly in the repo. Therefore, only add it if it was found.
        if (selectedBranchResource !== null) {
            branches = [selectedBranchResource, ...branches];
        }
    }
    const defaultBranchIndex = getDefaultBranchIndex(branches, gitPersistenceSettings);
    // If the default branch isn't in the list, add it
    if (defaultBranchIndex === -1) {
        // It may be that the default branch has been deleted directly in the repo. Therefore, only add it if it was found.
        const branchResource = await repository.PlatformHubRepository.tryGetBranch(gitPersistenceSettings.DefaultBranch, gitPersistenceSettings.DefaultBranch);
        if (branchResource !== null) {
            // If we have found the default branch, put it at the top
            branches = [branchResource, ...branches];
        }
    }
    else {
        // If the default branch is already in the list, move it to the top
        const defaultBranch = branches.splice(defaultBranchIndex, 1);
        branches = [...defaultBranch, ...branches];
    }
    return buildBranchNamesList(branches, gitPersistenceSettings);
};
const getFilteredProjectBranchesList = async (gitPersistenceSettings: GitPersistenceSettings, filterTerm: string): Promise<GitRefOption[]> => {
    const filteredBranchResources = await repository.PlatformHubRepository.searchBranches(filterTerm);
    let branches = filteredBranchResources.Items;
    // If the default branch is in the list, put it at the top
    const defaultBranchIndex = getDefaultBranchIndex(branches, gitPersistenceSettings);
    if (defaultBranchIndex !== -1) {
        const defaultBranch = branches.splice(defaultBranchIndex, 1);
        branches = [...defaultBranch, ...branches];
    }
    return buildBranchNamesList(branches, gitPersistenceSettings);
};
const getDefaultBranchIndex = (branches: GitBranchResource[], settings: GitPersistenceSettings) => {
    return branches.findIndex((item) => item.CanonicalName === settings.DefaultBranch || item.CanonicalName === toGitBranch(settings.DefaultBranch));
};
const buildBranchNamesList = (branches: GitBranchResource[], gitPersistenceSettings: GitPersistenceSettings): GitRefOption[] => {
    return branches.map((branch) => {
        const isDefaultBranch = branch.Name === gitPersistenceSettings.DefaultBranch;
        return {
            value: branch.CanonicalName,
            isDefault: isDefaultBranch,
            canWrite: !branch.IsProtected,
        };
    });
};
const branchSelectorContainer = css({
    display: "flex",
    gap: space["16"],
    alignItems: "center",
});
