/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Checkbox } from "@octopusdeploy/design-system-components";
import type { PageAction } from "@octopusdeploy/design-system-components";
import type { EnvironmentResource, EnvironmentSettingsMetadata, ExtensionSettingsValues } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { cloneDeep } from "lodash";
import * as React from "react";
import type { ActionEvent, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { Action, AnalyticView, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import { DeleteEnvironmentDialogLayout } from "~/areas/infrastructure/components/EnvironmentLayout/DeleteEnvironmentDialogLayout";
import { repository } from "~/clientInstance";
import DynamicForm from "~/components/DynamicForm/DynamicForm";
import type { FormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import { FormBaseComponent } from "~/components/FormBaseComponent/FormBaseComponent";
import FormPage from "~/components/FormPage/FormPage";
import { LegacyForm } from "~/components/FormPaperLayout/LegacyForm";
import Markdown from "~/components/Markdown";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuConverterVNext } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import { PageContent } from "~/components/PageContent/PageContent";
import { PageLoading } from "~/components/PageContent/PageLoading";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { ExpandableFormSection, FormSectionHeading, required, Summary, Text } from "~/components/form";
import MarkdownEditor from "~/components/form/MarkdownEditor/MarkdownEditor";
import NameSummaryWithSlug from "~/primitiveComponents/form/Slugs/NameSummaryWithSlug";
import SlugEditor from "~/primitiveComponents/form/Slugs/SlugEditor";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
interface EnvironmentModel {
    name: string;
    slug: string;
    description: string;
    useGuidedFailure: boolean;
    allowDynamicInfrastructure: boolean;
    sortOrder: number;
    extensionSettings: ExtensionSettingsValues[];
}
const defaultModel: EnvironmentModel = {
    name: "",
    slug: "",
    description: "",
    useGuidedFailure: false,
    allowDynamicInfrastructure: false,
    sortOrder: -1,
    extensionSettings: [],
};
interface EnvironmentPageProps {
    environmentId: string;
}
interface StaticEnvironmentData {
    environment: EnvironmentResource;
}
interface EnvironmentLayoutPageData extends StaticEnvironmentData {
    metadata: EnvironmentSettingsMetadata[];
}
const EnvironmentLayoutFormPage = FormPage<EnvironmentLayoutPageData>();
const Title = "Environments";
type EnvironmentPageInternalProps = {
    initialData: EnvironmentLayoutPageData;
    trackAction: AnalyticTrackedActionDispatcher;
};
interface EnvironmentPageInternalState extends FormBaseComponentState<EnvironmentModel> {
    deleted: boolean;
    environment: EnvironmentResource;
    metadata: EnvironmentSettingsMetadata[];
}
export function EnvironmentPage({ environmentId }: EnvironmentPageProps) {
    const trackAction = useAnalyticTrackedActionDispatch();
    return (<EnvironmentLayoutFormPage title={Title} load={async () => {
            const environmentData = await loadEnvironment(environmentId);
            const metadata = repository.Environments.getMetadata(environmentData.environment);
            return {
                metadata: await metadata,
                ...environmentData,
            };
        }} renderWhenLoaded={(data) => <EnvironmentPageInternal initialData={data} trackAction={trackAction}/>} renderAlternate={() => <PageLoading loadingTitle={Title}/>}/>);
}
const loadEnvironment = async (environmentId: string): Promise<StaticEnvironmentData> => {
    const environment = await repository.Environments.get(environmentId);
    return {
        environment,
    };
};
class EnvironmentPageInternal extends FormBaseComponent<EnvironmentPageInternalProps, EnvironmentPageInternalState, EnvironmentModel> {
    constructor(props: EnvironmentPageInternalProps) {
        super(props);
        const data = props.initialData;
        this.state = {
            environment: data.environment,
            model: this.buildModel(data.environment),
            cleanModel: cloneDeep(this.buildModel(data.environment)),
            deleted: false,
            metadata: data.metadata,
        };
    }
    descriptionSummary() {
        return this.state.model.description ? Summary.summary(<Markdown markup={this.state.model.description}/>) : Summary.placeholder("No description provided");
    }
    renderOverflowActions() {
        return [
            OverflowMenuItems.dialogItem("Delete", <DeleteEnvironmentDialogLayout environment={this.state.environment} onDelete={this.handleDeleteConfirm}/>, {
                permission: Permission.EnvironmentDelete,
                environment: "*",
            }),
            [
                OverflowMenuItems.navItem("Audit Trail", links.auditPage.generateUrl({ environments: [this.state.environment.Id] }), {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ],
        ];
    }
    renderExtensionSettings() {
        return this.state.metadata.map((m) => {
            let valuesForExtension = this.state.environment.ExtensionSettings.find((e) => e.ExtensionId === m.ExtensionId);
            if (!valuesForExtension || !valuesForExtension.Values) {
                valuesForExtension = {
                    ExtensionId: m.ExtensionId,
                    Values: {},
                };
                this.state.model.extensionSettings.push(valuesForExtension);
            }
            return (<div>
                    <FormSectionHeading title={m.Metadata.Description}/>
                    <DynamicForm types={m.Metadata.Types} values={valuesForExtension.Values} onChange={() => {
                    this.setState({
                        model: this.state.model,
                    });
                }}/>
                </div>);
        });
    }
    render() {
        const legacyOverflowActions = this.renderOverflowActions();
        const overflowMenu = OverflowMenuConverterVNext.convertAll(legacyOverflowActions);
        return (<>
                <AnalyticView resource="Environment"/>
                <LegacyForm model={this.state.model} cleanModel={this.state.cleanModel} savePermission={{ permission: Permission.EnvironmentEdit, environment: "*" }} onSaveClick={this.handleSaveClick} saveText={"Environment details updated"}>
                    {({ FormContent, createSaveAction }) => (<PageContent header={{
                    title: this.state.model.name,
                    breadcrumbs: [{ label: "Environments", pageUrl: links.infrastructureEnvironmentsPage.generateUrl({ spaceId: this.state.environment.SpaceId }) }],
                    overflowActions: overflowMenu.menuItems,
                    pageActions: this.getPageActions(),
                    primaryAction: createSaveAction({}),
                }} busy={this.state.busy} errors={this.errors}>
                            {overflowMenu.dialogs}
                            {this.state.deleted && <InternalRedirect to={links.infrastructureEnvironmentsPage.generateUrl({ spaceId: this.state.environment.SpaceId })}/>}
                            <FormContent expandAllOnMount={false}>
                                <TransitionAnimation>
                                    <ExpandableFormSection errorKey="name" title="Name" focusOnExpandAll summary={this.state.model.name ? Summary.summary(<NameSummaryWithSlug name={this.state.model.name} slug={this.state.model.slug}/>) : Summary.placeholder("Please enter a name for your environment")} help="A short, memorable, unique name for this environment. Example: Development.">
                                        <Text value={this.state.model.name} onChange={(name) => this.setModelState({ name })} label="Name" validate={required("Please enter a environment name")} autoFocus={true}/>
                                        <SlugEditor value={this.state.model.slug} name={this.state.model.name} originalSlug={this.state.cleanModel?.slug ?? ""} onChange={(slug) => this.setModelState({ slug })} label="Slug" validate={required("Please enter an environment slug")} error={this.getFieldError("slug")}/>
                                    </ExpandableFormSection>

                                    <ExpandableFormSection errorKey="description" title="Description" summary={this.descriptionSummary()} help="Enter a description for your environment.">
                                        <MarkdownEditor value={this.state.model.description} label="Environment description" onChange={(description) => this.setModelState({ description })}/>
                                    </ExpandableFormSection>

                                    <ExpandableFormSection errorKey="useGuidedFailure" title="Default Guided Failure Mode" summary={this.state.model.useGuidedFailure ? Summary.summary("Yes") : Summary.default("No")} help="Select whether guided failure mode is enabled by default">
                                        <div>
                                            <Checkbox value={this.state.model.useGuidedFailure} label="Use guided failure mode by default" onChange={(useGuidedFailure) => this.setModelState({ useGuidedFailure })} note={<span>If guided failure is enabled for an environment, Octopus Deploy will prompt for user intervention if a deployment fails in the environment.</span>}/>
                                        </div>
                                    </ExpandableFormSection>
                                    <ExpandableFormSection errorKey="dynamicInfrastructure" title="Dynamic Infrastructure" summary={this.state.model.allowDynamicInfrastructure ? Summary.default("Yes") : Summary.summary("No")} help="Select whether dynamic infrastructure is allowed in this environment">
                                        <div>
                                            <Checkbox value={this.state.model.allowDynamicInfrastructure} label="Allow dynamic infrastructure" onChange={(allowDynamicInfrastructure) => this.setModelState({ allowDynamicInfrastructure })} note={<span>
                                                        In many deployment scenarios, <ExternalLink href="DynamicInfrastructure">infrastructure is created dynamically</ExternalLink> as part of the deployment. If dynamic infrastructure is enabled for
                                                        an environment, deployments to this environment are allowed to create infrastructure, such as targets and accounts.
                                                    </span>}/>
                                        </div>
                                    </ExpandableFormSection>
                                    {this.renderExtensionSettings()}
                                </TransitionAnimation>
                            </FormContent>
                        </PageContent>)}
                </LegacyForm>
            </>);
    }
    private handleSaveClick = async () => {
        const model = this.state.model;
        const environment: EnvironmentResource = {
            Id: this.props.initialData.environment.Id,
            Name: model.name,
            Slug: model.slug,
            Description: model.description,
            UseGuidedFailure: model.useGuidedFailure,
            AllowDynamicInfrastructure: model.allowDynamicInfrastructure,
            SortOrder: model.sortOrder,
            ExtensionSettings: model.extensionSettings,
            SpaceId: this.props.initialData.environment.SpaceId,
            Links: this.props.initialData.environment.Links,
        };
        await this.doBusyTask(async () => {
            const ev: ActionEvent = {
                action: Action.Save,
                resource: "Environment",
            };
            await this.props.trackAction("Save Environment", ev, async () => {
                const result = await repository.Environments.save(environment);
                this.setState({
                    environment: result,
                    model: this.buildModel(result),
                    cleanModel: this.buildModel(result),
                    deleted: false,
                });
            });
        });
    };
    private buildModel(environment: EnvironmentResource): EnvironmentModel {
        const model: EnvironmentModel = {
            name: environment.Name,
            slug: environment.Slug ?? "",
            description: environment.Description,
            useGuidedFailure: environment.UseGuidedFailure,
            allowDynamicInfrastructure: environment.AllowDynamicInfrastructure,
            sortOrder: environment.SortOrder,
            extensionSettings: environment.ExtensionSettings,
        };
        return model;
    }
    private handleDeleteConfirm = () => {
        this.setState({
            model: cloneDeep(defaultModel),
            cleanModel: cloneDeep(defaultModel), //reset model so that dirty state doesn't prevent navigation
            deleted: true,
        });
        return true;
    };
    private getPageActions(): PageAction[] {
        return [
            {
                type: "navigate",
                path: links.newDeploymentTargetWithEnvironmentPage.generateUrl({ spaceId: this.state.environment.SpaceId, environmentId: this.state.environment.Id }),
                label: "Add Deployment Target",
                buttonType: "secondary",
                hasPermissions: isAllowed({ permission: Permission.MachineCreate, environment: "*", tenant: "*" }),
            },
        ];
    }
    static displayName = "EnvironmentPageInternal";
}
