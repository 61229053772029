import { css } from "@emotion/css";
import { CaretRightIcon } from "@octopusdeploy/design-system-icons";
import type { KeyboardEventHandler } from "react";
import { useCallback } from "react";
import * as React from "react";
import { CustomMenu } from "../..//Menu/CustomMenu";
import { useIsUrlActive } from "../../../routing/OctopusRoutingContext";
import type { MenuAnchorOrigin } from "../../Menu/CustomMenu";
import { useMenuState } from "../../Menu/useMenuState";
import { MenuItemButton } from "../MenuItemButton/MenuItemButton";
import { SimpleMenuItems } from "../SimpleMenuItems";
import type { SimpleMenuItem } from "../SimpleMenuItems";
export interface NestedMenuProps {
    label: string;
    // For now, we only support nested menus with simple menu items
    // This makes it easier for us to know if any descendant links are active
    childMenuItems: SimpleMenuItem[];
    autoFocus?: boolean;
    onClose?: () => void;
    compact?: boolean;
}
export function NestedMenu({ childMenuItems, label, onClose, autoFocus, compact }: NestedMenuProps) {
    const isUrlActive = useIsUrlActive();
    const isActive = hasActiveDescendants(childMenuItems, isUrlActive);
    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();
    const closeMenu = menuState.onClose;
    const close = useCallback(() => {
        onClose?.();
        closeMenu();
    }, [closeMenu, onClose]);
    const onKeyDown = useCallback<KeyboardEventHandler>((ev) => {
        // We want to stop propagation so that navigation keydown events (like arrow up/down or home/end)
        // don't bubble up to the parent menu, causing focus to change for that menu too.
        // Normally, the modal also handles escape events, but we also don't want these to bubble up,
        // and instead want to let our onClose call chain handle closing of all the nested menus,
        // so that we have a single way of closing menus
        ev.stopPropagation();
        if (ev.key === "Escape") {
            close();
        }
    }, [close]);
    return (<>
            <MenuItemButton isSelected={isActive} onClick={openMenu} autoFocus={autoFocus} compact={compact} menuButtonAttributes={buttonAriaAttributes}>
                <div className={buttonContainerStyles}>
                    <div className={buttonLabelStyles}>{label}</div>
                    <CaretRightIcon size={20}/>
                </div>
            </MenuItemButton>
            <CustomMenu menuId={menuState.menuId} onClose={close} isOpen={menuState.isOpen} anchorElement={menuState.anchorElement} anchorOrigin={anchorOrigin} onKeyDown={onKeyDown} accessibleName={label}>
                <SimpleMenuItems items={childMenuItems} onClose={close} autoFocus={true} compact={compact}/>
            </CustomMenu>
        </>);
}
NestedMenu.displayName = "NestedMenu";
const anchorOrigin: MenuAnchorOrigin = { horizontal: "right", vertical: "top" };
function hasActiveDescendants(children: SimpleMenuItem[], isUrlActive: ReturnType<typeof useIsUrlActive>): boolean {
    const allLinks = children
        .map((childMenuItem) => {
        if (childMenuItem.type === "internal-link") {
            return [childMenuItem];
        }
        return [];
        //Use Infinity here when typescript properly supports it, until then we are assuming 20 levels of nested items
        // is deep enough. Please see https://stackoverflow.com/questions/61420027/proper-typing-for-array-flatinfinity
    })
        .flat(20);
    return allLinks.some((l) => isUrlActive(l.path, l.showAsActive ?? "if path partially matches"));
}
const buttonContainerStyles = css({
    display: "flex",
    alignItems: "center",
    width: "100%",
});
const buttonLabelStyles = css({
    flexGrow: 1,
});
