import { css } from "@emotion/css";
import type { ReactNode } from "react";
import React, { useState, createRef } from "react";
import { PopoverBasic, type PopoverBasicProps } from "./PopoverBasic";
export interface PopoverBasicWithTriggerProps extends Omit<PopoverBasicProps, "open" | "anchorEl"> {
    onOpen?: () => void;
    triggerElelment: ReactNode;
}
export function PopoverBasicWithTrigger({ onOpen, ...props }: PopoverBasicWithTriggerProps): JSX.Element {
    const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null);
    const ref = createRef<HTMLDivElement>();
    const handleTriggerElementClick = () => {
        anchorEl ? handleClose() : handleOpen();
    };
    const handleOpen = () => {
        setAnchorEl(ref.current);
        onOpen?.();
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (<div ref={ref} className={styles.popoverandElement}>
            <div onClick={handleTriggerElementClick} className={styles.triggerElementWrapper}>
                {props.triggerElelment}
            </div>
            <PopoverBasic onClose={handleClose} anchorEl={anchorEl} open={!!anchorEl} {...props}/>
        </div>);
}
const styles = {
    popoverandElement: css({
        display: "inline-block",
        maxWidth: "100%",
    }),
    triggerElementWrapper: css({
        "&>div": {
            cursor: "pointer",
        },
    }),
};
