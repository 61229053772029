import { Button, RadioButton, RadioButtonGroup, Switch } from "@octopusdeploy/design-system-components";
import type { EnvironmentResource, Segment, TenantResource, TenantTargetingStrategy, FeatureToggleEnvironmentResource } from "@octopusdeploy/octopus-server-client";
import { TenantedDeploymentMode } from "@octopusdeploy/octopus-server-client";
import React, { useState } from "react";
import { EditableSegmentList, SegmentList } from "~/areas/projects/components/FeatureToggles/FeatureToggleEnvironment";
import { useProjectContext } from "~/areas/projects/context/index";
import { EnvironmentChip } from "~/components/Chips/index";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import { TenantMultiSelect } from "~/components/MultiSelect/TenantMultiSelect";
import EnvironmentSelect from "~/components/form/EnvironmentSelect/EnvironmentSelect";
import ExpandableFormSection from "~/components/form/Sections/ExpandableFormSection";
import { Summary } from "~/components/form/index";
import Text from "~/primitiveComponents/form/Text/Text";
import ParseHelper from "~/utils/ParseHelper/index";
interface EditFeatureToggleEnvironmentProps {
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    featureToggleEnvironment?: FeatureToggleEnvironmentResource | undefined;
    title: string;
    onSave(toggle: FeatureToggleEnvironmentResource): Promise<boolean>;
}
interface EditFeatureToggleEnvironmentState {
    environmentId: string | undefined;
    isEnabled: boolean;
    tenantTargetingStrategy: TenantTargetingStrategy;
    tenantIds: string[];
    rolloutPercentage: number | null;
    excludedTenantIds: string[];
    newSegmentKey: string;
    newSegmentValue: string;
    segments: Segment[];
}
export default ({ title, featureToggleEnvironment, environments, tenants, onSave }: EditFeatureToggleEnvironmentProps) => {
    const projectContext = useProjectContext();
    const project = projectContext.state && projectContext.state.model;
    const [state, setState] = useState<EditFeatureToggleEnvironmentState>({
        environmentId: featureToggleEnvironment ? featureToggleEnvironment.DeploymentEnvironmentId : undefined,
        isEnabled: featureToggleEnvironment ? featureToggleEnvironment.IsEnabled : true,
        tenantTargetingStrategy: featureToggleEnvironment ? featureToggleEnvironment.TenantTargetingStrategy : "AllTenants",
        tenantIds: featureToggleEnvironment ? featureToggleEnvironment.TenantIds : [],
        rolloutPercentage: featureToggleEnvironment ? featureToggleEnvironment.RolloutPercentage : null,
        excludedTenantIds: featureToggleEnvironment ? featureToggleEnvironment.ExcludedTenantIds : [],
        newSegmentKey: "",
        newSegmentValue: "",
        segments: featureToggleEnvironment ? featureToggleEnvironment.Segments : [],
    });
    const onSaveClick = async () => {
        if (!state.environmentId) {
            return false;
        }
        const toggleStrategy = state.tenantTargetingStrategy === "AllTenants" && Object.keys(state.segments).length === 0 ? "EntireEnvironment" : "Targeted";
        return onSave({
            DeploymentEnvironmentId: state.environmentId,
            IsEnabled: state.isEnabled,
            ToggleStrategy: toggleStrategy,
            TenantTargetingStrategy: state.tenantTargetingStrategy,
            TenantIds: state.tenantIds,
            RolloutPercentage: state.rolloutPercentage,
            ExcludedTenantIds: state.excludedTenantIds,
            Segments: state.segments,
        });
    };
    const onChangeTenantTargetingStrategy = (strategy: TenantTargetingStrategy) => {
        if (strategy === state.tenantTargetingStrategy)
            return;
        setState((prevState) => ({ ...prevState, tenantTargetingStrategy: strategy, tenantIds: [], rolloutPercentage: null }));
    };
    const environmentSummary = () => {
        if (!state.environmentId)
            return Summary.placeholder("Please select an environment");
        const selectedEnvironment = environments.find((e) => e.Id === state.environmentId);
        if (!selectedEnvironment)
            throw new Error(`Could not find environment matching ID: ${state.environmentId}`);
        return Summary.summary(<EnvironmentChip environmentName={selectedEnvironment.Name}/>);
    };
    const tenantSummary = () => {
        let summary = "";
        switch (state.tenantTargetingStrategy) {
            case "AllTenants":
                summary = "All tenants";
                break;
            case "SpecificTenants":
                summary = "Some tenants";
                break;
        }
        if (state.excludedTenantIds.length > 0) {
            summary += " with some excluded";
        }
        return state.tenantTargetingStrategy === "AllTenants" ? Summary.default(summary) : Summary.summary(summary);
    };
    const segmentSummary = () => {
        if (!state.segments) {
            return Summary.default("No segments specified");
        }
        return Summary.summary(<SegmentList segments={state.segments}/>);
    };
    const addSegment = () => {
        const key = state.newSegmentKey;
        const value = state.newSegmentValue;
        setState((prevState) => ({ ...prevState, newSegmentKey: "", newSegmentValue: "", segments: [...prevState.segments, { Key: key, Value: value }] }));
    };
    function deleteSegment(segment: Segment) {
        const segments = state.segments;
        const index = segments.findIndex((s) => s.Key === segment.Key && s.Value === segment.Value);
        if (index >= 0) {
            segments.splice(index, 1);
        }
        setState((prevState) => ({ ...prevState, newSegmentKey: "", newSegmentValue: "", segments: [...segments] }));
    }
    return (<OkDialogLayout title={title} cancelButtonLabel="Cancel" errors={null} onOkClick={onSaveClick}>
            <React.Fragment>
                <Switch value={state.isEnabled} onChange={(value) => setState((prevState) => ({
            ...prevState,
            isEnabled: value,
        }))}/>
                <ExpandableFormSection title="Environment" summary={environmentSummary()} errorKey="Environment">
                    <EnvironmentSelect label={"Environment"} environments={environments} value={state.environmentId} onChange={(selected) => {
            setState((prevState) => ({ ...prevState, environmentId: selected }));
        }}/>
                </ExpandableFormSection>
                {
        /* If the project is tenanted, allow targeting specific tenants */
        project.TenantedDeploymentMode !== TenantedDeploymentMode.Untenanted && (<ExpandableFormSection title="Tenants" help="Select which tenants should be targeted by this toggle" summary={tenantSummary()} errorKey={"tenantTargetingStrategy"}>
                            <RadioButtonGroup title="Included tenants" value={state.tenantTargetingStrategy} onChange={(x) => onChangeTenantTargetingStrategy(x)}>
                                <RadioButton label={"All Tenants"} value={"AllTenants"}/>
                                <RadioButton label={"Specific Tenants"} value={"SpecificTenants"}/>
                            </RadioButtonGroup>
                            {state.tenantTargetingStrategy === "SpecificTenants" && (<div>
                                    <TenantMultiSelect label="Included Tenants" items={tenants} value={state.tenantIds} onChange={(x) => setState((prevState) => ({ ...prevState, tenantIds: x }))}/>
                                    <Text label="Percentage of Tenants" value={state.rolloutPercentage ? state.rolloutPercentage.toString() : ""} type="number" onChange={(x) => setState((prevState) => ({ ...prevState, rolloutPercentage: x === "" ? null : ParseHelper.safeParseInt(x) }))}/>
                                </div>)}
                            <TenantMultiSelect label="Excluded Tenants" items={tenants} value={state.excludedTenantIds} onChange={(x) => setState((prevState) => ({ ...prevState, excludedTenantIds: x }))}/>
                        </ExpandableFormSection>)}
                <ExpandableFormSection title="Segments" summary={segmentSummary()} errorKey="segments">
                    <EditableSegmentList segments={state.segments} onRequestDelete={deleteSegment} deleteButtonAccessibleName="Delete Segment"/>
                    <Text label="Key" value={state.newSegmentKey} onChange={(x) => setState((prevState) => ({ ...prevState, newSegmentKey: x }))}/>
                    <Text label="Value" value={state.newSegmentValue} onChange={(x) => setState((prevState) => ({ ...prevState, newSegmentValue: x }))}/>
                    <Button label={"Add Segment"} onClick={addSegment} importance="primary" disabled={!state.newSegmentKey && !state.newSegmentValue}/>
                </ExpandableFormSection>
            </React.Fragment>
        </OkDialogLayout>);
};
