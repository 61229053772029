/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-eq-null */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { css } from "@emotion/css";
import { RadioButtonGroup, RadioButton, BooleanRadioButton, BooleanRadioButtonGroup, Tooltip, Callout } from "@octopusdeploy/design-system-components";
import { CopyIcon } from "@octopusdeploy/design-system-icons";
import { fontSize, space } from "@octopusdeploy/design-system-tokens";
import type { FeedResource, DeploymentActionContainer } from "@octopusdeploy/octopus-server-client";
import { containerRegistryFeedTypes, Permission, ProcessType } from "@octopusdeploy/octopus-server-client";
import { useCallback } from "react";
import * as React from "react";
import { useOptionalProcessBlueprintContext } from "~/areas/projects/components/Process/Blueprints/BlueprintContext";
import { isRunOnBuiltInWorker, isRunOnServerOrWorkerPool } from "~/areas/projects/components/Process/Common/CommonProcessHelpers";
import type { RunOn, RunOnServerOrWorkerPool } from "~/areas/projects/components/Process/types";
import { repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import DebounceValue from "~/components/DebounceValue/DebounceValue";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { possibleFeeds } from "~/components/PackageSelector/PackageSelector";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { FeedSelector, getDefaultFeedId } from "~/components/Selectors/FeedSelector";
import { Note, Text } from "~/components/form";
import { withBoundField } from "~/components/form/BoundField/BoundField";
import callAll from "~/utils/callAll";
import { CodeEditor, TextFormat } from "../CodeEditor/CodeEditor";
export enum StepExecutionOption {
    RunDirectlyOnWorker = "RunDirectlyOnWorker",
    RunInsideContainerOnWorker = "RunInsideContainerOnWorker",
    RunContainerFromInlineSourceOnWorker = "RunContainerFromInlineSourceOnWorker",
    RunContainerFromGitUrlOnWorker = "RunContainerFromGitUrlOnWorker"
}
const BoundDebounceText = withBoundField(DebounceValue(Text));
interface ExecutionContainerImageSelectorProps {
    runOn: RunOnServerOrWorkerPool;
    feeds: FeedResource[];
    autoFocus?: boolean;
    feedError?: string;
    feedSelectLabel?: string;
    imageNameError?: string;
    refreshFeeds(): Promise<void>;
    resetContainer(runOn: RunOnServerOrWorkerPool): void;
    onImageNameChange(value: string): void;
    onContainerGitUrlChange(value: string): void;
    onContainerDockerfileChange(value: string): void;
    onFeedChange(id: string | undefined): void;
    onStepExecutionOptionChange?(option: StepExecutionOption): void;
    containerImageRecommendation?: JSX.Element[];
    doBusyTask: DoBusyTask;
    disableInlineExecutionContainers: boolean;
    processType?: ProcessType;
}
const ExecutionContainerImageSelector: React.FC<ExecutionContainerImageSelectorProps> = (props) => {
    const { feeds: providedFeeds, autoFocus = false, feedError, feedSelectLabel = "Container Registry", refreshFeeds, onFeedChange, onImageNameChange, onContainerGitUrlChange, onContainerDockerfileChange, onStepExecutionOptionChange, resetContainer, runOn, imageNameError, } = props;
    const feedType = containerRegistryFeedTypes();
    const feeds = possibleFeeds(providedFeeds, feedType);
    //TODO: SM - this will eventually become container.Feed, but for now we are keeping this.
    const defaultFeedKey = runOn.container.FeedId ?? getDefaultFeedId(feeds);
    const defaultImageName = runOn.container.Image ?? "";
    const defaultGitUrl = runOn.container.GitUrl ?? "";
    const defaultDockerfile = runOn.container.Dockerfile ?? "";
    const [feedKey, setFeedKey] = React.useState(defaultFeedKey);
    const [imageName, setImageName] = React.useState(defaultImageName);
    const [containerGitUrl, setContainerGitUrl] = React.useState(defaultGitUrl);
    const [containerDockerfile, setContainerDockerfile] = React.useState(defaultDockerfile);
    const [stepExecutionOption, setStepExecutionOption] = React.useState<StepExecutionOption>(getExecutionOption(runOn.runningInContainer, runOn.container));
    const [runInContainerOption, setRunInContainerOption] = React.useState(stepExecutionOption !== StepExecutionOption.RunDirectlyOnWorker);
    const processBlueprintContext = useOptionalProcessBlueprintContext();
    const handleFeedSelected = useCallback((selectedFeed) => {
        if (typeof selectedFeed !== "string") {
            callAll(setFeedKey, onFeedChange)(selectedFeed.Id);
        }
        else {
            callAll(setFeedKey, onFeedChange)(selectedFeed);
        }
    }, [setFeedKey, onFeedChange]);
    const runInsideContainer = stepExecutionOption === StepExecutionOption.RunInsideContainerOnWorker;
    const runInlineContainer = stepExecutionOption === StepExecutionOption.RunContainerFromInlineSourceOnWorker;
    const runContainerFromGitUrl = stepExecutionOption === StepExecutionOption.RunContainerFromGitUrlOnWorker;
    if (!runOn.container.FeedId && defaultFeedKey && runInsideContainer) {
        onFeedChange(defaultFeedKey);
    }
    if (!runOn.container.Image && imageName && runInsideContainer) {
        onImageNameChange(imageName);
    }
    if (!runOn.container.GitUrl && containerGitUrl && runContainerFromGitUrl) {
        onContainerGitUrlChange(containerGitUrl);
    }
    if (!runOn.container.Dockerfile && containerDockerfile && runInlineContainer) {
        onContainerDockerfileChange(containerDockerfile);
    }
    const runningInContainer = Boolean(isRunningInContainer(runOn) || (feedKey && imageName) || containerGitUrl || containerDockerfile);
    const feedViewPermissionGranted = isAllowed({ permission: Permission.FeedView, wildcard: true });
    const runningOnBuiltInWorker = isRunOnBuiltInWorker(runOn);
    return (<>
            {props.disableInlineExecutionContainers ? (<RadioButtonGroup value={stepExecutionOption} onChange={(option) => {
                setStepExecutionOption(option);
                if (onStepExecutionOptionChange) {
                    onStepExecutionOptionChange(option);
                }
            }}>
                    <RadioButton value={StepExecutionOption.RunDirectlyOnWorker} label={`Runs directly on ${runningOnBuiltInWorker ? (props.processType === ProcessType.Blueprint ? "the project-selected worker" : "Octopus Server") : "a worker"}`} isDefault={true}/>
                    <Note>{runningOnBuiltInWorker ? "Octopus Server" : "The worker"} will need the required dependencies pre-installed</Note>

                    <RadioButton value={StepExecutionOption.RunInsideContainerOnWorker} label={`Runs inside a container on ${runningOnBuiltInWorker ? (props.processType === ProcessType.Blueprint ? "the project-selected worker" : "the Octopus Server") : "a worker"}`}/>
                    <Note>{runningOnBuiltInWorker ? "Octopus Server" : "The worker"} must support container execution</Note>
                </RadioButtonGroup>) : (<BooleanRadioButtonGroup value={runInContainerOption} onChange={(runInContainer) => {
                setRunInContainerOption(runInContainer);
                const stepExecution = getExecutionOption(runInContainer, runOn.container);
                setStepExecutionOption(stepExecution);
                if (onStepExecutionOptionChange) {
                    onStepExecutionOptionChange(stepExecution);
                }
            }}>
                    <BooleanRadioButton value={false} label={`Runs directly on ${runningOnBuiltInWorker ? (props.processType === ProcessType.Blueprint ? "the project-selected worker" : "Octopus Server") : "a worker"}`} isDefault={true}/>
                    <Note>{runningOnBuiltInWorker ? (props.processType === ProcessType.Blueprint ? "The project-selected worker" : "Octopus Server") : "The worker"} will need the required dependencies pre-installed</Note>

                    <BooleanRadioButton value={true} label={`Runs inside a container on ${runningOnBuiltInWorker ? (props.processType === ProcessType.Blueprint ? "the project-selected worker" : "the Octopus Server") : "a worker"}`}/>
                    <Note>{runningOnBuiltInWorker ? (props.processType === ProcessType.Blueprint ? "The project-selected worker" : "Octopus Server") : "The worker"} must support container execution</Note>

                    {runInContainerOption && (<RadioButtonGroup value={stepExecutionOption} onChange={(newStepExecutionOption) => {
                    if ((stepExecutionOption === StepExecutionOption.RunInsideContainerOnWorker || newStepExecutionOption === StepExecutionOption.RunInsideContainerOnWorker) && imageName !== "") {
                        setImageName("");
                    }
                    setStepExecutionOption(newStepExecutionOption);
                    if (onStepExecutionOptionChange) {
                        onStepExecutionOptionChange(newStepExecutionOption);
                    }
                }}>
                            <RadioButton value={StepExecutionOption.RunInsideContainerOnWorker} label={`Pull container from a registry`} isDefault={true}/>

                            <RadioButton value={StepExecutionOption.RunContainerFromGitUrlOnWorker} label={`Build container from Git URL`}/>
                            <Note>Not supported for Kubernetes workers</Note>

                            <RadioButton value={StepExecutionOption.RunContainerFromInlineSourceOnWorker} label={`Build container from an inline Dockerfile`}/>
                            <Note>Not supported for Kubernetes workers</Note>
                        </RadioButtonGroup>)}
                </BooleanRadioButtonGroup>)}

            {!feedViewPermissionGranted ? feedViewPermissionMissingCallout(runningInContainer) : ""}

            {runInsideContainer &&
            (props.processType === ProcessType.Blueprint ? (<Callout type={"information"} hideTitle={true}>
                        If you're looking to specify the feed, it will be specified in the project instead of in this template.
                    </Callout>) : (<FeedSelector onChange={(selectedFeed) => {
                    if (typeof selectedFeed !== "string") {
                        callAll(setFeedKey, onFeedChange)(selectedFeed.Id);
                    }
                    else {
                        callAll(setFeedKey, onFeedChange)(selectedFeed);
                    }
                }} feedId={feedKey} feedIdError={feedError} autoFocus={autoFocus} refreshFeeds={refreshFeeds} feeds={feeds} localNames={undefined} feedType={feedType} allowBoundVariables={false}/>))}

            {runContainerFromGitUrl && (<>
                    <Text value={containerGitUrl} label={"Enter URL to Git repository to source Dockerfile from"} onChange={callAll(setContainerGitUrl, onContainerGitUrlChange)}/>
                    <Note>
                        See the <ExternalLink href="https://docs.docker.com/engine/reference/commandline/build/#git-repositories">Docker documentation</ExternalLink> for information on Git URLs and context configuration
                    </Note>
                </>)}

            {runInlineContainer && (<div>
                    <CodeEditor allowFullScreen={true} value={containerDockerfile} language={TextFormat.YAML} autoComplete={[]} autoExpand={true} showToolbar={true} showCopyButton={true} showInsertVariableButton={true} onChange={callAll(setContainerDockerfile, onContainerDockerfileChange)} localNames={processBlueprintContext?.localNames}/>
                    <br />
                    <Note>
                        See the <ExternalLink href="https://octopus.com/docs/projects/steps/execution-containers-for-workers#custom-docker-images">Octopus documentation</ExternalLink> for information and requirements when using custom inline Docker
                        images.
                    </Note>
                </div>)}

            {((runInsideContainer && feedViewPermissionGranted) || runContainerFromGitUrl || runInlineContainer) && (<>
                    <BoundDebounceText variableLookup={{
                localNames: processBlueprintContext?.localNames,
            }} debounceDelay={500} placeholder="Enter the image, including the tag" value={imageName} onChange={callAll(setImageName, onImageNameChange)} error={imageNameError}/>
                    {runInsideContainer &&
                feedViewPermissionGranted &&
                (props.containerImageRecommendation ? <Note>{props.containerImageRecommendation}</Note> : <ActionContainersHelp doBusyTask={props.doBusyTask} setImageName={setImageName} onImageNameChange={onImageNameChange}/>)}
                    {(runContainerFromGitUrl || runInlineContainer) && (<>
                            <Note>
                                If image name is left empty, we will use a default image name: <code>octopus/[actionid]:latest</code>
                                <br />
                                <br />
                                <Callout title="Step execution speed" type={"information"}>
                                    The first time the step runs, it will take a bit longer because the container image is being built for the first time. Subsequent runs will be faster because the image is cached
                                    {!runningOnBuiltInWorker ? <em>, as long as it runs on the same worker</em> : ""}.
                                </Callout>
                            </Note>
                        </>)}
                </>)}
        </>);
};
ExecutionContainerImageSelector.displayName = "ExecutionContainerImageSelector"
interface ActionContainersHelpProps {
    setImageName: (image: string) => void;
    onImageNameChange: (value: string) => void;
    doBusyTask: DoBusyTask;
}
function ActionContainersHelp({ setImageName, onImageNameChange, doBusyTask }: ActionContainersHelpProps) {
    const [latestWindows2019Image, setLatestWindows2019Image] = React.useState("");
    const [latestWindows2022Image, setLatestWindows2022Image] = React.useState("");
    const [latestUbuntu2204Image, setLatestUbuntu2204Image] = React.useState("");
    useDoBusyTaskEffect(doBusyTask, async () => {
        await findLatestWorkerToolsImageTag(setLatestWindows2019Image, setLatestWindows2022Image, setLatestUbuntu2204Image);
    }, []);
    const fillerPhrase = "octopusdeploy/worker-tools:";
    const latestUbuntuImageLabel = latestUbuntu2204Image.replace(fillerPhrase, "\u2026");
    const latestWindows2022ImageLabel = latestWindows2022Image.replace(fillerPhrase, "\u2026");
    const latestWindows2019ImageLabel = latestWindows2019Image.replace(fillerPhrase, "\u2026");
    return (<div>
            <Note>The image should include the tag</Note>

            <Note>
                Use an <ExternalLink href="WorkerToolsDockerHub">octopusdeploy/worker-tools</ExternalLink> image:
                <ul className={useImageContainerStyles}>
                    {latestUbuntu2204Image && (<li>
                            Ubuntu 22.04:{" "}
                            <Tooltip content={`Click to use ${latestUbuntu2204Image}`}>
                                <code className={useImageStyles} onClick={(e) => setImageOnClick(e, latestUbuntu2204Image, setImageName, onImageNameChange)}>
                                    {latestUbuntuImageLabel} <CopyIcon size={24}/>
                                </code>
                            </Tooltip>
                        </li>)}
                    {latestWindows2022Image && (<li>
                            Windows 2022:{" "}
                            <Tooltip content={`Click to use ${latestWindows2022Image}`}>
                                <code className={useImageStyles} onClick={(e) => setImageOnClick(e, latestWindows2022Image, setImageName, onImageNameChange)}>
                                    {latestWindows2022ImageLabel} <CopyIcon size={24}/>
                                </code>
                            </Tooltip>
                        </li>)}
                    {latestWindows2019Image && (<li>
                            Windows 2019:{" "}
                            <Tooltip content={`Click to use ${latestWindows2019Image}`}>
                                <code className={useImageStyles} onClick={(e) => setImageOnClick(e, latestWindows2019Image, setImageName, onImageNameChange)}>
                                    {latestWindows2019ImageLabel} <CopyIcon size={24}/>
                                </code>
                            </Tooltip>
                        </li>)}
                </ul>
            </Note>
        </div>);
}
const feedViewPermissionMissingCallout = (isRunningInContainerCheck: boolean) => {
    return (<Callout type={"warning"} title={`FeedView Permission required`}>
            <div>You will need FeedView permission to {isRunningInContainerCheck ? "edit selected container and/or feed" : "enable running inside a container"}</div>
            <ExternalLink href="spaces-and-permissions">System and Space Permissions</ExternalLink>{" "}
        </Callout>);
};
const useImageStyles = css({
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    svg: {
        marginLeft: space[4],
        width: fontSize["medium"],
        height: fontSize["medium"],
    },
});
const useImageContainerStyles = css({
    "> li": {
        marginBottom: space[6],
    },
    "> li:last-child": {
        marginBottom: 0,
    },
});
const findLatestWorkerToolsImageTag = async (setLatestWindows2019Image: (image: string) => void, setLatestWindows2022Image: (image: string) => void, setLatestUbuntu2204Image: (image: string) => void) => {
    const latestImages = await repository.WorkerTools.getWorkerToolsLatestImages();
    const latestWindows2019Image = latestImages["windows.ltsc2019"];
    const latestWindows2022Image = latestImages["windows.ltsc2022"];
    const latestUbuntu2204Image = latestImages["ubuntu.22.04"];
    if (!!latestWindows2019Image) {
        setLatestWindows2019Image(latestWindows2019Image);
    }
    if (!!latestWindows2022Image) {
        setLatestWindows2022Image(latestWindows2022Image);
    }
    if (!!latestUbuntu2204Image) {
        setLatestUbuntu2204Image(latestUbuntu2204Image);
    }
};
const getExecutionOption = (runningInContainer: boolean, container: DeploymentActionContainer): StepExecutionOption => {
    if (runningInContainer) {
        if (container.GitUrl) {
            return StepExecutionOption.RunContainerFromGitUrlOnWorker;
        }
        if (container.Dockerfile) {
            return StepExecutionOption.RunContainerFromInlineSourceOnWorker;
        }
        return StepExecutionOption.RunInsideContainerOnWorker;
    }
    return StepExecutionOption.RunDirectlyOnWorker;
};
export const isRunningInContainer = (runOn: RunOn): boolean => {
    if (!isRunOnServerOrWorkerPool(runOn)) {
        return false;
    }
    if (!runOn.runningInContainer) {
        return false;
    }
    if (runOn.container.FeedId === null || runOn.container.Image === null || runOn.container.GitUrl === null || runOn.container.Dockerfile === null) {
        return false;
    }
    return true;
};
const setImageOnClick = (e: React.MouseEvent<Element, MouseEvent>, image: string, setImage: (image: string) => void, onImageNameChange: (value: string) => void) => {
    e.preventDefault();
    setImage(image);
    onImageNameChange(image);
};
export default ExecutionContainerImageSelector;
