import { NavigationButton, NavigationButtonType, type PageAction, type PrimaryPageAction } from "@octopusdeploy/design-system-components";
import type { CertificateResource, ResourceCollection } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { createAddCertificateAnalyticsEvent } from "~/areas/library/components/Certificates/Certificate/amplitudeAnalytics";
import { repository } from "~/clientInstance";
import ActionList from "~/components/ActionList";
import { useLegacyDoBusyTask } from "~/components/DataBaseComponent/useLegacyDoBusyTask";
import { PageContent } from "~/components/PageContent/PageContent";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import CertificateSearch from "~/components/form/CertificateSearch/CertificateSearch";
import CertificateOnboarding from "./CertificateOnboarding/CertificateOnboarding";
import CertificateSidebar from "./CertificateSidebar";
interface CertificatesPageProps {
    spaceId: string;
    loaderData: CertificatesPageLoaderData;
}
export async function certificatesPageLoader(): Promise<CertificatesPageLoaderData> {
    return {
        certificates: await repository.Certificates.list(),
    };
}
export interface CertificatesPageLoaderData {
    certificates: ResourceCollection<CertificateResource>;
}
export const certificatesPageTitle = "Certificates";
export default function CertificatesPage({ spaceId, loaderData }: CertificatesPageProps) {
    const trackEvent = useTrackEvent();
    const { doBusyTask, status: legacyDoBusyTaskStatus } = useLegacyDoBusyTask();
    const addCertificateClicked = () => {
        trackEvent(createAddCertificateAnalyticsEvent("Page"));
    };
    const addCertificatePageAction: PrimaryPageAction = {
        type: "navigate",
        hasPermissions: isAllowed({ permission: Permission.CertificateCreate, wildcard: true }),
        label: "Add Certificate",
        path: links.createCertificatePage.generateUrl({ spaceId }),
        onClick: addCertificateClicked,
    };
    const archivePageAction: PageAction = {
        type: "navigate",
        buttonType: "secondary",
        label: "View archive",
        path: links.archiveCertificatePage.generateUrl({ spaceId }),
    };
    const certificateOnboardingButtons = (<ActionList alignStart={true} actions={[
            <PermissionCheck permission={Permission.CertificateCreate}>
                    <NavigationButton type={NavigationButtonType.Primary} label="Add Certificate" href={links.createCertificatePage.generateUrl({ spaceId })} onClick={() => addCertificateClicked()}/>
                </PermissionCheck>,
            <NavigationButton type={NavigationButtonType.Secondary} label="View archive" href={links.archiveCertificatePage.generateUrl({ spaceId })}/>,
        ]}/>);
    const hasCertificates = loaderData.certificates.TotalResults > 0;
    if (!hasCertificates) {
        return (<PageContent header={{ title: certificatesPageTitle }} legacyStatus={legacyDoBusyTaskStatus}>
                <CertificateOnboarding actionButtons={certificateOnboardingButtons}/>
            </PageContent>);
    }
    return (<PageContent header={{ title: certificatesPageTitle, primaryAction: addCertificatePageAction, pageActions: [archivePageAction] }} sidebar={<CertificateSidebar />} legacyStatus={legacyDoBusyTaskStatus}>
            <CertificateSearch certificates={loaderData.certificates} doBusyTask={doBusyTask}/>
        </PageContent>);
}
