import type { SpaceResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { getImportExportMenuItems } from "~/areas/projects/components/ImportExport/ImportExportMenu";
import Tasks from "~/areas/tasks/components/Tasks/Tasks";
import { repository, session } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import DataBaseComponent from "~/components/DataBaseComponent";
import { PageContent } from "~/components/PageContent/PageContent";
interface ImportExportProjectTasksPageState extends DataBaseComponentState {
    space?: SpaceResource;
}
interface ImportExportProjectTasksPageProps {
    spaceId: string;
}
export class ImportExportProjectTasksPage extends DataBaseComponent<ImportExportProjectTasksPageProps, ImportExportProjectTasksPageState> {
    constructor(props: ImportExportProjectTasksPageProps) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            if (!repository.spaceId) {
                throw new Error("Attempted to render ImportExportTasks in a system context. This should never happen.");
            }
            const [space] = await Promise.all([repository.Spaces.get(repository.spaceId)]);
            this.setState({
                space,
            });
        });
    }
    isSpaceManager(): boolean {
        if (!session.currentPermissions) {
            throw new Error("Attempted to access the current user's permissions, but they weren't found. This should never happen.");
        }
        return this.state.space !== undefined && session.currentPermissions.isSpaceManager(this.state.space);
    }
    render() {
        const isSpaceManager = this.isSpaceManager();
        return (<Tasks renderLayout={(layoutProps) => (<PageContent header={{ title: "Import/Export Tasks", breadcrumbs: [{ label: "Projects", pageUrl: links.projectsPage.generateUrl({ spaceId: this.props.spaceId }) }], overflowActions: getImportExportMenuItems(this.props.spaceId) }} busy={layoutProps.busy} errors={this.errors} fullWidth={true} callout={this.state.space && !isSpaceManager ? { type: "information", title: "Permission Required", content: "The Space Manager permission is required to import/export projects from this space." } : undefined}>
                        {this.state.space && isSpaceManager && layoutProps.children}
                    </PageContent>)} restrictToTaskTypes={["ExportProjects", "ImportProjects"]} hideAdvancedFilters={true}/>);
    }
    static displayName = "ImportExportProjectTasksPage";
}
