import type { SpaceResource } from "@octopusdeploy/octopus-server-client";
import { useState } from "react";
import * as React from "react";
import type { BusyState } from "~/components/BusyFromPromise/BusyFromPromise";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import { SpaceMultiSelect } from "~/components/MultiSelect/SpaceMultiSelect";
interface ShareTemplateDialogProps {
    spaces: SpaceResource[];
    spacesSharedTo: string[];
    busy: BusyState;
    onSave: (spaces: string[]) => Promise<void>;
    errors: Error[];
}
export function ShareTemplateDialog({ spaces, spacesSharedTo, busy, onSave, errors }: ShareTemplateDialogProps) {
    const [selectedSpaces, setSelectedSpaces] = useState<string[]>(spacesSharedTo);
    return (<SaveDialogLayout errors={errors} title={"Share to..."} busy={busy} onSaveClick={async () => {
            await onSave(selectedSpaces);
            return true;
        }} saveButtonLabel={"Share"}>
            <p>Select the spaces where this process template will be shared</p>
            <SpaceMultiSelect items={spaces} onChange={(spaces) => setSelectedSpaces(spaces)} value={selectedSpaces}/>
        </SaveDialogLayout>);
}
