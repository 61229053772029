import { logger } from "@octopusdeploy/logging";
import type { RequestCorrelationContext } from "@octopusdeploy/octopus-server-client";
import { Repository } from "@octopusdeploy/octopus-server-client";
import type { DependencyList } from "react";
import { useEffect, useCallback, useMemo, useRef, useState } from "react";
import { v4 } from "uuid";
import { useOctopusClient } from "../OctopusClientContext";
import { useQueryCorrelationContext, useQueryStatusUpdaters } from "./QueryContext";
/**
 * Loads data from the backend.
 * The data will initially be loaded as this hook mounts (unless configured with an `initialResult`)
 * From there, the data can be refetched by various mechanisms:
 * - Manually with the refetch function returned from this hook
 * - When the dependencies change
 * - Periodically if a refresh interval is supplied
 * @template ResultData The type of data return from the query function
 * @template InitialData The type of data supplied as an `initialResult` for the hook. Either `ResultData`, or `null` if there is no `initialResult`.
 * @param {(repository: Repository) => Promise<ResultData>} query The query that loads the data from the server
 * @param {DependencyList} deps The dependencies of the query. This should be an exhaustive list of dependencies.
 * @param queryName A short label describing what is being queried. Used to correlate events associated with the query. Aim for something unique to the context within which it is used (such as the current page).
 * @param {QueryOptions<ResultData, InitialData>} options Configuration options for the hook
 * @returns {ExternallyManagedStatusQuery<ResultData, InitialData>} The isLoading and error states are externally managed and should not be rendered by the current component. Use the {@link useInlineStatusQuery} hook instead if you need to show the state of this query in the current component.
 */
export function useQuery<ResultData, InitialData extends ResultData | null = null>(query: (repository: Repository) => Promise<ResultData>, deps: DependencyList, queryName: string, options?: QueryOptions<ResultData, InitialData>): ExternallyManagedStatusQuery<ResultData, InitialData> {
    const queryHookInstanceId = useMemo(() => v4(), []);
    const [externallyManagedQueryState, externallyManagedQueryStateUpdaters] = useExternallyManagedQueryState<ResultData, InitialData>(queryHookInstanceId, options?.initialResult);
    const queryUpdaters = useQueryEffect(queryHookInstanceId, query, deps, externallyManagedQueryStateUpdaters, queryName, options);
    return {
        ...externallyManagedQueryState,
        ...queryUpdaters,
    };
}
export type ExternallyManagedStatusQuery<ResultData, InitialData extends ResultData | null> = {
    result: ResultData | InitialData;
} & QueryUpdaters;
/**
 * Loads data from the backend.
 * The data will initially be loaded as this hook mounts (unless configured with an `initialResult`)
 * From there, the data can be refetched by various mechanisms:
 * - Manually with the refetch function returned from this hook
 * - When the dependencies change
 * - Periodically if a refresh interval is supplied
 * @template ResultData The type of data return from the query function
 * @template InitialData The type of data supplied as an `initialResult` for the hook. Either `ResultData`, or `null` if there is no `initialResult`.
 * @param {(repository: Repository) => Promise<ResultData>} query The query that loads the data from the server
 * @param {DependencyList} deps The dependencies of the query. This should be an exhaustive list of dependencies.
 * @param queryName A short label describing what is being queried. Used to correlate events associated with the query. Aim for something unique to the context within which it is used (such as the current page).
 * @param {QueryOptions<ResultData, InitialData>} options Configuration options for the hook
 * @returns {InlineStatusQuery<ResultData, InitialData>} The isLoading and error states should also be shown to the user to given them feedback about the state fo the query. Use the {@link useQuery} hook instead if you want the isLoading and error states to be managed externally.
 */
export function useInlineStatusQuery<ResultData, InitialData extends ResultData | null = null>(query: (repository: Repository) => Promise<ResultData>, deps: DependencyList, queryName: string, options?: QueryOptions<ResultData, InitialData>): InlineStatusQuery<ResultData, InitialData> {
    const queryHookInstanceId = useMemo(() => v4(), []);
    const [inlineQueryState, inlineQueryStateUpdaters] = useInlineStatusQueryState<ResultData, InitialData>(options?.initialResult);
    const queryUpdaters = useQueryEffect(queryHookInstanceId, query, deps, inlineQueryStateUpdaters, queryName, options);
    return {
        ...inlineQueryState,
        ...queryUpdaters,
    };
}
export type InlineStatusQuery<ResultData, InitialData extends ResultData | null> = InlineQueryResult<ResultData, InitialData> & QueryUpdaters;
export type InlineQueryResult<ResultData, InitialData extends ResultData | null> = InitialLoadingState<InitialData> | HasLoadedQueryResult<ResultData> | HasErrorQueryResult<ResultData, InitialData> | RefetchingQueryResult<ResultData, InitialData>;
export interface InitialLoadingState<InitialData> {
    isLoading: boolean; // If we had supplied an initial result, then we won't do any initial loading
    error: null;
    result: InitialData;
}
export interface HasErrorQueryResult<ResultData, InitialData extends ResultData | null> {
    isLoading: false;
    error: Error;
    result: ResultData | InitialData; // Keep showing the previous query result if there was an error
}
export interface HasLoadedQueryResult<ResultData> {
    isLoading: false;
    error: null;
    result: ResultData;
}
export interface RefetchingQueryResult<ResultData, InitialData extends ResultData | null> {
    // Periodic refreshes shouldn't usually be displayed to users
    // These are used for "live" results, and should have another mechanism for showing that the result is live (e.g. a text description, animations, etc.)
    // Therefore isLoading is always false for periodic refreshes, and true for other kinds of refreshes
    isLoading: boolean;
    error: null;
    result: InitialData | ResultData; // Keep showing the previous query result while loading
}
export interface QueryOptions<ResultData, InitialData extends ResultData | null> {
    /**
     * Supplies the initial result of the query.
     * If an initial result is supplied, the query will not initially do any loading (since it already has a result).
     */
    initialResult?: InitialData;
    /**
     * Supplies the query to use for the initial fetch that happens when this hook mounts.
     * Use this property if you want to start the initial query elsewhere (for example in a loader).
     */
    initialQuery?: Promise<ResultData>;
    /**
     * When supplied, the query will periodically refetch whenever this interval elapses.
     */
    refetchIntervalInMs?: number;
}
export interface QueryUpdaters {
    refetch(): void;
}
export function useQueryEffect<ResultData, InitialData extends ResultData | null>(queryHookInstanceId: string, query: (repository: Repository) => Promise<ResultData>, deps: DependencyList, queryStateUpdaters: QueryStateUpdaters<ResultData>, queryName: string, options?: QueryOptions<ResultData, InitialData>): QueryUpdaters {
    if (options?.initialQuery !== undefined && options?.initialResult !== undefined) {
        throw new Error("Both an initial query and an initial result were supplied for a query. Only one of these parameters is allowed.");
    }
    const client = useOctopusClient();
    const queryRef = useRef(query);
    queryRef.current = query;
    const queryNameRef = useRef(queryName);
    const initialQueryRef = useRef(options?.initialQuery);
    const correlationContextFromProvider = useQueryCorrelationContext();
    const correlationContextForQueryHook = useMemo(() => ({
        ...correlationContextFromProvider,
        queryhookinstanceid: queryHookInstanceId,
        query: queryNameRef.current,
    } satisfies RequestCorrelationContext), [correlationContextFromProvider, queryHookInstanceId]);
    const { resumePeriodicRefetchingOnceQueryCompletes, pausePeriodicRefetchingWhileQueryExecuting, refetch, fetchIntent } = useFetchIntents(deps, options?.initialResult !== undefined, options?.refetchIntervalInMs);
    useEffect(() => {
        if (fetchIntent === "no-fetch")
            return;
        const currentFetchIntent = fetchIntent;
        const currentQueryName = queryNameRef.current;
        const abortController = new AbortController();
        const correlationContextForIndividualFetch: RequestCorrelationContext = {
            ...correlationContextForQueryHook,
            queryinvocationid: v4(),
            querytype: fetchIntent.trigger,
        };
        const repository = new Repository(client, {
            correlationContext: correlationContextForIndividualFetch,
            abortSignal: abortController.signal,
        });
        const queryLogger = logger.forContext(correlationContextForIndividualFetch);
        let queryCompleted = false;
        // noinspection JSIgnoredPromiseFromCall
        executeQuery();
        return () => {
            if (!queryCompleted) {
                queryLogger.debug("In progress {query} query is being aborted", { query: currentQueryName });
            }
            abortController.abort();
            queryStateUpdaters.abort();
        };
        async function executeQuery() {
            pausePeriodicRefetchingWhileQueryExecuting();
            queryStateUpdaters.startLoading(currentFetchIntent.trigger === "periodic" ? "periodic" : "other");
            queryLogger.debug("Starting {query} query", { query: currentQueryName });
            try {
                const query = currentFetchIntent.trigger === "initial" && initialQueryRef.current !== undefined ? initialQueryRef.current : queryRef.current(repository);
                const result = await query;
                if (!abortController.signal.aborted) {
                    queryCompleted = true;
                    queryLogger.debug("Finished {query} query", { query: currentQueryName });
                    queryStateUpdaters.setResult(result);
                }
            }
            catch (error) {
                if (!abortController.signal.aborted) {
                    queryCompleted = true;
                    queryLogger.debug("Error during {query} query", { query: currentQueryName, error });
                    if (error instanceof Error) {
                        queryStateUpdaters.setError(error);
                    }
                    else {
                        queryStateUpdaters.setError(new Error(`Unknown error: ${JSON.stringify(error)}`));
                    }
                }
            }
            finally {
                if (!abortController.signal.aborted) {
                    resumePeriodicRefetchingOnceQueryCompletes();
                }
            }
        }
    }, [queryStateUpdaters, client, fetchIntent, resumePeriodicRefetchingOnceQueryCompletes, pausePeriodicRefetchingWhileQueryExecuting, correlationContextForQueryHook]);
    return { refetch };
}
function useExternallyManagedQueryState<ResultData, InitialData extends ResultData | null>(queryHookInstanceId: string, initialResult?: InitialData): [
    {
        result: ResultData | InitialData;
    },
    QueryStateUpdaters<ResultData>
] {
    const queryStatusUpdaters = useQueryStatusUpdaters(queryHookInstanceId);
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const [result, setResult] = useState<InitialData | ResultData>(initialResult ?? (null as InitialData));
    const queryStateUpdaters: QueryStateUpdaters<ResultData> = useMemo(() => ({
        startLoading(trigger: "periodic" | "other") {
            queryStatusUpdaters.start(trigger);
        },
        setError(error: Error) {
            queryStatusUpdaters.error(error);
        },
        setResult(result: ResultData) {
            queryStatusUpdaters.finish();
            setResult(result);
        },
        abort() {
            queryStatusUpdaters.abort();
        },
    }), [queryStatusUpdaters]);
    return [{ result }, queryStateUpdaters];
}
function useInlineStatusQueryState<ResultData, InitialData extends ResultData | null>(initialResult?: InitialData): [
    InlineQueryResult<ResultData, InitialData>,
    QueryStateUpdaters<ResultData>
] {
    const [queryState, setQueryState] = useState<InlineQueryResult<ResultData, InitialData>>(initialResult === undefined
        ? {
            isLoading: true,
            error: null,
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            result: null as InitialData,
        }
        : {
            error: null,
            isLoading: false,
            result: initialResult,
        });
    const updaters: QueryStateUpdaters<ResultData> = useMemo(() => ({
        startLoading(trigger: "periodic" | "other") {
            // Periodic refreshes shouldn't usually be displayed to users
            // These are used for "live" results, and should have another mechanism for showing that the result is live (e.g. a text description, animations, etc.)
            // Therefore isLoading is always false for periodic refreshes, and true for other kinds of refreshes
            const isLoading = trigger !== "periodic";
            setQueryState((prev) => ({ isLoading, result: prev.result, error: null }));
        },
        setError(error: Error) {
            setQueryState((prev) => ({ isLoading: false, result: prev.result, error }));
        },
        setResult(result: ResultData) {
            setQueryState({ isLoading: false, result, error: null });
        },
        abort() { },
    }), []);
    return [queryState, updaters];
}
interface QueryStateUpdaters<ResultData> {
    startLoading(trigger: "periodic" | "other"): void;
    setResult(result: ResultData): void;
    setError(error: Error): void;
    abort(): void;
}
/**
 * The fetch intent represents an intent to refetch the query. It also includes a "trigger" reason to describe why the query should be refetched.
 * @param deps The dependencies of the query - when these change, we should refetch the query
 * @param hasInitialResult Whether the query has an initialResult
 * @param refreshIntervalInMs The interval which we should use to periodically execute the query. Undefined if this functionality is disabled.
 */
export function useFetchIntents(deps: DependencyList, hasInitialResult: boolean, refreshIntervalInMs?: number) {
    const { fetchIntent, scheduleFetch, onDependenciesChangedEffect } = useFetchIntentState(hasInitialResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => onDependenciesChangedEffect(), [onDependenciesChangedEffect, ...deps]);
    const { refreshIntervalIntent, resumePeriodicRefetchingOnceQueryCompletes, pausePeriodicRefetchingWhileQueryExecuting, pausePeriodRefetchingWhileTabNotVisible, resumePeriodRefetchingOnceTabVisible } = useRefreshIntervalIntent(hasInitialResult, refreshIntervalInMs);
    useEffect(() => {
        if (refreshIntervalIntent === "disabled" || refreshIntervalIntent === "pause while query is executing" || refreshIntervalIntent === "pause while tab not visible")
            return;
        if (refreshIntervalInMs === undefined)
            return;
        const timeout = setTimeout(() => scheduleFetch("periodic"), refreshIntervalInMs);
        return () => clearTimeout(timeout);
    }, [refreshIntervalInMs, refreshIntervalIntent, scheduleFetch]);
    const handleVisibilityChanged = useCallback(() => {
        if (refreshIntervalInMs === undefined)
            return;
        if (document.hidden) {
            pausePeriodRefetchingWhileTabNotVisible();
        }
        else {
            resumePeriodRefetchingOnceTabVisible();
            scheduleFetch("periodic");
        }
    }, [pausePeriodRefetchingWhileTabNotVisible, refreshIntervalInMs, resumePeriodRefetchingOnceTabVisible, scheduleFetch]);
    useEffect(() => {
        document.addEventListener("visibilitychange", handleVisibilityChanged);
        return () => document.removeEventListener("visibilitychange", handleVisibilityChanged);
    }, [handleVisibilityChanged]);
    const refetch = useCallback(() => scheduleFetch("manual"), [scheduleFetch]);
    return {
        fetchIntent,
        refetch,
        pausePeriodicRefetchingWhileQueryExecuting,
        resumePeriodicRefetchingOnceQueryCompletes,
    };
}
type FetchIntent = {
    fetchCount: number;
    trigger: FetchTrigger;
} | "no-fetch";
type FetchTrigger = "manual" | "periodic" | "dependencies-changed" | "initial";
function useFetchIntentState(hasInitialResult: boolean) {
    const hasInitialResultRef = useRef(hasInitialResult);
    // null represents the fetchIntent being uninitialized.
    // We need to differentiate this from the "no-fetch" state so that we can handle the initial effect that fires from the dependency array
    // and use this decide to do set the "initial" render intent. This should only be set once for the lifetime of the hook.
    const [fetchIntent, setFetchIntent] = useState<FetchIntent | null>(null);
    const scheduleFetch = useCallback((trigger: "manual" | "periodic") => setFetchIntent((prev) => ({
        trigger,
        fetchCount: prev === null || prev === "no-fetch" ? 0 : prev.fetchCount + 1,
    })), []);
    const onDependenciesChangedEffect = useCallback(() => setFetchIntent((prev) => {
        if (prev === null) {
            if (hasInitialResultRef.current) {
                return "no-fetch";
            }
            else {
                // We only ever set the "initial" trigger the first time this function is called (i.e. on mount)
                // This is the reason the fetchIntent initialized to null - so that we can detect this case.
                return {
                    trigger: "initial",
                    fetchCount: 0,
                };
            }
        }
        return {
            trigger: "dependencies-changed",
            fetchCount: prev === "no-fetch" ? 0 : prev.fetchCount + 1,
        };
    }), []);
    // The fetchIntent being null is an implementation detail of this hook and shouldn't be exposed outwards, hence the null coalesce to `no-fetch`.
    return { fetchIntent: fetchIntent ?? "no-fetch", scheduleFetch, onDependenciesChangedEffect } as const;
}
export function useRefreshIntervalIntent(hasInitialResult: boolean, refreshIntervalInMs?: number) {
    const initialRefreshIntervalIntent = hasInitialResult
        ? // If we do have an initial result, then we should start the periodic refresh interval immediately (or disable it entirely if there is no interval supplied)
            refreshIntervalInMs === undefined
                ? "disabled"
                : 0
        : "pause while query is executing"; // If we don't have an initial result, then we should already be running an initial query and the periodic refreshing should start paused
    const [refreshIntervalIntent, setRefreshIntervalIntent] = useState<"disabled" | "pause while query is executing" | "pause while tab not visible" | number>(initialRefreshIntervalIntent);
    const pausePeriodicRefetchingWhileQueryExecuting = useCallback(() => setRefreshIntervalIntent("pause while query is executing"), []);
    const resumePeriodicRefetchingOnceQueryCompletes = useCallback(() => setRefreshIntervalIntent((prev) => (typeof prev === "number" ? prev + 1 : 0)), []);
    const pausePeriodRefetchingWhileTabNotVisible = useCallback(() => {
        setRefreshIntervalIntent("pause while tab not visible");
    }, []);
    const resumePeriodRefetchingOnceTabVisible = useCallback(() => {
        setRefreshIntervalIntent((prev) => (typeof prev === "number" ? prev + 1 : 0));
    }, []);
    return {
        refreshIntervalIntent,
        pausePeriodicRefetchingWhileQueryExecuting,
        resumePeriodicRefetchingOnceQueryCompletes,
        pausePeriodRefetchingWhileTabNotVisible,
        resumePeriodRefetchingOnceTabVisible,
    };
}
