/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { Modifier } from "@dnd-kit/core";
import { useDraggable } from "@dnd-kit/core";
import * as React from "react";
import ResizeColumnHandle from "~/primitiveComponents/dataDisplay/ScrollTable/ResizeColumnHandle/ResizeColumnHandle";
export interface ColumnMeasurements {
    rightWidth: number;
    leftWidth: number;
    rightMinimumWidth: number;
    leftMinimumWidth: number;
}
interface DraggableResizeColumnHandleProps {
    columnIndex: number;
    isHovered: boolean;
    getColumnMeasurements(): ColumnMeasurements;
}
export function DraggableResizeColumnHandle({ columnIndex, isHovered, getColumnMeasurements }: DraggableResizeColumnHandleProps) {
    const { attributes, listeners, setNodeRef, transform, isDragging, active } = useDraggable({
        id: `resize-handle-${columnIndex}`,
        data: {
            columnIndex,
            getColumnMeasurements,
        },
    });
    return (<div ref={setNodeRef} {...listeners} {...attributes} style={isDragging
            ? {
                transform: `translateX(${transform?.x ?? 0}px)`,
            }
            : undefined}>
            <ResizeColumnHandle isActive={isDragging || (!active && isHovered)}/>
        </div>);
}
export interface ResizeHandleDragData {
    columnIndex: number;
    getColumnMeasurements(): ColumnMeasurements;
}
export function isResizeHandleDragData(value: unknown): value is ResizeHandleDragData {
    return (typeof value === "object" && value && "getColumnMeasurements" in value) ?? false;
}
export const constrainResizeHandleDragDistance: Modifier = ({ transform, active }) => {
    const currentItemData = active?.data?.current;
    if (!currentItemData || !isResizeHandleDragData(currentItemData))
        return transform;
    const measurements = currentItemData.getColumnMeasurements();
    const transformX = getBoundedDeltaX(transform.x, measurements);
    return {
        ...transform,
        x: transformX,
    };
};
export function getBoundedDeltaX(actualMouseDeltaX: number, measurements: ColumnMeasurements) {
    const boundedRightOffset = measurements.rightWidth - measurements.rightMinimumWidth;
    const boundedLeftOffset = -(measurements.leftWidth - measurements.leftMinimumWidth);
    return Math.min(boundedRightOffset, Math.max(actualMouseDeltaX, boundedLeftOffset));
}
