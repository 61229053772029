import { TriggerActionCategory } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { useAllFeedTypesForExternalFeeds } from "~/areas/projects/components/Triggers/Feed/useAllFeedTypesForExternalFeeds";
import EditFeedTrigger from "./EditReleaseCreationFeedTrigger";
interface CreateReleaseCreationFeedTriggersPageProps {
    spaceId: string;
    projectSlug: string;
}
export function CreateReleaseCreationFeedTriggersPage({ spaceId, projectSlug }: CreateReleaseCreationFeedTriggersPageProps) {
    const triggerFromAllFeeds = useAllFeedTypesForExternalFeeds();
    return <EditFeedTrigger create={true} triggerActionCategory={TriggerActionCategory.Deployment} spaceId={spaceId} projectSlug={projectSlug} triggerFromAllFeeds={triggerFromAllFeeds}/>;
}
