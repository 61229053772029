import { ControlType, VariableType } from "@octopusdeploy/octopus-server-client";
export function getVariableTypeFromDisplaySettings(type?: ControlType): VariableType {
    switch (type) {
        case ControlType.Sensitive:
            return VariableType.Sensitive;
        case ControlType.Certificate:
            return VariableType.Certificate;
        case ControlType.AmazonWebServicesAccount:
            return VariableType.AmazonWebServicesAccount;
        case ControlType.AzureAccount:
            return VariableType.AzureAccount;
        case ControlType.GoogleCloudAccount:
            return VariableType.GoogleCloudAccount;
        case ControlType.GenericOidcAccount:
            return VariableType.GenericOidcAccount;
        case ControlType.WorkerPool:
            return VariableType.WorkerPool;
        case ControlType.UsernamePasswordAccount:
            return VariableType.UsernamePasswordAccount;
        default:
            return VariableType.String;
    }
}
