import { Callout } from "@octopusdeploy/design-system-components";
import type { PackageReferenceProperties } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { BundledToolsProperties } from "~/components/BundledTools/BundledToolsEditBase";
import { BundledToolsEditBase } from "~/components/BundledTools/BundledToolsEditBase";
import FeatureToggleVisibility from "~/components/FeatureToggle/New/FeatureToggleVisibility";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import Note from "../../../primitiveComponents/form/Note/Note";
import type { ActionEditProps } from "../pluginRegistry";
import type { ScriptPackageProperties } from "../script/ScriptPackageReferenceDialog";
function AzureBundledToolsCallout() {
    return (<Callout type={"warning"} title={"Not recommended"}>
            Using the Azure tools bundled with Octopus is <strong>not recommended</strong>.
            <br />
            <br />
            For <strong>Windows</strong> workers, Octopus server can supply Azure CLI 2.0.50 and Azure Resource Manager (ARM) Powershell modules 6.13.1.
            <br />
            <br />
            For <strong>Linux</strong> workers the Azure CLI must be pre-installed.
            <br />
            <br />
            Learn more about <ExternalLink href="AzureTools">Azure Tools</ExternalLink>.
        </Callout>);
}
export function AzureBundledToolsForServiceFabricScriptsEdit(props: ActionEditProps<BundledToolsProperties, PackageReferenceProperties | ScriptPackageProperties>) {
    return (<FeatureToggleVisibility toggle="DeprecateBundledToolsFeatureToggle" disabledContent={<BundledToolsEditBase actionProps={props} title="Azure Tools" notes={<Note>
                            Scripts executed in this step may need to use the Azure CLI to authenticate to Azure or perform other actions. Learn more about <ExternalLink href="AzureTools">Azure Tools</ExternalLink>.
                        </Note>} help="Select whether to use the bundled Azure tools or using tooling pre-installed on the worker" useBundledToolsSummary="Use Azure tools bundled with Octopus" usePreInstalledToolsSummary="Use Azure tools pre-installed on the worker" useBundledToolsCallout={<AzureBundledToolsCallout />}/>}>
            <></>
        </FeatureToggleVisibility>);
}
export function AzureBundledToolsForScriptsEdit(props: ActionEditProps<BundledToolsProperties, PackageReferenceProperties | ScriptPackageProperties>) {
    return (<FeatureToggleVisibility toggle="DeprecateBundledToolsFeatureToggle" disabledContent={<BundledToolsEditBase actionProps={props} title="Azure Tools" notes={<Note>
                            This step depends on the Azure CLI to authenticate to Azure. Learn more about <ExternalLink href="AzureTools">Azure Tools</ExternalLink>.
                        </Note>} help="Select whether to use the bundled Azure tools or using tooling pre-installed on the worker" useBundledToolsSummary="Use Azure tools bundled with Octopus" usePreInstalledToolsSummary="Use Azure tools pre-installed on the worker" useBundledToolsCallout={<AzureBundledToolsCallout />}/>}>
            <></>
        </FeatureToggleVisibility>);
}
export function AzureBundledToolsForCustomScriptsEdit(props: ActionEditProps<BundledToolsProperties, PackageReferenceProperties | ScriptPackageProperties>) {
    return (<FeatureToggleVisibility toggle="DeprecateBundledToolsFeatureToggle" disabledContent={<BundledToolsEditBase actionProps={props} title="Azure Tools" notes={<Note>
                            If <ExternalLink href="CustomScriptsInPackage">custom deployment scripts</ExternalLink> are used by this step they may need to use the Azure CLI to authenticate to Azure or perform other actions. Learn more about{" "}
                            <ExternalLink href="AzureTools">Azure Tools</ExternalLink>.
                        </Note>} help="Select whether to use the bundled Azure tools or using tooling pre-installed on the worker when running custom scripts" useBundledToolsSummary="Use Azure tools bundled with Octopus for custom scripts" usePreInstalledToolsSummary="Use Azure tools pre-installed on the worker for custom scripts" useBundledToolsCallout={<AzureBundledToolsCallout />}/>}>
            <></>
        </FeatureToggleVisibility>);
}
