import * as globals from "./globals";
import type { OctopusTheme } from "./OctopusTheme";
export const lightTheme: OctopusTheme = {
    "colorCalloutTextInfo": globals["colorScales"]["blue"]["600"],
    "colorCalloutTextSuccess": globals["colorScales"]["green"]["600"],
    "colorCalloutTextWarning": globals["colorScales"]["yellow"]["500"],
    "colorCalloutTextDanger": globals["colorScales"]["red"]["600"],
    "colorCalloutTextFeature": globals["colorScales"]["purple"]["600"],
    "colorCalloutTextDefault": globals["colorScales"]["slate"]["600"],
    "colorCalloutTextBody": globals["colorScales"]["black"],
    "colorCalloutIconInfo": globals["colorScales"]["blue"]["600"],
    "colorCalloutIconSuccess": globals["colorScales"]["green"]["600"],
    "colorCalloutIconWarning": globals["colorScales"]["yellow"]["600"],
    "colorCalloutIconDanger": globals["colorScales"]["red"]["600"],
    "colorCalloutIconFeature": globals["colorScales"]["purple"]["600"],
    "colorCalloutIconDefault": globals["colorScales"]["slate"]["600"],
    "colorCalloutBorderInfo": globals["colorScales"]["blue"]["500"],
    "colorCalloutBorderSuccess": globals["colorScales"]["green"]["500"],
    "colorCalloutBorderWarning": globals["colorScales"]["yellow"]["400"],
    "colorCalloutBorderDanger": globals["colorScales"]["red"]["500"],
    "colorCalloutBorderFeature": globals["colorScales"]["purple"]["500"],
    "colorCalloutBorderDefault": globals["colorScales"]["slate"]["500"],
    "colorCalloutBackgroundInfoDefault": "rgba(135, 191, 236, 0.24)",
    "colorCalloutBackgroundInfoHover": "rgba(135, 191, 236, 0.40)",
    "colorCalloutBackgroundInfoPressed": "rgba(135, 191, 236, 0.64)",
    "colorCalloutBackgroundSuccessDefault": "rgba(94, 205, 158, 0.24)",
    "colorCalloutBackgroundSuccessHover": "rgba(94, 205, 158, 0.40)",
    "colorCalloutBackgroundSuccessPressed": "rgba(94, 205, 158, 0.64)",
    "colorCalloutBackgroundAttentionDefault": "rgba(255, 223, 98, 0.24)",
    "colorCalloutBackgroundAttentionHover": "rgba(255, 223, 98, 0.40)",
    "colorCalloutBackgroundAttentionPressed": "rgba(255, 223, 98, 0.64)",
    "colorCalloutBackgroundDangerDefault": "rgba(255, 159, 159, 0.24)",
    "colorCalloutBackgroundDangerHover": "rgba(255, 159, 159, 0.40)",
    "colorCalloutBackgroundDangerPressed": "rgba(255, 159, 159, 0.64)",
    "colorCalloutBackgroundFeatureDefault": "rgba(197, 174, 238, 0.24)",
    "colorCalloutBackgroundFeatureHover": "rgba(197, 174, 238, 0.40)",
    "colorCalloutBackgroundFeaturePressed": "rgba(197, 174, 238, 0.64)",
    "colorCalloutBackgroundDefaultDefault": "rgba(178, 185, 197, 0.24)",
    "colorCalloutBackgroundDefaultHover": "rgba(178, 185, 197, 0.40)",
    "colorCalloutBackgroundDefaultPressed": "rgba(178, 185, 197, 0.64)",
    "colorMenuListTextPrimary": globals["colorScales"]["slate"]["800"],
    "colorMenuListTextSecondary": globals["colorScales"]["slate"]["600"],
    "colorMenuListTextHighlight": globals["colorScales"]["blue"]["500"],
    "colorMenuListBackgroundDefault": globals["colorScales"]["white"],
    "colorMenuListBackgroundHover": globals["colorScales"]["slate"]["100"],
    "colorMenuListBackgroundActive": globals["colorScales"]["slate"]["200"],
    "colorMenuListIconPrimary": globals["colorScales"]["slate"]["600"],
    "colorMenuListBorderPrimary": globals["colorScales"]["slate"]["200"],
    "colorTextFieldTextPrimary": globals["colorScales"]["slate"]["800"],
    "colorTextFieldTextSecondary": globals["colorScales"]["slate"]["500"],
    "colorTextFieldTextSelected": globals["colorScales"]["blue"]["500"],
    "colorTextFieldTextDanger": globals["colorScales"]["red"]["500"],
    "colorTextFieldTextDisabled": globals["colorScales"]["grey"]["300"],
    "colorTextFieldBorderDefault": globals["colorScales"]["slate"]["300"],
    "colorTextFieldBorderHover": globals["colorScales"]["slate"]["500"],
    "colorTextFieldBorderActive": globals["colorScales"]["blue"]["500"],
    "colorTextFieldBorderDanger": globals["colorScales"]["red"]["500"],
    "colorTextFieldBorderDisabled": globals["colorScales"]["grey"]["300"],
    "colorTextFieldIconPrimary": globals["colorScales"]["slate"]["700"],
    "colorTextFieldIconDisabled": globals["colorScales"]["grey"]["300"],
    "colorChipFilledTextPrimary": globals["colorScales"]["navy"]["600"],
    "colorChipFilledTextSecondary": globals["colorScales"]["purple"]["600"],
    "colorChipFilledTextInfo": globals["colorScales"]["blue"]["600"],
    "colorChipFilledTextSuccess": globals["colorScales"]["green"]["600"],
    "colorChipFilledTextWarning": globals["colorScales"]["yellow"]["600"],
    "colorChipFilledTextDanger": globals["colorScales"]["red"]["600"],
    "colorChipFilledBackgroundPrimary": globals["colorScales"]["navy"]["200"],
    "colorChipFilledBackgroundSecondary": globals["colorScales"]["purple"]["200"],
    "colorChipFilledBackgroundInfo": globals["colorScales"]["blue"]["200"],
    "colorChipFilledBackgroundSuccess": globals["colorScales"]["green"]["200"],
    "colorChipFilledBackgroundWarning": globals["colorScales"]["yellow"]["200"],
    "colorChipFilledBackgroundDanger": globals["colorScales"]["red"]["200"],
    "colorChipFilledIconPrimary": globals["colorScales"]["navy"]["600"],
    "colorChipFilledIconSecondary": globals["colorScales"]["purple"]["600"],
    "colorChipFilledIconInfo": globals["colorScales"]["blue"]["600"],
    "colorChipFilledIconSuccess": globals["colorScales"]["green"]["600"],
    "colorChipFilledIconWarning": globals["colorScales"]["yellow"]["600"],
    "colorChipFilledIconDanger": globals["colorScales"]["red"]["600"],
    "colorChipOutlinedBorderPrimary": globals["colorScales"]["navy"]["500"],
    "colorChipOutlinedBorderSecondary": globals["colorScales"]["purple"]["500"],
    "colorChipOutlinedBorderInfo": globals["colorScales"]["blue"]["500"],
    "colorChipOutlinedBorderSuccess": globals["colorScales"]["green"]["500"],
    "colorChipOutlinedBorderWarning": globals["colorScales"]["yellow"]["500"],
    "colorChipOutlinedBorderDanger": globals["colorScales"]["red"]["500"],
    "colorChipOutlinedTextPrimary": globals["colorScales"]["navy"]["500"],
    "colorChipOutlinedTextSecondary": globals["colorScales"]["purple"]["500"],
    "colorChipOutlinedTextInfo": globals["colorScales"]["blue"]["500"],
    "colorChipOutlinedTextSuccess": globals["colorScales"]["green"]["500"],
    "colorChipOutlinedTextWarning": globals["colorScales"]["yellow"]["500"],
    "colorChipOutlinedTextDanger": globals["colorScales"]["red"]["500"],
    "colorChipOutlinedIconPrimary": globals["colorScales"]["navy"]["500"],
    "colorChipOutlinedIconSecondary": globals["colorScales"]["purple"]["500"],
    "colorChipOutlinedIconInfo": globals["colorScales"]["blue"]["500"],
    "colorChipOutlinedIconSuccess": globals["colorScales"]["green"]["500"],
    "colorChipOutlinedIconWarning": globals["colorScales"]["yellow"]["500"],
    "colorChipOutlinedIconDanger": globals["colorScales"]["red"]["500"],
    "colorButtonBackgroundPrimaryDefault": globals["colorScales"]["green"]["500"],
    "colorButtonBackgroundPrimaryHover": globals["colorScales"]["green"]["600"],
    "colorButtonBackgroundPrimaryPressed": globals["colorScales"]["green"]["700"],
    "colorButtonBackgroundSecondaryDefault": "rgba(26, 119, 202, 0.0)",
    "colorButtonBackgroundSecondaryHover": "rgba(26, 119, 202, 0.08)",
    "colorButtonBackgroundSecondaryPressed": "rgba(26, 119, 202, 0.24)",
    "colorButtonBackgroundTertiaryDefault": "rgba(26, 119, 202, 0.06)",
    "colorButtonBackgroundTertiaryHover": "rgba(26, 119, 202, 0.16)",
    "colorButtonBackgroundTertiaryPressed": "rgba(26, 119, 202, 0.24)",
    "colorButtonBackgroundDestructiveDefault": globals["colorScales"]["red"]["500"],
    "colorButtonBackgroundDestructiveHover": globals["colorScales"]["red"]["600"],
    "colorButtonBackgroundDestructivePressed": globals["colorScales"]["red"]["700"],
    "colorButtonBackgroundQuietDefault": "rgba(26, 119, 202, 0.0)",
    "colorButtonBackgroundQuietHover": "rgba(26, 119, 202, 0.08)",
    "colorButtonBackgroundQuietPressed": "rgba(26, 119, 202, 0.24)",
    "colorButtonBackgroundLoudDefault": globals["colorScales"]["blue"]["500"],
    "colorButtonBackgroundLoudHover": globals["colorScales"]["blue"]["600"],
    "colorButtonBackgroundLoudPressed": globals["colorScales"]["blue"]["700"],
    "colorButtonBackgroundNeutralDefault": globals["colorScales"]["slate"]["100"],
    "colorButtonBackgroundNeutralHover": globals["colorScales"]["slate"]["200"],
    "colorButtonBackgroundNeutralPressed": globals["colorScales"]["slate"]["300"],
    "colorButtonBackgroundDisabled": globals["colorScales"]["grey"]["200"],
    "colorButtonTextPrimary": globals["colorScales"]["white"],
    "colorButtonTextSecondary": globals["colorScales"]["blue"]["500"],
    "colorButtonTextTertiary": globals["colorScales"]["blue"]["500"],
    "colorButtonTextDestructive": globals["colorScales"]["white"],
    "colorButtonTextQuiet": globals["colorScales"]["blue"]["500"],
    "colorButtonTextLoud": globals["colorScales"]["white"],
    "colorButtonTextNeutral": globals["colorScales"]["slate"]["800"],
    "colorButtonTextDisabled": globals["colorScales"]["navy"]["600"],
    "colorButtonBorderSecondary": globals["colorScales"]["blue"]["400"],
    "colorButtonBorderDisabled": globals["colorScales"]["grey"]["200"],
    "colorButtonIconPrimary": globals["colorScales"]["white"],
    "colorButtonIconSecondary": globals["colorScales"]["blue"]["500"],
    "colorButtonIconTertiary": globals["colorScales"]["blue"]["500"],
    "colorButtonIconDestructive": globals["colorScales"]["white"],
    "colorButtonIconQuiet": globals["colorScales"]["blue"]["500"],
    "colorButtonIconLoud": globals["colorScales"]["white"],
    "colorButtonIconNeutral": globals["colorScales"]["slate"]["800"],
    "colorButtonIconDisabled": globals["colorScales"]["grey"]["600"],
    "colorDialogBackgroundPrimary": globals["colorScales"]["white"],
    "colorDialogHelpPanelText": globals["colorScales"]["white"],
    "colorProgressBackgroundPrimary": globals["colorScales"]["blue"]["500"],
    "colorProgressBackgroundSecondary": globals["colorScales"]["blue"]["200"],
    "colorProgressTextInfo": globals["colorScales"]["blue"]["400"],
    "colorProgressBarForegroundLinear": globals["colorScales"]["blue"]["500"],
    "colorProgressBarForegroundCircular": globals["colorScales"]["blue"]["500"],
    "colorProgressBarBackgroundLinear": globals["colorScales"]["blue"]["200"],
    "colorProgressBarTextPrimary": globals["colorScales"]["slate"]["700"],
    "colorPaginationBackgroundDefault": globals["colorScales"]["white"],
    "colorPaginationBackgroundHover": globals["colorScales"]["slate"]["400"],
    "colorPaginationBackgroundActive": globals["colorScales"]["blue"]["500"],
    "colorPaginationBackgroundDisabled": globals["colorScales"]["white"],
    "colorPaginationTextDefault": globals["colorScales"]["slate"]["800"],
    "colorPaginationTextHover": globals["colorScales"]["white"],
    "colorPaginationTextActive": globals["colorScales"]["white"],
    "colorPaginationTextDisabled": globals["colorScales"]["grey"]["400"],
    "colorPaginationIconDefault": globals["colorScales"]["slate"]["800"],
    "colorPaginationIconHover": globals["colorScales"]["white"],
    "colorPaginationIconActive": globals["colorScales"]["white"],
    "colorPaginationIconDisabled": globals["colorScales"]["grey"]["400"],
    "colorToggleIconDefault": globals["colorScales"]["navy"]["700"],
    "colorToggleIconActive": globals["colorScales"]["blue"]["500"],
    "colorToggleIconDisabled": globals["colorScales"]["grey"]["300"],
    "colorToggleBackgroundOn": globals["colorScales"]["blue"]["300"],
    "colorToggleBackgroundOff": globals["colorScales"]["navy"]["300"],
    "colorToggleBackgroundDisabled": globals["colorScales"]["grey"]["200"],
    "colorToggleForegroundOn": globals["colorScales"]["blue"]["500"],
    "colorToggleForegroundOff": globals["colorScales"]["navy"]["100"],
    "colorToggleForegroundDisabled": globals["colorScales"]["grey"]["300"],
    "colorToggleOverlayOn": "rgba(135, 191, 236, 0.16)",
    "colorToggleOverlayOff": "rgba(169, 187, 203, 0.16)",
    "colorTextPrimary": globals["colorScales"]["slate"]["800"],
    "colorTextSecondary": globals["colorScales"]["slate"]["600"],
    "colorTextTertiary": globals["colorScales"]["slate"]["500"],
    "colorTextDisabled": globals["colorScales"]["grey"]["300"],
    "colorTextInverse": globals["colorScales"]["white"],
    "colorTextSelected": globals["colorScales"]["blue"]["500"],
    "colorTextInfo": globals["colorScales"]["blue"]["500"],
    "colorTextSuccess": globals["colorScales"]["green"]["500"],
    "colorTextWarning": globals["colorScales"]["yellow"]["400"],
    "colorTextDanger": globals["colorScales"]["red"]["500"],
    "colorTextFeature": globals["colorScales"]["purple"]["500"],
    "colorTextLinkDefault": globals["colorScales"]["blue"]["500"],
    "colorTextLinkPressed": globals["colorScales"]["blue"]["600"],
    "colorIconPrimary": globals["colorScales"]["slate"]["800"],
    "colorIconSecondary": globals["colorScales"]["slate"]["600"],
    "colorIconTertiary": globals["colorScales"]["slate"]["500"],
    "colorIconDisabled": globals["colorScales"]["grey"]["300"],
    "colorIconInverse": globals["colorScales"]["white"],
    "colorIconSelected": globals["colorScales"]["blue"]["500"],
    "colorIconInfo": globals["colorScales"]["blue"]["400"],
    "colorIconSuccess": globals["colorScales"]["green"]["400"],
    "colorIconWarning": globals["colorScales"]["orange"]["400"],
    "colorIconAttention": globals["colorScales"]["yellow"]["400"],
    "colorIconDanger": globals["colorScales"]["red"]["500"],
    "colorIconFeature": globals["colorScales"]["purple"]["400"],
    "colorIconLinkDefault": globals["colorScales"]["blue"]["500"],
    "colorIconLinkPressed": globals["colorScales"]["blue"]["600"],
    "colorBorderPrimary": globals["colorScales"]["slate"]["200"],
    "colorBorderSecondary": globals["colorScales"]["slate"]["100"],
    "colorBorderTertiary": globals["colorScales"]["slate"]["300"],
    "colorBorderDisabled": globals["colorScales"]["grey"]["300"],
    "colorBorderInverse": globals["colorScales"]["white"],
    "colorBorderSelected": globals["colorScales"]["blue"]["500"],
    "colorBorderInfo": globals["colorScales"]["blue"]["500"],
    "colorBorderSuccess": globals["colorScales"]["green"]["500"],
    "colorBorderWarning": globals["colorScales"]["yellow"]["400"],
    "colorBorderDanger": globals["colorScales"]["red"]["500"],
    "colorBorderFeature": globals["colorScales"]["purple"]["500"],
    "colorBorderInput": globals["colorScales"]["slate"]["300"],
    "colorBorderLinkDefault": globals["colorScales"]["blue"]["500"],
    "colorBorderLinkPressed": globals["colorScales"]["blue"]["600"],
    "colorBackgroundPrimaryDefault": globals["colorScales"]["white"],
    "colorBackgroundPrimaryHovered": globals["colorScales"]["slate"]["100"],
    "colorBackgroundPrimaryPressed": globals["colorScales"]["slate"]["200"],
    "colorBackgroundPrimaryTransparent": "rgba(255, 255, 255, 0.72)",
    "colorBackgroundSecondaryDefault": globals["colorScales"]["slate"]["100"],
    "colorBackgroundSecondaryHovered": globals["colorScales"]["white"],
    "colorBackgroundSecondaryPressed": globals["colorScales"]["slate"]["300"],
    "colorBackgroundTertiary": globals["colorScales"]["slate"]["200"],
    "colorBackgroundDisabled": globals["colorScales"]["grey"]["200"],
    "colorBackgroundContrast": globals["colorScales"]["white"],
    "colorBackgroundInversePrimary": globals["colorScales"]["navy"]["900"],
    "colorBackgroundInverseSecondary": globals["colorScales"]["navy"]["700"],
    "colorBackgroundInverseNeutral": globals["colorScales"]["slate"]["500"],
    "colorBackgroundInverseSuccess": globals["colorScales"]["green"]["500"],
    "colorBackgroundSelected": globals["colorScales"]["blue"]["500"],
    "colorBackgroundInfo": globals["colorScales"]["blue"]["200"],
    "colorBackgroundSuccess": globals["colorScales"]["green"]["200"],
    "colorBackgroundWarning": globals["colorScales"]["yellow"]["100"],
    "colorBackgroundDanger": globals["colorScales"]["red"]["200"],
    "colorBackgroundFeature": globals["colorScales"]["purple"]["200"],
    "colorBackgroundSupplemantaryDefault": globals["colorScales"]["white"],
    "colorOverlayBackgroundPrimary": "rgba(17, 26, 35, 0.56)",
    "colorOverlayBackgroundMuted": "rgba(17, 26, 35, 0.24)",
    "colorActionButtonBorderSecondary": globals["colorScales"]["blue"]["400"],
    "colorActionButtonBorderDisabled": globals["colorScales"]["grey"]["300"],
    "colorActionButtonTextPrimary": globals["colorScales"]["white"],
    "colorActionButtonTextSecondary": globals["colorScales"]["blue"]["500"],
    "colorActionButtonTextTernary": globals["colorScales"]["blue"]["500"],
    "colorActionButtonTextDisabled": globals["colorScales"]["grey"]["400"],
    "colorActionButtonIconPrimary": globals["colorScales"]["white"],
    "colorActionButtonIconSecondary": globals["colorScales"]["blue"]["500"],
    "colorActionButtonIconTernary": globals["colorScales"]["blue"]["500"],
    "colorActionButtonIconDisabled": globals["colorScales"]["grey"]["400"],
    "colorActionButtonBackgroundDisabled": globals["colorScales"]["grey"]["200"],
    "colorActionButtonBackgroundPrimaryDefault": globals["colorScales"]["green"]["500"],
    "colorActionButtonBackgroundPrimaryHover": globals["colorScales"]["green"]["600"],
    "colorActionButtonBackgroundPrimaryPressed": globals["colorScales"]["green"]["700"],
    "colorActionButtonBackgroundSecondaryDefault": globals["colorScales"]["blue"]["100"],
    "colorActionButtonBackgroundSecondaryHover": "rgba(26, 119, 202, 0.08)",
    "colorActionButtonBackgroundSecondaryPressed": "rgba(26, 119, 202, 0.24)",
    "colorActionButtonBackgroundTernaryDefault": "rgba(26, 119, 202, 0.0)",
    "colorActionButtonBackgroundTernaryHover": "rgba(26, 119, 202, 0.08)",
    "colorActionButtonBackgroundTernaryPressed": "rgba(26, 119, 202, 0.24)",
    "colorActionButtonBackgroundCreateReleaseDefault": globals["colorScales"]["navy"]["900"],
    "colorActionButtonBackgroundCreateReleaseHover": globals["colorScales"]["navy"]["700"],
    "colorActionButtonBackgroundCreateReleasePressed": globals["colorScales"]["navy"]["600"],
    "colorCardBackgroundDefault": globals["colorScales"]["white"],
    "colorCardBackgroundHover": globals["colorScales"]["slate"]["100"],
    "colorCarouselTextPrimary": globals["colorScales"]["white"],
    "colorCarouselTextMuted": globals["colorScales"]["navy"]["200"],
    "colorCarouselIconDefault": "rgba(169, 187, 203, 0.32)",
    "colorCarouselIconHover": "rgba(169, 187, 203, 0.72)",
    "colorCarouselIconSelected": globals["colorScales"]["blue"]["400"],
    "colorCarouselIconActive": "rgba(169, 187, 203, 0.72)",
    "colorCarouselBackgroundPrimary": globals["colorScales"]["navy"]["900"],
    "colorNavListTextDefault": globals["colorScales"]["slate"]["800"],
    "colorNavListTextHover": globals["colorScales"]["white"],
    "colorNavListTextActive": globals["colorScales"]["blue"]["500"],
    "colorNavListIconDefault": globals["colorScales"]["slate"]["500"],
    "colorNavListIconHover": globals["colorScales"]["white"],
    "colorNavListIconActive": globals["colorScales"]["blue"]["500"],
    "colorNavListBackgroundDefault": "rgba(26, 119, 202, 0.0)",
    "colorNavListBackgroundHover": globals["colorScales"]["blue"]["500"],
    "colorNavListBackgroundActive": globals["colorScales"]["slate"]["100"],
    "colorPopoverBackgroundPrimary": globals["colorScales"]["white"],
    "colorPopoverBackgroundSecondary": globals["colorScales"]["navy"]["100"],
    "colorCodeEditorBackground": globals["colorScales"]["slate"]["100"],
    "colorCodeEditorForeground": globals["colorScales"]["slate"]["800"],
    "colorCodeEditorSelection": globals["colorScales"]["slate"]["200"],
    "colorCodeEditorSelectionMatch": globals["colorScales"]["slate"]["200"],
    "colorCodeEditorTextDefault": globals["colorScales"]["slate"]["800"],
    "colorCodeEditorTextHighlight": globals["colorScales"]["orange"]["700"],
    "colorCodeEditorTextHintHighlight": globals["colorScales"]["green"]["500"],
    "colorCodeEditorTextGutter": globals["colorScales"]["slate"]["400"],
    "colorCodeEditorCodeSelectedBackground": globals["colorScales"]["slate"]["200"],
    "colorCodeEditorCodeLinenumberColor": globals["colorScales"]["slate"]["400"],
    "colorCodeEditorCodeCursorBorderLeft": globals["colorScales"]["navy"]["800"],
    "colorCodeEditorCodeCursorBorderRight": globals["colorScales"]["navy"]["300"],
    "colorCodeEditorCodeActivelineBackground": "rgba(104, 119, 141, 0.15)",
    "colorCodeEditorCodeFatCursorBackground": globals["colorScales"]["navy"]["300"],
    "colorCodeEditorCodeAtomNumberKeywordVariableAttributeQuoteColor": globals["colorScales"]["blue"]["800"],
    "colorCodeEditorCodeVariableColor": globals["colorScales"]["purple"]["600"],
    "colorCodeEditorCodePropertyColor": globals["colorScales"]["orange"]["800"],
    "colorCodeEditorCodePunctuationUnitNegativeColor": globals["colorScales"]["red"]["600"],
    "colorCodeEditorCodeStringOperatorColor": globals["colorScales"]["green"]["700"],
    "colorCodeEditorCodePositiveColor": globals["colorScales"]["green"]["800"],
    "colorCodeEditorCodeVariable2Variable3String2UrlColor": globals["colorScales"]["blue"]["600"],
    "colorCodeEditorCodeDefTagBuiltinQualifierHeaderEmColor": globals["colorScales"]["red"]["600"],
    "colorCodeEditorCodeBracketCommentColor": globals["colorScales"]["slate"]["400"],
    "colorCodeEditorCodeErrorInvalidcharColor": globals["colorScales"]["red"]["700"],
    "colorCodeEditorCodeMatchingbracketColor": globals["colorScales"]["slate"]["900"],
    "colorCodeEditorCodeHintsBackground": globals["colorScales"]["slate"]["200"],
    "colorCodeEditorCodeHint": globals["colorScales"]["slate"]["800"],
    "colorCodeEditorCodeHintActiveBackground": globals["colorScales"]["slate"]["200"],
    "colorCodeEditorCodeSyntaxTagName": globals["colorScales"]["green"]["600"],
    "colorCodeEditorCodeSyntaxComment": globals["colorScales"]["slate"]["600"],
    "colorCodeEditorCodeSyntaxBracket": globals["colorScales"]["slate"]["600"],
    "colorCodeEditorCodeSyntaxClassName": globals["colorScales"]["purple"]["600"],
    "colorCodeEditorCodeSyntaxPropertyName": globals["colorScales"]["purple"]["600"],
    "colorCodeEditorCodeSyntaxVariableName": globals["colorScales"]["blue"]["600"],
    "colorCodeEditorCodeSyntaxAttributeName": globals["colorScales"]["blue"]["600"],
    "colorCodeEditorCodeSyntaxNumber": globals["colorScales"]["blue"]["600"],
    "colorCodeEditorCodeSyntaxOperator": globals["colorScales"]["blue"]["600"],
    "colorCodeEditorCodeSyntaxKeyword": globals["colorScales"]["red"]["600"],
    "colorCodeEditorCodeSyntaxTypeName": globals["colorScales"]["red"]["600"],
    "colorCodeEditorCodeSyntaxTypeOperator": globals["colorScales"]["red"]["600"],
    "colorCodeEditorCodeSyntaxString": globals["colorScales"]["blue"]["800"],
    "colorCodeEditorCodeSyntaxMeta": globals["colorScales"]["blue"]["800"],
    "colorCodeEditorCodeSyntaxRegexp": globals["colorScales"]["blue"]["800"],
    "colorCodeEditorCodeSyntaxName": globals["colorScales"]["green"]["600"],
    "colorCodeEditorCodeSyntaxQuote": globals["colorScales"]["green"]["600"],
    "colorCodeEditorCodeSyntaxHeading": globals["colorScales"]["grey"]["800"],
    "colorCodeEditorCodeSyntaxStrong": globals["colorScales"]["grey"]["800"],
    "colorCodeEditorCodeSyntaxEmphasis": globals["colorScales"]["grey"]["800"],
    "colorCodeEditorCodeSyntaxDeleted": globals["colorScales"]["red"]["600"],
    "colorCodeEditorCodeSyntaxDeletedBackground": globals["colorScales"]["red"]["100"],
    "colorCodeEditorCodeSyntaxAtom": globals["colorScales"]["orange"]["600"],
    "colorCodeEditorCodeSyntaxBool": globals["colorScales"]["orange"]["600"],
    "colorCodeEditorCodeSyntaxSpecialVariableName": globals["colorScales"]["orange"]["600"],
    "colorCodeEditorCodeSyntaxUrl": globals["colorScales"]["blue"]["800"],
    "colorCodeEditorCodeSyntaxEscape": globals["colorScales"]["blue"]["800"],
    "colorCodeEditorCodeSyntaxLink": globals["colorScales"]["blue"]["800"],
    "colorCodeEditorCodeSyntaxInvalid": globals["colorScales"]["red"]["600"],
    "colorCodeEditorCodeIndentationMarkerColor": globals["colorScales"]["slate"]["200"],
    "colorCodeEditorCodeIndentationMarkerActiveColor": globals["colorScales"]["slate"]["300"],
    "colorCodeEditorToolbarBackground": globals["colorScales"]["slate"]["200"],
    "colorCodeEditorToolbarButtonHover": globals["colorScales"]["slate"]["300"],
    "colorScrollbarBackground": "rgba(255, 255, 255, 0.0)",
    "colorScrollbarHandle": globals["colorScales"]["grey"]["400"],
    "colorCategorySelectListTextDefault": globals["colorScales"]["slate"]["800"],
    "colorCategorySelectListTextHover": globals["colorScales"]["white"],
    "colorCategorySelectListTextPressed": globals["colorScales"]["blue"]["500"],
    "colorCategorySelectListIconDefault": globals["colorScales"]["slate"]["600"],
    "colorCategorySelectListIconHover": globals["colorScales"]["white"],
    "colorCategorySelectListIconPressed": globals["colorScales"]["blue"]["500"],
    "colorCategorySelectListBackgroundDefault": globals["colorScales"]["slate"]["100"],
    "colorCategorySelectListBackgroundHover": globals["colorScales"]["blue"]["500"],
    "colorCategorySelectListBackgroundPressed": "rgba(26, 119, 202, 0.06)",
    "colorSpaceSwitcherBackgroundDefault": "rgba(225, 225, 225, 0.32)",
    "colorSpaceSwitcherBackgroundHover": globals["colorScales"]["blue"]["500"],
    "colorSpaceSwitcherBackgroundPressed": globals["colorScales"]["blue"]["500"],
    "colorSpaceSwitcherTextDefault": globals["colorScales"]["slate"]["600"],
    "colorSpaceSwitcherTextHover": globals["colorScales"]["white"],
    "colorSpaceSwitcherTextPressed": globals["colorScales"]["white"],
    "colorSpaceSwitcherIconDefault": globals["colorScales"]["slate"]["600"],
    "colorSpaceSwitcherIconHover": globals["colorScales"]["white"],
    "colorSpaceSwitcherIconPressed": globals["colorScales"]["white"],
    "colorSpaceSwitcherBorderPrimary": "rgba(0, 0, 0, 0.16)",
    "colorNavTabBackgroundDefault": "rgba(255, 255, 255, 0.00)",
    "colorNavTabBackgroundHover": globals["colorScales"]["white"],
    "colorNavTabBackgroundActive": "rgba(255, 255, 255, 0.00)",
    "colorNavTabTextDefault": globals["colorScales"]["blue"]["800"],
    "colorNavTabTextHover": globals["colorScales"]["blue"]["500"],
    "colorNavTabTextActive": globals["colorScales"]["blue"]["500"],
    "colorNavTabIconDefault": globals["colorScales"]["blue"]["800"],
    "colorNavTabIconHover": globals["colorScales"]["blue"]["500"],
    "colorNavTabIconActive": globals["colorScales"]["blue"]["500"],
    "colorNavTabBorderPrimary": globals["colorScales"]["blue"]["400"],
    "colorNavButtonIconDefault": globals["colorScales"]["slate"]["800"],
    "colorNavButtonIconHover": globals["colorScales"]["white"],
    "colorNavButtonIconPressed": globals["colorScales"]["white"],
    "colorNavButtonIconActive": globals["colorScales"]["white"],
    "colorNavButtonBackgroundDefault": "rgba(26, 119, 202, 0.00)",
    "colorNavButtonBackgroundHover": globals["colorScales"]["blue"]["500"],
    "colorNavButtonBackgroundPressed": globals["colorScales"]["blue"]["600"],
    "colorNavButtonBackgroundActive": globals["colorScales"]["blue"]["500"],
    "colorBadgeBackgroundNeutral": globals["colorScales"]["slate"]["200"],
    "colorBadgeBackgroundInverseNeutral": globals["colorScales"]["slate"]["600"],
    "colorBadgeBackgroundInfo": globals["colorScales"]["blue"]["500"],
    "colorBadgeBackgroundSuccess": globals["colorScales"]["green"]["500"],
    "colorBadgeBackgroundWarning": globals["colorScales"]["yellow"]["500"],
    "colorBadgeBackgroundDanger": globals["colorScales"]["red"]["500"],
    "colorBadgeTextPrimary": globals["colorScales"]["white"],
    "colorBadgeTextSecondary": globals["colorScales"]["black"],
    "colorBadgeIconPrimary": globals["colorScales"]["white"],
    "colorBadgeIconSecondary": globals["colorScales"]["black"],
    "shadowExtraSmall": "0 1px 8px 0 rgba(0,0,0,0.02), 0 1px 2px 0 rgba(0,0,0,0.06)",
    "shadowSmall": "0 1px 1px 0 rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.06)",
    "shadowMedium": "0 6px 12px 0 rgba(0,0,0,0.10), 0 1px 4px 0 rgba(0,0,0,0.04)",
    "shadowLarge": "0 14px 32px -4px rgba(0,0,0,0.12), 0 2px 6px 0 rgba(0,0,0,0.10)",
    "shadowLayoutPrimary": "-2px -2px 6px 0 rgba(0,0,0,0.06)",
    "shadowDrawer": "-4px 0 16px 0 rgba(0,0,0,0.08), -12px 4px 24px -4px rgba(0,0,0,0.10)",
    "shadowFocused": "0 0 0 1px rgba(26,119,202,1), 0 0 0 5px rgba(26,119,202,0.32)",
    "shadowFocusedInset": "inset 0 0 0 4px rgba(26,119,202,0.32)"
};
