/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CircularProgress } from "@octopusdeploy/design-system-components";
import type { GitRefResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { repository } from "~/clientInstance";
import BusyFromPromise from "~/components/BusyFromPromise/index";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip";
import InputWithActions from "~/components/InputWithActions";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import type FormFieldProps from "~/components/form/FormFieldProps";
import Select from "~/primitiveComponents/form/Select/Select";
interface VariableSelectSharedProps extends FormFieldProps<string> {
    fetchVariables: () => Promise<string[]>;
    allowClear?: boolean;
    label?: string | JSX.Element;
    error?: string;
    warning?: string;
}
interface VariableSelectInternalProps extends VariableSelectSharedProps {
    onAddNewVariableClick: () => void;
}
interface VariableSelectInternalState {
    variables: string[] | null;
}
class VariableSelectInternal extends DataBaseComponent<VariableSelectInternalProps, VariableSelectInternalState> {
    constructor(props: VariableSelectInternalProps) {
        super(props);
        this.state = {
            busy: undefined,
            variables: null,
        };
    }
    componentDidMount() {
        this.refreshVariables();
    }
    refreshVariables = () => {
        this.doBusyTask(async () => {
            const variables = await this.props.fetchVariables();
            this.setState({ variables });
        });
    };
    render() {
        return (<InputWithActions input={<Select label={this.props.label ?? "Select Account variable"} value={this.props.value} disabled={this.props.disabled} allowFilter={true} error={this.props.error ?? this.errors?.message} warning={this.props.warning} allowClear={this.props.allowClear} helperText={this.props.helperText} onChange={(value) => this.props.onChange(value!)} items={this.state.variables?.map((v) => ({ value: v, text: v })) ?? []}/>} actions={<>
                        <BusyFromPromise promise={this.state.busy}>
                            {(busy) => (<Busy isBusy={busy}>
                                    <IconButtonWithTooltip accessibleName={"Refresh variables"} toolTipContent={"Refresh"} icon={"ArrowRefreshIcon"} onClick={this.refreshVariables}/>
                                </Busy>)}
                        </BusyFromPromise>
                        <IconButtonWithTooltip accessibleName={"Add new variable"} toolTipContent={"Add"} icon={"PlusIcon"} onClick={this.props.onAddNewVariableClick}/>
                    </>}/>);
    }
    static displayName = "VariableSelectInternal";
}
export interface VariableSelectProps extends VariableSelectSharedProps {
    projectId: string;
    gitRef: GitRefResource | undefined;
}
export function ProjectVariableSelect({ projectId, ...rest }: VariableSelectProps) {
    const { open } = useSpaceAwareNavigation();
    const onAddNewVariableClick = React.useCallback(() => {
        open(links.variablesPage.generateUrl({ spaceId: repository.spaceId!, projectSlug: projectId }));
    }, [open, projectId]);
    return <VariableSelectInternal onAddNewVariableClick={onAddNewVariableClick} {...rest}/>;
}
export const Busy: React.FC<{
    isBusy: boolean;
}> = ({ isBusy, children }) => {
    return isBusy ? <CircularProgress size="small"/> : <>{children}</>;
};
Busy.displayName = "Busy"
