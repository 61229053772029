import { css } from "@emotion/css";
import { XmarkIcon } from "@octopusdeploy/design-system-icons";
import { borderRadius, themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { resetStyles } from "../../utils/resetStyles";
interface ClearInputButtonProps {
    onClear: () => void;
    accessibleName: string;
}
export function ClearInputButton({ onClear, accessibleName }: ClearInputButtonProps) {
    return (<button className={clearInputButtonStyles} onClick={() => onClear()} aria-label={accessibleName}>
            <XmarkIcon size={20}/>
        </button>);
}
const clearInputButtonStyles = css({
    ...resetStyles.button,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: borderRadius.extraSmall,
    color: themeTokens.color.icon.primary,
    ":focus-visible": {
        boxShadow: themeTokens.shadow.focused,
    },
});
