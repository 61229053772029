import { DeploymentStatus } from "./LiveAppIcons";
export function rollupDeploymentStatus(status: string): DeploymentStatus {
    switch (status) {
        case "Success":
            return DeploymentStatus.Success;
        case "Failed":
        case "Canceled":
        case "TimedOut":
            return DeploymentStatus.Failure;
        case "Executing":
        case "Cancelling":
        case "Queued":
            return DeploymentStatus.Progressing;
        default:
            throw new Error("Unexpected Deployment Status");
    }
}
export function liveStatusSummaryText(deploymentStatus: DeploymentStatus, liveStatus: string): string {
    if (deploymentStatus === DeploymentStatus.Failure) {
        return "Overall live status is unavailable because your last deployment failed.";
    }
    if (deploymentStatus === DeploymentStatus.Progressing) {
        return "Overall live status will be available once the deployment completes.";
    }
    switch (liveStatus) {
        case "OutOfSync":
            return "The objects on your cluster no longer match what you last deployed.";
        case "Progressing":
            return "Objects in your application are currently in a progressing state.";
        case "Degraded":
            return "Your objects experienced errors after the deployment completed.";
        case "Missing":
            return "Objects in your application are currently in a missing state.";
        case "Healthy":
            return "The objects in your cluster match what was specified in the last deployment.";
        case "Unknown":
        default:
            return "We\u2019re having trouble getting live status updates for this application.";
    }
}
