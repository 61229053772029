import { Level1PageLayout, Level1PageLoading, Level2PageLayout, Level2PageLoading, type PrimaryPageAction } from "@octopusdeploy/design-system-components";
import type { WorkerPoolsSummaryResource, WorkerPoolResource, SummaryResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { isEqual } from "lodash";
import * as React from "react";
import { useHistory, useLocation } from "react-router";
import URI from "urijs";
import { useOnClearMachineDispatch } from "~/areas/infrastructure/hooks/useOnClearMachineDispatch";
import { repository } from "~/clientInstance";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import FormPage from "~/components/FormPage/FormPage";
import { EndpointCommunicationStyleMultiSelect } from "~/components/MultiSelect/EndpointCommunicationStyleMultiSelect";
import { ShellNameMultiSelect } from "~/components/MultiSelect/ShellNameMultiSelect";
import { WorkerPoolMultiSelect } from "~/components/MultiSelect/WorkerPoolMultiSelect";
import { NoResults } from "~/components/NoResults/NoResults";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import Section from "~/components/Section";
import { useOctopusFeatureToggle } from "~/hooks/useOctopusFeatureToggle";
import type { WorkerPoolSummaryMachineQuery } from "../WorkerPools/WorkerPoolsLayout/WorkerPoolSummaryMachineQuery";
import type { WorkerPoolsSummaryFilter } from "../WorkerPools/WorkerPoolsLayout/WorkerPoolsSummaryFilter";
import { createWorkerPoolsSummaryArgs, defaultWorkerPoolsSummaryFilter, workerPoolsFilterToQuery, workerPoolsQueryToFilter } from "../WorkerPools/WorkerPoolsLayout/WorkerPoolsSummaryFilter";
import { BaseMachinesLayout } from "./BaseMachinesLayout";
import type { MachinesLayoutProps, MachinesLayoutState } from "./BaseMachinesLayout";
import WorkerMachinesSummarySection from "./WorkerMachinesSummarySection";
import WorkersOnboarding from "./WorkersOnboarding";
interface WorkerMachinesPageProps {
    spaceId: string;
}
interface WorkerMachinesPageInternalProps extends MachinesLayoutProps<WorkerPoolsSummaryFilter, WorkerPoolSummaryMachineQuery> {
    spaceId: string;
    initialData: InitialData;
    defaultFilter: WorkerPoolsSummaryFilter;
    filterToQuery: (filter: WorkerPoolsSummaryFilter) => WorkerPoolSummaryMachineQuery;
    onClearMachine(): void;
}
interface WorkerMachinesLayoutState extends MachinesLayoutState<WorkerPoolsSummaryFilter> {
    workerPoolsSummary: WorkerPoolsSummaryResource;
    filter: WorkerPoolsSummaryFilter;
}
interface InitialData {
    workerShellNames: string[];
    workerPools: WorkerPoolResource[];
    hasMachines: boolean;
    workerPoolsSummary: WorkerPoolsSummaryResource;
}
const Title = "Workers";
const WorkerMachinesLayoutFormPage = FormPage<InitialData>();
export function WorkerMachinesPage({ spaceId }: WorkerMachinesPageProps) {
    const location = useLocation();
    const history = useHistory();
    const onClearMachine = useOnClearMachineDispatch();
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const query = URI(location.search).search(true) as WorkerPoolSummaryMachineQuery;
    const filter = workerPoolsQueryToFilter(query);
    const showV2Page = useOctopusFeatureToggle("deployment-targets-page-uplift", false);
    return (<WorkerMachinesLayoutFormPage title={Title} load={async () => {
            const defaultArgs = createWorkerPoolsSummaryArgs(filter);
            const workerPools = repository.WorkerPools.all();
            const workerShellNames = repository.WorkerShells.all();
            const hasMachines = (await repository.Workers.list({ take: 0 })).TotalResults > 0;
            const workerPoolsSummary = repository.WorkerPools.summary(defaultArgs);
            return {
                workerPools: await workerPools,
                workerShellNames: await workerShellNames,
                workerPoolsSummary: await workerPoolsSummary,
                hasMachines,
            };
        }} renderWhenLoaded={(data) => (<WorkerMachinesPageInternal initialData={data} initialFilter={filter} defaultFilter={defaultWorkerPoolsSummaryFilter} filterToQuery={workerPoolsFilterToQuery} hasMachines={data.hasMachines} spaceId={spaceId} title={Title} itemDescriptions={"workers"} onClearMachine={onClearMachine} location={location} history={history} showV2Page={showV2Page}/>)} renderAlternate={() => {
            // TODO: When the feature toggle is removed, the layout should be rendered by AllPageRoutes and this should just use `PageLoading` here.
            return showV2Page ? (<Level1PageLayout>
                        <Level1PageLoading />
                    </Level1PageLayout>) : (<Level2PageLayout>
                        <Level2PageLoading loadingTitle={Title}/>
                    </Level2PageLayout>);
        }}/>);
}
class WorkerMachinesPageInternal extends BaseMachinesLayout<WorkerMachinesPageInternalProps, WorkerMachinesLayoutState, WorkerPoolsSummaryFilter, WorkerPoolSummaryMachineQuery> {
    constructor(props: WorkerMachinesPageInternalProps) {
        super(props);
        this.state = {
            ...this.commonInitialState,
            filter: props.initialFilter,
            queryFilter: props.initialFilter,
            workerPoolsSummary: this.props.initialData.workerPoolsSummary,
        };
    }
    async componentDidMount() {
        // Clear currentMachine (to avoid seeing old machine data when switching machines).
        this.props.onClearMachine();
    }
    protected getNameFilter(searchHintText: string): JSX.Element[] {
        return [
            <FilterSearchBox placeholder={searchHintText} value={this.state.filter.machinePartialName} onChange={(x) => {
                    this.setFilterState({ machinePartialName: x }, this.onFilterChange);
                }} autoFocus={true}/>,
        ];
    }
    protected getSummaries(): SummaryResource {
        return this.props.initialData.workerPools && this.state.workerPoolsSummary;
    }
    protected isFiltering() {
        return !isEqual(this.state.filter, this.props.defaultFilter);
    }
    protected extraFilters(): React.ReactNode[] {
        return [
            <WorkerPoolMultiSelect accessibleName="workerPoolFilter" key="workerPoolIdsMultiSelect" items={this.props.initialData.workerPools} value={this.state.filter.workerPoolIds} onChange={(x) => {
                    this.setFilterState({ workerPoolIds: x }, this.onFilterChange);
                }}/>,
            <ShellNameMultiSelect accessibleName="shellNameFilter" key="filterShellName" items={this.props.initialData.workerShellNames ? this.props.initialData.workerShellNames : []} value={this.state.filter.shellNames} onChange={(x) => {
                    this.setFilterState({ shellNames: x }, this.onFilterChange);
                }}/>,
            <EndpointCommunicationStyleMultiSelect accessibleName="communicationStyleFilter" key="filterCommunication" items={this.communicationStyles.filter((cs) => cs.Name !== "Step Package")} value={this.state.filter.commStyles} onChange={(x) => {
                    this.setFilterState({ commStyles: x }, () => {
                        this.onFilterChange();
                    });
                }}/>,
        ];
    }
    protected getPrimaryPageAction(): PrimaryPageAction {
        return {
            type: "navigate",
            label: "Add Worker",
            path: links.newWorkerMachinePage.generateUrl({ spaceId: this.props.spaceId }),
            hasPermissions: isAllowed({
                permission: Permission.MachineCreate,
                environment: "*",
                tenant: "*",
            }),
        };
    }
    protected renderOnboarding(): JSX.Element {
        return <WorkersOnboarding spaceId={this.props.spaceId}/>;
    }
    protected renderMachinesExpander(): React.ReactNode {
        let machinesExpander: React.ReactNode = null;
        const workerPoolsSummaries = this.props.initialData.workerPools && this.state.workerPoolsSummary;
        if (workerPoolsSummaries) {
            machinesExpander = <WorkerMachinesSummarySection key="allMachines" workerPoolsSummary={workerPoolsSummaries} filter={this.state.filter} workerPools={this.props.initialData.workerPools} showV2Page={this.props.showV2Page}/>;
        }
        if (this.state.workerPoolsSummary && this.state.workerPoolsSummary.WorkerPoolSummaries.length === 0) {
            machinesExpander = (<Section>
                    <NoResults />
                </Section>);
        }
        return machinesExpander;
    }
}
