import type { RunbookResource } from "@octopusdeploy/octopus-server-client";
import { isEqual } from "lodash";
import * as React from "react";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import { SkeletonLoadingLayout } from "~/components/SkeletonLoadingLayout/SkeletonLoadingLayout";
import type { RunbookContextProps } from "./RunbookContext";
import { RunbookContextProvider } from "./RunbookContext";
//eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RunbookContextLayoutState extends DataBaseComponentState {
}
interface RunbookContextLayoutRouteProps {
    runbookId: string;
}
type RunbookContextPropsWithLoadedRunbook = RunbookContextProps & {
    state: {
        runbook: RunbookResource;
    };
};
type RunbookContextLayoutProps = RunbookContextLayoutRouteProps & {
    children: (context: RunbookContextPropsWithLoadedRunbook) => React.ReactNode;
};
class RunbookContextLayout extends DataBaseComponent<RunbookContextLayoutProps, RunbookContextLayoutState> {
    shouldComponentUpdate(nextProps: RunbookContextLayoutProps) {
        return !isEqual(nextProps.runbookId, this.props.runbookId);
    }
    render() {
        return (<RunbookContextProvider id={this.props.runbookId} doBusyTask={this.doBusyTask}>
                {(runbookContext) => {
                if (isRunbookLoadedContext(runbookContext)) {
                    return this.props.children(runbookContext);
                }
                return (<div>
                            <SkeletonLoadingLayout errors={this.errors}/>
                        </div>);
            }}
            </RunbookContextProvider>);
    }
    static displayName = "RunbookContextLayout";
}
function isRunbookLoadedContext(props: RunbookContextProps): props is RunbookContextPropsWithLoadedRunbook {
    return !!props.state.runbook;
}
export default RunbookContextLayout;
