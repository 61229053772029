/* Generated file. Do not modify. Please check more details in build/generate.ts */
import * as React from "react";
import type { IconProps } from "../IconTypes";
import { IconWrapper } from "../IconWrapper";
export function WorkerPoolIcon(props: IconProps) {
    return (<IconWrapper {...props}>
            {<>
                    <path fillRule="evenodd" clipRule="evenodd" d="M4 33a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2zm2-.75c0 .414.336.75.75.75h2.5a.75.75 0 0 0 .75-.75v-2.5a.75.75 0 0 0-.75-.75h-2.5a.75.75 0 0 0-.75.75z"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M16 33a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2V18a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2zm2-.75c0 .414.336.75.75.75h2.5a.75.75 0 0 0 .75-.75v-2.5a.75.75 0 0 0-.75-.75h-2.5a.75.75 0 0 0-.75.75z"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M28 33a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2zm2-.75c0 .414.336.75.75.75h2.5a.75.75 0 0 0 .75-.75v-2.5a.75.75 0 0 0-.75-.75h-2.5a.75.75 0 0 0-.75.75z"/>
                </>}
        </IconWrapper>);
}
