/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Callout, BooleanRadioButton, BooleanRadioButtonGroup } from "@octopusdeploy/design-system-components";
import { FingerprintIcon, InfoCircleIcon, UserCircleIcon } from "@octopusdeploy/design-system-icons";
import type { CertificateResource, TagSetResource, EnvironmentResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { CertificateDataFormat } from "@octopusdeploy/octopus-server-client";
import { noOp } from "@octopusdeploy/utilities";
import * as React from "react";
import { CertificateExpiryChip, environmentChipList, tenantChipList } from "~/components/Chips/index";
import MarkdownDescription from "~/components/MarkdownDescription";
import Tag from "~/components/Tag/index";
import { withTheme } from "~/components/Theme";
import ThumbprintText from "~/components/ThumbprintText";
import { normal } from "~/fontWeights";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import styles from "./style.module.less";
interface CertificateListItemProps {
    certificate: CertificateResource;
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    tenantTags: TagSetResource[];
    selected?: boolean;
    showSelection?: boolean;
    onClick(): void;
}
const CertificateSummary: React.StatelessComponent<CertificateListItemProps> = (props) => {
    const certificate = props.certificate;
    return withTheme((theme) => (<div className={styles.container} onClick={props.onClick}>
            {props.showSelection && (<div className={styles.select}>
                    <BooleanRadioButtonGroup value={props.selected ?? false} onChange={noOp}>
                        <BooleanRadioButton value={true}/>
                    </BooleanRadioButtonGroup>
                </div>)}
            <div className={styles.summary}>
                <div className={styles.titleContainer}>
                    <div className={styles.title}>
                        <ListTitle>{certificate.Name}</ListTitle>
                    </div>
                    {certificate.CertificateDataFormat !== CertificateDataFormat.Unknown && (<div className={styles.details}>
                            <span className={styles.icon}>{(certificate?.CertificateChain ?? []).length > 0 && <em className="fa-solid fa-chain" title="Contains a certificate chain"/>}</span>
                            <span className={styles.icon}>{certificate.HasPrivateKey && <em className="fa-solid fa-key" title="Contains a private-key"/>}</span>
                            <CertificateExpiryChip certificate={certificate}/>
                        </div>)}
                </div>
                {certificate.Notes && (<div className={styles.row}>
                        <MarkdownDescription markup={certificate.Notes}/>
                    </div>)}

                {certificate.CertificateDataFormat === CertificateDataFormat.Unknown && (<div className={styles.row}>
                        <Callout title="Invalid Certificate" type={"warning"}>
                            This certificate was unable to be parsed and may be in an invalid format. This certificate will not be able to be used in Octopus deployments and you may need to upload a new certificate which can be correctly loaded.
                        </Callout>
                    </div>)}
                {certificate.CertificateDataFormat !== CertificateDataFormat.Unknown && (<div className={styles.row} style={{ fontWeight: normal }}>
                        <div className={styles.propertyContainer}>
                            <InfoCircleIcon size={16}/>
                            <span>{certificate.SubjectCommonName || certificate.SubjectOrganization || certificate.SubjectDistinguishedName}</span>
                        </div>
                        <div className={styles.propertyContainer}>
                            <UserCircleIcon size={16}/>
                            <span>{certificate.SelfSigned ? "Self-Signed" : certificate.IssuerCommonName || certificate.IssuerOrganization || certificate.IssuerDistinguishedName}</span>
                        </div>
                        <div className={styles.propertyContainer}>
                            <FingerprintIcon size={16}/>
                            <ThumbprintText thumbprint={certificate.Thumbprint!}/>
                        </div>
                    </div>)}
                <div className={styles.row}>
                    <span>{environmentChipList(props.environments, certificate.EnvironmentIds)}</span>
                    {(!!certificate.TenantIds.length || !!certificate.TenantTags.length) && (<span>
                            {tenantChipList(props.tenants, certificate.TenantIds)}
                            {certificate.TenantTags.map((tt) => {
                const result = props.tenantTags.find((t) => !!t.Tags.find((tag) => tag.CanonicalTagName === tt));
                if (result) {
                    const fu = result.Tags.find((tag) => tag.CanonicalTagName === tt);
                    return <Tag key={fu!.Id} description={fu!.Description} tagName={fu!.Name} tagColor={fu!.Color}/>;
                }
            })}
                        </span>)}
                </div>
            </div>
        </div>));
};
CertificateSummary.displayName = "CertificateSummary"
export default CertificateSummary;
