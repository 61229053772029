import { useAggregateAPIOperationStatus } from "@octopusdeploy/octopus-react-client";
import type { ResourcesById, WorkerPoolResource, TagSetResource, ChannelResource, EnvironmentResource, LifecycleResource, ProjectResource, GitRef, GitRefResource, ProcessResource, ModifyProcessCommand } from "@octopusdeploy/octopus-server-client";
import { ProcessType, VariableSetContentType, Permission } from "@octopusdeploy/octopus-server-client";
import { keyBy } from "lodash";
import { useState } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import configurationSelectors from "~/areas/configuration/reducers/selectors";
import { PersistenceSettingsContextProvider } from "~/areas/projects/context/PersistenceSettingsContext";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { useLegacyDoBusyTask } from "~/components/DataBaseComponent/useLegacyDoBusyTask";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import * as tenantTagsets from "~/components/tenantTagsets";
import StringHelper from "~/utils/StringHelper";
import type { ScriptModule } from "../Process/Common/SideBar";
import { useOptionalRunbookContext } from "../Runbooks/RunbookContext";
import { ProcessContextFormPage } from "./Contexts/ProcessContextFormPage";
import { ProcessController } from "./Contexts/ProcessController";
import ProcessListLayout from "./ProcessListLayout";
import type { ProcessIdentifier, ProcessPageSupportedActions } from "./types";
interface ProcessState extends DataBaseComponentState {
    lookups: ProcessListLayoutLoaderLookupData;
}
interface ProcessListLayoutProps {
    project: ProjectResource;
    gitRef: GitRef | undefined;
    onProcessSave: (gitRef: GitRefResource | string | undefined) => Promise<void>;
    changeGitRef: (gitRef: string) => void;
    refreshModel: (gitRef?: GitRef) => Promise<boolean>;
    gitRefResource: GitRefResource | undefined;
    titleAccessory?: React.ReactElement;
    processIdentifier: ProcessIdentifier;
    process: ProcessResource;
    reloadProcess: () => void;
    modifyProcess: (process: ModifyProcessCommand, gitRef: string | undefined) => Promise<void>;
    releaseCreationPackageStepId?: string | undefined;
}
export interface ProcessListLayoutLoaderLookupData {
    includedScriptModules: ScriptModule[];
    lifecyclePreview: LifecycleResource | null;
    environmentsById: ResourcesById<EnvironmentResource>;
    channelsById: ResourcesById<ChannelResource> | null;
    tagSets: TagSetResource[];
    workerPoolsById: ResourcesById<WorkerPoolResource>;
}
const loadData = async (project: ProjectResource, processType: ProcessType): Promise<ProcessListLayoutLoaderLookupData> => {
    const includedScriptModules = isAllowed({ permission: Permission.LibraryVariableSetView, environment: "*", tenant: "*" })
        ? repository.LibraryVariableSets.all({
            contentType: VariableSetContentType.ScriptModule,
        }).then((sm) => sm.filter((x) => project.IncludedLibraryVariableSetIds.includes(x.Id)))
        : Promise.resolve([]);
    const environments = repository.Environments.all();
    const lifecyclePreview = processType === ProcessType.Deployment && isAllowed({ permission: Permission.LifecycleView }) ? repository.Lifecycles.get(project.LifecycleId).then((x) => repository.Lifecycles.preview(x)) : Promise.resolve(null);
    const channels = processType === ProcessType.Deployment &&
        isAllowed({
            permission: Permission.ProcessView,
            project: project.Id,
            tenant: "*",
        })
        ? repository.Projects.getChannels(project).then((c) => keyBy(c.Items, "Id"))
        : Promise.resolve(null);
    const tagSets = tenantTagsets.getAll();
    const workerPools = keyBy(await repository.WorkerPools.all(), "Id");
    return {
        environmentsById: keyBy(await environments, "Id"),
        includedScriptModules: await includedScriptModules,
        lifecyclePreview: await lifecyclePreview,
        channelsById: await channels,
        tagSets: await tagSets,
        workerPoolsById: await workerPools,
    };
};
const ProcessListLayoutFormPage = ProcessContextFormPage<ProcessListLayoutLoaderLookupData>();
const ProcessListLayoutDataLoader: React.FC<ProcessListLayoutProps> = (props) => {
    const isBuiltInWorkerEnabled = useIsBuiltInWorkerEnabled();
    const runbookContext = useOptionalRunbookContext();
    const runbookName = runbookContext?.state.runbook?.Name ?? StringHelper.ellipsis;
    return (<ProcessListLayoutFormPage processType={props.processIdentifier.type} title={props.processIdentifier.type === ProcessType.Deployment ? "Process" : runbookName} titleAccessory={props.titleAccessory} load={() => loadData(props.project, props.processIdentifier.type)} renderWhenLoaded={(data) => <ProcessListLayoutLoader initialLookups={data} isBuiltInWorkerEnabled={isBuiltInWorkerEnabled} {...props}/>}/>);
};
ProcessListLayoutDataLoader.displayName = "ProcessListLayoutDataLoader"
interface ProcessListLayoutLoaderProps extends ProcessListLayoutProps {
    initialLookups: ProcessListLayoutLoaderLookupData;
    project: Readonly<ProjectResource>;
    isBuiltInWorkerEnabled: boolean;
}
function ProcessListLayoutLoader(props: ProcessListLayoutLoaderProps) {
    const [lookups, setLookups] = useState(props.initialLookups);
    const { doBusyTask, status: doBusyTaskStatus } = useLegacyDoBusyTask();
    const { isInProgress, errors } = useAggregateAPIOperationStatus(doBusyTaskStatus);
    //TODO: @Cleanup: This is an artifact of the data-loading pattern we have here. Ideally this could potentially all be moved into context.
    const supportedActions = (): ProcessPageSupportedActions => {
        const refreshLookupData = async () => {
            await doBusyTask(async () => setLookups(await loadData(props.project, props.processIdentifier.type)));
        };
        return {
            refreshLookupData,
        };
    };
    return (<PersistenceSettingsContextProvider {...props.project.PersistenceSettings}>
            <ProcessController layoutActions={supportedActions()} doBusyTask={doBusyTask} processIdentifier={props.processIdentifier} process={props.process} reloadProcess={props.reloadProcess} modifyProcess={props.modifyProcess}>
                {() => {
            return (<ProcessListLayout gitRef={props.gitRef} onProcessSave={props.onProcessSave} gitRefResource={props.gitRefResource} titleAccessory={props.titleAccessory} lookups={lookups} doBusyTask={doBusyTask} isBuiltInWorkerEnabled={props.isBuiltInWorkerEnabled ?? false} releaseCreationPackageStepId={props.releaseCreationPackageStepId}/>);
        }}
            </ProcessController>
        </PersistenceSettingsContextProvider>);
}
const isBuiltInWorkerEnabledSelector = configurationSelectors.createFeatureEnabledSelector((t) => t.isBuiltInWorkerEnabled);
function useIsBuiltInWorkerEnabled() {
    return useSelector((state: GlobalState) => isBuiltInWorkerEnabledSelector(state));
}
// We export our data-loader wrapper, as that wraps out layout and supplies the necessary data to the layout.
export default ProcessListLayoutDataLoader;
