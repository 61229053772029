/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-eq-null */
import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import type { EnvironmentResource, LifecycleResource, PhaseResource, ProjectResource, RetentionPeriod } from "@octopusdeploy/octopus-server-client";
import { Permission, RetentionUnit } from "@octopusdeploy/octopus-server-client";
import type { QueryParamValuesSetter } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import { cloneDeep, sortBy } from "lodash";
import * as React from "react";
import type { ActionEvent, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import RetentionPolicyControl from "~/areas/library/components/Lifecycle/RetentionPolicy/RetentionPolicyControl";
import SortPhases from "~/areas/library/components/Lifecycle/SortPhases/SortPhases";
import { repository } from "~/clientInstance";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent";
import FormBaseComponent from "~/components/FormBaseComponent";
import { LegacyForm } from "~/components/FormPaperLayout/LegacyForm";
import Markdown from "~/components/Markdown";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuConverterVNext } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import { PageContent } from "~/components/PageContent/PageContent";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import RemovableExpandersList from "~/components/RemovableExpandersList";
import SimpleDataTable from "~/components/SimpleDataTable";
import { ExpandableFormSection, MarkdownEditor, required, Summary, Text } from "~/components/form";
import FormSectionHeading from "~/components/form/Sections/FormSectionHeading";
import UnstructuredFormSection from "~/components/form/Sections/UnstructuredFormSection";
import NameSummaryWithSlug from "~/primitiveComponents/form/Slugs/NameSummaryWithSlug";
import SlugEditor from "~/primitiveComponents/form/Slugs/SlugEditor";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import StringHelper from "~/utils/StringHelper";
import InternalLink from "../../../../../components/Navigation/InternalLink/InternalLink";
import InternalRedirect from "../../../../../components/Navigation/InternalRedirect/InternalRedirect";
import LifecycleMap from "../LifecycleMap";
import Phase from "../Phase/Phase";
import { RetentionPolicySummary } from "../RetentionPolicy/RetentionPolicySummary";
import styles from "./style.module.less";
interface CreateLifecyclePageProps {
    create: true;
}
interface SpecificLifecycleQueryParams {
    activeTab: "details" | "usage";
}
interface SpecificLifecyclePageProps {
    lifecycleId: string;
    queryParams: SpecificLifecycleQueryParams;
    setQueryParams: QueryParamValuesSetter<SpecificLifecycleQueryParams>;
}
type LifecyclePageProps = (CreateLifecyclePageProps | SpecificLifecyclePageProps) & {
    spaceId: string;
    defaultTab?: string;
};
interface LifecycleState extends OptionalFormBaseComponentState<LifecycleResource> {
    deleted: boolean;
    newId?: string;
    projects?: ProjectResource[];
    environmentsById?: {};
}
type LifecyclePageInternalProps = {
    trackAction: AnalyticTrackedActionDispatcher;
} & LifecyclePageProps;
class LifecyclePageInternal extends FormBaseComponent<LifecyclePageInternalProps, LifecycleState, LifecycleResource> {
    constructor(props: LifecyclePageInternalProps) {
        super(props);
        this.state = {
            deleted: false,
            environmentsById: {},
        };
    }
    async componentDidMount() {
        await this.doBusyTask(() => this.load(), { timeOperationOptions: timeOperationOptions.forInitialLoad() });
        if (!isCreateLifecycleProps(this.props) && this.props.queryParams.activeTab === "usage") {
            this.onUsageTabActive();
        }
    }
    componentDidUpdate(prevProps: LifecyclePageProps) {
        // This should be replaced with an effect when this component is converted to functional
        if (!isCreateLifecycleProps(prevProps) && !isCreateLifecycleProps(this.props) && prevProps.queryParams.activeTab !== "usage" && this.props.queryParams.activeTab === "usage") {
            this.onUsageTabActive();
        }
    }
    render() {
        const isCreate = isCreateLifecycleProps(this.props);
        const legacyOverflowActions = !isCreate && !!this.state.model
            ? [
                OverflowMenuItems.deleteItemDefault("lifecycle", this.handleDeleteConfirm, { permission: Permission.LifecycleDelete }),
                [
                    OverflowMenuItems.navItem("Audit Trail", links.auditPage.generateUrl({ regardingAny: [this.state.model.Id] }), {
                        permission: Permission.EventView,
                        wildcard: true,
                    }),
                ],
            ]
            : [];
        const overflowActions = OverflowMenuConverterVNext.convertAll(legacyOverflowActions);
        const saveText: string = this.state.newId ? "Lifecycle created" : "Lifecycle details updated";
        if (this.state.deleted)
            return <InternalRedirect to={links.lifecyclesPage.generateUrl({ spaceId: this.props.spaceId })}/>;
        if (this.state.newId)
            return <InternalRedirect to={links.editLifecyclePage.generateUrl({ spaceId: this.props.spaceId, lifecycleId: this.state.newId })}/>;
        const model = this.state.model;
        const breadcrumbs = [{ label: "Lifecycles", pageUrl: links.lifecyclesPage.generateUrl({ spaceId: this.props.spaceId }) }];
        if (!model) {
            return (<PageContent header={{ title: StringHelper.ellipsis, breadcrumbs }} busy={this.state.busy} errors={this.errors}>
                    {null}
                </PageContent>);
        }
        return (<>
                <LegacyForm model={this.state.model} cleanModel={this.state.cleanModel} savePermission={{ permission: isCreate ? Permission.LifecycleCreate : Permission.LifecycleEdit }} onSaveClick={this.handleSaveClick} saveText={saveText}>
                    {({ FormContent, createSaveAction }) => {
                if (isCreate) {
                    return (<PageContent header={{ primaryAction: createSaveAction({}), title: "Create Lifecycle", breadcrumbs }} busy={this.state.busy} errors={this.errors}>
                                    <FormContent expandAllOnMount={true}>{this.renderDetailsFormSections(model, { showSlugEditor: false })}</FormContent>
                                </PageContent>);
                }
                const { queryParams, setQueryParams } = this.props;
                return (<PageContent header={{
                        primaryAction: createSaveAction({}),
                        overflowActions: overflowActions.menuItems,
                        title: model.Name,
                        breadcrumbs,
                    }} busy={this.state.busy} errors={this.errors} currentTab={queryParams.activeTab} onTabChanged={(value) => setQueryParams((prev) => ({ ...prev, activeTab: value }))} tabs={[
                        {
                            value: "details",
                            label: "Details",
                            content: <FormContent>{this.renderDetailsFormSections(model, { showSlugEditor: true })}</FormContent>,
                        },
                        {
                            value: "usage",
                            label: "Usage",
                            content: <FormContent>{this.renderUsagesFormSections()}</FormContent>,
                        },
                    ]}/>);
            }}
                </LegacyForm>
                {overflowActions.dialogs}
            </>);
    }
    private renderDetailsFormSections(model: LifecycleResource, { showSlugEditor }: {
        showSlugEditor: boolean;
    }) {
        const actions: React.ReactElement[] = [
            <PermissionCheck key="edit" permission={Permission.LifecycleEdit}>
                <ActionButton type={ActionButtonType.Secondary} onClick={this.handleAddPhaseClick} label="Add Phase"/>
            </PermissionCheck>,
        ];
        if (model.Phases.length > 1) {
            actions.push(<PermissionCheck key="edit" permission={Permission.LifecycleEdit}>
                    <OpenDialogButton label="Reorder phases">
                        <SortPhases phases={model.Phases} onPhasesSorted={(phases) => this.onPhasesSorted(phases)}/>
                    </OpenDialogButton>
                </PermissionCheck>);
        }
        return (<>
                <ExpandableFormSection errorKey="Name" title="Name" focusOnExpandAll summary={model.Name ? Summary.summary(<NameSummaryWithSlug name={model.Name} slug={model.Slug}/>) : Summary.placeholder("Please enter a name for your lifecycle")} help="Enter a name for your lifecycle.">
                    <Text value={model.Name} onChange={(Name) => this.setModelState({ Name })} label="Lifecycle name" validate={required("Please enter a lifecycle name")} error={this.getFieldError("Name")} autoFocus/>

                    {showSlugEditor && (<SlugEditor value={model.Slug ?? ""} name={model.Name} originalSlug={this.state.cleanModel?.Slug ?? ""} onChange={(Slug) => this.setModelState({ Slug })} label={"Lifecycle slug"} validate={required("Please enter a lifecycle slug")} error={this.getFieldError("slug")}/>)}
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="description" title="Description" summary={this.descriptionSummary()} help="Enter a description for your lifecycle.">
                    <MarkdownEditor value={model.Description} label="Lifecycle description" onChange={(Description) => this.setModelState({ Description })}/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="releaseRetentionPolicy,tentacleRetentionPolicy" title="Retention Policy" summary={this.retentionPolicySummary()} help="Change the retention policy.">
                    <RetentionPolicyControl releaseRetentionPolicy={model.ReleaseRetentionPolicy} tentacleRetentionPolicy={model.TentacleRetentionPolicy} onReleaseRetentionPolicyChange={(ReleaseRetentionPolicy) => this.setModelState({ ReleaseRetentionPolicy })} onTentacleRetentionPolicyChange={(TentacleRetentionPolicy) => this.setModelState({ TentacleRetentionPolicy })}/>
                </ExpandableFormSection>
                <FormSectionHeading title="Phases"/>
                <RemovableExpandersList helpElement={this.renderPhasesHelp()} typeDisplayName={"Phase"} data={model.Phases} listActions={actions} onRow={(item: PhaseResource, index: number) => {
                const lifecycle = model;
                const environmentsById = this.state.environmentsById;
                return this.renderPhase(lifecycle!, environmentsById!, item, index);
            }} onRowSummary={(item: PhaseResource) => {
                return Summary.summary(item.Name);
            }} onRowHelp={(item: PhaseResource) => {
                return "Please enter phase details";
            }} onRemoveRowByIndex={this.handlePhaseDeleteByIndex}/>
                <FormSectionHeading title="Lifecycle Preview"/>
                <UnstructuredFormSection>{this.renderLiveLifecyclePreview()}</UnstructuredFormSection>
            </>);
    }
    private renderUsagesFormSections() {
        return (<ExpandableFormSection key="usageInProjects" errorKey="usageInProjects" title="Projects" expandable={this.state.projects && this.state.projects.length > 0} summary={this.usageSummary()} help={this.usageHelp()}>
                <SimpleDataTable<ProjectResource> data={this.state.projects!} headerColumns={["Project Name"]} onRow={(usageEntry) => [<InternalLink to={links.channelsPage.generateUrl({ projectSlug: usageEntry.Slug, spaceId: usageEntry.SpaceId })}>{usageEntry.Name}</InternalLink>]}/>
            </ExpandableFormSection>);
    }
    renderPhase(lifecycle: LifecycleResource, environmentsById: Record<string, EnvironmentResource>, phase: PhaseResource, index: number) {
        return (<Phase key={index} phase={phase} lifecycle={lifecycle} index={index} environmentsById={environmentsById} onPhaseDeleteClick={() => this.handlePhaseDeleteByIndex(index)} onPhaseNameChange={(x) => this.handlePhaseNameChange(index, x)} onAutomaticEnvironmentDeleteClick={(i) => this.handleAutomaticEnvironmentDelete(index, i)} onOptionalEnvironmentDeleteClick={(i) => this.handleOptionalEnvironmentDelete(index, i)} onAddEnvironment={(environmentId, automatic) => this.handleAddEnvironment(index, environmentId, automatic)} onPhaseProgressionChange={(isOptional, minimumEnvironments) => this.handlePhaseProgressionChange(index, isOptional, minimumEnvironments)} onChangeRetentionPolicy={(release, tentacle) => this.handlePhaseRetentionPolicyChange(index, release!, tentacle!)} onPhasePriorityChange={(isPriority) => this.handlePhasePriorityChange(index, isPriority)}/>);
    }
    private usageHelp = () => {
        return this.state.projects && this.state.projects.length > 0
            ? this.state.projects.length > 1
                ? "This lifecycle is being used in the following projects"
                : "This lifecycle is being used in the following project"
            : "This lifecycle is not being used in any projects";
    };
    private usageSummary = () => {
        return this.state.projects && this.state.projects.length > 0
            ? this.state.projects.length > 1
                ? Summary.summary(<span>
                          This lifecycle is being used in <b>{this.state.projects.length}</b> projects
                      </span>)
                : Summary.summary(<span>
                          This lifecycle is being used in <b>1</b> project
                      </span>)
            : Summary.placeholder("This lifecycle is not being used in any projects");
    };
    private async onUsageTabActive() {
        if (this.state.projects || isCreateLifecycleProps(this.props)) {
            return;
        }
        await this.doBusyTask(async () => {
            const getProjects = repository.Lifecycles.projects(this.state.model!);
            this.setState({
                projects: sortBy(await getProjects, (p) => p.Name),
            });
        }, { timeOperationOptions: timeOperationOptions.for("Usage") });
    }
    private handlePhaseDeleteByIndex = (index: number) => {
        this.setState((state) => {
            const Phases = state.model!.Phases.filter((_, i) => i !== index);
            return {
                model: {
                    ...state.model,
                    Phases,
                },
            };
        });
    };
    private handlePhaseNameChange = (index: number, name: string) => {
        this.setState((state) => {
            const phases = [...state.model!.Phases];
            phases[index] = { ...phases[index], Name: name };
            return {
                model: {
                    ...state.model,
                    Phases: phases,
                },
            };
        });
    };
    private handleAutomaticEnvironmentDelete = (phaseIndex: number, environmentIndex: number) => {
        this.setState((state) => {
            const phases = [...state.model!.Phases];
            const targets = state.model!.Phases[phaseIndex].AutomaticDeploymentTargets.filter((_, i) => i !== environmentIndex);
            phases[phaseIndex] = { ...phases[phaseIndex], AutomaticDeploymentTargets: targets };
            return {
                model: {
                    ...state.model,
                    Phases: phases,
                },
            };
        });
    };
    private handleOptionalEnvironmentDelete = (phaseIndex: number, environmentIndex: number) => {
        this.setState((state) => {
            const phases = [...state.model!.Phases];
            const targets = state.model!.Phases[phaseIndex].OptionalDeploymentTargets.filter((_, i) => i !== environmentIndex);
            phases[phaseIndex] = { ...phases[phaseIndex], OptionalDeploymentTargets: targets };
            return {
                model: {
                    ...state.model,
                    Phases: phases,
                },
            };
        });
    };
    private onPhasesSorted(phases: PhaseResource[]) {
        this.setState((state) => ({
            model: {
                ...state.model,
                Phases: phases,
            },
        }));
        return true;
    }
    private renderLiveLifecyclePreview() {
        return this.state.model!.Phases.length === 0 ? <div>Uses default conventions</div> : <LifecycleMap className={styles.summaryMap} environmentsById={this.state.environmentsById!} lifecyclePreview={this.state.model!}/>;
    }
    private renderPhasesHelp() {
        return (<div>
                {this.state.model!.Phases.length === 0 && (<div>
                        This lifecycle will use the default conventions. They allow deployment to any environment, so long as environments are deployed in the order that they are defined on the{" "}
                        <InternalLink to={links.infrastructureRootRedirect.generateUrl({ spaceId: this.props.spaceId })}>environments</InternalLink> page. Use the <em>Add phase</em> button to explicitly define your own phases or to restrict the
                        lifecycle to specific environments.
                    </div>)}
                {this.state.model!.Phases.length > 0 && <div>Projects that use this lifecycle can only be deployed according to the phases below.</div>}
            </div>);
    }
    private retentionPolicySummary() {
        return RetentionPolicySummary(this.state.model!.ReleaseRetentionPolicy, this.state.model!.TentacleRetentionPolicy);
    }
    private descriptionSummary() {
        return this.state.model!.Description ? Summary.summary(<Markdown markup={this.state.model!.Description}/>) : Summary.placeholder("No lifecycle description provided");
    }
    private handleAddPhaseClick = () => {
        const phase: PhaseResource = {
            Id: null!,
            Name: "",
            MinimumEnvironmentsBeforePromotion: 0,
            AutomaticDeploymentTargets: [],
            OptionalDeploymentTargets: [],
            IsOptionalPhase: false,
            IsPriorityPhase: false,
        };
        this.setState((state) => ({
            model: {
                ...state.model,
                Phases: [...this.state.model!.Phases, phase],
            },
        }));
    };
    private handlePhaseRetentionPolicyChange = (index: number, release: RetentionPeriod, tentacle: RetentionPeriod): boolean => {
        this.setState((state) => {
            const phases = [...state.model!.Phases];
            phases[index] = { ...phases[index], ReleaseRetentionPolicy: release, TentacleRetentionPolicy: tentacle };
            return {
                model: {
                    ...state.model,
                    Phases: phases,
                },
            };
        });
        return true;
    };
    private handlePhaseProgressionChange = (index: number, isOptional: boolean, minimumEnvironments: number): void => {
        this.setState((state) => {
            const phases = [...state.model!.Phases];
            phases[index] = { ...phases[index], IsOptionalPhase: isOptional, MinimumEnvironmentsBeforePromotion: minimumEnvironments };
            return {
                model: {
                    ...state.model,
                    Phases: phases,
                },
            };
        });
    };
    private handlePhasePriorityChange = (index: number, isPriority: boolean): void => {
        this.setState((state) => {
            const phases = [...state.model!.Phases];
            phases[index] = { ...phases[index], IsPriorityPhase: isPriority };
            return {
                model: {
                    ...state.model,
                    Phases: phases,
                },
            };
        });
    };
    private handleAddEnvironment = (index: number, environmentId: string, automatic: boolean) => {
        if (!environmentId) {
            return;
        }
        this.setState((state) => {
            const phases = [...state.model!.Phases];
            phases[index] = automatic
                ? { ...phases[index], AutomaticDeploymentTargets: [...phases[index].AutomaticDeploymentTargets, environmentId] }
                : { ...phases[index], OptionalDeploymentTargets: [...phases[index].OptionalDeploymentTargets, environmentId] };
            return {
                model: {
                    ...state.model,
                    Phases: phases,
                },
            };
        });
        return true;
    };
    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const isNew = this.state.model!.Id == null;
            const actionEvent: ActionEvent = {
                action: Action.Save,
                resource: "Lifecycle",
            };
            await this.props.trackAction("Save Lifecycle", actionEvent, async () => {
                const result = await repository.Lifecycles.save(this.state.model!);
                this.setState({
                    model: result,
                    cleanModel: cloneDeep(result),
                    newId: isNew ? result.Id : null!,
                });
            });
        });
    };
    private handleDeleteConfirm = async () => {
        const result = await repository.Lifecycles.del(this.state.model!);
        this.setState((state) => {
            return {
                model: null,
                cleanModel: null, //reset model so that dirty state doesn't prevent navigation
                deleted: true,
            };
        });
        return true;
    };
    private load = async () => {
        const getEnvironmentsById = repository.Environments.allById();
        if (isCreateLifecycleProps(this.props)) {
            const [environmentsById, retentionDefaults] = await Promise.all([getEnvironmentsById, repository.RetentionDefaultConfiguration.get()]);
            const releaseRetentionPolicy = {
                ShouldKeepForever: true,
                QuantityToKeep: 0,
                Unit: RetentionUnit.Days,
            };
            const tentacleRetentionPolicy = {
                ShouldKeepForever: true,
                QuantityToKeep: 0,
                Unit: RetentionUnit.Days,
            };
            if (retentionDefaults.RetentionDays) {
                releaseRetentionPolicy.ShouldKeepForever = false;
                releaseRetentionPolicy.QuantityToKeep = retentionDefaults.RetentionDays;
                tentacleRetentionPolicy.ShouldKeepForever = false;
                tentacleRetentionPolicy.QuantityToKeep = retentionDefaults.RetentionDays;
            }
            const lifecycle: LifecycleResource = {
                Id: null!,
                Name: "",
                SpaceId: "",
                Description: "",
                Phases: [],
                ReleaseRetentionPolicy: releaseRetentionPolicy,
                TentacleRetentionPolicy: tentacleRetentionPolicy,
                Links: null!,
            };
            this.setState({
                model: lifecycle,
                cleanModel: cloneDeep(lifecycle),
                projects: [],
                environmentsById: environmentsById,
            });
        }
        else {
            const [environmentsById, lifecycle] = await Promise.all([getEnvironmentsById, repository.Lifecycles.get(this.props.lifecycleId)]);
            this.setState({
                model: lifecycle,
                cleanModel: cloneDeep(lifecycle),
                environmentsById: environmentsById,
            });
        }
    };
    static displayName = "LifecyclePageInternal";
}
function isCreateLifecycleProps(props: CreateLifecyclePageProps | SpecificLifecyclePageProps): props is CreateLifecyclePageProps {
    return "create" in props;
}
export const LifecyclePage = (props: LifecyclePageProps) => {
    const trackAction = useAnalyticTrackedActionDispatch();
    return <LifecyclePageInternal trackAction={trackAction} {...props}/>;
};
