import { IconButton } from "@octopusdeploy/design-system-components";
import { StarOffIcon, StarOnIcon } from "@octopusdeploy/design-system-icons";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React, { useState } from "react";
export function useOptimisticIsFavourite(isFavourite: boolean, setIsFavourite: (isFavourite: boolean) => Promise<boolean>) {
    const [isPendingUpdate, setIsPendingUpdate] = useState(false);
    const [optimisticIsFavourite, setOptimisticIsFavourite] = useState(isFavourite);
    const optimisticSetIsFavourite = async (nextIsFavourite: boolean) => {
        setOptimisticIsFavourite(nextIsFavourite);
        setIsPendingUpdate(true);
        let success = false;
        try {
            success = await setIsFavourite(nextIsFavourite);
        }
        finally {
            setIsPendingUpdate(false);
        }
        return success;
    };
    return { optimisticIsFavourite: isPendingUpdate ? optimisticIsFavourite : isFavourite, optimisticSetIsFavourite, isPendingUpdate };
}
interface ProjectFavouriteIconProps {
    isFavourite: boolean;
    setIsFavourite: (isFavourite: boolean) => Promise<boolean>;
    isDisabled: boolean;
    projectName: string;
}
export function ProjectFavouriteIconButton({ isFavourite, setIsFavourite, isDisabled, projectName }: ProjectFavouriteIconProps) {
    const accessibleName = isFavourite ? `Remove project ${projectName} from favorites` : `Add project ${projectName} to favorites`;
    return (<IconButton disabled={isDisabled} customIcon={isFavourite ? <StarOnIcon size={20} color={themeTokens.color.icon.attention}/> : <StarOffIcon size={20} color={themeTokens.color.icon.secondary}/>} onClick={() => setIsFavourite(!isFavourite)} accessibleName={accessibleName}/>);
}
interface OptimisticProjectFavouriteIconProps {
    isFavourite: boolean;
    setIsFavourite: (isFavourite: boolean) => Promise<boolean>;
    projectName: string;
}
export function OptimisticProjectFavouriteIconButton({ isFavourite, setIsFavourite, projectName }: OptimisticProjectFavouriteIconProps) {
    const { optimisticIsFavourite, optimisticSetIsFavourite, isPendingUpdate } = useOptimisticIsFavourite(isFavourite, setIsFavourite);
    return <ProjectFavouriteIconButton isFavourite={optimisticIsFavourite} setIsFavourite={optimisticSetIsFavourite} isDisabled={isPendingUpdate} projectName={projectName}/>;
}
