/* eslint-disable @typescript-eslint/init-declarations,@typescript-eslint/consistent-type-assertions */
import type { AzureWebSiteSlot } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository } from "~/clientInstance";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip";
import InputWithActions from "~/components/InputWithActions";
import { Select } from "~/components/form";
import Note from "~/primitiveComponents/form/Note/Note";
import ParseHelper from "~/utils/ParseHelper/ParseHelper";
interface AzureWebAppSlotSelectorProps {
    webAppName: string | undefined;
    resourceGroupName: string | undefined;
    webAppSlotName: string | undefined;
    accountId: string;
    webAppSlotNameError: string;
    doBusyTask: DoBusyTask;
    onWebAppSlotNameChanged(value: string | undefined): void;
}
interface AzureWebAppSlotSelectorState {
    reset: {
        name: string | undefined;
    };
    slots: AzureWebSiteSlot[];
    slotItems: Array<{
        value: string;
        text: string;
    }>;
    selectedSlotIndex: string | undefined;
    busy: boolean; //TODO: move busy back out into props and use a HOC/Render prop component to manage this state
}
const toggleBusy = (value?: boolean) => (prev: AzureWebAppSlotSelectorState, props: AzureWebAppSlotSelectorProps) => ({ ...prev, busy: value ? value : !prev.busy });
class AzureWebAppSlotSelector extends BaseComponent<AzureWebAppSlotSelectorProps, AzureWebAppSlotSelectorState> {
    constructor(props: AzureWebAppSlotSelectorProps) {
        super(props);
        this.state = {
            reset: {
                name: undefined,
            },
            slots: [],
            slotItems: [],
            selectedSlotIndex: undefined,
            busy: false,
        };
    }
    async componentDidMount() {
        await this.getWebSlots(this.props.accountId, this.props.resourceGroupName, this.props.webAppName);
    }
    async UNSAFE_componentWillReceiveProps(newprops: AzureWebAppSlotSelectorProps) {
        if (newprops.accountId !== this.props.accountId || newprops.webAppName !== this.props.webAppName || newprops.resourceGroupName !== this.props.resourceGroupName) {
            await this.getWebSlots(newprops.accountId, newprops.resourceGroupName, newprops.webAppName);
        }
    }
    render() {
        return (<div>
                <InputWithActions input={<Select value={this.state.selectedSlotIndex} onChange={this.handleSelectedSlotChanged} items={this.state.slotItems} label="Web App Slot" allowClear={true}/>} busy={this.state.busy} actions={<IconButtonWithTooltip disabled={this.state.busy} onClick={() => this.getWebSlots(this.props.accountId, this.props.resourceGroupName, this.props.webAppName)} toolTipContent={"Refresh"} icon={"ArrowRefreshIcon"} accessibleName={"Refresh"}/>}/>
                <Note>The name of your Azure Web App Slot.</Note>
            </div>);
    }
    private handleSelectedSlotChanged = (value: string | undefined) => {
        const index = ParseHelper.safeParseInt(value as string);
        const selectedSlot = index !== null ? this.state.slots[index] : undefined;
        this.props.onWebAppSlotNameChanged(selectedSlot ? selectedSlot.Name : undefined);
        this.setState({ selectedSlotIndex: index !== null ? value : undefined });
    };
    private async getWebSlots(accountId: string, resourceGroupName: string | undefined, siteName: string | undefined) {
        this.setState(toggleBusy(true));
        try {
            await this.props.doBusyTask(async () => {
                if (!(accountId && siteName && resourceGroupName)) {
                    this.setState({
                        slots: [],
                        slotItems: [],
                        selectedSlotIndex: undefined,
                    });
                    return;
                }
                const account = await repository.Accounts.get(accountId);
                const slots = await repository.Accounts.getWebSiteSlots(account, resourceGroupName, siteName);
                let selectedSlotIndex: string | undefined;
                if (this.props) {
                    const selectedSlot = slots.find((s) => s.Name === this.props.webAppSlotName && s.ResourceGroupName === resourceGroupName && s.Site === siteName);
                    if (selectedSlot) {
                        const index = slots.indexOf(selectedSlot);
                        selectedSlotIndex = index < 0 ? undefined : index.toString();
                    }
                }
                const slotItems = slots.map((slot, index) => {
                    return {
                        value: index.toString(),
                        text: slot.Name,
                    };
                });
                this.setState({
                    slots,
                    slotItems,
                    selectedSlotIndex,
                });
            });
        }
        finally {
            this.setState(toggleBusy(false));
        }
    }
    static displayName = "AzureWebAppSlotSelector";
}
export default AzureWebAppSlotSelector;
