/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { LibraryVariableSetResource, LibraryVariableSetUsageResource, LibraryVariableSetProjectUsage, VariableSetResource } from "@octopusdeploy/octopus-server-client";
import type { QueryParamValuesSetter } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import type { Dictionary } from "lodash";
import * as React from "react";
import useIsMultiTenancyEnabledFeatureFlag from "~/areas/configuration/hooks/useIsMultiTenancyEnabledFeatureFlag";
import VariableSetSettings from "~/areas/library/components/VariableSets/VariableSetSettings";
import VariableSetTemplates from "~/areas/library/components/VariableSets/VariableSetTemplates";
import VariableEditor from "~/areas/variables/VariableEditor/VariableEditor";
import type { VariableSaveConfirmationContent } from "~/areas/variables/VariableSaveConfirmationDialog/VariableSaveConfirmationDialog";
import { default as VariableSaveConfirmationDialog } from "~/areas/variables/VariableSaveConfirmationDialog/VariableSaveConfirmationDialog";
import { repository } from "~/clientInstance";
import { LegacyForm } from "~/components/FormPaperLayout/LegacyForm";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuConverterVNext } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import { PageContent } from "~/components/PageContent/PageContent";
import { ExpandableFormSection } from "~/components/form";
import DateFormatter from "~/utils/DateFormatter/DateFormatter";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import StringHelper from "~/utils/StringHelper";
import FormBaseComponent from "../../../../components/FormBaseComponent";
import type { OptionalFormBaseComponentState } from "../../../../components/FormBaseComponent/FormBaseComponent";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import type { ReadonlyVariableResource } from "../../../variables/ReadonlyVariableResource";
import { createDialogContent, createViewModel, getVariableResources } from "../../../variables/VariableEditor/conversions";
import type { VariableModel } from "../../../variables/VariablesModel/VariablesModel";
import groupVariablesByName from "../../../variables/groupVariablesByName";
import { variableSetProjectUsageHelp, VariableSetProjectUsages, variableSetProjectUsageSummary } from "./VariableSetProjectUsage";
import { variableSetReleaseUsageHelp, VariableSetReleaseUsages, variableSetReleaseUsageSummary, variableSetRunbookSnapshotUsageSummary, variableSetRunbookUsageHelp, VariableSetRunbookUsages } from "./VariableSetReleaseUsage";
interface EditVariableSetPageState extends OptionalFormBaseComponentState<VariableSetModel> {
    dialogContent?: VariableSaveConfirmationContent;
    libraryVariableSet: LibraryVariableSetResource;
    hasBeenDeleted: boolean;
    initialVariables?: ReadonlyArray<VariableModel>;
    groupedVariableResources?: Dictionary<ReadonlyVariableResource[]>;
    variableSet?: VariableSetResource;
    relativeColumnWidths?: ReadonlyArray<number>;
    cellFocusResetKey: string;
    usages: LibraryVariableSetUsageResource;
    usagesInProjects: LibraryVariableSetProjectUsage[];
    usagesInReleaseSnapshots: LibraryVariableSetProjectUsage[];
    usagesInRunbookSnapshots: LibraryVariableSetProjectUsage[];
}
interface VariableSetModel {
    readonly variables: ReadonlyArray<VariableModel>;
    readonly libraryVariableSet: LibraryVariableSetResource;
}
interface QueryParams {
    activeTab: "variables" | "variableTemplates" | "usages" | "settings";
}
interface EditVariableSetPageProps {
    spaceId: string;
    variableSetId: string;
    queryParams: QueryParams;
    setQueryParams: QueryParamValuesSetter<QueryParams>;
}
interface EditVariableSetPageInternalProps extends EditVariableSetPageProps {
    isMultiTenancyEnabled: boolean;
}
class EditVariableSetPageInternal extends FormBaseComponent<EditVariableSetPageInternalProps, EditVariableSetPageState, VariableSetModel> {
    constructor(props: EditVariableSetPageInternalProps) {
        super(props);
        this.state = {
            libraryVariableSet: null!,
            hasBeenDeleted: false,
            cellFocusResetKey: DateFormatter.timestamp(),
            usages: null!,
            usagesInProjects: [],
            usagesInReleaseSnapshots: [],
            usagesInRunbookSnapshots: [],
        };
    }
    async componentDidMount() {
        await this.doBusyTask(() => this.load(), { timeOperationOptions: timeOperationOptions.forInitialLoad() });
        if (this.props.queryParams.activeTab === "usages") {
            this.onUsageTabActive();
        }
    }
    componentDidUpdate(prevProps: EditVariableSetPageInternalProps) {
        if (prevProps.queryParams.activeTab !== this.props.queryParams.activeTab && this.props.queryParams.activeTab === "usages") {
            this.onUsageTabActive();
        }
    }
    render() {
        const legacyOverflowActions = this.state.model
            ? [
                OverflowMenuItems.deleteItemDefault("variable set", () => this.handleDeleteConfirm(), { permission: Permission.LibraryVariableSetDelete }),
                [
                    OverflowMenuItems.navItem("Audit Trail", links.auditPage.generateUrl({ regardingAny: [this.state.model.libraryVariableSet.Id] }), {
                        permission: Permission.EventView,
                        wildcard: true,
                    }),
                ],
            ]
            : [];
        const overflowActions = OverflowMenuConverterVNext.convertAll(legacyOverflowActions);
        if (this.state.hasBeenDeleted) {
            return <InternalRedirect to={links.variableSetsPage.generateUrl({ spaceId: this.props.spaceId })}/>;
        }
        const model = this.state.model;
        if (!model) {
            return (<PageContent header={{
                    title: StringHelper.ellipsis,
                    breadcrumbs: [{ label: "Variable Sets", pageUrl: links.variableSetsPage.generateUrl({ spaceId: this.props.spaceId }) }],
                }} busy={this.state.busy} errors={this.errors} fullWidth={true}>
                    {null}
                </PageContent>);
        }
        return (<>
                <LegacyForm model={model} cleanModel={this.state.cleanModel} savePermission={{ permission: Permission.LibraryVariableSetEdit, environment: "*", tenant: "*" }} onSaveClick={() => {
                const dialogContent = createDialogContent(model.variables, this.state.initialVariables!, this.state.variableSet!.Variables);
                if (dialogContent && dialogContent.hasContent) {
                    this.setState({ dialogContent });
                }
                else {
                    return this.doBusyTask(() => this.save());
                }
            }}>
                    {({ FormContent, createSaveAction }) => (<PageContent header={{
                    primaryAction: createSaveAction({}),
                    title: model.libraryVariableSet.Name,
                    breadcrumbs: [{ label: "Variable Sets", pageUrl: links.variableSetsPage.generateUrl({ spaceId: this.props.spaceId }) }],
                    overflowActions: overflowActions.menuItems,
                }} busy={this.state.busy} errors={this.errors} fullWidth={true} currentTab={this.props.queryParams.activeTab} onTabChanged={(value) => this.props.setQueryParams((prev) => ({ ...prev, activeTab: value }))} tabs={[
                    {
                        value: "variables",
                        label: "Variables",
                        content: (<FormContent hideExpandAll={true}>
                                            <VariableEditor initialVariables={this.state.initialVariables!} scopeValues={this.state.variableSet!.ScopeValues} isTenanted={this.props.isMultiTenancyEnabled} doBusyTask={this.doBusyTask} onVariablesChanged={(variables) => this.setState((prevState) => ({ model: { ...prevState.model, variables } }))} cellFocusResetKey={this.state.cellFocusResetKey} scope="Unscoped" gitVariables={false}/>
                                        </FormContent>),
                    },
                    {
                        value: "variableTemplates",
                        label: "Variable Templates",
                        content: (<FormContent hideExpandAll={true}>
                                            <VariableSetTemplates parameters={model.libraryVariableSet.Templates} onParametersChanged={(Templates) => this.setState((prev) => ({
                                model: {
                                    ...prev.model,
                                    libraryVariableSet: {
                                        ...prev.model!.libraryVariableSet,
                                        Templates,
                                    },
                                },
                            }))}/>
                                        </FormContent>),
                    },
                    {
                        value: "usages",
                        label: "Usages",
                        content: this.state.usages ? (<FormContent hideExpandAll={true}>
                                            <>
                                                <ExpandableFormSection key="usageInProjects" errorKey="usageInProjects" title="Projects" expandable={this.state.usagesInProjects.length > 0} summary={variableSetProjectUsageSummary(this.state.usagesInProjects, this.state.usages.CountOfProjectsUserCannotSee)} help={variableSetProjectUsageHelp(this.state.usagesInProjects, this.state.usages.CountOfProjectsUserCannotSee)}>
                                                    <VariableSetProjectUsages spaceId={model.libraryVariableSet.SpaceId} usage={this.state.usagesInProjects}/>
                                                </ExpandableFormSection>
                                                <ExpandableFormSection key="usageInReleaseSnapshots" errorKey="usageInReleaseSnapshots" title="Releases" expandable={this.state.usagesInReleaseSnapshots.length > 0} summary={variableSetReleaseUsageSummary(this.state.usagesInReleaseSnapshots, this.state.usages.CountOfReleasesUserCannotSee)} help={variableSetReleaseUsageHelp(this.state.usagesInReleaseSnapshots, this.state.usages.CountOfReleasesUserCannotSee)}>
                                                    <VariableSetReleaseUsages spaceId={model.libraryVariableSet.SpaceId} usage={this.state.usagesInReleaseSnapshots}/>
                                                </ExpandableFormSection>
                                                <ExpandableFormSection key="usagesInRunbookSnapshots" errorKey="usagesInRunbookSnapshots" title="Runbook Snapshots" expandable={this.state.usagesInRunbookSnapshots.length > 0} summary={variableSetRunbookSnapshotUsageSummary(this.state.usagesInRunbookSnapshots, this.state.usages.CountOfRunbookSnapshotsUserCannotSee)} help={variableSetRunbookUsageHelp(this.state.usagesInRunbookSnapshots, this.state.usages.CountOfRunbookSnapshotsUserCannotSee)}>
                                                    <VariableSetRunbookUsages spaceId={model.libraryVariableSet.SpaceId} usage={this.state.usagesInRunbookSnapshots}/>
                                                </ExpandableFormSection>
                                            </>
                                        </FormContent>) : null,
                    },
                    {
                        value: "settings",
                        label: "Settings",
                        content: (<FormContent hideExpandAll={true}>
                                            <VariableSetSettings errors={this.errors!} libraryVariableSet={model.libraryVariableSet} onLibraryVariableSetChanged={(libraryVariableSet) => {
                                this.setState((prev) => ({
                                    model: {
                                        ...prev.model,
                                        libraryVariableSet,
                                    },
                                }));
                            }}/>
                                        </FormContent>),
                    },
                ]}/>)}
                </LegacyForm>
                {overflowActions.dialogs}
                <VariableSaveConfirmationDialog content={this.state.dialogContent} onClosed={() => this.setState({ dialogContent: null! })} onSaveClick={() => this.save()}/>
            </>);
    }
    private async load() {
        const libraryVariableSet = await repository.LibraryVariableSets.get(this.props.variableSetId);
        const variableSet = await repository.Variables.get(libraryVariableSet.VariableSetId);
        const groupedVariableResources = groupVariablesByName(variableSet.Variables, (v) => v.Name);
        const variables = createViewModel(groupedVariableResources);
        const model: VariableSetModel = { variables, libraryVariableSet };
        this.setState({
            libraryVariableSet,
            variableSet,
            groupedVariableResources,
            initialVariables: [...variables],
            model,
            cleanModel: { ...model },
            cellFocusResetKey: DateFormatter.timestamp(),
        });
    }
    private async onUsageTabActive() {
        if (this.state.usages) {
            return;
        }
        await this.doBusyTask(async () => {
            const libraryVariableSet = this.state.libraryVariableSet ? this.state.libraryVariableSet : await repository.LibraryVariableSets.get(this.props.variableSetId);
            const usages = await repository.LibraryVariableSets.getUsages(libraryVariableSet);
            const usagesInProjects = usages.Projects.filter((x) => x.IsCurrentlyBeingUsedInProject === true);
            const usagesInReleaseSnapshots = usages.Projects.filter((x) => x.Releases.length > 0);
            const usagesInRunbookSnapshots = usages.Projects.filter((x) => x.RunbookSnapshots.length > 0);
            this.setState({
                usages,
                usagesInProjects,
                usagesInReleaseSnapshots,
                usagesInRunbookSnapshots,
            });
        }, { timeOperationOptions: timeOperationOptions.for("Usage") });
    }
    private async save() {
        await this.doBusyTask(async () => {
            const variableSet = { ...this.state.variableSet!, Variables: getVariableResources(this.state.model!.variables, this.state.groupedVariableResources!) };
            const librarySetSaveResult = await repository.LibraryVariableSets.modify(this.state.model!.libraryVariableSet);
            const variableSaveResult = librarySetSaveResult && (await repository.Variables.modify(variableSet));
            if (librarySetSaveResult && variableSaveResult) {
                // reload everything, as the result from the save isn't in the exact format we need, old portal did the same
                return this.load();
            }
        });
    }
    private async handleDeleteConfirm() {
        await repository.LibraryVariableSets.del(this.state.model!.libraryVariableSet);
        this.setState({ hasBeenDeleted: true });
        return true;
    }
    static displayName = "EditVariableSetPageInternal";
}
export function EditVariableSetPage({ spaceId, variableSetId, queryParams, setQueryParams }: EditVariableSetPageProps) {
    const isMultiTenancyEnabled = useIsMultiTenancyEnabledFeatureFlag();
    return <EditVariableSetPageInternal spaceId={spaceId} variableSetId={variableSetId} isMultiTenancyEnabled={isMultiTenancyEnabled} queryParams={queryParams} setQueryParams={setQueryParams}/>;
}
