/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Divider } from "@octopusdeploy/design-system-components";
import type { PrimaryPageAction, PageAction } from "@octopusdeploy/design-system-components";
import type { WorkerPoolResource, WorkerPoolsSummaryResource, TenantResource, DynamicWorkerType } from "@octopusdeploy/octopus-server-client";
import { Permission, EndpointsHelper } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import type * as H from "history";
import { isEqual } from "lodash";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useOnClearMachineDispatch } from "~/areas/infrastructure/hooks/useOnClearMachineDispatch";
import { repository } from "~/clientInstance";
import AdvancedFilterLayout, { AdvancedFilterCheckbox } from "~/components/AdvancedFilterLayout";
import { AdvancedFilters } from "~/components/AdvancedFilterLayout/AdvancedFilters";
import { AdvancedFilterTextInput } from "~/components/AdvancedFilterLayout/Text/AdvancedFilterTextInput";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import Dialog from "~/components/Dialog/Dialog";
import type { DialogControls } from "~/components/Dialog/DialogTrigger";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import FormPage from "~/components/FormPage/FormPage";
import { EndpointCommunicationStyleMultiSelect } from "~/components/MultiSelect/EndpointCommunicationStyleMultiSelect";
import { MachineModelHealthStatusMultiSelect } from "~/components/MultiSelect/MachineModelHealthStatusMultiSelect";
import { ShellNameMultiSelect } from "~/components/MultiSelect/ShellNameMultiSelect";
import { TargetTagMultiSelect } from "~/components/MultiSelect/TargetTagMultiSelect";
import { WorkerPoolMultiSelect } from "~/components/MultiSelect/WorkerPoolMultiSelect";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { NoResults } from "~/components/NoResults/NoResults";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuConverterVNext } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import { PageContent } from "~/components/PageContent/PageContent";
import { PageLoading } from "~/components/PageContent/PageLoading";
import NumberedPagingBar from "~/components/PagingBaseComponent/NumberedPagingBar";
import { QueryStringFilters } from "~/components/QueryStringFilters/QueryStringFilters";
import { Section } from "~/components/Section/Section";
import ExpansionButtons from "~/components/form/Sections/ExpansionButtons";
import * as tenantTagsets from "~/components/tenantTagsets";
import type { TagIndex } from "~/components/tenantTagsets";
import MachineHealthStatusHelper from "~/utils/MachineHealthStatusHelper";
import RequestRaceConditioner from "~/utils/RequestRaceConditioner";
import PermissionCheck, { isAllowed } from "../../../../../components/PermissionCheck/PermissionCheck";
import ConfirmTentacleUpgradePanel from "../../MachinesLayout/ConfirmTentacleUpgradePanel";
import AddWorkerPoolsDialog from "./AddWorkerPoolsDialog";
import type { WorkerPoolSummaryMachineQuery } from "./WorkerPoolSummaryMachineQuery";
import WorkerPoolSummarySection from "./WorkerPoolSummarySection";
import WorkerPoolsSorter from "./WorkerPoolsSorter";
import type { WorkerPoolsSummaryFilter } from "./WorkerPoolsSummaryFilter";
import { defaultWorkerPoolsSummaryFilter, createWorkerPoolsSummaryArgs, workerPoolsFilterToQuery, workerPoolsQueryToFilter } from "./WorkerPoolsSummaryFilter";
class FilterLayout extends AdvancedFilterLayout<WorkerPoolsSummaryFilter> {
}
const WorkerPoolQueryStringFilters = QueryStringFilters.For<WorkerPoolsSummaryFilter, WorkerPoolSummaryMachineQuery>();
const PageSize = 20;
interface WorkerPoolsPageProps {
    spaceId: string;
}
interface WorkerPoolsLayoutInnerProps extends WorkerPoolsPageProps {
    initialData: InitialData;
    onClearMachine(): void;
    location: H.Location;
    history: H.History;
    addWorkerPoolDialogControls: DialogControls;
}
interface WorkerPoolLayoutState extends DataBaseComponentState {
    workerPoolsSummary: WorkerPoolsSummaryResource;
    filter: WorkerPoolsSummaryFilter;
    queryFilter?: WorkerPoolsSummaryFilter;
    isSearching?: boolean;
    workerPools: WorkerPoolResource[];
    machineRoles: string[];
    tenants: TenantResource[];
    tagIndex: TagIndex;
    hasDeploymentTargets: boolean;
    redirectToTasks: boolean;
    redirectToWorkerPoolId?: string;
    currentPageIndex: number;
    workerShellNames: string[];
    workerTypes: DynamicWorkerType[];
}
interface InitialData {
    workerPools: WorkerPoolResource[];
    machineRoles: string[];
    tagIndex: TagIndex;
    hasDeploymentTargets: boolean;
    workerShellNames: string[];
    workerPoolsSummary: WorkerPoolsSummaryResource;
    tenants: TenantResource[];
    workerTypes: DynamicWorkerType[];
}
const Title = "Worker Pools";
const WorkerPoolsLayoutFormPage = FormPage<InitialData>();
export function WorkerPoolsPage({ spaceId }: WorkerPoolsPageProps) {
    const history = useHistory();
    const location = useLocation();
    const onClearMachine = useOnClearMachineDispatch();
    const addWorkerPoolDialogControls = useDialogTrigger();
    return (<WorkerPoolsLayoutFormPage title={Title} load={async () => {
            const defaultWorkerPoolSummaryArgs = createWorkerPoolsSummaryArgs(defaultWorkerPoolsSummaryFilter);
            const workerPools = repository.WorkerPools.all();
            const machineRoles = repository.MachineRoles.all();
            const workerShellNames = repository.WorkerShells.all();
            const workerPoolsSummary = repository.WorkerPools.summary(defaultWorkerPoolSummaryArgs);
            const tenants = isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? repository.Tenants.all() : Promise.resolve([]);
            const tagIndex = tenantTagsets.getTagIndex();
            const workerTypes = repository.WorkerPools.getDynamicWorkerTypes();
            const machineCount = repository.Machines.list({ take: 0 });
            return {
                workerPools: await workerPools,
                machineRoles: await machineRoles,
                tagIndex: await tagIndex,
                hasDeploymentTargets: (await machineCount).TotalResults > 0,
                workerShellNames: await workerShellNames,
                workerPoolsSummary: await workerPoolsSummary,
                tenants: await tenants,
                workerTypes: await workerTypes,
            };
        }} renderWhenLoaded={(data) => <WorkerPoolsLayoutInternal initialData={data} onClearMachine={onClearMachine} location={location} history={history} spaceId={spaceId} addWorkerPoolDialogControls={addWorkerPoolDialogControls}/>} renderAlternate={() => <PageLoading loadingTitle={Title}/>}/>);
}
class WorkerPoolsLayoutInternal extends DataBaseComponent<WorkerPoolsLayoutInnerProps, WorkerPoolLayoutState> {
    private machineHealthStatuses = MachineHealthStatusHelper.getMachineModelHealthStatusResources();
    private communicationStyles = EndpointsHelper.getCommunicationStyleResources();
    private requestRaceConditioner = new RequestRaceConditioner();
    constructor(props: WorkerPoolsLayoutInnerProps) {
        super(props);
        this.state = {
            workerPoolsSummary: props.initialData.workerPoolsSummary,
            filter: defaultWorkerPoolsSummaryFilter,
            isSearching: false,
            machineRoles: props.initialData.machineRoles,
            workerPools: props.initialData.workerPools,
            tenants: props.initialData.tenants,
            tagIndex: props.initialData.tagIndex,
            hasDeploymentTargets: false,
            currentPageIndex: 0,
            workerShellNames: props.initialData.workerShellNames,
            workerTypes: props.initialData.workerTypes,
            redirectToTasks: false,
        };
    }
    async UNSAFE_componentDidMount() {
        // Clear currentMachine (to avoid seeing old machine data when switching machines).
        this.props.onClearMachine();
    }
    render() {
        if (this.state.redirectToTasks) {
            return <InternalRedirect to={links.tasksPage.generateUrl()} push={true}/>;
        }
        if (this.state.redirectToWorkerPoolId) {
            return <InternalRedirect to={links.editWorkerPoolPage.generateUrl({ spaceId: this.props.spaceId, workerPoolId: this.state.redirectToWorkerPoolId })} push={true}/>;
        }
        const hasWorkerPool = this.state.workerPools && this.state.workerPools.length > 0;
        const addWorkerPageAction: PageAction = {
            type: "navigate",
            label: "Add Worker",
            path: links.newWorkerMachinePage.generateUrl({ spaceId: this.props.spaceId }),
            buttonType: "secondary",
            hasPermissions: isAllowed({
                permission: Permission.WorkerEdit,
            }),
        };
        const addWorkerPoolPageAction: PrimaryPageAction = {
            type: "button",
            label: "Add Worker Pool",
            hasPermissions: isAllowed({
                permission: Permission.WorkerEdit,
            }),
            onClick: this.props.addWorkerPoolDialogControls.openDialog,
        };
        const legacyOverflowMenuReorderItem = OverflowMenuItems.dialogItem("Reorder", <WorkerPoolsSorter saveDone={this.refreshWorkerPoolSummaryData}/>, { permission: Permission.WorkerView });
        const overflowMenu = OverflowMenuConverterVNext.convertAll([legacyOverflowMenuReorderItem]);
        let workerPoolExpanders: React.ReactNode = null;
        const workerPoolSummaries = this.state.workerPoolsSummary && this.state.workerPoolsSummary.WorkerPoolSummaries;
        if (workerPoolSummaries) {
            const start = this.state.currentPageIndex === 0 ? 0 : this.state.currentPageIndex * PageSize;
            const end = start + PageSize;
            workerPoolExpanders = workerPoolSummaries.slice(start, end).map((workerPoolsSummary) => {
                return (<React.Fragment key={workerPoolsSummary.WorkerPool.Id}>
                        <Divider />
                        <WorkerPoolSummarySection workerPoolSummary={workerPoolsSummary} workerTypes={this.state.workerTypes} filter={this.state.filter} isFiltering={this.isFiltering()} tenants={this.props.initialData.tenants} tagIndex={this.state.tagIndex}/>
                    </React.Fragment>);
            });
        }
        if (this.state.workerPoolsSummary && this.state.workerPoolsSummary.WorkerPoolSummaries.length === 0) {
            workerPoolExpanders = (<Section>
                    <NoResults />
                </Section>);
        }
        const advancedFilters = (<AdvancedFilters>
                <AdvancedFilterCheckbox label="Disabled only" value={this.state.filter.isDisabled === true} onChange={(x) => {
                this.setFilterState({ isDisabled: x ? x : undefined }, () => {
                    this.onFilterChange();
                });
            }}/>
                <AdvancedFilterCheckbox label="Hide empty worker pools" value={this.state.filter.hideEmptyWorkerPools} onChange={(x) => {
                this.setFilterState({ hideEmptyWorkerPools: x }, () => {
                    this.onFilterChange();
                });
            }}/>
                <AdvancedFilterTextInput fieldName={"worker"} value={this.state.filter.machinePartialName || ""} onChange={(x) => {
                this.setFilterState({ machinePartialName: x }, () => {
                    this.onFilterChange();
                });
            }}/>
                <WorkerPoolMultiSelect items={this.state.workerPools ? this.state.workerPools : []} value={this.state.filter.workerPoolIds} onChange={(x) => {
                this.setFilterState({ workerPoolIds: x }, () => {
                    this.onFilterChange();
                });
            }}/>
                <TargetTagMultiSelect items={this.state.machineRoles ? this.state.machineRoles : []} value={this.state.filter.roles} onChange={(x) => {
                this.setFilterState({ roles: x }, () => {
                    this.onFilterChange();
                });
            }}/>
                <MachineModelHealthStatusMultiSelect items={this.machineHealthStatuses} value={this.state.filter.healthStatuses} onChange={(x) => {
                this.setFilterState({ healthStatuses: x }, () => {
                    this.onFilterChange();
                });
            }}/>
                <EndpointCommunicationStyleMultiSelect items={this.communicationStyles} value={this.state.filter.commStyles} onChange={(x) => {
                this.setFilterState({ commStyles: x }, () => {
                    this.onFilterChange();
                });
            }}/>
                <ShellNameMultiSelect items={this.state.workerShellNames ? this.state.workerShellNames : []} value={this.state.filter.shellNames} onChange={(x) => {
                this.setFilterState({ shellNames: x }, this.onFilterChange);
            }}/>
            </AdvancedFilters>);
        const tentacleUpgradesRequiredWarning = workerPoolSummaries && workerPoolSummaries.length > 0 && workerPoolSummaries.find((x) => x.TentacleUpgradesRequired === true) && (<PermissionCheck permission={Permission.WorkerEdit}>
                <ConfirmTentacleUpgradePanel doBusyTask={this.doBusyTask} onTentacleUpgradeComplete={() => {
                this.setState({ redirectToTasks: true });
            }}/>
            </PermissionCheck>);
        return (<>
                <WorkerPoolQueryStringFilters filter={this.state.filter} getQuery={workerPoolsFilterToQuery} getFilter={workerPoolsQueryToFilter} onFilterChange={(filter) => this.setState({ filter, queryFilter: filter }, () => this.onFilterChange())}/>
                <PageContent header={{
                title: Title,
                primaryAction: addWorkerPoolPageAction,
                pageActions: hasWorkerPool ? [addWorkerPageAction] : undefined,
                overflowActions: hasWorkerPool ? overflowMenu.menuItems : undefined,
            }} filters={{
                inputs: [
                    <FilterSearchBox placeholder={"Search worker pools..."} value={this.state.filter.partialName} onChange={(x) => {
                            this.setFilterState({ partialName: x }, () => {
                                this.onFilterChange();
                            });
                        }} autoFocus={true}/>,
                ],
                advancedFilters: {
                    content: advancedFilters,
                    hasUserSelectedValues: this.isFiltering(),
                    onResetFilter: () => {
                        this.setState({ filter: defaultWorkerPoolsSummaryFilter }, () => {
                            this.onFilterChange();
                            const location = { ...this.props.history, search: "" };
                            this.props.history.replace(location);
                        });
                    },
                },
            }} busy={this.state.busy} errors={this.errors}>
                    {overflowMenu.dialogs}
                    <Dialog open={this.props.addWorkerPoolDialogControls.isOpen}>
                        <AddWorkerPoolsDialog saveDone={async (workerPool) => {
                this.setState({ redirectToWorkerPoolId: workerPool.Id });
                this.props.addWorkerPoolDialogControls.closeDialog();
            }}/>
                    </Dialog>
                    {workerPoolSummaries && (<div>
                            {tentacleUpgradesRequiredWarning}
                            <div>
                                <ExpansionButtons />
                                {workerPoolExpanders}
                                {this.state.workerPoolsSummary && this.state.workerPoolsSummary.WorkerPoolSummaries.length > 0 && (<NumberedPagingBar currentPageIndex={this.state.currentPageIndex} totalItems={this.state.workerPoolsSummary.WorkerPoolSummaries.length} pageSize={PageSize} onPageSelected={(_, currentPageIndex) => this.setState({ currentPageIndex })}/>)}
                            </div>
                        </div>)}
                </PageContent>
            </>);
    }
    private setFilterState<K extends keyof WorkerPoolsSummaryFilter>(state: Pick<WorkerPoolsSummaryFilter, K>, callback?: () => void) {
        this.setState((prev) => ({
            filter: { ...(prev.filter as object), ...(state as object) },
        }), callback);
    }
    private isFiltering() {
        return !isEqual(this.state.filter, defaultWorkerPoolsSummaryFilter);
    }
    private onFilterChange() {
        this.setState({ isSearching: true }, async () => {
            await this.refreshWorkerPoolSummaryData();
            this.setState({ isSearching: false });
        });
    }
    private refreshWorkerPoolSummaryData = async () => {
        await this.doBusyTask(() => this.loadWorkerPoolSummaries());
    };
    private async loadWorkerPoolSummaries() {
        const workerPoolSummariesArgs = createWorkerPoolsSummaryArgs(this.state.filter);
        await this.requestRaceConditioner.avoidStaleResponsesForRequest(repository.WorkerPools.summary(workerPoolSummariesArgs), (response) => {
            this.setState({
                workerPoolsSummary: response as WorkerPoolsSummaryResource,
            });
        });
    }
    static displayName = "WorkerPoolsLayoutInternal";
}
