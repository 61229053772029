/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { css } from "@emotion/css";
import { TimeCircleIcon } from "@octopusdeploy/design-system-icons";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ProjectResource, ResourceCollection, RunbookResource, TriggerActionCategory, TriggerResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { PagingList } from "~/components/PagingList/PagingList";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import Note from "~/primitiveComponents/form/Note/Note";
class TriggersList extends PagingList<TriggerResource> {
}
export interface NextScheduledRunsBuildTriggerRowProps {
    trigger: TriggerResource;
}
interface NextScheduledRunsProps {
    project: ProjectResource;
    runbook?: RunbookResource;
    triggersResponse: ResourceCollection<TriggerResource>;
    triggerActionCategory: TriggerActionCategory;
    renderBuildTriggerRow: (props: NextScheduledRunsBuildTriggerRowProps) => React.ReactElement<any>;
}
export const NextScheduleRunsTitle: React.FC = () => (<h4 className={nextScheduledRunsTitle}>
        <TimeCircleIcon size={24} color={themeTokens.color.icon.tertiary}/>
        <span>Scheduled Triggers</span>
    </h4>);
NextScheduleRunsTitle.displayName = "NextScheduleRunsTitle"
export const NextScheduledRuns: React.FC<NextScheduledRunsProps> = ({ project, runbook, triggersResponse, triggerActionCategory, renderBuildTriggerRow }) => {
    const createTriggerUrl = runbook
        ? links.projectRunbookCreateScheduledTriggerForRunbookPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id })
        : links.projectRunbookCreateScheduledTriggerPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug });
    const overflowMenuItems = [
        OverflowMenuItems.navItem("Create scheduled trigger", createTriggerUrl, {
            permission: Permission.TriggerCreate,
            project: project.Id,
            wildcard: true,
        }),
    ];
    const overflowMenu = <OverflowMenu menuItems={overflowMenuItems}/>;
    return (<PermissionCheck permission={Permission.TriggerView} project={project.Id}>
            <>
                <div className={nextScheduledRunsTitleContainer}>
                    <NextScheduleRunsTitle />
                    {overflowMenu}
                </div>
                {!runbook && triggersResponse.Items.length === 0 && (<Note>
                        No triggers are currently setup for runbooks.
                        {/* <InternalLink to={routeLinks.project(project.Slug).operations.scheduledTriggers.new}>Create a trigger now</InternalLink>. */}
                    </Note>)}
                {runbook && triggersResponse.Items.length === 0 && (<Note>
                        No triggers are currently setup for this runbook.
                        {/* <InternalLink to={routeLinks.project(project.Slug).operations.scheduledTriggers.newForRunbook(runbook.Id)}>Create a trigger now</InternalLink>. */}
                    </Note>)}
                {triggersResponse && (<TriggersList initialData={triggersResponse} additionalRequestParams={new Map([["triggerActionCategory", [triggerActionCategory]]])} onRow={(trigger: TriggerResource) => renderBuildTriggerRow({ trigger })} filterSearchEnabled={false} wrapRowsInListItem={false}/>)}
            </>
        </PermissionCheck>);
};
NextScheduledRuns.displayName = "NextScheduledRuns"
const nextScheduledRunsTitleContainer = css({
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
});
const nextScheduledRunsTitle = css({
    display: "flex",
    alignItems: "center",
    gap: space[4],
});
