import { ProcessType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { LegacyPaperLayoutVNextProps } from "~/components/PaperLayout/LegacyPaperLayoutVNext";
import { LegacyPaperLayoutVNext } from "~/components/PaperLayout/LegacyPaperLayoutVNext";
import { ProjectPageTitleAccessory } from "../../../components/ProjectPageTitleAccessory";
import { RunbooksPaperLayout } from "../../Runbooks/Layouts";
type ProcessPaperLayoutProps = LegacyPaperLayoutVNextProps & {
    processType: ProcessType;
};
export const ProcessPaperLayout: React.FC<ProcessPaperLayoutProps> = ({ children, ...rest }) => {
    if (rest.processType === ProcessType.Runbook) {
        return (<RunbooksPaperLayout fullWidth={true} {...rest}>
                {children}
            </RunbooksPaperLayout>);
    }
    else {
        return (<LegacyPaperLayoutVNext titleAccessory={<ProjectPageTitleAccessory />} fullWidth={true} {...rest}>
                {children}
            </LegacyPaperLayoutVNext>);
    }
};
ProcessPaperLayout.displayName = "ProcessPaperLayout"
