import { Tooltip } from "@octopusdeploy/design-system-components";
import { ActivityStatus } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { TaskStatusIcon } from "~/areas/tasks/components/Task/Kubernetes/TaskStatusIcon";
import { useOctopusTheme } from "~/components/Theme/index";
export const ObjectStatus = (props: {
    status: string;
    stepStatus: string | undefined;
}) => {
    const theme = useOctopusTheme();
    switch (props.stepStatus) {
        case ActivityStatus.Failed:
        case ActivityStatus.Success:
        case ActivityStatus.SuccessWithWarning:
        case ActivityStatus.Canceled:
        case "Successful":
            if (props.status === "InProgress" || props.status === ActivityStatus.Running) {
                return (<Tooltip content="Timed out while in progress">
                        <em aria-label="Timeout Status" className="fa-solid fa-clock" style={{ color: theme.alertText }}/>
                    </Tooltip>);
            }
    }
    return <TaskStatusIcon status={props.status}/>;
};
