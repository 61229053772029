/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/init-declarations */
import type { OctopusPackageVersionBuildInformationMappedResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { repository } from "~/clientInstance";
import BuildInformationTable from "~/components/BuildInformation/BuildInformationTable";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuConverterVNext } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import { PageContent } from "~/components/PageContent/PageContent";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
interface BuildInformationDetailProps {
    id: string;
    spaceId: string;
}
interface BuildInformationDetailState extends DataBaseComponentState {
    buildInformation?: OctopusPackageVersionBuildInformationMappedResource;
    deleted?: boolean;
}
export class BuildInformationDetailPage extends DataBaseComponent<BuildInformationDetailProps, BuildInformationDetailState> {
    private id: string;
    constructor(props: BuildInformationDetailProps) {
        super(props);
        this.id = this.props.id;
        this.state = {};
    }
    async componentDidMount() {
        await this.doBusyTask(() => this.load(), { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    async load() {
        const buildInformation = await repository.BuildInformationRepository.get(this.id);
        let errors: {
            message: string;
            errors: {
                [key: string]: string;
            };
        } | undefined;
        if (buildInformation.IncompleteDataWarning) {
            errors = { message: `Unable to retrieve part of the build information for package '${buildInformation.PackageId}'.`, errors: { buildInformationIncomplete: buildInformation.IncompleteDataWarning } };
        }
        this.setState({ buildInformation });
        if (errors) {
            this.setValidationErrors(errors.message, errors.errors);
        }
    }
    render() {
        const buildInformation = this.state.buildInformation;
        const title = this.state.buildInformation ? buildInformation!.PackageId + " " + buildInformation!.Version : "";
        const legacyOverflowActions = [
            OverflowMenuItems.deleteItemDefault(title!, this.handleDeleteConfirm, { permission: Permission.BuildInformationAdminister, project: "*" }, "Deleting build information means it will be unavailable for future releases"),
        ];
        const overflowMenu = OverflowMenuConverterVNext.convertAll(legacyOverflowActions);
        return (<PageContent header={{
                title,
                breadcrumbs: buildInformation
                    ? [{ label: buildInformation.PackageId, pageUrl: links.buildInformationVersionsPage.generateUrl({ spaceId: this.props.spaceId, packageId: (buildInformation && buildInformation.PackageId)! }) }]
                    : undefined,
                overflowActions: overflowMenu.menuItems,
            }} busy={this.state.busy} errors={this.errors}>
                {overflowMenu.dialogs}
                {this.state.deleted && <InternalRedirect to={links.buildInformationOverviewPage.generateUrl({ spaceId: this.props.spaceId })}/>}
                {buildInformation && <BuildInformationTable data={buildInformation}/>}
            </PageContent>);
    }
    private handleDeleteConfirm = async () => {
        await repository.BuildInformationRepository.deleteMany([this.id]);
        this.setState({ deleted: true });
        return true;
    };
    static displayName = "BuildInformationDetailPage";
}
