/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-eq-null */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { PageAction } from "@octopusdeploy/design-system-components";
import type { ExternalFeedResource } from "@octopusdeploy/octopus-server-client";
import { FeedType, Permission } from "@octopusdeploy/octopus-server-client";
import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import { links } from "@octopusdeploy/portal-routes";
import { isEqual, cloneDeep } from "lodash";
import * as React from "react";
import type { ActionEvent, AnalyticActionDispatcher, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { Action, AnalyticView, useAnalyticActionDispatch, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import { createSaveAndTestFeedAnalyticsEvent, createSaveFeedAnalyticsEvent } from "~/areas/library/components/ExternalFeeds/amplitudeTracking";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent/index";
import { FeedTypeSelect } from "~/components/FeedTypeSelect/FeedTypeSelect";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent";
import FormBaseComponent from "~/components/FormBaseComponent";
import { LegacyForm } from "~/components/FormPaperLayout/LegacyForm";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { ConvertedOverflowMenuItems } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import { OverflowMenuConverterVNext } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import { PageContent } from "~/components/PageContent/PageContent";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { ExpandableFormSection, Summary, Text, Note, required } from "~/components/form";
import NameSummaryWithSlug from "~/primitiveComponents/form/Slugs/NameSummaryWithSlug";
import SlugEditor from "~/primitiveComponents/form/Slugs/SlugEditor";
import StringHelper from "~/utils/StringHelper";
import feedTypeRegistry from "./FeedTypes";
export interface ExternalFeedProps {
    spaceId: string;
    create?: boolean;
    feedId?: string;
    callout?: React.ReactNode;
}
export type ExternalFeedPropsInternal = FormStateChanged & {
    trackAction: AnalyticTrackedActionDispatcher;
    dispatchAction: AnalyticActionDispatcher;
    spaceId: string;
    create?: boolean;
    feedId?: string;
    onStateTransition?: (currentState: ExternalFeedStateTypes | null, newState: ExternalFeedStateTypes) => void;
    expandableContainerKey?: string;
    allowedFeedTypes?: FeedType[];
};
export type ExternalFeedStateTypes = ExternalFeedCreateState | ExternalFeedNewState | ExternalFeedEditState | ExternalFeedTestState | ExternalFeedDeleteState;
export interface CommonStateTransitionProps {
    primaryAction?: PageAction;
    secondaryActions?: PageAction[];
    overflowMenuItems?: ConvertedOverflowMenuItems;
}
export type ExternalFeedCreateState = CommonStateTransitionProps & {
    state: "create";
};
export type ExternalFeedNewState = CommonStateTransitionProps & {
    state: "new";
    feedId: string;
    feed: ExternalFeedResource | undefined;
};
export type ExternalFeedEditState = CommonStateTransitionProps & {
    state: "edit";
    feedId: string;
    feed: ExternalFeedResource | undefined;
};
export type ExternalFeedTestState = CommonStateTransitionProps & {
    state: "test";
    feedId: string;
    feed: ExternalFeedResource | undefined;
};
export type ExternalFeedDeleteState = CommonStateTransitionProps & {
    state: "delete";
};
export interface ExternalFeedRouteParams {
    feedId: string;
}
interface FormState {
    model: ExternalFeedResource | undefined;
    cleanModel: ExternalFeedResource | undefined;
    busy: Promise<void> | undefined;
    errors?: Errors;
}
export interface FormStateChanged {
    onFormStateChanged: (newState: FormState) => void;
}
interface ExternalFeedState extends OptionalFormBaseComponentState<ExternalFeedResource> {
    deleted: boolean;
    newId: string;
    test: boolean;
}
export const ExternalFeedInPaperLayout = (props: ExternalFeedProps) => {
    const trackAction = useAnalyticTrackedActionDispatch();
    const dispatchAction = useAnalyticActionDispatch();
    const [state, setState] = React.useState<ExternalFeedStateTypes>({
        state: "create",
    });
    const [formState, setFormState] = React.useState<FormState>({
        busy: undefined,
        model: undefined,
        errors: undefined,
        cleanModel: undefined,
    });
    const [primaryAction, setPrimaryAction] = React.useState<PageAction>();
    const [secondaryActions, setSecondaryActions] = React.useState<PageAction[]>();
    const [overflowMenuItems, setOverflowMenuItems] = React.useState<ConvertedOverflowMenuItems>();
    const trackEvent = useTrackEvent();
    const onStateTransition = (currentState: ExternalFeedStateTypes | null, newState: ExternalFeedStateTypes) => {
        setState(newState);
        setPrimaryAction(newState.primaryAction);
        setSecondaryActions(newState.secondaryActions);
        setOverflowMenuItems(newState.overflowMenuItems);
    };
    const title = state.state === "create" ? "Create Feed" : formState.model ? formState.model.Name : StringHelper.ellipsis;
    const saveText: string = state.state ? "Feed created" : "Feed details updated";
    if (state.state === "delete") {
        return <InternalRedirect to={links.feedsPage.generateUrl({ spaceId: props.spaceId })}/>;
    }
    if (state.state === "new") {
        trackEvent(createSaveFeedAnalyticsEvent({
            Location: "Page",
            "Feed Type": state.feed?.FeedType,
        }));
        return <InternalRedirect to={links.editFeedPage.generateUrl({ spaceId: props.spaceId, feedId: state.feedId })}/>;
    }
    if (state.state === "test") {
        trackEvent(createSaveAndTestFeedAnalyticsEvent({
            Location: "Page",
            "Feed Type": state.feed?.FeedType,
        }));
        return <InternalRedirect to={links.testFeedPage.generateUrl({ spaceId: props.spaceId, feedId: state.feedId })} push={true}/>;
    }
    const feedId = formState.model?.Id ?? props.feedId;
    return (<LegacyForm model={formState.model} cleanModel={formState.cleanModel} onSaveClick={() => {
            if (primaryAction?.type === "button")
                primaryAction.onClick(undefined);
        }} savePermission={{ permission: [Permission.FeedEdit] }} saveText={saveText}>
            {({ FormContent, createSaveAction }) => (<PageContent header={{
                primaryAction: createSaveAction({}),
                title,
                breadcrumbs: [{ label: "External Feeds", pageUrl: links.feedsPage.generateUrl({ spaceId: props.spaceId }) }],
                pageActions: secondaryActions,
                overflowActions: overflowMenuItems && overflowMenuItems.menuItems,
            }} busy={formState.busy} errors={formState.errors}>
                    {overflowMenuItems && overflowMenuItems.dialogs}
                    <FormContent expandAllOnMount={props.create}>
                        <ExternalFeedInternal spaceId={props.spaceId} create={props.create} trackAction={trackAction} dispatchAction={dispatchAction} feedId={feedId} onStateTransition={onStateTransition} onFormStateChanged={setFormState}/>
                    </FormContent>
                </PageContent>)}
        </LegacyForm>);
};
export class ExternalFeedInternal extends FormBaseComponent<ExternalFeedPropsInternal, ExternalFeedState, ExternalFeedResource> {
    constructor(props: ExternalFeedPropsInternal) {
        super(props);
        this.state = {
            deleted: false,
            newId: null!,
            test: false,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            if (this.props.create) {
                this.resetModel();
            }
            else if (this.props.feedId) {
                const feed = (await repository.Feeds.get(this.props.feedId)) as ExternalFeedResource;
                this.setState({
                    model: feed,
                    cleanModel: cloneDeep(feed),
                });
            }
        });
    }
    componentDidUpdate(prevProps: ExternalFeedPropsInternal, prevState: ExternalFeedState) {
        if (prevState.busy !== this.state.busy || prevState.model !== this.state.model || prevState.cleanModel !== this.state.cleanModel || prevState.test !== this.state.test || prevState.deleted !== this.state.deleted) {
            this.notifyFormStateChanged();
        }
    }
    private notifyFormStateChanged() {
        this.props.onFormStateChanged({
            busy: this.state.busy,
            model: this.state.model,
            cleanModel: this.state.cleanModel,
            errors: this.errors,
        });
        const isDirty = this.isDirty();
        const overFlowActions = [];
        if (!this.props.create && !!this.state.model) {
            overFlowActions.push(OverflowMenuItems.deleteItemDefault("feed", this.handleDeleteConfirm, { permission: [Permission.FeedEdit] }));
            overFlowActions.push([
                OverflowMenuItems.navItem("Audit Trail", links.auditPage.generateUrl({ regardingAny: [this.state.model.Id] }), {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ]);
        }
        const pageActions: PageAction[] = [
            {
                type: "button",
                buttonType: "secondary",
                hasPermissions: isAllowed({ permission: Permission.FeedEdit }),
                label: isDirty || !this.state.model || !this.state.model.Id ? "Save and test" : "Test",
                busyLabel: isDirty ? "Saving..." : "Testing...",
                onClick: this.handleTestClick,
                disabled: !this.saveAndTestButtonIsEnabled(),
            },
        ];
        const commonProps: Omit<ExternalFeedStateTypes, "state"> = {
            primaryAction: {
                type: "button",
                buttonType: "secondary",
                hasPermissions: isAllowed({ permission: Permission.FeedEdit }),
                label: "Save",
                busyLabel: "Saving...",
                onClick: () => {
                    this.handleSaveClick();
                },
            },
            secondaryActions: pageActions,
            overflowMenuItems: OverflowMenuConverterVNext.convertAll(overFlowActions),
        };
        if (this.props.create && !this.state.newId) {
            this.props.onStateTransition?.(null, {
                state: "create",
                ...commonProps,
            });
        }
        else {
            const state = this.state.test ? "test" : this.state.newId ? "new" : null;
            if (this.state.deleted) {
                this.props.onStateTransition?.(null, {
                    state: "delete",
                    ...commonProps,
                });
            }
            else if (state !== null) {
                this.props.onStateTransition?.(null, {
                    state: state,
                    feedId: this.state.newId ? this.state.newId : this.state.model!.Id,
                    feed: this.state.model,
                    ...commonProps,
                });
            }
            else if (this.state.model) {
                this.props.onStateTransition?.(null, {
                    state: "edit",
                    feedId: this.state.newId ? this.state.newId : this.state.model!.Id,
                    feed: this.state.model,
                    ...commonProps,
                });
            }
        }
    }
    private getDefaultFeed(): ExternalFeedResource {
        const commonProps = {
            Id: null!,
            FeedUri: null!,
            Name: null!,
            DownloadAttempts: 5,
            DownloadRetryBackoffSeconds: 10,
            Links: null!,
            SpaceId: this.props.spaceId,
        };
        if (this.props.allowedFeedTypes === undefined || this.props.allowedFeedTypes.length === 0 || this.props.allowedFeedTypes.some((f) => f === FeedType.Nuget)) {
            return {
                ...commonProps,
                FeedType: FeedType.Nuget,
                EnhancedMode: false,
            };
        }
        const feedType = this.props.allowedFeedTypes[0];
        switch (feedType) {
            case FeedType.Nuget:
                return {
                    ...commonProps,
                    FeedType: FeedType.Nuget,
                    EnhancedMode: false,
                };
            case FeedType.Docker:
                return {
                    ...commonProps,
                    FeedType: FeedType.Docker,
                    RegistryPath: "",
                    Username: "",
                    Password: { HasValue: false, NewValue: "" },
                };
            case FeedType.Maven:
                return {
                    ...commonProps,
                    FeedType: FeedType.Maven,
                };
            case FeedType.GitHub:
                return {
                    ...commonProps,
                    FeedType: FeedType.GitHub,
                    Username: "",
                    Password: { HasValue: false, NewValue: "" },
                };
            case FeedType.BuiltIn:
                break;
            case FeedType.OctopusProject:
                break;
            case FeedType.Helm:
                return {
                    ...commonProps,
                    FeedType: FeedType.Helm,
                };
            case FeedType.OciRegistry:
                return {
                    ...commonProps,
                    FeedType: FeedType.OciRegistry,
                    Username: "",
                    Password: { HasValue: false, NewValue: "" },
                };
            case FeedType.S3:
                return {
                    ...commonProps,
                    FeedType: FeedType.S3,
                    AccessKey: "",
                    SecretKey: { HasValue: false, NewValue: "" },
                    UseMachineCredentials: false,
                };
            case FeedType.AzureContainerRegistry:
                return {
                    ...commonProps,
                    FeedType: FeedType.AzureContainerRegistry,
                    RegistryPath: "",
                    Username: "",
                    Password: { HasValue: false, NewValue: "" },
                };
            case FeedType.GoogleContainerRegistry:
                return {
                    ...commonProps,
                    FeedType: FeedType.GoogleContainerRegistry,
                    RegistryPath: "",
                    Username: "",
                    Password: { HasValue: false, NewValue: "" },
                };
            case FeedType.AwsElasticContainerRegistry:
                return {
                    ...commonProps,
                    FeedType: feedType,
                    Region: "",
                    AccessKey: "",
                    SecretKey: { HasValue: false, NewValue: "" },
                };
        }
        return {
            ...commonProps,
            FeedType: FeedType.Nuget,
            EnhancedMode: false,
        };
    }
    private resetModel() {
        const feed: ExternalFeedResource = this.getDefaultFeed();
        this.setState({
            model: feed,
            cleanModel: cloneDeep(feed),
        });
    }
    isDirty() {
        return !isEqual(this.state.model, this.state.cleanModel);
    }
    render() {
        return (<>
                {this.state.model && !this.state.newId && !this.state.test && !this.props.create && <AnalyticView resource="External Feed" name="View Feed"/>}
                {this.state.model && (<ExternalFeedForm create={this.props.create} model={this.state.model} busy={this.state.busy} setModelState={(state) => {
                    this.setModelState(state);
                }} doBusyTask={this.doBusyTask.bind(this)} getFieldError={this.getFieldError.bind(this)} cleanModelSlug={this.state.cleanModel?.Slug} expandableContainerKey={this.props.expandableContainerKey} allowedFeedTypes={this.props.allowedFeedTypes}/>)}
            </>);
    }
    private saveAndTestButtonIsEnabled() {
        if (this.state.busy) {
            return false;
        }
        if ((this.state.model && this.state.model.Id) || this.state.newId || this.isDirty()) {
            return true;
        }
        return false;
    }
    private handleTestClick = async () => {
        if (this.isDirty()) {
            await this.handleSaveClick(true);
        }
        else {
            const ev: ActionEvent = {
                action: Action.Test,
                resource: "External Feed",
            };
            this.props.dispatchAction("Test Feed", ev);
            this.setState({ test: true });
        }
    };
    private handleSaveClick = async (redirectToTest: boolean = false) => {
        await this.doBusyTask(async () => {
            const ev: ActionEvent = {
                action: Action.Save,
                resource: "External Feed",
            };
            await this.props.trackAction(redirectToTest ? "Save and Test Feed" : "Save Feed", ev, async () => {
                const isNew = this.state.model!.Id == null;
                const result = (await repository.Feeds.save(this.state.model!)) as ExternalFeedResource;
                this.setState({
                    newId: isNew ? result.Id : null!,
                    test: redirectToTest,
                    model: result,
                    cleanModel: cloneDeep(result),
                });
            });
        });
    };
    private handleDeleteConfirm = async () => {
        const result = await repository.Feeds.del(this.state.model!);
        this.setState((state) => {
            return {
                model: null,
                cleanModel: null, //reset model so that dirty state doesn't prevent navigation
                deleted: true,
            };
        });
        return true;
    };
    static displayName = "ExternalFeedInternal";
}
interface ExternalFeedFormProps {
    create?: boolean;
    model: ExternalFeedResource;
    busy?: Promise<void> | undefined;
    cleanModelSlug?: string;
    doBusyTask: DoBusyTask;
    getFieldError: (fieldName: string) => string;
    setModelState: <K extends keyof ExternalFeedResource>(state: Pick<ExternalFeedResource, K>) => void;
    expandableContainerKey?: string;
    allowedFeedTypes?: FeedType[];
}
export const ExternalFeedForm = ({ create, model, busy, doBusyTask, setModelState, getFieldError, cleanModelSlug, expandableContainerKey, allowedFeedTypes }: ExternalFeedFormProps) => {
    const handleFeedTypeChange = (feedType: FeedType) => {
        let feedUri = model.FeedUri;
        if (!feedUri) {
            const registration = feedTypeRegistry.getRegistration(feedType);
            if (registration && registration.uriDefault) {
                feedUri = registration.uriDefault;
            }
        }
        if (feedType !== FeedType.OctopusProject && feedType !== FeedType.BuiltIn) {
            setModelState({ FeedType: feedType, FeedUri: feedUri });
        }
    };
    const renderFeedSpecificSection = (): JSX.Element => {
        const feedRegistration = feedTypeRegistry.getRegistration(model.FeedType);
        if (feedRegistration == null) {
            return null!;
        }
        return (<React.Fragment>
                {feedRegistration.hasUri && (<ExpandableFormSection errorKey="FeedUri" title="URL" summary={model.FeedUri ? Summary.summary(model.FeedUri) : Summary.placeholder("Please enter a url for your feed")} help="Provide the location of the feed." containerKey={expandableContainerKey}>
                        <Text value={model.FeedUri} onChange={(FeedUri) => setModelState({ FeedUri })} label="Feed url" validate={required("Please enter a feed url")} error={getFieldError("FeedUri")}/>
                        <Note>{feedRegistration.uriNotes}</Note>
                    </ExpandableFormSection>)}
                <feedRegistration.edit doBusyTask={doBusyTask} busy={busy!} feed={model!} onChange={(f: ExternalFeedResource) => setModelState(f)} getFieldError={getFieldError} expandableContainerKey={expandableContainerKey}/>
            </React.Fragment>);
    };
    const feedRegistration = feedTypeRegistry.getRegistration(model.FeedType);
    return (<TransitionAnimation>
            {!create && (<ExpandableFormSection errorKey="ID" title="ID" summary={Summary.summary(model.Id)} help="This is the identity of the feed which can be used in variable bindings. It will never change, even if you rename the feed." containerKey={expandableContainerKey}>
                    <Text value={model.Id} label="ID" disabled={true} onChange={(x) => undefined}/>
                </ExpandableFormSection>)}

            <ExpandableFormSection errorKey="FeedType" title="Feed Type" focusOnExpandAll={create} summary={Summary.summary(feedTypeRegistry.getRegistration(model.FeedType).text)} help="Select the type of the feed." containerKey={expandableContainerKey}>
                <FeedTypeSelect disabled={!create} value={model.FeedType} onChange={handleFeedTypeChange} showOnly={allowedFeedTypes}/>
            </ExpandableFormSection>

            {feedRegistration.callout && feedRegistration.callout}

            <ExpandableFormSection errorKey="Name" title="Name" focusOnExpandAll={!create} summary={model.Name ? Summary.summary(<NameSummaryWithSlug name={model.Name} slug={model.Slug}/>) : Summary.placeholder("Please enter a name for your feed")} help="Enter a name for the external feed." containerKey={expandableContainerKey}>
                <Text value={model.Name} onChange={(Name) => setModelState({ Name })} label="Feed name" validate={required("Please enter a feed name")} error={getFieldError("Name")} autoFocus={true}/>
                <Note>A short, memorable, unique name for this feed. Example: ACME Builds.</Note>

                {!create && (<SlugEditor value={model.Slug ?? ""} name={model.Name} originalSlug={cleanModelSlug ?? ""} onChange={(Slug) => setModelState({ Slug })} label="Feed slug" validate={required("Please enter a feed slug")} error={getFieldError("Slug")}/>)}
            </ExpandableFormSection>
            {renderFeedSpecificSection()}
        </TransitionAnimation>);
};
export function ExternalFeed(props: ExternalFeedProps) {
    return <ExternalFeedInPaperLayout {...props}/>;
}
