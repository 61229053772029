export enum ControlType {
    SingleLineText = "SingleLineText",
    MultiLineText = "MultiLineText",
    Select = "Select",
    Checkbox = "Checkbox",
    Sensitive = "Sensitive",
    StepName = "StepName",
    AzureAccount = "AzureAccount",
    Certificate = "Certificate",
    WorkerPool = "WorkerPool",
    AmazonWebServicesAccount = "AmazonWebServicesAccount",
    UsernamePasswordAccount = "UsernamePasswordAccount",
    GoogleCloudAccount = "GoogleCloudAccount",
    GenericOidcAccount = "GenericOidcAccount",
    Package = "Package",
    Custom = "Custom"
}
export function isResourceControlType(controlType: ControlType | undefined): boolean {
    return controlType
        ? [
            ControlType.AzureAccount,
            ControlType.Certificate,
            ControlType.WorkerPool,
            ControlType.AmazonWebServicesAccount,
            ControlType.UsernamePasswordAccount,
            ControlType.GoogleCloudAccount,
            ControlType.GenericOidcAccount,
            ControlType.Package,
        ].includes(controlType)
        : false;
}
