import { css, cx } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
interface ChipIconWrapperProps {
    backgroundColor?: string;
    children?: React.ReactNode;
}
export function ChipIconWrapper({ children, backgroundColor }: ChipIconWrapperProps): React.ReactElement | null {
    const backgroundColorValue = backgroundColor || "var(--avatarBackground)";
    return <div className={cx(chipIconWrapperStyles, chipIconWrapperBackground(backgroundColorValue))}>{children}</div>;
}
const chipIconWrapperStyles = css({
    margin: `${space[4]} 0`,
    width: "24px",
    height: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
});
const chipIconWrapperBackground = (backgroundColor: string) => css({
    backgroundColor: backgroundColor,
});
