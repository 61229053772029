/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from "@emotion/css";
import { MagnifyingGlassIcon } from "@octopusdeploy/design-system-icons";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import cn from "classnames";
import MobileDetect from "mobile-detect";
import * as React from "react";
import DebounceValue from "~/components/DebounceValue/DebounceValue";
import { withTheme } from "~/components/Theme";
import Text from "~/primitiveComponents/form/Text/Text";
import type { TextInput } from "~/primitiveComponents/form/Text/Text";
import styles from "./style.module.less";
interface FilterSearchBoxProps {
    placeholder?: string;
    onChange: (newValue: string) => void;
    onKeyDown?: (keyEvent: any) => void;
    value?: string;
    autoFocus?: boolean;
    containerClassName?: string;
    inputClassName?: string;
    iconColor?: string;
    fullWidth?: boolean;
    error?: string;
}
class FilterSearchBoxInternal extends React.Component<FilterSearchBoxProps> {
    ref: TextInput | null = null;
    focus() {
        this.ref?.focus();
    }
    render() {
        // Disable autoFocus filtering for mobile (Android has issues and is annoying users).
        const md = new MobileDetect(window.navigator.userAgent);
        const autoFocus = md.isPhoneSized() ? false : this.props.autoFocus;
        const searchIconColor = this.props.iconColor ? this.props.iconColor : themeTokens.color.text.secondary;
        return withTheme((theme) => (<div className={cn(this.props.containerClassName, styles.filterField, this.props.fullWidth ? styles.filterFieldFullWidth : null)}>
                <Text textInputRef={this.setRef} type="search" placeholder={this.props.placeholder} value={this.props.value!} onChange={(value) => this.props.onChange(value)} autoFocus={autoFocus} onKeyDown={this.props.onKeyDown} className={cn(this.props.inputClassName, this.props.fullWidth ? styles.filterTextBoxFullWidth : styles.filterTextBoxStandardWidth)} error={this.props.error ?? ""} accessibleName={this.props.placeholder}/>
                <span className={SearchIcon} aria-hidden="true">
                    <MagnifyingGlassIcon size={20} color={searchIconColor}/>
                </span>
            </div>));
    }
    private setRef = (el: TextInput | null) => {
        this.ref = el;
    };
    static displayName = "FilterSearchBoxInternal";
}
const SearchIcon = css({
    position: "absolute",
    right: "0",
    top: "1.25rem",
});
export default DebounceValue<FilterSearchBoxProps, string>(FilterSearchBoxInternal);
