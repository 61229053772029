import { css } from "@emotion/css";
import { KubernetesIcon, RocketIcon } from "@octopusdeploy/design-system-icons";
import { colorScales, fontSize, fontWeight, letterSpacing, lineHeight, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { nameForDocument, useAnalyticExternalLinkDispatch } from "~/analytics/Analytics";
import { StepCard } from "~/areas/projects/components/Process/ActionTemplateSelector/StepCard";
import { OpensInNewTabIcon } from "../Navigation/InternalLink/InternalLink";
export const ProjectsGettingStartedGuides = () => {
    const dispatchLink = useAnalyticExternalLinkDispatch();
    const dispatchAnalyticsEventAndOpenLink = (linkLabel: string, href: string, name: string) => {
        dispatchLink(linkLabel, href, name);
        window.open(href);
    };
    return (<div>
            <div className={bottomSectionStyles.subtitleStyles}>Getting Started Guides</div>
            <div className={bottomSectionStyles.stepCardsContainer}>
                <StepCard heading="First Kubernetes Deployment" description="Deploy sample YAML files to your Kubernetes cluster." logo={<KubernetesIcon size={24} color={colorScales.blue["500"]}/>} primaryButtonLabel="Tutorial" primaryButtonIcon={<OpensInNewTabIcon />} onPrimaryButtonClick={() => dispatchAnalyticsEventAndOpenLink("First Kubernetes Deployment", "https://oc.to/FirstKubernetesGuide", nameForDocument())}/>
                <StepCard heading="First Deployment" description="Deploy a sample hello world package to one of your servers." logo={<RocketIcon size={24} color={colorScales.blue["500"]}/>} primaryButtonLabel="Tutorial" primaryButtonIcon={<OpensInNewTabIcon />} onPrimaryButtonClick={() => dispatchAnalyticsEventAndOpenLink("First Deployment", "https://oc.to/FirstDeploymentGuide", nameForDocument())}/>
            </div>
        </div>);
};
// We set the minimum step card width to achieve the following:
//   - 2 columns when the container is 531px wide or wider
//   - 1 column when the container is less than 531px wide
const smallContainerMaxWidth = 530;
const sidePadding = 32;
const gap = 24;
const stepCardMinWidth = `${(smallContainerMaxWidth - 2 * sidePadding - gap) / 2}px`;
const bottomSectionStyles = {
    subtitleStyles: css({
        marginBottom: space["24"],
        color: themeTokens.color.text.secondary,
        fontSize: fontSize.base,
        fontWeight: fontWeight["700"],
        lineHeight: lineHeight.small,
        letterSpacing: letterSpacing.wider,
    }),
    stepCardsContainer: css({
        display: "grid",
        gridTemplateColumns: `repeat(2, minmax(${stepCardMinWidth}, 1fr))`,
        gap: space[24],
        [`@container Level1EmptyStateLayout (width < ${smallContainerMaxWidth + 1}px)`]: {
            gridTemplateColumns: "1fr",
        },
    }),
};
