/* eslint-disable @typescript-eslint/init-declarations,@typescript-eslint/consistent-type-assertions */
import type { AzureWebSite } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository } from "~/clientInstance";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip";
import InputWithActions from "~/components/InputWithActions";
import { Select } from "~/components/form";
import Note from "~/primitiveComponents/form/Note/Note";
import ParseHelper from "~/utils/ParseHelper/ParseHelper";
interface AzureWebAppAndResourceGroupSelectorProps {
    webAppName: string | undefined;
    accountId: string;
    webAppNameError: string;
    doBusyTask: DoBusyTask;
    onResourceGroupChanged(value: string | undefined): void;
    onWebAppNameChanged(value: string | undefined): void;
}
interface AzureWebAppAndResourceGroupSelectorState {
    reset: {
        name: string | undefined;
    };
    sites: AzureWebSite[];
    siteItems: Array<{
        value: string;
        text: string;
    }>;
    selectedSiteIndex: string | undefined;
    busy: boolean; //TODO: move busy back out into props and use a HOC/Render prop component to manage this state
}
const toggleBusy = (value?: boolean) => (prev: AzureWebAppAndResourceGroupSelectorState, props: AzureWebAppAndResourceGroupSelectorProps) => ({ ...prev, busy: value ? value : !prev.busy });
class AzureWebAppAndResourceGroupSelector extends BaseComponent<AzureWebAppAndResourceGroupSelectorProps, AzureWebAppAndResourceGroupSelectorState> {
    constructor(props: AzureWebAppAndResourceGroupSelectorProps) {
        super(props);
        this.state = {
            reset: {
                name: undefined,
            },
            sites: [],
            siteItems: [],
            selectedSiteIndex: undefined,
            busy: false,
        };
    }
    async componentDidMount() {
        await this.getWebSites(this.props.accountId);
    }
    async componentDidUpdate(prevProps: AzureWebAppAndResourceGroupSelectorProps) {
        if (this.props.accountId !== prevProps.accountId) {
            await this.getWebSites(this.props.accountId);
        }
    }
    render() {
        return (<div>
                <InputWithActions input={<Select value={this.state.selectedSiteIndex} onChange={this.handleSelectedSiteChanged} items={this.state.siteItems} label="Web app"/>} busy={this.state.busy} actions={<IconButtonWithTooltip disabled={this.state.busy} onClick={() => this.getWebSites(this.props.accountId)} toolTipContent={"Refresh"} icon={"ArrowRefreshIcon"} accessibleName={"Refresh"}/>}/>
                <Note>The name of your Azure Web App.</Note>
            </div>);
    }
    private handleSelectedSiteChanged = (value: string | undefined) => {
        const index = ParseHelper.safeParseInt(value as string);
        const selectedSite = index !== null ? this.state.sites[index] : null;
        this.props.onResourceGroupChanged(selectedSite ? selectedSite.ResourceGroup : undefined);
        this.props.onWebAppNameChanged(selectedSite ? selectedSite.Name : undefined);
        this.setState({ selectedSiteIndex: index !== null ? index.toString() : undefined });
    };
    private async getWebSites(accountId: string) {
        if (!accountId) {
            return;
        }
        this.setState(toggleBusy(true));
        try {
            await this.props.doBusyTask(async () => {
                if (!accountId) {
                    this.setState({
                        sites: [],
                        siteItems: [],
                        selectedSiteIndex: undefined,
                    });
                    return;
                }
                const account = await repository.Accounts.get(accountId);
                const sites = await repository.Accounts.getWebSites(account);
                let selectedSiteIndex: string | undefined;
                if (this.props.webAppName) {
                    const selectedSite = sites.find((s) => s.Name === this.props.webAppName);
                    if (selectedSite) {
                        const index = sites.indexOf(selectedSite);
                        selectedSiteIndex = index < 0 ? undefined : index.toString();
                    }
                }
                const siteItems = sites.map((site, index) => {
                    const details = site.ResourceGroup ? site.ResourceGroup + " " + site.Region : site.Region;
                    return {
                        value: index.toString(),
                        text: site.Name + " " + details,
                    };
                });
                this.setState({
                    sites,
                    siteItems,
                    selectedSiteIndex,
                });
            });
        }
        finally {
            this.setState(toggleBusy(false));
        }
    }
    static displayName = "AzureWebAppAndResourceGroupSelector";
}
export default AzureWebAppAndResourceGroupSelector;
