import { css, keyframes } from "@emotion/css";
import { fontSize, lineHeight, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import cn from "classnames";
import React, { useEffect, useState } from "react";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip";
import { useDidUpdateEffect } from "~/hooks/useDidUpdateEffect";
export interface HelpPanelImage {
    src: string;
    altText: string;
    darkThemeSrc?: string;
}
export type HelpPanelSize = "third" | "half";
interface HelpPanelProps {
    fullScreen?: boolean;
    image?: HelpPanelImage;
    content?: React.ReactNode;
    size: HelpPanelSize;
    showToggle: boolean;
    theme: "light" | "dark";
    open: boolean;
    onClose: () => void;
}
export function HelpPanel({ fullScreen, image, content, size, showToggle, theme, open, onClose }: HelpPanelProps) {
    const [helpPanelClasses, setHelpPanelClasses] = useState([styles.helpPanel]);
    useEffect(() => {
        setHelpPanelClasses(open ? [] : [styles.displayNone]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useDidUpdateEffect(() => {
        setHelpPanelClasses(open ? [styles.animateOpen] : [styles.animateClose]);
        const timeoutId = setTimeout(() => setHelpPanelClasses(open ? [] : [styles.displayNone]), 350);
        return () => clearTimeout(timeoutId);
    }, [open]);
    return (<div className={cn(...helpPanelClasses, styles.helpPanel, styles.background, styles.size(size), fullScreen ? styles.helpPanelFullScreen : "", styles.helpPanelInvisible(fullScreen))}>
            {showToggle && (<span className={styles.toggleButton}>
                    <IconButtonWithTooltip toolTipContent={"Collapse help"} onClick={() => onClose()} icon="ChevronsLeftIcon" accessibleName={"Collapse help"}/>
                </span>)}
            {fullScreen && (<span className={styles.cancelButton}>
                    <IconButtonWithTooltip toolTipContent={"Close dialog"} onClick={() => onClose()} accessibleName={"Cancel"} icon={"XmarkIcon"}/>
                </span>)}
            <div className={styles.contentWrapper}>
                {image && <img src={image.src} className={styles.image} alt={image.altText}/>}
                {content && <div className={cn({ [styles.content]: true, [styles.contentFullScreen]: fullScreen })}>{content}</div>}
            </div>
        </div>);
}
interface HelpPanelToggleProps {
    showToggle: boolean;
    open: boolean;
    onClick: () => void;
}
export function HelpPanelToggle({ showToggle, open, onClick }: HelpPanelToggleProps) {
    if (open || !showToggle)
        return <></>;
    return (<span className={styles.toggleButton}>
            <IconButtonWithTooltip toolTipContent={"Expand help"} icon={"QuestionCircleIcon"} accessibleName={"OpenHelp"} onClick={onClick}/>
        </span>);
}
const animations = {
    slideIn: keyframes({
        "0%": {
            marginLeft: "-40%",
            transform: "translateX(-60%)",
            boxShadow: "none",
        },
        "100%": {
            marginLeft: 0,
            transform: "translateX(0%)",
            width: "100%",
            boxShadow: themeTokens.shadow.large,
        },
    }),
    slideOut: keyframes({
        "0%": {
            marginLeft: 0,
            transform: "translateX(0%)",
            boxShadow: themeTokens.shadow.large,
        },
        "100%": {
            marginLeft: "-40%",
            transform: "translateX(-60%)",
            boxShadow: "none",
        },
    }),
};
const styles = {
    helpPanel: css({
        flex: "auto",
        position: "relative",
        overflow: "hidden",
        background: "#122536",
        borderRadius: `0 ${space[4]} ${space[4]} 0`,
        maxWidth: "400px",
        height: "100%",
        boxShadow: themeTokens.shadow.large,
    }),
    helpPanelFullScreen: css({
        backgroundColor: themeTokens.color.background.secondary.default,
        width: "100%",
        maxWidth: "unset",
        boxShadow: "none",
    }),
    helpPanelInvisible: (fullScreen: boolean = false) => {
        if (fullScreen) {
            return css({
                "@media (max-width: 800px)": {
                    display: "none",
                },
            });
        }
        return css({
            "@media (max-width: 1000px)": {
                display: "none",
            },
        });
    },
    animateClose: css({
        animation: `${animations.slideOut} 0.35s forwards`,
    }),
    animateOpen: css({
        animation: `${animations.slideIn} 0.35s forwards`,
    }),
    displayNone: css({
        display: "none",
    }),
    toggleButton: css({
        position: "absolute",
        right: "20px",
        top: "20px",
        zIndex: 10,
        "@media (max-width: 1000px)": {
            visibility: "hidden",
        },
    }),
    cancelButton: css({
        position: "absolute",
        right: space[16],
        top: space[16],
        zIndex: 10,
        color: "red",
        height: "20px",
        width: "20px",
    }),
    content: css({
        display: "block",
        height: "100%",
        width: "80.5%",
        margin: "auto",
        padding: `${space[24]} 0`,
        color: "white",
        lineHeight: lineHeight.small,
        fontWeight: 400,
        fontSize: fontSize.base,
    }),
    contentFullScreen: css({
        width: "100%",
        padding: 0,
        color: themeTokens.color.text.primary,
    }),
    image: css({
        display: "block",
        width: "80.5%",
        margin: "80px auto 20px",
    }),
    imageFullScreen: css({
        width: "100%",
        margin: " 0 0 1.5rem 0",
    }),
    size: (size: HelpPanelSize) => {
        switch (size) {
            case "half":
                return css({ "&&": { width: "50%", maxWidth: "800px" } });
            case "third":
                return css({ "&&": { width: "40%" } });
        }
    },
    contentWrapper: css({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
    }),
    background: css({
        backgroundColor: themeTokens.color.carousel.background.primary,
    }),
};
