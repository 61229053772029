import { css } from "@emotion/css";
import { Tooltip } from "@octopusdeploy/design-system-components";
import { UnavailableIcon } from "@octopusdeploy/design-system-icons";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { SpaceResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { SpaceChip } from "~/components/Chips/index";
import Logo from "~/components/Logo/Logo";
import InternalLink from "~/components/Navigation/InternalLink/index";
type TenantPageLink = "Overview" | "Variables";
interface TenantNameCellWithLinkProps {
    spaceId: string;
    tenantId: string;
    tenantName: string;
    tenantLogoLink: string;
    linkToPage?: TenantPageLink;
    isDisabled: boolean;
}
export function TenantNameCellWithLink({ spaceId, tenantId, tenantName, isDisabled, tenantLogoLink, linkToPage = "Overview" }: TenantNameCellWithLinkProps) {
    const dispatchAction = useAnalyticActionDispatch();
    const linkTarget = linkToPage === "Variables" ? links.tenantVariablesPage.generateUrl({ tenantId, spaceId }) : links.tenantOverviewPage.generateUrl({ spaceId, tenantId });
    function handleClick() {
        const resource = linkToPage === "Variables" ? "Tenant Variables" : "Tenant";
        dispatchAction(`View ${resource}`, { resource: resource, action: Action.View });
    }
    return (<div className={isDisabled ? disabledStyle : undefined}>
            <InternalLink to={linkTarget} onClick={handleClick}>
                <div className={cellStyles}>
                    <div className={logoWrapperStyles}>
                        <div>
                            <Logo url={tenantLogoLink} size={"2.2rem"}/>
                        </div>
                        {isDisabled && (<div className={unavailableIconContainerStyles}>
                                <Tooltip content="Disabled" display="block">
                                    <UnavailableIcon size={24}/>
                                </Tooltip>
                            </div>)}
                    </div>
                    <div>{tenantName}</div>
                </div>
            </InternalLink>
        </div>);
}
interface TenantNameCellProps {
    tenantName: string;
    tenantLogoLink: string;
    space?: SpaceResource;
    isDisabled?: boolean;
}
export function TenantNameCell({ tenantName, tenantLogoLink, space, isDisabled }: TenantNameCellProps) {
    return (<div className={`${cellStyles} ${isDisabled ? disabledStyle : ""}`}>
            <div>
                <Logo url={tenantLogoLink} size={"2.2rem"}/>
            </div>
            <div>
                {tenantName} {space !== undefined ? <SpaceChip space={space}/> : null}
            </div>
        </div>);
}
const cellStyles = css({
    display: "flex",
    alignItems: "center",
    gap: space[8],
});
const disabledStyle = css({
    opacity: "0.4",
});
const logoWrapperStyles = css({
    position: "relative",
    alignSelf: "center",
});
const unavailableIconContainerStyles = css({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: themeTokens.color.icon.primary,
});
