import { css } from "@emotion/css";
import { Divider, IconButton, resetStyles } from "@octopusdeploy/design-system-components";
import { space, text } from "@octopusdeploy/design-system-tokens";
import { AnalyticLinkLocationProvider } from "@octopusdeploy/portal-analytics";
import { usePage } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import configurationSelectors from "~/areas/configuration/reducers/selectors";
import drawerActions from "~/components/ContextualHelpLayout/reducers/actions";
import * as drawerSelectors from "~/components/ContextualHelpLayout/reducers/selectors";
import { HelpSidebarHandle } from "./HelpSidebarHandle";
import { HelpSidebarResources } from "./HelpSidebarResources";
import { PageHelpOverviewView, PageHelpTopicsView } from "./PageHelpRegistry/PageHelpSelector";
export function useIsContextualHelpPanelExpanded() {
    const isDrawerOpen = useSelector(drawerSelectors.isDrawerOpen);
    const isHelpSidebarEnabled = useSelector(configurationSelectors.createFeatureEnabledSelector((x) => x.isHelpSidebarEnabled));
    return isDrawerOpen && isHelpSidebarEnabled;
}
export function ContextualHelpPanel() {
    return (<>
            <div className={contextualHelpPanelStyles.helpHandle}>
                <HelpSidebarHandle />
            </div>
            <ContextualHelpSidebarContent />
        </>);
}
const contextualHelpPanelStyles = {
    helpHandle: css({
        position: "absolute",
        left: 0,
        height: "100%",
        transform: "translateX(-50%)",
    }),
};
export function ContextualHelpSidebarContent() {
    const page = usePage();
    const dispatch = useDispatch();
    const toggleDrawer: () => void = React.useCallback(() => dispatch(drawerActions.toggleDrawer()), [dispatch]);
    return (<div className={sidebarContentStyles.root}>
            <div className={sidebarContentStyles.header}>
                <h4 className={sidebarContentStyles.heading}>Help Sidebar</h4>
                <IconButton icon={"XmarkIcon"} onClick={() => toggleDrawer()} accessibleName="Close help sidebar"/>
            </div>
            <Divider />
            <div className={sidebarContentStyles.content}>
                <AnalyticLinkLocationProvider location="Help sidebar overview">
                    <PageHelpOverviewView pageId={page?.Id}/>
                </AnalyticLinkLocationProvider>
                <AnalyticLinkLocationProvider location="Help sidebar related docs">
                    <PageHelpTopicsView pageId={page?.Id}/>
                </AnalyticLinkLocationProvider>
            </div>
            <Divider />
            <AnalyticLinkLocationProvider location="Help sidebar footer">
                <HelpSidebarResources />
            </AnalyticLinkLocationProvider>
        </div>);
}
const defaultSidebarWidth = 340;
const sidebarContentStyles = {
    root: css({
        display: "flex",
        flexDirection: "column",
        width: `var(--help-sidebar-width, ${defaultSidebarWidth}px)`,
    }),
    header: css({
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: `${space[16]} ${space[24]}`,
    }),
    heading: css({
        ...resetStyles.heading,
        font: text.heading.small,
    }),
    content: css({
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        flexGrow: 1,
        padding: `${space[16]} ${space[24]}`,
    }),
};
