/* Generated file. Do not modify. Please check more details in build/generate.ts */
import * as React from "react";
import type { IconProps } from "../IconTypes";
import { IconWrapper } from "../IconWrapper";
export function RepeatOffIcon(props: IconProps) {
    return (<IconWrapper {...props}>
            {<>
                    <path d="M9.707 10.068 2.415 4.354c-.624-.562-1.561-.437-2.06.25-.563.624-.438 1.561.249 2.061l36.98 28.981c.625.562 1.562.437 2.062-.25.562-.624.437-1.561-.25-2.061l-6.726-5.271a11.4 11.4 0 0 0 3.267-7.439l.063-.5c0-.875-.625-1.5-1.437-1.562-.875-.063-1.563.562-1.563 1.375l-.062.5a8.43 8.43 0 0 1-2.62 5.783l-18-14.106a8.5 8.5 0 0 1 3.185-.615h7.499v2.313c0 1.187 1 2.187 2.187 2.187a2.24 2.24 0 0 0 1.5-.562l4.812-4.313c.312-.25.5-.687.5-1.125 0-.375-.188-.812-.5-1.062l-4.812-4.313C26.25 4.25 25.75 4 25.189 4c-1.25 0-2.187 1-2.187 2.25V8.5h-7.5c-2.107 0-4.09.57-5.795 1.568"/>
                    <path d="M5.38 21.5c-.813 0-1.438-.75-1.376-1.562v-.5a11.5 11.5 0 0 1 1.53-5.18l2.402 1.884a8.7 8.7 0 0 0-.932 3.483v.5c-.063.813-.75 1.438-1.625 1.375"/>
                    <path d="M23.786 28.563h-6.783V26.25c0-1.187-1-2.187-2.25-2.187q-.844 0-1.5.562l-4.812 4.313c-.312.25-.437.687-.437 1.125 0 .375.125.812.437 1.062l4.812 4.313q.656.562 1.5.562c1.25 0 2.25-1 2.25-2.187V31.5h7.436c.927 0 1.829-.109 2.694-.314z"/>
                </>}
        </IconWrapper>);
}
