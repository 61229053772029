/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { useCurrentScrollArea } from "@octopusdeploy/design-system-components";
import { range } from "lodash";
import * as React from "react";
import { List as VirtualList, AutoSizer, WindowScroller } from "react-virtualized";
import type { OctopusTheme } from "~/components/Theme";
import { withTheme } from "~/components/Theme";
import AlignedScrollTableRow, { adjustColumnWidths } from "~/primitiveComponents/dataDisplay/ScrollTable/AlignedScrollTableRow/AlignedScrollTableRow";
import { BorderCss } from "~/utils/BorderCss/BorderCss";
import { constrainResizeHandleDragDistance, isResizeHandleDragData } from "./DraggableResizeColumnHandle/DraggableResizeColumnHandle";
import styles from "./style.module.less";
export interface CellAlignmentArgs {
    customColumnWidthsInPercent?: number[];
    showResizeHandles?: boolean;
}
export type CellAligner = (cells: JSX.Element[], optionalArgs?: CellAlignmentArgs) => JSX.Element;
export interface RenderArgs {
    columnWidthsInPercent: ReadonlyArray<number>;
    borderStyle: BorderCss;
    cellAligner: CellAligner;
}
export interface RowRenderArgs extends RenderArgs {
    index: number;
    isVisible: boolean;
}
interface SharedScrollTableProps {
    relativeColumnWidths: ReadonlyArray<number>;
    minimumColumnWidthsInPx: ReadonlyArray<number>;
    rowCount: number;
    overscanRowCount: number;
    shouldVirtualize: boolean;
    bodyContentWrapper?: (children: React.ReactNode) => JSX.Element;
    onColumnWidthsChanged(relativeColumnWidths: ReadonlyArray<number>): void;
    rowHeight(index: number): number;
    headers(renderArgs: RenderArgs): React.ReactNode[];
    rowRenderer(rowRenderArgs: RowRenderArgs): React.ReactNode;
}
interface ScrollTableInternalProps extends SharedScrollTableProps {
    scrollContainer: Element | undefined;
}
interface ScrollTableState {
}
let scrollTableCount = 0;
function createBorderStyle(theme: OctopusTheme) {
    return new BorderCss(0.0625, "solid", theme.dividerLight);
}
interface ScrollTableContentWrapperProps {
    wrappingComponent?: (children: React.ReactNode) => JSX.Element;
    children: React.ReactNode;
}
function ScrollTableContentWrapper(props: ScrollTableContentWrapperProps): JSX.Element {
    if (props.wrappingComponent) {
        return props.wrappingComponent(props.children);
    }
    return <>{props.children}</>;
}
class ScrollTableInternal extends React.Component<ScrollTableInternalProps, ScrollTableState> {
    private readonly scrollTableId: number;
    private rowCellAligner: CellAligner = undefined!;
    private windowScroller: WindowScroller | null = null;
    private timeoutId?: number;
    private virtualList: VirtualList | null = null;
    constructor(props: ScrollTableInternalProps) {
        super(props);
        this.state = {};
        this.scrollTableId = scrollTableCount++;
        this.setRowCellAligner();
    }
    get relativeColumnWidthsInPercent() {
        return convertRelativeSizesToPercentages(this.props.relativeColumnWidths);
    }
    componentDidMount() {
        this.refreshWindowPosition();
    }
    componentWillUnmount() {
        if (this.timeoutId) {
            window.clearTimeout(this.timeoutId);
        }
    }
    render() {
        //React virtualized does the wrong thing when given the document or body elements as the
        //scrolling container. The default being undefined happens to assume these elements anyway,
        //forcing react virtualized to do the right thing.
        const scrollContainer = this.props.scrollContainer === document.documentElement || this.props.scrollContainer === document.body ? undefined : this.props.scrollContainer;
        return withTheme((theme) => {
            const borderStyle = createBorderStyle(theme);
            const headerRenderArgs: RenderArgs = {
                columnWidthsInPercent: this.relativeColumnWidthsInPercent,
                borderStyle,
                cellAligner: (cells, optionalArgs) => cellAlignerInner(cells, optionalArgs, this),
            };
            const rowRenderer: (args: any) => JSX.Element = (args: any) => {
                return this.renderRow(args, borderStyle);
            };
            return (<DndContext modifiers={[constrainResizeHandleDragDistance]} onDragEnd={this.handleColumnResizeEnd}>
                    <div id={`scrollTable-${this.scrollTableId}`} className={styles.table}>
                        {this.props.headers(headerRenderArgs).map((h, index) => {
                    return <div key={index}>{h}</div>;
                })}

                        <ScrollTableContentWrapper wrappingComponent={this.props.bodyContentWrapper}>
                            <div className={styles.tableBody}>
                                {this.props.shouldVirtualize && (<WindowScroller scrollElement={scrollContainer} ref={(windowScroller) => (this.windowScroller = windowScroller)}>
                                        {({ height, isScrolling, onChildScroll, scrollTop }) => (<AutoSizer disableHeight={true}>
                                                {({ width }) => (<VirtualList autoHeight={true} tabIndex={-1} height={height} scrollTop={scrollTop} onScroll={onChildScroll} isScrolling={isScrolling} rowCount={this.props.rowCount} rowHeight={({ index }: {
                            index: number;
                        }) => this.props.rowHeight(index)} width={width} className={styles.virtualList} overscanRowCount={this.props.overscanRowCount} rowRenderer={rowRenderer} ref={(virtualList) => (this.virtualList = virtualList)}/>)}
                                            </AutoSizer>)}
                                    </WindowScroller>)}
                                {!this.props.shouldVirtualize &&
                    range(0, this.props.rowCount).map((ind) => {
                        return rowRenderer({
                            key: ind,
                            index: ind,
                            isVisible: true,
                            style: {},
                        });
                    })}
                            </div>
                        </ScrollTableContentWrapper>
                    </div>
                </DndContext>);
        });
    }
    handleColumnResizeEnd = (event: DragEndEvent) => {
        const dragItemData = event.active.data.current;
        if (!isResizeHandleDragData(dragItemData))
            return;
        const initialColumnMeasurements = dragItemData.getColumnMeasurements();
        const columnIndex = dragItemData.columnIndex;
        const deltaX = event.delta.x;
        const newRelativeColumnWidths = adjustColumnWidths(columnIndex, deltaX, initialColumnMeasurements, this.relativeColumnWidthsInPercent);
        this.onColumnWidthsChanged(newRelativeColumnWidths);
    };
    onColumnWidthsChanged = (newColumnWidths: ReadonlyArray<number>) => {
        // Run it through convertRelativeSizesToPercentages again to ensure that everything is rounded appropriately
        // and adds up to exactly 100%
        this.props.onColumnWidthsChanged(convertRelativeSizesToPercentages(newColumnWidths));
        // For performance reasons, we use `shouldComponentUpdate` in the variable editor.
        // The cell aligner is one of the properties that we watch to determine whether to re-render a variable row
        // By changing the cell aligner function instance, we can trigger the rows to re-render.
        this.setRowCellAligner();
        if (this.virtualList) {
            // Need to tell the virtual list that something has changed and it must re-render
            this.virtualList.forceUpdateGrid();
        }
    };
    private renderRow({ key, index, isVisible, style }: any, borderStyle: BorderCss) {
        const cells = this.props.rowRenderer({
            columnWidthsInPercent: this.relativeColumnWidthsInPercent,
            index,
            isVisible,
            cellAligner: this.rowCellAligner,
            borderStyle,
        });
        return (<div style={style} key={key}>
                {cells}
            </div>);
    }
    private setRowCellAligner() {
        this.rowCellAligner = (cells: JSX.Element[], optionalArgs?: CellAlignmentArgs) => cellAlignerInner(cells, optionalArgs, this);
    }
    private refreshWindowPosition() {
        this.timeoutId = window.setTimeout(() => {
            if (this.windowScroller) {
                // https://github.com/bvaughn/react-virtualized/blob/master/docs/WindowScroller.md#updateposition
                // This needs to be called if anything above the table in the dom moves, so that the table
                // can re-evaluate its position w.r.t. the window. It is bad to have to be so coupled to whatever
                // is displayed above the table, so instead lets just re-check the position every 500ms
                // so we don't have to worry about it
                this.windowScroller.updatePosition();
            }
            this.refreshWindowPosition();
        }, 500);
    }
    static displayName = "ScrollTableInternal";
}
function cellAlignerInner(cells: JSX.Element[], optionalArgs: CellAlignmentArgs | undefined, scrollTable: ScrollTableInternal): JSX.Element {
    const emptyCellAlignmentArgs: CellAlignmentArgs = {};
    const { customColumnWidthsInPercent, showResizeHandles } = optionalArgs || emptyCellAlignmentArgs;
    return (<AlignedScrollTableRow cells={cells} showResizeHandles={showResizeHandles!} relativeColumnWidthsInPercent={customColumnWidthsInPercent || scrollTable.relativeColumnWidthsInPercent} onColumnWidthsChanged={scrollTable.onColumnWidthsChanged} minimumColumnWidthsInPx={scrollTable.props.minimumColumnWidthsInPx}/>);
}
function convertRelativeSizesToPercentages(relativeColumnSizes: ReadonlyArray<number>) {
    const totalSize = sum(relativeColumnSizes);
    const allColumnsExceptLast = relativeColumnSizes.slice(0, relativeColumnSizes.length - 1);
    const columnSizePercentageExceptLast = allColumnsExceptLast.map((relativeColumnSize) => {
        return (relativeColumnSize / totalSize) * 100;
    });
    const lastColumnSize = 100 - sum(columnSizePercentageExceptLast);
    return [...columnSizePercentageExceptLast, lastColumnSize];
    function sum(numbers: ReadonlyArray<number>) {
        return numbers.reduce((p, c) => p + c, 0);
    }
}
interface ScrollTableProps extends SharedScrollTableProps {
}
function ScrollTable(props: ScrollTableProps) {
    const currentScrollArea = useCurrentScrollArea();
    return <ScrollTableInternal {...props} scrollContainer={currentScrollArea}/>;
}
export default ScrollTable;
