/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from "@emotion/css";
import type { BreadcrumbItem, PageAction, PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { SpaceResource, TaskResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { TaskStatusIcon } from "~/areas/projects/components/TaskStatusIcon/TaskStatusIcon";
import { client, repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import { PageContent } from "~/components/PageContent/PageContent";
import styles from "./style.module.less";
interface RawLogPageProps {
    taskId: string;
}
interface RawLogPageState extends DataBaseComponentState {
    task?: TaskResource<any>;
    taskSpace?: SpaceResource;
    raw?: string;
}
export class RawLogPage extends DataBaseComponent<RawLogPageProps, RawLogPageState> {
    constructor(props: RawLogPageProps) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        return this.refresh();
    }
    refresh() {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.get(this.props.taskId, { spaces: ["all"] });
            const taskSpaceAsync = task.SpaceId ? repository.Spaces.get(task.SpaceId) : undefined;
            const rawAsync = repository.Tasks.getRaw(task);
            this.setState({
                task,
                taskSpace: await taskSpaceAsync,
                raw: await rawAsync,
            });
        });
    }
    render() {
        const task = this.state.task!;
        const pageActions: PageAction[] = task
            ? [
                {
                    type: "navigate",
                    buttonType: "secondary",
                    path: task.Links["Raw"],
                    label: "Download",
                    external: true,
                },
            ]
            : [];
        const refreshPageAction: PrimaryPageAction | undefined = task
            ? {
                type: "button",
                label: "Refresh",
                disabled: this.state.busy,
                onClick: () => this.refresh(),
            }
            : undefined;
        const breadcrumbsItems: BreadcrumbItem[] = [{ label: "Tasks", pageUrl: links.tasksPage.generateUrl() }];
        if (task) {
            breadcrumbsItems.push({ label: task.Description, pageUrl: links.taskPage.generateUrl({ taskId: task.Id }) });
        }
        return (<main>
                <div className={containerStyles}>
                    <PageContent header={{
                title: "Raw Log",
                logo: task ? <TaskStatusIcon item={task}/> : undefined,
                breadcrumbs: breadcrumbsItems,
                pageActions: pageActions,
                primaryAction: refreshPageAction,
            }} busy={this.state.busy} errors={this.errors} fullWidth={true} callout={this.state.taskSpace && this.state.taskSpace.Id != client.spaceId
                ? {
                    type: "information",
                    content: (<>
                                              You are viewing a task from Space: <strong>{this.state.taskSpace.Name}</strong>.
                                              <InternalLink to={links.spaceRootRedirect.generateUrl({ spaceId: this.state.taskSpace.Id })}>Change to this Space.</InternalLink>
                                          </>),
                }
                : undefined}>
                        {task && <div className={styles.rawLogContent}>{this.state.raw}</div>}
                    </PageContent>
                </div>
            </main>);
    }
    static displayName = "RawLogPage";
}
const containerStyles = css({
    margin: space[32],
});
