import { ActionExecutionLocation, type ActionProperties } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { getProcessTemplateCommitFromActionProperties, getProcessTemplateSlugFromActionProperties, getProcessTemplateVersionFromActionProperties } from "~/areas/projects/components/Process/Blueprints/processTemplateId";
import { isMatchingProcessTemplate } from "~/areas/projects/components/Process/Common/CommonProcessHelpers";
import { useProcessBlueprintsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessBlueprintsContextProvider";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import pluginRegistry from "~/components/Actions/pluginRegistry";
function Summary(action: ActionProperties) {
    const processTemplates = useProcessBlueprintsFromContext();
    const processTemplateSlug = getProcessTemplateSlugFromActionProperties(action);
    const processTemplateCommit = getProcessTemplateCommitFromActionProperties(action);
    const processTemplateVersion = getProcessTemplateVersionFromActionProperties(action);
    const processTemplate = processTemplates.find((b) => isMatchingProcessTemplate(b, processTemplateSlug, processTemplateCommit))?.processTemplate;
    if (!processTemplateSlug) {
        return <div>Run a process template. No template has been selected.</div>;
    }
    if (!processTemplate) {
        return (<div>
                Run a process template.{" "}
                {processTemplateVersion ? (<>
                        The selected process template <strong>{processTemplateSlug}</strong> version <strong>{processTemplateVersion}</strong> could not be found.
                    </>) : (<>
                        The latest version of the selected process template <strong>{processTemplateSlug}</strong> could not be found.
                    </>)}
            </div>);
    }
    // Shouldn't ever show since we have a specific list item for blueprint steps that do exist, but using some sensible default.
    return <div>Run process template {processTemplate.Name}</div>;
}
// Register plugin to satisfy contract of displaying deployment process step
// Blueprint steps will be displayed in separate component
pluginRegistry.registerAction({
    actionType: "Octopus.ProcessTemplate",
    summary: Summary,
    editSections: {
        default: () => <></>,
        top: () => <></>,
    },
    canHaveChildren: (step) => false,
    canBeChild: false,
    executionLocation: ActionExecutionLocation.TargetOrServer,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => false,
    features: {
        optional: [],
    },
});
