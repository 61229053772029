import { css, cx } from "@emotion/css";
import type { BreadcrumbItem, SimpleMenuItem, PageAction, PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { LinearProgress, PageHeaderSecondary } from "@octopusdeploy/design-system-components";
import { borderRadius, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ReactElement, ReactNode } from "react";
import React from "react";
import BusyFromPromise from "~/components/BusyFromPromise";
import type { Errors } from "~/components/DataBaseComponent";
import { getErrorsFromError, isErrors } from "~/components/DataBaseComponent/Errors";
import ErrorPanel from "~/components/ErrorPanel/ErrorPanel";
interface BreadcrumbProps {
    breadcrumbsItems?: BreadcrumbItem[];
    showBreadcrumbBackIcon?: boolean;
}
export interface LegacyPaperLayoutVNextProps extends BreadcrumbProps {
    children?: ReactNode;
    title?: string;
    titleAccessory?: ReactElement;
    titleChip?: ReactElement;
    titleComplementaryText?: string;
    titleLogo?: ReactElement;
    primaryAction?: PrimaryPageAction;
    pageActions?: PageAction[];
    overflowActions?: SimpleMenuItem[];
    errors?: Errors | Error[];
    hideErrors?: boolean;
    busy?: boolean | Promise<unknown>;
    callout?: React.ReactNode;
    fullWidth?: boolean;
    hero?: React.ReactNode;
}
export function LegacyPaperLayoutVNext({ title, titleAccessory, titleChip, titleComplementaryText, titleLogo, primaryAction, pageActions, overflowActions, errors, hideErrors, busy, callout, breadcrumbsItems, showBreadcrumbBackIcon, children, fullWidth, hero, }: LegacyPaperLayoutVNextProps) {
    // TODO: this header likely should not be optional in the updated page layouts
    // but since this component is currently also used for theming level 1 page content, it needs to be able to hide the header in some cases
    // this should be deleted or reviewed further into the page layout work
    const hasHeaderContent = title || primaryAction || overflowActions;
    const convertedErrors = errors === undefined ? undefined : isErrors(errors) ? [errors] : errors.map(getErrorsFromError);
    return (<div className={cx(containerStyles, { [containerFullWidthStyles]: fullWidth })}>
            <BusyFromPromise promise={busy}>{(busy: boolean) => <LinearProgress variant={"indeterminate"} show={busy}/>}</BusyFromPromise>
            {hero}
            {hasHeaderContent && (<PageHeaderSecondary title={title} titleAccessory={titleAccessory} titleChip={titleChip} titleComplementaryText={titleComplementaryText} titleStatusIcon={titleLogo} primaryAction={primaryAction} pageActions={pageActions} overflowActions={overflowActions} breadcrumbsItems={breadcrumbsItems} showBreadcrumbBackIcon={showBreadcrumbBackIcon} isStickied/>)}
            {convertedErrors &&
            !hideErrors &&
            convertedErrors.map((error, index) => <ErrorPanel key={index} message={error.message} errors={error.errors} parsedHelpLinks={error.parsedHelpLinks} helpText={error.helpText} helpLink={error.helpLink} statusCode={error.statusCode}/>)}
            {callout}
            {children}
        </div>);
}
const containerStyles = css({
    display: "flex",
    flexDirection: "column",
    borderRadius: borderRadius.medium,
    border: `1px solid ${themeTokens.color.border.primary}`,
    paddingBottom: space[4], // equivalent paddingTop added by the busy LinearProgress
    minHeight: 640, // Not percentage-based, due to long project descriptions
    maxWidth: 1280,
    marginLeft: "auto",
    marginRight: "auto",
});
const containerFullWidthStyles = css({
    maxWidth: "unset",
});
