import type { Event as AnalyticsEvent } from "@amplitude/analytics-types";
import React from "react";
import { TaskLogViewer } from "~/areas/configuration/components/Diagnostics/TaskLogViewer";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { AmplitudeEventsTab } from "~/components/DevTools/AmplitudeEventsTab/AmplitudeEventsTab";
import { useRegisteredDevToolTabs } from "~/components/DevTools/DevToolsContext";
import { Images } from "~/components/DevTools/Images/Images";
import type { OverrideoverrideFontProps } from "~/components/DevTools/OverrideFontTab/OverrideFontTab";
import { OverrideFont } from "~/components/DevTools/OverrideFontTab/OverrideFontTab";
import { SiteMapTab } from "~/components/DevTools/SiteMapTab";
import ToggleControls from "~/components/DevTools/Toggles/ToggleControls";
import { PermissionsTab } from "./Permissions/PermissionsTab";
interface Tab {
    label: string; // This label is used as a key, so it is assumed to be globally unique across all tabs
    content: React.ReactNode;
}
export function useDevDrawerTabs(doBusyTask: DoBusyTask, analyticsEvents: AnalyticsEvent[], overrideFontProps: OverrideoverrideFontProps) {
    const dynamicTabs = useRegisteredDevToolTabs();
    const overrideFont: Tab = {
        label: "Override Font",
        content: <OverrideFont {...overrideFontProps}/>,
    };
    return [createAmplitudeEventsTab(analyticsEvents), togglesTab, permissionsTab, siteMapTab, imagesTab, overrideFont, ...dynamicTabs];
}
const togglesTab: Tab = {
    label: "Toggles",
    content: <ToggleControls />,
};
const permissionsTab: Tab = {
    label: "Permissions",
    content: <PermissionsTab />,
};
const imagesTab: Tab = {
    label: "Images",
    content: <Images />,
};
const taskLogViewerTab: Tab = {
    label: "Task Log Viewer",
    content: <TaskLogViewer />,
};
const siteMapTab: Tab = {
    label: "Sitemap",
    content: <SiteMapTab />,
};
function createAmplitudeEventsTab(analyticsEvents: AnalyticsEvent[]): Tab {
    return {
        label: "Amplitude Events",
        content: <AmplitudeEventsTab analyticsEvents={analyticsEvents}/>,
    };
}
