import { useAggregateAPIOperationStatus, useMutation } from "@octopusdeploy/octopus-react-client";
import type { ProjectGroupResource, Repository } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import type { ReactNode } from "react";
import * as React from "react";
import Markdown from "~/components/Markdown";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuConverterVNext } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import { PageContent } from "~/components/PageContent/PageContent";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { ExpandableFormSection, required, Summary, Text } from "~/components/form";
import { Form, useForm } from "~/components/form/Form/Form";
import MarkdownEditor from "~/components/form/MarkdownEditor/MarkdownEditor";
import StringHelper from "~/utils/StringHelper/index";
import styles from "./style.module.less";
interface ProjectGroupPageProps {
    loaderData: ProjectGroupPageLoaderData;
    spaceId: string;
}
export async function projectGroupPageLoader(repository: Repository, projectGroupId: string): Promise<ProjectGroupPageLoaderData> {
    const projectGroup = repository.ProjectGroups.get(projectGroupId);
    return { projectGroup: await projectGroup };
}
interface ProjectGroupPageLoaderData {
    projectGroup: ProjectGroupResource;
}
export function ProjectGroupPage({ spaceId, loaderData }: ProjectGroupPageProps) {
    const { model, setModel, formProps, createSaveAction, resetForm } = useForm({
        formName: "Edit project group",
        initialModel: loaderData.projectGroup,
        onSubmit: (repository, model) => repository.ProjectGroups.save(model),
        submitPermission: { permission: Permission.ProjectGroupEdit, projectGroup: "*" },
        snackbarSaveText: "Project group details updated",
    });
    const { navigate } = useSpaceAwareNavigation();
    const projectsPageUrl = links.projectsPage.generateUrl({ spaceId });
    const { execute: deleteProjectGroup } = useMutation({
        name: "Delete project group",
        action: (repository) => repository.ProjectGroups.del(model),
        afterComplete: async () => {
            await resetForm(); // reset the form so that the navigation isn't blocked because of a dirty form
            navigate(projectsPageUrl);
        },
    });
    const status = useAggregateAPIOperationStatus();
    const legacyOverflowActions: Array<MenuItem | MenuItem[]> = [];
    legacyOverflowActions.push(OverflowMenuItems.deleteItemDefault("project group", deleteProjectGroup, { permission: Permission.ProjectGroupDelete, projectGroup: "*" }));
    legacyOverflowActions.push([
        OverflowMenuItems.navItem("Audit Trail", links.auditPage.generateUrl({ regardingAny: [model.Id] }), {
            permission: Permission.EventView,
            wildcard: true,
        }),
    ]);
    const convertedOverflowActions = OverflowMenuConverterVNext.convertAll(legacyOverflowActions);
    return (<ProjectGroupPageLayout>
            <PageContent header={{
            title: model.Name,
            breadcrumbs: [{ label: "Projects", pageUrl: projectsPageUrl }],
            showBreadcrumbBackIcon: true,
            primaryAction: createSaveAction(),
            overflowActions: convertedOverflowActions.menuItems,
        }} legacyStatus={status}>
                {convertedOverflowActions.dialogs}
                <Form {...formProps}>
                    <TransitionAnimation>
                        <ExpandableFormSection errorKey="Name" title="Name" summary={model.Name ? Summary.summary(model.Name) : Summary.placeholder("Please enter a name for your project group")} help="A short, memorable, unique name for this project group.">
                            <Text value={model.Name} onChange={(Name) => setModel((prev) => ({ ...prev, Name }))} label="Name" validate={required("Please enter a project group name")} autoFocus={true}/>
                        </ExpandableFormSection>

                        <ExpandableFormSection errorKey="description" title="Description" summary={descriptionSummary(model)} help="Enter a description for your project group.">
                            <MarkdownEditor value={model.Description} label="Project group description" onChange={(Description) => setModel((prev) => ({ ...prev, Description }))}/>
                        </ExpandableFormSection>
                    </TransitionAnimation>
                </Form>
            </PageContent>
        </ProjectGroupPageLayout>);
}
function descriptionSummary(model: ProjectGroupResource) {
    return model.Description ? Summary.summary(<Markdown markup={model.Description}/>) : Summary.placeholder("No project group description provided");
}
// This should be replaced by a generic page layout component, once we have them
function ProjectGroupPageLayout({ children }: {
    children?: ReactNode;
}) {
    return (<main>
            <div className={styles.container}>{children}</div>
        </main>);
}
export function ProjectGroupPageLoadingContent({ state }: {
    state: Error | "is-loading";
}) {
    return (<ProjectGroupPageLayout>
            <PageContent header={{ title: StringHelper.ellipsis }} busy={state === "is-loading"} errors={state === "is-loading" ? [] : [state]}>
                {null}
            </PageContent>
        </ProjectGroupPageLayout>);
}
