import React from "react";
import { usePage } from "./PageProvider";
import type { PageDefinition } from "./pageId";
interface PageChangedProps {
    onChange: (page: PageDefinition) => void;
}
export function PageChanged({ onChange }: PageChangedProps) {
    const page = usePage();
    const onChangeRef = React.useRef(onChange);
    //We want the latest of the callback for when we notify of page changes, but
    //we do not want the callback to trigger the actual page change effect.
    React.useEffect(() => {
        onChangeRef.current = onChange;
    }, [onChange]);
    React.useEffect(() => {
        if (page) {
            onChangeRef.current(page);
        }
    }, [page]);
    return null;
}
