import type { LinkHref } from "@octopusdeploy/portal-routes";
import type { Dispatch, SetStateAction } from "react";
import * as React from "react";
import { useState } from "react";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import type { OctopusTheme } from "~/components/Theme";
import { useOctopusTheme } from "~/components/Theme";
import { ResultsLink } from "../ResultsLink";
import styles from "../style.module.less";
import { RolesContentChip } from "./RolesContentChip";
interface RolesContentWithThemeProps extends RolesContentProps {
    filterText: string;
    setFilterText: Dispatch<SetStateAction<string>>;
    theme: OctopusTheme;
}
const onFilter = (filter: string, role: string) => {
    return !filter || filter.length === 0 || !role || role.toLowerCase().includes(filter.toLowerCase());
};
export const RolesContentWithTheme = ({ machineRoles, numberOfItemsToRender, link, filterText, setFilterText, theme }: RolesContentWithThemeProps) => {
    const filteredRoles = machineRoles.filter((item: string) => onFilter(filterText, item)).slice(0, numberOfItemsToRender);
    return (<>
            {(filteredRoles.length > 1 || filterText) && (<FilterSearchBox placeholder="Filter..." onChange={(value) => setFilterText(value.toLowerCase())} containerClassName={styles.cardContentContainerFilter} inputClassName={styles.dashboardFilterInputClassName} iconColor={theme.secondaryText}/>)}
            <div className={styles.cardContentContainerRows}>
                {filteredRoles.map((role: string) => {
            return <RolesContentChip key={role} role={role} link={link}/>;
        })}
                <ResultsLink totalCount={machineRoles.length} renderedCount={filteredRoles.length} link={link()}/>
            </div>
        </>);
};
interface RolesContentProps {
    machineRoles: string[];
    numberOfItemsToRender: number;
    link: (filter?: RolesContentFilter) => LinkHref;
}
export interface RolesContentFilter {
    roles?: string[];
}
export const RolesContent = (props: RolesContentProps) => {
    const theme = useOctopusTheme();
    const [filterText, setFilterText] = useState<string>("");
    return <RolesContentWithTheme {...props} filterText={filterText} setFilterText={setFilterText} theme={theme}/>;
};
