import { css } from "@emotion/css";
import { Callout } from "@octopusdeploy/design-system-components";
import { ActivityStatus } from "@octopusdeploy/octopus-server-client";
import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
const calloutStyle = css({
    marginTop: "10/16rem",
    marginBottom: "10/16rem",
});
type EmptyStateProps = {
    status: ActivityStatus | undefined;
    isResourceStatusEnabled: boolean;
    hasData: boolean;
};
export const EmptyState = (props: EmptyStateProps) => {
    const getCallout = () => {
        //if this is skipped, then just show skipped in all scenario's
        if (props.status === ActivityStatus.Skipped) {
            return (<Callout title="This step has been skipped" type={"information"}>
                    Skipped steps won’t perform the Kubernetes Object Status Check.
                </Callout>);
        }
        //if the resource status is not enabled at all for this step, just show that it's not enabled
        if (!props.isResourceStatusEnabled) {
            return (<Callout title="The Kubernetes Object Status Check is not enabled for this step" type={"information"}>
                    <ExternalLink href="KOS">Learn more</ExternalLink> about how this feature can help you get better visibility into the status of your Kubernetes objects.
                </Callout>);
        }
        switch (props.status) {
            case ActivityStatus.Pending:
                if (!props.hasData)
                    return (<Callout title="This step is currently queued" type={"information"}>
                            You’ll see the status of your Kubernetes objects here when this step starts running.
                        </Callout>);
                break;
            case ActivityStatus.Failed:
            case ActivityStatus.Success:
            case ActivityStatus.SuccessWithWarning:
                if (!props.hasData) {
                    return (<Callout title="No objects were found for this step" type={"information"}>
                            We didn't find any Kubernetes objects in the deployment manifest. The manifest or Helm chart may be empty and Helm hooks are not currently shown. <ExternalLink href="KOS">Docs</ExternalLink>
                        </Callout>);
                }
                break;
            case ActivityStatus.Canceled:
                return (<Callout title="This step has been cancelled" type={"information"}>
                        Cancelled steps won’t perform the Kubernetes Object Status Check.
                    </Callout>);
        }
    };
    const callout = getCallout();
    if (callout !== undefined) {
        return <div className={calloutStyle}>{callout}</div>;
    }
    return null;
};
