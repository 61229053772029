/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Tooltip } from "@octopusdeploy/design-system-components";
import { ArrowDownIcon, ArrowRightIcon, ArrowUpIcon, MinusIcon } from "@octopusdeploy/design-system-icons";
import { InsightsTrendDirection } from "@octopusdeploy/octopus-server-client";
import type { OverallTrend, InsightsTrendKey } from "@octopusdeploy/octopus-server-client";
import cn from "classnames";
import React from "react";
import { formatDurationInWords } from "~/areas/insights/dataTransformation/stringHelpers";
import styles from "./styles.module.less";
interface TrendIndicatorProps<T extends string | number> {
    trend: OverallTrend<T>;
    trendKey: InsightsTrendKey;
}
const trendDirectionIconLookup: Record<InsightsTrendDirection, JSX.Element | null> = {
    None: <MinusIcon size={24}/>,
    Up: <ArrowUpIcon size={24}/>,
    Steady: <ArrowRightIcon size={24}/>,
    Down: <ArrowDownIcon size={24}/>,
};
function getTrendTooltipContent(direction: InsightsTrendDirection) {
    switch (direction) {
        case InsightsTrendDirection.None:
            return "Not enough deployments to draw a trend";
        case InsightsTrendDirection.Steady:
            return "Trending even in the last 4 weeks compared to the previous 4";
        case InsightsTrendDirection.Up:
        case InsightsTrendDirection.Down:
            return `Trending ${direction.toLocaleLowerCase()} in the last 4 weeks compared to the previous 4`;
    }
}
export function TrendIndicator<T extends string | number>({ trend, trendKey }: TrendIndicatorProps<T extends string ? string : number>) {
    const parseValue = (val: number | string) => {
        switch (trendKey) {
            case "DeploymentFrequency":
                // The deployment frequency current value is the sum of the last 4 weeks (instead of an average)
                // Hack to show a weekly average
                const weeklyAvg = Math.ceil((val as number) / 4);
                return weeklyAvg.toLocaleString();
            case "LeadTime":
                return formatDurationInWords(val as string);
            case "ChangeFailureRate":
                return `${Math.round((val as number) * 100)}%`;
            case "MeanTimeToRecovery":
                return formatDurationInWords(val as string);
            default:
                return val;
        }
    };
    // Up should be red for everything other than Frequency
    const containerClassname = cn(styles.container, {
        [styles.positiveTrend]: trendKey === "DeploymentFrequency" ? trend.Direction === InsightsTrendDirection.Up : trend.Direction === InsightsTrendDirection.Down,
        [styles.negativeTrend]: trendKey === "DeploymentFrequency" ? trend.Direction === InsightsTrendDirection.Down : trend.Direction === InsightsTrendDirection.Up,
    });
    return (<div className={containerClassname}>
            {trend.Current ? (<Tooltip content="Average for the last 4 weeks">
                    <span className={styles.value}>{parseValue(trend.Current)}</span>
                </Tooltip>) : (<Tooltip style={{ display: "flex" }} content="No deployments in the last 4 weeks">
                    {trendDirectionIconLookup[InsightsTrendDirection.None]}
                </Tooltip>)}
            <Tooltip style={{ display: "flex" }} content={getTrendTooltipContent(trend.Direction)}>
                {trendDirectionIconLookup[trend.Direction]}
            </Tooltip>
        </div>);
}
