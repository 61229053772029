import { ActionButtonType, EmptyStateInsightsIllustration } from "@octopusdeploy/design-system-components";
import type { PrimaryPageAction } from "@octopusdeploy/design-system-components";
import type { InsightsReportResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import AddInsightsReportDialog from "app/areas/insights/components/Reports/AddInsightsReportDialog";
import { InsightsReportCard } from "app/areas/insights/components/Reports/ReportCard/ReportCard";
import React from "react";
import { useAnalyticSimpleActionDispatch } from "~/analytics/Analytics";
import type { AnalyticSimpleDispatcher } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import DataBaseComponent from "~/components/DataBaseComponent";
import DataLoader from "~/components/DataLoader";
import Dialog from "~/components/Dialog/Dialog";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import { Level1EmptyStateLayout } from "~/components/GettingStarted/Level1EmptyStateLayout";
import LoadMoreCard from "~/components/LoadMoreCard/LoadMoreCard";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { PageContent } from "~/components/PageContent/PageContent";
import { PermissionCheck } from "~/components/PermissionCheck";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { SkeletonLoadingLayout } from "~/components/SkeletonLoadingLayout/SkeletonLoadingLayout";
import styles from "./style.module.less";
type InitialData = {
    reports: InsightsReportResource[];
    totalNumberOfReports: number;
};
const ReportDataLoader = DataLoader<InitialData>();
interface ReportListLayoutProps {
    children: React.ReactNode;
}
interface ReportListInnerProps {
    initialReports: InsightsReportResource[];
    pageSize: number;
    totalNumberOfReports: number;
    dispatchAction: AnalyticSimpleDispatcher;
}
interface ReportListInnerState {
    reports: InsightsReportResource[];
    currentPage: number;
}
function AddInsightsReportButton() {
    const dispatchAction = useAnalyticSimpleActionDispatch();
    const onClick = () => dispatchAction("Click Create Insights Report Button");
    const { navigate } = useSpaceAwareNavigation();
    const onReportCreated = (report: InsightsReportResource) => {
        navigate(links.insightsReportSettingsPage.generateUrl({ spaceId: report.SpaceId, reportId: report.Id }));
    };
    return (<PermissionCheck permission={Permission.InsightsReportCreate}>
            <OpenDialogButton type={ActionButtonType.Primary} label="Create an Insights Report" onClick={onClick}>
                <AddInsightsReportDialog onReportCreated={onReportCreated}/>
            </OpenDialogButton>
        </PermissionCheck>);
}
export function ReportOnboarding() {
    const intro = (<span>
            Access <strong>DORA metrics</strong> across your teams at a portfolio level to help quantify your performance across projects, environments or tenants. See what you need to do to improve next, increasing tempo and stability.
        </span>);
    return <Level1EmptyStateLayout title={"Create an Insights Report"} intro={intro} image={<EmptyStateInsightsIllustration />} actionButtonsAndLinks={[<AddInsightsReportButton />]}/>;
}
function ReportListLayout({ children }: ReportListLayoutProps) {
    const { isOpen: isCreateReportDialogOpen, openDialog: openCreateReportDialog, closeDialog: closeCreateReportDialog } = useDialogTrigger();
    const dispatchAction = useAnalyticSimpleActionDispatch();
    const onClickCreateInsightsReportButton = () => {
        dispatchAction("Click Create Insights Report Button");
        openCreateReportDialog();
    };
    const { navigate } = useSpaceAwareNavigation();
    const onReportCreated = (report: InsightsReportResource) => {
        navigate(links.insightsReportSettingsPage.generateUrl({ spaceId: report.SpaceId, reportId: report.Id }));
        closeCreateReportDialog();
    };
    const createInsightsReportAction: PrimaryPageAction = {
        type: "button",
        label: "Create an Insights Report",
        onClick: onClickCreateInsightsReportButton,
        hasPermissions: isAllowed({
            permission: Permission.InsightsReportCreate,
        }),
    };
    return (<PageContent header={{ title: "Insights", primaryAction: createInsightsReportAction }}>
            <Dialog open={isCreateReportDialogOpen}>
                <AddInsightsReportDialog onReportCreated={onReportCreated}/>
            </Dialog>
            {children}
        </PageContent>);
}
class ReportListInner extends DataBaseComponent<ReportListInnerProps, ReportListInnerState> {
    constructor(props: ReportListInnerProps) {
        super(props);
        this.state = {
            currentPage: 1,
            reports: [...props.initialReports],
        };
    }
    componentDidMount(): void {
        this.props.dispatchAction("View Insights Report List");
    }
    private async loadMoreReports() {
        const { currentPage, reports } = this.state;
        const { pageSize } = this.props;
        this.doBusyTask(async () => {
            const nextReports = (await repository.InsightsReports.list({ skip: currentPage * pageSize, take: pageSize })).Items;
            this.setState({ currentPage: currentPage + 1, reports: [...reports, ...nextReports] });
        });
    }
    render() {
        const { reports } = this.state;
        const { totalNumberOfReports } = this.props;
        const showLoadMore = reports.length < totalNumberOfReports;
        return (<main>
                <>
                    {reports.length > 0 && (<div className={styles.castListContainer}>
                            <div className={styles.cardList}>
                                {reports.map((w) => (<InsightsReportCard key={w.Id} report={w}/>))}
                                {/* This design is not finalized. This load more button will change */}
                                {showLoadMore && <LoadMoreCard onLoadAll={() => this.loadMoreReports()} onLoadMore={() => this.loadMoreReports()}/>}
                            </div>
                        </div>)}
                </>
            </main>);
    }
    static displayName = "ReportListInner";
}
export function InsightsReportListPage() {
    const pageSize = 30;
    const dispatchAction = useAnalyticSimpleActionDispatch();
    return (<ReportDataLoader load={async () => {
            const { Items: reports, TotalResults: totalNumberOfReports } = await repository.InsightsReports.list({ skip: 0, take: pageSize });
            return { reports, totalNumberOfReports };
        }} renderAlternate={({ errors }) => <SkeletonLoadingLayout errors={errors}/>} renderWhenLoaded={({ reports, totalNumberOfReports }) => {
            if (reports.length === 0)
                return <ReportOnboarding />;
            return (<ReportListLayout>
                        <ReportListInner initialReports={reports} pageSize={pageSize} totalNumberOfReports={totalNumberOfReports} dispatchAction={dispatchAction}/>
                    </ReportListLayout>);
        }}/>);
}
