import { css } from "@emotion/css";
import { space, themeTokens, fontSize, fontWeight } from "@octopusdeploy/design-system-tokens";
import React from "react";
import styles from "./Registrations.module.less";
type CommonViewsProps = {
    children?: React.ReactNode;
    title?: string;
};
export const OverviewContainer = (props: CommonViewsProps) => {
    return <div className={styles.overviewContainer}>{props.children}</div>;
};
export const TopicsContainer = (props: CommonViewsProps) => {
    return <div className={styles.topicsContainer}>{props.children}</div>;
};
export const OverviewHighlightText = (props: CommonViewsProps) => {
    return <div className={styles.overviewHighlight}>{props.children}</div>;
};
export const TermsText = (props: CommonViewsProps) => {
    return <div className={styles.terms}>{props.children}</div>;
};
export const LearnMoreResources = (props: CommonViewsProps) => {
    return (<div className={styles.docs}>
            <h4 className={relatedDocsStyle}>{props.title ?? "Related Docs"}</h4>
            {props.children}
        </div>);
};
export const SupportResources = (props: CommonViewsProps) => {
    return (<div className={styles.support}>
            <h5>Support</h5>
            {props.children}
        </div>);
};
export const TutorialResources = (props: CommonViewsProps) => {
    return (<div className={styles.tutorials}>
            <h5>Tutorials</h5>
            {props.children}
        </div>);
};
export const WebinarResources = (props: CommonViewsProps) => {
    return (<div className={styles.webinar}>
            <h5>Webinar</h5>
            {props.children}
        </div>);
};
export const VideoResources = (props: CommonViewsProps) => {
    return (<div className={styles.video}>
            <h5>Video</h5>
            {props.children}
        </div>);
};
const relatedDocsStyle = css({
    paddingTop: space[16],
    borderTop: `1px solid ${themeTokens.color.border.primary}`,
    fontSize: fontSize.medium,
    fontWeight: fontWeight[700],
    color: themeTokens.color.text.secondary,
});
