import { Tooltip, Callout } from "@octopusdeploy/design-system-components";
import type { PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { TenantResource, TagSetResource, ProjectSummaryResource, EnvironmentResource, TenantMissingVariableResource, Repository } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import cn from "classnames";
import type { Dictionary } from "lodash";
import { keyBy, compact } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import type { AnalyticActionDispatcher, AnalyticConnectTenantsDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch, useAnalyticConnectTenantsDispatch } from "~/analytics/Analytics";
import ConnectMultipleProjectsDialog from "~/areas/projects/components/ProjectTenants/ConnectMultipleProjectsDialog";
import { UpdateConnectionForTenantDialog } from "~/areas/tenants/TenantOverview/UpdateConnectionForTenantDialog";
import { repository } from "~/clientInstance";
import Dialog from "~/components/Dialog/Dialog";
import type { DialogControls } from "~/components/Dialog/DialogTrigger";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import Markdown from "~/components/Markdown/index";
import InternalLink from "~/components/Navigation/InternalLink";
import { NoResults } from "~/components/NoResults/NoResults";
import { PageContent } from "~/components/PageContent/PageContent";
import { SimplePagingList } from "~/components/PagingList/SimplePagingList";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { Section, SectionHeadingType } from "~/components/Section/Section";
import * as tenantTagsets from "~/components/tenantTagsets";
import { environmentChipList } from "../../../components/Chips/index";
import type { DataBaseComponentState } from "../../../components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "../../../components/DataBaseComponent/DataBaseComponent";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import OpenDialogButton from "../../../components/Dialog/OpenDialogButton";
import Logo from "../../../components/Logo/Logo";
import { OverflowMenu, OverflowMenuItems } from "../../../components/OverflowMenu/OverflowMenu";
import Tag from "../../../components/Tag/Tag";
import { tenantsActions } from "../tenantsArea";
import AddTagsToTenantDialog from "./AddTagsToTenantDialog";
import styles from "./style.module.less";
interface TenantOverviewState extends DataBaseComponentState {
    tenant: TenantResource;
    selectedProjectId?: string;
    projectToRemove?: ProjectSummaryResource;
    projectToEdit?: ProjectSummaryResource;
}
interface TenantOverviewInternalProps {
    onTenantVariablesFetched: (tenantMissingVariables?: TenantMissingVariableResource) => void;
    loaderData: LoaderData;
    dispatchAction: AnalyticActionDispatcher;
    tenantDispatchAction: AnalyticConnectTenantsDispatcher;
    connectProjectDialogControls: DialogControls;
}
class LinkedProjectEnvironmentList extends SimplePagingList<{
    project: ProjectSummaryResource;
    environmentIds: string[];
}> {
}
interface LoaderData {
    tenant: TenantResource;
    tagSets: TagSetResource[];
    projects: Dictionary<ProjectSummaryResource>;
    environments: EnvironmentResource[];
}
export const tenantOverviewPageTitle = "Overview";
interface TenantOverviewPageProps {
    loaderData: LoaderData;
}
export async function tenantOverviewPageLoader(repository: Repository, tenantId: string): Promise<LoaderData> {
    const tenant = repository.Tenants.get(tenantId);
    const tagSets = tenantTagsets.getAll();
    const projects = repository.Projects.summaries();
    const environments = repository.Environments.all();
    return {
        tenant: await tenant,
        tagSets: await tagSets,
        projects: keyBy(await projects, (p) => p.Id),
        environments: await environments,
    };
}
export const TenantOverviewPage: React.FC<TenantOverviewPageProps> = ({ loaderData }) => {
    const dispatch = useDispatch();
    const dispatchAction = useAnalyticActionDispatch();
    const tenantDispatchAction = useAnalyticConnectTenantsDispatch();
    const connectProjectDialogControls = useDialogTrigger();
    const onTenantVariablesFetch = React.useCallback((tenantMissingVariables: TenantMissingVariableResource | undefined) => {
        dispatch(tenantsActions.tenantMissingVariablesFetched(tenantMissingVariables));
    }, [dispatch]);
    return (<TenantOverviewPageInternal loaderData={loaderData} dispatchAction={dispatchAction} tenantDispatchAction={tenantDispatchAction} onTenantVariablesFetched={onTenantVariablesFetch} connectProjectDialogControls={connectProjectDialogControls}/>);
};
TenantOverviewPage.displayName = "TenantOverviewPage"
class TenantOverviewPageInternal extends DataBaseComponent<TenantOverviewInternalProps, TenantOverviewState> {
    constructor(props: TenantOverviewInternalProps) {
        super(props);
        const intitialTenant = this.props.loaderData.tenant;
        this.state = {
            tenant: intitialTenant,
        };
    }
    get tenantId(): string {
        return this.state.tenant.Id;
    }
    handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const tenant = await repository.Tenants.save(this.state.tenant);
            this.setState({ tenant });
            const variables = await repository.Tenants.missingVariables({ tenantId: tenant.Id }, false);
            this.props.onTenantVariablesFetched(variables.find((t) => t.TenantId === tenant.Id));
        });
    };
    renderEnvironmentItems = ({ projectId, projectName }: {
        projectId: string;
        projectName: string;
    }) => {
        const environmentIds = this.state.tenant.ProjectEnvironments[projectId];
        if (!environmentIds || !environmentIds.length) {
            return (<div className={styles.noEnvironmentsWarning}>
                    <Tooltip content={`You will not be able to deploy to ${projectName} until you connect to one of the environments.`}>
                        <em className={cn("fa-solid fa-triangle-exclamation", styles.noEnvironmentsWarningIcon)}/>
                    </Tooltip>
                    Not connected to any environments
                </div>);
        }
        return <>{environmentChipList(this.props.loaderData.environments, environmentIds)}</>;
    };
    renderLinkedProjects(): React.ReactNode {
        const projectEnvironments = this.state.tenant.ProjectEnvironments;
        const projects = this.props.loaderData.projects;
        const items = Object.keys(this.state.tenant.ProjectEnvironments)
            // need to filter out projects that were not returned due to permissions
            .filter((p) => !!projects[p])
            .map((p) => ({ project: projects[p], environmentIds: projectEnvironments[p] }))
            .sort((a, b) => a.project && b.project && a.project.Name.localeCompare(b.project.Name));
        if (items.length === 0) {
            return null;
        }
        // user may not be able to see some of these projects, if they *can't see any* show a callout
        if (items.filter((i) => !!i.project).length === 0) {
            return (<div className={styles.noProjectAccess}>
                    <Callout type={"information"} title={"Insufficient permissions"}>
                        Your Project permissions do not allow you to see any of the Linked Projects.
                    </Callout>
                </div>);
        }
        return (<LinkedProjectEnvironmentList onFilter={(filter, item) => item.project.Name.toLowerCase().includes(filter.toLowerCase())} items={items} pageSize={20} onRow={(item) => {
                if (!item.project) {
                    return null;
                }
                return (<div className={styles.linkedProject}>
                            <div className={styles.header}>
                                <div className={styles.projectName}>
                                    <Logo url={item.project.Logo}/>
                                    <InternalLink to={links.projectRootRedirect.generateUrl({ spaceId: item.project.SpaceId, projectSlug: item.project.Slug })}>{item.project.Name}</InternalLink>
                                </div>
                                <OverflowMenu menuItems={[
                        OverflowMenuItems.dialogItem("Edit", <UpdateConnectionForTenantDialog projectId={item.project.Id} projectName={item.project.Name} tenantId={this.state.tenant.Id} selectedEnvironmentIds={item.environmentIds} onUpdated={this.handleUpdatedProjectLink}/>, { permission: Permission.TenantEdit, tenant: this.tenantId }),
                        OverflowMenuItems.item("Remove", () => this.setState({ projectToRemove: item.project }), { permission: Permission.TenantEdit, tenant: this.tenantId }),
                        OverflowMenuItems.navItem(`View Project Template Variables`, links.projectTenantProjectTemplatesPage.generateUrl({ spaceId: item.project.SpaceId, projectSlug: item.project.Slug }), {
                            permission: Permission.VariableView,
                            wildcard: true,
                        }),
                        OverflowMenuItems.navItem(`View Common Template Variables`, links.projectTenantCommonTemplatesPage.generateUrl({ spaceId: item.project.SpaceId, projectSlug: item.project.Slug }), {
                            permission: Permission.VariableView,
                            wildcard: true,
                        }),
                    ]}/>
                            </div>
                            <div className={styles.details}>
                                <div className={styles.environments}>{this.renderEnvironmentItems({ projectId: item.project.Id, projectName: item.project.Name })}</div>
                            </div>
                        </div>);
            }}/>);
    }
    handleUpdatedProjectLink = async (tenant: TenantResource): Promise<boolean> => {
        return this.doBusyTask(async () => {
            this.setState({ tenant });
            const variables = await repository.Tenants.missingVariables({ tenantId: tenant.Id }, false);
            this.props.onTenantVariablesFetched(variables.find((t) => t.TenantId === tenant.Id));
            return true;
        });
    };
    handleRemoveProjectLink = async () => {
        const tenantId = this.state.tenant.Id;
        const projectId = this.state.projectToRemove?.Id;
        return this.doBusyTask(async () => {
            this.setState({ projectToRemove: undefined });
            const tenant = await repository.Tenants.get(tenantId);
            if (projectId) {
                delete tenant.ProjectEnvironments[projectId];
            }
            const savedTenant = await repository.Tenants.save(tenant);
            return this.handleUpdatedProjectLink(savedTenant);
        });
    };
    onConnected = (tenant: TenantResource, numberOfProjectsConnected: number) => {
        this.setState({ tenant });
        this.props.tenantDispatchAction("Connect Projects", { action: Action.Save, resource: "Project", numTenants: numberOfProjectsConnected });
    };
    onDialogOpen = () => {
        this.props.dispatchAction("Start Connecting Projects", { action: Action.Configure, resource: "Project" });
        this.props.connectProjectDialogControls.openDialog();
    };
    linkedProjectMessage() {
        const projectCount = Object.keys(this.state.tenant.ProjectEnvironments).length;
        if (projectCount === 0) {
            return (<div className={styles.notConnectedMessage}>
                    <div>No projects are connected to this tenant.</div>
                    <NoResults />
                </div>);
        }
        return (<div>
                {projectCount} project{projectCount > 1 ? "s" : ""} can deploy to this tenant.
            </div>);
    }
    renderTenantTagsSection() {
        const tagSets = this.props.loaderData.tagSets;
        const groupedTenantTags = tenantTagsets.groupAndOrderByTagSet(this.state.tenant.TenantTags, tagSets);
        return (<div>
                <h4>Tenant Tag Sets</h4>
                {groupedTenantTags.map((groupedTenantTag) => {
                const tagSet = tagSets.find((ts) => ts.Name === groupedTenantTag.name);
                if (!tagSet) {
                    throw new Error(`Tenant had a tag that did not match one of the tag sets. Tried to find ${groupedTenantTag.name}.`);
                }
                return (<div key={groupedTenantTag.name} className={styles.tagSetContainer}>
                            {tagSet.Description ? <Tooltip content={<Markdown markup={tagSet.Description}/>}>{this.tagSetName(groupedTenantTag.name)}</Tooltip> : this.tagSetName(groupedTenantTag.name)}
                            <div>
                                {compact(groupedTenantTag.tags.map((canonicalTagName) => tagSet.Tags.find((t) => t.CanonicalTagName === canonicalTagName)))
                        .sort((a, b) => a.SortOrder - b.SortOrder)
                        .map((tag) => {
                        return <Tag tagName={tag.Name} tagColor={tag.Color} key={tag.Name} description={tag.Description}/>;
                    })}
                            </div>
                        </div>);
            })}
                <OpenDialogButton label="Manage Tags">
                    <AddTagsToTenantDialog tenant={this.state.tenant} onUpdated={(tenant: TenantResource) => this.setState({ tenant })} tagSets={this.props.loaderData.tagSets} dispatchAction={this.props.dispatchAction}/>
                </OpenDialogButton>
            </div>);
    }
    render() {
        // need to filter out projects that were not returned due to permissions
        const alreadyConnectedProjectIds = Object.keys(this.state.tenant.ProjectEnvironments).filter((p) => !!this.props.loaderData.projects[p]);
        const primaryAction: PrimaryPageAction = {
            type: "button",
            label: "Connect Projects",
            hasPermissions: isAllowed({ permission: Permission.TenantEdit, tenant: this.tenantId }),
            onClick: this.onDialogOpen,
        };
        return (<PageContent header={{ title: "Overview", primaryAction }} busy={this.state.busy} errors={this.errors} sidebar={this.renderTenantTagsSection()}>
                <Dialog open={this.props.connectProjectDialogControls.isOpen}>
                    <ConnectMultipleProjectsDialog tenant={this.state.tenant} onConnected={(tenant: TenantResource, numberOfProjectsConnected: number) => this.onConnected(tenant, numberOfProjectsConnected)} alreadyConnectedProjectsIds={alreadyConnectedProjectIds}/>
                </Dialog>
                <DeleteDialog title={"Unlink Tenant from Project"} open={!!this.state.projectToRemove} onClose={() => this.setState({ projectToRemove: undefined })} deleteButtonLabel="Remove" onDeleteClick={this.handleRemoveProjectLink} renderContent={() => {
                if (this.state.projectToRemove) {
                    return (<p>
                                    Are you sure you want to unlink <b>{this.state.tenant.Name}</b> from <b>{this.state.projectToRemove.Name}</b>?
                                </p>);
                }
                throw new Error("Unlink Tenant from Project dialog tried to render without a project selected to unlink.");
            }}/>
                <Section sectionHeader="Projects" headingType={SectionHeadingType.Heading4}>
                    {this.linkedProjectMessage()}
                </Section>
                {this.renderLinkedProjects()}
            </PageContent>);
    }
    private tagSetName(name: string) {
        return <strong>{name}</strong>;
    }
    static displayName = "TenantOverviewPageInternal";
}
