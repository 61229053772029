/* Generated file. Do not modify. Please check more details in build/generate.ts */
import * as React from "react";
import type { IconProps } from "../IconTypes";
import { IconWrapper } from "../IconWrapper";
export function CurlyBracketsHashtagIcon(props: IconProps) {
    return (<IconWrapper {...props}>
            {<>
                    <path d="M27.9 6.5A1.5 1.5 0 0 0 26.401 5a5.267 5.267 0 0 0-5.265 5.27v3.5c0 .476-.137.943-.393 1.345l-2.43 3.81a2 2 0 0 0 0 2.15l2.43 3.81c.256.402.393.868.393 1.345v3.5c0 2.91 2.357 5.27 5.265 5.27a1.5 1.5 0 0 0 0-3 2.27 2.27 0 0 1-2.268-2.27v-3.5c0-1.048-.3-2.075-.863-2.959L21.184 20l2.086-3.271c.564-.884.863-1.91.863-2.96v-3.5A2.27 2.27 0 0 1 26.401 8 1.5 1.5 0 0 0 27.9 6.5"/>
                    <path d="M30.1 6.5A1.5 1.5 0 0 1 31.599 5a5.267 5.267 0 0 1 5.265 5.27v3.5c0 .476.137.943.393 1.345l2.43 3.81a2 2 0 0 1 0 2.15l-2.43 3.81a2.5 2.5 0 0 0-.392 1.345v3.5c0 2.91-2.358 5.27-5.266 5.27a1.5 1.5 0 0 1 0-3 2.27 2.27 0 0 0 2.268-2.27v-3.5c0-1.048.299-2.075.863-2.959L36.816 20l-2.086-3.271a5.5 5.5 0 0 1-.863-2.96v-3.5A2.27 2.27 0 0 0 31.599 8 1.5 1.5 0 0 1 30.1 6.5"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.769 28.283a1.25 1.25 0 1 0 2.462.434l.612-3.467h2.407a1.25 1.25 0 1 0 0-2.5h-1.966l.97-5.5h1.996a1.25 1.25 0 1 0 0-2.5h-1.554l.535-3.033a1.25 1.25 0 1 0-2.462-.434l-.612 3.467H7.99l.494-3.05a1.25 1.25 0 0 0-2.468-.4l-.558 3.45H3.25a1.25 1.25 0 0 0 0 2.5h1.804l-.89 5.5H2.25a1.25 1.25 0 0 0 0 2.5h1.51l-.494 3.05a1.25 1.25 0 1 0 2.468.4l.558-3.45h4.012zM7.586 17.25h4.13l-.97 5.5h-4.05z"/>
                </>}
        </IconWrapper>);
}
