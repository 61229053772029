import { useMutation } from "@octopusdeploy/octopus-react-client";
import type { ChannelResource, GitRefResource, ProjectResource, Repository, ValidateGitRefV2Response } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import type { ChannelLookupData } from "~/areas/projects/components/Channels/CreateOrEditChannelPageContent";
import { channelLookupDataLoader, CreateOrEditChannelPageContent } from "~/areas/projects/components/Channels/CreateOrEditChannelPageContent";
import { type ProjectContextProps } from "~/areas/projects/context/index";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import type { OverflowMenuDeleteItem, OverflowMenuDialogItem, OverflowMenuDisabledItem, OverflowMenuNavLink } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { ConvertedOverflowMenuItems } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import { OverflowMenuConverterVNext } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
export async function editChannelPageLoader(repository: Repository, channelId: string, projectContext: Promise<ProjectContextProps>): Promise<EditChannelPageLoaderData> {
    const lookupData = channelLookupDataLoader(repository, projectContext);
    const { state: { model: project }, } = await projectContext;
    const channels = await repository.Projects.getChannels(project);
    const channel = channels.Items.filter((c) => c.Id === channelId)[0];
    return {
        lookupData: await lookupData,
        channel,
    };
}
export interface EditChannelPageLoaderData {
    lookupData: ChannelLookupData;
    channel: ChannelResource;
}
interface EditChannelPageProps {
    loaderData: EditChannelPageLoaderData;
    project: ProjectResource;
    gitRefValidationError: ValidateGitRefV2Response | undefined;
    gitRef: Readonly<GitRefResource> | undefined;
}
export function EditChannelPage({ loaderData, project, gitRef, gitRefValidationError }: EditChannelPageProps) {
    const { navigate } = useSpaceAwareNavigation();
    const { execute: deleteChannel } = useMutation({
        name: "Delete channel",
        action: async (repository) => repository.Channels.del(loaderData.channel),
        afterComplete: async () => navigate(links.channelsPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug })),
    });
    const overflowMenuItems = getOverflowMenuItems(loaderData.channel, project.Id, deleteChannel);
    return <CreateOrEditChannelPageContent channel={loaderData.channel} lookupData={loaderData.lookupData} project={project} gitRef={gitRef} gitRefValidationError={gitRefValidationError} overflowMenuItems={overflowMenuItems}/>;
}
function getOverflowMenuItems(channel: ChannelResource, projectId: string, deleteChannel: () => Promise<void>): ConvertedOverflowMenuItems {
    const overFlowActions: (OverflowMenuDialogItem | OverflowMenuDeleteItem | OverflowMenuDisabledItem | OverflowMenuNavLink[])[] = [
        OverflowMenuItems.deleteItemDefault("channel", deleteChannel, {
            permission: Permission.ProcessEdit,
            project: projectId,
            tenant: "*",
        }, "The channel and all steps and variables scoped only to this channel will be permanently deleted."),
        [
            OverflowMenuItems.navItem("Audit Trail", links.auditPage.generateUrl({ regardingAny: [channel.Id] }), {
                permission: Permission.EventView,
                wildcard: true,
            }),
        ],
    ];
    return OverflowMenuConverterVNext.convertAll(overFlowActions);
}
