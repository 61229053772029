import { Level2PageLayout, useIsLargerThanIpadResolution, useSetInPageNavVisible } from "@octopusdeploy/design-system-components";
import { CodeBranchIcon } from "@octopusdeploy/design-system-icons";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import type { BranchSpecifier, GetFavouriteProjectsForUserBffResponse, ProjectGroupResource, ProjectIntentsResource, ProjectResource, ValidateGitRefV2Response } from "@octopusdeploy/octopus-server-client";
import { Permission, UseDefaultBranch, ValidateGitRefV2ResponseType } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import type { ReactNode } from "react";
import * as React from "react";
import { ProjectLinks } from "~/areas/projects/components/ProjectLayout/ProjectLinks";
import { ResilientPipelineCallouts } from "~/areas/projects/components/ProjectLayout/ResilientPipelineCallouts";
import SampleProjectTour from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTour";
import SampleProjectTourResume from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTourResume";
import { NewlyCreatedProjectWizardDialog } from "~/areas/projects/components/Projects/OnboardingQuestionnaire/NewlyCreatedProjectWizardDialog";
import { useIsPageVersionControlled } from "~/areas/projects/context/useIsPageVersionControlled";
import { repository } from "~/clientInstance";
import Chip from "~/components/Chips/Chip";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
import ErrorPanel from "~/components/ErrorPanel/ErrorPanel";
import { GettingStartedFooter } from "~/components/GettingStarted/GettingStartedFooter";
import Markdown from "~/components/Markdown";
import { hasPermission, isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import NewProjectWizard from "~/components/ProjectBasedActivation/NewProjectWizard";
import { SkeletonLoadingLayout } from "~/components/SkeletonLoadingLayout/SkeletonLoadingLayout";
import { useCreatePageSideNavLink } from "~/hooks/useCreatePageSideNavLink";
import type { CreatePageSideNavLink } from "~/hooks/useCreatePageSideNavLink";
import { useOctopusFeatureToggle } from "~/hooks/useOctopusFeatureToggle";
import type { ProjectContextProps } from "../../context";
import { ProjectContextProvider } from "../../context";
import { ProjectStatusBar } from "../ProjectStatus/ProjectStatusBar";
import { projectStatusActions } from "../ProjectStatus/actions";
import type { ProjectStatus } from "../ProjectStatus/useProjectStatus";
import { WelcomeToProjectDialog } from "../Projects/WelcomeToProjectDialog";
import { VcsErrorPage } from "../VersionControl/VcsErrorPage";
import { OptimisticProjectFavouriteIconButton } from "./ProjectFavouriteIconButton";
export interface ProjectLayoutProps {
    spaceId: string;
    projectSlug: string;
    branchName: string | undefined;
    isNewlyCreatedProject?: string | null;
    configureProject?: boolean | null;
    defaultActionType?: string | null | undefined;
    children: (params: {
        projectContext: ProjectContextProps;
        setShowK8sStatusItem: SetRequiresKubernetesTarget;
        projectStatus?: ProjectStatus;
        scrollAreaRef?: React.RefObject<HTMLDivElement>;
    }) => ReactNode;
    scrollAreaRef?: React.RefObject<HTMLDivElement>;
    projectStatus?: ProjectStatus;
}
export function ProjectLayout({ spaceId, projectSlug, isNewlyCreatedProject, configureProject, branchName, children, defaultActionType, scrollAreaRef, projectStatus }: ProjectLayoutProps) {
    const isPageVersionControlled = useIsPageVersionControlled();
    const isKGSFeatureEnabled = useOctopusFeatureToggle("kubernetes-guided-setup", false);
    const isKGSNthProjectFeatureEnabled = useOctopusFeatureToggle("kubernetes-guided-setup-nth-project", false);
    const createPageSideNavLink = useCreatePageSideNavLink();
    return (<ErrorContextProvider>
            <ProjectLayoutInternal spaceId={spaceId} projectSlug={projectSlug} branchName={branchName} isNewlyCreatedProject={isNewlyCreatedProject} configureProject={configureProject} isPageVersionControlled={isPageVersionControlled} isKGSEnabled={isKGSFeatureEnabled} isKGSNthProjectEnabled={isKGSNthProjectFeatureEnabled} defaultActionType={defaultActionType} projectStatus={projectStatus} scrollAreaRef={scrollAreaRef} createPageSideNavLink={createPageSideNavLink}>
                {children}
            </ProjectLayoutInternal>
        </ErrorContextProvider>);
}
type ProjectLayoutInternalProps = {
    spaceId: string;
    projectSlug: string;
    branchName: string | undefined;
    isNewlyCreatedProject: string | null | undefined;
    configureProject: boolean | null | undefined;
    isPageVersionControlled: boolean;
    isKGSEnabled: boolean;
    isKGSNthProjectEnabled: boolean;
    defaultActionType?: string | null | undefined;
    scrollAreaRef?: React.RefObject<HTMLDivElement>;
    projectStatus?: ProjectStatus;
    createPageSideNavLink: CreatePageSideNavLink;
    children: (params: {
        projectContext: ProjectContextProps;
        setShowK8sStatusItem: SetRequiresKubernetesTarget;
        projectStatus?: ProjectStatus;
        scrollAreaRef?: React.RefObject<HTMLDivElement>;
    }) => ReactNode;
};
interface ProjectLayoutState extends DataBaseComponentState {
    projectGroups: ProjectGroupResource[] | null;
    hasEnvironments: boolean | null;
    newlyCreatedDialogOpen: boolean;
    configureProject: boolean;
    isFavourite: boolean | null;
    projectIntents: ProjectIntentsResource | null;
    defaultActionType: string | null | undefined;
    showTargetStatusItemBeforeProcess: boolean;
    configureProjectWizardOpen: boolean;
    resumingEnvironments: boolean;
}
export type SetRequiresKubernetesTarget = (showTargetStatusItemBeforeProcess: boolean) => void;
class ProjectLayoutInternal extends DataBaseComponent<ProjectLayoutInternalProps, ProjectLayoutState> {
    constructor(props: ProjectLayoutInternalProps) {
        super(props);
        this.state = {
            projectGroups: null,
            hasEnvironments: null,
            newlyCreatedDialogOpen: props.isNewlyCreatedProject === "true",
            configureProject: !!props.configureProject,
            isFavourite: null,
            projectIntents: null,
            defaultActionType: props.defaultActionType,
            showTargetStatusItemBeforeProcess: false,
            configureProjectWizardOpen: false,
            resumingEnvironments: false,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const projectGroups = repository.ProjectGroups.all();
            const hasEnvironments = await repository.Environments.list({ take: 0 }).then((response) => response.TotalResults !== 0);
            const favouriteProjects = hasPermission(Permission.ProjectView) ? repository.Users.getProjectFavourites() : Promise.resolve<GetFavouriteProjectsForUserBffResponse>({ Projects: [] });
            const projectIntents = await repository.Projects.getProjectIntents(this.props.projectSlug, this.props.spaceId);
            this.setState({
                projectGroups: await projectGroups,
                hasEnvironments: hasEnvironments,
                isFavourite: (await favouriteProjects).Projects.some((p) => p.Slug === this.props.projectSlug),
                projectIntents: await projectIntents,
                configureProjectWizardOpen: !this.props.isNewlyCreatedProject && !hasEnvironments,
            });
        });
    }
    toBranchSpecifier(branch: string | undefined): BranchSpecifier {
        return branch ? branch : UseDefaultBranch;
    }
    render() {
        const projectSlug = this.props.projectSlug;
        const branchName = this.props.branchName;
        const branchSpecifier = this.toBranchSpecifier(branchName);
        const setShowK8sStatusItem: SetRequiresKubernetesTarget = (showTargetStatusItemBeforeProcess: boolean) => {
            this.setState({ showTargetStatusItemBeforeProcess });
        };
        return (<ProjectContextProvider doBusyTask={this.doBusyTask} projectIdOrSlug={projectSlug} gitRef={branchSpecifier}>
                {(projectContext) => {
                const { state } = projectContext;
                const projectLogo = state.model && state.model.Links.Logo;
                if (!state.model) {
                    return (<main>
                                <HideAreaNavPanelWorkaround />
                                <SkeletonLoadingLayout errors={this.errors}/>
                            </main>);
                }
                const navigationSidebarContent = state.model.IsVersionControlled && this.props.isPageVersionControlled && state.gitRefValidationError
                    ? this.getGitErrorPanel(state.gitRefValidationError)
                    : this.props.children({
                        projectContext,
                        setShowK8sStatusItem,
                        projectStatus: this.props.projectStatus,
                        scrollAreaRef: this.props.scrollAreaRef,
                    });
                const projectSettingsURL = links.projectSettingsPage.generateUrl({ spaceId: this.props.spaceId, projectSlug: this.props.projectSlug });
                return (<main>
                            <SampleProjectTour project={state.model}>
                                {this.shouldShowConfigurationDialog() ? this.renderConfigureProjectDialog(state.model) : this.renderWelcomeToProjectDialog()}
                                {this.renderErrors()}
                                <Level2PageLayout navItems={ProjectLinks(this.props.createPageSideNavLink, branchSpecifier, state.model, state.gitRefValidationError?.Type === ValidateGitRefV2ResponseType.ConnectionFailed, state.projectExtensionSettingsMetadata)} header={{
                        breadcrumbs: this.headerBreadcrumbs(state.model),
                        logoLinkURL: projectSettingsURL,
                        logo: { href: projectLogo, accessibleName: "Project Logo" },
                        title: state.model.Name,
                        statusSection: (<ProjectStatusBar spaceId={this.props.spaceId} projectSlug={this.props.projectSlug} state={this.props.projectStatus?.state} dispatch={this.props.projectStatus?.dispatch} showBeaconOnDeploymentTargetItem enableLinks onEnvironmentsClick={() => {
                                this.setState({
                                    configureProjectWizardOpen: true,
                                    resumingEnvironments: true,
                                });
                                this.renderConfigureProjectDialog(state.model);
                            }}/>),
                        titleIcon: (<ProjectPageHeaderTitleIcon projectId={state.model.Id} projectName={state.model.Name} isDisabled={state.model.IsDisabled} isFavourite={this.state.isFavourite} onIsFavouriteChanged={this.onIsFavouriteChanged} isVersionControlled={state.model.IsVersionControlled}/>),
                    }} callout={<ResilientPipelineCallouts />} tourProgress={<SampleProjectTourResume />} description={<Markdown markup={state.model.Description}/>}>
                                    {navigationSidebarContent}
                                </Level2PageLayout>
                                <GettingStartedFooter />
                            </SampleProjectTour>
                        </main>);
            }}
            </ProjectContextProvider>);
    }
    private getGitErrorPanel(error: ValidateGitRefV2Response) {
        return <VcsErrorPage error={error} projectSlug={this.props.projectSlug} spaceId={this.props.spaceId}/>;
    }
    renderErrors() {
        const errors = this.state && this.errors;
        if (!errors) {
            return null;
        }
        return <ErrorPanel message={errors.message} errors={errors.errors} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink}/>;
    }
    private renderConfigureProjectDialog(project: ProjectResource) {
        if (!this.props.isKGSEnabled || !this.props.isKGSNthProjectEnabled) {
            return (<NewlyCreatedProjectWizardDialog open={this.state.newlyCreatedDialogOpen || this.state.configureProject} close={() => this.setState({ newlyCreatedDialogOpen: false, configureProject: false })} showCreateEnvironments={this.shouldShowCreateEnvironments()} spaceId={this.props.spaceId}/>);
        }
        if (!this.props.projectStatus?.state.guidedSetupResumed && this.state.configureProjectWizardOpen) {
            this.props.projectStatus?.dispatch({ type: projectStatusActions.guidedSetupResumed });
        }
        return (<NewProjectWizard open={this.state.configureProjectWizardOpen} fullScreen={true} spaceId={project.SpaceId} projectSlug={project.Slug} projectGroupId={project.ProjectGroupId} doBusyTask={this.doBusyTask} isOnboarding={false} close={() => this.setState({ configureProjectWizardOpen: false })} skipWizardNavigation={true} projectStatus={this.props.projectStatus} resumingPage={this.state.resumingEnvironments ? "Environments" : undefined} onResumedPage={() => this.setState({ resumingEnvironments: false })}/>);
    }
    private renderWelcomeToProjectDialog() {
        return (<WelcomeToProjectDialog isOpen={this.state.newlyCreatedDialogOpen} isVersionControlled={!!this.state.projectIntents?.IsVcsProject} selectedStep={this.state.defaultActionType} close={() => this.setState({ newlyCreatedDialogOpen: false })}/>);
    }
    private headerBreadcrumbs(project: ProjectResource) {
        const foundProjectGroup = this.state.projectGroups && this.state.projectGroups.find((pg) => pg.Id === project.ProjectGroupId);
        if (foundProjectGroup) {
            return [
                { label: "Projects", pageUrl: links.projectsPage.generateUrl({ spaceId: project.SpaceId }) },
                { label: foundProjectGroup.Name, pageUrl: links.projectsPage.generateUrl({ spaceId: project.SpaceId }, { searchValue: foundProjectGroup.Name }) },
            ];
        }
        return [{ label: "Projects", pageUrl: links.projectsPage.generateUrl({ spaceId: project.SpaceId }) }];
    }
    private shouldShowCreateEnvironments() {
        const hasEnvironmentCreatePermission = isAllowed({ permission: Permission.EnvironmentCreate });
        return hasEnvironmentCreatePermission && !this.state.hasEnvironments;
    }
    private shouldShowConfigurationDialog() {
        if (!this.props.isKGSEnabled || !this.props.isKGSNthProjectEnabled) {
            return true;
        }
        return !this.state.newlyCreatedDialogOpen && this.shouldShowCreateEnvironments();
    }
    private onIsFavouriteChanged = (projectId: string, isFavourite: boolean) => {
        return this.doBusyTask(async () => {
            if (isFavourite) {
                await repository.Users.createProjectFavourite(projectId);
            }
            else {
                await repository.Users.deleteProjectFavourite(projectId);
            }
            this.setState((state) => ({ ...state, isFavourite }));
        });
    };
    static displayName = "ProjectLayoutInternal";
}
interface ProjectPageHeaderTitleIconProps {
    projectId: string;
    projectName: string;
    isDisabled: boolean;
    isVersionControlled: boolean;
    isFavourite: boolean | null;
    onIsFavouriteChanged: (projectId: string, isFavourite: boolean) => Promise<boolean>;
}
function ProjectPageHeaderTitleIcon({ projectId, projectName, isDisabled, isVersionControlled, isFavourite, onIsFavouriteChanged }: ProjectPageHeaderTitleIconProps) {
    const isLargerThanIpad = useIsLargerThanIpadResolution();
    const setIsFavourite = (isFavourite: boolean) => onIsFavouriteChanged(projectId, isFavourite);
    return (<>
            {isDisabled && (<Chip backgroundColor={themeTokens.color.background.tertiary} labelColor={themeTokens.color.text.secondary} noMargin noTooltip>
                    Disabled
                </Chip>)}
            {isFavourite !== null && <OptimisticProjectFavouriteIconButton isFavourite={isFavourite} setIsFavourite={setIsFavourite} projectName={projectName}/>}
            {isVersionControlled && isLargerThanIpad ? <CodeBranchIcon size={20} color={themeTokens.color.icon.tertiary}/> : undefined}
        </>);
}
/*
Currently the routes we have for branch vs non branch based pages co-exist in the same menu.
This means that the `ProjectLayout` currently gets unmounted and remounted between these
routes. This is especially noticeable for the vertical navigation as we show the area nav
panel when the in page menu is not visible. This is exactly what happens when we navigate
between a git based page vs a non git based page when vertical navigation is enabled.
This workaround provides us with a cheap way to make this transition less jarring until
we can find a way to properly refactor the layout to either be mounted higher up in the
tree OR we consolidate routes in some way (possibly using URL query parameters instead).
 */
function HideAreaNavPanelWorkaround() {
    const setVisible = useSetInPageNavVisible();
    React.useEffect(() => {
        setVisible?.(true);
        return () => setVisible?.(false);
    }, [setVisible]);
    return null;
}
