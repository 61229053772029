import { css } from "@emotion/css";
import { UserCircleIcon } from "@octopusdeploy/design-system-icons";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import type { MachineTenantSummaryResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import cn from "classnames";
import * as React from "react";
import InternalLink from "~/components/Navigation/InternalLink";
import styles from "../style.module.less";
interface TenantContentRowProps {
    tenant: TenantResource;
    tenantSummaries: MachineTenantSummaryResource;
}
export const TenantContentRow = ({ tenant, tenantSummaries }: TenantContentRowProps) => {
    const totalMachines = (tenantSummaries && tenantSummaries[tenant.Id]) || 0;
    const ariaLabel = `Tenant ${tenant.Name} has ${totalMachines.toLocaleString()} deployment target${totalMachines === 1 ? "" : "s"}`;
    return (<div className={cn(styles.tenantRowsContainer, { [disabledStyles]: tenant.IsDisabled })} key={tenant.Id} tabIndex={0} aria-label={ariaLabel}>
            <div className={styles.tenantIcon}>
                <UserCircleIcon size={16} color={themeTokens.color.icon.primary}/>
            </div>
            <div className={styles.tenantName}>
                <InternalLink to={links.deploymentTargetsPage.generateUrl({ spaceId: tenant.SpaceId }, { tenantIds: [tenant.Id] })}>{tenant.Name}</InternalLink>
            </div>
            <div className={styles.tenantMachinesCount}>{totalMachines.toLocaleString()}</div>
        </div>);
};
const disabledStyles = css({
    opacity: 0.4,
});
