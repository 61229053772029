import type { ActivityElement } from "@octopusdeploy/octopus-server-client";
import { ActivityStatus } from "@octopusdeploy/octopus-server-client";
import React from "react";
import TimeFromNowLabel from "~/components/TimeLabels/TimeFromNowLabel";
export const LastUpdateMessage = (props: {
    lastObjectStatusUpdate: string;
    activityElement: Pick<ActivityElement, "Status" | "Ended" | "Started">;
}) => {
    if (props.activityElement.Status === ActivityStatus.Canceled) {
        return <>This task was canceled prior to starting</>;
    }
    if (props.activityElement.Ended) {
        return <>Object status check complete</>;
    }
    return (<>
            Last Updated: <TimeFromNowLabel time={props.lastObjectStatusUpdate}/>
        </>);
};
