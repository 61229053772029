import { TriggerActionCategory } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { useAllFeedTypesForExternalFeeds } from "~/areas/projects/components/Triggers/Feed/useAllFeedTypesForExternalFeeds";
import EditFeedTrigger from "./EditReleaseCreationFeedTrigger";
interface EditReleaseCreationFeedTriggersPageProps {
    spaceId: string;
    projectSlug: string;
    triggerId: string;
}
export function EditReleaseCreationFeedTriggersPage({ spaceId, projectSlug, triggerId }: EditReleaseCreationFeedTriggersPageProps) {
    const triggerFromAllFeeds = useAllFeedTypesForExternalFeeds();
    return <EditFeedTrigger create={false} triggerActionCategory={TriggerActionCategory.Deployment} spaceId={spaceId} projectSlug={projectSlug} triggerId={triggerId} triggerFromAllFeeds={triggerFromAllFeeds}/>;
}
