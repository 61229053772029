/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { NavigationButton, NavigationButtonType } from "@octopusdeploy/design-system-components";
import type { SimpleMenuItem, PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { logger } from "@octopusdeploy/logging";
import type { PerformanceConfigurationResource, ProjectResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { DashboardRenderMode, Permission, Repository, TenantedDeploymentMode } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { isEqual } from "lodash";
import * as React from "react";
import type { ActionEvent, AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action, ProjectAnalyticView, useProjectScopedAnalyticActionDispatch } from "~/analytics/Analytics";
import type { RenderDashboardProps } from "~/areas/dashboard/DashboardOverview/DashboardOverviewPage";
import type { DeploymentOverviewFilters } from "~/areas/projects/components/DashboardDataSource/DataCube";
import { DimensionTypes } from "~/areas/projects/components/DashboardDataSource/DataCube";
import TenantedProjectDashboardDataSource from "~/areas/projects/components/DashboardDataSource/TenantedProjectDashboardDataSource";
import UntenantedProjectDashboardDataSource from "~/areas/projects/components/DashboardDataSource/UntenantedProjectDashboardDataSource";
import OverviewFilters from "~/areas/projects/components/DeploymentsOverview/OverviewFilters";
import useDeploymentsOverviewFilters from "~/areas/projects/components/DeploymentsOverview/hooks/useDeploymentsOverviewFilters";
import type { SampleProjectTourContextProps } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTour";
import { SampleProjectTourContext } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTour";
import SampleProjectTourStartTourOnMount from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTourStartTourOnMount";
import { ProjectPageTitleAccessory } from "~/areas/projects/components/ProjectPageTitleAccessory";
import { useProjectContext } from "~/areas/projects/context/ProjectContext";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context/withProjectContext";
import type { PageState } from "~/areas/tenants/components/Paging/usePageState";
import { usePageState } from "~/areas/tenants/components/Paging/usePageState";
import { repository } from "~/clientInstance";
import ActionList from "~/components/ActionList";
import { PageContent } from "~/components/PageContent/PageContent";
import { PageLoading } from "~/components/PageContent/PageLoading";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import SampleProcessButtonWithRedirect from "~/components/ProjectBasedActivation/SampleProcessButtonWithRedirect";
import { useRequiredContext } from "~/hooks/index";
import { RecentProjects } from "~/utils/RecentProjects/RecentProjects";
import type { DataBaseComponentState } from "../../../../components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "../../../../components/DataBaseComponent/DataBaseComponent";
import ProjectDashboard from "../ProjectDashboard";
import Onboarding from "./Onboarding";
interface OverviewState extends DataBaseComponentState {
    project: ProjectResource;
    tenants: TenantResource[];
    hasSteps: boolean;
    hasReleases: boolean;
    failedChecks: Array<{
        permission: Permission;
        isNotAllowed: boolean;
    }>;
    dashboardRenderMode: DashboardRenderMode;
    isDashboardLoaded: boolean;
}
interface DeploymentsOverviewPropsInternal extends WithProjectContextInjectedProps {
    sampleProjectTourContext: SampleProjectTourContextProps;
    dispatchAction: AnalyticActionDispatcher;
    filters: DeploymentOverviewFilters;
    setFilters: (newFilters: DeploymentOverviewFilters) => void;
    pageState: PageState;
    setPageState: (newPageNumber: number, newPageSize: number) => void;
}
const pageTitle = "Deployments";
class DeploymentsOverviewInternal extends DataBaseComponent<DeploymentsOverviewPropsInternal, OverviewState> {
    constructor(props: DeploymentsOverviewPropsInternal) {
        super(props);
        this.state = {
            hasSteps: false,
            hasReleases: false,
            project: null!,
            tenants: [],
            failedChecks: [],
            dashboardRenderMode: null!,
            isDashboardLoaded: false,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const tenants = isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? await repository.Tenants.all() : [];
            const { model: project, projectContextRepository } = this.props.projectContext.state;
            const performanceConfiguration = await repository.PerformanceConfiguration.get();
            RecentProjects.getInstance().UpdateAccessedProjectIntoLocalStorage(project.Id);
            const requiredPermissions = [
                { permission: Permission.ProjectView, project: project.Id, tenant: "*", projectGroup: project.ProjectGroupId },
                { permission: Permission.ReleaseView, project: project.Id, tenant: "*", projectGroup: project.ProjectGroupId },
                { permission: Permission.EnvironmentView, wildcard: true },
            ];
            const failedChecks = requiredPermissions
                .map((check) => ({
                permission: check.permission,
                isNotAllowed: !isAllowed(check),
            }))
                .filter((check) => check.isNotAllowed);
            if (failedChecks.length > 0) {
                this.setState({
                    project,
                    failedChecks,
                });
                return;
            }
            let hasSteps = false;
            try {
                const projectStatus = await repository.Projects.getProjectStatus(project.Slug, project.SpaceId);
                hasSteps = projectStatus.HasSteps;
            }
            catch (exception) {
                logger.error(exception, "Failed to get status for {project}", { project });
            }
            let hasReleases = false;
            try {
                const releases = await repository.Projects.getReleases(project, { take: 1 });
                hasReleases = releases.TotalResults > 0;
            }
            catch (exception) {
                logger.error(exception, "Failed to get releases for {project}", { project });
            }
            this.setState({
                project,
                tenants,
                hasSteps,
                hasReleases,
                dashboardRenderMode: this.getDashboardRenderMode(project, performanceConfiguration),
            });
        });
    }
    render() {
        if (!this.state.project) {
            return <PageLoading loadingTitle={pageTitle}/>;
        }
        if (this.state.failedChecks.length > 0) {
            return (<PageContent busy={this.state.busy} errors={this.errors} header={{ title: pageTitle, contextSelector: <ProjectPageTitleAccessory /> }} callout={{ type: "information", title: "Permission required", content: `The ${this.state.failedChecks[0].permission} permission is required to view project overview details` }}>
                    {null}
                </PageContent>);
        }
        if (this.state.project && !this.state.hasSteps && !this.state.hasReleases) {
            return (<PageContent busy={this.state.busy} errors={this.errors} header={{ title: pageTitle, contextSelector: <ProjectPageTitleAccessory /> }}>
                    <Onboarding project={this.state.project} actionButtons={<PermissionCheck permission={Permission.ProcessEdit} project={this.state.project.Id} tenant="*">
                                <ActionList alignStart={true} actions={[
                        <NavigationButton label="Create Process" href={links.deploymentProcessStepsPage.generateUrl({ spaceId: this.state.project.SpaceId, projectSlug: this.state.project.Slug }, { stepTemplates: true })} type={NavigationButtonType.Primary} onClick={() => {
                                const ev: ActionEvent = {
                                    action: Action.Add,
                                    resource: "Deployment Process",
                                };
                                this.props.dispatchAction("Define your Deployment Process", ev);
                            }}/>,
                        <SampleProcessButtonWithRedirect project={this.props.projectContext.state.model}/>,
                    ]}/>
                            </PermissionCheck>}/>
                    <ProjectAnalyticView resource="Project" projectId={this.state.project.Id}/>
                </PageContent>);
        }
        let primaryPageAction: PrimaryPageAction | undefined = undefined;
        const createReleaseIsAllowed = this.state.hasSteps || this.state.project?.IsVersionControlled;
        if (createReleaseIsAllowed) {
            const createReleaseButton: PrimaryPageAction = {
                type: "navigate",
                label: "Create Release",
                path: links.createReleasePage.generateUrl({ spaceId: this.state.project.SpaceId, projectSlug: this.state.project.Slug }),
                onClick: () => this.props.dispatchAction("Create a release", { resource: "Create Release", action: Action.Add }),
                hasPermissions: isAllowed({ permission: Permission.ReleaseCreate, project: this.state.project.Id, tenant: "*" }),
            };
            primaryPageAction = createReleaseButton;
        }
        const oppositeDashboardSetting = this.state.dashboardRenderMode === DashboardRenderMode.VirtualizeColumns ? DashboardRenderMode.VirtualizeRowsAndColumns : DashboardRenderMode.VirtualizeColumns;
        const isAllowedToViewAudit = isAllowed({
            permission: Permission.EventView,
            wildcard: true,
        });
        const overflowActions: SimpleMenuItem[] = [
            {
                type: "button",
                label: this.state.dashboardRenderMode === DashboardRenderMode.VirtualizeColumns ? "Switch to fast rendering" : "Switch to full rendering",
                title: "Full rendering will render all rows, fast rendering will render only visible rows",
                onClick: () => this.setDashboardRenderMode(oppositeDashboardSetting),
            },
        ];
        if (isAllowedToViewAudit) {
            overflowActions.push({
                type: "internal-link",
                label: "Audit Trail",
                path: links.auditPage.generateUrl({ projects: [this.state.project.Id] }),
            });
        }
        return (<>
                <PageContent header={{ title: pageTitle, primaryAction: primaryPageAction, overflowActions, contextSelector: <ProjectPageTitleAccessory /> }} busy={this.state.isDashboardLoaded ? false : this.state.busy} errors={this.errors} fullWidth={true}>
                    {this.state.project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted ? (<UntenantedProjectDashboardDataSource project={this.state.project} render={this.renderDashboard} doBusyTask={this.doBusyTask} onDashboardLoad={() => this.setState({ isDashboardLoaded: true })}/>) : (<TenantedProjectDashboardDataSource project={this.state.project} filters={this.props.filters} render={this.renderDashboard} doBusyTask={this.doBusyTask} onDashboardLoad={(filters, pageNumber, pageSize) => this.onDashboardLoad(filters, pageNumber, pageSize)} pageNumber={this.props.pageState.pageNumber} pageSize={this.props.filters.groupBy ? Repository.takeAll : this.props.pageState.pageSize}/>)}
                </PageContent>
            </>);
    }
    private onDashboardLoad(filters: DeploymentOverviewFilters, pageNumber: number, pageSize: number) {
        // Requests with outdated parameters should be ignored, otherwise the loading bar will be hidden even though the latest request is ongoing
        const isQueryParamsUpToDate = isEqual(filters, this.props.filters) && pageNumber === this.props.pageState.pageNumber && pageSize === this.props.pageState.pageSize;
        if (isQueryParamsUpToDate) {
            this.setState({ isDashboardLoaded: true });
        }
    }
    private showLoadingDashboard(dataSource: RenderDashboardProps) {
        return (<ProjectDashboard spaceId={this.props.projectContext.state.model.SpaceId} cube={dataSource.cube!} filters={this.props.filters} allowDeployments={true} dashboardRenderMode={this.state.dashboardRenderMode} project={this.state.project} pageNumber={this.props.pageState.pageNumber} pageSize={this.props.filters.groupBy ? Repository.takeAll : this.props.pageState.pageSize} totalItems={dataSource.totalItems} onPagingSelectionChange={(newPageNumber, newPageSize) => this.updatePage(newPageNumber, newPageSize)}/>);
    }
    private updatePage(newPageNumber: number, newPageSize: number) {
        this.setState({ isDashboardLoaded: false });
        this.props.setPageState(newPageNumber, newPageSize);
    }
    private localStorageKeyForVirtualDashboard(project: ProjectResource): string {
        return "virtualDashboard" + project.Id;
    }
    private getDashboardRenderMode(project: ProjectResource, performanceConfiguration: PerformanceConfigurationResource): DashboardRenderMode {
        const localRenderMode = localStorage.getItem(this.localStorageKeyForVirtualDashboard(project));
        if (localRenderMode !== null) {
            const typedRenderModeString = localRenderMode as keyof typeof DashboardRenderMode;
            return DashboardRenderMode[typedRenderModeString];
        }
        return performanceConfiguration.DefaultDashboardRenderMode;
    }
    private setDashboardRenderMode(value: DashboardRenderMode) {
        localStorage.setItem(this.localStorageKeyForVirtualDashboard(this.state.project), value.toString());
        this.setState({ dashboardRenderMode: value });
    }
    private filtersUpdated = (filters: DeploymentOverviewFilters) => {
        this.setState({ isDashboardLoaded: false });
        this.props.setPageState(1, this.props.pageState.pageSize);
        this.props.setFilters(filters);
    };
    private renderDashboard = (dataSource: RenderDashboardProps) => {
        if (!dataSource.cube) {
            return <></>;
        }
        return (<SampleProjectTourStartTourOnMount>
                <OverviewFilters cube={dataSource.cube} tenants={this.state.tenants} filters={this.props.filters} doBusyTask={this.doBusyTask} defaultFilter={this.createDefaultFilter(this.state.project)} onFiltersUpdated={this.filtersUpdated} project={this.state.project} render={() => this.showLoadingDashboard(dataSource)}/>
            </SampleProjectTourStartTourOnMount>);
    };
    private createDefaultFilter = (project: ProjectResource): DeploymentOverviewFilters => {
        return {
            [DimensionTypes.Project]: {
                [project.Id]: true,
            },
            columnDimension: DimensionTypes.Environment,
            groupBy: project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted ? DimensionTypes.Channel : DimensionTypes.None,
            rowDimension: project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted ? DimensionTypes.Release : DimensionTypes.Tenant,
        };
    };
    static displayName = "DeploymentsOverviewInternal";
}
const deploymentsOverviewPagingSelectionKey = "octoDeploymentsOverviewPagingSelection";
export function DeploymentsOverview() {
    const projectContext = useProjectContext();
    const sampleProjectTourContext = useRequiredContext(SampleProjectTourContext);
    const dispatchAction = useProjectScopedAnalyticActionDispatch(projectContext.state.model.Id);
    const [filters, setFilters] = useDeploymentsOverviewFilters(projectContext.state.model);
    const [pageState, setPageState] = usePageState(deploymentsOverviewPagingSelectionKey);
    return (<DeploymentsOverviewInternal filters={filters} setFilters={setFilters} projectContext={projectContext} sampleProjectTourContext={sampleProjectTourContext} dispatchAction={dispatchAction} pageState={pageState} setPageState={setPageState}/>);
}
