import { css, cx } from "@emotion/css";
import { Button } from "@octopusdeploy/design-system-components";
import { fontSize, fontWeight, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { TenantResource, EnvironmentResource, RunbooksDashboardItemResource, ProjectResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import { EnvironmentChip, TenantChip } from "~/components/Chips";
import InternalLink from "~/components/Navigation/InternalLink";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { DataTable, DataTableBody, DataTableFooter, DataTableFooterColumn, DataTableHeader, DataTableHeaderColumn, DataTableRow, DataTableRowColumn, DataTableToolsRow } from "~/primitiveComponents/dataDisplay/DataTable";
import { GitRefChip } from "../../Releases/GitRefChip/GitRefChip";
import { RunbookRunTaskStatusDetails } from "./RunbookRunTaskStatusDetails";
interface RunbookRunListProps {
    project: ProjectResource;
    runs: RunbooksDashboardItemResource[];
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    canLoadMore: boolean;
    onLoadMore: () => void;
}
export function RunbookRunList({ project, runs, environments, tenants, canLoadMore, onLoadMore }: RunbookRunListProps) {
    const hasDatabaseRunbookRuns = runs.some((run) => !run.GitReference);
    const hasGitRunbookRuns = runs.some((run) => run.GitReference);
    const columns = 4 + (hasDatabaseRunbookRuns ? 1 : 0) + (hasGitRunbookRuns ? 1 : 0);
    const styles = {
        loadMoreContainer: css({
            padding: space[16],
        }),
        loadMoreActions: css({
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            flexDirection: "column",
            alignItems: "center",
        }),
        loadMoreSubtitle: css({
            color: themeTokens.color.text.secondary,
            fontSize: fontSize["xSmall"],
        }),
    };
    return (<div>
            <DataTable>
                <DataTableHeader>
                    <DataTableHeaderColumn>Run</DataTableHeaderColumn>
                    {hasDatabaseRunbookRuns && <DataTableHeaderColumn>Snapshot</DataTableHeaderColumn>}
                    {hasGitRunbookRuns && <DataTableHeaderColumn>Git</DataTableHeaderColumn>}
                    <DataTableHeaderColumn>Environment</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Duration</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Run by</DataTableHeaderColumn>
                </DataTableHeader>
                <DataTableBody>
                    {runs.map((run) => (<RunbookRun project={project} run={run} environments={environments} tenants={tenants} hasGitRunbookRuns={hasGitRunbookRuns} hasDatabaseRunbookRuns={hasDatabaseRunbookRuns}/>))}
                </DataTableBody>
                {canLoadMore ? (<DataTableFooter>
                        <DataTableToolsRow>
                            <DataTableFooterColumn colSpan={columns}>
                                <div className={styles.loadMoreContainer}>
                                    <div className={styles.loadMoreActions}>
                                        <Button importance="secondary" label="Load more" onClick={onLoadMore}/>
                                        <div className={styles.loadMoreSubtitle}>Or use filters to narrow the search results</div>
                                    </div>
                                </div>
                            </DataTableFooterColumn>
                        </DataTableToolsRow>
                    </DataTableFooter>) : null}
            </DataTable>
        </div>);
}
interface RunbookRunProps {
    project: ProjectResource;
    run: RunbooksDashboardItemResource;
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    hasGitRunbookRuns: boolean;
    hasDatabaseRunbookRuns: boolean;
}
function RunbookRun({ project, run, environments, tenants, hasGitRunbookRuns: hasGitRunbooks, hasDatabaseRunbookRuns: hasDatabaseRunbooks }: RunbookRunProps) {
    const spaceAwareNavigation = useSpaceAwareNavigation();
    const environment = environments.find((x) => x.Id === run.EnvironmentId);
    const environmentChip = environment && <EnvironmentChip environmentName={environment.Name}/>;
    const tenant = tenants.find((x) => x.Id === run.TenantId);
    const tenantChip = tenant && <TenantChip tenantName={tenant.Name}/>;
    const styles = {
        middle: css({
            verticalAlign: "middle !important",
        }),
        untenanted: css({
            color: themeTokens.color.text.tertiary,
            fontWeight: fontWeight[400],
            fontSize: fontSize.small,
        }),
        full: css({
            display: "block",
            width: "100%",
        }),
        row: css({
            "&:hover": {
                background: themeTokens.color.background.primary.hovered,
                cursor: "pointer",
            },
        }),
    };
    const snapshotLink = links.runbookSnapshotRedirect.generateUrl({
        spaceId: project.SpaceId,
        runbookSnapshotId: run.RunbookSnapshotId,
    });
    const runDetailsLink = links.projectRunbookRunDetailPage.generateUrl({
        spaceId: project.SpaceId,
        projectSlug: project.Slug,
        runbookId: run.RunbookId,
        runbookSnapshotId: run.RunbookSnapshotId,
        runbookRunId: run.Id,
    });
    return (<DataTableRow className={cx(styles.middle, styles.row)} onClick={() => spaceAwareNavigation.navigate(runDetailsLink)}>
            <DataTableRowColumn className={styles.middle}>
                <RunbookRunTaskStatusDetails run={run} link={runDetailsLink} noPadding/>
            </DataTableRowColumn>
            {hasDatabaseRunbooks && <DataTableRowColumn className={styles.middle}>{!run.GitReference ? <InternalLink to={snapshotLink}>{run.RunbookSnapshotName}</InternalLink> : ""}</DataTableRowColumn>}
            {hasGitRunbooks && <DataTableRowColumn className={styles.middle}>{run.GitReference ? <GitRefChip vcsRef={run.GitReference} showCommit/> : ""}</DataTableRowColumn>}
            <DataTableRowColumn className={styles.middle}>
                {environmentChip} {tenantChip}
            </DataTableRowColumn>
            <DataTableRowColumn className={styles.middle}>{run.Duration}</DataTableRowColumn>
            <DataTableRowColumn className={styles.middle}>{run.RunBy}</DataTableRowColumn>
        </DataTableRow>);
}
