import { PlayIcon } from "@octopusdeploy/design-system-icons";
import cn from "classnames";
import * as React from "react";
import type { ExternalLinkProps } from "./ExternalLink";
import ExternalLink from "./ExternalLink";
import styles from "./style.module.less";
interface ExternalVideoLinkProps extends ExternalLinkProps {
    hideVideoIcon?: boolean;
}
const ExternalVideoLink: React.SFC<ExternalVideoLinkProps> = (props: ExternalVideoLinkProps) => {
    // Display default CTA when there is no children
    const defaultText = "Watch in action";
    const { className, ...restProps } = props;
    return (<ExternalLink className={cn([styles.externalVideoLink, className])} trackAnalytics={true} {...restProps}>
            {!props.hideVideoIcon && <PlayIcon size={20}/>}
            {React.Children.count(props.children) > 0 ? props.children : defaultText}
        </ExternalLink>);
};
ExternalVideoLink.displayName = "ExternalVideoLink"
export default ExternalVideoLink;
