import { Callout } from "@octopusdeploy/design-system-components";
import { isDeploymentProcessResource, isRunbookProcessResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { isDeploymentOrRunbookProcessIdentifier } from "~/areas/projects/components/Process/types";
import type { Errors } from "~/components/DataBaseComponent";
import FileUploadDropzone from "~/primitiveComponents/form/FileUploadDragDrop/FileUploadDragDrop";
import type { ProcessContextProps } from "../ProcessContext";
import styles from "./style.module.less";
export const DevToolbarProcessUpload: React.FC<{
    processContext: ProcessContextProps;
}> = (props) => {
    const [file, setFile] = React.useState<File | null | undefined>(null);
    const [error, setError] = React.useState<Errors>();
    const [success, setSuccess] = React.useState<boolean | null>(null);
    const tryParseJson = (rawJson: string) => {
        try {
            const process = JSON.parse(rawJson);
            return process;
        }
        catch (err) {
            return new Error("Unable to parse json: " + err);
        }
    };
    const saveProcess = async (rawJson: string) => {
        const process = tryParseJson(rawJson);
        if (process instanceof Error) {
            setError({
                message: process.message,
                errors: [process.message],
                fieldErrors: {},
            });
            return;
        }
        const model = props.processContext.state.cleanModel;
        if (model instanceof Error) {
            setError({
                message: model.message,
                errors: [model.message],
                fieldErrors: {},
            });
            return;
        }
        const existingProcess = props.processContext.selectors.getProcessResource();
        const existingProcessIdentifier = props.processContext.state.processIdentifier;
        process.Id = existingProcessIdentifier.id;
        if (isDeploymentOrRunbookProcessIdentifier(existingProcessIdentifier)) {
            process.SpaceId = existingProcessIdentifier.spaceId;
            process.ProjectId = existingProcessIdentifier.projectId;
        }
        if (isDeploymentProcessResource(existingProcess) || isRunbookProcessResource(existingProcess)) {
            process.Version = existingProcess.Version;
            process.Links["Self"] = existingProcess.Links["Self"];
        }
        await props.processContext.actions.saveOnServer(process, (errors) => {
            setError(errors);
        }, () => {
            setSuccess(true);
            setFile(null);
            const fiveSeconds = 5 * 1000;
            setTimeout(() => {
                setSuccess(null);
            }, fiveSeconds);
        });
    };
    return (<div className={styles.uploadContainer}>
            <FileUploadDropzone label={"Drag and drop a process json file or select to browse"} onFilesChanged={(file) => {
            setError(undefined);
            setFile(file[0]);
            if (file && file.length > 0) {
                loadFile(file[0], saveProcess);
            }
        }} showAlerts={false} showFileChips={false}/>
            <DeploymentProcessUploadCallout success={success} errors={error}/>
        </div>);
};
DevToolbarProcessUpload.displayName = "DevToolbarProcessUpload"
const loadFile = (file: File, saveProcess: (rawJson: string) => Promise<void>) => {
    const reader = new FileReader();
    const DONE = 2;
    reader.onload = async (event) => {
        if (event && event.target && event.target.readyState === DONE) {
            if (typeof event.target.result === "string") {
                await saveProcess(event.target.result);
            }
            else {
                throw new Error(`Unexpected type returned when reading file. Expected 'string' but got '${typeof event.target.result}'.`);
            }
        }
    };
    reader.readAsText(file);
};
const DeploymentProcessUploadCallout: React.FC<{
    success: boolean | null;
    errors: Errors | undefined;
}> = (props) => {
    if (props.success) {
        return <DeploymentProcessUploadSuccessCallout />;
    }
    if (props.errors) {
        return <DeploymentProcessUploadErrorCallout errors={props.errors}/>;
    }
    return <DeploymentProcessUploadInfoCallout />;
};
DeploymentProcessUploadCallout.displayName = "DeploymentProcessUploadCallout"
const DeploymentProcessUploadSuccessCallout: React.FC<{}> = (props) => {
    return (<Callout type={"success"} title="Success">
            Process uploaded
        </Callout>);
};
DeploymentProcessUploadSuccessCallout.displayName = "DeploymentProcessUploadSuccessCallout"
const DeploymentProcessUploadErrorCallout: React.FC<{
    errors: Errors;
}> = (props) => {
    return (<Callout type={"danger"} title="Failed">
            Upload failed: {props.errors.message}
        </Callout>);
};
DeploymentProcessUploadErrorCallout.displayName = "DeploymentProcessUploadErrorCallout"
const DeploymentProcessUploadInfoCallout: React.FC<{}> = (props) => {
    return (<Callout type={"warning"} title={"Experimental feature"}>
            This feature is designed for support. Importing a deployment process may cause errors that will break things. Use with caution.
        </Callout>);
};
DeploymentProcessUploadInfoCallout.displayName = "DeploymentProcessUploadInfoCallout"
export default DevToolbarProcessUpload;
