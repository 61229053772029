import { CircularProgress, Snackbar } from "@octopusdeploy/design-system-components";
import type { PageAction, PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { MessagesIcon, PenFieldIcon } from "@octopusdeploy/design-system-icons";
import { IsDefaultBranch, Permission, ProjectContextRepository, canCommitTo, isProtectedBranch, toGitCommitShort } from "@octopusdeploy/octopus-server-client";
import type { ProjectResource, GitRefResource, ProcessType, GitCommit, GitBranchResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { Suspense, useState } from "react";
import { Action, ActionStatus, useProjectScopedAnalyticActionDispatch } from "~/analytics/Analytics";
import { client, repository } from "~/clientInstance";
import { useDoBusyTaskEffect, type Errors } from "~/components/DataBaseComponent";
import { DataBaseComponent, type DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import type { PrimarySavePageAction } from "~/components/FormPaperLayout/LegacyForm";
import { LegacyForm } from "~/components/FormPaperLayout/LegacyForm";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { createGlobalRequestContext } from "~/globalRequestContext";
import { GetCommitButton, type GetCommitButtonProps } from "../VersionControl/CommitButton";
import { getFormattedCommitMessage, type CommitMessageWithDetails } from "../VersionControl/CommitMessageWithDetails";
import { CodeEditorFeedbackDialog } from "./CodeEditor/CodeEditorFeedbackDialog";
import { useDispatchCodeEditorFeedback, useDispatchCommitCodeEditorChanges, useDispatchSwitchToFormEditor } from "./CodeEditor/ProcessCodeEditorAnalytics";
import { ProcessCodeEditorCallout } from "./CodeEditor/ProcessCodeEditorCallout";
import { isProcessCodeEditorEnabled, useProcessCodeEditorState } from "./CodeEditor/isProcessCodeEditorEnabled";
import { ProcessPaperLayout } from "./CustomPaperLayouts/ProcessPaperLayout";
interface ProcessCodePageProps {
    project: ProjectResource;
    processType: ProcessType;
    gitRef?: GitRefResource;
    changeGitRef: (gitRef: string) => void;
    errors?: Errors;
    busy?: Promise<void>;
    doBusyTask: DoBusyTask;
}
type ProcessCodeModel = {
    ocl: string | undefined;
};
type OpenCommitDialog = () => void;
const defaultCommitMessage = "Update deployment process";
const ProcessCodeEditor = React.lazy(() => import("./CodeEditor/ProcessCodeEditor"));
const ProcessCodeLayoutInternal = ({ project, processType, gitRef, changeGitRef, errors, busy, doBusyTask }: ProcessCodePageProps) => {
    const dispatchAction = useProjectScopedAnalyticActionDispatch(project.Id);
    const dispatchSwitchToFormEditor = useDispatchSwitchToFormEditor();
    const dispatchCodeEditorFeedback = useDispatchCodeEditorFeedback();
    const dispatchCommitCodeEditorChanges = useDispatchCommitCodeEditorChanges();
    const [cleanModel, setCleanModel] = useState<ProcessCodeModel>({
        ocl: undefined,
    });
    const [model, setModel] = useState<ProcessCodeModel>({
        ocl: undefined,
    });
    const [commitMessage, setCommitMessage] = useState<CommitMessageWithDetails>({
        summary: "",
        details: "",
    });
    const [lastCommit, setLastCommit] = useState<GitCommit | undefined>(undefined);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [creatingNewBranch, setCreatingNewBranch] = useState<boolean>(false);
    const openCommitDialog = React.useRef<OpenCommitDialog | undefined>(undefined);
    const { disableProcessCodeEditorByDefault, showCallout } = useProcessCodeEditorState();
    const navigation = useSpaceAwareNavigation();
    const { isOpen: isFeedbackDialogOpen, openDialog: openFeedbackDialog, closeDialog: closeFeedbackDialog } = useDialogTrigger();
    useDoBusyTaskEffect(doBusyTask, async () => {
        const projectRepository = new ProjectContextRepository(client, project, gitRef, createGlobalRequestContext("ProjectContext"));
        const response = await projectRepository.DeploymentProcesses.getRawOcl();
        setCleanModel({ ocl: response.Ocl });
        setModel({ ocl: response.Ocl });
    }, [project, gitRef]);
    const onSaveClick = async (isNavigationConfirmation: boolean) => {
        if (isNavigationConfirmation && openCommitDialog.current !== undefined) {
            openCommitDialog.current();
        }
        else {
            await doBusyTask(async () => {
                await saveDeploymentProcess();
            });
        }
    };
    const saveDeploymentProcess = async (newBranch?: GitBranchResource) => {
        if (model.ocl !== undefined) {
            setLastCommit(undefined);
            const refToCommitTo = newBranch ?? gitRef;
            const isBranchDefault = IsDefaultBranch(project.PersistenceSettings, refToCommitTo?.CanonicalName) ?? false;
            const isBranchProtected = isProtectedBranch(refToCommitTo);
            const isBranchNew = newBranch !== undefined;
            const hasCommitMessage = commitMessage.summary !== "";
            const projectRepository = new ProjectContextRepository(client, project, refToCommitTo, createGlobalRequestContext("ProjectContext"));
            try {
                const response = await projectRepository.DeploymentProcesses.modifyRawOcl(model.ocl, getFormattedCommitMessage(commitMessage, defaultCommitMessage));
                dispatchCommitCodeEditorChanges(ActionStatus.Success, isBranchDefault, isBranchNew, isBranchProtected, hasCommitMessage);
                if (response.Commit) {
                    setLastCommit(response.Commit);
                    setSnackbarOpen(true);
                }
                setCleanModel(model);
            }
            catch (e) {
                dispatchCommitCodeEditorChanges(ActionStatus.Failed, isBranchDefault, isBranchNew, isBranchProtected, hasCommitMessage);
                throw e;
            }
        }
    };
    const saveDeploymentProcessToNewBranch = async (branchName: string) => {
        await doBusyTask(async () => {
            setCreatingNewBranch(true);
            const newBranch = await repository.ProjectBranches.createBranch(project.Id, branchName, gitRef?.CanonicalName ?? "");
            await saveDeploymentProcess(newBranch);
            changeGitRef(newBranch.Name);
            setCreatingNewBranch(false);
        });
    };
    const handleOptOut = () => {
        disableProcessCodeEditorByDefault();
        dispatchSwitchToFormEditor();
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        navigation.navigate(links.branchDeploymentProcessPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, branchName: gitRef!.CanonicalName }));
    };
    const onFeedbackSubmitted = (feedback: string) => {
        dispatchCodeEditorFeedback(feedback);
    };
    const pageActions: PageAction[] = [];
    const isCodeEditorEnabled = isProcessCodeEditorEnabled(project, processType);
    if (!isCodeEditorEnabled)
        return (<InternalRedirect to={gitRef
                ? links.branchDeploymentProcessPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, branchName: gitRef.CanonicalName })
                : links.deploymentProcessPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug })}/>);
    if (!showCallout) {
        const feedbackButton: PageAction = {
            type: "button",
            buttonType: "tertiary",
            label: "Feedback",
            extraContext: "Provide feedback on editing your deployment process in code",
            icon: <MessagesIcon size={24}/>,
            onClick: openFeedbackDialog,
        };
        pageActions.push(feedbackButton);
    }
    const switchToCodeViewButton: PageAction = {
        type: "button",
        buttonType: "secondary",
        label: "Form",
        extraContext: "Switch back to the form editor to edit your deployment process",
        icon: <PenFieldIcon size={24}/>,
        hasPermissions: isAllowed({ permission: Permission.ProcessView, project: project.Id }),
        onClick: () => handleOptOut(),
    };
    pageActions.push(switchToCodeViewButton);
    const createReleaseButton: PageAction = {
        type: "navigate",
        buttonType: "secondary",
        label: "Create Release",
        path: links.createReleasePage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }),
        onClick: () => dispatchAction("Create a release", { resource: "Create Release", action: Action.Add }),
        hasPermissions: isAllowed({ permission: Permission.ReleaseCreate, project: project.Id }),
    };
    pageActions.push(createReleaseButton);
    const canCommitToGitRef = canCommitTo(gitRef);
    const getCommitButtonProps = (primarySaveAction: PrimarySavePageAction): GetCommitButtonProps => {
        return {
            hasPermission: { permission: Permission.ProjectEdit, project: project.Id },
            gitRef: gitRef?.CanonicalName,
            persistenceSettings: project.PersistenceSettings,
            canCommitToGitRef: canCommitToGitRef,
            defaultCommitMessage: defaultCommitMessage,
            commitMessage: commitMessage,
            updateCommitMessage: (newCommitMessage: CommitMessageWithDetails) => setCommitMessage(newCommitMessage),
            commitMessageAccessibleName: "Commit message for saving the deployment process",
            commitDetailsAccessibleName: "Commit details for saving the deployment process",
            commitButtonAccessibleName: "Commit changes to the deployment process",
            onNewBranchCreating: saveDeploymentProcessToNewBranch,
            onInitializing: (openDialog) => {
                if (openCommitDialog.current === undefined) {
                    openCommitDialog.current = openDialog;
                }
            },
            label: primarySaveAction.label,
            busyLabel: primarySaveAction.busyLabel,
            onClick: primarySaveAction.onClick,
            disabled: primarySaveAction.disabled,
        };
    };
    const getCommitButtonPrimaryAction = (defaultPrimaryAction: PrimarySavePageAction): PrimaryPageAction => {
        return { type: "custom", content: <GetCommitButton {...getCommitButtonProps(defaultPrimaryAction)}/>, key: "Get Commit" };
    };
    return (<>
            <LegacyForm cleanModel={cleanModel} model={model} disableDirtyFormChecking={creatingNewBranch} disableKeyboardFormSubmission={true} devToolsDirtyTrackingDisabled={true} confirmNavigateSaveLabel="Commit changes" savePermission={{ permission: Permission.ProcessEdit, project: project.Id }} onSaveClick={onSaveClick}>
                {({ FormContent, createSaveAction }) => (<ProcessPaperLayout title="Process" primaryAction={getCommitButtonPrimaryAction(createSaveAction({ saveButtonLabel: "Commit", saveButtonBusyLabel: "Committing" }))} pageActions={pageActions} processType={processType} busy={busy} errors={errors}>
                        <CodeEditorFeedbackDialog open={isFeedbackDialogOpen} onClose={closeFeedbackDialog} onSubmit={onFeedbackSubmitted}/>
                        <FormContent>
                            <ProcessCodeEditorCallout onFeedbackSubmitted={onFeedbackSubmitted}/>
                            {model.ocl !== undefined && (<Suspense fallback={<CircularProgress size="large"/>}>
                                    <ProcessCodeEditor code={model.ocl} onChange={(newVal) => setModel({ ocl: newVal })} readOnly={!isAllowed({ permission: Permission.ProcessEdit, project: project.Id })}/>
                                </Suspense>)}
                        </FormContent>
                    </ProcessPaperLayout>)}
            </LegacyForm>
            <Snackbar content={`Changes committed in ${toGitCommitShort(lastCommit ?? "")}`} open={snackbarOpen} autoHideDuration={3500} onClose={() => setSnackbarOpen(false)} textAlign="center"/>
        </>);
};
type ProcessCodeLayoutProps = {
    processId: string;
    processType: ProcessType;
    project: ProjectResource;
    gitRef?: GitRefResource;
    changeGitRef: (gitRef: string) => void;
};
export class ProcessCodeLayout extends DataBaseComponent<ProcessCodeLayoutProps> {
    constructor(props: ProcessCodeLayoutProps) {
        super(props);
        this.state = {};
    }
    render() {
        return <ProcessCodeLayoutInternal doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors} processType={this.props.processType} project={this.props.project} gitRef={this.props.gitRef} changeGitRef={this.props.changeGitRef}/>;
    }
    static displayName = "ProcessCodeLayout";
}
