/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Level2PageLayout } from "@octopusdeploy/design-system-components";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { compact } from "lodash";
import * as React from "react";
import { useCreatePageSideNavLink } from "~/hooks/useCreatePageSideNavLink";
interface UserProfileLayoutProps {
    children: React.ReactNode;
}
export function UserProfileLayout({ children }: UserProfileLayoutProps) {
    const createPageSideNavLink = useCreatePageSideNavLink();
    const navLinks = compact([
        createPageSideNavLink("My Profile", links.currentUserDetailsPage.generateUrl()),
        createPageSideNavLink("My Logins", links.currentUserLoginsPage.generateUrl()),
        createPageSideNavLink("My API Keys", links.currentUserApiKeysPage.generateUrl()),
        createPageSideNavLink("My Recent Activity", links.currentUserActivityPage.generateUrl(), null!, { permission: Permission.EventView, wildcard: true }),
    ]);
    return (<main>
            <Level2PageLayout navItems={navLinks} header={{ title: "My Profile" }}>
                {children}
            </Level2PageLayout>
        </main>);
}
