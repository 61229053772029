import { PageTitleProvider, GlobalSnackbarProvider } from "@octopusdeploy/design-system-components";
import { UnauthenticatedAnalyticSessionProvider } from "@octopusdeploy/portal-analytics";
import { GlobalPageProvider } from "@octopusdeploy/portal-routes";
import { OpenFeatureProvider } from "@openfeature/react-sdk";
import * as React from "react";
import { Provider } from "react-redux";
import { PageChangedCallbacks } from "~/PageChangedCallbacks";
import DynamicExtensionsLoader from "~/areas/dynamicExtensions/dynamicExtensionsLoader";
import { ClientConnector } from "~/areas/infrastructure/components/ClientConnector/ClientConnector";
import { AuthenticationRoutes } from "~/components/AuthenticationRoutes/AuthenticationRoutes";
import { DevToolsContextProvider } from "~/components/DevTools/DevToolsContext";
import ErrorBoundary from "~/components/ErrorBoundary/ErrorBoundary";
import { ErrorContextProvider } from "~/components/ErrorContext/ErrorContext";
import { LogErrorDisplayer } from "~/components/LogErrors/LogErrorDisplayer";
import { LogEventCapturer } from "~/components/LogErrors/LogEventCapturer";
import { OctopusRouter } from "~/components/OctopusRouter/OctopusRouter";
import store from "~/store";
import { ThemeFromLocalStorage } from "./components/Theme/ThemeFromLocalStorage";
const App: React.FC = () => {
    return (<LogEventCapturer>
            <UnauthenticatedAnalyticSessionProvider>
                <ErrorBoundary>
                    <ErrorContextProvider>
                        <OpenFeatureProvider>
                            <Provider store={store}>
                                <GlobalPageProvider>
                                    <PageTitleProvider>
                                        <PageChangedCallbacks />
                                        <ThemeFromLocalStorage>
                                            {({ overrideFontProps }) => (<GlobalSnackbarProvider>
                                                    <LogErrorDisplayer />
                                                    <ClientConnector renderWhenConnected={() => (<DevToolsContextProvider>
                                                                <OctopusRouter>
                                                                    <AuthenticationRoutes overrideFontProps={overrideFontProps}/>
                                                                </OctopusRouter>
                                                                <DynamicExtensionsLoader />
                                                            </DevToolsContextProvider>)}/>
                                                </GlobalSnackbarProvider>)}
                                        </ThemeFromLocalStorage>
                                    </PageTitleProvider>
                                </GlobalPageProvider>
                            </Provider>
                        </OpenFeatureProvider>
                    </ErrorContextProvider>
                </ErrorBoundary>
            </UnauthenticatedAnalyticSessionProvider>
        </LogEventCapturer>);
};
App.displayName = "App"
export default App;
