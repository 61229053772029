import type { GitRef, GitRefResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useMaybeProcessBlueprintsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessBlueprintsContextProvider";
import { isBlueprintProcessIdentifier, isDeploymentOrRunbookProcessIdentifier } from "~/areas/projects/components/Process/types";
import { usePersistenceSettingsContext } from "~/areas/projects/context/PersistenceSettingsContext";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import { OverflowMenuConverterVNext } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { getCommonOverflowMenuItems } from "./Common/CommonProcessHelpers";
import { useMaybeLoadedActionTemplatesFromContext } from "./Contexts/ProcessActionTemplatesContextProvider";
import { useProcessContext } from "./Contexts/ProcessContext";
import { useProcessErrorActions } from "./Contexts/ProcessErrors/ProcessErrorsContext";
import { useMaybeFeedsFromContext } from "./Contexts/ProcessFeedsContextProvider";
import { useProcessWarningActions } from "./Contexts/ProcessWarnings/ProcessWarningsContext";
import { ProcessContextPaperLayout } from "./CustomPaperLayouts/ProcessContextPaperLayout";
import ProcessList from "./ProcessList";
import type { ProcessListLayoutLoaderLookupData } from "./ProcessListLayoutLoader";
interface ProcessPageProps {
    gitRef: GitRef | undefined;
    onProcessSave: (gitRef: GitRefResource | string | undefined) => Promise<void>;
    gitRefResource: GitRefResource | undefined;
    titleAccessory?: React.ReactElement;
    lookups: ProcessListLayoutLoaderLookupData;
    doBusyTask: DoBusyTask;
    isBuiltInWorkerEnabled: boolean;
    releaseCreationPackageStepId?: string | undefined;
}
const ProcessListLayout: React.FC<ProcessPageProps> = (props) => {
    const location = useLocation();
    const history = useHistory();
    const processContext = useProcessContext();
    const processIdentifier = processContext.state.processIdentifier;
    const persistenceSettings = usePersistenceSettingsContext();
    const errorActions = useProcessErrorActions();
    const warningActions = useProcessWarningActions();
    const feeds = useMaybeFeedsFromContext();
    const actionTemplates = useMaybeLoadedActionTemplatesFromContext();
    const blueprints = useMaybeProcessBlueprintsFromContext();
    const hasLoadedNecessaryLookupData = (): boolean => {
        const feedViewPermissionGranted = isAllowed({ permission: Permission.FeedView, project: isDeploymentOrRunbookProcessIdentifier(processIdentifier) ? processIdentifier.projectId : undefined, wildcard: true });
        return actionTemplates !== "NotLoaded" && (isBlueprintProcessIdentifier(processIdentifier) || !feedViewPermissionGranted || feeds !== "NotLoaded") && blueprints !== "NotLoaded";
    };
    if (!processContext.selectors.hasValidProcess() || !hasLoadedNecessaryLookupData()) {
        return <ProcessContextPaperLayout doBusyTask={props.doBusyTask} titleAccessory={props.titleAccessory} gitRefResource={props.gitRefResource} processIdentifier={processIdentifier}/>;
    }
    const legacyOverflowMenuItems = getCommonOverflowMenuItems(processIdentifier, props.releaseCreationPackageStepId, persistenceSettings, props.gitRefResource, processIdentifier.type, processContext.selectors, processContext.actions, props.onProcessSave, errorActions, warningActions, undefined);
    const convertedOverflowMenuItems = OverflowMenuConverterVNext.convertAll(legacyOverflowMenuItems);
    return (<ProcessContextPaperLayout processIdentifier={processIdentifier} overflowActions={convertedOverflowMenuItems.menuItems} doBusyTask={props.doBusyTask} titleAccessory={props.titleAccessory} gitRefResource={props.gitRefResource}>
            {convertedOverflowMenuItems.dialogs}
            <ProcessList lookups={props.lookups} doBusyTask={props.doBusyTask} location={location} history={history} gitRefResource={props.gitRefResource}/>
        </ProcessContextPaperLayout>);
};
ProcessListLayout.displayName = "ProcessListLayout"
export default React.memo(ProcessListLayout);
