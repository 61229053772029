import { css } from "@emotion/css";
import { Callout, ExternalLink } from "@octopusdeploy/design-system-components";
import { ArrowLeftIcon } from "@octopusdeploy/design-system-icons";
import { space, text } from "@octopusdeploy/design-system-tokens";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import type { UserOnboardingResource } from "@octopusdeploy/octopus-server-client/src/resources/userOnboardingResource";
import { links } from "@octopusdeploy/portal-routes";
import React, { useState } from "react";
import { ProjectStatusBar } from "~/areas/projects/components/ProjectStatus/ProjectStatusBar";
import { projectStatusActions } from "~/areas/projects/components/ProjectStatus/actions";
import type { ProjectStatus } from "~/areas/projects/components/ProjectStatus/useProjectStatus";
import type { ProjectCreatedOption } from "~/areas/projects/components/Projects/AddProject";
import AddProject, { ProjectInfrastructure } from "~/areas/projects/components/Projects/AddProject";
import { repository, session } from "~/clientInstance";
import useLocalStorage from "~/hooks/useLocalStorage";
import { useDoBusyTaskEffect, type DoBusyTask } from "../DataBaseComponent";
import InternalLink from "../Navigation/InternalLink";
import { GuidedSetupFrame } from "../OnboardingDialog/GuidedSetupFrame";
import addProjectImageDark from "./assets/img-addproject-dark.svg";
import addProjectImageLight from "./assets/img-addproject-light.svg";
interface CreateNewProjectProps {
    spaceId: string;
    projectGroupId?: string;
    isOnboarding?: boolean;
    next: (project: ProjectResource, option: ProjectCreatedOption) => void;
    back: () => void;
    close: () => void;
    doBusyTask: DoBusyTask;
    projectStatus?: ProjectStatus;
    k8sTargetAlreadyExistsInSpace: boolean;
    spaceHasEnvironments: boolean;
}
function CreateNewProject(props: CreateNewProjectProps) {
    const [feedbackDismissed, setFeedbackDismissed] = useLocalStorage<boolean>("Octopus.Callout.KubernetesGuidedSetup.FeedbackDismissed", false);
    const [userOnboarding, setUserOnboarding] = useState<UserOnboardingResource | undefined>(undefined);
    const onBackClicked = () => {
        props.back();
    };
    const onProjectCreated = (project: ProjectResource, option: ProjectCreatedOption) => {
        props.projectStatus?.dispatch({
            type: projectStatusActions.newProjectFormSubmitted,
            intendsToUseK8s: option.infrastructure == ProjectInfrastructure.Kubernetes,
            k8sTargetAlreadyExistsInSpace: props.k8sTargetAlreadyExistsInSpace,
            intendsToUseConfigAsCode: option.vcsRedirect,
        });
        props.next(project, option);
    };
    useDoBusyTaskEffect(props.doBusyTask, async () => {
        const userOnboarding = (await repository.UserOnboarding.getForCurrentUser())?.UserOnboardingResource;
        setUserOnboarding(userOnboarding);
        props.projectStatus?.dispatch({ type: projectStatusActions.projectInitiated, hasEnvironments: props.spaceHasEnvironments });
        // we only want this to run once, so we don't need to include the props in the dependency array
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const feedbackLink = "full-screen-project-creation-feedback";
    const feedbackStartDate = new Date("2024-09-23"); //release date of "kubernetes-guided-setup"
    const feedbackCutOffDate = new Date("2025-02-01");
    const showFeedback = !props.isOnboarding && !feedbackDismissed && new Date() < feedbackCutOffDate && userOnboarding?.WelcomeDialogDismissedDate && new Date(userOnboarding?.WelcomeDialogDismissedDate) < feedbackStartDate;
    const helpPanelContent = (<div>
            <div>
                A <strong>project</strong> represents a specific software component, service, or database that you want to deploy and manage. Each project has its own deployment process.
            </div>
            {/* Remove the feedback callout along with the "kubernetes-guided-setup" feature toggle */}
            {showFeedback && (<div className={createNewProjectStyles.feedbackContainer}>
                    <Callout type="information" title="Project Setup Feedback" canClose={true} onClose={() => setFeedbackDismissed(true)}>
                        We’d love to <ExternalLink href={session.currentUser?.EmailAddress ? `${feedbackLink}#email=${session.currentUser?.EmailAddress}` : feedbackLink} label="hear your feedback"/> about using the new project setup experience in
                        Octopus Deploy.
                    </Callout>
                </div>)}
        </div>);
    return (<GuidedSetupFrame helpPanelImage={{ src: addProjectImageLight, altText: "Add New Project", darkThemeSrc: addProjectImageDark }} helpPanelContent={helpPanelContent} onClose={props.close} showThemeSelector={props.isOnboarding}>
            <div className={createNewProjectStyles.projectStatusContainer}>
                {!props.isOnboarding && (<InternalLink className={createNewProjectStyles.projectsLink} to={links.projectsPage.generateUrl({ spaceId: props.spaceId })} onClick={props.close}>
                        <ArrowLeftIcon size={20}/>
                        <span>Projects</span>
                    </InternalLink>)}
                <ProjectStatusBar projectSlug={undefined} spaceId={props.spaceId} state={props.projectStatus?.state} dispatch={props.projectStatus?.dispatch} hideCloseButton/>
            </div>
            <div className={createNewProjectStyles.projectContainer}>
                <AddProject spaceId={props.spaceId} projectCreated={onProjectCreated} hideEnvironmentConfigWarning={true} projectNameTextBoxLabel="Project name" groupId={props.projectGroupId} title="Add New Project" saveButtonLable={props.isOnboarding ? "Next" : "Create Project"} cancelButtonLabel={props.isOnboarding ? "Back" : "Cancel"} onCancelled={props.isOnboarding ? onBackClicked : props.close} isKubernetesGuidedSetup={true} isOnboarding={props.isOnboarding}/>
            </div>
        </GuidedSetupFrame>);
}
const createNewProjectStyles = {
    projectContainer: css({
        flexBasis: "100%",
        "& .MuiDialogContent-root, & .MuiDialogActions-root, & .MuiDialogTitle-root .MuiTypography-root div": {
            padding: "0 !important",
        },
        "& .MuiDialogContent-root div[role='alert'] + div": {
            marginTop: space["32"],
        },
        "& .MuiDialogTitle-root .MuiTypography-root": {
            padding: `${space["48"]} 0 ${space["32"]} 0 !important`,
            "& .MuiLinearProgress-root[role='progressbar']": {
                display: "none",
            },
        },
    }),
    projectStatusContainer: css({
        paddingTop: space["40"],
    }),
    projectsLink: css({
        paddingBottom: space[16],
        display: "flex",
        font: text.regular.default.small,
        gap: space["4"],
    }),
    feedbackContainer: css({
        marginTop: space[24],
    }),
};
export default CreateNewProject;
