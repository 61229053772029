import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { repository } from "~/clientInstance";
import { SkeletonLoadingLayout } from "~/components/SkeletonLoadingLayout/SkeletonLoadingLayout";
import { AsyncInternalRedirect } from "~/routing/AsyncInternalRedirect";
export function ScopedUserRoleToTeamRedirect({ scopedUserRoleId }: {
    scopedUserRoleId: string;
}) {
    return (<AsyncInternalRedirect loader={async () => {
            const scopedUserRole = await repository.ScopedUserRoles.get(scopedUserRoleId);
            return links.editTeamPage.generateUrl({ teamId: scopedUserRole.TeamId });
        }} renderWhenLoading={<SkeletonLoadingLayout errors={undefined}/>}/>);
}
