import type { SensitiveValue, TokenPlatformHubAccountResource } from "@octopusdeploy/octopus-server-client";
import { AccountType } from "@octopusdeploy/octopus-server-client";
import React from "react";
import type { PlatformHubAccountEditModel } from "~/areas/blueprints/accounts/PlatformHubAccountEditBase";
import PlatformHubAccountEditBase from "~/areas/blueprints/accounts/PlatformHubAccountEditBase";
import Sensitive, { ObfuscatedPlaceholder } from "~/components/form/Sensitive/Sensitive";
import { ExpandableFormSection, FormSectionHeading, Summary } from "~/components/form/index";
interface TokenPlatformHubModel extends PlatformHubAccountEditModel {
    token: SensitiveValue;
}
class TokenAccountPlatformHubEdit extends PlatformHubAccountEditBase<TokenPlatformHubAccountResource, TokenPlatformHubModel> {
    getPartialModel(account?: TokenPlatformHubAccountResource) {
        return getPartialModel(account);
    }
    getPartialResource() {
        return getPartialResource(this.state.model);
    }
    getPageActions() {
        return [];
    }
    getTestDialog() {
        return null;
    }
    customExpandableFormSections() {
        return customExpandableFormSections(this.state.model, (token) => this.setModelState({ token }));
    }
}
function getPartialModel(account?: TokenPlatformHubAccountResource): Partial<TokenPlatformHubModel> {
    if (!account) {
        return {
            token: {
                HasValue: false,
            },
        };
    }
    return {
        token: account.Token,
    };
}
function getPartialResource(modelState: TokenPlatformHubModel): Partial<TokenPlatformHubAccountResource> & {
    AccountType: AccountType;
} {
    return {
        AccountType: AccountType.Token,
        Token: modelState.token,
    };
}
function tokenSummary(modelState: TokenPlatformHubModel) {
    return modelState.token && modelState.token.HasValue ? Summary.summary(ObfuscatedPlaceholder) : Summary.placeholder("No token yet provided");
}
function customExpandableFormSections(modelState: TokenPlatformHubModel, onTokenChange: (token: SensitiveValue) => void): JSX.Element[] {
    const baseElements: JSX.Element[] = [];
    baseElements.push(<FormSectionHeading title="Credentials" key={"header"}/>, <ExpandableFormSection key="token" errorKey="token" title="Token" summary={tokenSummary(modelState)} help="The password to use to when authenticating against the remote host." containerKey="AccountEdit">
            <Sensitive value={modelState.token} onChange={onTokenChange} label="Token"/>
        </ExpandableFormSection>);
    return baseElements;
}
export default TokenAccountPlatformHubEdit;
