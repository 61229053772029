/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { TenantedDeploymentMode } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { connect } from "react-redux";
import BaseComponent from "~/components/BaseComponent";
import { EnvironmentChip, TenantChip, ChannelChip, DeploymentActionChip, MachineChip } from "~/components/Chips";
import type { Item } from "~/primitiveComponents/form/Select/Select";
interface GlobalConnectedProps {
    isMultiTenancyEnabled: boolean;
}
interface VariablePreviewSummaryProps {
    tenantedDeploymentMode: TenantedDeploymentMode;
    environments: Item[];
    tenants: Item[];
    actions: Item[];
    channels: Item[];
    machines: Item[];
    environmentId: string;
    tenantId: string;
    actionId: string;
    channelId: string;
    machineId: string;
}
export class VariablePreviewSummary extends BaseComponent<GlobalConnectedProps & VariablePreviewSummaryProps> {
    render() {
        const hasData = this.props.environments && this.props.tenants && this.props.actions && this.props.channels && this.props.machines;
        const notificationSummary = hasData &&
            this.getVariablePreviewSummary(this.props.tenantedDeploymentMode, this.props.environments, this.props.tenants, this.props.actions, this.props.channels, this.props.machines, this.props.environmentId, this.props.tenantId, this.props.actionId, this.props.channelId, this.props.machineId);
        return <span>{notificationSummary}</span>;
    }
    getVariablePreviewSummary(tenantedDeploymentMode: TenantedDeploymentMode, environments: Item[], tenants: Item[], actions: Item[], channels: Item[], machines: Item[], environmentId: string, tenantId: string, actionId: string, channelId: string, machineId: string): JSX.Element {
        const environmentChip = environments.filter((x) => x.value === environmentId).map((x) => <EnvironmentChip environmentName={x.text} key={x.value!}/>);
        const tenantChip = tenants.filter((x) => x.value === tenantId).map((x) => <TenantChip tenantName={x.text} key={x.value!}/>);
        const channelChip = channels.filter((x) => x.value === channelId).map((x) => <ChannelChip channelName={x.text} key={x.value!}/>);
        const actionChip = actions.filter((x) => x.value === actionId).map((x) => <DeploymentActionChip stepName={x.text} key={x.value!}/>);
        const machineChip = machines.filter((x) => x.value === machineId).map((x) => <MachineChip machineName={x.text} key={x.value!}/>);
        return this.getVariablePreviewSummary2(tenantedDeploymentMode, channels, tenantId, machineId, environmentChip, tenantChip, channelChip, actionChip, machineChip);
    }
    //todo: rename
    getVariablePreviewSummary2(tenantedDeploymentMode: TenantedDeploymentMode, channels: Item[], tenantId: string, machineId: string, environmentChip: React.ReactNode, tenantChip: React.ReactNode, channelChip: React.ReactNode, actionChip: React.ReactNode, machineChip: React.ReactNode): JSX.Element {
        // environments: Item[];            mandatory
        // tenants: Item[];                 mandatory if tenantedmode=tenantedonly
        //                                  optional if tenantedmode=both
        //                                  missing if tenantedmode=both
        // actions: Item[];                 mandatory
        // channels: Item[];                mandatory if channel count > 1
        // machines: Item[];                optional
        //todo: move the chips to be params to this function - that'll fix the test (that's why getChipList exists in EventFilterSummary.spec.tsx)
        const channelPart = channels.length > 1 ? <> during deployment using {channelChip}</> : <></>;
        const tenantPart = tenantedDeploymentMode === TenantedDeploymentMode.Tenanted ? (<> for tenant {tenantChip}</>) : tenantedDeploymentMode === TenantedDeploymentMode.Untenanted ? (<></>) : tenantId ? (<> for tenant {tenantChip}</>) : (<> for untenanted deployments</>);
        let machinePart = <></>;
        if (machineId) {
            machinePart = <> for deployment target {machineChip}</>;
        }
        else {
            machinePart = <>. Variables scoped to deployment targets are not currently shown</>;
        }
        return (<div>
                Showing variables available{channelPart} when running step {actionChip} in environment {environmentChip}
                {tenantPart}
                {machinePart}.
            </div>);
    }
    static displayName = "VariablePreviewSummary";
}
const mapGlobalStateToProps = (state: GlobalState): GlobalConnectedProps => {
    return {
        isMultiTenancyEnabled: state.configurationArea.currentSpace.isMultiTenancyEnabled,
    };
};
export default connect(mapGlobalStateToProps)(VariablePreviewSummary);
