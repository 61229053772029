import { css } from "@emotion/css";
import { colorScales } from "@octopusdeploy/design-system-tokens";
import React from "react";
export function OctopusLogo() {
    return (<svg className={octopusIconStyles} viewBox="0 0 40 40">
            <path d="M6.49742 28.2856C8.88633 26.7332 11.6929 24.0717 10.6272 20.9369C10.0466 19.2287 9.24218 17.7646 9.13487 15.9182C9.04418 14.3595 9.27518 12.7847 9.81564 11.3215C11.9576 5.52123 18.3254 2.77521 24.0801 4.51897C29.4102 6.13376 33.0773 12.3773 30.8682 17.8181C29.5936 20.9576 29.0225 23.3819 31.8655 25.825C32.6361 26.4874 34.5067 27.4798 34.5 28.6542C34.4905 30.1921 31.5 28.3266 31.1658 28.0591C31.5462 28.7296 35.3164 32.6953 32.9167 32.9777C30.7078 33.2373 28.7573 30.1248 27.4299 28.7887C25.2 26.5444 25.5879 31.5096 25.5778 32.5351C25.5619 34.1546 24.4281 37.4363 22.3927 35.2991C20.7118 33.5346 21.3476 30.7204 20.1787 28.7603C18.8987 26.6131 16.7691 30.9073 16.237 31.693C15.6423 32.5708 12.6682 36.8205 11.4848 34.5564C10.5246 32.7191 12.0594 29.8368 12.8145 28.1746C12.5392 28.7782 10.5887 29.6711 10.0185 29.9614C8.73079 30.6175 7.42372 31.0067 5.97519 30.9017C2.97821 30.6847 5.27689 29.08 6.49742 28.2856C6.90611 28.02 6.11458 28.5347 6.49742 28.2856Z"/>
        </svg>);
}
const octopusIconStyles = css({
    fill: colorScales.blue[500],
});
