/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import { DatePicker } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { EnvironmentResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { TaskDateFilterTypeValues, TaskFilterStateValues, type TaskDateFilterType, type TaskFilterState } from "@octopusdeploy/portal-routes";
import moment from "moment";
import React from "react";
import { AdvancedFilterCheckbox } from "~/components/AdvancedFilterLayout";
import { Feature, FeatureToggle } from "~/components/FeatureToggle";
import { PermissionCheck } from "~/components/PermissionCheck";
import EnvironmentSelect from "~/components/form/EnvironmentSelect/EnvironmentSelect";
import Select from "~/primitiveComponents/form/Select/Select";
export interface RunbookTaskFilter {
    ids?: string[];
    state?: TaskFilterState;
    environment?: string;
    tenant?: string;
    hasPendingInterruptions: boolean;
    hasWarningsOrErrors: boolean;
    dateFilterType?: TaskDateFilterType;
    fromDate?: Date;
    toDate?: Date;
}
interface RunbookRunsStateFilterProps {
    filter: RunbookTaskFilter;
    setFilter: (callback: (prev: RunbookTaskFilter) => RunbookTaskFilter) => void;
}
export function RunbookRunsStateFilter({ filter, setFilter }: RunbookRunsStateFilterProps) {
    const items = Object.keys(TaskFilterStateValues).map((t) => ({ value: t, text: t }));
    const styles = {
        states: css({
            width: "16rem",
        }),
    };
    return (<div className={styles.states}>
            <Select value={filter.state} onChange={(value) => {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const state = value as TaskFilterState | undefined;
            setFilter((prev) => ({ ...prev, state }));
        }} items={items} allowClear={true} fieldName="task state" placeholder="All task states"/>
        </div>);
}
interface RunbooksAdvancedFilterSectionProps {
    filter: RunbookTaskFilter;
    setFilter: (callback: (prev: RunbookTaskFilter) => RunbookTaskFilter) => void;
    environments?: EnvironmentResource[];
    tenants?: TenantResource[];
}
export function RunbooksAdvancedFilterSection({ filter, setFilter, environments, tenants }: RunbooksAdvancedFilterSectionProps) {
    const datePickerFormat = "MMM dd, yyyy";
    const fromError = filter.fromDate && filter.toDate && filter.fromDate > filter.toDate ? "Must be before the \"To\" date" : undefined;
    const toError = filter.fromDate && filter.toDate && filter.fromDate > filter.toDate ? "Must be after the \"From\" date" : undefined;
    const styles = {
        datepickerWrap: css({
            paddingBottom: space[16],
        }),
        checkboxFiltersContainer: css({
            marginBottom: space[16],
        }),
    };
    return (<>
            <div className={styles.checkboxFiltersContainer}>
                <AdvancedFilterCheckbox label="Awaiting manual intervention" value={filter.hasPendingInterruptions} onChange={(hasPendingInterruptions) => setFilter((prev) => ({ ...prev, hasPendingInterruptions }))}/>
                <AdvancedFilterCheckbox label="Has warnings or errors" value={filter.hasWarningsOrErrors} onChange={(hasWarningsOrErrors) => setFilter((prev) => ({ ...prev, hasWarningsOrErrors }))}/>
            </div>
            {environments && (<PermissionCheck permission={Permission.EnvironmentView} wildcard={true}>
                    <EnvironmentSelect value={filter.environment} onChange={(environment) => setFilter((prev) => ({ ...prev, environment }))} environments={environments} allowClear={true} allowFilter={true} fieldName="environment" placeholder="All environments"/>
                </PermissionCheck>)}
            {tenants && (<FeatureToggle feature={Feature.MultiTenancy}>
                    <PermissionCheck permission={Permission.TenantView} tenant="*">
                        <Select value={filter.tenant} onChange={(tenant) => setFilter((prev) => ({ ...prev, tenant }))} items={tenants.map((t) => ({ value: t.Id, text: t.Name }))} allowClear={true} allowFilter={true} fieldName="tenant" placeholder="All tenants" label="By tenant"/>
                    </PermissionCheck>
                </FeatureToggle>)}
            <Select value={filter.dateFilterType} allowFilter={false} sortItems={false} 
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    onChange={(dateFilterType) => setFilter((prev) => ({ ...filter, dateFilterType: dateFilterType as TaskDateFilterType }))} items={Object.values(TaskDateFilterTypeValues).map((v) => ({ value: v, text: v }))} allowClear={true} fieldName="date filter type" placeholder="Select a date type" label="By date"/>
            {filter.dateFilterType && (<>
                    <div className={styles.datepickerWrap}>
                        <DatePicker value={filter.fromDate!} onChange={(fromDate) => setFilter((prev) => ({ ...prev, fromDate }))} label="From" error={fromError} format={datePickerFormat} variant={"inline"}/>
                    </div>
                    <div className={styles.datepickerWrap}>
                        <DatePicker value={filter.toDate!} onChange={(toDate) => setFilter((prev) => ({ ...prev, toDate: moment(toDate).endOf("day").toDate() }))} label="To" error={toError} format={datePickerFormat} variant={"inline"}/>
                    </div>
                </>)}
        </>);
}
