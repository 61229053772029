/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { WeeklyRecurringSchedule, RecurringScheduleResource, MonthlyRecurringSchedule } from "@octopusdeploy/octopus-server-client/src/resources/deploymentFreezes/getDeploymentFreezesResponse";
import { RecurringScheduleEndType, RecurringScheduleType } from "@octopusdeploy/octopus-server-client/src/resources/deploymentFreezes/getDeploymentFreezesResponse";
import type { Moment } from "moment";
import pluralize from "pluralize";
import DateFormatter from "../DateFormatter";
import DaysDescriptionHelper from "./DaysDescriptionHelper";
import DaysPerMonthDescriptionHelper from "./DaysPerMonthDescriptionHelper";
export class ScheduleFormatter {
    private static readonly scheduleSummaryPrefix = "Repeats every";
    public static buildScheduleSummary(schedule: RecurringScheduleResource | undefined, startDate: Moment): string {
        if (!schedule) {
            return "Does not repeat";
        }
        let scheduleSummary = "";
        const scheduleEndSummary = this.buildScheduleEndSummary(schedule);
        switch (schedule.Type) {
            case RecurringScheduleType.Daily:
                scheduleSummary = `${this.scheduleSummaryPrefix} ${pluralize("day", schedule.Unit, schedule.Unit !== 1)}`;
                break;
            case RecurringScheduleType.Weekly:
                scheduleSummary = `${this.scheduleSummaryPrefix} ${pluralize("week", schedule.Unit, schedule.Unit !== 1)} ${new DaysDescriptionHelper(schedule as WeeklyRecurringSchedule).getSimpleSummary()}`;
                break;
            case RecurringScheduleType.Monthly:
                scheduleSummary = `${this.scheduleSummaryPrefix} ${pluralize("month", schedule.Unit, schedule.Unit !== 1)} ${new DaysPerMonthDescriptionHelper(schedule as MonthlyRecurringSchedule).getSimpleSummary()}`;
                break;
            case RecurringScheduleType.Annually:
                scheduleSummary = `${this.scheduleSummaryPrefix} ${pluralize("year", schedule.Unit, schedule.Unit !== 1)} on ${startDate.format("MMMM D")}`;
                break;
        }
        return `${scheduleSummary}${scheduleEndSummary}`;
    }
    private static buildScheduleEndSummary(schedule: RecurringScheduleResource): string {
        switch (schedule.EndType) {
            case RecurringScheduleEndType.OnDate:
                return ` until ${DateFormatter.dateToCustomFormat(schedule.EndOnDate, "LL")}`;
            case RecurringScheduleEndType.AfterOccurrences:
                return `, ${schedule.EndAfterOccurrences} times`;
            default:
                return "";
        }
    }
}
