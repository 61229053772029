import type { PageAction } from "@octopusdeploy/design-system-components";
import type { GoogleCloudPlatformHubAccountResource, SensitiveValue } from "@octopusdeploy/octopus-server-client";
import { AccountType } from "@octopusdeploy/octopus-server-client";
import React from "react";
import type { PlatformHubAccountEditModel } from "~/areas/blueprints/accounts/PlatformHubAccountEditBase";
import PlatformHubAccountEditBase from "~/areas/blueprints/accounts/PlatformHubAccountEditBase";
import type { SaveAndTestAccountProps } from "~/areas/infrastructure/components/AccountEdit/SaveAndTestAccountDialog";
import SaveAndTestAccountDialog from "~/areas/infrastructure/components/AccountEdit/SaveAndTestAccountDialog";
import { repository } from "~/clientInstance";
import Dialog from "~/components/Dialog/Dialog";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import { ObfuscatedPlaceholder } from "~/components/form/Sensitive/Sensitive";
import { ExpandableFormSection, FormSectionHeading, SensitiveFileUpload, Summary } from "~/components/form/index";
import Note from "~/primitiveComponents/form/Note/Note";
interface GoogleCloudPlatformHubAccountModel extends PlatformHubAccountEditModel {
    jsonKey: SensitiveValue;
}
class GoogleCloudPlatformHubAccountEdit extends PlatformHubAccountEditBase<GoogleCloudPlatformHubAccountResource, GoogleCloudPlatformHubAccountModel> {
    getPartialModel(account?: GoogleCloudPlatformHubAccountResource) {
        return getPartialModel(account);
    }
    getPartialResource() {
        return getPartialResource(this.state.model);
    }
    getPageActions() {
        return getPageActions((performTest: boolean) => this.saveAccount(performTest, this.state.accountData === undefined));
    }
    getTestDialog() {
        return getTestDialog(this.state.accountData?.account?.Id, this.state.showTestDialog, (success: boolean) => this.onTestDone(success));
    }
    customExpandableFormSections() {
        return customExpandableFormSections(this.state.model, (jsonKey) => this.setModelState({ jsonKey }), this.getFieldError);
    }
}
function getPartialModel(account?: GoogleCloudPlatformHubAccountResource): Partial<GoogleCloudPlatformHubAccountModel> {
    if (!account) {
        return {
            jsonKey: {
                HasValue: false,
            },
        };
    }
    return {
        jsonKey: account.JsonKey,
    };
}
function getPartialResource(modelState: GoogleCloudPlatformHubAccountModel): (Partial<GoogleCloudPlatformHubAccountResource> & {
    AccountType: AccountType;
}) | undefined {
    return {
        AccountType: AccountType.GoogleCloudAccount,
        JsonKey: modelState.jsonKey,
    };
}
function jsonKeySummary(modelState: GoogleCloudPlatformHubAccountModel) {
    return modelState.jsonKey && modelState.jsonKey.HasValue ? Summary.summary(ObfuscatedPlaceholder) : Summary.placeholder("No key file provided");
}
function getPageActions(handleSaveClick: (performTest: boolean) => void): PageAction[] {
    return [{ type: "button", buttonType: "secondary", onClick: () => handleSaveClick(true), label: "Save and test" }];
}
function getTestDialog(accountId: string | undefined, showTestDialog: boolean, onOkClick: (success: boolean) => void): React.ReactNode {
    return (accountId && (<Dialog open={showTestDialog}>
                <SaveAndTestGoogleCloudPlatformHubAccountDialog onOkClick={onOkClick} accountId={accountId}/>
            </Dialog>));
}
function customExpandableFormSections(modelState: GoogleCloudPlatformHubAccountModel, onJsonKeyChange: (newValue: SensitiveValue) => void, getFieldError: (field: string) => string): React.ReactElement[] {
    const baseElements: React.ReactElement[] = [];
    baseElements.push(<FormSectionHeading title="Credentials" key={"header"}/>, <ExpandableFormSection key="keyFile" errorKey="keyFile" title="Key File" summary={jsonKeySummary(modelState)} help="The json key file to use when authenticating against Google Cloud." containerKey="AccountEdit">
            <SensitiveFileUpload value={modelState.jsonKey} onChange={onJsonKeyChange} label="Key File" error={getFieldError("KeyFile")}/>
            <Note>
                Refer to the <ExternalLink href="GoogleCloudServiceAccountKey">Google cloud documentation</ExternalLink> for information on generating json key for service accounts.
            </Note>
        </ExpandableFormSection>);
    return baseElements;
}
const SaveAndTestGoogleCloudPlatformHubAccountDialog: React.SFC<SaveAndTestAccountProps> = ({ accountId, onOkClick }) => {
    return <SaveAndTestAccountDialog accountId={accountId} onOkClick={onOkClick} createTask={(accId) => repository.Tasks.createTestGoogleCloudPlatformHubAccountTask(accId)}/>;
};
SaveAndTestGoogleCloudPlatformHubAccountDialog.displayName = "SaveAndTestGoogleCloudPlatformHubAccountDialog"
export default GoogleCloudPlatformHubAccountEdit;
