/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { AzureServicePrincipalPlatformHubAccountResource, AzureSubscriptionPlatformHubAccountResource, PlatformHubAccountResource, Repository, SshKeyPairPlatformHubAccountResource, TokenPlatformHubAccountResource, UsernamePasswordPlatformHubAccountResource, AzureOidcAccountPlatformHubResource, AzureEnvironment, AmazonWebServicesAccessKeyPlatformHubAccountResource, AmazonWebServicesOidcPlatformHubAccountResource, GoogleCloudPlatformHubAccountResource, GenericOidcPlatformHubAccountResource, } from "@octopusdeploy/octopus-server-client";
import { AccountType } from "@octopusdeploy/octopus-server-client";
import React from "react";
import GenericOidcPlatformHubAccountEdit from "~/areas/blueprints/accounts/GenericOidcPlatformHubAccountEdit";
import GoogleCloudPlatformHubAccountEdit from "~/areas/blueprints/accounts/GoogleCloudPlatformHubAccountEdit";
import SshKeyPairPlatformHubAccountEdit from "~/areas/blueprints/accounts/SshKeyPairPlatformHubAccountEdit";
import TokenAccountPlatformHubEdit from "~/areas/blueprints/accounts/TokenPlatformHubAccountEdit";
import { repository } from "~/clientInstance";
import { ErrorPanel } from "~/components/form/index";
import AmazonWebServicesPlatformHubAccountEdit from "./AmazonWebServicesPlatformHubAccountEdit";
import AzurePlatformHubAccountEdit from "./AzurePlatformHubAccountEdit";
import UsernamePasswordPlatformHubAccountEdit from "./UsernamePasswordPlatformHubAccountEdit";
const IsNew = "IsNew";
interface NewPlatformHubAccountLoaderData {
    accountType: AccountType;
    azureEnvironments: AzureEnvironment[];
}
interface ExistingPlatformHubAccountLoaderData extends NewPlatformHubAccountLoaderData {
    account: PlatformHubAccountResource;
}
interface PlatformHubAccountsPageProps {
    loaderData: NewPlatformHubAccountLoaderData | ExistingPlatformHubAccountLoaderData;
}
export async function newAccountPlatformHubLoader(accountType: AccountType): Promise<NewPlatformHubAccountLoaderData> {
    const azureEnvironments = await repository.Accounts.getIsolatedAzureEnvironments();
    return {
        accountType,
        azureEnvironments,
    };
}
export async function existingAccountPlatformHubLoader(repository: Repository, accountId: string): Promise<ExistingPlatformHubAccountLoaderData> {
    const account = await repository.PlatformHubRepository.getAccountById(accountId);
    const azureEnvironments = await repository.Accounts.getIsolatedAzureEnvironments();
    return {
        account,
        accountType: account.AccountType,
        azureEnvironments,
    };
}
function hasAccount(check: object): check is ExistingPlatformHubAccountLoaderData {
    return (check as ExistingPlatformHubAccountLoaderData).account !== undefined;
}
export function PlatformHubAccountPage({ loaderData }: PlatformHubAccountsPageProps) {
    const account = hasAccount(loaderData) ? loaderData.account : IsNew;
    switch (loaderData.accountType) {
        case AccountType.SshKeyPair:
            return <SshKeyPairPlatformHubAccountEdit azureEnvironments={loaderData.azureEnvironments} account={account as SshKeyPairPlatformHubAccountResource}/>;
        case AccountType.Token:
            return <TokenAccountPlatformHubEdit azureEnvironments={loaderData.azureEnvironments} account={account as TokenPlatformHubAccountResource}/>;
        case AccountType.UsernamePassword:
            return <UsernamePasswordPlatformHubAccountEdit azureEnvironments={loaderData.azureEnvironments} account={account as UsernamePasswordPlatformHubAccountResource}/>;
        case AccountType.AzureSubscription:
        case AccountType.AzureOidc:
        case AccountType.AzureServicePrincipal:
            return (<AzurePlatformHubAccountEdit azureEnvironments={loaderData.azureEnvironments} account={account as AzureServicePrincipalPlatformHubAccountResource | AzureSubscriptionPlatformHubAccountResource | AzureOidcAccountPlatformHubResource}/>);
        case AccountType.AmazonWebServicesAccount:
        case AccountType.AmazonWebServicesOidcAccount:
            return <AmazonWebServicesPlatformHubAccountEdit azureEnvironments={loaderData.azureEnvironments} account={account as AmazonWebServicesAccessKeyPlatformHubAccountResource | AmazonWebServicesOidcPlatformHubAccountResource}/>;
        case AccountType.GoogleCloudAccount:
            return <GoogleCloudPlatformHubAccountEdit azureEnvironments={loaderData.azureEnvironments} account={account as GoogleCloudPlatformHubAccountResource}/>;
        case AccountType.GenericOidcAccount:
            return <GenericOidcPlatformHubAccountEdit azureEnvironments={loaderData.azureEnvironments} account={account as GenericOidcPlatformHubAccountResource}/>;
        default:
            return <ErrorPanel message="Invalid account type"/>;
    }
}
