import type { TenantSummary } from "..";
import type { ResourceWithSlug } from "./ResourceWithSlug";
import type { IconResource } from "./iconResource";
import type { NamedResource, NewNamedResource } from "./namedResource";
import type { SpaceScopedResource, NewSpaceScopedResource } from "./spaceScopedResource";
interface TenantResourceShared {
    TenantTags: string[];
    ProjectEnvironments: {
        [projectId: string]: string[];
    };
}
interface TenantResourceLinks {
    Self: string;
    Variables: string;
}
export interface TenantResource extends NamedResource<TenantResourceLinks>, TenantResourceShared, SpaceScopedResource, ResourceWithSlug {
    ClonedFromTenantId: string | null;
    Description: string | null;
    IsDisabled: boolean | null;
    Icon?: IconResource;
}
export interface TenantResourceWithFrozenInfo extends TenantResource {
    IsFrozen: boolean | null;
}
export interface NewTenantResource extends NewNamedResource, NewSpaceScopedResource, TenantResourceShared {
    Description?: string;
}
export interface GetAllTenantSummariesAcrossAllSpacesResponse {
    Tenants: TenantSummary[];
}
export interface TagTestResult {
    [key: string]: {
        IsMatched: boolean;
        Reason: string;
        MissingTags: string[];
    };
}
export interface TenantEnvironmentMapping {
    TenantId: string;
    ProjectId: string;
    Environments: string[];
}
export interface ActiveFreeze {
    Id: string;
    Name: string;
    Environments: string[];
    TenantEnvironments: TenantEnvironmentMapping[];
}
export function isTenantWithFrozenInfo(tenant: TenantResource): tenant is TenantResourceWithFrozenInfo {
    return "IsFrozen" in tenant;
}
