import React from "react";
import type { ProcessTemplateDetails } from "~/areas/projects/components/Process/Contexts/ProcessController";
import useRequiredContext from "~/hooks/useRequiredContext";
type ProcessBlueprintsContextValue = ProcessTemplateDetails[] | "NotLoaded";
type ProcessBlueprintsRefreshContextValue = () => Promise<unknown>;
const ProcessBlueprintsContext = React.createContext<ProcessBlueprintsContextValue>("NotLoaded");
const RefreshProcessBlueprintsContext = React.createContext<ProcessBlueprintsRefreshContextValue | undefined>(undefined);
export function useProcessBlueprintsFromContext() {
    const maybeProcessBlueprints = useMaybeProcessBlueprintsFromContext();
    return maybeProcessBlueprints === "NotLoaded" ? [] : maybeProcessBlueprints;
}
export function useMaybeProcessBlueprintsFromContext(): ProcessBlueprintsContextValue {
    return useRequiredContext(ProcessBlueprintsContext, "ProcessBlueprintsContext");
}
export function useRefreshBlueprintsFromContext(): ProcessBlueprintsRefreshContextValue {
    return useRequiredContext(RefreshProcessBlueprintsContext, "ProcessBlueprintsRefreshContext");
}
type ProcessBlueprintsContextProviderProps = {
    blueprints: ProcessTemplateDetails[] | "NotLoaded";
    refreshBlueprints: () => Promise<unknown>;
};
export function ProcessBlueprintsContextProvider(props: React.PropsWithChildren<ProcessBlueprintsContextProviderProps>) {
    return (<ProcessBlueprintsContext.Provider value={props.blueprints}>
            <RefreshProcessBlueprintsContext.Provider value={props.refreshBlueprints}>{props.children}</RefreshProcessBlueprintsContext.Provider>
        </ProcessBlueprintsContext.Provider>);
}
