import { css } from "@emotion/css";
import { Callout, Checkbox, RadioButton, RadioButtonGroup } from "@octopusdeploy/design-system-components";
import { fontSize, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import { useQuery } from "@octopusdeploy/octopus-react-client";
import type { ActionProperties, BlueprintResource, ProcessTemplateListSummaryResource, ProcessTemplateVersionResource } from "@octopusdeploy/octopus-server-client";
import { first, isEmpty } from "lodash";
import * as React from "react";
import { useState } from "react";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import convertPropertyValueResourceToString from "~/components/convertPropertyValueResourceToString";
import { ExpandableFormSection, FormSection, FormSectionHeading, Select, Summary } from "~/components/form/index";
interface ProcessTemplateVersionSelectorProps {
    actionProperties: ActionProperties;
    doBusyTask: DoBusyTask;
    setUseSpecificVersion: (version: string, commit: string) => void;
    setUseVersioningOption: (versioningOption: VersioningOption) => void;
    removeVersionProperties: () => void;
    setProcessTemplateSlug: (slug: string) => void;
    isNew: boolean;
    currentProcessTemplate: BlueprintResource | undefined;
    busy?: boolean;
}
export enum VersioningOption {
    AlwaysUseLatest = "AlwaysUseLatest",
    SpecifiedVersion = "SpecifiedVersion"
}
export function ProcessTemplateVersionSelector({ setUseSpecificVersion, actionProperties, setUseVersioningOption, removeVersionProperties, setProcessTemplateSlug, isNew, currentProcessTemplate, busy }: ProcessTemplateVersionSelectorProps) {
    const selectedSlug = convertPropertyValueResourceToString(actionProperties["Octopus.Action.ProcessTemplate.Reference.Slug"]);
    const selectedVersion = convertPropertyValueResourceToString(actionProperties["Octopus.Action.ProcessTemplate.Reference.Version"]);
    const selectedVersioningOption = convertPropertyValueResourceToString(actionProperties["Octopus.Action.ProcessTemplate.Reference.VersioningOption"]);
    const [selectedVersionOption, setSelectedVersionOption] = useState<string | undefined>(isEmpty(selectedVersion) ? undefined : selectedVersion);
    const [selectedProcessTemplateOption, setSelectedProcessTemplateOption] = useState<string | undefined>(isEmpty(selectedSlug) ? undefined : selectedSlug);
    const { result: distinctTemplates } = useQuery((repository) => repository.Blueprints.summaries(), [], "Get Templates");
    const { result: allVersions } = useQuery(async (repository) => {
        let versions: ProcessTemplateVersionResource[] = [];
        if (selectedProcessTemplateOption) {
            versions = await repository.Blueprints.getPublishedVersions(selectedProcessTemplateOption, true);
        }
        return versions;
    }, [selectedProcessTemplateOption], "Get Template Versions");
    const distinctProcessTemplateOptions = distinctTemplates ? mapProcessTemplatesToProcessTemplateOptions(distinctTemplates) : [];
    const currentSelectedTemplateName = distinctTemplates?.find((v) => v.Slug === selectedProcessTemplateOption)?.Name;
    const isExpandedByDefault = isNew || !currentProcessTemplate || !busy;
    const title = selectedVersionOption ? "Change process template" : "Select process template";
    const summary = selectedVersionOption ? Summary.placeholder("Change process template by selecting one from the list") : null;
    const onProcessTemplateChanged = (value: string | undefined) => {
        if (value) {
            setSelectedVersionOption(undefined);
            setSelectedProcessTemplateOption(value);
            setProcessTemplateSlug(value);
            removeVersionProperties();
            if (!selectedVersioningOption) {
                setUseVersioningOption(VersioningOption.AlwaysUseLatest);
            }
        }
    };
    return (<>
            <ExpandableFormSection isExpandedByDefault={isExpandedByDefault} errorKey="selectedProcessTemplate" title={title} summary={summary}>
                <Select value={selectedProcessTemplateOption} allowClear={false} onChange={onProcessTemplateChanged} items={distinctProcessTemplateOptions} label="Select process template" placeholder={"Select Process Template"}/>
                {selectedProcessTemplateOption && (selectedVersioningOption === VersioningOption.AlwaysUseLatest || selectedVersion) && (<>
                        <ProcessTemplateNotFoundCallout processTemplate={currentProcessTemplate} busy={busy}/>
                    </>)}
            </ExpandableFormSection>
            {allVersions && selectedProcessTemplateOption && (<>
                    <FormSection title={"Template Name"} includeBorder={true} help={<div>{currentSelectedTemplateName}</div>}></FormSection>
                    <ProcessTemplateVersioningRadio allVersions={allVersions} currentSelectedTemplateName={currentSelectedTemplateName} setSelectedVersionOption={setSelectedVersionOption} setUseVersioningOption={setUseVersioningOption} removeVersionProperties={removeVersionProperties} selectedVersionOption={selectedVersionOption} setUseSpecificVersion={setUseSpecificVersion} actionProperties={actionProperties}/>
                </>)}
        </>);
}
interface Option {
    value: string;
    text: string;
}
function mapProcessTemplatesToProcessTemplateOptions(processTemplates: ProcessTemplateListSummaryResource[]): Option[] {
    return processTemplates.map((pt) => ({ value: pt.Slug, text: pt.Name }));
}
function mapVersionsToVersionOptions(versions: ProcessTemplateVersionResource[]): Option[] {
    return versions.map(mapVersionToVersionOption);
}
function mapVersionToVersionOption(version: ProcessTemplateVersionResource): Option {
    return { value: version.Version, text: `${version.Version} ${version.IsPreRelease ? "(Pre-Release)" : ""}` };
}
function mapToVersioningOption(value: string): VersioningOption {
    return value === VersioningOption.SpecifiedVersion ? VersioningOption.SpecifiedVersion : VersioningOption.AlwaysUseLatest;
}
interface ProcessTemplateNotFoundCalloutProps {
    processTemplate: BlueprintResource | undefined;
    busy?: boolean;
}
function ProcessTemplateNotFoundCallout({ processTemplate, busy }: ProcessTemplateNotFoundCalloutProps) {
    const shouldDisplay = !processTemplate && !busy;
    return (<>
            {shouldDisplay && (<Callout type={"danger"} title={"Process template not found"}>
                    <div>The selected version of the process template cannot be found.</div>
                </Callout>)}
        </>);
}
const styles = {
    helperText: css({ fontSize: fontSize.xSmall, color: themeTokens.color.text.secondary, marginBottom: space["12"] }),
    versionSelection: css({ marginLeft: space["32"] }),
};
interface ProcessTemplateVersionRadioProps {
    allVersions: ProcessTemplateVersionResource[];
    currentSelectedTemplateName: string | undefined;
    setSelectedVersionOption: (value: string | undefined) => void;
    setUseVersioningOption: (value: VersioningOption) => void;
    removeVersionProperties: () => void;
    selectedVersionOption: string | undefined;
    setUseSpecificVersion: (version: string, commit: string) => void;
    actionProperties: ActionProperties;
}
function ProcessTemplateVersioningRadio({ allVersions, currentSelectedTemplateName, setSelectedVersionOption, setUseVersioningOption, removeVersionProperties, selectedVersionOption, setUseSpecificVersion, actionProperties, }: ProcessTemplateVersionRadioProps) {
    const selectedVersioningOption = convertPropertyValueResourceToString(actionProperties["Octopus.Action.ProcessTemplate.Reference.VersioningOption"]);
    const currentVersion = allVersions.length > 0 ? allVersions.find((v) => v.Version === selectedVersionOption) : undefined;
    const isOnlyPreRelease = allVersions.length > 0 ? allVersions.every((v) => v.IsPreRelease) : false;
    const [includePreRelease, setIncludePreRelease] = useState<boolean>(currentVersion === undefined ? false : currentVersion.IsPreRelease);
    const [versioningOption, setVersioningOption] = useState<VersioningOption>(mapToVersioningOption(selectedVersioningOption));
    const filteredVersions = includePreRelease ? allVersions : allVersions.filter((v) => !v.IsPreRelease);
    const versionOptions = filteredVersions ? mapVersionsToVersionOptions(filteredVersions) : [];
    const latestVersionOfSelectedTemplate = first(filteredVersions);
    const summary = versioningOption === VersioningOption.AlwaysUseLatest ? Summary.placeholder("Always use the latest version") : selectedVersionOption ? Summary.placeholder(`Use version ${selectedVersionOption}`) : Summary.placeholder("Select a version");
    const onVersioningOptionChanged = (value: VersioningOption) => {
        removeVersionProperties();
        setVersioningOption(value);
        setUseVersioningOption(value);
        setSelectedVersionOption(undefined);
        if (value === VersioningOption.SpecifiedVersion) {
            onProcessTemplateVersionChanged(selectedVersionOption);
        }
    };
    const onProcessTemplateVersionChanged = (value: string | undefined) => {
        if (value) {
            const currentVersion = allVersions.find((v) => v.Version === value);
            if (currentVersion) {
                setSelectedVersionOption(value);
                setUseSpecificVersion(value, currentVersion.GitCommit);
            }
        }
    };
    const onUpdateToLatestVersion = () => {
        if (latestVersionOfSelectedTemplate) {
            onProcessTemplateVersionChanged(latestVersionOfSelectedTemplate.Version);
        }
    };
    const onIncludePreReleaseChanged = () => {
        setIncludePreRelease(!includePreRelease);
        setSelectedVersionOption(undefined);
        removeVersionProperties();
    };
    return (<>
            <FormSectionHeading title="Versioning"/>
            {isOnlyPreRelease ? (<Callout type={"information"} title={"Only pre-release versions of this template are available."}>
                    To use this template, select an available version.
                </Callout>) : (versioningOption === VersioningOption.SpecifiedVersion &&
            latestVersionOfSelectedTemplate &&
            selectedVersionOption !== latestVersionOfSelectedTemplate.Version && (<Callout type={"information"} title={`Version ${latestVersionOfSelectedTemplate.Version} of ${currentSelectedTemplateName} is available.`}>
                        You can continue to use this version of the template or{" "}
                        <a href="#" onClick={(e) => {
                e.preventDefault();
                onUpdateToLatestVersion();
            }}>
                            <strong>update to the latest version</strong>
                        </a>
                        .
                    </Callout>))}
            <ExpandableFormSection title={"Template Version"} summary={summary} errorKey={selectedVersioningOption} isExpandedByDefault={true}>
                <RadioButtonGroup value={versioningOption} onChange={onVersioningOptionChanged}>
                    <RadioButton label="Always use the latest version" value={VersioningOption.AlwaysUseLatest}/>
                    <span className={styles.helperText}>Use the latest version from the main branch. Updating may impact deployment processes.</span>
                    <RadioButton label="Use a specified version" value={VersioningOption.SpecifiedVersion}/>
                </RadioButtonGroup>
                {versioningOption === VersioningOption.SpecifiedVersion && (<div className={styles.versionSelection}>
                        <Select value={selectedVersionOption} allowClear={false} onChange={onProcessTemplateVersionChanged} items={versionOptions} placeholder={"Select Version"} sortItems={false}/>
                        <Checkbox label={"Include pre-releases"} value={includePreRelease} onChange={onIncludePreReleaseChanged}/>{" "}
                    </div>)}
            </ExpandableFormSection>
        </>);
}
