import { css } from "@emotion/css";
import { space, text, themeTokens, borderRadius, lineHeight, fontWeight } from "@octopusdeploy/design-system-tokens";
import { useInlineStatusQuery } from "@octopusdeploy/octopus-react-client";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useState } from "react";
import DateFormatter from "~/utils/DateFormatter";
import { DeploymentStatus } from "./LiveAppIcons";
import { LiveDeploymentStatusIcon } from "./LiveDeploymentStatusIcon";
import { LiveStatusIcon } from "./LiveStatusIcon";
import { liveStatusSummaryText, rollupDeploymentStatus } from "./liveStatusCalculators";
interface LiveStatusSummaryBarProps {
    environmentId: string;
    project: ProjectResource;
    tenantId?: string;
}
export function LiveStatusSummaryBar(props: LiveStatusSummaryBarProps) {
    const [rolledUpDeploymentState, setRolledUpDeploymentState] = useState<DeploymentStatus>();
    const liveStatus = useInlineStatusQuery(async (repo) => {
        return await repo.KubernetesLiveStatus.getRollupForApplicationInstance(props.project.Id, props.environmentId, props.tenantId);
    }, [props.project.Id, props.environmentId, props.tenantId], "Live Status Rollup", {
        refetchIntervalInMs: 1000,
    }).result;
    const latestDeployment = useInlineStatusQuery(async (repo) => {
        const deployments = await repo.Releases.getLatest(props.project);
        const latestForEnv = deployments.filter((d) => d.EnvironmentId === props.environmentId);
        if (props.tenantId !== undefined) {
            const latestForTenant = latestForEnv.filter((d) => d.TenantId === props.tenantId);
            setRolledUpDeploymentState(rollupDeploymentStatus(latestForTenant[0].State));
            return latestForTenant[0];
        }
        else {
            const latestForTenant = latestForEnv.filter((d) => d.TenantId === null || d.TenantId === undefined);
            setRolledUpDeploymentState(rollupDeploymentStatus(latestForTenant[0].State));
            return latestForTenant[0];
        }
    }, [props.project, props.environmentId, props.tenantId], "Latest Deployment", {
        refetchIntervalInMs: 1000,
    }).result;
    function LiveStatusContent() {
        if (!liveStatus) {
            return <></>;
        }
        return (<div className={styles.iconStatusCard}>
                <div className={styles.iconStatusCardIcon}>
                    <LiveStatusIcon status={liveStatus.Status} size={48}/>
                </div>
                <div className={styles.iconStatusCardTitle}>{liveStatus.Status}</div>
                <div className={styles.iconStatusCardStatus}>Last updated {DateFormatter.momentAgo(liveStatus.LastUpdated.toString())}</div>
            </div>);
    }
    function LastDeploymentContent() {
        if (!latestDeployment || !rolledUpDeploymentState) {
            return <></>;
        }
        const deploymentStatusText = rolledUpDeploymentState === DeploymentStatus.Progressing ? <></> : <span>Completed {DateFormatter.momentAgo(latestDeployment.CompletedTime?.toString() ?? "")}</span>;
        return (<div className={styles.iconStatusCard}>
                <div className={styles.iconStatusCardIcon}>
                    <LiveDeploymentStatusIcon status={rolledUpDeploymentState} size={48}/>
                </div>
                <div className={styles.iconStatusCardTitle}>{latestDeployment.ReleaseVersion}</div>
                <div className={styles.iconStatusCardStatus}>{deploymentStatusText}</div>
            </div>);
    }
    function StatusSummaryContent() {
        if (!rolledUpDeploymentState || !liveStatus?.Status) {
            return <></>;
        }
        return <span>{liveStatusSummaryText(rolledUpDeploymentState, liveStatus.Status)}</span>;
    }
    return (<div className={styles.summaryBar}>
            <LiveStatusCard title="Live Status">{LiveStatusContent()}</LiveStatusCard>
            <LiveStatusCard title="Last Deployment">{LastDeploymentContent()}</LiveStatusCard>
            <LiveStatusCard title="Status Summary">{StatusSummaryContent()}</LiveStatusCard>
        </div>);
}
interface LiveStatusCardProps {
    title: string;
    children: JSX.Element;
}
export function LiveStatusCard(props: LiveStatusCardProps) {
    return (<div className={styles.liveStatusCard}>
            <div className={styles.liveStatusCardTitle}>{props.title}</div>
            <div>{props.children}</div>
        </div>);
}
const styles = {
    summaryBar: css({
        background: themeTokens.color.background.secondary.default,
        display: "flex",
        flexDirection: "row",
    }),
    liveStatusCard: css({
        background: themeTokens.color.background.primary.default,
        flex: 1,
        height: "120px",
        borderRadius: borderRadius.large,
        margin: space[16],
        padding: space[16],
    }),
    liveStatusCardTitle: css({
        font: text.heading.xSmall,
        lineHeight: lineHeight.xSmall,
        color: themeTokens.color.text.secondary,
        fontWeight: fontWeight[700],
        textTransform: "uppercase",
        marginBottom: space[12],
    }),
    iconStatusCard: css({
        display: "grid",
        gridTemplateColumns: "60px auto",
        gridTemplateRows: "24px 24px",
    }),
    iconStatusCardIcon: css({
        gridRowStart: 1,
        gridRowEnd: 2,
    }),
    iconStatusCardTitle: css({
        gridRow: 1,
        gridColumn: 2,
        font: text.regular.bold.large,
        color: themeTokens.color.text.primary,
    }),
    iconStatusCardStatus: css({
        gridRow: 2,
        gridColumn: 2,
        font: text.regular.default.medium,
        color: themeTokens.color.text.secondary,
    }),
};
