import type { UnknownQueryParam } from "@octopusdeploy/portal-routes";
import { QueryStateMode, useQueryStringParams } from "@octopusdeploy/portal-routes";
import type { ReactNode } from "react";
import React from "react";
import type { RouteProps } from "react-router";
import ReloadableRoute from "~/components/ReloadableRoute";
import { useSetChameleonRouteParamsEffect } from "~/inAppMessaging/InAppMessaging";
import { Page } from "../Page";
import type { PageRegistration } from "./PageRegistration";
import { decodeRouteParams } from "./decodeRouteParams";
export interface RoutePageProps<RouteParams, QueryParams extends UnknownQueryParam[], LoaderData, LoaderContext, PageContext> extends Omit<RouteProps, "render"> {
    path: string;
    exact?: boolean;
    pageRegistration: PageRegistration<RouteParams, QueryParams, LoaderData, LoaderContext, PageContext>;
    loaderContext: LoaderContext;
    pageContext: PageContext;
    renderPageLayout?: (page: ReactNode) => ReactNode;
}
export function RoutePage<RouteParams, QueryParams extends UnknownQueryParam[], LoaderData, LoaderContext, PageContext>({ path, loaderContext, exact, pageRegistration, pageContext, renderPageLayout, ...otherProps }: RoutePageProps<RouteParams, QueryParams, LoaderData, LoaderContext, PageContext>) {
    const [queryParams, setQueryParams] = useQueryStringParams<QueryParams>(pageRegistration.route.queryParameters, QueryStateMode.ReplaceHistory);
    useSetChameleonRouteParamsEffect();
    // The ReloadableRoute requires some props that directly come from react router, injected by the Switch (i.e. the `computedMatch`). The RoutePage sits in between these, so should forward any extra props to the ReloadableRoute.
    return (<ReloadableRoute path={path} exact={exact} render={(routeProps) => {
            const routeParams = decodeRouteParams(routeProps.match.params);
            const page = (<Page<RouteParams, QueryParams, LoaderData, LoaderContext, PageContext> pageRegistration={pageRegistration} routeParams={routeParams} queryParams={queryParams} setQueryParams={setQueryParams} loaderContext={loaderContext} pageContext={pageContext}/>);
            return renderPageLayout ? renderPageLayout(page) : page;
        }} {...otherProps}/>);
}
