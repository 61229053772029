import React from "react";
import { ActiveStatusIcon } from "~/components/ActiveStatusIcon/ActiveStatusIcon";
import { DisabledStatusIcon } from "~/components/ActiveStatusIcon/DisabledStatusIcon";
import { GreenTickIcon } from "./GreenTickIcon";
import type { TaskState } from "./ProjectStatusBar";
export const ProjectStatusIcon = ({ state }: {
    state: TaskState;
}): JSX.Element => {
    if (state === "Current")
        return <ActiveStatusIcon />;
    if (state === "Pending")
        return <DisabledStatusIcon />;
    if (state === "Done")
        return <GreenTickIcon />;
    return <></>;
};
