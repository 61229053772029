import React from "react";
import * as LiveAppStatusIcon from "./LiveAppIcons";
interface LiveStatusIconProps {
    status: string;
    size: LiveAppStatusIcon.LiveStatusIconSize;
}
const LiveStatusToVisuals = (status: string, size: LiveAppStatusIcon.LiveStatusIconSize): JSX.Element => {
    switch (status) {
        case "OutOfSync":
            return LiveAppStatusIcon.OutOfSyncIcon(size);
        case "Progressing":
            return LiveAppStatusIcon.ProgressingIcon(size);
        case "Degraded":
            return LiveAppStatusIcon.DegradedIcon(size);
        case "Missing":
            return LiveAppStatusIcon.MissingIcon(size);
        case "Healthy":
            return LiveAppStatusIcon.HealthyIcon(size);
        case "Unavailable":
            return LiveAppStatusIcon.UnavailableIcon(size);
        case "Waiting":
            return LiveAppStatusIcon.WaitingIcon(size);
        case "Unknown":
        default:
            return LiveAppStatusIcon.UnknownIcon(size);
    }
};
export const LiveStatusIcon = (props: LiveStatusIconProps) => {
    if (props.status === "NotSupported") {
        return <></>;
    }
    return LiveStatusToVisuals(props.status, props.size);
};
