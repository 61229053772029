import { Tooltip } from "@octopusdeploy/design-system-components";
import { ExclamationTriangleIcon } from "@octopusdeploy/design-system-icons";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
interface MissingVariablesIcon {
    show: boolean;
    withoutTooltip?: boolean;
}
const MissingVariablesIcon: React.StatelessComponent<MissingVariablesIcon> = (props) => {
    const AlertIcon = () => <ExclamationTriangleIcon size={24} color={themeTokens.color.icon.attention}/>;
    if (!props.show) {
        return null;
    }
    if (props.withoutTooltip) {
        return <AlertIcon />;
    }
    return (<Tooltip content={`Some of the required variable values have not been set`}>
            <AlertIcon />
        </Tooltip>);
};
MissingVariablesIcon.displayName = "MissingVariablesIcon"
export default MissingVariablesIcon;
