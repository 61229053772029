/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { PageAction } from "@octopusdeploy/design-system-components";
import type { AccountType, AzureEnvironment, PlatformHubAccountResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import MarkdownEditor from "app/components/form/MarkdownEditor/MarkdownEditor";
import { cloneDeep } from "lodash";
import React from "react";
import styles from "~/areas/infrastructure/components/AccountEdit/style.module.less";
import { repository } from "~/clientInstance";
import { FormBaseComponent, type FormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import { LegacyForm } from "~/components/FormPaperLayout/LegacyForm";
import Markdown from "~/components/Markdown/index";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { ConvertedOverflowMenuItems } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import { OverflowMenuConverterVNext } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import { PageContent } from "~/components/PageContent/PageContent";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { ExpandableFormSection, required, Summary } from "~/components/form/index";
import NameSummaryWithSlug from "~/primitiveComponents/form/Slugs/NameSummaryWithSlug";
import SlugEditor from "~/primitiveComponents/form/Slugs/SlugEditor";
import Text from "~/primitiveComponents/form/Text/Text";
interface PlatformHubAccountEditModel {
    name: string;
    slug: string;
    description: string;
}
interface PlatformHubAccountData<TAccountResource extends PlatformHubAccountResource> {
    account: TAccountResource;
}
const IsNew = "IsNew";
const defaultModel: PlatformHubAccountEditModel = {
    name: "",
    slug: "",
    description: "",
};
type PlatformHubAccountEditProps<TAccountResource extends PlatformHubAccountResource> = {
    account: TAccountResource | typeof IsNew;
    azureEnvironments: AzureEnvironment[];
    correlationId?: string;
};
interface PlatformHubAccountEditState<TAccountResource extends PlatformHubAccountResource, TModel extends PlatformHubAccountEditModel> extends FormBaseComponentState<TModel> {
    deleted: boolean;
    showTestDialog: boolean;
    accountData?: PlatformHubAccountData<TAccountResource> | undefined;
}
abstract class PlatformHubAccountEditInternalBase<TAccountResource extends PlatformHubAccountResource, TState extends PlatformHubAccountEditState<TAccountResource, TModel>, TModel extends PlatformHubAccountEditModel> extends FormBaseComponent<PlatformHubAccountEditProps<TAccountResource>, TState, TModel> {
    constructor(props: PlatformHubAccountEditProps<TAccountResource>) {
        super(props);
    }
    protected initialState() {
        if (this.props.account === IsNew) {
            return {
                model: this.buildModel() as TModel,
                cleanModel: cloneDeep(this.buildModel()) as TModel,
                deleted: false,
                showTestDialog: false,
            };
        }
        else {
            return {
                model: this.buildModel(this.props.account) as TModel,
                cleanModel: cloneDeep(this.buildModel(this.props.account)) as TModel,
                accountData: {
                    account: this.props.account,
                },
                deleted: false,
                showTestDialog: false,
            };
        }
    }
    abstract getTestDialog(): React.ReactNode;
    abstract getPageActions(): PageAction[];
    abstract customExpandableFormSections(): React.ReactElement[];
    abstract getPartialResource(): (Partial<TAccountResource> & {
        AccountType: AccountType;
    }) | undefined;
    abstract getPartialModel(account?: TAccountResource): Partial<TModel> | undefined;
    abstract onSuccess?(account: TAccountResource): void;
    abstract onTestDone(success: boolean): void;
    getAccountSummary(): React.ReactElement | null {
        return null;
    }
    buildModel(account?: TAccountResource | undefined): PlatformHubAccountEditModel {
        const partial = this.getPartialModel(account);
        if (!account) {
            return {
                ...partial,
                ...defaultModel,
            };
        }
        return {
            ...partial,
            name: account.Name,
            slug: account.Slug ?? "",
            description: account.Description,
        };
    }
    handleDeleteConfirm = async (account: PlatformHubAccountResource) => {
        await repository.PlatformHubRepository.deleteAccount(account);
        this.setState(() => {
            return {
                model: null,
                cleanModel: null, //reset model so that dirty state doesn't prevent navigation
                deleted: true,
            };
        });
        return true;
    };
    protected savePermission() {
        return { permission: Permission.PlatformHubEdit };
    }
    saveAccount = async (performTest: boolean, isNewAccount: boolean) => {
        await this.doBusyTask(async () => {
            let model = this.state.model;
            let existingAccount;
            if (this.state.accountData !== undefined) {
                existingAccount = this.state.accountData.account;
            }
            const account: PlatformHubAccountResource = {
                ...(existingAccount as PlatformHubAccountResource),
                ...(this.getPartialResource() as {
                    AccountType: AccountType;
                }),
                Name: model.name,
                Slug: model.slug,
                Description: model.description,
            };
            const result = isNewAccount ? await repository.PlatformHubRepository.createAccount(account) : await repository.PlatformHubRepository.modifyAccount(account);
            model = this.buildModel(account as TAccountResource) as TModel;
            this.setState({
                ...this.state,
                accountData: {
                    account: result as TAccountResource,
                },
                model,
                cleanModel: cloneDeep(model),
                deleted: false,
                showTestDialog: performTest,
            });
            if (!performTest && this.onSuccess)
                this.onSuccess(result as TAccountResource);
        });
    };
    nameSummary() {
        return this.state.model.name ? Summary.summary(<NameSummaryWithSlug name={this.state.model.name} slug={this.state.model.slug}/>) : Summary.placeholder("Please enter a name for your account");
    }
    descriptionSummary() {
        return this.state.model.description ? Summary.summary(<Markdown markup={this.state.model.description}/>) : Summary.placeholder("No account description provided");
    }
    getOverFlowActions(account?: PlatformHubAccountResource): ConvertedOverflowMenuItems {
        if (!account)
            return { menuItems: [], dialogs: [] };
        return OverflowMenuConverterVNext.convertAll([
            OverflowMenuItems.deleteItemDefault("account", () => this.handleDeleteConfirm(account), {
                permission: Permission.PlatformHubEdit,
            }),
            [
                OverflowMenuItems.navItem("Audit Trail", links.auditPage.generateUrl({ regardingAny: [account.Id] }), {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ],
        ]);
    }
    innerRender() {
        const accountData = this.state.accountData;
        if (this.state.deleted) {
            return <InternalRedirect to={links.platformHubAccountsPage.generateUrl()}/>;
        }
        const isNewAccount = accountData === undefined;
        return (<>
                {this.getTestDialog()}
                {this.getAccountSummary()}
                {this.state.model && (<div className={styles.expanderContainer}>
                        <ExpandableFormSection errorKey="name" title="Name" focusOnExpandAll summary={this.nameSummary()} help="A short, memorable, unique name for this account." containerKey="AccountEdit">
                            <Text value={this.state.model.name} onChange={(name) => this.setModelState({ name })} label="Account name" validate={required("Please enter an account name")} error={this.getFieldError("name")} autoFocus={true}/>
                            {!isNewAccount && (<SlugEditor value={this.state.model.slug} name={this.state.model.name} originalSlug={this.state.cleanModel?.slug ?? ""} onChange={(slug) => this.setModelState({ slug })} label={"Account slug"} validate={required("Please enter an account slug")} error={this.getFieldError("slug")}/>)}
                        </ExpandableFormSection>
                        <ExpandableFormSection errorKey="description" title="Description" summary={this.descriptionSummary()} help="A summary explaining the use of the account to other users." containerKey="AccountEdit">
                            <MarkdownEditor value={this.state.model.description} label="Account description" onChange={(description) => this.setModelState({ description })}/>
                        </ExpandableFormSection>

                        {this.customExpandableFormSections()}
                    </div>)}
            </>);
    }
    static displayName = "PlatformHubAccountEditInternalBase";
}
abstract class PlatformHubAccountEditBase<TAccountResource extends PlatformHubAccountResource, TModel extends PlatformHubAccountEditModel> extends PlatformHubAccountEditInternalBase<TAccountResource, PlatformHubAccountEditState<TAccountResource, TModel>, TModel> {
    constructor(props: PlatformHubAccountEditProps<TAccountResource>) {
        super(props);
        this.state = this.initialState();
    }
    // We redirect to another page via an `<InternalRedirect />` instead of any particular action here
    onSuccess? = undefined;
    onTestDone(success: boolean) {
        this.setState({
            showTestDialog: false,
        });
    }
    render() {
        const accountData = this.state.accountData;
        const isNewAccount = accountData === undefined;
        const savePermission = this.savePermission();
        const title = accountData?.account.Name ?? "Create Account";
        const breadcrumbPath = links.platformHubAccountsPage.generateUrl();
        const overflowMenu = this.getOverFlowActions(accountData?.account);
        return (<LegacyForm model={this.state.model} cleanModel={this.state.cleanModel} saveText={"Account details changed"} onSaveClick={() => this.saveAccount(false, isNewAccount)} savePermission={savePermission}>
                {({ FormContent, createSaveAction }) => (<PageContent header={{
                    title,
                    breadcrumbs: [{ label: "Accounts", pageUrl: breadcrumbPath }],
                    pageActions: isAllowed(savePermission) ? this.getPageActions() : undefined,
                    overflowActions: overflowMenu.menuItems,
                    primaryAction: createSaveAction({}),
                }} busy={this.state.busy} errors={this.errors}>
                        {overflowMenu.dialogs}
                        <FormContent expandAllOnMount={isNewAccount} containerKey={"AccountEdit"}>
                            {!this.state.showTestDialog && this.state.accountData && <InternalRedirect to={links.editPlatformHubAccountPage.generateUrl({ accountId: this.state.accountData.account.Id })}/>}
                            {this.innerRender()}
                        </FormContent>
                    </PageContent>)}
            </LegacyForm>);
    }
}
export { PlatformHubAccountEditModel };
export default PlatformHubAccountEditBase;
