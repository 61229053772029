import type { SensitiveValue, UsernamePasswordPlatformHubAccountResource } from "@octopusdeploy/octopus-server-client";
import { AccountType } from "@octopusdeploy/octopus-server-client";
import React from "react";
import type { PlatformHubAccountEditModel } from "~/areas/blueprints/accounts/PlatformHubAccountEditBase";
import PlatformHubAccountEditBase from "~/areas/blueprints/accounts/PlatformHubAccountEditBase";
import Sensitive, { ObfuscatedPlaceholder } from "~/components/form/Sensitive/Sensitive";
import { ExpandableFormSection, FormSectionHeading, Summary } from "~/components/form/index";
import Text from "~/primitiveComponents/form/Text/Text";
interface UsernamePasswordPlatformHubModel extends PlatformHubAccountEditModel {
    username: string;
    password: SensitiveValue;
}
class UsernamePasswordPlatformHubAccountEdit extends PlatformHubAccountEditBase<UsernamePasswordPlatformHubAccountResource, UsernamePasswordPlatformHubModel> {
    getPartialModel(account?: UsernamePasswordPlatformHubAccountResource) {
        return getPartialModel(account);
    }
    getPartialResource() {
        return getPartialResource(this.state.model);
    }
    getPageActions() {
        return [];
    }
    getTestDialog() {
        return null;
    }
    customExpandableFormSections() {
        return customExpandableFormSections(this.state.model, (username) => this.setModelState({ username }), (password) => this.setModelState({ password }), this.getFieldError);
    }
}
function getPartialModel(account?: UsernamePasswordPlatformHubAccountResource): Partial<UsernamePasswordPlatformHubModel> {
    if (!account) {
        return {
            username: "",
            password: {
                HasValue: false,
            },
        };
    }
    return {
        username: account.Username,
        password: account.Password,
    };
}
function getPartialResource(modelState: UsernamePasswordPlatformHubModel): Partial<UsernamePasswordPlatformHubAccountResource> & {
    AccountType: AccountType;
} {
    return {
        AccountType: AccountType.UsernamePassword,
        Username: modelState.username,
        Password: modelState.password,
    };
}
function usernameSummary(modelState: UsernamePasswordPlatformHubModel) {
    return modelState.username ? Summary.summary(modelState.username) : Summary.placeholder("No username provided");
}
function passwordSummary(modelState: UsernamePasswordPlatformHubModel) {
    return modelState.password && modelState.password.HasValue ? Summary.summary(ObfuscatedPlaceholder) : Summary.placeholder("No password provided");
}
function customExpandableFormSections(modelState: UsernamePasswordPlatformHubModel, onUsernameChange: (newValue: string) => void, onPasswordChange: (newValue: SensitiveValue) => void, getFieldError: (field: string) => string): React.ReactElement[] {
    const baseElements: React.ReactElement[] = [];
    baseElements.push(<FormSectionHeading title="Credentials" key={"header"}/>, <ExpandableFormSection key="username" errorKey="username" title="Username" summary={usernameSummary(modelState)} help="The username to use when authenticating against the remote host." containerKey="AccountEdit">
            <Text value={modelState.username} onChange={onUsernameChange} label="Username" error={getFieldError("Username")}/>
        </ExpandableFormSection>, <ExpandableFormSection key="password" errorKey="password" title="Password" summary={passwordSummary(modelState)} help="The password to use to when authenticating against the remote host." containerKey="AccountEdit">
            <Sensitive value={modelState.password} onChange={onPasswordChange} label="Password"/>
        </ExpandableFormSection>);
    return baseElements;
}
export default UsernamePasswordPlatformHubAccountEdit;
