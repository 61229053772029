import { css, cx } from "@emotion/css";
// eslint-disable-next-line @octopusdeploy/custom-portal-rules/no-restricted-imports
import { Collapse } from "@material-ui/core";
import { Button } from "@octopusdeploy/design-system-components";
import { ChevronDownIcon, ChevronUpIcon } from "@octopusdeploy/design-system-icons";
import { borderWidth, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import { noOp } from "@octopusdeploy/utilities";
import pluralize from "pluralize";
import type { PropsWithChildren } from "react";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
interface CollapsibleFilterProps {
    filteredCount?: number | null;
    totalCount: number;
    secondaryContent?: JSX.Element;
    actions?: JSX.Element;
    entityName?: string;
    ariaLabel?: string;
    withBorder?: boolean;
}
export default function CollapsibleFilter({ filteredCount, totalCount, children, secondaryContent, actions, entityName, ariaLabel, withBorder = false }: PropsWithChildren<CollapsibleFilterProps>) {
    const dispatchAction = useAnalyticActionDispatch();
    const isLargerThanIpad = useMediaQuery({ query: `(min-width: 811px)` });
    const [expanded, setExpanded] = useState(false);
    const handleOnExpandCollapse = () => {
        setExpanded(!expanded);
        dispatchAction("Toggle Filters", { resource: "Filters", action: Action.View });
    };
    return (<div className={withBorder ? toolbarBorder : undefined}>
            <div className={cx(toolbarHeaderStyles, { [toolbarHeaderMobileStyles]: !isLargerThanIpad })} aria-label={ariaLabel}>
                <ResultCount filteredCount={filteredCount} totalCount={totalCount} entityName={entityName}/>
                {secondaryContent ? <VerticalDivider /> : null}
                {secondaryContent}
                <div className={alignRightStyles}>
                    {actions}
                    {actions && children ? <VerticalDivider /> : null}
                    {children ? <ExpandCollapseToggle expanded={expanded} onExpandCollapse={handleOnExpandCollapse}/> : null}
                </div>
            </div>
            {children ? (<Collapse in={expanded} timeout="auto" unmountOnExit={true}>
                    {children}
                </Collapse>) : null}
        </div>);
}
interface CountProps {
    filteredCount?: number | null;
    totalCount: number;
    entityName?: string;
}
function ResultCount({ totalCount, entityName = "result", filteredCount = null }: CountProps) {
    const isFiltered = filteredCount !== null;
    return isFiltered ? (<div className={filteredResultStyles}>
            {filteredCount.toLocaleString()} of {totalCount.toLocaleString()} {pluralize(entityName.toLowerCase(), totalCount)}
        </div>) : (<div className={resultStyles}>
            {totalCount.toLocaleString()} {pluralize(entityName.toLowerCase(), totalCount)}
        </div>);
}
interface ExpandCollapseToggleProps {
    expanded: boolean;
    onExpandCollapse?: () => void;
}
function ExpandCollapseToggle({ expanded, onExpandCollapse }: ExpandCollapseToggleProps) {
    const icon = expanded ? <ChevronUpIcon size={20}/> : <ChevronDownIcon size={20}/>;
    return (<div className={alignRightStyles}>
            <Button label={expanded ? "Collapse filters" : "Expand filters"} onClick={onExpandCollapse ?? noOp} importance="quiet" rightIcon={icon}/>
        </div>);
}
export function VerticalDivider() {
    return <div className={dividerStyles}>&nbsp;</div>;
}
const borderColor = `${borderWidth[1]} solid ${themeTokens.color.border.primary}`;
const toolbarBorder = css({
    backgroundColor: themeTokens.color.background.primary.default,
    border: borderColor,
});
const toolbarHeaderStyles = css({
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    flexDirection: "row",
    padding: "0 1rem",
});
const toolbarHeaderMobileStyles = css({
    flexWrap: "wrap",
});
const alignRightStyles = css({
    marginLeft: "auto",
    display: "inherit",
    gap: "1rem",
});
const resultStyles = css({
    font: text.heading.medium,
    color: themeTokens.color.text.primary,
});
const filteredResultStyles = css({
    font: text.regular.bold.large,
    color: themeTokens.color.text.primary,
});
const dividerStyles = css({
    position: "relative",
    paddingTop: "0.2rem",
    paddingBottom: "0.2rem",
    ":after": {
        content: "''",
        position: "absolute",
        right: 0,
        top: "0",
        height: "100%",
        borderRight: borderColor,
    },
});
