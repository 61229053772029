import type { KubernetesObjectStatus } from "@octopusdeploy/octopus-server-client";
export function sortObjectStatusByObjectType(data: [
    string,
    KubernetesObjectStatus[]
][]) {
    const kubernetesObjectTypeOrder = "Pod,ReplicaSet,Deployment,StatefulSet,DaemonSet,Job,CronJob,Service,EndpointSlice,Ingress,ConfigMap,Secret,";
    data.sort(([a], [b]) => {
        const aIndex = kubernetesObjectTypeOrder.indexOf(`${a},`);
        const bIndex = kubernetesObjectTypeOrder.indexOf(`${b},`);
        if (aIndex === -1 && bIndex === -1) {
            // We fall back to order alphabetically when object type is not found
            return a.localeCompare(b);
        }
        if (aIndex === bIndex) {
            return 0;
        }
        if (aIndex === -1) {
            return 1;
        }
        if (bIndex === -1) {
            return -1;
        }
        if (aIndex > bIndex) {
            return 1;
        }
        return -1;
    });
}
