import type { ActivityElement } from "@octopusdeploy/octopus-server-client";
import { ActivityStatus } from "@octopusdeploy/octopus-server-client";
import moment from "moment";
export function getStepActivity(stepName: string, activityLogs: Pick<ActivityElement, "Children">[]): Pick<ActivityElement, "Name" | "Status" | "Ended" | "Started"> | undefined {
    function stepNameDeconstruct(): [
        stepParentNumber: string,
        stepSanitisedName: string
    ] {
        const leftPart = stepName.substring(0, stepName.indexOf(":"));
        const childNumberIndex = leftPart.indexOf(".");
        const parentNumberPart = childNumberIndex === -1 ? leftPart : leftPart.substring(0, childNumberIndex);
        const stepParentNumber = `${parentNumberPart}: `;
        return [stepParentNumber, stepName.substring(leftPart.length + 2)];
    }
    function lookupActivityInChildren(name: string, activity: ActivityElement): ActivityElement[] {
        if (activity.Name === name) {
            return [activity];
        }
        const results: ActivityElement[] = [];
        for (const childActivity of activity.Children) {
            results.push(...lookupActivityInChildren(name, childActivity));
        }
        return results;
    }
    const [stepParentNumber, stepSanitisedName] = stepNameDeconstruct();
    for (const activityLog of activityLogs) {
        for (const activity of activityLog.Children) {
            if (activity.Name.startsWith(stepParentNumber)) {
                if (activity.Name === stepName) {
                    return activity;
                }
                const results = lookupActivityInChildren(stepSanitisedName, activity);
                if (results.length === 0) {
                    return undefined;
                }
                if (results.length === 1) {
                    return results[0];
                }
                let started: string | undefined = moment().add(99, "y").toString();
                let ended: string | undefined = moment().subtract(99, "y").toString();
                let status: ActivityStatus = ActivityStatus.Pending;
                for (const result of results) {
                    if (result.Started && moment(result.Started) < moment(started)) {
                        started = result.Started;
                    }
                    if (!result.Ended || ended === undefined) {
                        ended = undefined;
                    }
                    else if (moment(result.Ended) > moment(ended)) {
                        ended = result.Ended;
                    }
                }
                if (results.every((item) => item.Status === ActivityStatus.Skipped)) {
                    status = ActivityStatus.Skipped;
                }
                else if (results.some((item) => item.Status === ActivityStatus.Failed)) {
                    status = ActivityStatus.Failed;
                }
                else if (results.every((item) => item.Status === ActivityStatus.Success || item.Status === ActivityStatus.SuccessWithWarning)) {
                    status = ActivityStatus.Success;
                }
                else if (results.some((item) => item.Status === ActivityStatus.Running)) {
                    status = ActivityStatus.Running;
                }
                return { Name: stepSanitisedName, Started: started, Ended: ended, Status: status };
            }
        }
    }
    return undefined;
}
