/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Callout } from "@octopusdeploy/design-system-components";
import type { GenericOidcPlatformHubAccountResource, ReferenceDataItem } from "@octopusdeploy/octopus-server-client";
import { AccountType } from "@octopusdeploy/octopus-server-client";
import { cloneDeep } from "lodash";
import React from "react";
import PlatformHubAccountEditBase, { type PlatformHubAccountEditModel } from "~/areas/blueprints/accounts/PlatformHubAccountEditBase";
import styles from "~/areas/infrastructure/components/AccountEdit/style.module.less";
import { subjectKeyChipList } from "~/components/Chips/index";
import { AvailableDeploymentSubjectKeys, AvailableExecutionSubjectKeys, AvailableRunbookSubjectKeys, DefaultDeploymentSubjectKeys, OpenIdSubjectMultiSelect, SubjectKeyOrder } from "~/components/MultiSelect/OpenIdSubjectMultiSelect";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import { ExpandableFormSection, FormSectionHeading, Note, Summary } from "~/components/form/index";
import Text from "~/primitiveComponents/form/Text/Text";
interface PlatformHubGenericOidcAuth {
    deploymentSubjectKeys: string[];
    audience: string;
}
interface GenericOidcPlatformHubModel extends PlatformHubAccountEditModel {
    accountType: AccountType.GenericOidcAccount;
    authentication: PlatformHubGenericOidcAuth;
}
const defaultGenericOidc: PlatformHubGenericOidcAuth = {
    deploymentSubjectKeys: [],
    audience: "api://default",
};
class GenericOidcPlatformHubAccountEdit extends PlatformHubAccountEditBase<GenericOidcPlatformHubAccountResource, GenericOidcPlatformHubModel> {
    getPartialModel(account?: GenericOidcPlatformHubAccountResource) {
        return getPartialModel(account);
    }
    getPartialResource() {
        return getPartialResource(this.state.model);
    }
    getPageActions() {
        return [];
    }
    getTestDialog() {
        return null;
    }
    customExpandableFormSections() {
        return customExpandableFormSections(this.state.model, (auth) => this.setState((prev) => ({
            model: {
                ...prev.model,
                authentication: {
                    ...prev.model.authentication,
                    ...auth,
                },
            },
        })), this.getFieldError);
    }
}
function getPartialModel(account?: GenericOidcPlatformHubAccountResource): Partial<GenericOidcPlatformHubModel> | undefined {
    if (!account) {
        return {
            accountType: AccountType.GenericOidcAccount,
            authentication: cloneDeep(defaultGenericOidc),
        };
    }
    const oidcAccount = account as GenericOidcPlatformHubAccountResource;
    return {
        ...oidcAccount,
        accountType: AccountType.GenericOidcAccount,
        authentication: {
            deploymentSubjectKeys: oidcAccount.DeploymentSubjectKeys,
            audience: oidcAccount.Audience,
        },
    };
}
function getPartialResource(modelState: GenericOidcPlatformHubModel): (Partial<GenericOidcPlatformHubAccountResource> & {
    AccountType: AccountType;
}) | undefined {
    const resource = {
        AccountType: modelState.accountType,
    };
    return {
        ...resource,
        Audience: modelState.authentication.audience,
        DeploymentSubjectKeys: modelState.authentication.deploymentSubjectKeys,
    };
}
function audienceSummary(model: PlatformHubGenericOidcAuth) {
    return model.audience ? Summary.summary(model.audience) : Summary.placeholder("Federated Identity audience");
}
function subjectKeySummary(subjectKeys: string[]) {
    if (subjectKeys && subjectKeys.length > 0) {
        return Summary.summary(subjectKeyChipList(subjectKeys));
    }
    else {
        return Summary.summary("Using default subject keys");
    }
}
function customExpandableFormSections(modelState: GenericOidcPlatformHubModel, setOidcAuth: <GenericOidcAuth, K extends keyof GenericOidcAuth>(newState: Pick<GenericOidcAuth, K>) => void, getFieldError: (field: string) => string): React.ReactElement[] {
    const baseElements: React.ReactElement[] = [];
    baseElements.push(<FormSectionHeading title={"Generic Oidc Details"} key={"header"}/>);
    baseElements.push(<div key="Generic-Oidc">
            <Callout type={"warning"} title="Early Access">
                The OpenID Connect feature is still in development. We'd love to hear <ExternalLink href={"OpenIDConnectFeedback"}> your feedback</ExternalLink>.
            </Callout>

            <FormSectionHeading title="Subject Claim" key={"SubjectClaim"}/>
            <ExpandableFormSection errorKey="deploymentSubjectKeys" key="deploymentSubjectKeys" title="Deployments & Runbooks" help="Select keys to include for a deployment or runbook." summary={subjectKeySummary(modelState.authentication.deploymentSubjectKeys)} containerKey="AccountEdit">
                <OpenIdSubjectMultiSelect onChange={(deploymentSubjectKeys) => setOidcAuth({ deploymentSubjectKeys })} value={modelState.authentication.deploymentSubjectKeys} subjectKeys={AvailableExecutionSubjectKeys} accessibleName="Deployment Subject Keys"/>
                <Note>
                    Subject claim formats:
                    <br />
                    <b>Deployment:</b>
                    <span>{generateSampleSubject(modelState.authentication.deploymentSubjectKeys, DefaultDeploymentSubjectKeys, "deployment", AvailableDeploymentSubjectKeys)}</span>
                    <br />
                    <b>Runbook:</b>
                    <span>{generateSampleSubject(modelState.authentication.deploymentSubjectKeys, DefaultDeploymentSubjectKeys, "runbook", AvailableRunbookSubjectKeys)}</span>
                    <br />
                    <span className={styles.secondaryTextColor}>If no keys are specified, the subject claim will default to: {DefaultDeploymentSubjectKeys.join(", ")}. Any keys not selected will not be included in the subject claim.</span>
                </Note>
            </ExpandableFormSection>
            <FormSectionHeading title="Other Claims" key={"OtherClaims"}/>
            <ExpandableFormSection errorKey="Audience" key="audience" title="Audience" summary={audienceSummary(modelState.authentication)} help={`Federated credentials audience, this value is used to establish a connection between external workload identities`} containerKey="AccountEdit">
                <Text value={modelState.authentication.audience} onChange={(audience) => setOidcAuth({ audience })} label="Audience" error={getFieldError("Audience")}/>
            </ExpandableFormSection>
        </div>);
    return baseElements;
}
function generateSampleSubject(requestedKeys: string[], defaultKeys: string[], type: string, possibleItems: ReferenceDataItem[]): string {
    const possibleKeys = possibleItems.map((k) => k.Id);
    const keys: string[] = requestedKeys && requestedKeys.length > 0 ? requestedKeys : defaultKeys;
    return keys
        .filter((k) => possibleKeys.indexOf(k) > -1)
        .sort((a, b) => SubjectKeyOrder.indexOf(a) - SubjectKeyOrder.indexOf(b))
        .map((k) => (k === "type" ? `type:${type}` : `${k}:[${k}-slug]`))
        .join(":");
}
export default GenericOidcPlatformHubAccountEdit;
