import { Callout, RadioButton, RadioButtonGroup } from "@octopusdeploy/design-system-components";
import { useAggregateAPIOperationStatus } from "@octopusdeploy/octopus-react-client";
import type { AnonymousVcsCredentials, GitHubVcsCredentials, GitPersistenceSettings, PlatformHubConnectionConfigurationResource, ReferenceVcsCredentials, Repository, UsernamePasswordVcsCredentials } from "@octopusdeploy/octopus-server-client";
import { defaultUsernamePasswordVcsCredentials, AuthenticationType, IsUsingAnonymousAuth, IsUsingUsernamePasswordAuth, Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { PageContent } from "~/components/PageContent/PageContent";
import { ExpandableFormSection, Note, required, Sensitive, Summary, Text } from "~/components/form";
import { Form, useForm } from "~/components/form/Form/Form";
interface PlatformHubConnectionInternalPageProps {
    loaderData: PlatformHubConnectionLoaderData;
}
interface PlatformHubConnectionLoaderData {
    connectionConfiguration: PlatformHubConnectionConfigurationResource;
}
export async function platformHubConnectionLoader(repository: Repository): Promise<PlatformHubConnectionLoaderData> {
    const connectionConfiguration = await repository.PlatformHubConnectionRepository.get();
    return { connectionConfiguration };
}
export function PlatformHubConnectionPage({ loaderData }: PlatformHubConnectionInternalPageProps) {
    const { isInProgress } = useAggregateAPIOperationStatus();
    const [isNew] = React.useState(!loaderData.connectionConfiguration.PersistenceSettings.Url);
    const { model, setModel, formProps, createSaveAction, getFieldError } = useForm({
        initialModel: loaderData.connectionConfiguration,
        formName: "Save Platform Hub Connection Configuration",
        onSubmit: async (repository: Repository, connectionModel) => await repository.PlatformHubConnectionRepository.modify(connectionModel),
        submitPermission: { permission: Permission.PlatformHubEdit },
    });
    const updatePersistenceSettings = (newValues: Partial<GitPersistenceSettings>) => {
        setModel({ ...model, PersistenceSettings: { ...model.PersistenceSettings, ...newValues } });
    };
    const onCredentialTypeChanged = async (newAuthenticationType: AuthenticationType) => {
        if (newAuthenticationType === AuthenticationType.UsernamePassword) {
            updatePersistenceSettings({ Credentials: defaultUsernamePasswordVcsCredentials });
        }
        else if (newAuthenticationType === AuthenticationType.Anonymous) {
            updatePersistenceSettings({ Credentials: { Type: AuthenticationType.Anonymous } });
        }
    };
    const urlSummary = model.PersistenceSettings.Url ? Summary.summary(model.PersistenceSettings.Url) : Summary.placeholder("Enter a Git repository URL");
    const credentials = model.PersistenceSettings.Credentials;
    const authSummary = createAuthSummary(credentials);
    return (<PageContent header={{ title: "Version Control Settings", primaryAction: createSaveAction() }}>
            <Form {...formProps} expandAllOnMount={isNew}>
                <ExpandableFormSection errorKey="Url" title="Git Repository" summary={urlSummary} help={"Add the Git repository to use with the platform hub"}>
                    <Callout type={"information"} title="Repository must be initialized">
                        <div>If you are creating a new repository, it must be initialized with at least one branch</div>
                    </Callout>
                    <Text key="Url" value={model.PersistenceSettings.Url} onChange={(Url) => updatePersistenceSettings({ Url })} label="URL" error={getFieldError("Url")} validate={required("Enter a Git repository URL.")} autoFocus={true} accessibleName={"Git repository URL"}/>
                    <Note>
                        The HTTPS URL to your git repo. E.g. <code>https://github.com/acme/hello-world.git</code>
                    </Note>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Authentication" title="Authentication" summary={authSummary} help="Choose a method to authenticate with the above Git repository">
                    <RadioButtonGroup value={credentials.Type} onChange={onCredentialTypeChanged} accessibleName={"Authentication mode"}>
                        <RadioButton value={AuthenticationType.UsernamePassword} key={AuthenticationType.UsernamePassword} label={"Username/Password"} accessibleName={"Authenticate using username and password"}/>
                        {IsUsingUsernamePasswordAuth(credentials) && (<>
                                <Text key="Username" value={credentials.Username} onChange={(Username) => updatePersistenceSettings({ Credentials: { ...credentials, Username } })} label="Username" error={getFieldError("Username")} validate={required("Enter authentication details.")}/>
                                <Sensitive key="Password" value={credentials.Password} onChange={(Password) => updatePersistenceSettings({ Credentials: { ...credentials, Password } })} label="Password or Personal Access Token" error={getFieldError("Password")}/>
                                <Note>If you are using a personal access token, ensure it is scoped with read and write access for the repo.</Note>
                            </>)}
                        <RadioButton value={AuthenticationType.Anonymous} key={AuthenticationType.Anonymous} label={"Anonymous"} accessibleName={"No authentication"}/>
                        {IsUsingAnonymousAuth(credentials) && <Note>When no credentials are required (e.g. locally hosted repositories).</Note>}
                    </RadioButtonGroup>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="BasePath" title="Git File Storage Directory" summary={Summary.summary(model.PersistenceSettings.BasePath)} help="The directory where Octopus should store the files in the repository">
                    <Text inputProps={{
            startAdornment: (<>
                                    <code>.octopus</code>
                                    &nbsp;/
                                </>),
        }} key="BasePath" value={model.PersistenceSettings.BasePath.substring(".octopus/".length)} onChange={(BasePath) => updatePersistenceSettings({ BasePath: `.octopus/${BasePath}` })} label="Git File Storage Directory" error={getFieldError("BasePath")} disabled={isInProgress} accessibleName={"Directory in the repository where files relating to the platform hub should be stored"}/>
                    <Note>This controls where in your repository the files related to the platform hub will be stored.</Note>
                    <Note>
                        It’s recommended to connect a dedicated Git repository to the platform hub, in this case the default directory, <code>.octopus</code>, is a good option. If managing multiple configurations within the same Git repository,
                        consider organizing them in subdirectories, e.g. <code>.octopus/platform-hub</code>.
                    </Note>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="DefaultBranch" title="Branch Settings" summary={Summary.summary(<span>
                            Default branch is <strong>{model.PersistenceSettings.DefaultBranch}</strong>
                        </span>)} help="Enter the details for your branches">
                    <Text key="DefaultBranch" value={model.PersistenceSettings.DefaultBranch} onChange={(DefaultBranch) => updatePersistenceSettings({ DefaultBranch })} label="Default Branch" error={getFieldError("DefaultBranch")} validate={required("Enter a default branch.")} disabled={isInProgress} accessibleName={"Name of the default branch on the Git repository"}/>
                </ExpandableFormSection>
            </Form>
        </PageContent>);
}
function createAuthSummary(credentials: AnonymousVcsCredentials | UsernamePasswordVcsCredentials | ReferenceVcsCredentials | GitHubVcsCredentials) {
    let authSummary = Summary.placeholder("Enter authentication details");
    if (IsUsingUsernamePasswordAuth(credentials)) {
        const { Username: username, Password: password } = credentials;
        if (username && password && password.HasValue) {
            authSummary = Summary.summary(React.Children.toArray([
                <span>Authenticated with username&nbsp;</span>,
                <span>
                        <strong>{username}</strong>
                    </span>,
            ]));
        }
    }
    else if (IsUsingAnonymousAuth(credentials)) {
        authSummary = Summary.summary(React.Children.toArray([
            <span>
                    Authenticated as an <strong>Anonymous</strong> user
                </span>,
        ]));
    }
    return authSummary;
}
