/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { css } from "@emotion/css";
import type { WithStyles } from "@material-ui/core/styles";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { Tooltip } from "@octopusdeploy/design-system-components";
import { ExclamationTriangleIcon } from "@octopusdeploy/design-system-icons";
import { themeTokens, lineHeight } from "@octopusdeploy/design-system-tokens";
import { keyBy } from "lodash";
import * as React from "react";
import type { TabItemProps } from "~/primitiveComponents/navigation/Tabs/TabItem";
const styles = () => {
    return createStyles({
        warning: {
            paddingLeft: "0.25rem",
        },
    });
};
const TabLabel: React.FC<{
    label: React.ReactNode;
}> = ({ label }) => <div className={tabLabelStyles}>{label}</div>;
TabLabel.displayName = "TabLabel"
interface WarningTabLabelProps extends WithStyles<typeof styles> {
    label: React.ReactNode;
    warning: string;
}
const WarningTabLabel: React.FC<WarningTabLabelProps> = ({ label, warning, classes }) => {
    const containerStyles = css({
        alignSelf: "center",
        display: "flex",
    });
    return (<div className={containerStyles}>
            <div className={tabLabelStyles}>{label}</div>
            <Tooltip content={warning}>
                <div className={classes.warning}>
                    <ExclamationTriangleIcon size={24} color={themeTokens.color.icon.attention}/>
                </div>
            </Tooltip>
        </div>);
};
WarningTabLabel.displayName = "WarningTabLabel"
const EnhancedWarningTabLabel = withStyles(styles)(WarningTabLabel);
const tabLabelStyles = css({
    lineHeight: lineHeight.small,
});
export type RenderTabCallback = (props: RenderTabProps) => React.ReactNode;
export interface RenderTabProps {
    label: React.ReactNode;
    value: string;
    index: number;
}
const convertTab = (tab: React.ReactElement, index: number, fallbackValue: string, renderTab: RenderTabCallback) => {
    const { label, warning, value = fallbackValue, children, onActive }: TabItemProps = tab.props;
    const tabLabel = warning ? <EnhancedWarningTabLabel label={label} warning={warning}/> : <TabLabel label={label}/>;
    const control = renderTab({ value, label: tabLabel, index });
    return {
        content: children,
        value,
        control,
        onActive,
    };
};
export const useTabConversion = (children: React.ReactNode, value: string, renderTab: RenderTabCallback) => {
    //We can't memoize children, they will always be a new array so no point trying.
    const validElements = React.Children.toArray(children).filter(React.isValidElement);
    const converted = validElements.map((tab, index) => convertTab(tab, index, index.toString(), renderTab));
    const lookup = keyBy(converted, (t) => t.value);
    const tabs = converted.map((x) => x.control);
    //Since lookup will always be a new object as it's based on the children there is no point trying to memoize this either.
    const { content } = lookup[value] || { content: null, onActive: null };
    const getTabDefinition = (name: string) => {
        return lookup[name];
    };
    return {
        tabs,
        content,
        getTabDefinition,
    };
};
