import { css } from "@emotion/css";
import { Callout, IconButton } from "@octopusdeploy/design-system-components";
import { CopyIcon } from "@octopusdeploy/design-system-icons";
import { generateSlug } from "@octopusdeploy/utilities";
import { useEffect, useState } from "react";
import * as React from "react";
import CopyToClipboardButton from "~/components/CopyToClipboardButton/index";
import IconButtonList from "~/components/IconButtonList";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip/index";
import InputWithActions from "~/components/InputWithActions/index";
import Text from "~/primitiveComponents/form/Text/Text";
import type { TextProps } from "~/primitiveComponents/form/Text/Text";
export type SlugEditorProps = TextProps & {
    originalSlug: string;
    name?: string | undefined;
    additionalWarningMessage?: string | undefined;
    prefix?: string | undefined;
};
const SlugEditor: React.FC<SlugEditorProps> = ({ onChange: onChangeProp, originalSlug, name, additionalWarningMessage, prefix, readOnly, ...rest }) => {
    const [currentValue, setCurrentValue] = useState(rest.value);
    const [validSlug, setValidSlug] = useState(rest.value);
    const [editing, setEditing] = useState(false);
    // If the original slug changes, we've likely saved, disable the editor
    useEffect(() => {
        setEditing(false);
    }, [originalSlug]);
    // Ensure any input is converted to a slug, but allowing for trailing dashes
    const onChange = (input: string) => {
        const newCurrentValue = generateSlug(input, false);
        setCurrentValue(newCurrentValue);
        // Store the current value but as a valid slug
        const newValidSlug = generateSlug(newCurrentValue);
        setValidSlug(newValidSlug);
        onChangeProp(newValidSlug);
    };
    // Once we lose focus, update the value to the valid one
    const onBlur = () => {
        onChange(validSlug);
    };
    const onEditClick = () => setEditing(true);
    const onCancelEditClick = () => {
        setEditing(false);
        onChange(originalSlug);
    };
    const onUpdateClick = () => {
        if (name) {
            const newSlug = generateSlug(name);
            onChange(newSlug);
            setEditing(true);
        }
    };
    const renderActions = () => {
        const buttons: Array<React.ReactElement> = [];
        if (!editing && !readOnly)
            buttons.push(<IconButtonWithTooltip accessibleName={`Change slug`} toolTipContent={"Change"} onClick={onEditClick} icon="PenLineIcon"/>);
        if (editing)
            buttons.push(<IconButtonWithTooltip accessibleName={`Cancel editing slug`} toolTipContent={"Cancel"} onClick={onCancelEditClick} icon="XmarkIcon"/>);
        if (name && generateSlug(name) !== validSlug)
            buttons.push(<IconButtonWithTooltip accessibleName={"Update slug to match name"} toolTipContent={"Update"} onClick={onUpdateClick} icon={"ArrowRefreshIcon"}/>);
        buttons.push(<CopyToClipboardIconButton value={prefix ? `${prefix}${validSlug}` : validSlug}/>);
        return <IconButtonList buttons={buttons}/>;
    };
    const renderPrefix = () => {
        if (prefix) {
            return {
                startAdornment: (<>
                        <code>{prefix}</code>
                        &nbsp;
                    </>),
            };
        }
        return {};
    };
    return (<>
            <InputWithActions input={<Text {...rest} inputProps={renderPrefix()} value={currentValue} onChange={onChange} onBlur={onBlur} disabled={!editing}/>} actions={renderActions()}/>
            {originalSlug !== validSlug && (<Callout title={"Be careful when changing a slug"} type={"warning"}>
                    {additionalWarningMessage && (<span>
                            {additionalWarningMessage}
                            <br />
                        </span>)}
                    <span>Modifying a slug may impact any references to it. Any scripts referencing the old slug will need to be updated manually.</span>
                </Callout>)}
        </>);
};
SlugEditor.displayName = "SlugEditor"
const CopyToClipboardIconButton = ({ value }: {
    value: string;
}) => {
    const copyToClipboardContainerStyles = css({
        width: "1.5rem",
        height: "1.5rem",
    });
    return (<div className={copyToClipboardContainerStyles}>
            <CopyToClipboardButton value={value}>
                <IconButton customIcon={<CopyIcon size={24}/>}/>
            </CopyToClipboardButton>
        </div>);
};
export default SlugEditor;
