/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-eq-null */
import { Button, IconButton } from "@octopusdeploy/design-system-components";
import { XmarkIcon } from "@octopusdeploy/design-system-icons";
import { noOp } from "@octopusdeploy/utilities";
import * as React from "react";
import InputWithActions from "~/components/InputWithActions";
import Text from "~/primitiveComponents/form/Text/Text";
import type FormFieldProps from "../FormFieldProps";
import styles from "./style.module.less";
export interface LogoEditorSettings {
    file?: File;
    reset: boolean;
}
interface LogoEditorProps extends FormFieldProps<LogoEditorSettings> {
    autoFocus?: boolean;
    type?: string;
    placeholder?: string;
    error?: string;
    onValidate?(value: string): void;
}
export default class LogoEditor extends React.Component<LogoEditorProps, {}> {
    input: HTMLInputElement = undefined!;
    constructor(props: LogoEditorProps) {
        super(props);
    }
    handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length < 1) {
            this.onValidate("No file selected");
            return;
        }
        const file = e.target.files[0];
        const ValidImageTypes = ["image/gif", "image/jpeg", "image/png"];
        if (ValidImageTypes.indexOf(file.type) < 0) {
            this.onValidate("Selected file was not an image");
            return;
        }
        this.onValidate("");
        const value: LogoEditorSettings = {
            file,
            reset: false,
        };
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };
    onValidate(message: string) {
        if (this.props.onValidate) {
            this.props.onValidate(message);
        }
    }
    useDefault = (event: React.MouseEvent) => {
        //event.preventDefault();
        event.stopPropagation();
        const value: LogoEditorSettings = {
            file: undefined,
            reset: true,
        };
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };
    clear = () => {
        const value: LogoEditorSettings = {
            file: undefined,
            reset: false,
        };
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };
    chooseFile = () => {
        this.input.click();
    };
    render() {
        const { value, error, placeholder } = this.props;
        const logInputValue = value?.file?.name ? value.file.name : "";
        return (<div className={styles.logoEditor}>
                <div className={styles.logoContainer}>
                    <InputWithActions input={<div>
                                <Text type="text" onChange={noOp} placeholder={placeholder ?? "Choose image"} value={logInputValue} error={error} onClick={this.chooseFile} inputProps={logInputValue
                    ? {
                        endAdornment: <IconButton className={styles.resetButton} onClick={(e) => this.useDefault(e)} customIcon={<XmarkIcon size={24}/>}/>,
                    }
                    : undefined}/>
                            </div>} actions={<Button onClick={this.chooseFile} label="Choose image" importance="secondary" size="small"/>}/>
                    <input type="file" style={{ display: "none" }} onChange={this.handleFileChange} ref={(input: HTMLInputElement) => {
                this.input = input;
            }}/>
                </div>
                <div className={styles.logoNote}>
                    <div>
                        <span className={styles.logoNoteBold}>Best format:</span> transparent PNG 100 x 100 pixels.
                    </div>
                    <div>JPG and GIF are also suitable.</div>
                </div>
            </div>);
    }
    static displayName = "LogoEditor";
}
