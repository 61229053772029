import type { PageAction, PrimaryPageAction, SimpleMenuItem } from "@octopusdeploy/design-system-components";
import { CodeIcon } from "@octopusdeploy/design-system-icons";
import type { GitRefResource, ProcessType } from "@octopusdeploy/octopus-server-client";
import { HasRunbooksInGit, Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import type { ReactElement, ReactNode } from "react";
import * as React from "react";
import { Action, useProjectScopedAnalyticActionDispatch } from "~/analytics/Analytics";
import { isVersionControlledProcess, processScopedEditPermission } from "~/areas/projects/components/Process/Common/CommonProcessHelpers";
import type { DeploymentProcessIdentifier, ProcessIdentifier } from "~/areas/projects/components/Process/types";
import { isBlueprintProcessIdentifier, isDeploymentProcessIdentifier, isRunbookProcessIdentifier } from "~/areas/projects/components/Process/types";
import { RunbookPageContent } from "~/areas/projects/components/Runbooks/Layouts/RunbookPageContent";
import { AddRunbookProcessTemplateStepButton } from "~/areas/projects/components/Steps/AddBlueprintStepButton";
import { GitCallouts } from "~/areas/projects/components/VersionControl/GitCallouts";
import { useProjectContext } from "~/areas/projects/context";
import type { DoBusyTask } from "~/components/DataBaseComponent";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { PageContent } from "~/components/PageContent/PageContent";
import { isAllowed, isAllowedToRunGitRunbook } from "~/components/PermissionCheck/PermissionCheck";
import { useOctopusFeatureToggle } from "~/hooks/useOctopusFeatureToggle";
import StringHelper from "~/utils/StringHelper";
import { LastPublishedChip } from "../../Runbooks/LastPublishedChip";
import PublishButton, { usePublishedRunbookSnapshotSetup } from "../../Runbooks/PublishButton";
import { getPermissionDeniedTooltipText, RunNowButton } from "../../Runbooks/RunNowButton";
import { useOptionalRunbookContext, useRunbookContext } from "../../Runbooks/RunbookContext";
import ContextAddStepNavigationButton from "../../Steps/ContextAddStepNavigationButton";
import { GitRepositoryErrorCallout } from "../../Variables/GitRepositoryErrorCallout";
import { useDispatchSwitchToCodeEditor } from "../CodeEditor/ProcessCodeEditorAnalytics";
import { isProcessCodeEditorEnabled, useProcessCodeEditorState } from "../CodeEditor/isProcessCodeEditorEnabled";
import { useProcessContext } from "../Contexts/ProcessContext";
export interface ProcessContextPaperLayoutProps {
    children?: ReactNode;
    titleAccessory?: ReactElement;
    overflowActions?: SimpleMenuItem[];
    processIdentifier: ProcessIdentifier;
    doBusyTask: DoBusyTask;
    gitRefResource: GitRefResource | undefined;
}
export const ProcessContextPaperLayout: React.FC<ProcessContextPaperLayoutProps> = ({ children, doBusyTask, gitRefResource, processIdentifier, overflowActions, titleAccessory }) => {
    const processContext = useProcessContext();
    const hasSteps = processContext.selectors.hasSteps();
    const runbookContext = useOptionalRunbookContext();
    const runbookName = runbookContext?.state.runbook?.Name ?? StringHelper.ellipsis;
    if (isRunbookProcessIdentifier(processIdentifier)) {
        return (<RunbooksProcessContextPaperLayout title={runbookName} hasSteps={hasSteps} doBusyTask={doBusyTask} gitRefResource={gitRefResource} processIdentifier={processIdentifier} overflowActions={overflowActions} titleAccessory={titleAccessory}>
                <GitCallouts isRunbooksPage gitRefResource={gitRefResource}/>
                {children}
            </RunbooksProcessContextPaperLayout>);
    }
    if (isDeploymentProcessIdentifier(processIdentifier)) {
        return (<DeploymentProcessContextPaperLayout title={"Process"} gitRefResource={gitRefResource} processIdentifier={processIdentifier} overflowActions={overflowActions} titleAccessory={titleAccessory}>
                <GitCallouts gitRefResource={gitRefResource}/>
                {children}
            </DeploymentProcessContextPaperLayout>);
    }
    if (isBlueprintProcessIdentifier(processIdentifier)) {
        return <>{children}</>;
    }
    exhaustiveCheck(processIdentifier, "Unknown process type");
};
ProcessContextPaperLayout.displayName = "ProcessContextPaperLayout"
interface DeploymentProcessContextPaperLayoutProps {
    title: string;
    processIdentifier: DeploymentProcessIdentifier;
    gitRefResource: GitRefResource | undefined;
    titleAccessory?: ReactElement;
    overflowActions?: SimpleMenuItem[];
    children?: ReactNode;
}
const DeploymentProcessContextPaperLayout: React.FC<DeploymentProcessContextPaperLayoutProps> = ({ processIdentifier, title, titleAccessory, gitRefResource, children, overflowActions }) => {
    const processContext = useProcessContext();
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    const hasSteps: boolean = processContext.selectors.hasSteps();
    const dispatchAction = useProjectScopedAnalyticActionDispatch(projectContext.state.model.Id);
    const dispatchSwitchToCodeEditor = useDispatchSwitchToCodeEditor();
    const pageActions: PageAction[] = [];
    const isCodeEditorEnabled = isProcessCodeEditorEnabled(project, processIdentifier.type);
    const { isProcessCodeEditorEnabledByDefault, enableProcessCodeEditorByDefault } = useProcessCodeEditorState();
    React.useEffect(() => {
        projectContext.actions.refreshGitVariableErrors();
        // We only want to check that the variables when this component is created. No need for any dependencies to trigger a re-check.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (isCodeEditorEnabled && isProcessCodeEditorEnabledByDefault) {
        // The process code editor is enabled by default, kick out to that route
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return <InternalRedirect to={links.branchDeploymentProcessCodePage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, branchName: gitRefResource!.CanonicalName })}/>;
    }
    if (isCodeEditorEnabled) {
        const switchToCodeViewButton: PageAction = {
            type: "navigate",
            buttonType: "secondary",
            label: "Code",
            extraContext: "Switch to the code editor to edit the OCL for your deployment process directly",
            icon: <CodeIcon size={20}/>,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            path: links.branchDeploymentProcessCodePage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, branchName: gitRefResource!.CanonicalName }),
            onClick: () => {
                enableProcessCodeEditorByDefault();
                dispatchSwitchToCodeEditor();
            },
            hasPermissions: isAllowed({ permission: Permission.ProcessView, project: project.Id, tenant: "*" }),
        };
        pageActions.push(switchToCodeViewButton);
    }
    if (hasSteps || project?.IsVersionControlled) {
        const createReleaseButton: PageAction = {
            type: "navigate",
            buttonType: "secondary",
            label: "Create Release",
            path: links.createReleasePage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }),
            onClick: () => dispatchAction("Create a release", { resource: "Create Release", action: Action.Add }),
            hasPermissions: isAllowed({ permission: Permission.ReleaseCreate, project: project.Id, tenant: "*" }),
        };
        pageActions.push(createReleaseButton);
    }
    if (processContext.selectors.hasSteps()) {
        pageActions.push(getContextAddStepNavigationPageAction(processIdentifier.type, project.Id, gitRefResource));
    }
    return (<PageContent header={{ title: title ?? StringHelper.ellipsis, pageActions, overflowActions, contextSelector: titleAccessory }} fullWidth={true}>
            {children}
        </PageContent>);
};
DeploymentProcessContextPaperLayout.displayName = "DeploymentProcessContextPaperLayout"
interface RunbooksProcessContextPaperLayoutProps extends ProcessContextPaperLayoutProps {
    title: string;
    hasSteps: boolean;
    doBusyTask: DoBusyTask;
}
const RunbooksProcessContextPaperLayout: React.FC<RunbooksProcessContextPaperLayoutProps> = ({ hasSteps, doBusyTask, processIdentifier, overflowActions, titleAccessory, title, gitRefResource, children }) => {
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    const runbooksContext = useRunbookContext();
    const runbook = runbooksContext.state.runbook;
    const gitRef = projectContext.state.gitRef ? projectContext.state.gitRef.CanonicalName : undefined;
    const allowedToRunOnCurrentBranch = isAllowedToRunGitRunbook(project, gitRef);
    const hasGitRunbooks = HasRunbooksInGit(project.PersistenceSettings);
    React.useEffect(() => {
        projectContext.actions.refreshGitVariableErrors();
        // We only want to check that the variables when this component is created. No need for any dependencies to trigger a re-check.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (!runbook) {
        throw Error("runbook value should exist");
    }
    const publishedRunbookAndTemplate = usePublishedRunbookSnapshotSetup(doBusyTask, runbook);
    const publishedRunbookSnapshot = publishedRunbookAndTemplate?.publishedRunbookSnapshot;
    const runbookRunTemplate = publishedRunbookAndTemplate?.runbookRunTemplate;
    const isRunbookRunTemplateModified = runbookRunTemplate && (runbookRunTemplate.IsRunbookProcessModified || runbookRunTemplate.IsVariableSetModified || runbookRunTemplate.IsLibraryVariableSetModified);
    const titleChip = publishedRunbookSnapshot && <LastPublishedChip project={project} publishedRunbookSnapshot={publishedRunbookSnapshot} isRunbookRunTemplateModified={isRunbookRunTemplateModified}/>;
    const processContext = useProcessContext();
    const isBlueprintsEnabled = useOctopusFeatureToggle("blueprints", false);
    const runNowPageAction: PrimaryPageAction = {
        type: "custom",
        key: "Run Now",
        content: (<RunNowButton spaceId={runbook.SpaceId} isDisabled={!hasSteps || (hasGitRunbooks && !allowedToRunOnCurrentBranch)} tooltip={hasGitRunbooks && !allowedToRunOnCurrentBranch ? getPermissionDeniedTooltipText(gitRef) : undefined} projectSlug={project.Slug} projectId={project.Id} runbookId={runbook.Id}/>),
        hasPermissions: isAllowed({ permission: Permission.RunbookRunCreate, project: project.Id, wildcard: true }),
    };
    const pageActions: PageAction[] = [];
    if (!isVersionControlledProcess(project.PersistenceSettings, processContext.state.processIdentifier.type)) {
        const publishAction: PageAction = {
            type: "custom",
            key: "Publish",
            content: <PublishButton publishedRunbookSnapshot={publishedRunbookSnapshot} isRunbookRunTemplateModified={isRunbookRunTemplateModified}/>,
            hasPermissions: isAllowed({ permission: Permission.RunbookEdit, project: project.Id, wildcard: true }),
        };
        pageActions.push(publishAction);
    }
    const addStepAction = getContextAddStepNavigationPageAction(processIdentifier.type, project.Id, gitRefResource);
    pageActions.push(addStepAction);
    if (isBlueprintsEnabled) {
        const addProcessTemplateStepButton: PageAction = {
            type: "custom",
            key: "Add Process Template",
            content: <AddRunbookProcessTemplateStepButton projectId={project.Id}/>,
            hasPermissions: isAllowed({ permission: Permission.RunbookEdit, project: project.Id, wildcard: true }),
        };
        pageActions.push(addProcessTemplateStepButton);
    }
    return (<RunbookPageContent header={{ title: title ?? runbook.Name, primaryAction: runNowPageAction, pageActions, chip: titleChip, overflowActions, contextSelector: titleAccessory }} project={project}>
            <GitRepositoryErrorCallout calloutType={"danger"} additionalIntroText="The runbook can not be published until the error is resolved."></GitRepositoryErrorCallout>
            {children}
        </RunbookPageContent>);
};
RunbooksProcessContextPaperLayout.displayName = "RunbooksProcessContextPaperLayout"
function getContextAddStepNavigationPageAction(processType: ProcessType, projectId: string, gitRefResource: GitRefResource | undefined): PageAction {
    return {
        type: "custom",
        key: "Context Add Step Navigation",
        content: <ContextAddStepNavigationButton gitRefResource={gitRefResource}/>,
        hasPermissions: isAllowed({
            permission: processScopedEditPermission(processType),
            project: projectId,
            wildcard: true,
        }),
    };
}
