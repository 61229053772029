/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { SpaceResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import Task from "~/areas/tasks/components/Task/Task";
import { repository, session } from "~/clientInstance";
import DataBaseComponent from "~/components/DataBaseComponent";
import { PageContent } from "~/components/PageContent/PageContent";
import { getImportExportMenuItems } from "./ImportExportMenu";
export class ImportProjectTaskPage extends DataBaseComponent<ImportProjectTaskPageProps, {
    space?: SpaceResource;
}> {
    taskId: string;
    constructor(props: ImportProjectTaskPageProps) {
        super(props);
        this.taskId = this.props.taskId;
        this.state = {};
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const [space] = await Promise.all([repository.Spaces.get(repository.spaceId!)]);
            this.setState({
                space,
            });
        });
    }
    isSpaceManager(): boolean {
        return this.state.space !== undefined && session.currentPermissions!.isSpaceManager(this.state.space);
    }
    render() {
        return (<PageContent header={{ title: "Import project", breadcrumbs: [{ label: "Projects", pageUrl: links.projectsPage.generateUrl({ spaceId: this.props.spaceId }) }], overflowActions: getImportExportMenuItems(this.props.spaceId) }} callout={this.state.space !== undefined && !this.isSpaceManager() ? { type: "danger", title: "Permission", content: "Only Space Managers can perform an export or import" } : undefined}>
                {this.state.space !== undefined && this.isSpaceManager() && <Task taskId={this.taskId} delayRender={() => false}/>}
            </PageContent>);
    }
    static displayName = "ImportProjectTaskPage";
}
interface ImportProjectTaskPageProps {
    spaceId: string;
    taskId: string;
}
