import { DeploymentStatus, SuccessfulDeploymentIcon, FailedDeploymentIcon, ProgressingDeploymentIcon, type LiveStatusIconSize, type LiveStatusDeploymentIconProps } from "./LiveAppIcons";
const LiveStatusToVisuals = (status: DeploymentStatus, size: LiveStatusIconSize): JSX.Element => {
    switch (status) {
        case DeploymentStatus.Success:
            return SuccessfulDeploymentIcon(size);
        case DeploymentStatus.Failure:
            return FailedDeploymentIcon(size);
        case DeploymentStatus.Progressing:
        default:
            return ProgressingDeploymentIcon(size);
    }
};
export const LiveDeploymentStatusIcon = (props: LiveStatusDeploymentIconProps) => {
    return LiveStatusToVisuals(props.status, props.size);
};
