/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css, cx } from "@emotion/css";
import { CircularProgress, InlineSnackbar } from "@octopusdeploy/design-system-components";
import { LockOnIcon } from "@octopusdeploy/design-system-icons";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { CertificateResource } from "@octopusdeploy/octopus-server-client";
import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import cn from "classnames";
import moment from "moment";
import * as React from "react";
import { CertificateDrawer } from "~/areas/library/components/Certificates/Certificate/CertificateDrawer";
import { createAddCertificateAnalyticsEvent } from "~/areas/library/components/Certificates/Certificate/amplitudeAnalytics";
import OpenDialogIconButton from "~/components/Dialog/OpenDialogIconButton";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip";
import InputWithActions from "~/components/InputWithActions/InputWithActions";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { withTheme } from "~/components/Theme";
import type { OctopusTheme } from "~/components/Theme";
import type FormFieldProps from "~/components/form/FormFieldProps";
import Select from "~/primitiveComponents/form/Select/Select";
import type { Item } from "~/primitiveComponents/form/Select/Select";
import CertificateSelector from "./CertificateSelector";
interface CertificateSelectProps extends FormFieldProps<string> {
    allowClear?: boolean;
    tenantId?: string;
    disabled?: boolean;
    label?: string | JSX.Element;
    error?: string | null;
    warning?: string;
    hideSearch?: boolean;
    items(): Promise<CertificateResource[]>;
    onRequestRefresh(): Promise<boolean>;
    validate?(value: string | null): string;
    onValidate?(value: string): void;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
}
interface CertificateSelectState {
    error?: string;
    items?: CertificateResource[];
    busy: boolean;
    isDrawerOpen: boolean;
    showSnackbar: boolean;
}
const snackbarContainerStyles = css({
    marginBottom: space[16],
});
class CertificateSelect extends React.Component<CertificateSelectProps, CertificateSelectState> {
    constructor(props: CertificateSelectProps) {
        super(props);
        this.state = {
            busy: true,
            isDrawerOpen: false,
            showSnackbar: false,
        };
    }
    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            const items = await this.props.items();
            this.setState({ items, busy: false });
        });
    }
    render() {
        const { onChange, onValidate, tenantId, doBusyTask, items, onRequestRefresh, hideSearch, ...otherProps } = this.props;
        if (!this.state.items) {
            return <CircularProgress size="small"/>;
        }
        return withTheme((theme) => (<>
                <InputWithActions input={<Select label="Select certificate" {...otherProps} allowFilter={true} onChange={this.handleChange} items={this.getItems(theme)} selectionRenderer={this.selectionRenderer}/>} busy={this.state.busy} actions={<>
                            <SearchDialogButton selectedId={this.props.value} onSelect={(certificate) => this.handleChange(certificate.Id)} tenantId={this.props.tenantId} hide={hideSearch}/>
                            <IconButtonWithTooltip disabled={this.state.busy} onClick={this.refreshData} toolTipContent={"Refresh"} icon={"ArrowRefreshIcon"} accessibleName={"Refresh"}/>
                            <AddCertificateButton openDrawer={() => this.setState({ isDrawerOpen: true })}/>
                        </>}/>
                <div className={cn({ snackbarContainerStyles: this.state.showSnackbar })}>
                    <InlineSnackbar variant="success" show={this.state.showSnackbar} content={"Certificate Created"} onClose={() => this.setState({ showSnackbar: false })} autoHideDuration={3000}/>
                </div>
                <CertificateDrawer isOpen={this.state.isDrawerOpen} onClose={this.handleDrawerClose} onSave={this.handleSave}/>
            </>));
    }
    private handleDrawerClose = () => {
        this.setState({ isDrawerOpen: false });
    };
    private handleSave = async (cert: CertificateResource) => {
        await this.refreshData();
        this.props.onChange(cert.Id);
        this.setState({ isDrawerOpen: false });
        setTimeout(() => {
            this.setState({ showSnackbar: true });
        }, 300);
    };
    private refreshData = async () => {
        let items: CertificateResource[] = [];
        try {
            this.setState({ busy: true });
            await this.props.onRequestRefresh();
            items = await this.props.items();
        }
        finally {
            this.setState({ items, busy: false });
        }
    };
    private handleChange = (certificateId: string | undefined) => {
        const value = certificateId === "" ? null : certificateId;
        if (this.props.validate) {
            const result = this.props.validate(value!);
            this.setState({ error: result });
            if (this.props.onValidate) {
                this.props.onValidate(result);
            }
        }
        if (this.props.onChange) {
            this.props.onChange(value!);
        }
    };
    private getItems(theme: OctopusTheme): Item[] {
        return this.state.items!.map((certificate) => {
            const expired = this.isCertificateExpired(certificate);
            const icon = expired ? <LockOnIcon size={24} color={theme.danger}/> : <LockOnIcon size={24}/>;
            return {
                value: certificate.Id,
                text: certificate.Name + (expired ? " (expired)" : ""),
                icon,
                variant: expired ? "danger" : undefined,
            };
        });
    }
    private selectionRenderer = (certificateId: string) => {
        const certificate = this.state.items!.find((cert) => cert.Id === certificateId);
        if (!certificate) {
            return certificateId;
        }
        if (this.isCertificateExpired(certificate)) {
            return (<div className={cx(iconWrapper, expiredIcon)}>
                    <span className={selectedIcon}>
                        <LockOnIcon size={24}/>
                    </span>
                    <span>{certificate.Name} (expired)</span>
                </div>);
        }
        return (<div className={iconWrapper}>
                <span className={selectedIcon}>
                    <LockOnIcon size={24}/>
                </span>
                <span>{certificate.Name}</span>
            </div>);
    };
    private isCertificateExpired(certificate: CertificateResource) {
        const now = moment();
        const certificateExpiry = moment(certificate.NotAfter);
        return certificateExpiry.isBefore(now);
    }
    static displayName = "CertificateSelect";
}
const selectedIcon = css({
    position: "relative",
    paddingRight: space[6],
});
const expiredIcon = css({
    color: themeTokens.color.text.danger,
});
const iconWrapper = css({
    display: "flex",
    alignItems: "center",
});
function AddCertificateButton(props: {
    openDrawer: () => void;
}) {
    const navigate = useSpaceAwareNavigation();
    const trackEvent = useTrackEvent();
    const handleOnClick = () => {
        trackEvent(createAddCertificateAnalyticsEvent("Drawer"));
        props.openDrawer();
    };
    return <IconButtonWithTooltip toolTipContent={"Add"} onClick={handleOnClick} icon={"PlusIcon"} accessibleName={"Add"}/>;
}
interface SearchDialogButtonProps {
    selectedId: string;
    onSelect: (pool: CertificateResource) => void;
    tenantId?: string;
    hide?: boolean;
}
function SearchDialogButton({ selectedId, onSelect, tenantId, hide }: SearchDialogButtonProps) {
    if (hide) {
        return null;
    }
    return (<OpenDialogIconButton toolTipContent="Search" wideDialog={true} icon={"MagnifyingGlassIcon"} accessibleName={"Search"}>
            <CertificateSelector onSelected={onSelect} selectedCertificateId={selectedId} tenantId={tenantId}/>
        </OpenDialogIconButton>);
}
export default CertificateSelect;
