/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionButton, ActionButtonType, EmptyStateDashboardNoPermissionIllustration, SimpleMenu, useMenuState } from "@octopusdeploy/design-system-components";
import type { PageAction, PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { useAggregateAPIOperationStatus, useInlineStatusQuery } from "@octopusdeploy/octopus-react-client";
import type { ProjectResource, Repository, DashboardConfigurationResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { QueryParamValuesSetter } from "@octopusdeploy/portal-routes";
import { isEqual } from "lodash";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { AddProjectGroup } from "~/areas/projects/components/Projects/AddProjectGroup";
import type { ProjectsFilter } from "~/areas/projects/components/Projects/ProjectsDashboard";
import { defaultProjectsPageSize, isProjectsPageSize, ProjectsDashboardPageContent } from "~/areas/projects/components/Projects/ProjectsDashboard";
import { DropDownIcon } from "~/components/Button/DropDownIcon/DropDownIcon";
import { useLegacyDoBusyTask } from "~/components/DataBaseComponent/useLegacyDoBusyTask";
import Dialog from "~/components/Dialog/Dialog";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import { Level1EmptyStateLayout } from "~/components/GettingStarted/Level1EmptyStateLayout";
import { PageLoading } from "~/components/PageContent/PageLoading";
import PermissionCheck, { hasPermission, isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import AddNewProjectDialog from "~/components/ProjectBasedActivation/AddNewProjectDialog";
import DashboardOnboardingLayout from "~/components/ProjectBasedActivation/DashboardOnboardingLayout";
import NewProjectWizard from "~/components/ProjectBasedActivation/NewProjectWizard";
import useLocalStorage from "~/hooks/useLocalStorage";
import { useOctopusFeatureToggle } from "~/hooks/useOctopusFeatureToggle";
import { getImportExportMenuItems } from "../ImportExport/ImportExportMenu";
import { projectStatusActions } from "../ProjectStatus/actions";
import type { ProjectStatus } from "../ProjectStatus/useProjectStatus";
import { VerticalNavigationIntroDialog } from "./VerticalNavigationIntroDialog";
export interface ProjectsQueryParams {
    page?: number;
    pageSize?: number;
    searchValue: string;
}
interface ProjectsPageProps {
    spaceId: string;
    queryParams: ProjectsQueryParams;
    setQueryParams: QueryParamValuesSetter<ProjectsQueryParams>;
    projectStatus?: ProjectStatus;
    loaderData: ProjectsPageLoaderData;
}
const DashboardDispatcher = ({ dispatchAction }: {
    dispatchAction: AnalyticActionDispatcher;
}) => {
    const [dispatched, setDispatched] = React.useState(false);
    if (!dispatched) {
        dispatchAction("Dashboard Viewed", { action: Action.View, resource: "Dashboard" });
        setDispatched(true);
    }
    return <></>;
};
export interface ProjectsPageLoaderData {
    configuration: DashboardConfigurationResource;
}
export const projectsPageLoader = async (repository: Repository): Promise<ProjectsPageLoaderData> => {
    const configuration = repository.DashboardConfiguration.get();
    return {
        configuration: await configuration,
    };
};
export function ProjectsPage({ spaceId, queryParams, setQueryParams, loaderData, projectStatus }: ProjectsPageProps) {
    const { doBusyTask, status: doBusyTaskStatus } = useLegacyDoBusyTask();
    const { errors } = useAggregateAPIOperationStatus(doBusyTaskStatus);
    const projectsFilter = useProjectsDashboardFilter(queryParams, setQueryParams);
    const [projectGroupIdForProjectDialog, setProjectGroupIdForProjectDialog] = useState<string | null>(null);
    const dispatchAction = useAnalyticActionDispatch();
    const { result: onboardingData, refetch: refetchOnboardingData } = useInlineStatusQuery((repository) => repository.UserOnboarding.getProjectsOnboardingForCurrentUser(), [], "Projects User Onboarding");
    const { isOpen: isAddProjectGroupDialogOpen, openDialog: openAddProjectGroupDialog, closeDialog: closeAddProjectGroupDialog } = useDialogTrigger();
    const { isOpen: isAddProjectDialogOpen, openDialog: openAddProjectDialog, closeDialog: closeAddProjectDialog } = useDialogTrigger();
    const isKGSetupFeatureEnabled = useOctopusFeatureToggle("kubernetes-guided-setup", false);
    const isKGSNthProjectFeatureEnabled = useOctopusFeatureToggle("kubernetes-guided-setup-nth-project", false);
    const openAddProjectDialogWithoutProjectGroupId = useCallback(() => {
        setProjectGroupIdForProjectDialog(null);
        openAddProjectDialog();
    }, [openAddProjectDialog]);
    const openAddProjectDialogWithProjectGroupId = useCallback((groupId: string) => {
        setProjectGroupIdForProjectDialog(groupId);
        openAddProjectDialog();
    }, [openAddProjectDialog]);
    const { result } = useInlineStatusQuery(async (repository) => {
        const hasProjectGroupViewPermissions = isAllowed({ permission: Permission.ProjectGroupView, projectGroup: "*" });
        const projectGroupsPromise = hasProjectGroupViewPermissions ? repository.ProjectGroups.all() : Promise.resolve([]);
        const environmentsPromise = repository.spaceId ? repository.Environments.all() : Promise.resolve([]);
        const [projectGroups, environments] = await Promise.all([projectGroupsPromise, environmentsPromise]);
        return { projectGroups, environments };
    }, [], "Project Groups and Environments");
    const projectGroups = result?.projectGroups;
    const environments = result?.environments;
    const importExportPageAction: PageAction = {
        type: "custom",
        key: "importExport",
        content: <ImportExportButton spaceId={spaceId}/>,
    };
    const addProjectGroupPageAction: PageAction = {
        type: "button",
        label: "Add Project Group",
        hasPermissions: isAllowed({ permission: Permission.ProjectGroupCreate, projectGroup: "*" }),
        buttonType: "secondary",
        onClick: () => {
            dispatchAction("Add Project Group", { resource: "Project Group", action: Action.Add });
            openAddProjectGroupDialog();
        },
    };
    const addProjectPageAction: PrimaryPageAction = {
        type: "button",
        label: "Add Project",
        hasPermissions: isAllowed({ permission: Permission.ProjectCreate, projectGroup: "*" }),
        onClick: () => {
            dispatchAction("Add Project", { resource: "Project", action: Action.Add });
            projectStatus?.dispatch({ type: projectStatusActions.projectInitiated, hasEnvironments: environments ? environments.length > 0 : false });
            openAddProjectDialogWithoutProjectGroupId();
        },
        disabled: !projectGroups || projectGroups.length === 0,
    };
    const close = (project: ProjectResource | undefined) => {
        if (project) {
            dispatchAction("Save Project", { action: Action.Save, resource: "Project" });
            if (isKGSetupFeatureEnabled && isKGSNthProjectFeatureEnabled) {
                refetchOnboardingData();
            }
        }
        else {
            dispatchAction("Cancel Project Creation", { action: Action.Cancel, resource: "Project" });
        }
        closeAddProjectDialog();
    };
    const dialogs = (<>
            <Dialog open={isAddProjectGroupDialogOpen}>
                {/* After new Project Group is created in existing Projects page, it redirects to itself with the new projectGroupId as query param.
            Then the componentDidUpdate will trigger, and Project Groups will be reloaded, hence showing the newly created one.
            However, for this temporary page, how we show empty Project Group is still TBD, therefore just closing the dialog here for now.
            It is not good user experience, because user does not know whether a new Project Group is created or not, we will come back to solve this.
          */}
                <AddProjectGroup projectGroupCreated={(resource) => {
            setQueryParams({ page: 1, searchValue: resource.Name });
            closeAddProjectGroupDialog();
        }}/>
            </Dialog>
            {!isKGSetupFeatureEnabled || !isKGSNthProjectFeatureEnabled ? (<AddNewProjectDialog open={isAddProjectDialogOpen} close={close} projectGroupId={projectGroupIdForProjectDialog ?? undefined}/>) : (isAddProjectDialogOpen && (<NewProjectWizard open={isAddProjectDialogOpen} fullScreen={true} spaceId={spaceId} projectGroupId={projectGroupIdForProjectDialog ?? undefined} doBusyTask={doBusyTask} isOnboarding={false} close={close} projectStatus={projectStatus}/>))}

            <VerticalNavigationIntroDialog />
        </>);
    if (!onboardingData) {
        return <PageLoading errors={errors}/>;
    }
    // If this is a fresh instance, show onboarding.
    if (onboardingData.TotalProjectsInSpace === 0) {
        return (<>
                <DashboardOnboardingLayout header={{ title: "Projects", actions: [importExportPageAction, addProjectGroupPageAction], primaryAction: addProjectPageAction }} spaceId={spaceId} canContinueOnboarding={false} onboardingProject={undefined} doBusyTask={doBusyTask} projectStatus={projectStatus}/>
                {dialogs}
            </>);
    }
    if (onboardingData.ContinueFirstProject && hasPermission(Permission.ProjectCreate)) {
        return (<>
                <DashboardOnboardingLayout header={{ title: "Projects", actions: [importExportPageAction, addProjectGroupPageAction], primaryAction: addProjectPageAction }} spaceId={spaceId} canContinueOnboarding={!!onboardingData.ContinueFirstProject} onboardingProject={onboardingData.ContinueFirstProject} doBusyTask={doBusyTask} projectStatus={projectStatus}/>
                {dialogs}
            </>);
    }
    return (<PermissionCheck permission={Permission.ProjectView} project="*" alternate={<NoProjectViewPermissionsDashboard />}>
            <DashboardDispatcher dispatchAction={dispatchAction}/>
            <ProjectsDashboardPageContent spaceId={spaceId} pageActions={[importExportPageAction, addProjectGroupPageAction]} primaryPageAction={addProjectPageAction} doBusyTaskStatus={doBusyTaskStatus} dashboardConfiguration={loaderData.configuration} filter={projectsFilter} setFilter={setQueryParams} onAddProjectToGroup={openAddProjectDialogWithProjectGroupId} projectStatus={projectStatus}/>
            {dialogs}
        </PermissionCheck>);
}
function useProjectsDashboardFilter(queryParams: ProjectsQueryParams, setQueryParams: QueryParamValuesSetter<ProjectsQueryParams>): ProjectsFilter {
    const [userDefaultProjectsPageSize, setUserDefaultProjectsPageSize] = useLocalStorage(`Octopus.ProjectsDashboard.PageSize`, defaultProjectsPageSize);
    const filter = useMemo(() => ({
        searchValue: queryParams.searchValue,
        page: queryParams.page ?? 1,
        pageSize: (isProjectsPageSize(queryParams.pageSize) ? queryParams.pageSize : undefined) ?? userDefaultProjectsPageSize,
    }), [userDefaultProjectsPageSize, queryParams.page, queryParams.pageSize, queryParams.searchValue]);
    if (userDefaultProjectsPageSize !== filter.pageSize) {
        setUserDefaultProjectsPageSize(filter.pageSize);
    }
    useEffect(() => {
        if (!isEqual(filter, queryParams))
            setQueryParams(filter);
    }, [queryParams, filter, setQueryParams]);
    return filter;
}
export function ImportExportButton({ spaceId }: {
    spaceId: string;
}) {
    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();
    return (<>
            <ActionButton type={ActionButtonType.Secondary} icon={<DropDownIcon />} iconPosition="right" label="Import/Export" onClick={openMenu} menuButtonAttributes={buttonAriaAttributes}/>
            <SimpleMenu menuState={menuState} items={getImportExportMenuItems(spaceId)} accessibleName={"Import/Export Menu"} compact={true}/>
        </>);
}
function NoProjectViewPermissionsDashboard() {
    return (<Level1EmptyStateLayout title={"Permissions"} intro={"Your current permissions don\u2019t allow you to view projects. If you require more information or believe this is in error please contact your administrator. "} image={<EmptyStateDashboardNoPermissionIllustration />}/>);
}
