/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { LinearProgress, IconButton } from "@octopusdeploy/design-system-components";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import cn from "classnames";
import * as React from "react";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import BusyFromPromise from "~/components/BusyFromPromise/BusyFromPromise";
import CardTitle from "~/components/CardTitle";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { OverflowMenuNavLink, OverflowMenuRemoveItem } from "../../../components/OverflowMenu/OverflowMenu";
import styles from "./style.module.less";
interface VariableSetSectionHeadingProps {
    spaceId: string;
    variableSetId: string;
    variableSetName: string;
    variableSetTab: "variables" | "variableTemplates";
    isExpanded: boolean;
    busy?: Promise<void>;
    onRemoveVariableSet?: () => Promise<boolean>;
    onExpandedChanged: (expanded: boolean) => void;
}
class VariableSetSectionHeading extends BaseComponent<VariableSetSectionHeadingProps, {}> {
    render() {
        const linkTarget = links.editVariableSetPage.generateUrl({ spaceId: this.props.spaceId, variableSetId: this.props.variableSetId }, { activeTab: this.props.variableSetTab });
        const overflowMenuItems: Array<OverflowMenuRemoveItem | OverflowMenuNavLink> = [
            OverflowMenuItems.navItem("Edit Variable Set", linkTarget, {
                permission: Permission.ProjectEdit,
                wildcard: true,
            }),
        ];
        if (this.props.onRemoveVariableSet) {
            overflowMenuItems.push(OverflowMenuItems.removeItem("Remove", `Are you sure you want to remove ${this.props.variableSetName} set?`, this.props.onRemoveVariableSet, <div>
                        <p>The values for any variable templates that are removed by this action will be permanently deleted from associated Tenants, there is no going back.</p>
                        <p>Do you wish to continue?</p>
                    </div>, {
                permission: Permission.ProjectEdit,
                wildcard: true,
            }));
        }
        return (<div>
                <div className={styles.header} onClick={() => this.props.onExpandedChanged(!this.props.isExpanded)}>
                    <BusyFromPromise promise={this.props.busy}>
                        {(busy: boolean) => (<div className={styles.busy}>
                                <LinearProgress variant={"indeterminate"} show={busy}/>
                            </div>)}
                    </BusyFromPromise>
                    <div className={styles.textAndButtons}>
                        <div className={styles.variableSetLink}>
                            <CardTitle>{this.props.variableSetName}</CardTitle>
                        </div>
                        <OverflowMenu menuItems={overflowMenuItems}/>
                        <IconButton icon={"ChevronDownIcon"} accessibleName={this.props.isExpanded ? "Collapse" : "Expand"} className={cn(styles.expandIcon, this.props.isExpanded ? styles.expandOpen : styles.expandClosed)} style={{ marginRight: "0.5rem" }}/>
                    </div>
                </div>
                {this.props.isExpanded && this.props.children}
            </div>);
    }
    static displayName = "VariableSetSectionHeading";
}
export default VariableSetSectionHeading;
