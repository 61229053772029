/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { RunbooksDashboardItemResource } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes/src/link/LinkHref";
import * as React from "react";
import DateFormatter from "~/utils/DateFormatter";
import { TaskStatusDetails } from "./TaskStatusDetails";
interface RunbookRunTaskStatusDetailsProps {
    run: RunbooksDashboardItemResource;
    link: LinkHref;
    noPadding?: boolean;
}
export function RunbookRunTaskStatusDetails({ run, link, noPadding }: RunbookRunTaskStatusDetailsProps) {
    const startTime = run.StartTime || run.QueueTime;
    const date = DateFormatter.dateToCustomFormat(startTime, "MMMM Do YYYY h:mm A") ?? undefined;
    return <TaskStatusDetails task={run} title={run.RunName} subtitle={date} link={link} noPadding={noPadding}/>;
}
