import type { TenantResource } from "..";
import type { ReferenceDataItem } from "../resources/referenceDataItem";
import type { RecurringScheduleResource } from "./deploymentFreezes/getDeploymentFreezesResponse";
import type { SummaryView } from "./tenants/getTenantsOverviewBffResponse";
export interface DeploymentFreezeOverviewBffResponse {
    TotalDeploymentFreezeCount: number;
    FilteredDeploymentFreezeCount: number;
    DeploymentFreezes: DeploymentFreezeSummary[];
    Filters: DeploymentFreezeBffFilters;
}
export interface DeploymentFreezeBffFilters {
    Projects: ReferenceDataItem[];
    Tenants: TenantResource[];
    Environments: ReferenceDataItem[];
    Status: DeploymentFreezeStatus[];
}
export enum DeploymentFreezeStatus {
    Expired = "Expired",
    Scheduled = "Scheduled",
    Active = "Active"
}
export interface DeploymentFreezeSummary {
    Id: string;
    Name: string;
    Start: string;
    End: string;
    RecurringSchedule?: RecurringScheduleResource;
    Status: DeploymentFreezeStatus;
    Projects: SummaryView<DeploymentFreezeScopeOverview>;
    Tenants: SummaryView<DeploymentFreezeScopeOverview>;
}
export interface DeploymentFreezeScopeOverview {
    Id: string;
    Name: string;
    IsDisabled: boolean;
    Environments: SummaryView<DeploymentFreezeOverviewEnvironment>;
}
export interface DeploymentFreezeOverviewEnvironment {
    Id: string;
    Name: string;
}
export interface DeploymentFreezeFilterQueryParameters {
    filterByProjects: string[];
    filterByTenants: string[];
    filterByEnvironments: string[];
    filterByStatus: string[];
    filterByName: string | undefined;
    pageNumber: string;
    pageSize: string;
}
