import { css, cx } from "@emotion/css";
import { fontSize, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
type Size = "small" | "medium" | "base";
export type LinkProps = {
    href: string;
    label: string;
    leadingIcon?: React.ReactNode;
    trailingIcon?: React.ReactNode;
    openInNewTab?: boolean;
    size?: Size;
};
export function Link({ href, label, leadingIcon, trailingIcon, openInNewTab = false, size }: LinkProps) {
    return (<a className={cx(styles.link, { [styles.baseFontSize]: size === "base" }, { [styles.smallFontSize]: size === "small" })} href={href} target={openInNewTab ? "_blank" : undefined} rel={openInNewTab ? "noreferrer noopener" : undefined}>
            {leadingIcon ? <span>{leadingIcon}</span> : null}
            {label}
            {trailingIcon ? <span>{trailingIcon}</span> : null}
        </a>);
}
const styles = {
    link: css({
        display: "inline-flex",
        alignItems: "center",
        gap: space[4],
        font: text.regular.default.medium,
        color: themeTokens.color.text.link.default,
        textDecoration: "none",
        "&:hover": {
            color: themeTokens.color.text.link.pressed,
            svg: {
                fill: themeTokens.color.text.link.pressed,
            },
        },
        svg: {
            height: "14px",
            width: "14px",
            fill: themeTokens.color.text.link.default,
        },
    }),
    baseFontSize: css({
        fontSize: fontSize.base,
    }),
    smallFontSize: css({
        fontSize: fontSize.small,
    }),
};
