import { css } from "@emotion/css";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { RecurringScheduleResource } from "@octopusdeploy/octopus-server-client/src/resources/deploymentFreezes/getDeploymentFreezesResponse";
import type { FreezeScheduleResource } from "@octopusdeploy/octopus-server-client/src/resources/deploymentFreezes/getRecurringFreezePreviewResponse";
import type { Moment } from "moment";
import React, { useCallback, useState, useEffect } from "react";
import { repository } from "~/clientInstance";
const DEBOUNCE_DELAY = 500;
const DEFAULT_OCCURRENCES = 10;
interface RecurringFreezePreviewProps {
    recurringSchedule?: RecurringScheduleResource;
    startDate: Moment;
    endDate: Moment;
    userTimezone: string;
}
const formatDateTime = (dateTimeStr: string): string => {
    const date = new Date(dateTimeStr);
    const dateStr = date.toLocaleDateString("en-US", {
        weekday: "short",
        month: "short",
        day: "numeric",
        year: "numeric",
    });
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const period = date.getHours() >= 12 ? "PM" : "AM";
    return `${dateStr} ${hours}:${minutes} ${period}`;
};
export const RecurringFreezePreview = ({ recurringSchedule, startDate, endDate, userTimezone }: RecurringFreezePreviewProps) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [dateRanges, setDateRanges] = useState<FreezeScheduleResource[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const fetchDateRanges = useCallback(async (count: number) => {
        try {
            setIsLoading(true);
            const response = await repository.DeploymentFreezes.getRecurringFreezePreview({
                StartDate: startDate.format(),
                EndDate: endDate.format(),
                Occurrences: count,
                RecurringSchedule: recurringSchedule,
            });
            setDateRanges(response.Schedules);
        }
        catch (error) {
            console.error("Error generating date ranges:", error);
        }
        finally {
            setIsLoading(false);
        }
    }, [startDate, endDate, recurringSchedule]);
    useEffect(() => {
        let timeoutId: ReturnType<typeof setTimeout> | undefined = undefined;
        if (isExpanded) {
            timeoutId = setTimeout(() => {
                fetchDateRanges(DEFAULT_OCCURRENCES);
            }, DEBOUNCE_DELAY);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [isExpanded, fetchDateRanges, recurringSchedule]);
    const handleToggleExpand = () => {
        setIsExpanded((prev) => !prev);
    };
    return (<div className={containerStyles}>
            <div className={toggleStyles} onClick={handleToggleExpand}>
                <span className={toggleStyles}>
                    {isExpanded ? "Hide" : "Show"} next {dateRanges?.length || DEFAULT_OCCURRENCES} occurrences
                </span>
            </div>

            {isExpanded && (<div className={previewContainerStyles}>
                    <div className={headerStyles}>
                        Next {dateRanges?.length} freeze windows: ({userTimezone})
                    </div>

                    {isLoading ? (<div>Loading...</div>) : (dateRanges?.map((range, index) => (<div key={index} className={freezeWindowStyles}>
                                <div className={dateRangeContainerStyles}>
                                    <div className={dateTimeStyles}>{formatDateTime(range.ScheduleStart)}</div>
                                    <div className={arrowStyles}>to</div>
                                    <div className={dateTimeStyles}>{formatDateTime(range.ScheduleEnd)}</div>
                                </div>
                            </div>)))}
                </div>)}
        </div>);
};
const containerStyles = css({
    marginTop: space[16],
});
const toggleStyles = css({
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    color: themeTokens.color.text.link.default,
});
const occurrencesInputStyles = css({
    width: "40px",
    margin: "0px 4px",
});
const previewContainerStyles = css({
    marginTop: space[8],
    padding: space[16],
    backgroundColor: themeTokens.color.background.secondary.default,
    borderRadius: space[4],
});
const headerStyles = css({
    color: themeTokens.color.text.secondary,
    marginBottom: space[12],
});
const freezeWindowStyles = css({
    padding: `${space[8]} ${space[12]}`,
    marginBottom: space[8],
    backgroundColor: themeTokens.color.background.primary.default,
    borderRadius: space[4],
    cursor: "pointer",
    transition: "background-color 0.2s ease",
    ":last-child": {
        marginBottom: 0,
    },
    ":hover": {
        backgroundColor: themeTokens.color.background.primary.pressed,
    },
});
const dateRangeContainerStyles = css({
    display: "grid",
    gridTemplateColumns: "160px 40px 160px",
    gap: space[4],
    alignItems: "center",
});
const dateTimeStyles = css({
    color: themeTokens.color.text.primary,
    textAlign: "left",
    whiteSpace: "nowrap",
});
const arrowStyles = css({
    color: themeTokens.color.text.secondary,
    textAlign: "center",
});
