import { BooleanRadioButtonGroup, BooleanRadioButton } from "@octopusdeploy/design-system-components";
import React from "react";
import { ExpandableFormSection, Note, Summary } from "~/components/form";
type CreateFeedsWithLocalOrSmbPathsFeature = {
    isEnabled: boolean;
    onChange: (isEnabled: boolean) => void;
};
export function CreateFeedsWithLocalOrSmbPathsFeature({ isEnabled, onChange }: CreateFeedsWithLocalOrSmbPathsFeature) {
    return (<ExpandableFormSection key="IsConfigureFeedsWithLocalOrSmbPathsEnabled" errorKey="IsConfigureFeedsWithLocalOrSmbPathsEnabled" title="Allow creation with Local or SMB paths" summary={isEnabled ? Summary.summary("Enabled") : Summary.default("Disabled")} help="Enable/disable the ability to create feeds with Local or SMB paths.">
            <BooleanRadioButtonGroup value={isEnabled} onChange={onChange}>
                <BooleanRadioButton value={true} label="Enabled"/>
                <BooleanRadioButton value={false} label="Disabled" isDefault={true}/>
            </BooleanRadioButtonGroup>
            <Note style={{ marginTop: "1rem" }}>This feature enables users to create NuGet Feeds with Local or SMB paths.</Note>
        </ExpandableFormSection>);
}
