/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import { RepeatOffIcon, RepeatOnIcon } from "@octopusdeploy/design-system-icons";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import { type AnnuallyRecurringSchedule, type DailyRecurringSchedule, type MonthlyRecurringSchedule, type WeeklyRecurringSchedule, type RecurringScheduleResource, } from "@octopusdeploy/octopus-server-client/src/resources/deploymentFreezes/getDeploymentFreezesResponse";
import moment from "moment";
import React from "react";
import { DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/index";
import { ScheduleFormatter } from "~/utils/ScheduledTriggerDescriptionHelper/ScheduleFormatter";
interface DeploymentFreezeDataRecurrenCadenceCellProps {
    startDate?: string;
    recurringSchedule?: RecurringScheduleResource | DailyRecurringSchedule | WeeklyRecurringSchedule | MonthlyRecurringSchedule | AnnuallyRecurringSchedule | undefined;
}
export const DeploymentFreezeDataRecurrenCadenceCell = ({ startDate, recurringSchedule }: DeploymentFreezeDataRecurrenCadenceCellProps) => {
    const iconColor = recurringSchedule ? themeTokens.color.icon.success : themeTokens.color.icon.secondary;
    return (<DataTableRowColumn className={cellStyles}>
            <div className={innerCellStyles}>
                {recurringSchedule ? <RepeatOnIcon size={24} color={iconColor}/> : <RepeatOffIcon size={24} color={iconColor}/>}
                <div className={cadenceStyles}>{ScheduleFormatter.buildScheduleSummary(recurringSchedule, moment(startDate))}</div>
            </div>
        </DataTableRowColumn>);
};
const cadenceStyles = css({ marginLeft: "0.5rem" });
const innerCellStyles = css({
    display: "flex",
    alignItems: "center",
});
const iconStyles = css({
    alignSelf: "center",
    transform: "rotate(90deg)",
});
const cellStyles = css({ width: "30%" });
