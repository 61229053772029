/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { css } from "@emotion/css";
import { Tabs, Tab } from "@material-ui/core";
import { IconButton, Popover } from "@octopusdeploy/design-system-components";
import { DockBottomIcon, DockLeftIcon, DockRightIcon, InfoCircleIcon } from "@octopusdeploy/design-system-icons";
import { space } from "@octopusdeploy/design-system-tokens";
import DevToolsOverview from "app/components/DevTools/OverviewTab/OverviewTab";
import React from "react";
import { useSetDevToolsDrawerDockLocation, useToggleDevTools } from "~/components/DevTools/DevToolsContext";
const tabsContainerStyles = css({
    gridArea: "tabs",
    overflowY: "auto",
    gridColumn: "span 1",
});
const actionsStyles = css({
    gridArea: "actions",
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    "& > *": {
        display: "flex",
    },
    gap: space["8"],
    paddingRight: space["8"],
});
const devToolbarStyles = css({
    flex: "auto",
    display: "grid",
    columnGap: space["16"],
    gridTemplateColumns: "1fr auto",
});
interface DevToolbarProps {
    selectedTab: string;
    onSelectTab: (value: string) => void;
    tabs: Array<{
        label: string;
    }>;
}
export const DevToolbar: React.FC<DevToolbarProps> = (props) => {
    const toggleDevTools = useToggleDevTools();
    const setDrawerDockLocation = useSetDevToolsDrawerDockLocation();
    return (<div className={devToolbarStyles}>
            <div className={tabsContainerStyles}>
                <Tabs value={props.selectedTab} onChange={(e, val) => props.onSelectTab(val)} scrollButtons={"auto"} variant={"scrollable"}>
                    {props.tabs.map((x) => (<Tab key={x.label} value={x.label} label={x.label}/>))}
                </Tabs>
            </div>
            <div className={actionsStyles}>
                <VersionInfoButton />
                <IconButton onClick={() => setDrawerDockLocation("left")} customIcon={<DockLeftIcon size={24}/>}/>
                <IconButton onClick={() => setDrawerDockLocation("bottom")} customIcon={<DockBottomIcon size={24}/>}/>
                <IconButton onClick={() => setDrawerDockLocation("right")} customIcon={<DockRightIcon size={24}/>}/>
                <IconButton onClick={toggleDevTools} icon={"XmarkIcon"}/>
            </div>
        </div>);
};
DevToolbar.displayName = "DevToolbar"
type VersionInfoButtonProps = {};
function VersionInfoButton(props: VersionInfoButtonProps) {
    const [buttonAnchor, setButtonAnchor] = React.useState<HTMLElement | null>(null);
    const handleClick = React.useCallback((e: React.MouseEvent) => {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        setButtonAnchor(e.currentTarget as HTMLElement);
    }, []);
    return (<>
            <Popover placement={"top-start"} anchorEl={buttonAnchor} open={!!buttonAnchor} onClose={() => setButtonAnchor(null)}>
                <div className={popoverContentStyles}>
                    <DevToolsOverview />
                </div>
            </Popover>
            <IconButton customIcon={<InfoCircleIcon size={24}/>} onClick={handleClick}/>
        </>);
}
const popoverContentStyles = css({
    padding: space["16"],
});
export default DevToolbar;
