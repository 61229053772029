import React from "react";
import type { UnknownPageRegistration } from "~/routing/pageRegistrations/PageRegistration";
const PageRegistrationContext = React.createContext<UnknownPageRegistration | null>(null);
interface PageRegistrationProviderProps {
    pageRegistration: UnknownPageRegistration;
    children: React.ReactNode;
}
export function PageRegistrationProvider({ children, pageRegistration }: PageRegistrationProviderProps) {
    return <PageRegistrationContext.Provider value={pageRegistration}>{children}</PageRegistrationContext.Provider>;
}
export function usePageRegistration() {
    const pageRegistration = React.useContext(PageRegistrationContext);
    if (!pageRegistration) {
        throw new Error("The PageRegistrationContext has not been set up. Please check the component hierarchy and ensure the appropriate context exists above this component.");
    }
    return pageRegistration;
}
