/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { PageAction } from "@octopusdeploy/design-system-components";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { RunbookSnapshotResource, RunbooksDashboardItemResource, TaskResource, RunbookRunTemplateResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { isEqual } from "lodash";
import * as React from "react";
import RunbooksNavigationTabs from "~/areas/projects/components/Runbooks/RunbooksNavigationTabs";
import type { ProjectContextProps } from "~/areas/projects/context";
import { useProjectContext } from "~/areas/projects/context";
import Tasks from "~/areas/tasks/components/Tasks/Tasks";
import { repository } from "~/clientInstance";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import InternalLink from "~/components/Navigation/InternalLink";
import { PageContent } from "~/components/PageContent/PageContent";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import TaskDetails from "~/components/TaskDetails";
import type { GitRefQueryParamsProps } from "../BranchSelector/useProjectGitRef";
import { LastPublishedChip } from "./LastPublishedChip";
import { NewRunbookRunsListPage } from "./NewRunbookRunsListPage/NewRunbookRunsListPage";
import PublishButton from "./PublishButton";
import { RunNowButton } from "./RunNowButton";
import type { RunbookContextProps } from "./RunbookContext";
import { useRunbookContext } from "./RunbookContext";
import RunbookTaskStatusDetails from "./RunbookTaskStatusDetails/RunbookTaskStatusDetails";
import { useNewRunbookRunsListPageFeatureToggle } from "./useNewRunbookRunsListPageFeatureToggle";
interface RunbookRunsListProps {
    projectContext: ProjectContextProps;
    runbookContext: RunbookContextProps;
}
interface RunbookRunsListState extends DataBaseComponentState {
    hasSteps: boolean;
    publishedRunbookSnapshot: RunbookSnapshotResource;
    runbookRunTemplate: RunbookRunTemplateResource;
    dashboardItems: RunbooksDashboardItemResource[];
}
class RunbookRunsListLayoutInternal extends DataBaseComponent<RunbookRunsListProps, RunbookRunsListState> {
    constructor(props: RunbookRunsListProps) {
        super(props);
        this.state = {
            hasSteps: false,
            publishedRunbookSnapshot: null!,
            runbookRunTemplate: null!,
            dashboardItems: [],
        };
    }
    async componentDidMount() {
        // This is an async method, but we don't care about the result, so fire and forget (no await).
        this.props.projectContext.actions.refreshGitVariableErrors();
        await this.reload();
    }
    async componentDidUpdate(prevProps: RunbookRunsListProps) {
        const nextRunbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        const currentRunbook = prevProps.runbookContext.state && prevProps.runbookContext.state.runbook;
        if (!isEqual(currentRunbook, nextRunbook)) {
            await this.reload();
        }
    }
    async reload() {
        await this.doBusyTask(async () => {
            const runbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
            if (!runbook) {
                return;
            }
            const runbookProcess = repository.RunbookProcess.get(runbook.RunbookProcessId);
            const publishedRunbookSnapshot = runbook.PublishedRunbookSnapshotId ? await repository.RunbookSnapshots.get(runbook.PublishedRunbookSnapshotId) : null!;
            const runbookRunTemplate = publishedRunbookSnapshot ? await repository.RunbookSnapshots.getRunbookRunTemplate(publishedRunbookSnapshot) : null!;
            this.setState({
                hasSteps: (await runbookProcess).Steps.length > 0,
                publishedRunbookSnapshot,
                runbookRunTemplate,
            });
        });
    }
    render() {
        const project = this.props.projectContext.state && this.props.projectContext.state.model;
        const runbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        if (!project || !runbook || this.errors) {
            return (<PageContent header={{ title: "Runbook runs" }} fullWidth={true} busy={this.state.busy} errors={this.errors}>
                    <RunbooksNavigationTabs />
                </PageContent>);
        }
        const runbookRunTemplate = this.state.runbookRunTemplate;
        const publishedRunbookSnapshot = this.state.publishedRunbookSnapshot;
        const isRunbookRunTemplateModified = runbookRunTemplate && (runbookRunTemplate.IsRunbookProcessModified || runbookRunTemplate.IsVariableSetModified || runbookRunTemplate.IsLibraryVariableSetModified);
        const pageActions: PageAction[] = [];
        if (this.state.hasSteps) {
            pageActions.push({
                type: "custom",
                key: "Publish",
                content: <PublishButton publishedRunbookSnapshot={publishedRunbookSnapshot} isRunbookRunTemplateModified={isRunbookRunTemplateModified}/>,
                hasPermissions: isAllowed({
                    permission: Permission.RunbookEdit,
                    project: project.Id,
                    wildcard: true,
                }),
            });
        }
        pageActions.push({
            type: "custom",
            key: "Run now",
            content: <RunNowButton spaceId={runbook.SpaceId} isDisabled={!this.state.hasSteps} projectSlug={project.Slug} projectId={project.Id} runbookId={runbook.Id}/>,
            hasPermissions: isAllowed({
                permission: Permission.RunbookRunCreate,
                project: project.Id,
                wildcard: true,
            }),
        });
        const titleChip = publishedRunbookSnapshot && <LastPublishedChip project={project} publishedRunbookSnapshot={publishedRunbookSnapshot} isRunbookRunTemplateModified={isRunbookRunTemplateModified}/>;
        return (<Tasks {...this.props} restrictToProjectId={project.Id} restrictToRunbookId={runbook.Id} renderLayout={(layoutProps) => {
                return (<PageContent header={{
                        title: runbook.Name,
                        breadcrumbs: [{ label: "Runbooks", pageUrl: links.projectRunbooksPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }) }],
                        chip: titleChip,
                        pageActions,
                    }} busy={layoutProps.busy} errors={layoutProps.errors} fullWidth={true}>
                            <RunbooksNavigationTabs />
                            {layoutProps.children}
                        </PageContent>);
            }} onNewItems={async (tasks: Array<TaskResource<any>>) => {
                await this.loadRunbookRunsAndSnapshots(tasks);
                return tasks;
            }} renderCell={(cellProps) => {
                const task = cellProps.task;
                const dashboardItem = this.state.dashboardItems.find((x) => x.TaskId === task.Id);
                if (dashboardItem) {
                    return <RunbookTaskStatusDetails project={project} item={dashboardItem}/>;
                }
                // Permission edge-case: We fall back to the regular task-cell for cases where scopings on the user's permissions
                // stop them from seeing dashboard items related to this task.
                return (<InternalLink to={links.taskPage.generateUrl({ taskId: task.Id })}>
                            <TaskDetails task={task} stripTopBottomPadding={true}/>
                        </InternalLink>);
            }}/>);
    }
    private async loadRunbookRunsAndSnapshots(task: Array<TaskResource<any>>) {
        const taskIds = task.map((x) => x.Id);
        const runbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        const dashboardItemsCollection = await repository.Progression.getTaskRunDashboardItemsForRunbook(runbook!, { taskIds, take: taskIds.length });
        this.setState({ dashboardItems: dashboardItemsCollection.Items });
    }
    static displayName = "RunbookRunsListLayoutInternal";
}
interface RunbookRunsListLayoutProps extends GitRefQueryParamsProps {
    spaceId: string;
}
export function RunbookRunsListPage(props: RunbookRunsListLayoutProps) {
    const runbookContext = useRunbookContext();
    const projectContext = useProjectContext();
    const newRunbookRunsListPageEnabled = useNewRunbookRunsListPageFeatureToggle(projectContext.state.model);
    if (newRunbookRunsListPageEnabled) {
        return <NewRunbookRunsListPage {...props}/>;
    }
    return <RunbookRunsListLayoutInternal runbookContext={runbookContext} projectContext={projectContext}/>;
}
