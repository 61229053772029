/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { CertificateResource, ResourceCollection } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import { PageContent } from "~/components/PageContent/PageContent";
import CertificateSearch from "~/components/form/CertificateSearch/CertificateSearch";
import CertificateSidebar from "./CertificateSidebar";
import styles from "./style.module.less";
interface ArchivedCertificatesPageProps {
    spaceId: string;
}
interface CertificatesState extends DataBaseComponentState {
    certificates?: ResourceCollection<CertificateResource>;
}
export default class ArchivedCertificatesPage extends DataBaseComponent<ArchivedCertificatesPageProps, CertificatesState> {
    constructor(props: ArchivedCertificatesPageProps) {
        super(props);
        this.state = {
            certificates: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const certificates = await repository.Certificates.list({ archived: true });
            this.setState({
                certificates,
            });
        });
    }
    render() {
        const content = this.state.certificates &&
            (this.state.certificates.TotalResults > 0 ? (<CertificateSearch certificates={this.state.certificates} doBusyTask={this.doBusyTask} archived/>) : (<div className={styles.root}>
                    <span>The certificate archive is empty.</span>
                </div>));
        return (<PageContent header={{
                title: "Archived Certificates",
                breadcrumbs: [{ label: "Certificates", pageUrl: links.certificatesPage.generateUrl({ spaceId: this.props.spaceId }) }],
            }} busy={this.state.busy} errors={this.errors} sidebar={<CertificateSidebar />}>
                {content}
            </PageContent>);
    }
    static displayName = "ArchivedCertificatesPage";
}
