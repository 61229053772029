/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-eq-null */
import { Callout } from "@octopusdeploy/design-system-components";
import type { FeedResource } from "@octopusdeploy/octopus-server-client";
import { FeedType, isOctopusProjectFeed, OctopusError, feedTypeCanSearchEmpty } from "@octopusdeploy/octopus-server-client";
import _ from "lodash";
import * as React from "react";
import { CalloutPrimaryPackageFeedTriggerConnections } from "~/areas/projects/components/Triggers/ProcessCallouts/CalloutPrimaryPackageFeedTriggerConnections";
import type { RelatedTriggersDependencies } from "~/areas/projects/components/Triggers/ProcessCallouts/CalloutReferencedPackageFeedTriggerConnections";
import { repository } from "~/clientInstance";
import type { AutoCompleteOption } from "~/components/AutoComplete/AutoComplete";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import { BoundAutoComplete } from "~/components/BoundAutoComplete/BoundAutoComplete";
import { FeedSelector, getDefaultFeedId } from "~/components/Selectors/FeedSelector";
import { required } from "~/components/form";
import Note from "~/primitiveComponents/form/Note/Note";
import { DebounceText } from "~/primitiveComponents/form/Text/Text";
import { createErrorsFromOctopusError } from "../DataBaseComponent/Errors";
import { VariableLookupText } from "../form/VariableLookupText";
import { PackageVersionOnActionVisibility } from "./packageVersionOnActionEnabled";
export enum PackageSelectorType {
    AutoComplete = "AutoComplete",
    FreeText = "FreeText"
}
export interface PackageSelectorProps {
    packageId: string | undefined;
    feedId: string | undefined;
    projectId: string | undefined;
    feeds: FeedResource[];
    localNames: string[] | undefined;
    feedType?: FeedType[];
    autoFocus?: boolean;
    packageIdError?: string;
    feedIdError?: string;
    packageSelectorType?: PackageSelectorType;
    feedSelectLabel?: string;
    packageSelectLabel?: string;
    relatedTriggersDependencies?: RelatedTriggersDependencies;
    onPackageIdChange(value: string): void;
    onFeedIdChange(value: string): void;
    refreshFeeds(): Promise<void>;
    modelDirty?: boolean;
    packageVersion?: string;
    onPackageVersionChange?(value: string | undefined): void;
}
interface PackageSelectorState {
    looksLikeVersion: boolean;
    feedDoesNotSupportSearchError?: string;
    selectedFeed: FeedResource | null;
}
export const possibleFeeds = (feeds: FeedResource[], feedType?: FeedType[]) => {
    return feeds.filter((f) => !isOctopusProjectFeed(f.FeedType) && // We will never show the OctopusProject feed when selecting packages
        (!feedType || feedType.length === 0 || feedType.some((feedTypeToCheck) => feedTypeToCheck === f.FeedType)));
};
class PackageSelector extends BaseComponent<PackageSelectorProps, PackageSelectorState> {
    constructor(props: PackageSelectorProps) {
        super(props);
        this.state = {
            looksLikeVersion: false,
            selectedFeed: null,
        };
    }
    componentDidMount() {
        // Default to built-in feed.
        if (!this.props.feedId) {
            const defaultFeedId = getDefaultFeedId(possibleFeeds(this.props.feeds, this.props.feedType));
            if (defaultFeedId) {
                this.setState({ selectedFeed: this.props.feeds.find((f) => f.Id === defaultFeedId) ?? null });
                this.props.onFeedIdChange(defaultFeedId);
            }
        }
        else {
            this.setState({ selectedFeed: this.props.feeds.find((f) => f.Id === this.props.feedId) ?? null });
        }
    }
    checkIfLooksLikeVersion = () => {
        let looksLikeVersion = false;
        const packageId = this.props.packageId;
        if (packageId) {
            if (packageId.match(/\.[0-9]+\.?$/)) {
                looksLikeVersion = true;
            }
        }
        this.setState({ looksLikeVersion });
    };
    render() {
        const feeds = possibleFeeds(this.props.feeds, this.props.feedType);
        const feedSelectLabel = this.props.feedSelectLabel || "Package feed";
        const canFixPackageVersion = !!this.props.onPackageVersionChange;
        return (<div>
                <FeedSelector localNames={this.props.localNames} feedId={this.props.feedId} feeds={feeds} feedType={this.props.feedType} refreshFeeds={this.props.refreshFeeds} feedSelectLabel={feedSelectLabel} feedIdError={this.props.feedIdError} onChange={this.feedChanged}/>
                {this.renderPackageIdSelector(this.state.selectedFeed)}

                {this.props.relatedTriggersDependencies && <CalloutPrimaryPackageFeedTriggerConnections dependencies={this.props.relatedTriggersDependencies} feedType={this.state.selectedFeed?.FeedType} modelDirty={this.props.modelDirty || false}/>}
                <PackageVersionOnActionVisibility>
                    {canFixPackageVersion && (<>
                            <DebounceText label="Version (optional)" accessibleName="Version (optional)" value={this.props.packageVersion!} onChange={(version) => this.packageVersionChanged(version)}/>
                            <Note>Package will be locked to this version.</Note>
                        </>)}
                </PackageVersionOnActionVisibility>
            </div>);
    }
    private renderPackageIdSelector(selectedFeed: FeedResource | null) {
        const label = this.props.packageSelectLabel || "Package ID";
        const showAutoComplete = !this.state.feedDoesNotSupportSearchError;
        if (this.props.packageSelectorType && this.props.packageSelectorType === PackageSelectorType.FreeText) {
            return (<>
                    <DebounceText label={this.props.packageSelectLabel} value={this.props.packageId!} onChange={this.packageChanged} validate={required("Please enter the ID of the package.")} autoFocus={false}/>
                </>);
        }
        return (<>
                {this.state.feedDoesNotSupportSearchError && (<Callout type={"information"} title="Search not supported">
                        {this.state.feedDoesNotSupportSearchError}
                        <p>Auto-complete is disabled in the field below, but this feed may still be able to be used successfully.</p>
                    </Callout>)}
                {showAutoComplete ? (<BoundAutoComplete name="PackageID" variableLookup={{
                    localNames: this.props.localNames,
                }} resetValue="" label={label} value={this.props.packageId || ""} onChange={this.packageChanged} placeholder="Enter package ID" allowAnyTextValue={true} getOptions={(searchText) => this.getPackageOptions(searchText, selectedFeed)} error={this.props.packageIdError} showEmpty={selectedFeed !== null && feedTypeCanSearchEmpty(selectedFeed.FeedType)} debounceDelay={750}/>) : (<VariableLookupText label={label} value={this.props.packageId || ""} onChange={this.packageChanged} localNames={this.props.localNames}/>)}
                <Note>
                    {selectedFeed && selectedFeed.FeedType === FeedType.Maven && (<span>
                            {" "}
                            The format of the package ID is <em>Group:Artifact</em> for example <em>com.google.guava:guava</em> or <em>junit:junit</em>.
                        </span>)}
                </Note>
                {this.state.looksLikeVersion && (<Callout type={"information"} title="Package version not required">
                        Hey there! It looks like you might be including the package version number in this field. You shouldn't include the package version number here, just the package ID.
                    </Callout>)}
            </>);
    }
    private getPackageOptions = async (searchText: string, feed: FeedResource | null) => {
        if (feed === null) {
            const items: AutoCompleteOption[] = [];
            return {
                items,
                containsAllResults: true,
            };
        }
        try {
            const packages = await repository.Feeds.searchPackages(feed, { term: searchText || "", take: 11, skip: 0 });
            return {
                items: packages.Items.slice(0, 10).map((p) => ({ Id: p.Id, Name: p.Id })),
                containsAllResults: packages.Items.length !== 11,
            };
        }
        catch (e) {
            if (e instanceof OctopusError) {
                const errors = createErrorsFromOctopusError(e);
                if (errors.statusCode === 404) {
                    this.setState({ feedDoesNotSupportSearchError: errors.message });
                    return {
                        items: [],
                        containsAllResults: true,
                    };
                }
            }
            throw e;
        }
    };
    private packageChanged = (packageId: string) => {
        this.props.onPackageIdChange(packageId);
        if (this.props.packageSelectorType && this.props.packageSelectorType !== PackageSelectorType.FreeText) {
            this.checkIfLooksLikeVersion();
        }
    };
    private packageVersionChanged = (packageVersion: string | undefined) => {
        if (!this.props.onPackageVersionChange)
            return;
        // Handle empty string
        if (_.trim(packageVersion).length === 0) {
            this.props.onPackageVersionChange(undefined);
            return;
        }
        this.props.onPackageVersionChange(packageVersion);
    };
    private feedChanged = (feed: FeedResource | string) => {
        if (typeof feed === "string") {
            this.props.onFeedIdChange(feed);
        }
        else {
            this.setState({ selectedFeed: feed });
            this.props.onFeedIdChange(feed.Id);
        }
    };
    static displayName = "PackageSelector";
}
export default PackageSelector;
