import { css } from "@emotion/css";
import type { PrimaryPageAction, SimpleMenuItem } from "@octopusdeploy/design-system-components";
import { ActionButton, ActionButtonType, SimpleMenu, useMenuState } from "@octopusdeploy/design-system-components";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import type { Repository, AccountType, PlatformHubAccountResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { RawAccountTypeDetailsMap } from "app/areas/infrastructure/InfrastructureDetails";
import { sortBy } from "lodash";
import React, { type ReactElement } from "react";
import { DropDownIcon } from "~/components/Button/DropDownIcon/DropDownIcon";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { Level2EmptyStateLayout } from "~/components/GettingStarted/Level2EmptyStateLayout";
import MarkdownDescription from "~/components/MarkdownDescription/index";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import { PageContent } from "~/components/PageContent/PageContent";
import { SimplePagingList } from "~/components/PagingList/index";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { FormSectionHeading } from "~/components/form/index";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
class PlatformHubAccountList extends SimplePagingList<PlatformHubAccountResource> {
}
interface PlatformHubAccountsLoaderData {
    accounts: PlatformHubAccountResource[];
}
interface PlatformHubAccountGroupProps {
    initialData: PlatformHubAccountsLoaderData;
    accountTypes: AccountType[];
    groupName: string;
}
const Title = "Accounts";
interface PlatformHubAccountsPageProps {
    loaderData: PlatformHubAccountsLoaderData;
}
export async function platformHubAccountsLoader(repository: Repository): Promise<PlatformHubAccountsLoaderData> {
    return {
        accounts: await repository.PlatformHubRepository.getAllAccounts(),
    };
}
export function PlatformHubAccountsPage({ loaderData }: PlatformHubAccountsPageProps) {
    const addAccountPageAction: PrimaryPageAction = {
        type: "custom",
        key: "Add Account",
        content: <AddPlatformHubAccountButton />,
        hasPermissions: isAllowed({
            permission: Permission.PlatformHubEdit,
            wildcard: true,
        }),
    };
    return loaderData.accounts.length === 0 ? (<PageContent header={{ title: Title }}>
            <Onboarding actionButtonsAndLinks={[<AddPlatformHubAccountButton />]}/>
        </PageContent>) : (<PageContent header={{ title: Title, primaryAction: addAccountPageAction }}>
            {RawAccountTypeDetailsMap.map((accountTypeDetail) => (<AccountGroup key={accountTypeDetail.name} initialData={loaderData} accountTypes={accountTypeDetail.types} groupName={accountTypeDetail.namePlural}/>))}
        </PageContent>);
}
function AddPlatformHubAccountButton() {
    const [openMenu, menuState, buttonArriaAttributes] = useMenuState();
    let rawAccountTypeDetailsMap = RawAccountTypeDetailsMap;
    if (!isFeatureToggleEnabled("GenericOidcAccountsFeatureToggle")) {
        rawAccountTypeDetailsMap = rawAccountTypeDetailsMap.filter((detail) => detail.name !== "Generic OIDC");
    }
    const addAccountItems: SimpleMenuItem[] = rawAccountTypeDetailsMap.map((accountTypeDetails) => ({
        type: "internal-link",
        label: accountTypeDetails.name,
        path: links.createPlatformHubAccountPage.generateUrl({ accountType: accountTypeDetails.types[0] }),
        onClick: () => { },
    }));
    return (<>
            <ActionButton type={ActionButtonType.Primary} icon={<DropDownIcon />} iconPosition="right" label="Add Account" onClick={openMenu} menuButtonAttributes={buttonArriaAttributes}/>
            <SimpleMenu menuState={menuState} items={addAccountItems} accessibleName={"Add account"} compact={true}/>
        </>);
}
function AccountGroup({ initialData, accountTypes, groupName }: PlatformHubAccountGroupProps) {
    const accounts = initialData.accounts.filter((account: PlatformHubAccountResource) => accountTypes.indexOf(account.AccountType) !== -1);
    if (!accounts || accounts.length === 0) {
        return null;
    }
    const sortedAccounts = sortBy(accounts, (account) => account.Name);
    const title = (<div className={accountListPageStyles.typeHeader}>
            <div className={accountListPageStyles.typeHeaderTitleContainer}>{groupName}</div>
        </div>);
    const accountOverview = (account: PlatformHubAccountResource) => (<div key={account.Id} className={accountListPageStyles.account}>
            <ListTitle>{account.Name}</ListTitle>
            <MarkdownDescription markup={account.Description}/>
        </div>);
    return (<div>
            <FormSectionHeading title={title}/>
            <div className={accountListPageStyles.typeBody}>
                <PlatformHubAccountList items={sortedAccounts} onRow={accountOverview} onRowRedirectUrl={(account: PlatformHubAccountResource) => links.editPlatformHubAccountPage.generateUrl({ accountId: account.Id })}/>
            </div>
        </div>);
}
interface PlatformHubAccountsOnboardingProps {
    actionButtonsAndLinks?: ReactElement[];
}
const Onboarding = ({ actionButtonsAndLinks }: PlatformHubAccountsOnboardingProps) => {
    const intro = (<div className={introWrapper}>
            {"Accounts help you to centralise account details used in your process templates, including things like username/password, tokens, Azure and AWS credentials and SSH key pairs."}
            <ExternalLink href="OnboardingAccountsLearnMore">Learn more</ExternalLink>
        </div>);
    return <Level2EmptyStateLayout title={"Add accounts to use in your process templates"} intro={intro} actionButtonsAndLinks={actionButtonsAndLinks}/>;
};
const introWrapper = css({
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
});
const accountListPageStyles = {
    typeName: css({
        color: themeTokens.color.text.primary,
        padding: 0,
        margin: "0 0 0.5rem 0",
    }),
    typeHeader: css({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    }),
    typeHeaderTitleContainer: css({
        whiteSpace: "nowrap",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    }),
    typeBody: css({
        visibility: "visible",
    }),
    account: css({
        p: css({
            margin: "0.5rem 0",
            color: themeTokens.color.text.secondary,
        }),
    }),
};
