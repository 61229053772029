import { NavigationButton, NavigationButtonType } from "@octopusdeploy/design-system-components";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import OnboardingPage from "~/components/GettingStarted/OnboardingPage";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { PermissionCheck } from "~/components/PermissionCheck";
export const NewFeatureText = <span>Automate routine or emergency operations-centric processes, for instance, disaster recovery and database backups.</span>;
export const RunbooksIntroText = "Runbooks can be used to automate routine or emergency operations-centric processes, for instance, disaster recovery and database backups.";
export const RunbooksWelcomeOnboarding = (props: {
    project: ProjectResource;
}) => {
    return (<OnboardingPage title="Welcome to Runbooks!" intro={RunbooksIntroText} learnMore={<ExternalLink href="OnboardingRunbooksLearnMore">Learn more</ExternalLink>} actionButtons={<PermissionCheck permission={Permission.RunbookEdit} project={props.project.Id} tenant="*">
                    <NavigationButton type={NavigationButtonType.Secondary} label="Go to Runbooks" href={links.projectRunbooksPage.generateUrl({ spaceId: props.project.SpaceId, projectSlug: props.project.Slug })}/>
                </PermissionCheck>}/>);
};
const RunbooksOnboarding = (props: {
    actionButtons: React.ReactNode;
    notification?: React.ReactNode;
}) => {
    return (<OnboardingPage title="Add your first Runbook to get started!" intro={RunbooksIntroText} learnMore={<ExternalLink href="OnboardingRunbooksLearnMore">Learn more</ExternalLink>} actionButtons={props.actionButtons} notification={props.notification}/>);
};
export default RunbooksOnboarding;
