import { UnavailableIcon } from "@octopusdeploy/design-system-icons";
import * as React from "react";
import { DegradedIcon } from "~/areas/projects/components/Observability/LiveStatusIcons/DegradedIcon";
import { HealthyIcon } from "~/areas/projects/components/Observability/LiveStatusIcons/HealthyIcon";
import { MissingIcon } from "~/areas/projects/components/Observability/LiveStatusIcons/MissingIcon";
import { OutOfSyncIcon } from "~/areas/projects/components/Observability/LiveStatusIcons/OutOfSyncIcon";
import { ProgressingIcon } from "~/areas/projects/components/Observability/LiveStatusIcons/ProgressingIcon";
import { UnknownIcon } from "~/areas/projects/components/Observability/LiveStatusIcons/UnknownIcon";
export const kubernetesStatuses = ["Degraded", "Healthy", "OutOfSync", "Progressing", "Unavailable", "Unknown", "Missing"] as const;
export type KubernetesStatus = (typeof kubernetesStatuses)[number];
export function getKubernetesStatusIcon(status: KubernetesStatus): React.ReactElement {
    switch (status) {
        case "Degraded":
            return <DegradedIcon />;
        case "Healthy":
            return <HealthyIcon />;
        case "OutOfSync":
            return <OutOfSyncIcon />;
        case "Progressing":
            return <ProgressingIcon />;
        case "Unavailable":
            return <UnavailableIcon size={24}/>;
        case "Unknown":
            return <UnknownIcon />;
        case "Missing":
            return <MissingIcon />;
        default:
            return <UnknownIcon />;
    }
}
