import type { Client, GlobalAndSpaceRootLinks, RequestContext } from "../client";
import type { GetDeploymentFreezeDetailBffResponse, CreateDeploymentFreezeCommand, ProjectResource } from "../resources";
import type { DeploymentFreezeFilterQueryParameters, DeploymentFreezeOverviewBffResponse } from "../resources/deploymentFreezeOverviewBffResponse";
import type { CreateDeploymentFreezeResponse } from "../resources/deploymentFreezes/createDeploymentFreezeResponse";
import type { GetDeploymentFreezesResponse } from "../resources/deploymentFreezes/getDeploymentFreezesResponse";
import type { GetRecurringFreezePreviewBffRequest, GetRecurringFreezePreviewBffResponse } from "../resources/deploymentFreezes/getRecurringFreezePreviewResponse";
import type { GetTenantConnectedProjectBffResponse } from "../resources/deploymentFreezes/getTenantConnectedProjectBff";
import type { ModifyDeploymentFreezeCommand } from "../resources/deploymentFreezes/modifyDeploymentFreezeCommand";
import type { ModifyDeploymentFreezeResponse } from "../resources/deploymentFreezes/modifyDeploymentFreezeResponse";
import type { SearchAvailableEnvironmentsBffResponse } from "../resources/deploymentFreezes/searchAvailableEnvironmentsBffResponse";
export class DeploymentFreezesRepository {
    protected client: Client;
    collectionLinkName: GlobalAndSpaceRootLinks = "DeploymentFreezes";
    constructor(client: Client, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    async create(createDeploymentFreezeCommand: CreateDeploymentFreezeCommand): Promise<CreateDeploymentFreezeResponse> {
        return await this.client.post<CreateDeploymentFreezeResponse>(this.requestContext, "~/api/deploymentfreezes", createDeploymentFreezeCommand);
    }
    async modify(modifyDeploymentFreezeCommand: ModifyDeploymentFreezeCommand): Promise<ModifyDeploymentFreezeResponse> {
        return await this.client.put<ModifyDeploymentFreezeResponse>(this.requestContext, `~/api/deploymentfreezes/${modifyDeploymentFreezeCommand.Id}`, modifyDeploymentFreezeCommand);
    }
    async delete(id: string) {
        return this.client.delete(this.requestContext, `~/api/deploymentfreezes/${id}`);
    }
    async getDetailBff(deploymentFreezeId: string): Promise<GetDeploymentFreezeDetailBffResponse> {
        const response = await this.client.get<GetDeploymentFreezeDetailBffResponse>(this.requestContext, `~/bff/deploymentfreezes/${deploymentFreezeId}`);
        return response;
    }
    getOverviewBff(params?: DeploymentFreezeFilterQueryParameters): Promise<DeploymentFreezeOverviewBffResponse> {
        const template = "~/bff/deploymentfreezes/overview{?pageNumber,pageSize,filterByProjects,filterByTenants,filterByEnvironments,filterByStatus,filterByName}";
        return this.client.get<DeploymentFreezeOverviewBffResponse>(this.requestContext, template, { ...params });
    }
    list(project: ProjectResource, tenantIds?: string[]): Promise<GetDeploymentFreezesResponse> {
        const link = this.client.getLink(this.collectionLinkName);
        return this.client.get(this.requestContext, link, { skip: 0, take: 1337, projectIds: [project.Id], tenantIds, includeComplete: false });
    }
    activeByProject(project: ProjectResource): Promise<GetDeploymentFreezesResponse> {
        const link = this.client.getLink(this.collectionLinkName);
        return this.client.get(this.requestContext, link, { skip: 0, take: 1337, projectIds: [project.Id], includeComplete: false, status: "Active" });
    }
    activeByTenant(tenantIds: string[]): Promise<GetDeploymentFreezesResponse> {
        const link = this.client.getLink(this.collectionLinkName);
        return this.client.get(this.requestContext, link, { skip: 0, take: 1337, tenantIds, includeComplete: false, status: "Active" });
    }
    allActive(): Promise<GetDeploymentFreezesResponse> {
        const link = this.client.getLink(this.collectionLinkName);
        return this.client.get(this.requestContext, link, { skip: 0, take: 1337, includeComplete: false, status: "Active" });
    }
    searchAvailableEnvironments(tenantProjects: {
        tenantId: string;
        projectId: string;
    }[]): Promise<SearchAvailableEnvironmentsBffResponse> {
        return this.client.post<SearchAvailableEnvironmentsBffResponse>(this.requestContext, "~/bff/deploymentfreezes/environment-search", {
            tenantProjects: tenantProjects,
        });
    }
    getTenantConnectedProjects(tenantIds: string[]): Promise<GetTenantConnectedProjectBffResponse> {
        return this.client.post<GetTenantConnectedProjectBffResponse>(this.requestContext, "~/bff/deploymentfreezes/tenant-connected-projects", {
            tenantIds: tenantIds,
        });
    }
    getRecurringFreezePreview(request: GetRecurringFreezePreviewBffRequest): Promise<GetRecurringFreezePreviewBffResponse> {
        return this.client.post<GetRecurringFreezePreviewBffResponse>(this.requestContext, "~/bff/deploymentfreezes/recurring-freeze-preview", request);
    }
}
