import { css } from "@emotion/css";
import { Button, Callout } from "@octopusdeploy/design-system-components";
import { MessagesIcon } from "@octopusdeploy/design-system-icons";
import { space } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import { useState } from "react";
import { CodeEditorFeedbackDialog } from "./CodeEditorFeedbackDialog";
import { useProcessCodeEditorState } from "./isProcessCodeEditorEnabled";
type ProcessCodeEditorCalloutProps = {
    onFeedbackSubmitted: (feedback: string) => void;
};
export function ProcessCodeEditorCallout({ onFeedbackSubmitted }: ProcessCodeEditorCalloutProps) {
    const { showCallout, calloutDismissed } = useProcessCodeEditorState();
    const [feedbackDialogOpen, setFeedbackDialogOpen] = useState<boolean>(false);
    if (!showCallout)
        return null;
    const onFeedbackClose = () => {
        setFeedbackDialogOpen(false);
    };
    const onSubmit = (feedback: string) => {
        onFeedbackSubmitted(feedback);
    };
    const styles = {
        container: css({
            marginBottom: space[16],
        }),
        content: css({
            display: "flex",
            flexDirection: "column",
            gap: space[4],
        }),
    };
    return (<div className={styles.container}>
            <Callout title={"Edit your deployment process in code"} type={"new-feature"} isExperimentalFeature={true} canClose={true} onClose={() => calloutDismissed()}>
                <div className={styles.content}>
                    <div>
                        <p>This is an experimental feature that allows you to make changes to the OCL of your deployment process directly. You can switch back to form editing at any time using the Form button above.</p>
                    </div>
                    <div>
                        <Button label="Provide Feedback" importance="neutral" leftIcon={<MessagesIcon size={24}/>} onClick={() => setFeedbackDialogOpen(true)}/>
                    </div>
                </div>
            </Callout>
            <CodeEditorFeedbackDialog open={feedbackDialogOpen} onClose={onFeedbackClose} onSubmit={onSubmit}/>
        </div>);
}
