import { css } from "@emotion/css";
import { ActionButton } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import { type GitHubAppConnections, type GitHubAppConnectionSummary, type GitHubAppRepositories, type GitHubRepository, Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React, { useCallback, useEffect, useState } from "react";
import { GitHubAppAuthCheck } from "~/areas/library/components/GitConnections/GitHubAppAuthCheck";
import { GitHubInstallationLogo } from "~/areas/library/components/GitConnections/GitHubInstallationLogo";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { hasPermission } from "~/components/PermissionCheck/PermissionCheck";
import type { SummaryNode } from "~/components/form";
import { ExpandableFormSection, Note, Summary } from "~/components/form";
import SelectWithAddRefresh from "~/components/form/SelectWithAddRefresh/SelectWithAddRefresh";
import type { DropdownMenuOption } from "~/primitiveComponents/form/Select/DropDownMenu";
import { GitConnectionOwner } from "./VersionControlSettingsConnectionDetails";
const projectPlaceholderText = "Select a GitHub repository to store the configuration for this project";
const platformHubPlaceholderText = "Select a GitHub repository to connect to the platform hub";
export interface GitHubVersionControlSettingsDetailsProps {
    spaceId: string;
    isNew: boolean;
    gitConnectionOwner: GitConnectionOwner;
    connections: GitHubAppConnections | undefined;
    refreshGitConnections: () => Promise<void>;
    getRepositoriesForConnection: (connection: GitHubAppConnectionSummary) => Promise<GitHubAppRepositories | undefined>;
    url: string | undefined;
    connectionId: string | undefined;
    setGitHubConnectionDetails: (connectionId: string | undefined, url: string | undefined) => void;
}
export function GitHubVersionControlSettingsDetails({ spaceId, isNew, connections, refreshGitConnections, getRepositoriesForConnection, url, connectionId, setGitHubConnectionDetails, gitConnectionOwner }: GitHubVersionControlSettingsDetailsProps) {
    const styles = {
        buttonContainer: css({
            display: "flex",
            alignItems: "center",
            gap: space[8],
        }),
    };
    const canViewLibraryGitCredentials = hasPermission(Permission.GitCredentialView);
    const spaceAwareNavigation = useSpaceAwareNavigation();
    const [repositories, setRepositories] = useState<GitHubAppRepositories | undefined>(undefined);
    const refreshRepositories = useCallback(async (connectionId: string | undefined, connections: GitHubAppConnectionSummary[] | undefined) => {
        setRepositories(undefined);
        if (connectionId) {
            const newSelectedConnection = connections?.find((value) => value.Id === connectionId);
            if (newSelectedConnection) {
                const repositories = await getRepositoriesForConnection(newSelectedConnection);
                setRepositories(repositories);
            }
        }
    }, [getRepositoriesForConnection, setRepositories]);
    useEffect(() => {
        refreshRepositories(connectionId, connections?.Connections);
    }, [connectionId, connections?.Connections, refreshRepositories]);
    const selectedConnection = connections?.Connections.find((value) => value.Id === connectionId);
    const selectedRepository = repositories?.Repositories.find((value) => value.GitUrl === url);
    const connectionOptions = SelectOptionsMap(connections?.Connections, (value) => value.Id, (value) => value.Installation?.AccountLogin ?? value.Id, (value) => (value.Installation ? <GitHubInstallationLogo small installation={value.Installation}/> : undefined));
    const repositoryOptions = SelectOptionsMap(repositories?.Repositories ?? [], (value) => value.RepositoryId, (value) => value.RepositoryName);
    const onConnectionChanged = async (newConnectionId: string | undefined) => {
        // If the connection hasn't changed (someone's just re-selecting the current one), we don't want to clear the repository.
        if (newConnectionId !== connectionId) {
            setGitHubConnectionDetails(newConnectionId, undefined);
        }
    };
    const onRepositoryChanged = async (newRepositoryId: string | undefined) => {
        const newRepository = repositories?.Repositories.find((value) => value.RepositoryId === newRepositoryId);
        setGitHubConnectionDetails(connectionId, newRepository?.GitUrl);
    };
    if (!canViewLibraryGitCredentials) {
        // If the user can't view library Git credentials, they can't load anything that we need
        // to fill out the dropdowns. In this case, show the URL if it's already connected, otherwise
        // show a message that they can't configure GitHub connections.
        const helpText = "You do not have permission to configure GitHub connections";
        let summary = Summary.summary(helpText);
        let contents = <Note>You do not have permission to change GitHub connections</Note>;
        if (url) {
            summary = Summary.summary(`${url}`);
            contents = (<Note>
                    Connected to repository <code>{url}</code>. You do not have permission to change GitHub connections
                </Note>);
        }
        return (<ExpandableFormSection errorKey="GitHubRepository" title="GitHub Repository" summary={summary} help={helpText} isExpandedByDefault={isNew}>
                {contents}
            </ExpandableFormSection>);
    }
    return (<>
            <GitHubAppAuthCheck actionDescription={gitConnectionOwner === GitConnectionOwner.Project ? "convert a project using a GitHub connection" : "connect a Git repository for the platform hub using a GitHub connection"}/>
            <ExpandableFormSection errorKey="GitHubRepository" title="GitHub Repository" summary={GitHubVersionControlSettingsDetailsSummary(connectionId, url, selectedConnection, selectedRepository, repositories !== undefined, gitConnectionOwner)} help={gitConnectionOwner === GitConnectionOwner.Project ? projectPlaceholderText : platformHubPlaceholderText} isExpandedByDefault={isNew}>
                {!connections || connections.Connections.length > 0 ? (<SelectWithAddRefresh label="GitHub account" items={connectionOptions} value={selectedConnection?.Id} onChange={onConnectionChanged} sortItems={false} autoFocus={true} disabled={!connections} placeholder={connections ? "Select a GitHub account" : "Loading..."} addUrl={links.gitConnectionsPage.generateUrl({ spaceId })} onRequestRefresh={refreshGitConnections} allowFilter={true} selectionRenderer={(value, menuItem) => <RenderedDropdownMenuOption item={menuItem}/>}/>) : (<div className={styles.buttonContainer}>
                        <ActionButton label="Connect to GitHub" accessibleName="Connect to GitHub" onClick={() => spaceAwareNavigation.open(links.gitConnectionsPage.generateUrl({ spaceId }))}/>
                        <IconButtonWithTooltip onClick={refreshGitConnections} toolTipContent={"Refresh"} icon={"ArrowRefreshIcon"} accessibleName="Refresh"/>
                    </div>)}
                {selectedConnection && (<SelectWithAddRefresh label="GitHub repository" items={repositoryOptions} value={selectedRepository?.RepositoryId} onChange={onRepositoryChanged} sortItems={false} disabled={!repositories} placeholder={repositories ? "Select a GitHub repository" : "Loading..."} addUrl={links.editGitHubConnectionPage.generateUrl({ spaceId, connectionId: selectedConnection.Id })} onRequestRefresh={async () => {
                refreshRepositories(connectionId, connections?.Connections);
            }} allowFilter={true}/>)}
            </ExpandableFormSection>
        </>);
}
function SelectOptionsMap<T>(items: T[] | undefined, valueSelector: (item: T) => string, textSelector: (item: T) => string, iconSelector: ((item: T) => JSX.Element | undefined) | undefined = undefined): DropdownMenuOption[] {
    if (!items) {
        return [];
    }
    return items.map((value) => {
        return {
            value: valueSelector(value),
            text: textSelector(value),
            icon: iconSelector ? iconSelector(value) : undefined,
        };
    });
}
export function GitHubVersionControlSettingsDetailsSummary(connectionId: string | undefined, url: string | undefined, connection: GitHubAppConnectionSummary | undefined, repository: GitHubRepository | undefined, repositoriesLoaded: boolean, gitConnectionOwner: GitConnectionOwner): SummaryNode {
    if (!connectionId || !url) {
        // If they haven't finished selecting an account or repository, show a placeholder prompting them to do so.
        return Summary.placeholder(gitConnectionOwner === GitConnectionOwner.Project ? platformHubPlaceholderText : platformHubPlaceholderText);
    }
    if (connection && repository) {
        if (connection.Installation) {
            const connectionText = connection.Installation.AccountLogin;
            const repositoryText = repository.RepositoryName;
            const styles = {
                container: css({
                    display: "flex",
                    flexDirection: "row",
                    gap: space[8],
                    alignItems: "center",
                }),
            };
            return Summary.summary(<span className={styles.container}>
                    <GitHubInstallationLogo small installation={connection.Installation}/> {connectionText}/{repositoryText}
                </span>);
        }
        else {
            // We have a connection, but it's missing the installation details. This is rare, but possible if we
            // have a connection that's to an installation that's been removed, and don't have the details cached.
            return Summary.summary(url);
        }
    }
    // Even if they have a connectionId and repository URL selected, it's possible that we can't load it anymore. This
    // could be because we're still loading the details or the repository has been removed from the installation.
    if (repositoriesLoaded) {
        // If we've loaded the repositories but we can't find the one we're looking for, just show the URL. This could
        // be because it's been removed from the connection or renamed. Either way, this should be enough for the user
        // to troubleshoot what's going on.
        return Summary.summary(url);
    }
    else {
        return Summary.placeholder("Loading...");
    }
}
interface RenderedDropdownMenuOptionProps {
    item: DropdownMenuOption;
}
export function RenderedDropdownMenuOption({ item }: RenderedDropdownMenuOptionProps) {
    const styles = {
        container: css({
            display: "flex",
            flexDirection: "row",
            gap: space[8],
            alignItems: "center",
        }),
    };
    return (<div className={styles.container}>
            {item.icon ?? item.icon} {item.text}
        </div>);
}
