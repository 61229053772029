import * as React from "react";
import OnboardingPage from "~/components/GettingStarted/OnboardingPage";
import ExternalLink from "~/components/Navigation/ExternalLink";
export const RunbooksIntroText = "Triggers allow you to automate your deployments by responding to events such as changes in your deployment targets or time-based schedules.";
const TriggersOnboarding = (props: {
    actionButtons: React.ReactNode;
    notification?: React.ReactNode;
}) => {
    return <OnboardingPage title="Automate your deployments with triggers" intro={RunbooksIntroText} learnMore={<ExternalLink href="ProjectTriggers">Learn more</ExternalLink>} actionButtons={props.actionButtons} notification={props.notification}/>;
};
export default TriggersOnboarding;
