import type { DeploymentTargetResource, EnvironmentResource, ProjectResource, Repository } from "@octopusdeploy/octopus-server-client";
import type { KubernetesLiveStatusResource } from "@octopusdeploy/octopus-server-client/src/resources/kubernetesLiveStatusResource";
import { isEqual } from "lodash";
import { useState } from "react";
import * as React from "react";
import type { LiveObjectStatusFilter } from "~/areas/projects/components/Observability/LiveStatusFiltering";
import { defaultFilter, filterSections } from "~/areas/projects/components/Observability/LiveStatusFiltering";
import { RefreshingLiveStatusTable } from "~/areas/projects/components/Observability/LiveStatusTable";
import type { ProjectContextProps } from "~/areas/projects/context/index";
import FeatureToggleVisibility from "~/components/FeatureToggle/New/FeatureToggleVisibility";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import { NotFound } from "~/components/NotFound/NotFound";
import { PageContent } from "~/components/PageContent/PageContent";
import { LiveStatusSummaryBar } from "./LiveStatusSummaryBar";
export async function liveStatusPageLoader(repository: Repository, environmentId: string, projectContext: Promise<ProjectContextProps>, tenantId?: string): Promise<LoaderData> {
    const { state: { model: project }, } = await projectContext;
    const kubernetesLiveStatusResources = await repository.KubernetesLiveStatus.getForApplicationInstance(project.Id, environmentId, tenantId);
    const deploymentTargets = await repository.Machines.all({ ids: kubernetesLiveStatusResources.Resources.map((r) => r.MachineId) });
    return {
        environment: await repository.Environments.get(environmentId),
        kubernetesLiveStatusResources,
        deploymentTargets,
    };
}
interface LoaderData {
    environment: EnvironmentResource;
    kubernetesLiveStatusResources: KubernetesLiveStatusResource;
    deploymentTargets: DeploymentTargetResource[];
}
type LiveStatusPageProps = {
    loaderData: LoaderData;
    project: ProjectResource;
    tenantId?: string;
};
export function LiveStatusPage(props: LiveStatusPageProps) {
    const [filter, setFilter] = useState<LiveObjectStatusFilter>(defaultFilter);
    function updateFilterContent<K extends keyof LiveObjectStatusFilter>(updates: Pick<LiveObjectStatusFilter, K>) {
        setFilter({ ...defaultFilter, ...updates });
    }
    return (<FeatureToggleVisibility toggle="KubernetesLiveObjectStatusFeatureToggle" disabledContent={<NotFound />}>
            <PageContent header={{ title: `${props.project.Name} ${props.loaderData.environment.Name} Live Status` }} fullWidth={true} filters={{
            inputs: [<FilterSearchBox placeholder={"Search by resource name..."} value={filter.name} onChange={(x) => updateFilterContent({ name: x })} autoFocus={true}/>],
            advancedFilters: {
                onResetFilter: () => setFilter(defaultFilter),
                hasUserSelectedValues: !isEqual(defaultFilter, filter),
                content: filterSections(filter, setFilter),
            },
        }}>
                <LiveStatusSummaryBar project={props.project} tenantId={props.tenantId} environmentId={props.loaderData.environment.Id}/>
                <RefreshingLiveStatusTable kubernetesLiveStatusResources={props.loaderData.kubernetesLiveStatusResources} deploymentTargets={props.loaderData.deploymentTargets} environmentId={props.loaderData.environment.Id} projectId={props.project.Id} tenantId={props.tenantId} filter={filter}/>
            </PageContent>
        </FeatureToggleVisibility>);
}
