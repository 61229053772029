/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { css, cx } from "@emotion/css";
import { Badge, IconButton, tableStyles } from "@octopusdeploy/design-system-components";
import { borderRadius, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { MachineResource, PagingCollection, ResourceCollection, TaskRestrictedTo } from "@octopusdeploy/octopus-server-client";
import { isWorkerMachine, MachineModelHealthStatus, Repository } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import type { ReactNode } from "react";
import * as React from "react";
import { orderedHealthStatuses } from "~/areas/infrastructure/InfrastructureDetails";
import { DisabledMachineIcon } from "~/areas/infrastructure/components/MachineHealthStatusIcons/DisabledMachineIcon";
import { MachineHealthStatusIcon } from "~/areas/infrastructure/components/MachineHealthStatusIcons/MachineHealthStatusIcon";
import { DisabledMachineHealthSummaryLink, MachineHealthSummaryLink } from "~/areas/infrastructure/components/MachineHealthSummaryLink/MachineHealthSummaryLink";
import type { EndpointRegistration } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import { NoResults } from "~/components/NoResults/NoResults";
import List from "~/components/PagingList";
import { Section } from "~/components/Section/Section";
import { FormSectionHeading } from "~/components/form";
import MachineHealthStatusHelper from "~/utils/MachineHealthStatusHelper";
import type { BaseInfrastructureFilter } from "../MachinesLayout/MachineFilter";
import { createMachineHealthMap, toPagingCollection } from "../MachinesLayout/MachineFilter";
import styles from "./style.module.less";
interface SmallCloseButtonProps {
    onClose?: (event: React.MouseEvent) => void;
}
export const SmallCloseButton: React.SFC<SmallCloseButtonProps> = (props) => {
    //TODO: consider using a small icon size here when it's supported
    return <IconButton onClick={props.onClose} accessibleName={"Cancel"} icon={"XmarkIcon"}/>;
};
SmallCloseButton.displayName = "SmallCloseButton"
class MachinesList extends List<MachineResource> {
}
export interface BaseAllMachinesSummaryProps<Filter> {
    showV2Page: boolean;
    filter: Filter;
}
export type HealthStatusRecord<TResourceType> = Record<keyof typeof MachineModelHealthStatus | "Disabled", PagingCollection<TResourceType>>;
export interface BaseAllMachinesSummaryState<TResourceType extends MachineResource = MachineResource> extends DataBaseComponentState {
    machinesResponse: PagingCollection<TResourceType>;
    machineHealthStatusFastLookup: HealthStatusRecord<TResourceType>;
    currentPageIndex: number; // This has a custom endpoint, so we manage our own paging implementation in List/onLoadMore.
    expanded: boolean; // Need to know if we're currently expanded so we can choose to reload when the filter changes or not.
    healthStatusFilter: string;
    isDisabledFilter: boolean | undefined;
    redirectToTaskId?: string;
    redirectToTasks: boolean;
    endpointRegistrations: EndpointRegistration[];
}
abstract class BaseAllMachinesSummary<TResourceType extends MachineResource, Props extends BaseAllMachinesSummaryProps<Filter>, Filter extends BaseInfrastructureFilter, State extends BaseAllMachinesSummaryState<TResourceType>> extends DataBaseComponent<Props, State> {
    protected machineListTakeSize = Repository.takeDefaultPageSize;
    protected commonInitialState = {
        currentPageIndex: 0,
        expanded: true,
        healthStatusFilter: "",
        isDisabledFilter: undefined,
    };
    constructor(props: Props) {
        super(props);
    }
    protected abstract loadData(): Promise<void>;
    protected abstract renderMachine(machine: TResourceType, needsUpgrading?: boolean): ReactNode;
    protected abstract renderTableHeader(): ReactNode;
    protected reloadDataAndCurrentPageIndex() {
        this.setState({ currentPageIndex: 0 }, async () => {
            await this.doBusyTask(async () => {
                await this.loadData();
            });
        });
    }
    protected async performHealthCheck(taskRestrictedTo: TaskRestrictedTo, machineIds: Set<string>) {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createHealthCheckTaskRestrictedTo(taskRestrictedTo, Array.from(machineIds.values()));
            this.setState({ redirectToTaskId: task.Id });
        });
    }
    protected async performWorkerUpgrade() {
        return this.doBusyTask(async () => {
            await repository.Machines.upgradeAllWorkers();
            this.setState({ redirectToTasks: true });
        });
    }
    protected async performTargetUpgrade() {
        return this.doBusyTask(async () => {
            await repository.Machines.upgradeAllTargets();
            this.setState({ redirectToTasks: true });
        });
    }
    protected async performCalamariUpgradeOnTargets(machineIds: string[]) {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createUpdateCalamariOnTargetsTask(machineIds);
            this.setState({ redirectToTaskId: task.Id });
        });
    }
    protected async performCalamariUpgradeOnWorkers(machineIds: string[]) {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createUpdateCalamariOnWorkersTask(machineIds);
            this.setState({ redirectToTaskId: task.Id });
        });
    }
    protected renderMachinesList(allMachines: TResourceType[], machineIdsForTentacleUpgrade: string[], machineIdsForCalamariUpgrade: string[]) {
        const componentKey = "allMachines";
        return <div key={componentKey}>{this.state.expanded && this.state.machinesResponse && <div>{this.renderMachinesListGroupedByHealthStatus(allMachines, machineIdsForTentacleUpgrade, machineIdsForCalamariUpgrade)}</div>}</div>;
    }
    protected renderMachinesListGroupedByHealthStatus(allMachines: TResourceType[], machineIdsForTentacleUpgrade: string[], machineIdsForCalamariUpgrade: string[]) {
        if (allMachines.length === 0) {
            return (<Section>
                    <NoResults />
                </Section>);
        }
        let machinesNeedUpgrading: string[] = [];
        if (machineIdsForCalamariUpgrade) {
            machinesNeedUpgrading = machinesNeedUpgrading.concat(machineIdsForCalamariUpgrade);
        }
        if (machineIdsForTentacleUpgrade) {
            machinesNeedUpgrading = machinesNeedUpgrading.concat(machineIdsForTentacleUpgrade);
        }
        if (this.props.showV2Page) {
            return this.renderV2Page(allMachines, machinesNeedUpgrading);
        }
        return (<div>
                {orderedHealthStatuses.map((status) => this.renderHealthStatusSectionHeading(status, allMachines, machinesNeedUpgrading))}
                {this.renderDisabledSectionHeading(allMachines)}
            </div>);
    }
    protected renderV2Page(allMachines: TResourceType[], machinesNeedUpgrading: string[]) {
        return (<div>
                <table className={cx(tableStyles, extraTableStyles)}>
                    {this.renderTableHeader()}
                    <tbody>
                        {orderedHealthStatuses.map((status) => this.renderV2SectionByStatus(status, machinesNeedUpgrading))}
                        {this.renderDisabledMachinesV2(allMachines.filter((m) => m.IsDisabled), machinesNeedUpgrading)}
                    </tbody>
                </table>
            </div>);
    }
    protected renderV2SectionByStatus(status: MachineModelHealthStatus, machinesNeedUpgrading: string[]) {
        const machinesForThisStatus = this.state.machineHealthStatusFastLookup[status].Items.filter((m) => !m.IsDisabled);
        const machineCount = machinesForThisStatus.length;
        return (<>
                {machineCount > 0 && (<tr className={statusRowHeadingStyles}>
                        <td colSpan={999}>
                            <div className={statusRowHeadingContainerStyles}>
                                <MachineHealthStatusIcon healthStatus={status} title={"Health status"}/> {MachineHealthStatusHelper.getFriendlyName(status)}
                                <Badge count={machineCount} size="small" variant="neutral"/>
                            </div>{" "}
                        </td>
                    </tr>)}
                {machinesForThisStatus.map((item) => this.renderMachine(item, machinesNeedUpgrading.includes(item.Id)))}
            </>);
    }
    protected renderDisabledMachinesV2(disabledMachines: TResourceType[], machinesNeedUpgrading: string[]) {
        const machineCount = disabledMachines.length;
        return (<>
                {machineCount > 0 && (<tr className={statusRowHeadingStyles}>
                        <td colSpan={999}>
                            <div className={statusRowHeadingContainerStyles}>
                                <DisabledMachineIcon /> Disabled <Badge count={machineCount} size="small" variant="neutral"/>
                            </div>{" "}
                        </td>
                    </tr>)}
                {disabledMachines.map((item) => this.renderMachine(item, machinesNeedUpgrading.includes(item.Id)))}
            </>);
    }
    protected renderHealthStatusSectionHeading(status: MachineModelHealthStatus, allMachines: TResourceType[], machineIdsTobeUpgraded: string[]): React.ReactElement | null {
        let machinesByHealthStatus = allMachines.filter((x) => x.HealthStatus === status);
        if (status === MachineModelHealthStatus.Unknown) {
            machinesByHealthStatus = machinesByHealthStatus.filter((x) => !x.IsDisabled);
        }
        const machinesTitle = (<div className={styles.healthStatusCardTitleContainer} key={status}>
                <div className={styles.healthStatusIconContainer}>
                    <MachineHealthStatusIcon healthStatus={status} title={"Health status"}/>
                </div>
                <div className={styles.healthStatusName}>{MachineHealthStatusHelper.getFriendlyName(status)}</div>
                <div className={styles.healthStatusMachinesCount}>({machinesByHealthStatus.length})</div>
            </div>);
        const componentKey = status;
        return machinesByHealthStatus.length > 0 ? (<div key={status}>
                <FormSectionHeading title={machinesTitle}/>
                <MachinesList key={componentKey} initialData={this.state.machineHealthStatusFastLookup[componentKey]} onRow={(item: TResourceType) => this.renderMachine(item, machineIdsTobeUpgraded.includes(item.Id))} onRowRedirectUrl={(machine: TResourceType) => isWorkerMachine(machine)
                ? links.workerMachineSettingsPage.generateUrl({ spaceId: machine.SpaceId, machineId: machine.Id })
                : links.deploymentTargetSettingsPage.generateUrl({
                    spaceId: machine.SpaceId,
                    machineId: machine.Id,
                })} onLoadMore={async () => {
                const newTakeSize = this.getBaseState((state) => state.machineHealthStatusFastLookup[componentKey].ItemsPerPage + this.machineListTakeSize);
                const machineHealthStatusFastLookup = this.getBaseState((state) => state.machineHealthStatusFastLookup);
                const response = this.getBaseState((state) => state.machinesResponse);
                const filteredMachines = response.Items.filter((x) => x.HealthStatus === status);
                const machinesForHealthStatus = toPagingCollection(filteredMachines, newTakeSize);
                machineHealthStatusFastLookup[componentKey] = machinesForHealthStatus;
                this.setState({
                    machineHealthStatusFastLookup,
                });
            }}/>
            </div>) : null;
    }
    protected renderDisabledSectionHeading(allMachines: TResourceType[]): React.ReactElement | null {
        const disabledMachines = allMachines.filter((x) => x.IsDisabled);
        const disabledMachinesTitle = (<div className={styles.healthStatusCardTitleContainer} key={status}>
                <div className={styles.healthStatusIconContainer}>
                    <DisabledMachineIcon title={"Health status"}/>
                </div>
                <div className={styles.healthStatusName}>Disabled</div>
                <div className={styles.healthStatusMachinesCount}>({disabledMachines.length})</div>
            </div>);
        const componentKey = "Disabled";
        return disabledMachines.length > 0 ? (<div>
                <FormSectionHeading title={disabledMachinesTitle}/>
                <List<TResourceType> key={componentKey} initialData={this.state.machineHealthStatusFastLookup[componentKey]} onRow={(item: TResourceType) => this.renderMachine(item)} onRowRedirectUrl={(machine: TResourceType) => isWorkerMachine(machine) ? links.workerMachineSettingsPage.generateUrl({ spaceId: machine.SpaceId, machineId: machine.Id }) : links.deploymentTargetSettingsPage.generateUrl({ spaceId: machine.SpaceId, machineId: machine.Id })} onLoadMore={async () => {
                const newTakeSize = this.getBaseState((state) => state.machineHealthStatusFastLookup[componentKey].ItemsPerPage + this.machineListTakeSize);
                const machineHealthStatusFastLookup = this.getBaseState((state) => state.machineHealthStatusFastLookup);
                const response = this.getBaseState((state) => state.machinesResponse);
                const filteredMachines = response.Items.filter((x) => x.IsDisabled);
                const machinesForHealthStatus = toPagingCollection(filteredMachines, newTakeSize);
                machineHealthStatusFastLookup[componentKey] = machinesForHealthStatus;
                this.setState({
                    machineHealthStatusFastLookup,
                });
            }}/>
            </div>) : null;
    }
    handleHealthSummaryLinkSelect = (e: React.MouseEvent, healthStatus: MachineModelHealthStatus) => {
        if (this.state.expanded) {
            e.preventDefault();
            e.stopPropagation(); //prevent clicking the link toggling the panel/expander.
        }
        // Clear any disabled filters when a healthStatus filter is clicked. You can't chain inline disabled and healthStatus
        // filters together because they use different and/or logic at the API and it causes UI confusion.
        this.setState({
            healthStatusFilter: healthStatus,
            isDisabledFilter: undefined,
        }, () => {
            if (this.state.expanded) {
                this.reloadDataAndCurrentPageIndex();
            }
        });
    };
    handleClearHealthSummaryFilter = () => {
        this.setState({ healthStatusFilter: "" }, () => {
            if (this.state.expanded) {
                this.reloadDataAndCurrentPageIndex();
            }
        });
    };
    protected renderMachineSummaryLinks(machines: TResourceType[], healthStatus: MachineModelHealthStatus) {
        return (<MachineHealthSummaryLink key={healthStatus} healthStatus={healthStatus} onSelect={this.handleHealthSummaryLinkSelect} onClearSelect={this.handleClearHealthSummaryFilter} getLinkText={(status, count, friendlyName) => `${count.toLocaleString()} ${friendlyName}`} count={machines.filter((machine) => !machine.IsDisabled && machine.HealthStatus === healthStatus).length} allowSelection={!(this.props.filter.healthStatuses && this.props.filter.healthStatuses.length > 0)} isSelected={this.state.healthStatusFilter === healthStatus}/>);
    }
    protected handleDisabledSummaryLinkSelect = (e: React.MouseEvent) => {
        // The user may click a disabled link to open an expander (but it shouldn't ever close it).
        if (this.state.expanded) {
            e.preventDefault();
            e.stopPropagation(); //prevent clicking the link toggling the panel/expander.
        }
        // Clear any healthStatus filters when disabled is clicked. You can't chain inline disabled and healthStatus
        // filters together because they use different and/or logic at the API and it causes UI confusion.
        this.setState({
            isDisabledFilter: true,
            healthStatusFilter: "",
        }, () => {
            if (this.state.expanded) {
                this.reloadDataAndCurrentPageIndex();
            }
        });
    };
    protected handleClearDisabledSummaryFilter = () => {
        this.setState({ isDisabledFilter: undefined }, () => {
            if (this.state.expanded) {
                this.reloadDataAndCurrentPageIndex();
            }
        });
    };
    protected renderMachineDisabledSummaryLinks(machines: TResourceType[]) {
        return (<DisabledMachineHealthSummaryLink key="Disabled" onSelect={this.handleDisabledSummaryLinkSelect} onClearSelect={this.handleClearDisabledSummaryFilter} count={machines.filter((machine) => machine.IsDisabled).length} allowSelection={!this.props.filter.isDisabled} isSelected={this.state.isDisabledFilter === true}/>);
    }
    protected setMachineResponseState(machinesResponse: ResourceCollection<TResourceType>) {
        const machineHealthStatusFastLookup = this.assembleHealthStatusFastLookup(machinesResponse);
        this.setState({ machinesResponse, machineHealthStatusFastLookup });
    }
    protected assembleHealthStatusFastLookup(machinesResponse: ResourceCollection<TResourceType>) {
        return createMachineHealthMap(machinesResponse, this.machineListTakeSize, this.state.machineHealthStatusFastLookup);
    }
    private getBaseState<T>(accessor: (state: Readonly<BaseAllMachinesSummaryState<TResourceType>>) => T) {
        return accessor(this.state as BaseAllMachinesSummaryState<TResourceType>);
    }
    static displayName = "BaseAllMachinesSummary";
}
export default BaseAllMachinesSummary;
const statusRowHeadingStyles = css({
    backgroundColor: themeTokens.color.background.secondary.default,
    color: themeTokens.color.text.secondary,
    "& td": {
        font: text.regular.bold.small,
        textTransform: "uppercase",
        verticalAlign: "middle",
    },
});
const statusRowHeadingContainerStyles = css({
    display: "flex",
    alignItems: "center",
    gap: space["8"],
});
const chipStyles = css({
    ".MuiChip-label": {
        paddingRight: space[8],
        paddingLeft: space[8],
    },
});
const chipTextStyles = css({
    fontWeight: 600,
});
const extraTableStyles = css({
    borderRadius: borderRadius.medium,
});
