/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { DeploymentStepResource, DeploymentActionResource, DeploymentActionContainer, ProjectResource, RunbookResource, ProcessResource, GitRef } from "@octopusdeploy/octopus-server-client";
import { ProcessType } from "@octopusdeploy/octopus-server-client";
import type { ReactNode } from "react";
import type { IQuery } from "~/components/QueryStringFilters/QueryStringFilters";
import type { ActionPlugin } from "../../../../components/Actions/pluginRegistry";
export interface ProcessPageSupportedActions {
    refreshLookupData: () => Promise<void>;
    refreshVariables?: () => Promise<void>;
}
export interface AssembledAction {
    step: StoredStep;
    action: StoredAction | null;
    actionTypeName: string;
    pageTitle: string;
}
export interface AssembleParentStepResult {
    step: StoredStep;
    pageTitle: string;
}
export enum EnvironmentOption {
    All = "all",
    Include = "include",
    Exclude = "exclude"
}
export enum ExecutionLocation {
    OctopusServer = "OctopusServer",
    WorkerPool = "WorkerPool",
    WorkerPoolForRoles = "WorkerPoolForRoles",
    OctopusServerForRoles = "OctopusServerForRoles",
    DeploymentTarget = "DeploymentTarget"
}
export type RunOnServerOrWorkerPool = RunOnBuiltInWorker | RunOnWorkerPool;
export type RunOn = RunOnDeploymentTarget | RunOnServerOrWorkerPool;
export class RunOnDeploymentTarget implements RunOnModel {
    executionLocation = ExecutionLocation.DeploymentTarget;
}
export class RunOnBuiltInWorker implements RunOnModel {
    executionLocation: ExecutionLocation.OctopusServer | ExecutionLocation.OctopusServerForRoles = undefined!;
    runningInContainer: boolean = undefined!;
    container: DeploymentActionContainer = undefined!;
}
export class RunOnWorkerPool implements RunOnModel {
    executionLocation: ExecutionLocation.WorkerPool | ExecutionLocation.WorkerPoolForRoles = undefined!;
    runningInContainer: boolean = undefined!;
    container: DeploymentActionContainer = undefined!;
}
export enum TargetRoles {
    Optional,
    None,
    Required
}
export interface RunOnModel {
    executionLocation: ExecutionLocation;
}
export interface ProcessFilter {
    actionId?: string;
    actionType?: string;
    reloadKey?: string;
    stepTemplates?: string;
    childStepTemplates?: string;
    templateId?: string;
    parentStepId?: string;
    blueprintSlug?: string;
    blueprintCommit?: string;
    blueprintActionId?: string;
    blueprintParentStepId?: string;
    new?: string;
    actionCategory?: string;
    tags?: string[];
    gitRef?: string;
}
export interface ProcessQuery extends IQuery, ProcessFilter {
}
export type ByIdLookup<TModel> = {
    byId: {
        [id: string]: TModel;
    };
};
export type AllIds = {
    allIds: string[];
};
export interface LookupState<T> {
    [key: string]: T;
}
export interface StoredErrors {
    steps: {
        [id: string]: {
            [key: string]: string;
        };
    };
    actions: {
        [id: string]: {
            [key: string]: string;
        };
    };
    global: {
        [key: string]: string;
    };
    globalMessage: string;
}
export type ActionError = {
    key: string;
    stepId: string;
    actionId: string;
    value: string;
};
export type StepError = {
    key: string;
    stepId: string;
    value: string;
};
export type GlobalError = {
    key: string;
    value: string;
};
export type ErrorValuePair = {
    key: string;
    value: string;
};
export type ProcessError = ActionError | StepError | GlobalError;
export type StoredStep = Omit<DeploymentStepResource, "Actions"> & {
    ActionIds: string[];
};
export interface StoredAction extends DeploymentActionResource {
    ParentId: string;
    plugin: ActionPlugin;
}
export type ProcessState = Omit<ProcessResource, "Steps"> | null;
export type StepsState = ByIdLookup<StoredStep> & AllIds;
export type ActionsState = ByIdLookup<StoredAction> & AllIds;
export type StoredModelState = {
    process: ProcessState;
    actions: ActionsState;
    steps: StepsState;
};
export type MergeModelState = {
    staged: StoredModelState;
    server: StoredModelState;
    processMerged: boolean;
    dialogClosed: boolean;
};
export type ProcessContextModelState = {
    model: StoredModelState;
    cleanModel: StoredModelState;
    mergeModel: MergeModelState;
    processIdentifier: ProcessIdentifier;
};
export type DeploymentProcessIdentifier = {
    type: ProcessType.Deployment;
    id: string;
    projectId: string;
    projectSlug: string;
    spaceId: string;
};
export type RunbookProcessIdentifier = {
    type: ProcessType.Runbook;
    id: string;
    runbookId: string;
    runbookName: string;
    projectId: string;
    projectSlug: string;
    spaceId: string;
};
export type BlueprintProcessIdentifier = {
    type: ProcessType.Blueprint;
    id: string;
    blueprintId: string;
    slug: string;
    gitRef: GitRef;
};
export type ProcessIdentifier = DeploymentProcessIdentifier | RunbookProcessIdentifier | BlueprintProcessIdentifier;
export function isDeploymentProcessIdentifier(processIdentifier: ProcessIdentifier | undefined): processIdentifier is DeploymentProcessIdentifier {
    return processIdentifier ? processIdentifier.type === ProcessType.Deployment : false;
}
export function isRunbookProcessIdentifier(processIdentifier: ProcessIdentifier | undefined): processIdentifier is RunbookProcessIdentifier {
    return processIdentifier ? processIdentifier.type === ProcessType.Runbook : false;
}
export function isDeploymentOrRunbookProcessIdentifier(processIdentifier: ProcessIdentifier | undefined): processIdentifier is DeploymentProcessIdentifier | RunbookProcessIdentifier {
    return isDeploymentProcessIdentifier(processIdentifier) || isRunbookProcessIdentifier(processIdentifier);
}
export function isBlueprintProcessIdentifier(processIdentifier: ProcessIdentifier | undefined): processIdentifier is BlueprintProcessIdentifier {
    return processIdentifier ? processIdentifier.type === ProcessType.Blueprint : false;
}
export function toDeploymentProcessIdentifier(project: ProjectResource): DeploymentProcessIdentifier {
    return {
        type: ProcessType.Deployment,
        id: project.DeploymentProcessId,
        projectId: project.Id,
        projectSlug: project.Slug,
        spaceId: project.SpaceId,
    };
}
export function toRunbookProcessIdentifier(project: ProjectResource, runbook: RunbookResource): RunbookProcessIdentifier {
    return {
        type: ProcessType.Runbook,
        id: runbook.RunbookProcessId,
        runbookId: runbook.Id,
        runbookName: runbook.Name,
        projectId: project.Id,
        projectSlug: project.Slug,
        spaceId: project.SpaceId,
    };
}
export function toBlueprintProcessIdentifier(slug: string, gitRef: GitRef): BlueprintProcessIdentifier {
    const blueprintId = `${gitRef}|${slug}`;
    return {
        type: ProcessType.Blueprint,
        id: `process-${blueprintId}`, // Unused for now
        blueprintId,
        slug,
        gitRef: gitRef,
    };
}
export type MetaStepProperties = Omit<StoredStep, "Properties" | "Actions" | "Id">;
export type MetaActionProperties = Omit<StoredAction, "Properties" | "Id" | "ActionType" | "Links">;
export type SelectorType<TTarget extends {
    [key: string]: (...args: never[]) => unknown;
}> = {
    [K in keyof TTarget]: ReturnType<TTarget[K]>;
};
//#region Warning guidance / validation
export interface Warnings {
    message?: string;
    parsedHelpLinks?: string[];
    helpText?: string;
    helpLink?: string;
    fieldWarnings: {
        [other: string]: string;
    };
    details?: {
        [key: string]: string | ReactNode | StepWarningDetails;
    };
}
export interface StoredWarnings {
    steps: {
        [id: string]: {
            [key: string]: string | ReactNode | StepWarningDetails;
        };
    };
    actions: {
        [id: string]: {
            [key: string]: string | ReactNode;
        };
    };
    global: {
        [key: string]: string | ReactNode;
    };
    globalMessage?: string;
}
export type ActionWarning = {
    key: string;
    stepId: string;
    actionId: string;
    value: string | ReactNode;
};
export type ActionWarningWithDetails = {
    key: string;
    stepId: string;
    actionId: string;
    value: StepWarningDetails;
};
export type StepWarningWithDetails = {
    key: string;
    stepId: string;
    value: StepWarningDetails;
};
export type StepWarningDetails = {
    summary: string;
    details?: string;
};
export type StepWarning = {
    key: string;
    stepId: string;
    value: string | ReactNode;
};
export type GlobalWarning = {
    key: string;
    value: string | ReactNode;
};
export type WarningValuePair = {
    key: string;
    value: string | ReactNode;
};
export type ProcessWarning = ActionWarning | StepWarning | GlobalWarning | StepWarningWithDetails | ActionWarningWithDetails;
//#endregion
