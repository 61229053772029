/* eslint-disable @typescript-eslint/no-non-null-assertion */
// import { NavigationButton, NavigationButtonType, Tooltip } from "@octopusdeploy/design-system-components";
import { Button, NavigationButtonType, type PrimaryPageAction } from "@octopusdeploy/design-system-components";
import type { ProjectResource, ResourceCollection, RunbookResource } from "@octopusdeploy/octopus-server-client";
import { canCommitTo, getGitRefDescription, HasRunbooksInGit, isGitBranchResource, Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { useRef, useState } from "react";
import { useHiddenFeatures } from "~/areas/configuration/components/FeaturesLayout/useHiddenFeatures";
import { ProjectGitHubAppAuthCheck } from "~/areas/library/components/GitConnections/GitHubAppAuthCheck";
import { SampleProjectTourContext } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTour";
import { useProjectContext } from "~/areas/projects/context";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import { DataBaseComponent, useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import Dialog from "~/components/Dialog/Dialog";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import MarkdownDescription from "~/components/MarkdownDescription";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { PageContent } from "~/components/PageContent/PageContent";
import PermissionCheck, { isAllowed, isAllowedToRunGitRunbook } from "~/components/PermissionCheck/PermissionCheck";
import { useRequiredContext } from "~/hooks/index";
import { useOctopusFeatureToggle } from "~/hooks/useOctopusFeatureToggle";
import ListItem from "~/primitiveComponents/dataDisplay/ListItem";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { PageHeaderBranchSelectorVNext } from "../BranchSelector/PageHeaderBranchSelector";
import { useProjectRunbooksGitRef, type GitRefQueryParamsProps } from "../BranchSelector/useProjectGitRef";
import AddRunbook from "./AddRunbook";
import { LoadMoreList } from "./LoadMoreList";
import { getPermissionDeniedTooltipText, RunNowButton } from "./RunNowButton";
import RunbooksOnboarding from "./RunbooksOnboarding";
import { UnmigratedDraftsCallout } from "./UnmigratedDraftsCallout";
import MigrateProjectRunbooksBanner from "./migrations/MigrateProjectRunbooksBanner";
interface FilterableRunbookCollection extends ResourceCollection<RunbookResource> {
    IsFiltered: boolean;
}
export class RunbooksPage extends DataBaseComponent<GitRefQueryParamsProps> {
    constructor(props: GitRefQueryParamsProps) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.clearErrors();
    }
    render() {
        return <RunbooksPageInternal {...this.props} doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors}/>;
    }
    static displayName = "RunbooksPage";
}
interface RunbooksPageInternalProps extends GitRefQueryParamsProps {
    doBusyTask: DoBusyTask;
    busy?: Promise<unknown>;
    errors?: Errors;
}
function RunbooksPageInternal({ doBusyTask, busy, errors, queryParams, setQueryParams }: RunbooksPageInternalProps) {
    const pageSize = 30;
    const navigation = useSpaceAwareNavigation();
    const projectContext = useProjectContext();
    const onboardingContext = useRequiredContext(SampleProjectTourContext);
    const { isOpen: isAddRunbookDialogOpen, openDialog: openAddRunbookDialog, closeDialog: closeAddRunbookDialog } = useDialogTrigger();
    const firstLoad = useRef(true);
    const [runbooks, setRunbooks] = useState<FilterableRunbookCollection>();
    const [take, setTake] = useState<number>(pageSize);
    const [filter, setFilter] = useState<string>();
    const { model: project } = projectContext.state;
    const [gitRef, setGitRef, gitRefValidationResult] = useProjectRunbooksGitRef(project, queryParams, setQueryParams);
    const hasGitRunbooks = HasRunbooksInGit(project.PersistenceSettings);
    const hiddenFeaturesEnabled = useHiddenFeatures();
    const gitRunbooksMigrationsEnabled = (useOctopusFeatureToggle("git-runbooks-migration", false) || hiddenFeaturesEnabled) && isFeatureToggleEnabled("GitRunbooksFeatureToggle");
    const reload = useDoBusyTaskEffect(doBusyTask, async () => {
        // Keep filter locally in case it changes between when the search
        // starts and when we get the results below, so we can work out if
        // there was a filter when we made the request.
        const partialName = filter;
        const hasGitRunbooks = HasRunbooksInGit(project.PersistenceSettings);
        const params = { skip: 0, take, partialName: filter };
        if (hasGitRunbooks) {
            if (gitRef) {
                const runbooks = await repository.Runbooks.getRunbooks(project, gitRef, params);
                setRunbooks({ ...runbooks, IsFiltered: !!partialName });
            }
        }
        else {
            const runbooks = await repository.Runbooks.getRunbooks(project, undefined, params);
            setRunbooks({ ...runbooks, IsFiltered: !!partialName });
        }
        if (firstLoad.current) {
            firstLoad.current = false;
            onboardingContext.continueSampleProjectTour();
        }
    }, [gitRef, take, filter], { timeOperationOptions: timeOperationOptions.forInitialLoad(project.IsVersionControlled) });
    const onFilterChanged = (value: string) => {
        setTake(pageSize); // When resetting the search, reset the take to the default
        setFilter(value);
    };
    const addRunbookPageAction: PrimaryPageAction = {
        type: "button",
        label: "Add Runbook",
        hasPermissions: isAllowed({ permission: Permission.RunbookEdit, project: project.Id, wildcard: true }),
        onClick: openAddRunbookDialog,
        disabled: hasGitRunbooks && !canCommitTo(gitRefValidationResult?.GitRef),
        extraContext: hasGitRunbooks && gitRefValidationResult?.GitRef && !canCommitTo(gitRefValidationResult?.GitRef)
            ? `Cannot create a Runbook on a ${isGitBranchResource(gitRefValidationResult?.GitRef) ? "protected branch" : getGitRefDescription(gitRef)}`
            : undefined,
    };
    return (<PageContent busy={busy} errors={errors} header={{
            title: "Runbooks",
            primaryAction: runbooks?.Items?.length !== 0 || runbooks.IsFiltered ? addRunbookPageAction : undefined,
            contextSelector: hasGitRunbooks ? <PageHeaderBranchSelectorVNext project={project} gitRef={gitRef} setGitRef={setGitRef}/> : undefined,
        }} callout={{
            type: "custom",
            content: (<>
                        {runbooks && <MigrateProjectRunbooksBanner hasNoRunbooks={runbooks.Items.length === 0 && !runbooks.IsFiltered}/>}
                        <UnmigratedDraftsCallout project={project} gitRef={gitRef}/>
                        {HasRunbooksInGit(project.PersistenceSettings) && <ProjectGitHubAppAuthCheck project={project} permission={Permission.RunbookView}/>}
                    </>),
        }}>
            <Dialog open={isAddRunbookDialogOpen}>
                <AddRunbook projectId={project.Id} onProcessCreated={async (id) => {
            navigation.navigate(links.runbookRootRedirect.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: id }));
        }}/>
            </Dialog>
            {runbooks && (<>
                    {runbooks.Items.length === 0 && !runbooks.IsFiltered ? (<RunbooksOnboarding actionButtons={<PermissionCheck permission={Permission.RunbookEdit} project={project.Id} tenant="*">
                                    <Button importance="primary" label="Add Runbook" onClick={openAddRunbookDialog} disabled={hasGitRunbooks && !canCommitTo(gitRefValidationResult?.GitRef)}/>
                                </PermissionCheck>}/>) : (<LoadMoreList loadingMore={!!busy} items={runbooks.Items} renderRow={(item) => (<RunbooksPageRow project={project} runbook={item} reload={async () => {
                        await reload();
                    }} hasGitRunbooks={HasRunbooksInGit(project.PersistenceSettings)} gitRef={gitRef}/>)} getAccessibleName={(runbook) => runbook.Name} getNavigationUrl={(runbook) => links.runbookRootRedirect.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id })} showLoadMore={runbooks.Items.length < runbooks.TotalResults} onLoadMore={() => {
                    setTake((currentTake) => currentTake + pageSize);
                }} onFilterChanged={onFilterChanged}/>)}
                </>)}
        </PageContent>);
}
interface RunbooksPageRowProps {
    project: ProjectResource;
    runbook: RunbookResource;
    reload: () => Promise<void>;
    hasGitRunbooks: boolean;
    gitRef?: string;
}
function RunbooksPageRow({ project, runbook, reload, hasGitRunbooks, gitRef }: RunbooksPageRowProps) {
    const runbookDescription = runbook.Description && <MarkdownDescription markup={runbook.Description}/>;
    const overFlowActions: Array<MenuItem | MenuItem[]> = [];
    if (!HasRunbooksInGit(project.PersistenceSettings)) {
        const CloneDialog = () => <AddRunbook projectId={project.Id} onProcessCreated={async (id) => reload()} cloneId={runbook.Id}/>;
        const clonePermission = {
            permission: Permission.RunbookEdit,
            projectGroup: project && project.ProjectGroupId,
            wildcard: true,
        };
        overFlowActions.push(OverflowMenuItems.dialogItem("Clone", <CloneDialog />, clonePermission));
    }
    overFlowActions.push([
        OverflowMenuItems.navItem("Audit Trail", links.auditPage.generateUrl({ projects: [project.Id], documentTypes: ["Runbooks"], regardingAny: [runbook.Id] }), {
            permission: Permission.EventView,
            wildcard: true,
        }),
    ]);
    const runAction = (<RunNowButton spaceId={runbook.SpaceId} isDisabled={hasGitRunbooks && !isAllowedToRunGitRunbook(project, gitRef)} tooltip={hasGitRunbooks && !isAllowedToRunGitRunbook(project, gitRef) ? getPermissionDeniedTooltipText(gitRef) : undefined} projectSlug={project.Slug} projectId={project.Id} runbookId={runbook.Id} buttonType={NavigationButtonType.Secondary}/>);
    return (<ListItem overflowMenuItems={overFlowActions} secondaryAction={runAction}>
            <ListTitle>{runbook.Name}</ListTitle>
            {runbookDescription}
        </ListItem>);
}
