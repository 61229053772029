import { css } from "@emotion/css";
import { Button, PopoverBasic } from "@octopusdeploy/design-system-components";
import { PenLineIcon } from "@octopusdeploy/design-system-icons";
import { borderRadius, colorScales, space } from "@octopusdeploy/design-system-tokens";
import type { IconMetadataResource, IconSvgResource } from "@octopusdeploy/octopus-server-client";
import React, { useState, createRef } from "react";
import IconAndLogoEditLayout from "~/areas/infrastructure/components/IconAndLogoEditLayout/IconAndLogoEditLayout";
import IconEditor from "~/components/form/IconEditor/IconEditor";
import type { LogoEditorSettings } from "~/components/form/LogoEditor/LogoEditor";
import LogoEditor from "~/components/form/LogoEditor/LogoEditor";
import { IconAndLogoPreview } from "./IconAndLogoPreview";
interface IconAndLogoSelectorPopoverProps {
    icons: IconSvgResource[];
    iconMetadata: IconMetadataResource;
    iconId: string;
    iconColor: string;
    logo: LogoEditorSettings;
    onIconIdChange: (iconId: string) => void;
    onIconColorChange: (iconColor: string) => void;
    onLogoChange: (logo: LogoEditorSettings) => void;
}
export function IconAndLogoSelectorPopover({ icons, iconMetadata, iconId, iconColor, logo, onIconIdChange, onIconColorChange, onLogoChange }: IconAndLogoSelectorPopoverProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null);
    const ref = createRef<HTMLDivElement>();
    const handleIconClick = () => {
        anchorEl ? handleClose() : handleOpen();
    };
    const handleOpen = () => {
        setAnchorEl(ref.current);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const icon = icons.find((icon) => icon.id === iconId);
    return (<>
            <div ref={ref}>
                <div onClick={handleIconClick} className={styles.projectCard}>
                    <div className={styles.projectCardHoverState}>
                        <PenLineIcon size={24}/>
                    </div>
                    <IconAndLogoPreview icon={icon} iconColor={iconColor} file={logo.file}/>
                </div>
                <PopoverBasic onClose={handleClose} anchorEl={anchorEl} open={!!anchorEl} placement="bottom-start" width={"wide"} description={<>
                            <IconAndLogoEditLayout iconEditor={<IconEditor icons={icons} iconMetadata={iconMetadata} selectedIconId={iconId} selectedIconColor={iconColor} onIconIdChange={onIconIdChange} onIconColorChange={onIconColorChange}/>} logoEditor={<LogoEditor value={logo} onChange={onLogoChange}/>}/>
                            <div className={styles.button}>
                                <Button onClick={handleClose} label={"Close"} importance={"primary"}/>
                            </div>
                        </>}/>
            </div>
        </>);
}
const styles = {
    projectCard: css({
        width: "46px",
        minWidth: "46px",
        height: "46px",
        position: "relative",
        marginRight: space["16"],
        "&>div": {
            borderRadius: borderRadius.small,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
    }),
    projectCardHoverState: css({
        color: colorScales.white,
        opacity: "0",
        zIndex: "1",
        position: "absolute",
        backgroundColor: `${colorScales.navy["900"]}CC`,
        transition: "opacity 300ms ease-out",
        "&:hover": {
            opacity: "1",
        },
    }),
    button: css({
        button: { width: space[80], display: "flex", justifyContent: "center" },
    }),
};
