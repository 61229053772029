/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { css, cx } from "@emotion/css";
import { Tooltip } from "@octopusdeploy/design-system-components";
import { NoteIcon, MessageDotsIcon, MessagePenIcon, LockOnIcon, WorkerPoolIcon, DatabaseIcon } from "@octopusdeploy/design-system-icons";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
export enum CellIcons {
    description = "description",
    multiline = "multiline",
    prompted = "prompted",
    certificate = "certificate",
    workerpool = "workerpool",
    database = "database"
}
interface VariableCellIconProps {
    type: CellIcons;
    description?: string;
    onClick?: () => void;
}
export const VariableCellIcon: React.FC<VariableCellIconProps> = (props) => {
    const icon = getIcon(props.type);
    return (<div className={props.onClick ? cx(iconContainer, clickStyles) : iconContainer} onClick={props.onClick ? props.onClick : undefined}>
            {props.description ? (<div className={CellIconStyles}>
                    <Tooltip content={props.description} display="block">
                        {icon}
                    </Tooltip>
                </div>) : (icon)}
        </div>);
};
VariableCellIcon.displayName = "VariableCellIcon"
const getIcon = (type: CellIcons) => {
    switch (type) {
        case CellIcons.description:
            return <MessageDotsIcon size={24} color={themeTokens.color.text.secondary}/>;
        case CellIcons.multiline:
            return <NoteIcon size={24} color={themeTokens.color.text.secondary}/>;
        case CellIcons.prompted:
            return <MessagePenIcon size={24} color={themeTokens.color.text.secondary}/>;
        case CellIcons.certificate:
            return <LockOnIcon size={24} color={themeTokens.color.text.secondary}/>;
        case CellIcons.workerpool:
            return <WorkerPoolIcon size={24} color={themeTokens.color.text.secondary}/>;
        case CellIcons.database:
            return <DatabaseIcon size={24} color={themeTokens.color.text.secondary}/>;
        default:
            return <MessageDotsIcon size={24} color={themeTokens.color.text.secondary}/>;
    }
};
const CellIconStyles = css({
    "&:hover svg": {
        transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        fill: themeTokens.color.text.primary,
    },
});
const clickStyles = css({
    cursor: "pointer",
});
const iconContainer = css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: "1rem",
    height: "100%",
});
export default VariableCellIcon;
