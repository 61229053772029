/* Generated file. Do not modify. Please check more details in build/generate.ts */
import * as React from "react";
import type { IconProps } from "../IconTypes";
import { IconWrapper } from "../IconWrapper";
export function UnavailableIcon(props: IconProps) {
    return (<IconWrapper {...props}>
            {<>
                    <path d="M15.556 7.78c-.612.222-1.308.106-1.769-.354-.733-.733-.554-1.966.412-2.342A16 16 0 0 1 20 4c8.837 0 16 7.164 16 16a16 16 0 0 1-1.084 5.802c-.376.965-1.609 1.144-2.342.411-.46-.46-.576-1.157-.353-1.769.504-1.386.779-2.883.779-4.444 0-7.18-5.82-13-13-13-1.56 0-3.058.275-4.444.78"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.44 6.44a1.5 1.5 0 0 1 2.12 0l25 25a1.5 1.5 0 1 1-2.12 2.12l-1.237-1.235A15.94 15.94 0 0 1 20 36c-8.837 0-16-7.163-16-16 0-3.878 1.38-7.434 3.675-10.204L6.44 8.561a1.5 1.5 0 0 1 0-2.122m3.368 5.49 18.263 18.262A12.95 12.95 0 0 1 20 33c-7.18 0-13-5.82-13-13 0-3.05 1.05-5.854 2.808-8.07"/>
                </>}
        </IconWrapper>);
}
