import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import OnboardingPage from "../../../../components/GettingStarted/OnboardingPage";
export const OnboardingTextForFeeds = "Octopus can consume packages from TeamCity, Azure DevOps, Docker Registry, MyGet, Maven, locally or hosted remotely.";
const Onboarding = (props: {
    actionButtons: React.ReactNode;
}) => {
    return (<OnboardingPage title="Add a feed to integrate with an external package repository" intro={<span>{OnboardingTextForFeeds}</span>} learnMore={<>
                    <ExternalLink href="OnboardingPackageRepositoriesLearnMore">Learn more</ExternalLink>
                </>} actionButtons={props.actionButtons}/>);
};
export default Onboarding;
