/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/init-declarations */
import { css } from "@emotion/css";
import { Divider } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens/src/generated/globals";
import { type CreateDeploymentFreezeCommand, type ProjectSummaryResource, type ScopeToEnvironmentMapping, MonthlyScheduleType, Permission, type TenantProjectEnvironment, DayOfWeek } from "@octopusdeploy/octopus-server-client";
import type { DeploymentFreezeDetailEnvironment, DeploymentFreezeDetailStatus, DeploymentFreezeScopeDetail, DeploymentFreezeTenantScopeDetail, GetDeploymentFreezeDetailBffResponse, } from "@octopusdeploy/octopus-server-client/src/resources/deploymentFreezes/getDeploymentFreezeDetailBffResponse";
import type { AnnuallyRecurringSchedule, DailyRecurringSchedule, MonthlyRecurringSchedule, WeeklyRecurringSchedule, RecurringScheduleResource } from "@octopusdeploy/octopus-server-client/src/resources/deploymentFreezes/getDeploymentFreezesResponse";
import { RecurringScheduleEndType, RecurringScheduleType } from "@octopusdeploy/octopus-server-client/src/resources/deploymentFreezes/getDeploymentFreezesResponse";
import type { ModifyDeploymentFreezeCommand } from "@octopusdeploy/octopus-server-client/src/resources/deploymentFreezes/modifyDeploymentFreezeCommand";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import { DeploymentFreezeScopeTable } from "app/areas/configuration/components/DeploymentFreezes/DeploymentFreezeScopeTable";
import _ from "lodash";
import type { Moment } from "moment";
import moment from "moment";
import pluralize from "pluralize";
import React from "react";
import { FreezeDateTimePicker } from "~/areas/configuration/components/DeploymentFreezes/FreezeDateTimePicker";
import ConnectFreezeProjectsDialog from "~/areas/configuration/components/DeploymentFreezes/ScopeConnectDialog/ConnectFreezeProjectsDialog";
import rollForward from "~/areas/projects/components/Releases/Deployments/NowOrLater/rollFoward";
import { repository } from "~/clientInstance";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import FormBaseComponent from "~/components/FormBaseComponent";
import type { FormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import { LegacyForm } from "~/components/FormPaperLayout/LegacyForm";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { NoResults } from "~/components/NoResults/NoResults";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuConverterVNext } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import type { PageContentProps } from "~/components/PageContent/PageContent";
import { PageContent } from "~/components/PageContent/PageContent";
import { CardFill } from "~/components/form/Sections/ExpandableFormSection";
import { ExpandableFormSection, ExpansionButtons, FormSectionHeading, Select, Summary, required } from "~/components/form/index";
import Text from "~/primitiveComponents/form/Text/Text";
import DateFormatter from "~/utils/DateFormatter/index";
import DayOfWeekOrdinalHelper from "~/utils/DeploymentFreezeScheduleHelper/DayOfWeekOrdinalHelper";
import DaysDescriptionHelper from "~/utils/ScheduledTriggerDescriptionHelper/DaysDescriptionHelper";
import { ScheduleFormatter } from "~/utils/ScheduledTriggerDescriptionHelper/ScheduleFormatter";
import { DeploymentFreezeScheduleDialog } from "./DeploymentFreezeScheduleDialog";
import { DeploymentFreezeTenantScopeTable } from "./DeploymentFreezeTenantScopeTable";
import { RecurringFreezePreview } from "./RecurringFreezePreview";
import ConnectFreezeTenantsDialog from "./ScopeConnectDialog/ConnectFreezeTenantsDialog";
enum DeploymentFreezeRecurrenceOptions {
    None = "none",
    Daily = "daily",
    Weekly = "weekly",
    Weekdays = "weekdays",
    Weekends = "weekends",
    DateOfMonth = "dateOfMonth",
    DayOfMonth = "dayOfMonth",
    Annually = "annually",
    Custom = "custom",
    Configured = ""
}
interface CreateDeploymentFreezeProps {
    create: true;
    deploymentFreezeId?: string;
}
interface EditDeploymentFreezeProps {
    create: false;
    deploymentFreezeId: string;
}
type DeploymentFreezeProps = CreateDeploymentFreezeProps | EditDeploymentFreezeProps;
export const EditDeploymentFreeze = (props: DeploymentFreezeProps) => {
    return <EditDeploymentFreezeInternalPage {...props}/>;
};
interface EditDeploymentFreezeState extends FormBaseComponentState<DeploymentFreezeModel> {
    isLoaded: boolean;
    hasError: boolean;
    redirectTo: LinkHref;
    currentPageIndex: number;
    projectSummaries: ProjectSummaryResource[];
    showCustomScheduleDialog: boolean;
    focussedScopeCellId: string;
}
export type DeploymentFreezeModel = FreezeDisplayProps;
interface FreezeDisplayProps {
    id: string;
    name: string;
    startDate: Moment;
    endDate: Moment;
    status: DeploymentFreezeDetailStatus;
    userTimezone: string;
    userUtcOffset: number;
    projectScope: DeploymentFreezeScopeDetail[];
    recurringSchedule?: RecurringScheduleResource;
    tenantScopes: DeploymentFreezeTenantScopeDetail[];
}
const currentTime = new Date();
const scheduledTime = rollForward(moment(currentTime), 10);
const expiryTime = rollForward(scheduledTime, 30);
const defaultFreezeModel: DeploymentFreezeModel = {
    id: "",
    name: "",
    startDate: scheduledTime,
    endDate: expiryTime,
    status: undefined,
    userTimezone: moment(currentTime).format("[GMT] Z"),
    userUtcOffset: moment(currentTime).utcOffset(),
    projectScope: [],
    tenantScopes: [],
    recurringSchedule: undefined,
};
export type EditDeploymentFreezeInternalProps = DeploymentFreezeProps;
class EditDeploymentFreezeInternalPage extends FormBaseComponent<EditDeploymentFreezeInternalProps, EditDeploymentFreezeState, DeploymentFreezeModel> {
    constructor(props: EditDeploymentFreezeInternalProps) {
        super(props);
        this.state = {
            hasError: false,
            isLoaded: false,
            model: defaultFreezeModel,
            cleanModel: defaultFreezeModel,
            redirectTo: "",
            projectSummaries: [],
            currentPageIndex: 0,
            focussedScopeCellId: "",
            showCustomScheduleDialog: false,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            let model = defaultFreezeModel;
            if (this.props.deploymentFreezeId) {
                const response = await repository.DeploymentFreezes.getDetailBff(this.props.deploymentFreezeId);
                model = this.buildModel(response);
            }
            this.setState({
                isLoaded: true,
                model,
                cleanModel: model,
            });
        }, {
            onError: (error) => {
                this.setState({
                    isLoaded: true,
                    hasError: true,
                });
            },
            onSuccess: () => { },
        });
    }
    genericValidationMessage = "Validation failed. Please check the errors messages below.";
    isDeploymentFreezeByTenantFeatureToggle = isFeatureToggleEnabled("DeploymentFreezeByTenantFeatureToggle");
    handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const { id, name, startDate, endDate, projectScope, tenantScopes, recurringSchedule } = this.state.model;
            const projectEnvironmentScopes: ScopeToEnvironmentMapping = {};
            let tenantEnvironmentScopes: TenantProjectEnvironment[] = [];
            const projectsWithoutEnvironments: string[] = [];
            const tenantsWithoutEnvironments: string[] = [];
            projectScope.forEach((detail) => {
                if (detail.Environments.length === 0) {
                    projectsWithoutEnvironments.push(detail.Name);
                }
                projectEnvironmentScopes[detail.Id] = detail.Environments.map((e) => e.Id);
            });
            tenantScopes.forEach((detail) => {
                if (detail.ProjectEnvironments.length === 0 || detail.ProjectEnvironments.some((pe) => pe.Environments.length == 0)) {
                    tenantsWithoutEnvironments.push(detail.Name);
                }
                tenantEnvironmentScopes = [...tenantEnvironmentScopes, ...detail.ProjectEnvironments.flatMap((pe) => pe.Environments.map((e) => ({ TenantId: detail.Id, ProjectId: pe.Id, EnvironmentId: e.Id })))];
            });
            if (this.isDeploymentFreezeByTenantFeatureToggle) {
                if (Object.keys(projectEnvironmentScopes).length === 0 && tenantEnvironmentScopes.length === 0) {
                    this.setValidationErrors(this.genericValidationMessage, { Scope: "Please assign at least 1 project or tenant to this freeze" });
                    return;
                }
                if (tenantsWithoutEnvironments.length > 0) {
                    const tenants = tenantsWithoutEnvironments.length < 20 ? tenantsWithoutEnvironments.join(", ") : `${tenantsWithoutEnvironments.slice(0, 19).join(", ")} and ${tenantsWithoutEnvironments.length - 20} more`;
                    this.setValidationErrors(this.genericValidationMessage, { Scope: `Please select at least 1 environment for the following ${pluralize("tenant", tenantsWithoutEnvironments.length)}: ${tenants}` });
                    return;
                }
            }
            else {
                if (Object.keys(projectEnvironmentScopes).length === 0) {
                    this.setValidationErrors(this.genericValidationMessage, { Scope: "Please assign at least 1 project to this freeze" });
                    return;
                }
            }
            if (projectsWithoutEnvironments.length > 0) {
                const projects = projectsWithoutEnvironments.length < 20 ? projectsWithoutEnvironments.join(", ") : `${projectsWithoutEnvironments.slice(0, 19).join(", ")} and ${projectsWithoutEnvironments.length - 20} more`;
                this.setValidationErrors(this.genericValidationMessage, { Scope: `Please select at least 1 environment for the following ${pluralize("project", projectsWithoutEnvironments.length)}: ${projects}` });
                return;
            }
            if (this.props.create) {
                const createCommand: CreateDeploymentFreezeCommand = {
                    Id: id,
                    Name: name,
                    Start: startDate,
                    End: endDate,
                    ProjectEnvironmentScope: projectEnvironmentScopes,
                    TenantProjectEnvironmentScope: tenantEnvironmentScopes,
                    RecurringSchedule: recurringSchedule,
                };
                const response = await repository.DeploymentFreezes.create(createCommand);
                this.setState({
                    model: defaultFreezeModel,
                    cleanModel: defaultFreezeModel,
                    redirectTo: links.deploymentFreezesEditPage.generateUrl({ deploymentFreezeId: response.Id }),
                });
            }
            else {
                const modifyCommand: ModifyDeploymentFreezeCommand = {
                    Id: id,
                    Name: name,
                    Start: startDate,
                    End: endDate,
                    ProjectEnvironmentScope: projectEnvironmentScopes,
                    TenantProjectEnvironmentScope: tenantEnvironmentScopes,
                    RecurringSchedule: recurringSchedule,
                };
                const response = await repository.DeploymentFreezes.modify(modifyCommand);
                const updatedFreeze = await repository.DeploymentFreezes.getDetailBff(response.Id);
                this.setState({
                    model: this.buildModel(updatedFreeze),
                    cleanModel: this.buildModel(updatedFreeze),
                });
            }
        });
    };
    handleDeploymentFreezeDelete = async () => {
        await this.doBusyTask(async () => {
            await repository.DeploymentFreezes.delete(this.state.model.id);
            this.setState({ redirectTo: links.deploymentFreezesListPage.generateUrl() });
        });
        return true;
    };
    buildModel(response: GetDeploymentFreezeDetailBffResponse): DeploymentFreezeModel {
        const model = {
            id: response.Id,
            name: response.Name,
            startDate: moment(response.Start),
            endDate: moment(response.End),
            status: response.Status,
            userTimezone: moment(currentTime).format("[GMT] Z"),
            userUtcOffset: moment(currentTime).utcOffset(),
            projectScope: response.Project,
            tenantScopes: response.TenantScopes,
            recurringSchedule: response.RecurringSchedule,
        };
        return model;
    }
    setProjectSummariesState(projectSummaries: ProjectSummaryResource[]) {
        this.setState({ projectSummaries });
    }
    onConnected = (model: DeploymentFreezeModel, numberOfProjectsConnected: number) => {
        this.setState({ model });
    };
    onFocusedScopeCell = (id: string) => {
        this.setState({ focussedScopeCellId: id });
    };
    onScopeCellLostFocus = () => {
        this.setState({ focussedScopeCellId: "" });
    };
    onProjectRemove = (project: DeploymentFreezeScopeDetail) => {
        const updatedScope = this.state.model.projectScope.filter((scope) => scope.Id !== project.Id);
        this.setState({ model: { ...this.state.model, projectScope: updatedScope } });
    };
    onTenantRemove = (projectEnvironmentScope: DeploymentFreezeScopeDetail, tenantId: string) => {
        const tenant = this.state.model.tenantScopes.find((scope) => scope.Id === tenantId);
        if (tenant) {
            const updatedTenant = {
                ...tenant,
                ProjectEnvironments: tenant.ProjectEnvironments.filter((scope) => scope.Id !== projectEnvironmentScope.Id),
            };
            const updatedTenantScopes = this.state.model.tenantScopes.map((scope) => (scope.Id === tenantId ? updatedTenant : scope)).filter((t) => t.ProjectEnvironments.length !== 0);
            this.setState({
                model: {
                    ...this.state.model,
                    tenantScopes: updatedTenantScopes,
                },
            });
        }
    };
    onProjectEnvironmentUpdate = (project: DeploymentFreezeScopeDetail, selectedEnvironments: DeploymentFreezeDetailEnvironment[], tenantId?: string) => {
        const updatedScope = this.state.model.projectScope.map((scope) => {
            if (scope.Id === project.Id) {
                return { ...scope, Environments: selectedEnvironments };
            }
            return scope;
        });
        this.setState({ model: { ...this.state.model, projectScope: updatedScope } });
        return Promise.resolve(true);
    };
    onTenantEnvironmentUpdate = (projectScope: DeploymentFreezeScopeDetail, selectedEnvironments: DeploymentFreezeDetailEnvironment[], tenantId?: string) => {
        const updatedScope = this.state.model.tenantScopes.map((scope) => {
            if (scope.Id === tenantId) {
                const projectEnvironments = scope.ProjectEnvironments.map((p) => {
                    if (p.Id == projectScope.Id) {
                        return { ...p, Environments: selectedEnvironments };
                    }
                    return p;
                });
                return { ...scope, ProjectEnvironments: projectEnvironments };
            }
            return scope;
        });
        this.setState({ model: { ...this.state.model, tenantScopes: updatedScope } });
        return Promise.resolve(true);
    };
    onDeploymentFreezeRecurranceUpdate = (recurrence: string | undefined) => {
        if (recurrence === DeploymentFreezeRecurrenceOptions.Custom) {
            this.setState({ showCustomScheduleDialog: true });
            return;
        }
        let recurringSchedule: RecurringScheduleResource | DailyRecurringSchedule | WeeklyRecurringSchedule | MonthlyRecurringSchedule | AnnuallyRecurringSchedule | undefined;
        switch (recurrence) {
            case "":
            case DeploymentFreezeRecurrenceOptions.None:
                recurringSchedule = undefined;
                break;
            case DeploymentFreezeRecurrenceOptions.Daily:
                recurringSchedule = {
                    Type: RecurringScheduleType.Daily,
                    Unit: 1,
                };
                break;
            case DeploymentFreezeRecurrenceOptions.Weekly:
                recurringSchedule = {
                    Type: RecurringScheduleType.Weekly,
                    Unit: 1,
                    DaysOfWeek: [this.state.model.startDate.format("dddd") as DayOfWeek],
                };
                break;
            case DeploymentFreezeRecurrenceOptions.Weekdays:
                recurringSchedule = {
                    Type: RecurringScheduleType.Weekly,
                    Unit: 1,
                    DaysOfWeek: [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday],
                };
                break;
            case DeploymentFreezeRecurrenceOptions.Weekends:
                recurringSchedule = {
                    Type: RecurringScheduleType.Weekly,
                    Unit: 1,
                    DaysOfWeek: [DayOfWeek.Saturday, DayOfWeek.Sunday],
                };
                break;
            case DeploymentFreezeRecurrenceOptions.DateOfMonth:
                recurringSchedule = {
                    Type: RecurringScheduleType.Monthly,
                    MonthlyScheduleType: MonthlyScheduleType.DateOfMonth,
                    Unit: 1,
                    DateOfMonth: this.state.model.startDate.date().toString(),
                };
                break;
            case DeploymentFreezeRecurrenceOptions.DayOfMonth:
                recurringSchedule = {
                    Type: RecurringScheduleType.Monthly,
                    MonthlyScheduleType: MonthlyScheduleType.DayOfMonth,
                    Unit: 1,
                    DayNumberOfMonth: new DayOfWeekOrdinalHelper().getDayOfWeekOrdinalDescriptor(this.state.model.startDate),
                    DayOfWeek: this.state.model.startDate.format("dddd") as DayOfWeek,
                };
                break;
            case DeploymentFreezeRecurrenceOptions.Annually:
                recurringSchedule = {
                    Type: RecurringScheduleType.Annually,
                    Unit: 1,
                };
                break;
        }
        this.setChildState1("model", { recurringSchedule: recurringSchedule });
    };
    onCustomScheduleDialogClosed = () => {
        this.setState({ showCustomScheduleDialog: false });
    };
    onRecurringScheduleUpdate = (recurringSchedule: RecurringScheduleResource | WeeklyRecurringSchedule | MonthlyRecurringSchedule) => {
        this.setChildState1("model", { recurringSchedule: recurringSchedule });
        this.onCustomScheduleDialogClosed();
    };
    getCalloutConfiguration(): PageContentProps["callout"] | undefined {
        const { startDate, endDate, status, projectScope, tenantScopes, recurringSchedule } = this.state.model;
        const currentStartDate = moment(recurringSchedule?.StartDate || startDate);
        const currentEndDate = moment(recurringSchedule?.EndDate || endDate);
        const hasTenants = tenantScopes.length > 0;
        const hasProjects = projectScope.length > 0;
        const getScopeText = () => {
            if (hasProjects && !hasTenants) {
                return "projects and environments";
            }
            return "projects, tenants and environments";
        };
        switch (status) {
            case "Active":
                return {
                    title: `This deployment freeze is currently active ${recurringSchedule ? "within a recurring schedule" : ""}`,
                    type: "success",
                    content: `Deployments to scoped ${getScopeText()} are frozen until ${currentEndDate.format("LT")} on ${currentEndDate.format("Do MMMM YYYY")}`,
                };
            case "Scheduled":
                return {
                    title: "This deployment freeze is scheduled",
                    type: "information",
                    content: `Deployments to the scoped ${getScopeText()} will be frozen from ${currentStartDate.format("LT")} on ${currentStartDate.format("Do MMMM YYYY")}`,
                };
            case "Expired":
                return {
                    title: "This deployment freeze has expired",
                    type: "warning",
                    content: `Deployments to the scoped ${getScopeText()} resumed at ${currentEndDate.format("LT")} on ${currentEndDate.format("Do MMMM YYYY")}`,
                };
            default:
                return undefined;
        }
    }
    getOverflowActions() {
        const overflowActions = [];
        if (!this.props.create) {
            overflowActions.push(OverflowMenuItems.deleteItem("Remove", `Are you sure you want to delete '${this.state.model.name}'?`, () => this.handleDeploymentFreezeDelete(), <div>
                        <p>Do you wish to continue?</p>
                    </div>, { permission: Permission.DeploymentFreezeAdminister }));
        }
        return overflowActions;
    }
    buildPickerSummary = () => Summary.summary(`This deployment freeze is scheduled to start on ${DateFormatter.dateToLongFormat(this.state.model.startDate)}`);
    buildNameSummary = () => (this.state.model.name.length > 0 ? Summary.summary(this.state.model.name) : Summary.placeholder("No deployment freeze name provided"));
    buildProjectScopeSummary = () => (this.state.model.projectScope.length > 0 ? `${pluralize("project", this.state.model.projectScope.length, true)} assigned` : "Assign projects to this deployment freeze.");
    buildTenantScopeSummary = () => (this.state.model.tenantScopes.length > 0 ? `${pluralize("tenant", this.state.model.tenantScopes.length, true)} assigned` : "Assign tenants to this deployment freeze.");
    getTitle = () => (this.props.create ? "New Deployment Freeze" : this.state.model.name);
    getRecurringScheduleOptions = () => {
        const recurringScheduleOptions = [
            { value: DeploymentFreezeRecurrenceOptions.None, text: "Does not repeat" },
            { value: DeploymentFreezeRecurrenceOptions.Daily, text: "Repeats daily" },
            { value: DeploymentFreezeRecurrenceOptions.Weekly, text: `Repeats weekly on ${this.state.model.startDate.format("dddd")}` },
            { value: DeploymentFreezeRecurrenceOptions.Weekdays, text: "Repeats on all weekdays" },
            { value: DeploymentFreezeRecurrenceOptions.Weekends, text: "Repeats on all weekends" },
            { value: DeploymentFreezeRecurrenceOptions.DateOfMonth, text: `Repeats monthly on the ${this.state.model.startDate.format("Do")}` },
            { value: DeploymentFreezeRecurrenceOptions.DayOfMonth, text: `Repeats monthly on the ${new DayOfWeekOrdinalHelper().getDayOfWeekOrdinalDescription(this.state.model.startDate)} ${this.state.model.startDate.format("dddd")}` },
            { value: DeploymentFreezeRecurrenceOptions.Annually, text: `Repeats annually on ${this.state.model.startDate.format("MMMM D")}` },
        ];
        if (this.isCustomSchedule(this.state.model.recurringSchedule)) {
            recurringScheduleOptions.push({ value: DeploymentFreezeRecurrenceOptions.Configured, text: ScheduleFormatter.buildScheduleSummary(this.state.model.recurringSchedule, this.state.model.startDate) });
        }
        recurringScheduleOptions.push({ value: DeploymentFreezeRecurrenceOptions.Custom, text: "Custom..." });
        return recurringScheduleOptions;
    };
    isCustomSchedule(schedule?: RecurringScheduleResource) {
        if (!schedule)
            return false;
        if (schedule.Unit !== 1)
            return true;
        if (schedule.EndType && schedule.EndType !== RecurringScheduleEndType.Never)
            return true;
        if (schedule.Type === RecurringScheduleType.Weekly) {
            const weeklySchedule = schedule as WeeklyRecurringSchedule;
            return !(_.isEqual(weeklySchedule.DaysOfWeek, [this.state.model.startDate.format("dddd") as DayOfWeek]) ||
                _.isEqual(weeklySchedule.DaysOfWeek, [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday]) ||
                _.isEqual(weeklySchedule.DaysOfWeek, [DayOfWeek.Saturday, DayOfWeek.Sunday]));
        }
        if (schedule.Type === RecurringScheduleType.Monthly) {
            const monthlySchedule = schedule as MonthlyRecurringSchedule;
            switch (monthlySchedule.MonthlyScheduleType) {
                case MonthlyScheduleType.DateOfMonth:
                    return monthlySchedule.DateOfMonth !== this.state.model.startDate.date().toString();
                case MonthlyScheduleType.DayOfMonth:
                    return monthlySchedule.DayNumberOfMonth !== new DayOfWeekOrdinalHelper().getDayOfWeekOrdinalDescriptor(this.state.model.startDate) && monthlySchedule.DayOfWeek !== (this.state.model.startDate.format("dddd") as DayOfWeek);
            }
            return false;
        }
        return false;
    }
    getRecurringScheduleOption(schedule?: RecurringScheduleResource) {
        if (!schedule)
            return DeploymentFreezeRecurrenceOptions.None;
        if (this.isCustomSchedule(schedule)) {
            return DeploymentFreezeRecurrenceOptions.Configured;
        }
        if (schedule?.Type === RecurringScheduleType.Daily) {
            return DeploymentFreezeRecurrenceOptions.Daily;
        }
        if (schedule?.Type === RecurringScheduleType.Weekly) {
            const daysPerWeekRecurringSchedule = schedule as WeeklyRecurringSchedule;
            if (new DaysDescriptionHelper(daysPerWeekRecurringSchedule).runsOnWeekdays()) {
                return DeploymentFreezeRecurrenceOptions.Weekdays;
            }
            if (new DaysDescriptionHelper(daysPerWeekRecurringSchedule).runsOnWeekends()) {
                return DeploymentFreezeRecurrenceOptions.Weekends;
            }
            return DeploymentFreezeRecurrenceOptions.Weekly;
        }
        if (schedule?.Type === RecurringScheduleType.Monthly) {
            const daysPerMonthRecurringSchedule = schedule as MonthlyRecurringSchedule;
            if (daysPerMonthRecurringSchedule.MonthlyScheduleType === MonthlyScheduleType.DateOfMonth) {
                return DeploymentFreezeRecurrenceOptions.DateOfMonth;
            }
            else {
                return DeploymentFreezeRecurrenceOptions.DayOfMonth;
            }
        }
        if (schedule?.Type === RecurringScheduleType.Annually) {
            return DeploymentFreezeRecurrenceOptions.Annually;
        }
        return DeploymentFreezeRecurrenceOptions.Custom;
    }
    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true}/>;
        }
        const legacyOverflowActions = this.getOverflowActions();
        const overflowMenu = OverflowMenuConverterVNext.convertAll(legacyOverflowActions);
        return (<React.Fragment>
                {this.state.isLoaded && (<LegacyForm model={this.state.model} cleanModel={this.state.cleanModel} savePermission={{ permission: Permission.DeploymentFreezeAdminister }} onSaveClick={this.handleSaveClick}>
                        {({ FormContent, createSaveAction }) => (<PageContent header={{
                        title: this.getTitle(),
                        breadcrumbs: [{ label: "Deployment Freezes", pageUrl: links.deploymentFreezesListPage.generateUrl() }],
                        primaryAction: createSaveAction({}),
                        overflowActions: overflowMenu.menuItems,
                    }} busy={this.state.busy} errors={this.errors} callout={this.getCalloutConfiguration()}>
                                {overflowMenu.dialogs}
                                {!this.state.hasError && (<FormContent>
                                        <ExpansionButtons containerKey="FreezeEdit" errors={this.errors?.fieldErrors} expandAllOnMount={this.props.create}/>
                                        <ExpandableFormSection key="name" errorKey="name" title="Name" summary={this.buildNameSummary()} help={helpText.name.help} containerKey="FreezeEdit" isExpandedByDefault={true}>
                                            <Text value={this.state.model.name} label="Deployment Freeze Name" validate={required("Please provide a name for this deployment freeze")} onChange={(value) => {
                            this.setState({ model: { ...this.state.model, name: value } });
                        }}/>
                                        </ExpandableFormSection>
                                        <ExpandableFormSection key="schedule" errorKey="schedule" title="Schedule" summary={this.buildPickerSummary()} help={helpText.schedule.help} containerKey="FreezeEdit" isExpandedByDefault={true} fillCardWidth={CardFill.FillRight}>
                                            <FreezeDateTimePicker initialStartDate={this.state.model.startDate} initialEndDate={this.state.model.endDate} updateScheduledDatesOnModel={(startDate, endDate) => this.setChildState1("model", {
                            startDate,
                            endDate,
                        })} onStartDateChange={() => this.onDeploymentFreezeRecurranceUpdate(DeploymentFreezeRecurrenceOptions.None)}/>
                                            {isFeatureToggleEnabled("RecurringDeploymentFreezesFeatureToggle") && (<>
                                                    <h4>Recurrence</h4>
                                                    <div>
                                                        <Select value={this.getRecurringScheduleOption(this.state.model.recurringSchedule)} allowClear={true} onChange={this.onDeploymentFreezeRecurranceUpdate} items={this.getRecurringScheduleOptions()} sortItems={false}/>
                                                        <DeploymentFreezeScheduleDialog show={this.state.showCustomScheduleDialog} startDate={this.state.model.startDate} schedule={this.state.model.recurringSchedule} onClosed={this.onCustomScheduleDialogClosed} onScheduleChange={this.onRecurringScheduleUpdate}/>
                                                    </div>
                                                </>)}
                                            {this.state.model.recurringSchedule && (<RecurringFreezePreview recurringSchedule={this.state.model.recurringSchedule} startDate={this.state.model.startDate} endDate={this.state.model.endDate} userTimezone={this.state.model.userTimezone}/>)}
                                        </ExpandableFormSection>
                                        <FormSectionHeading title="Project Scope" key="projectScope"/>
                                        <div>
                                            <div className={styles.connectScopesRow}>
                                                <span>{this.buildProjectScopeSummary()}</span>
                                                {this.state.model.projectScope.length === 0 && <NoResults />}
                                                <OpenDialogButton label="Assign Projects">
                                                    <ConnectFreezeProjectsDialog deploymentFreeze={this.state.model} onConnected={(freeze: DeploymentFreezeModel, numberOfProjectsConnected: number) => this.onConnected(freeze, numberOfProjectsConnected)} alreadyConnectedProjectsIds={this.state.model.projectScope.map((s) => s.Id)}/>
                                                </OpenDialogButton>
                                            </div>
                                            {this.state.model.projectScope.length > 0 && (<React.Fragment>
                                                    <Divider />
                                                    <DeploymentFreezeScopeTable key="scopeTableProject" scopeType="Project" scopeDetails={this.state.model.projectScope} onEnvironmentUpdate={this.onProjectEnvironmentUpdate} onRemove={this.onProjectRemove} doBusyTask={this.doBusyTask} focussedScopeCellId={this.state.focussedScopeCellId} onFocusedScopeCell={this.onFocusedScopeCell} onScopeCellLostFocus={this.onScopeCellLostFocus}/>
                                                </React.Fragment>)}
                                        </div>
                                        {this.isDeploymentFreezeByTenantFeatureToggle && (<div>
                                                <FormSectionHeading title="Tenant Scope" key="tenantScope"/>
                                                <div className={styles.connectScopesRow}>
                                                    <span>{this.buildTenantScopeSummary()}</span>
                                                    {this.state.model.tenantScopes.length === 0 && <NoResults />}
                                                    <OpenDialogButton label="Assign Tenants">
                                                        <ConnectFreezeTenantsDialog deploymentFreeze={this.state.model} onConnected={(freeze: DeploymentFreezeModel, numberOfTenantsConnected: number) => this.onConnected(freeze, numberOfTenantsConnected)} alreadyConnectedTenantIds={this.state.model.tenantScopes.map((t) => t.Id)}/>
                                                    </OpenDialogButton>
                                                </div>
                                                {this.state.model.tenantScopes.length > 0 && (<React.Fragment>
                                                        <Divider />
                                                        <DeploymentFreezeTenantScopeTable key="scopeTableTenant" scopeType="Tenant" scopeDetails={this.state.model.tenantScopes} onEnvironmentUpdate={this.onTenantEnvironmentUpdate} onRemove={this.onTenantRemove} doBusyTask={this.doBusyTask} focussedScopeCellId={this.state.focussedScopeCellId} onFocusedScopeCell={this.onFocusedScopeCell} onScopeCellLostFocus={this.onScopeCellLostFocus}/>
                                                    </React.Fragment>)}
                                            </div>)}
                                    </FormContent>)}
                            </PageContent>)}
                    </LegacyForm>)}
            </React.Fragment>);
    }
    static displayName = "EditDeploymentFreezeInternalPage";
}
const helpText = {
    name: {
        help: "Provide a name for this deployment freeze.",
    },
    schedule: {
        help: "Specify the schedule for this deployment freeze.",
    },
};
const styles = {
    connectScopesRow: css({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: space[16],
    }),
};
