import { css } from "@emotion/css";
import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import { FilterIcon } from "@octopusdeploy/design-system-icons";
import type { DashboardConfigurationResource } from "@octopusdeploy/octopus-server-client";
import { useState } from "react";
import * as React from "react";
import { ProjectsDashboardAdvancedFiltersDialogLayout } from "~/areas/dashboard/DashboardConfiguration/ProjectsDashboardAdvancedFiltersDialogLayout";
import { Skeleton } from "~/areas/projects/components/Projects/Skeleton";
import { CustomDialog } from "~/components/Dialog/CustomDialog";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
interface ProjectsDashboardAdvancedFiltersProps {
    onAdvancedFilterChange: () => void;
    initialDashboardConfiguration: DashboardConfigurationResource;
}
export function ProjectsDashboardAdvancedFilters({ onAdvancedFilterChange, initialDashboardConfiguration }: ProjectsDashboardAdvancedFiltersProps) {
    const { isOpen: isAdvancedFiltersDialogOpen, openDialog: openAdvancedFiltersDialog, closeDialog: closeAdvancedFiltersDialog } = useDialogTrigger();
    const [dashboardConfiguration, setDashboardConfiguration] = useState(initialDashboardConfiguration);
    const appliedFilterCount = getAppliedConfigurationFiltersCount(dashboardConfiguration);
    const handleFilterChanged = React.useCallback((dashboardConfiguration: DashboardConfigurationResource) => {
        setDashboardConfiguration(dashboardConfiguration);
        onAdvancedFilterChange();
    }, [onAdvancedFilterChange]);
    return (<>
            <ActionButton type={ActionButtonType.Ternary} label={`Advanced filters ${appliedFilterCount === 0 ? "" : ` (${appliedFilterCount})`}`} icon={<FilterIcon size={24}/>} onClick={openAdvancedFiltersDialog}/>
            <CustomDialog close={closeAdvancedFiltersDialog} open={isAdvancedFiltersDialogOpen} render={() => <ProjectsDashboardAdvancedFiltersDialogLayout dashboardConfiguration={dashboardConfiguration} onDashboardConfigurationChange={handleFilterChanged} open={isAdvancedFiltersDialogOpen} close={closeAdvancedFiltersDialog}/>}/>
        </>);
}
function getAppliedConfigurationFiltersCount(configurationData?: DashboardConfigurationResource): number {
    if (!configurationData) {
        return 0;
    }
    const filtersToCount = [configurationData.IncludedProjectIds, configurationData.IncludedProjectGroupIds, configurationData.IncludedEnvironmentIds, [...configurationData.IncludedTenantIds, ...configurationData.IncludedTenantTags]];
    return filtersToCount.reduce((prev, item) => {
        if (item.length === 0) {
            return prev;
        }
        return prev + 1;
    }, 0);
}
export function ProjectsDashboardAdvancedFiltersSkeleton() {
    return (<div className={skeletonStyles}>
            <Skeleton shape={"Rounded"} borderRadius={"small"}/>
        </div>);
}
const skeletonStyles = css({
    height: "2.25rem",
    width: "11.125rem",
});
