import { css, cx } from "@emotion/css";
import { ExternalLink } from "@octopusdeploy/design-system-components";
import { borderRadius, fontSize, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import React, { useEffect } from "react";
import rocketImageClipped from "./assets/img-rocket-clipped.svg";
import rocketImage from "./assets/img-rocket.svg";
export const FirstDeploymentBanner = () => {
    const [isChameleonAvailable, setIsChameleonAvailable] = React.useState(false);
    // observe div[data-chameleon-id="first-successful-deployment"] to determine if Chameleon is available
    useEffect(() => {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === "childList") {
                    mutation.addedNodes.forEach((node) => {
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        if (node.nodeType === Node.ELEMENT_NODE && (node as Element).getAttribute("data-chmln-style") === "card") {
                            // Chameleon is available
                            setIsChameleonAvailable(true);
                        }
                    });
                }
            });
        });
        const target = document.querySelector("div[data-chameleon-id='first-successful-deployment']")?.parentNode;
        if (target) {
            observer.observe(target, { childList: true });
        }
        return () => {
            observer.disconnect();
        };
    }, []);
    return (<div className={cx(styles.container, isChameleonAvailable ? styles.backgroundForLargeHeightBanner : styles.backgroundForSmallHeightBanner)}>
            <div className={styles.content}>
                <div className={styles.heading}>Congratulations on your first deployment!</div>
                {isChameleonAvailable && <div className={styles.subheading}>Ready to go beyond the basics? Check out these examples to see how you can start powering up your deployments.</div>}
                <div data-chameleon-id="first-successful-deployment"></div>
                <div className={styles.note}>
                    <ExternalLink href="" label="Tell us about your deployment experience" size="medium"/> and we’ll mail you a pack of laptop stickers to say thanks.
                </div>
            </div>
        </div>);
};
const styles = {
    container: css({
        background: themeTokens.color.callout.background.info.default,
        backgroundRepeat: "no-repeat",
        padding: space[16],
        borderRadius: borderRadius.medium,
        minHeight: "118px",
        display: "flex",
        flexDirection: "column",
        gap: space[4],
        margin: `${space[12]} ${space[16]} 0 ${space[16]}`,
    }),
    backgroundForLargeHeightBanner: css({
        backgroundPosition: "right bottom",
        backgroundSize: "auto",
        backgroundImage: `url(${rocketImageClipped})`,
    }),
    backgroundForSmallHeightBanner: css({
        backgroundImage: `url(${rocketImage})`,
        backgroundPosition: "right -10%",
        backgroundSize: "auto 170%",
    }),
    content: css({
        maxWidth: "87%",
    }),
    heading: css({
        font: text.heading.medium,
        fontSize: fontSize.large,
        marginBottom: `${space[2]}`,
    }),
    subheading: css({
        font: text.regular.default.large,
        fontSize: fontSize.base,
        marginBottom: `${space[12]}`,
    }),
    note: css({
        font: text.regular.default.medium,
        fontSize: fontSize.medium,
        marginTop: `${space[12]}`,
    }),
};
