import { Button } from "@octopusdeploy/design-system-components";
import type { EnvironmentResource, TenantProjectEnvironmentDetail } from "@octopusdeploy/octopus-server-client";
import React, { useState } from "react";
import { useProjectContext } from "~/areas/projects/context/index";
import { repository } from "~/clientInstance";
import CopyValueToClipboard from "~/components/CopyToClipboardButton/CopyValueToClipboard";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import EnvironmentSelect from "~/components/form/EnvironmentSelect/EnvironmentSelect";
import { Select } from "~/components/form/index";
interface ClientIdentifierDialogProps {
    doBusyTask: DoBusyTask;
    busy: Promise<void> | undefined;
    errors: Errors | undefined;
    environments: EnvironmentResource[];
}
interface ManageClientIdentifierState {
    environmentId: string | undefined;
    tenantId: string | undefined;
    clientIdentifier: string | undefined;
}
export default ({ doBusyTask, busy, errors, environments }: ClientIdentifierDialogProps) => {
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    const [state, setState] = useState<ManageClientIdentifierState>({
        environmentId: environments.length > 0 ? environments[0].Id : undefined,
        tenantId: undefined,
        clientIdentifier: undefined,
    });
    const [tenants, setTenants] = useState<TenantProjectEnvironmentDetail[]>([]);
    useDoBusyTaskEffect(doBusyTask, async () => {
        if (!state.environmentId)
            return;
        setTenants(await repository.FeatureToggles.getTenantsConnectedToProjectAndEnvironment(project, state.environmentId));
    }, []);
    const generateClientIdentifier = async () => {
        await doBusyTask(async () => {
            if (!state.environmentId)
                return;
            const result = await repository.FeatureToggles.generateClientIdentifier(project, state.environmentId, state.tenantId);
            setState((prevState) => ({ ...prevState, clientIdentifier: result.ClientIdentifier }));
        });
    };
    return (<OkDialogLayout title={"Preview Client Identifiers"} busy={busy} errors={errors} onOkClick={() => { }} hideCancel={true}>
            <React.Fragment>
                <>
                    <p>Client identifiers allow your software to access Octopus Feature Toggles.</p>
                    <p>
                        Octopus provides the <b>Octopus.FeatureToggles.ClientIdentifier</b> deployment variable for you to merge into your application configuration.
                    </p>
                    <p>If you wish to use Octopus Feature Toggles in applications not deployed by Octopus, or wish to otherwise provide the client identifier to your client software, you can generate an appropriate identifier below.</p>
                    <EnvironmentSelect label={"Environment"} environments={environments} value={state.environmentId} onChange={async (selected) => {
            setState((prevState) => ({ ...prevState, environmentId: selected }));
            if (selected) {
                setTenants(await repository.FeatureToggles.getTenantsConnectedToProjectAndEnvironment(project, selected));
            }
        }}/>
                    {tenants.length > 0 && (<Select label={"Tenant"} sortItems={false} value={state.tenantId} items={tenants.map((e) => ({ value: e.TenantId, text: e.Name }))} onChange={(selected) => {
                setState((prevState) => ({ ...prevState, tenantId: selected }));
            }} allowClear={true}/>)}
                    <Button label={"Preview"} onClick={() => generateClientIdentifier()} importance="primary" disabled={!state.environmentId}/>
                    {state.clientIdentifier && (<div>
                            <p>
                                Your client identifier for <b>{environments.find((x) => x.Id === state.environmentId)?.Name}</b>
                                {state.tenantId ? (<>
                                        {" "}
                                        and <b>{tenants.find((x) => x.TenantId === state.tenantId)?.Name}</b>
                                    </>) : null}{" "}
                                is:
                            </p>
                            <CopyValueToClipboard value={state.clientIdentifier}>
                                <p style={{ maxWidth: 540, wordWrap: "break-word" }}>{state.clientIdentifier}</p>
                            </CopyValueToClipboard>
                            <ExternalLink href={"https://oc.to/UsingClientIdentifiers"}>Using Feature Toggle Client Identifiers</ExternalLink>.
                        </div>)}
                </>
            </React.Fragment>
        </OkDialogLayout>);
};
