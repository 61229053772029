/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { ActionButtonType, RadioButton, RadioButtonGroup, Tooltip } from "@octopusdeploy/design-system-components";
import { Repository, PackageReferenceNamesMatch, Permission, HasGitPersistenceSettings, getGitRefType, IsDefaultBranch, toGitBranch, toGitRefShort, getGitRefDescription } from "@octopusdeploy/octopus-server-client";
import type { ResourcesById, ChannelResource, DeploymentProcessResource, FeedResource, FeedType, ProjectResource, ReleaseResource, ReleaseTemplateResource, ResourceCollection, IHaveGitReference, GitRef, PackageVersionResource, GitReferenceSatisfiesChannelGitReferenceRulesResponse, } from "@octopusdeploy/octopus-server-client";
import { links, optionalStringQueryParam, useQueryStringParams } from "@octopusdeploy/portal-routes";
import cn from "classnames";
import type { Dictionary } from "lodash";
import * as _ from "lodash";
import { cloneDeep, flatten, groupBy, isEqual, keyBy, keys, uniq } from "lodash";
import * as React from "react";
import type { ActionEvent, AnalyticErrorCallback, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { Action, useProjectScopedAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import { GitResourcesSection } from "~/areas/projects/components/Releases/Edit/GitResourcesSection";
import type { GitReferenceEditInfo } from "~/areas/projects/components/Releases/gitResourceModel";
import { GitReferenceType } from "~/areas/projects/components/Releases/gitResourceModel";
import type { PackageEditInfo } from "~/areas/projects/components/Releases/packageModel";
import { VersionType } from "~/areas/projects/components/Releases/packageModel";
import { useProjectContext } from "~/areas/projects/context";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context/withProjectContext";
import { repository } from "~/clientInstance";
import type { GlobalDispatchControlExpandersProps } from "~/components/ControlExpanders/ControlExpanders";
import { useSetExpanderState } from "~/components/ControlExpanders/useSetExpanderState";
import DebounceValue from "~/components/DebounceValue/DebounceValue";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import { default as FormBaseComponent } from "~/components/FormBaseComponent/FormBaseComponent";
import { LegacyForm } from "~/components/FormPaperLayout/LegacyForm";
import LoadMoreWrapper from "~/components/LoadMoreWrapper/LoadMoreWrapper";
import MoreInfo from "~/components/MoreInfo/MoreInfo";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuConverterVNext } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import { PageContent } from "~/components/PageContent/PageContent";
import { withTheme } from "~/components/Theme";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import WarningIcon from "~/components/WarningIcon/index";
import { ExpandableFormSection, Note, Summary } from "~/components/form";
import isBound from "~/components/form/BoundField/isBound";
import MarkdownEditor from "~/components/form/MarkdownEditor/MarkdownEditor";
import { CardFill } from "~/components/form/Sections/ExpandableFormSection";
import type { SummaryNode } from "~/components/form/Sections/ExpandableFormSection";
import { required } from "~/components/form/Validators";
import { useOctopusFeatureToggle } from "~/hooks/useOctopusFeatureToggle";
import { DataTable, DataTableBody, DataTableHeader, DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import type { DataTableRowProps } from "~/primitiveComponents/dataDisplay/DataTable/DataTableRow";
import Select from "~/primitiveComponents/form/Select/Select";
import Text from "~/primitiveComponents/form/Text/Text";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { GitRefChip } from "../GitRefChip/GitRefChip";
import MissingProcessStepsMessage from "../MissingProcessStepsMessage";
import PackageListDialogContent from "../PackageListDialog/PackageListDialogContent";
import { ChannelRulesNotSatisfiedCallout } from "./ChannelRulesNotSatisfiedCallout";
import GitRefFormSection from "./GitRefFormSection";
import VersionNumberInfo from "./VersionNumberInfo";
import styles from "./style.module.less";
const versionExpanderKey = "version";
interface ReleaseModel {
    packages: PackageEditInfo[];
    channels: ResourceCollection<ChannelResource>;
    release: ReleaseResource;
    gitResources: GitReferenceEditInfo[];
}
interface ReleaseState extends OptionalFormBaseComponentState<ReleaseModel> {
    project: ProjectResource;
    originalVersion: string;
    originalChannelId: string;
    deploymentProc: DeploymentProcessResource;
    template: ReleaseTemplateResource;
    violatedPackages: string[];
    seeVersionExample: boolean;
    isNew: boolean;
    redirect: boolean;
    deleted: boolean;
    defaultCheckModel: ReleaseModel;
    feeds: ResourcesById<FeedResource>;
    hasInitialModelUpdateCompleted: boolean; // To stop the user being able to interact with the release version input before we've finished loading version rules.
    busyTaskIsRunning: boolean;
    checkingDeploymentProcess: boolean;
    missingPackages: string[];
    gitReferenceRuleValidation?: GitReferenceSatisfiesChannelGitReferenceRulesResponse;
    actionsThatViolateGitResourceRules: Set<string>;
}
const DebounceText = DebounceValue(Text);
export type channelFilters = {
    versionRange?: string;
    preReleaseTag?: string;
};
interface CreateOrEditReleasePageInternalProps extends CreateOrEditReleasePageProps, GlobalDispatchControlExpandersProps, WithProjectContextInjectedProps {
    trackAction: AnalyticTrackedActionDispatcher;
    fixedPackageVersionEnabled: boolean;
    channelId?: string;
}
interface PackageDataTableRowProps extends DataTableRowProps {
    accessibleName: string;
}
class PackageDataTableRow extends React.Component<PackageDataTableRowProps> {
    render() {
        const { accessibleName, ...otherProps } = this.props;
        const accessibilityProps = {
            role: "radiogroup",
            "aria-label": accessibleName,
        };
        return (<DataTableRow {...otherProps} {...accessibilityProps}>
                {this.props.children}
            </DataTableRow>);
    }
    static displayName = "PackageDataTableRow";
}
class CreateOrEditReleasePageInternal extends FormBaseComponent<CreateOrEditReleasePageInternalProps, ReleaseState, ReleaseModel> {
    memoizedRepositoryChannelsRuleTest = _.memoize((version: string, versionRange: string, preReleaseTag: string, feedType: FeedType) => repository.Channels.ruleTest({
        version,
        versionRange,
        preReleaseTag,
        feedType,
    }), (...args) => args.join("_"));
    constructor(props: CreateOrEditReleasePageInternalProps) {
        super(props);
        this.state = {
            project: null!,
            originalVersion: null!,
            originalChannelId: null!,
            deploymentProc: null!,
            template: null!,
            violatedPackages: [],
            seeVersionExample: false,
            isNew: true,
            redirect: false,
            deleted: false,
            defaultCheckModel: null!,
            feeds: {},
            hasInitialModelUpdateCompleted: false,
            busyTaskIsRunning: false,
            checkingDeploymentProcess: false,
            missingPackages: [],
            gitReferenceRuleValidation: undefined,
            actionsThatViolateGitResourceRules: new Set<string>(),
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const { model: project, projectContextRepository, gitRef } = this.props.projectContext.state;
            const deploymentSettings = await repository.ProjectDeploymentSettings.get(project.Id, gitRef?.CanonicalName);
            const channels = await repository.Projects.getChannels(project);
            let release = null;
            let originalVersion: string = null!;
            let originalChannelId: string = null!;
            let deploymentProcPromise = null;
            let isNew = true;
            let cleanModel: any = null!;
            if (this.props.releaseVersion) {
                release = await repository.Projects.getReleaseByVersion(project, this.props.releaseVersion);
                originalVersion = release.Version;
                originalChannelId = release.ChannelId;
                isNew = false;
                deploymentProcPromise = projectContextRepository.DeploymentProcesses.getForRelease(release);
            }
            else {
                const defaultChannel = channels.Items.find((x) => x.IsDefault);
                release = {
                    ...this.defaultGitProperties(),
                    ProjectId: project.Id,
                    ChannelId: this.props.channelId ? this.props.channelId : defaultChannel ? defaultChannel.Id : channels.Items[0].Id,
                };
                deploymentProcPromise = this.props.projectContext.state.projectContextRepository.DeploymentProcesses.get();
                cleanModel = {
                    channelId: null,
                    version: null,
                    packages: [],
                    gitResources: [],
                    releaseNotes: null,
                    channels: [],
                    release: null,
                    options: null,
                };
            }
            let gitReferenceRuleValidation: GitReferenceSatisfiesChannelGitReferenceRulesResponse | undefined = undefined;
            if (this.shouldValidateGitRefRule(release.VersionControlReference?.GitRef)) {
                gitReferenceRuleValidation = await repository.Channels.gitReferenceRuleValidation(release.ProjectId, release.ChannelId, release.VersionControlReference?.GitRef);
            }
            const model: ReleaseModel = { release: release as ReleaseResource, packages: [], gitResources: [], channels };
            const deploymentProc = await deploymentProcPromise;
            if (isNew) {
                model.release.ReleaseNotes = deploymentSettings.ReleaseNotesTemplate || null!;
            }
            await this.loadTemplate(model, deploymentProc, isNew, project.Id);
            this.setState({
                project,
                originalVersion,
                originalChannelId,
                deploymentProc,
                model,
                cleanModel: cleanModel ? cleanModel : cloneDeep(model),
                defaultCheckModel: cloneDeep(model),
                isNew,
                hasInitialModelUpdateCompleted: true,
                gitReferenceRuleValidation,
            });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    private shouldValidateGitRefRule(gitRef: GitRef | undefined) {
        return gitRef !== null && gitRef !== undefined;
    }
    private shouldValidateGitResourceRules(gitResources: GitReferenceEditInfo[] | undefined) {
        return gitResources !== null && gitResources !== undefined && gitResources.length > 0;
    }
    render() {
        const project = this.props.projectContext.state.model;
        const channel = this.state.model?.channels.Items.find((c) => c.Id === this.state.model!.release.ChannelId);
        if (this.state.redirect) {
            return <InternalRedirect to={links.releasePage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, releaseVersion: this.state.model!.release.Version })} push={true}/>;
        }
        if (this.state.deleted) {
            return <InternalRedirect to={links.releasesPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug })} push={true}/>;
        }
        const legacyOverflowActions = !this.state.isNew && !!this.state.model && !!this.state.model.release
            ? [
                OverflowMenuItems.deleteItemDefault("release", this.handleDeleteConfirm, {
                    permission: Permission.ReleaseDelete,
                    project: this.state.project && this.state.project.Id,
                    tenant: "*",
                }, "The release and any of its deployments will be permanently deleted and they will disappear from all dashboards."),
                [
                    OverflowMenuItems.navItem("Audit Trail", links.auditPage.generateUrl({ regardingAny: [this.state.model.release.Id] }), {
                        permission: Permission.EventView,
                        wildcard: true,
                    }),
                ],
            ]
            : [];
        const overflowMenu = OverflowMenuConverterVNext.convertAll(legacyOverflowActions);
        let title = "Release";
        if (this.state.project) {
            title = this.state.isNew ? "Create release for " + this.state.project.Name : this.state.model && this.state.model.release ? "Edit release " + this.state.model.release.Version : "Edit release";
        }
        const hasUnresolvableGitResources = (this.state.model && this.state.model.gitResources && this.state.model.gitResources.some((r) => !r.IsResolvable)) ?? false;
        const violatesGitReferenceRules = this.state.gitReferenceRuleValidation ? !this.state.gitReferenceRuleValidation.SatisfiesGitReferenceRules : false;
        const violatesPackageRules = this.state.violatedPackages && this.state.violatedPackages.length > 0;
        const projectAllowsIgnoringChannelRules = this.state.project && this.state.project.AllowIgnoreChannelRules;
        const violatesGitResourceRules = this.state.actionsThatViolateGitResourceRules.size > 0;
        const violatesChannelRules = violatesGitReferenceRules || violatesPackageRules || violatesGitResourceRules;
        const cannotSaveBecauseOfChannelRules = violatesChannelRules && (!projectAllowsIgnoringChannelRules || this.state.model?.release.IgnoreChannelRules !== true);
        return withTheme((theme) => (<LegacyForm model={this.state.model} cleanModel={this.state.cleanModel} disableDirtyFormChecking={this.state.isNew && (this.disableDirtyFormCheck() || !this.deploymentProcessHasSteps(this.state.deploymentProc))} savePermission={{
                permission: this.state.isNew ? Permission.ReleaseCreate : Permission.ReleaseEdit,
                project: this.state.project && this.state.project.Id,
                tenant: "*",
            }} onSaveClick={this.handleSaveClick} saveText="Release saved" forceDisableFormSaveButton={!this.deploymentProcessHasSteps(this.state.deploymentProc) || this.state.busyTaskIsRunning || hasUnresolvableGitResources || cannotSaveBecauseOfChannelRules}>
                {({ FormContent, createSaveAction }) => (<PageContent header={{
                    title,
                    primaryAction: createSaveAction({}),
                    breadcrumbs: [
                        { label: "Releases", pageUrl: this.state.project ? links.releasesPage.generateUrl({ spaceId: this.props.projectContext.state.model.SpaceId, projectSlug: this.props.projectContext.state.model.Slug }) : null },
                    ],
                    overflowActions: overflowMenu.menuItems,
                }} busy={this.state.busy} errors={this.errors} callout={this.state.originalChannelId && this.state.originalChannelId !== this.state.model!.release.ChannelId
                    ? {
                        type: "danger",
                        content: (<>
                                              <p>Changing the channel of this release is only allowed where all the steps previously applicable (with respect to any channel filters) will still be applicable with the new channel.</p>
                                              <p>Please make sure you are aware which steps are now active as you may be unable to reverse this change.</p>
                                          </>),
                    }
                    : undefined}>
                        <FormContent>
                            {overflowMenu.dialogs}
                            {this.state.model && this.state.hasInitialModelUpdateCompleted && (<TransitionAnimation>
                                    {channel && (<ChannelRulesNotSatisfiedCallout project={this.state.project} release={this.state.model.release} channel={channel} gitReferenceRuleValidation={this.state.gitReferenceRuleValidation} actionsWithPackageVersionRuleViolations={this.state.violatedPackages} actionsThatViolateGitResourceRules={this.state.actionsThatViolateGitResourceRules} onIgnoreChannelRulesChange={(ignoreChannelRules) => {
                            this.setChildState2("model", "release", { IgnoreChannelRules: ignoreChannelRules });
                        }}/>)}

                                    {!this.deploymentProcessHasSteps(this.state.deploymentProc) ? (!this.state.checkingDeploymentProcess ? (<MissingProcessStepsMessage project={this.state.project} {...(this.state.project?.IsVersionControlled ? { branchName: this.state.model && this.state.model.release.VersionControlReference?.GitRef } : {})}/>) : ("")) : ("")}
                                    {this.state.project && this.state.project.IsVersionControlled && (this.state.isNew || !!this.state.model.release.VersionControlReference?.GitRef) && (<>
                                            <ExpandableFormSection errorKey="gitRef" title="Git Reference" summary={this.gitRefSummary()} help={this.gitRefHelp()} chip={violatesGitReferenceRules ? (<Tooltip content={<>
                                                                    Selected {getGitRefDescription(this.state.model.release.VersionControlReference?.GitRef)}
                                                                    <strong>{toGitRefShort(this.state.model.release.VersionControlReference?.GitRef)}</strong> does not satisfy Git reference rules
                                                                </>}>
                                                            <WarningIcon />
                                                        </Tooltip>) : undefined}>
                                                <GitRefFormSection value={this.state.model.release.VersionControlReference!.GitRef} onChange={this.onGitRefChange} canResetToDefaultBranch={true} disabled={!this.state.isNew}/>
                                            </ExpandableFormSection>
                                        </>)}
                                    {this.state.model.channels && this.state.model.channels.Items && this.state.model.channels.Items.length > 1 && (<ExpandableFormSection errorKey="channel" title="Channel" focusOnExpandAll summary={this.state.model.release.ChannelId ? Summary.summary(this.state.model.channels.Items.find((x) => x.Id === this.state.model!.release.ChannelId)!.Name) : Summary.placeholder("Please select a channel")} help={this.state.model.channels.Items.length > 1 ? "Select a channel for this release" : this.state.model.channels.Items[0].Name}>
                                            {this.state.model.channels && this.state.model.channels.Items.length > 1 && (<Select value={this.state.model.release.ChannelId} onChange={async (channelId) => this.onChannelChanged(channelId!)} items={this.state.model.channels.Items.map((c) => ({
                                text: c.Name,
                                value: c.Id,
                            }))} label="Channel" disabled={this.state.busyTaskIsRunning}/>)}
                                        </ExpandableFormSection>)}
                                    <ExpandableFormSection errorKey={versionExpanderKey} title="Version" summary={this.state.model.release.Version ? Summary.summary(this.state.model.release.Version) : Summary.placeholder("Please enter a version")} help="Enter a unique version number for this release with at least two parts.">
                                        <Text value={this.state.model.release.Version} onChange={(version) => this.setChildState2("model", "release", { Version: version })} label="Version" validate={required("Please enter a version number")}/>
                                        {this.state.project && this.state.template && this.state.template.LastReleaseVersion && !this.state.originalVersion && (<div>
                                                Most recent release:{" "}
                                                <InternalLink to={links.releasePage.generateUrl({
                            spaceId: this.props.projectContext.state.model.SpaceId,
                            projectSlug: this.props.projectContext.state.model.Slug,
                            releaseVersion: this.state.template.LastReleaseVersion,
                        })}>
                                                    {this.state.template.LastReleaseVersion}
                                                </InternalLink>
                                            </div>)}
                                        <MoreInfo>
                                            <VersionNumberInfo />
                                        </MoreInfo>
                                    </ExpandableFormSection>

                                    {/* TODO: Pull PackageSelector into its own component */}
                                    {this.state.model.packages && this.state.model.packages.length > 0 && (<ExpandableFormSection errorKey="packages" title="Packages" fillCardWidth={CardFill.FillAll} summary={this.packagesSummary()} help="Select package(s) for this release" chip={violatesPackageRules ? (<Tooltip content={<>
                                                                Selected package versions for <strong>{this.state.violatedPackages.join(", ")}</strong> do not satisfy version rules
                                                            </>}>
                                                        <WarningIcon />
                                                    </Tooltip>) : undefined}>
                                            <div className={styles.packageTableContainer}>
                                                <DataTable className={styles.packageTable}>
                                                    <DataTableHeader>
                                                        <DataTableRow>
                                                            <DataTableHeaderColumn>
                                                                <div className={styles.actionName}>Step</div>
                                                                Package
                                                            </DataTableHeaderColumn>
                                                            <DataTableHeaderColumn>
                                                                <Tooltip key="latest" content="The most recent package that we could find in the package feed that matches channel rules">
                                                                    <ExternalLink href="LatestPackage">Latest</ExternalLink>
                                                                    {this.state.model.packages && this.state.model.packages.length > 1 && (<React.Fragment>
                                                                            <br />
                                                                            <Note>
                                                                                <a href="#" onClick={(e) => this.setAllPackageVersionsTo(e, VersionType.latest, null!, false)}>
                                                                                    Select all
                                                                                </a>
                                                                            </Note>
                                                                        </React.Fragment>)}
                                                                </Tooltip>
                                                            </DataTableHeaderColumn>
                                                            {this.state.template && this.state.template.LastReleaseVersion && !this.state.originalVersion && (<DataTableHeaderColumn>
                                                                    <Tooltip key="last" content={"The version selected for release " + this.state.template.LastReleaseVersion}>
                                                                        Last
                                                                    </Tooltip>
                                                                    {this.state.model.packages && this.state.model.packages.length > 1 && (<React.Fragment>
                                                                            <br />
                                                                            <Note>
                                                                                <a href="#" onClick={(e) => this.setAllPackageVersionsTo(e, VersionType.last, null!, false)}>
                                                                                    Select all
                                                                                </a>
                                                                            </Note>
                                                                        </React.Fragment>)}
                                                                </DataTableHeaderColumn>)}
                                                            <DataTableHeaderColumn>
                                                                Specific
                                                                {this.state.model.packages && this.state.model.packages.length > 1 && this.state.model.release && this.state.model.release.Version && (<React.Fragment>
                                                                        <br />
                                                                        <Note>
                                                                            <a href="#" onClick={(e) => this.setAllPackageVersionsTo(e, VersionType.specific, this.state.model!.release.Version, true)}>
                                                                                Select current release version
                                                                            </a>
                                                                        </Note>
                                                                    </React.Fragment>)}
                                                            </DataTableHeaderColumn>
                                                        </DataTableRow>
                                                    </DataTableHeader>
                                                    <DataTableBody>
                                                        {this.state.model && this.state.model.packages && (<LoadMoreWrapper items={this.state.model.packages} renderLoadMore={(children) => {
                                return (<DataTableRow>
                                                                            <DataTableRowColumn colSpan={4}>{children}</DataTableRowColumn>
                                                                        </DataTableRow>);
                            }} renderItem={(pack) => (<PackageDataTableRow key={this.createPackageKey(pack)} accessibleName={`${pack.ActionName} for ${pack.ProjectName ? pack.ProjectName : pack.PackageId}`}>
                                                                        <DataTableRowColumn className={cn(styles.packageTableRowColumn, styles.packageColumn)}>
                                                                            <div className={styles.actionName}>
                                                                                {pack.ActionName}
                                                                                {!!pack.PackageReferenceName && <span>/{pack.PackageReferenceName}</span>}
                                                                            </div>
                                                                            <Tooltip key="packageId" content={pack.ProjectName ? pack.ProjectName : `${pack.PackageId} from ${pack.FeedName}`}>
                                                                                {pack.ProjectName ? pack.ProjectName : pack.PackageId}
                                                                            </Tooltip>
                                                                            {this.state.missingPackages.includes(pack.PackageId) && (<span className={styles.missingPackageIcon}>
                                                                                    <Tooltip key="missingPackage" content={`${pack.PackageId} could not be found in ${pack.FeedName}`}>
                                                                                        <WarningIcon />
                                                                                    </Tooltip>
                                                                                </span>)}
                                                                        </DataTableRowColumn>
                                                                        <DataTableRowColumn className={cn(styles.packageTableRowColumn, styles.latestColumn)}>
                                                                            {this.buildRadioButton(pack, pack.LatestVersion, VersionType.latest, this.state.model!)}
                                                                        </DataTableRowColumn>
                                                                        {this.state.template && this.state.template.LastReleaseVersion && !this.state.originalVersion && (<DataTableRowColumn className={cn(styles.packageTableRowColumn, styles.lastColumn)}>
                                                                                <div className={styles.specificVersionDiv}>
                                                                                    {pack.LastReleaseVersion && (<div>
                                                                                            {!pack.IsLastReleaseVersionValid ? (<Tooltip content="Package version does not satisfy channel rules">
                                                                                                    {this.buildRadioButton(pack, pack.LastReleaseVersion, VersionType.last, this.state.model!)}
                                                                                                </Tooltip>) : (<div>{this.buildRadioButton(pack, pack.LastReleaseVersion, VersionType.last, this.state.model!)}</div>)}
                                                                                        </div>)}
                                                                                </div>
                                                                            </DataTableRowColumn>)}
                                                                        <DataTableRowColumn className={cn(styles.packageTableRowColumn, styles.specificColumn)}>
                                                                            <div className={styles.specificVersionDiv}>
                                                                                {this.props.fixedPackageVersionEnabled && pack.FixedVersion ? (<Tooltip content={`The version for this package is set in the deployment process.`}>
                                                                                        <div className={styles.inlineDiv}>{this.buildRadioButton(pack, pack.FixedVersion, VersionType.specific, this.state.model!)}</div>
                                                                                    </Tooltip>) : (<>
                                                                                        <div className={styles.inlineDiv}>{this.buildRadioButton(pack, pack.SpecificVersion, VersionType.specific, this.state.model!)}</div>
                                                                                        <div className={styles.inlineDiv}>
                                                                                            <div className={styles.editVersionArea}>
                                                                                                <DebounceText debounceDelay={500} className={styles.versionTextbox} placeholder="Enter a version" accessibleName="Specific version" value={this.props.fixedPackageVersionEnabled && pack.FixedVersion ? pack.FixedVersion : pack.SpecificVersion} onChange={async (version) => {
                                        await this.specificVersionSelected(this.state.model!, pack, version);
                                    }} disabled={this.props.fixedPackageVersionEnabled && !!pack.FixedVersion}/>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className={styles.inlineDiv}>{this.packageVersionsButton(pack)}</div>
                                                                                    </>)}
                                                                            </div>
                                                                        </DataTableRowColumn>
                                                                    </PackageDataTableRow>)}/>)}
                                                    </DataTableBody>
                                                </DataTable>
                                            </div>
                                        </ExpandableFormSection>)}

                                    {this.state.model.gitResources && this.state.model.gitResources.length > 0 && (<GitResourcesSection project={project} context={"Release"} channelId={channel?.Id} releaseId={this.state.model.release.Id} showLastGitResource={!this.state.originalVersion} actionsThatViolateGitResourceRules={this.state.actionsThatViolateGitResourceRules} gitResources={this.state.model.gitResources} onUpdateGitRef={async (gitRef: GitReferenceEditInfo) => {
                            await this.doBusyTask(async () => {
                                const updatedGitRefs = this.state.model?.gitResources.map((gr) => (gr.ActionName === gitRef.ActionName && gr.GitResourceReferenceName === gitRef.GitResourceReferenceName ? gitRef : gr));
                                let actionsThatViolateGitResourceRules = new Set<string>();
                                if (this.shouldValidateGitResourceRules(updatedGitRefs)) {
                                    actionsThatViolateGitResourceRules = await this.updateActionsThatViolateGitResourcesRules(updatedGitRefs, gitRef);
                                }
                                this.setState((prevState: ReleaseState) => ({
                                    model: { ...prevState.model, gitResources: updatedGitRefs },
                                    actionsThatViolateGitResourceRules,
                                }));
                            });
                        }}/>)}

                                    <ExpandableFormSection errorKey="notes" title="Release Notes" summary={this.state.model.release.ReleaseNotes ? Summary.summary("Release notes have been provided") : Summary.placeholder("No release notes provided")} help={this.buildReleaseNoteHelpInfo()}>
                                        <MarkdownEditor value={this.state.model.release.ReleaseNotes} label="Release notes" onChange={(releaseNotes) => this.setChildState2("model", "release", { ReleaseNotes: releaseNotes })}/>
                                    </ExpandableFormSection>
                                </TransitionAnimation>)}
                        </FormContent>
                    </PageContent>)}
            </LegacyForm>));
    }
    private setAllPackageVersionsTo = (e: React.MouseEvent, versionType: VersionType, specificVersion: string, includeConfirmation: boolean) => {
        e.preventDefault();
        if (includeConfirmation && !confirm(`This will set all packages to version ${specificVersion}. Are you sure this version exists for all the packages?`)) {
            return;
        }
        const model = this.state.model!;
        const release = model.release;
        release.SelectedPackages = [];
        for (const selection of this.state.model!.packages) {
            selection.VersionType = versionType;
            selection.SpecificVersion = specificVersion;
            release.SelectedPackages.push({
                ActionName: selection.ActionName,
                Version: specificVersion,
                PackageReferenceName: selection.PackageReferenceName,
            });
        }
        this.setState({ model });
    };
    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const ev: ActionEvent = {
                action: Action.Save,
                resource: this.state.isNew ? "Create Release" : "Edit Release",
                isDefaultBranch: IsDefaultBranch(this.state.project.PersistenceSettings, this.props.projectContext.state.gitRef?.CanonicalName),
                gitRefType: this.props.projectContext.state.gitRef && getGitRefType(this.props.projectContext.state.gitRef.CanonicalName),
            };
            await this.props.trackAction("Save a Release", ev, async (cb: AnalyticErrorCallback) => {
                const model = this.state.model;
                const release = model!.release;
                release.SelectedPackages = [];
                for (const selection of this.state.model!.packages) {
                    let selectedVersion = "";
                    if (this.props.fixedPackageVersionEnabled && selection.FixedVersion) {
                        selectedVersion = selection.FixedVersion;
                    }
                    else if (selection.VersionType === VersionType.latest) {
                        selectedVersion = selection.LatestVersion;
                    }
                    else if (selection.VersionType === VersionType.last) {
                        selectedVersion = selection.LastReleaseVersion;
                    }
                    else if (selection.VersionType === VersionType.specific) {
                        selectedVersion = selection.SpecificVersion;
                    }
                    release.SelectedPackages.push({
                        ActionName: selection.ActionName,
                        Version: selectedVersion,
                        PackageReferenceName: selection.PackageReferenceName,
                    });
                }
                release.SelectedGitResources = this.state.model!.gitResources.map((r) => ({
                    ActionName: r.ActionName,
                    GitReferenceResource: r.GitReferenceType === GitReferenceType.specific ? r.SpecificGitResource : r.LastGitResource!,
                    GitResourceReferenceName: r.GitResourceReferenceName ?? "",
                }));
                const newRelease = await save(release);
                const newModel: ReleaseModel = {
                    release: newRelease,
                    packages: this.state.model!.packages,
                    channels: this.state.model!.channels,
                    gitResources: this.state.model!.gitResources,
                };
                this.setState({
                    model: newModel,
                    cleanModel: cloneDeep(newModel),
                    redirect: true,
                });
                if (!newRelease || !newRelease.Id) {
                    cb("Failed to save release");
                }
            });
        });
        function save(release: ReleaseResource) {
            if (release.Links) {
                return repository.Releases.modify(release);
            }
            return repository.Releases.create(release);
        }
    };
    private defaultGitProperties(): IHaveGitReference {
        const gitRef = this.props.projectContext.state.gitRef;
        const project = this.props.projectContext.state.model;
        if (gitRef) {
            return {
                VersionControlReference: {
                    GitRef: gitRef.CanonicalName,
                },
            };
        }
        if (HasGitPersistenceSettings(project.PersistenceSettings)) {
            return {
                VersionControlReference: {
                    GitRef: toGitBranch(project.PersistenceSettings.DefaultBranch),
                },
            };
        }
        return {
            VersionControlReference: {
                GitRef: undefined,
            },
        };
    }
    private packageVersionsButton = (pack: PackageEditInfo) => {
        const feed = this.state.feeds[pack.FeedId];
        const openDialog = (disabled: boolean) => (<OpenDialogButton type={ActionButtonType.Secondary} dialogWidth={"1000px"} disabled={disabled} label="Select Version" accessibleName={`Select version of ${pack.PackageReferenceName} for ${pack.ActionName}`}>
                <PackageListDialogContent package={pack} feed={feed} onVersionSelected={async (version) => {
                await this.specificVersionSelected(this.state.model!, pack, version);
            }} channelFilters={this.getChannelFilters(this.state.model!, pack.ActionName, pack.PackageReferenceName)}/>
            </OpenDialogButton>);
        if (feed) {
            return openDialog(false);
        }
        return <Tooltip content="No feed available. Package step may be using a variable as feed.">{openDialog(true)}</Tooltip>;
    };
    private gitRefHelp(): string {
        return this.state.isNew ? "Choose a GitRef for this release to use." : "The GitRef for an existing release cannot be modified.";
    }
    private gitRefSummary(): SummaryNode {
        if (!this.state.model || !this.state.model.release.VersionControlReference?.GitRef) {
            return Summary.placeholder("Select a GitRef");
        }
        const gitRef = this.state.model.release.VersionControlReference.GitRef;
        if (!HasGitPersistenceSettings(this.state.project.PersistenceSettings))
            throw new Error("Config as Code: Trying to access a VCS Property on a non-VCS Project.");
        const defaultBranch = this.state.project.PersistenceSettings.DefaultBranch;
        const chip = <GitRefChip vcsRef={{ GitRef: gitRef }}/>;
        return toGitRefShort(gitRef) === toGitRefShort(defaultBranch) ? Summary.default(chip) : Summary.summary(chip);
    }
    private packagesSummary = () => {
        if (!this.state.model!.packages || this.state.model!.packages.length === 0) {
            return Summary.placeholder("No package is included");
        }
        const packageVersions = this.state.model!.packages.map((p) => this.getPackageInfoVersion(p));
        if (packageVersions.length === 1) {
            return Summary.summary(packageVersions[0] ? ("1 package included, at version " + packageVersions[0]) : (<span>
                        1 package included, <strong>no version specified</strong>
                    </span>));
        }
        const firstVersion = packageVersions.find((p) => !!p);
        const noneHaveVersion = !firstVersion;
        const allOnSameVersion = firstVersion && packageVersions.every((p) => p === firstVersion);
        const numberWithNoVersion = packageVersions.filter((p) => !p).length;
        const packagesIncluded = packageVersions.length + " packages included";
        const noVersionSummary = numberWithNoVersion ? (<span>
                ,{" "}
                <strong>
                    {numberWithNoVersion} {numberWithNoVersion === 1 ? "has" : "have"} no version selected
                </strong>
            </span>) : (<span />);
        const versionSummary = allOnSameVersion ? ", all at version " + firstVersion : noneHaveVersion ? "" : ", with a mix of versions";
        return Summary.summary(<span>
                {packagesIncluded}
                {versionSummary}
                {noVersionSummary}
            </span>);
    };
    private getPackageInfoVersion(info: PackageEditInfo): string {
        if (info.FixedVersion && this.props.fixedPackageVersionEnabled) {
            return info.FixedVersion;
        }
        switch (info.VersionType) {
            case VersionType.specific:
                return info.SpecificVersion;
            case VersionType.last:
                return info.LastReleaseVersion;
            case VersionType.latest:
                return info.LatestVersion;
            default:
                throw Error("Unsupported version type");
        }
    }
    private disableDirtyFormCheck = () => {
        // don't want "dirty" to be triggered by the version being auto populated or channel from route param
        return this.state.cleanModel && isEqual(this.state.defaultCheckModel, this.state.model);
    };
    private buildRadioButton(pack: PackageEditInfo, version: string, type: VersionType, model: ReleaseModel) {
        if (!pack.IsResolvable && type === VersionType.latest) {
            return <div />;
        }
        return (<RadioButtonGroup className={styles.radioButtonContainer} value={type} onChange={async () => {
                await this.packageVersionChanged(model, pack, version, type);
            }}>
                <RadioButton accessibleName={`${type} package version ${version}`} className={styles.myRadioButton} value={this.props.fixedPackageVersionEnabled && pack.FixedVersion ? VersionType.specific : pack.VersionType} label={type === VersionType.specific && (!this.props.fixedPackageVersionEnabled || !pack.FixedVersion) ? "" : version} disabled={this.props.fixedPackageVersionEnabled && !!pack.FixedVersion && type !== VersionType.specific}/>
            </RadioButtonGroup>);
    }
    private buildReleaseNoteHelpInfo = () => {
        const helpInfo = "Enter a summary of what has changed in this release, such as which features were added and which bugs were fixed. " + "These notes will be shown on the release page. You can edit these notes later.";
        return helpInfo;
    };
    private specificVersionSelected = async (model: ReleaseModel, pack: PackageEditInfo, version: string) => {
        pack.SpecificVersion = version;
        await this.packageVersionChanged(model, pack, version, VersionType.specific);
    };
    private handleDeleteConfirm = async (): Promise<boolean> => {
        if (!this.state.isNew) {
            await repository.Releases.del(this.state.model!.release);
            this.setState(() => {
                return {
                    model: null,
                    cleanModel: null,
                    deleted: true,
                };
            });
            return true;
        }
        else {
            return false;
        }
    };
    private async getTemplate(deploymentProc: DeploymentProcessResource, model: ReleaseModel, isNew: boolean): Promise<ReleaseTemplateResource> {
        const { model: project, projectContextRepository } = this.props.projectContext.state;
        // We can only load branch information when loading for NEW vcs releases,
        // we cant make any assumptions about the branches for existing releases
        if (isNew && project?.IsVersionControlled) {
            const gitRef = await repository.Projects.getGitRef(project, model.release.VersionControlReference!.GitRef!);
            return await projectContextRepository.DeploymentProcesses.getTemplateForGitRef(gitRef, model.release.ChannelId);
        }
        else {
            return await projectContextRepository.DeploymentProcesses.getTemplate(deploymentProc, model.release.ChannelId, model.release.Id);
        }
    }
    private async loadTemplate(model: ReleaseModel, deploymentProc: DeploymentProcessResource, isNew: boolean, projectId: string) {
        const template = await this.getTemplate(deploymentProc, model, isNew);
        if (!model.release.Id && template?.NextVersionIncrement) {
            model.release.Version = template.NextVersionIncrement;
        }
        const existingSelections: {
            [key: string]: string;
        } = {};
        if (model.release.SelectedPackages) {
            for (const p of model.release.SelectedPackages) {
                existingSelections[this.createPackageKey(p)] = p.Version;
            }
        }
        const selectionByFeed: {
            [feedId: string]: PackageEditInfo[];
        } = {};
        const packageSelections = [];
        for (const p of template.Packages) {
            const specificVersion = existingSelections[this.createPackageKey(p)] ?? "";
            const isResolvable = p.IsResolvable;
            const lastReleaseVersion = p.VersionSelectedLastRelease;
            const selection: PackageEditInfo = {
                ActionName: p.ActionName,
                PackageReferenceName: p.PackageReferenceName,
                PackageId: p.PackageId,
                ProjectName: p.ProjectName,
                FeedId: p.FeedId,
                FeedName: p.FeedName,
                LatestVersion: "",
                SpecificVersion: specificVersion,
                IsResolvable: isResolvable,
                LastReleaseVersion: lastReleaseVersion,
                ...(this.props.fixedPackageVersionEnabled ? { FixedVersion: p.FixedVersion } : {}),
                VersionType: specificVersion ? VersionType.specific : isResolvable ? VersionType.latest : lastReleaseVersion ? VersionType.last : VersionType.specific,
                IsLastReleaseVersionValid: !isBound(p.FeedId),
            };
            packageSelections.push(selection);
            if (selection.IsResolvable) {
                if (!selectionByFeed[selection.FeedId]) {
                    selectionByFeed[selection.FeedId] = [];
                }
                selectionByFeed[selection.FeedId].push(selection);
            }
        }
        const gitResourceSelections: GitReferenceEditInfo[] = template.GitResources.map((g) => {
            const existingSelection = model.release.SelectedGitResources?.find((sgr) => sgr.ActionName === g.ActionName && sgr.GitResourceReferenceName === g.Name);
            return {
                ActionName: g.ActionName,
                GitCredentialId: g.GitCredentialId,
                RepositoryUri: g.RepositoryUri,
                DefaultBranch: g.DefaultBranch,
                IsResolvable: g.IsResolvable,
                FilePathFilters: g.FilePathFilters,
                LastGitResource: g.GitResourceSelectedLastRelease,
                SpecificGitResource: existingSelection?.GitReferenceResource || { GitRef: toGitBranch(g.DefaultBranch) },
                GitReferenceType: GitReferenceType.specific,
                GitResourceReferenceName: g.Name,
            };
        });
        const allRelevantFeedIds = uniq(template.Packages.map((x) => x.FeedId)).filter((x) => !isBound(x));
        const relevantFeeds = await repository.Feeds.list({ skip: 0, take: Repository.takeAll, ...{ ids: allRelevantFeedIds } });
        await this.setStateAsync({ ...this.state, template, feeds: keyBy(relevantFeeds.Items, "Id") });
        await this.loadVersions(model, selectionByFeed); // This function depends on template being in state.
        model.packages = packageSelections;
        model.gitResources = gitResourceSelections;
        let actionsThatViolateGitResourceRules: Set<string> = new Set();
        if (this.shouldValidateGitResourceRules(gitResourceSelections)) {
            actionsThatViolateGitResourceRules = new Set(await this.validateGitResourceRules(deploymentProc, model.gitResources, projectId, model.release.ChannelId));
        }
        this.setState({ model, actionsThatViolateGitResourceRules });
        if (!model.release.Version) {
            this.props.setExpanderState(versionExpanderKey, true);
        }
    }
    private setVersionSatisfaction = async (model: ReleaseModel, pkg: PackageEditInfo, version: string, versionType: VersionType, feedType: FeedType) => {
        const violatedPackages = this.state.violatedPackages.slice();
        const filters = this.getChannelFilters(model, pkg.ActionName, pkg.PackageReferenceName);
        if (versionType) {
            pkg.VersionType = versionType;
        }
        await this.doBusyTask(async () => {
            const result = await this.memoizedRepositoryChannelsRuleTest(version, filters.versionRange!, filters.preReleaseTag!, feedType);
            const isSelectedVersionValid = result.Errors.indexOf("Invalid Version Number") !== -1 || (result.SatisfiesVersionRange && result.SatisfiesPreReleaseTag);
            const position = violatedPackages.indexOf(pkg.ActionName);
            if (isSelectedVersionValid && position !== -1) {
                violatedPackages.splice(position, 1);
            }
            else if (!isSelectedVersionValid && position === -1) {
                violatedPackages.push(pkg.ActionName);
            }
            this.setState({ violatedPackages });
        });
    };
    private loadVersions(model: ReleaseModel, selectionsByFeed: Dictionary<PackageEditInfo[]>): Promise<boolean> {
        const memoizedRepositoryFeedsGet = (id: string) => this.state.feeds[id];
        const checkForRuleSatisfaction = async (selection: PackageEditInfo, filters: {
            versionRange?: string;
            preReleaseTag?: string;
        }, feedType: FeedType) => {
            if (selection.LastReleaseVersion) {
                const result = await this.memoizedRepositoryChannelsRuleTest(selection.LastReleaseVersion, filters.versionRange!, filters.preReleaseTag!, feedType);
                selection.IsLastReleaseVersionValid = result.SatisfiesVersionRange && result.SatisfiesPreReleaseTag;
            }
            else {
                selection.IsLastReleaseVersionValid = false;
            }
        };
        const getPackageVersion = async (feedId: string): Promise<any> => {
            const feed = memoizedRepositoryFeedsGet(feedId);
            if (!feed) {
                throw "No feed with an Id of '" + feedId + "' could be found.";
            }
            const selections = selectionsByFeed[feedId];
            if (!selections) {
                throw "No selections with a Feed Id of '" + feedId + "' could be found.";
            }
            const packageSearchGroups = groupBy(selections.map((selection) => ({ selection, filter: this.getChannelFilters(model, selection.ActionName, selection.PackageReferenceName) })), ({ selection, filter }) => selection.PackageId + JSON.stringify(filter || {}));
            const t = Object.values(packageSearchGroups).map(async (sameFilteredPackages) => {
                const packageId = sameFilteredPackages[0].selection.PackageId;
                let releases: PackageVersionResource[] = [];
                try {
                    releases = (await repository.Feeds.searchPackageVersions(feed, packageId, {
                        ...sameFilteredPackages[0].filter,
                        take: 1,
                    })).Items;
                }
                catch (ex) {
                    this.setState({ missingPackages: [...this.state.missingPackages, packageId] });
                    throw ex;
                }
                const packageExists = releases.length > 0;
                if (!packageExists) {
                    this.setState({ missingPackages: [...this.state.missingPackages, packageId] });
                }
                return sameFilteredPackages.map(async ({ selection, filter }) => {
                    await checkForRuleSatisfaction(selection, filter, feed.FeedType);
                    if (releases.length === 0) {
                        // no latest version found
                        selection.IsResolvable = false;
                        // Docker feeds may not conform to semver, in which case there will be no valid versions.
                        // However you can manually enter a version like "latest", and this will be shown as the
                        // last version. It is convenient to select that last version rather than default to
                        // the specific version field.
                        selection.VersionType = selection.LastReleaseVersion ? VersionType.last : VersionType.specific;
                        if (!packageExists) {
                            return;
                        }
                        return this.setVersionSatisfaction(model, selection, selection.SpecificVersion, null!, feed.FeedType);
                    }
                    const pkg = releases[0];
                    selection.LatestVersion = pkg.Version;
                    if (!model.release.Id) {
                        return this.packageVersionChanged(model, selection, pkg.Version, null!);
                    }
                    if (!packageExists) {
                        return;
                    }
                    return this.setVersionSatisfaction(model, selection, selection.SpecificVersion, null!, feed.FeedType);
                });
            });
            return Promise.all(flatten(await Promise.all(t)));
        };
        return this.doBusyTask(() => {
            return Promise.all(keys(selectionsByFeed)
                .filter((f) => !isBound(f))
                .map((f) => getPackageVersion(f)));
        });
    }
    private packageVersionChanged = async (m: ReleaseModel, pkg: PackageEditInfo, version: string, versionType: VersionType) => {
        const model = { ...m };
        if (this.state.template &&
            this.state.template.VersioningPackageStepName &&
            this.state.template.VersioningPackageStepName === pkg.ActionName &&
            this.state.template.VersioningPackageReferenceName === pkg.PackageReferenceName &&
            this.state.isNew) {
            model.release.Version = version;
        }
        if (versionType) {
            pkg.VersionType = versionType;
            if (versionType === VersionType.specific) {
                pkg.SpecificVersion = version;
            }
        }
        if (!isBound(pkg.FeedId) && this.state.feeds) {
            const feed = this.state.feeds[pkg.FeedId];
            if (feed) {
                await this.setVersionSatisfaction(model, pkg, version, versionType, feed.FeedType);
            }
        }
        this.setState({ model });
    };
    private getChannelFilters = (model: ReleaseModel, deploymentActionName: string, packageReferenceName: string | undefined): channelFilters => {
        const filters: channelFilters = {};
        if (!model || !model.release.ChannelId) {
            return filters;
        }
        const applicableRules = model.channels.Items.find((x) => {
            return x.Id === model.release.ChannelId;
        })!.Rules.find((rule) => {
            return rule.ActionPackages.length === 0 || rule.ActionPackages.findIndex((x) => x.DeploymentAction === deploymentActionName && PackageReferenceNamesMatch(packageReferenceName, x.PackageReference)) >= 0;
        });
        if (applicableRules && applicableRules.VersionRange) {
            filters.versionRange = applicableRules.VersionRange;
        }
        if (applicableRules && applicableRules.Tag) {
            filters.preReleaseTag = applicableRules.Tag;
        }
        return filters;
    };
    private onGitRefChange = async (branch: GitRef | undefined) => {
        this.setState((state) => {
            if (!state.model)
                return state;
            if (!state.model.release.VersionControlReference) {
                state.model.release.VersionControlReference = {
                    GitRef: branch,
                };
            }
            else {
                state.model.release.VersionControlReference.GitRef = branch;
            }
            return { ...state, busyTaskIsRunning: true, checkingDeploymentProcess: true, gitReferenceRuleValidation: undefined, violatedPackages: [] };
        }, () => {
            if (!this.state.model)
                return;
            const model = { ...this.state.model };
            if (branch) {
                this.doBusyTask(async () => {
                    await this.props.projectContext.actions.onBranchSelected(this.state.project, branch);
                    let gitReferenceRuleValidation: GitReferenceSatisfiesChannelGitReferenceRulesResponse | undefined = undefined;
                    if (this.shouldValidateGitRefRule(branch) && this.state.model?.release.ChannelId !== null && this.state.model?.release.ChannelId !== undefined) {
                        gitReferenceRuleValidation = await repository.Channels.gitReferenceRuleValidation(this.state.project.Id, this.state.model.release.ChannelId, branch);
                    }
                    const deploymentProc = await this.props.projectContext.state.projectContextRepository.DeploymentProcesses.get();
                    this.setState({ model, deploymentProc, gitReferenceRuleValidation, checkingDeploymentProcess: false, ...(this.deploymentProcessHasSteps(deploymentProc) ? { busyTaskIsRunning: false } : { busyTaskIsRunning: true }) }, () => {
                        if (deploymentProc.Steps.length > 0) {
                            this.doBusyTask(async () => {
                                await this.loadTemplate(model, deploymentProc, this.state.isNew, this.state.project.Id);
                            });
                        }
                    });
                });
            }
        });
    };
    private deploymentProcessHasSteps = (DeploymentProcess: DeploymentProcessResource) => {
        return DeploymentProcess && DeploymentProcess.Steps.length > 0;
    };
    private onChannelChanged = async (channelId: string) => {
        this.state.model!.release.ChannelId = channelId;
        await this.doBusyTask(async () => {
            this.setState({ violatedPackages: [], gitReferenceRuleValidation: undefined });
            if (this.shouldValidateGitRefRule(this.state.model?.release.VersionControlReference?.GitRef)) {
                const gitReferenceRuleValidation = await repository.Channels.gitReferenceRuleValidation(this.state.project.Id, channelId, this.state.model?.release.VersionControlReference?.GitRef);
                this.setState({ gitReferenceRuleValidation });
            }
            await this.loadTemplate(_.cloneDeep(this.state.model!), this.state.deploymentProc, this.state.isNew, this.state.project.Id);
        });
    };
    private createPackageKey(pkg: {
        ActionName: string;
        PackageReferenceName?: string;
    }) {
        let key = pkg.ActionName;
        if (pkg.PackageReferenceName) {
            key += `[${pkg.PackageReferenceName}]`;
        }
        return key;
    }
    private async validateGitResourceRules(deploymentProc: DeploymentProcessResource, gitResourceSelections: GitReferenceEditInfo[], projectId: string, channelId: string) {
        const steps = deploymentProc.Steps;
        const actionsThatViolateGitResourceRules: Set<string> = new Set();
        if (steps !== undefined && steps.length > 0) {
            const actionsOnSteps = steps.flatMap((step) => step.Actions);
            for await (const gitResource of gitResourceSelections) {
                const matchedAction = actionsOnSteps.find((action) => action.Name === gitResource.ActionName);
                if (matchedAction !== undefined) {
                    const gitRef = gitResource.GitReferenceType === GitReferenceType.specific ? gitResource.SpecificGitResource.GitRef : gitResource.LastGitResource?.GitRef;
                    const validationResponse = await repository.Channels.gitResourceRuleValidation(projectId, channelId, gitRef, matchedAction.Slug, encodeURIComponent(gitResource.GitResourceReferenceName ?? ""));
                    if (!validationResponse.SatisfiesGitResourceRules) {
                        actionsThatViolateGitResourceRules.add(gitResource.ActionName);
                    }
                }
            }
        }
        return actionsThatViolateGitResourceRules;
    }
    private async updateActionsThatViolateGitResourcesRules(updatedGitRefs: GitReferenceEditInfo[] | undefined, gitRef: GitReferenceEditInfo) {
        let actionViolatesResourceRules: Set<string> = new Set();
        if (this.shouldValidateGitResourceRules(updatedGitRefs) && this.state.model !== undefined && updatedGitRefs !== undefined) {
            actionViolatesResourceRules = await this.validateGitResourceRules(this.state.deploymentProc, [gitRef], this.state.project.Id, this.state.model.release.ChannelId);
        }
        if (actionViolatesResourceRules.size > 0) {
            return new Set([...this.state.actionsThatViolateGitResourceRules, ...actionViolatesResourceRules]);
        }
        else {
            const errorListWithErrorRemoved = new Set(this.state.actionsThatViolateGitResourceRules);
            errorListWithErrorRemoved.delete(gitRef.ActionName);
            return errorListWithErrorRemoved;
        }
    }
    static displayName = "CreateOrEditReleasePageInternal";
}
interface CreateOrEditReleasePageProps {
    releaseVersion?: string;
}
export const CreateOrEditReleasePage: React.FC<CreateOrEditReleasePageProps> = (props) => {
    const [queryParams] = useQueryStringParams([optionalStringQueryParam("channelId")]);
    const setExpanderState = useSetExpanderState();
    const projectContext = useProjectContext();
    const trackAction = useProjectScopedAnalyticTrackedActionDispatch(projectContext.state.model.Id);
    const fixedPackageVersionEnabled = useOctopusFeatureToggle("package-version-on-action", false);
    return <CreateOrEditReleasePageInternal setExpanderState={setExpanderState} projectContext={projectContext} trackAction={trackAction} fixedPackageVersionEnabled={fixedPackageVersionEnabled} channelId={queryParams.channelId} {...props}/>;
};
CreateOrEditReleasePage.displayName = "CreateOrEditReleasePage"
