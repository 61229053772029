import type { PrimaryPageAction } from "@octopusdeploy/design-system-components";
import type { LibraryVariableSetResource, ResourceCollection } from "@octopusdeploy/octopus-server-client";
import { Permission, VariableSetContentType } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { AddVariableSetDialogLayout } from "~/areas/library/components/VariableSets/AddVariableSetDialogLayout";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import Dialog from "~/components/Dialog/Dialog";
import type { DialogControls } from "~/components/Dialog/DialogTrigger";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import MarkdownDescription from "~/components/MarkdownDescription";
import { PageContent } from "~/components/PageContent/PageContent";
import { PagingList } from "~/components/PagingList/PagingList";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
interface VariableSetsPageProps {
    spaceId: string;
}
interface VariableSetsPageInternalProps extends VariableSetsPageProps {
    addVariableSetDialogControls: DialogControls;
}
interface VariableSetsState extends DataBaseComponentState {
    variableSets?: ResourceCollection<LibraryVariableSetResource>;
}
class VariableSetsPageInternal extends DataBaseComponent<VariableSetsPageInternalProps, VariableSetsState> {
    constructor(props: VariableSetsPageInternalProps) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        await this.doBusyTask(() => this.loadVariableSets(), { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    render() {
        const addVariableSetPageAction: PrimaryPageAction = {
            type: "button",
            hasPermissions: isAllowed({ permission: Permission.LibraryVariableSetCreate }),
            label: "Add Variable Set",
            onClick: this.props.addVariableSetDialogControls.openDialog,
        };
        return (<PageContent header={{ title: "Variable Sets", primaryAction: addVariableSetPageAction }} busy={this.state.busy} errors={this.errors}>
                <Dialog open={this.props.addVariableSetDialogControls.isOpen}>
                    <AddVariableSetDialogLayout spaceId={this.props.spaceId}/>
                </Dialog>
                {this.state.variableSets && (<VariableSetList initialData={this.state.variableSets} onRow={(variableSet: LibraryVariableSetResource) => this.buildRow(variableSet)} onRowRedirectUrl={(variableSet: LibraryVariableSetResource) => links.editVariableSetPage.generateUrl({ spaceId: variableSet.SpaceId, variableSetId: variableSet.Id })} onFilter={filterVariableSets} filterHintText="Filter by name..." filterSearchEnabled={true} apiSearchParams={["partialName"]} additionalRequestParams={new Map([["contentType", ["Variables"]]])}/>)}
            </PageContent>);
    }
    private buildRow(tagSet: LibraryVariableSetResource) {
        return (<div>
                <ListTitle>{tagSet.Name}</ListTitle>
                <MarkdownDescription markup={tagSet.Description}/>
            </div>);
    }
    private async loadVariableSets() {
        this.setState({ variableSets: await repository.LibraryVariableSets.list({ contentType: VariableSetContentType.Variables }) });
    }
    static displayName = "VariableSetsPageInternal";
}
export function VariableSetsPage(props: VariableSetsPageProps) {
    const addVariableSetDialogControls = useDialogTrigger();
    return <VariableSetsPageInternal {...props} addVariableSetDialogControls={addVariableSetDialogControls}/>;
}
function filterVariableSets(filter: string, item: LibraryVariableSetResource) {
    return item.Name.toLowerCase().includes(filter.toLowerCase());
}
class VariableSetList extends PagingList<LibraryVariableSetResource> {
}
