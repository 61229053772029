/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Callout } from "@octopusdeploy/design-system-components";
import type { ResourceCollection, EventResource, EventCategoryResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository, session } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import EventListing from "~/components/EventListing/EventListing";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { PageContent } from "~/components/PageContent/PageContent";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
interface UserRecentActivityProps {
    userId?: string;
}
interface UserRecentActivityState extends DataBaseComponentState {
    events: ResourceCollection<EventResource>;
    eventCategories: EventCategoryResource[];
    userId: string;
}
export default class UserRecentActivityPage extends DataBaseComponent<UserRecentActivityProps, UserRecentActivityState> {
    constructor(props: UserRecentActivityProps) {
        super(props);
        this.state = {
            events: null!,
            eventCategories: null!,
            userId: null!,
        };
    }
    currentUserId(): string {
        return session && session.currentUser ? session.currentUser.Id : null!;
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const useOptimization = isFeatureToggleEnabled("PermissionCheckOptimizationFeatureToggle");
            const userId = this.props.userId || this.currentUserId();
            const eventsPromise = useOptimization ? repository.Events.getUnpaginatedEvents({ users: [userId], includeSystem: "false" }) : repository.Events.list({ users: [userId], includeSystem: "false" });
            const eventCategoriesPromise = repository.Events.categories({});
            this.setState({
                events: await eventsPromise,
                eventCategories: await eventCategoriesPromise,
                userId,
            });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    render() {
        return (<PageContent header={{ title: "My Recent Activity" }} busy={this.state.busy} errors={this.errors}>
                <PermissionCheck permission={Permission.EventView} wildcard={true} alternate={<Callout type={"information"} title={"Permission required"}>
                            The {Permission.EventView} permission is required to view recent activity
                        </Callout>}>
                    {this.state.events && (<EventListing data={this.state.events} eventCategories={this.state.eventCategories} onPageSelected={async (skip: number, p: number) => {
                    const result = await repository.Events.list({ users: [this.state.userId], includeSystem: "false", skip });
                    return result;
                }} noDataMessage={"There's no activity to show"}/>)}
                </PermissionCheck>
            </PageContent>);
    }
    static displayName = "UserRecentActivityPage";
}
