/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { ActionButton, Tooltip } from "@octopusdeploy/design-system-components";
import type { ScriptProperties } from "@octopusdeploy/legacy-action-properties";
import type { ActionProperties, FeedResource, PackageReference, WorkerPoolResource } from "@octopusdeploy/octopus-server-client";
import { ActionExecutionLocation, GetNamedPackageReferences, GetPackageVariableNames, GetPrimaryPackageReference, InitialisePrimaryGitDependency, InitialisePrimaryPackageReference, PackageAcquisitionLocation, PackageRequirement, PackageSelectionMode, RemovePrimaryGitDependency, RemovePrimaryPackageReference, ScriptingLanguage, SetPrimaryPackageReference, SpecialVariables, } from "@octopusdeploy/octopus-server-client";
import * as _ from "lodash";
import * as React from "react";
import { useOptionalProcessContext } from "~/areas/projects/components/Process/Contexts/ProcessContext";
import { useGitCredentialsFromContext, useRefreshGitCredentialsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessGitCredentialsContextProvider";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import { CalloutModifiedFeedTriggerConnections } from "~/areas/projects/components/Triggers/ProcessCallouts/CalloutModifiedFeedTriggerConnections";
import { CalloutReferencedPackageFeedTriggerConnections } from "~/areas/projects/components/Triggers/ProcessCallouts/CalloutReferencedPackageFeedTriggerConnections";
import { buildRelatedTriggerDependencies } from "~/areas/projects/components/Triggers/ProcessCallouts/triggerPackageRelations";
import { useOptionalProjectContext } from "~/areas/projects/context";
import ScriptSourceSelectorComponent from "~/components/Actions/script/scriptSourceSelectorComponent";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import { WorkerPoolChip } from "~/components/Chips";
import type { Language, TextFormat } from "~/components/CodeEditor/CodeEditor";
import { CodeEditor } from "~/components/CodeEditor/CodeEditor";
import { InlineScriptEditorContextualHelp } from "~/components/ContextualHelp/ContextualHelpSnippets";
import { GitRepositorySourceSelector, isProjectRepositoryGitSourceSupported } from "~/components/GitRepositorySource/GitRepositorySourceSelector";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import { default as PackageDownloadOptions } from "~/components/PackageDownloadOptions/PackageDownloadOptions";
import PackageSelector from "~/components/PackageSelector/PackageSelector";
import { SupportedLanguage } from "~/components/ScriptingLanguageSelector/ScriptingLanguageSelector";
import { ExpandableFormSection, FormSectionHeading, Summary } from "~/components/form";
import { CardFill } from "~/components/form/Sections/ExpandableFormSection";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import Note from "~/primitiveComponents/form/Note/Note";
import { codeEditorVariablesList } from "~/utils/ScriptIntellisense/scriptIntellisense";
import { ProcessFeedLookup, useFeedsFromContext, useRefreshFeedsFromContext } from "../../../areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import ListTitle from "../../../primitiveComponents/dataDisplay/ListTitle/ListTitle";
import DialogOpener from "../../Dialog/DialogOpener";
import { RemoveItemsList } from "../../RemoveItemsList/RemoveItemsList";
import { DisplayFeedName } from "../DisplayFeedName";
import Roles from "../Roles";
import type { ActionSummaryProps } from "../actionSummaryProps";
import type { ActionWithDirtyState, ActionWithFeeds, ActionWithGitRepositorySource } from "../commonActionHelpers";
import type { ActionEditProps } from "../pluginRegistry";
import pluginRegistry from "../pluginRegistry";
import { useScriptActionContext } from "./ScriptActionContext";
import type { ScriptPackageProperties, ScriptPackageReference } from "./ScriptPackageReferenceDialog";
import { ScriptPackageReferenceDialog } from "./ScriptPackageReferenceDialog";
import type { WithScriptActionContextInjectedProps } from "./withScriptActionContext";
export interface ScriptActionSummaryProps extends ActionSummaryProps {
    workerPool?: WorkerPoolResource;
    workerPoolVariable?: string | null;
}
interface ScriptActionSummaryState {
    feedNames: Map<string, string>;
}
class ScriptActionSummarySelector extends BaseComponent<ScriptActionSummaryProps, ScriptActionSummaryState> {
    constructor(props: ScriptActionSummaryProps) {
        super(props);
    }
    render() {
        return <ScriptActionSummary {...this.props}/>;
    }
    static displayName = "ScriptActionSummarySelector";
}
class ScriptActionSummary extends BaseComponent<ScriptActionSummaryProps, ScriptActionSummaryState> {
    constructor(props: ScriptActionSummaryProps) {
        super(props);
    }
    render() {
        const runsOnServer = this.props.properties[SpecialVariables.Action.RunOnServer] === "true";
        let summary;
        if (this.props.workerPool || this.props.workerPoolVariable) {
            const poolDescription = this.props.workerPoolVariable ? (<>
                    Run a script on a worker from the pool selected via the <strong>{this.props.workerPoolVariable}</strong> variable
                </>) : (<>
                    Run a script on a worker from the <WorkerPoolChip workerPoolName={this.props.workerPool!.Name} workerPoolType={this.props.workerPool!.WorkerPoolType}/> pool
                </>);
            summary = (<>
                    {poolDescription}
                    {this.props.targetRolesAsCSV && (<>
                            {" "}
                            on behalf of targets with <Roles rolesAsCSV={this.props.targetRolesAsCSV}/>
                        </>)}
                </>);
        }
        else {
            summary = runsOnServer ? (<>
                    Run a script on the Octopus Server
                    {this.props.targetRolesAsCSV && (<>
                            {" "}
                            on behalf of targets with <Roles rolesAsCSV={this.props.targetRolesAsCSV}/>
                        </>)}
                </>) : (<>
                    Run a script{" "}
                    {this.props.targetRolesAsCSV ? (<>
                            across targets with <Roles rolesAsCSV={this.props.targetRolesAsCSV}/>
                        </>) : (<>on the deployment target</>)}
                </>);
        }
        const packageContainingScript = GetPrimaryPackageReference(this.props.packages);
        const gitRepositoryContainingScript = this.props.properties["Octopus.Action.Script.ScriptSource"] === "GitRepository";
        const packageReferences = GetNamedPackageReferences(this.props.packages!);
        if (!!packageContainingScript) {
            summary = (<>
                    {summary}
                    <div>
                        {" "}
                        <Tooltip content={<>{this.props.properties["Octopus.Action.Script.ScriptParameters"] || "No script arguments specified"}</>}>
                            <strong>{this.props.properties["Octopus.Action.Script.ScriptFileName"] || "Script"}</strong>
                        </Tooltip>{" "}
                        contained in package {this.packageAndFeed(packageContainingScript)}
                        {packageReferences.length > 0 ? <span> and {this.packageReferenceSummary(packageReferences)}</span> : ""}
                    </div>
                </>);
        }
        else if (gitRepositoryContainingScript) {
            summary = (<>
                    {summary}
                    <div>
                        {" "}
                        <Tooltip content={<>{this.props.properties["Octopus.Action.Script.ScriptParameters"] || "No script arguments specified"}</>}>
                            <strong>{this.props.properties["Octopus.Action.Script.ScriptFileName"] || "Script"}</strong>
                        </Tooltip>{" "}
                        from a Git repository
                        {packageReferences.length > 0 ? <span> and {this.packageReferenceSummary(packageReferences)}</span> : ""}
                    </div>
                </>);
        }
        else {
            if (packageReferences.length > 0) {
                summary = (<div>
                        {summary}. Script {this.packageReferenceSummary(packageReferences)}.
                    </div>);
            }
        }
        return <div>{summary}</div>;
    }
    packageReferenceSummary(packageReferences: PackageReference[]) {
        if (packageReferences.length === 0) {
            return null;
        }
        if (packageReferences.length === 1) {
            return <span>references package {this.packageAndFeed(packageReferences[0])}</span>;
        }
        if (packageReferences.length < 3) {
            return (<>
                    <span>references packages:</span>
                    {packageReferences.map((pkg) => (<div key={pkg.Id}>{this.packageAndFeed(pkg)}</div>))}
                </>);
        }
        return <span>references {packageReferences.length} packages</span>;
    }
    packageAndFeed(pkg: PackageReference) {
        return (<span>
                <strong> {pkg.PackageId} </strong> {"from"} <DisplayFeedName pkg={pkg}/>
            </span>);
    }
    static displayName = "ScriptActionSummary";
}
interface ScriptActionEditState {
    originalStepPackageRequirement: PackageRequirement;
    editPackageReference: ScriptPackageReference | null;
    editPackageReferenceIndex: number | null;
    serverNames: string[];
}
type ScriptActionEditProps = ActionEditProps<ScriptProperties, ScriptPackageProperties> & {
    supportedLanguages?: SupportedLanguage;
};
type ScriptActionEditInternalProps = ScriptActionEditProps & ActionWithFeeds & WithScriptActionContextInjectedProps & ActionWithGitRepositorySource & ActionWithDirtyState;
class PackageReferenceList extends RemoveItemsList<ScriptPackageReference> {
}
function ScriptFilePathExample(props: {
    isProjectGitRepositorySourced: boolean;
}) {
    if (props.isProjectGitRepositorySourced) {
        return (<Note>
                The path to the script file relative to the root of the Git repository.
                <br />
                Use the <ExternalLink href={"https://oc.to/SystemVariables"}>System Variable</ExternalLink> to reference the directory that stores this project's configuration.
                <br />
                e.g. <code>#&#123;Octopus.Project.Git.BasePath&#125;/Scripts/MyScript.sh</code>
            </Note>);
    }
    else {
        return (<Note>
                The path to the script file relative to the root of the Git repository.
                <br />
                e.g. <code>Scripts/MyScript.sh</code>
            </Note>);
    }
}
// Note: this is also used in the Azure PowerShell step and the ServiceFabric PowerShell step
class ScriptActionEditInternal extends BaseComponent<ScriptActionEditInternalProps, ScriptActionEditState> {
    public static defaultProps: Partial<ScriptActionEditInternalProps> = {
        supportedLanguages: SupportedLanguage.All,
    };
    constructor(props: ScriptActionEditInternalProps) {
        super(props);
        const packageAcquisition = props.additionalActions && props.additionalActions.packageAcquisition;
        this.state = {
            serverNames: [],
            originalStepPackageRequirement: packageAcquisition ? packageAcquisition.stepPackageRequirement! : PackageRequirement.LetOctopusDecide,
            editPackageReference: null,
            editPackageReferenceIndex: null,
        };
    }
    async componentDidMount() {
        if (!this.props.properties["Octopus.Action.Script.ScriptSource"]) {
            this.handleScriptSourceChanged("Inline", this.props.feeds, true);
        }
        return this.props.doBusyTask(async () => {
            this.setState({
                serverNames: this.props.scriptActionContext ? await this.props.scriptActionContext.loadVariables() : [],
            });
        });
    }
    handleScriptSourceChanged(val: string, feeds: FeedResource[], initialise: boolean = false) {
        const isRunFromPackage = val === "Package";
        const isRunFromGitSource = val === "GitRepository";
        const isInline = val === "Inline";
        const properties: ActionProperties = { ["Octopus.Action.Script.ScriptSource"]: val };
        if (!isInline) {
            if (this.props.properties["Octopus.Action.Script.Syntax"]) {
                properties["Octopus.Action.Script.Syntax"] = null;
            }
            if (this.props.properties["Octopus.Action.Script.ScriptBody"]) {
                properties["Octopus.Action.Script.ScriptBody"] = null;
            }
        }
        if (!isRunFromPackage) {
            this.props.setPackages(RemovePrimaryPackageReference(this.props.packages), initialise);
        }
        if (!isRunFromGitSource) {
            properties["Octopus.Action.GitRepository.Source"] = null;
            this.props.setGitDependencies?.(RemovePrimaryGitDependency(this.props.gitDependencies), false);
        }
        if (isInline) {
            properties["Octopus.Action.Script.ScriptParameters"] = null;
            if (!this.props.properties["Octopus.Action.Script.Syntax"]) {
                properties["Octopus.Action.Script.Syntax"] = ScriptingLanguage.PowerShell;
            }
            if (this.props.properties["Octopus.Action.Script.ScriptFileName"]) {
                properties["Octopus.Action.Script.ScriptFileName"] = null;
            }
        }
        if (isRunFromPackage) {
            this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, feeds), initialise);
        }
        if (isRunFromGitSource) {
            const supportsProjectRepository = isProjectRepositoryGitSourceSupported(this.props.project?.PersistenceSettings, this.props.processType);
            if (supportsProjectRepository) {
                properties["Octopus.Action.GitRepository.Source"] = "Project";
                this.props.setGitDependencies?.(RemovePrimaryGitDependency(this.props.gitDependencies), false);
            }
            else {
                properties["Octopus.Action.GitRepository.Source"] = "External";
                this.props.setGitDependencies?.(InitialisePrimaryGitDependency(this.props.gitDependencies), false);
            }
        }
        this.props.setProperties(properties, initialise);
        // if we are pulling the script from a package we might need to override things
        // if the user has set this step to run before package acquisition
        if (this.props.additionalActions) {
            if (this.state.originalStepPackageRequirement === PackageRequirement.BeforePackageAcquisition) {
                const newValue = isRunFromPackage ? PackageRequirement.LetOctopusDecide : this.state.originalStepPackageRequirement;
                this.props.additionalActions.packageAcquisition.onStepPackageRequirementChanged(newValue);
            }
        }
    }
    scriptSourceIsPackage() {
        return this.props.properties["Octopus.Action.Script.ScriptSource"] && this.props.properties["Octopus.Action.Script.ScriptSource"] === "Package";
    }
    scriptSourceSummary() {
        if (this.props.properties["Octopus.Action.Script.ScriptSource"] === "Inline") {
            return Summary.default("The script is defined below");
        }
        else if (this.props.properties["Octopus.Action.Script.ScriptSource"] == "Package") {
            return Summary.summary("The script is contained in a package");
        }
        else if (this.props.properties["Octopus.Action.Script.ScriptSource"] == "GitRepository") {
            return Summary.summary("The script is contained in a Git repository");
        }
    }
    scriptBodySummary() {
        if (!this.props.properties["Octopus.Action.Script.ScriptBody"]) {
            return Summary.placeholder("The script body has not been defined");
        }
        if (this.isPowershellOnly()) {
            return Summary.summary("The script body has been defined");
        }
        const syntax = this.props.properties["Octopus.Action.Script.Syntax"];
        if (syntax === ScriptingLanguage.FSharp) {
            return Summary.summary(<span>
                    An <strong>F#</strong> script has been defined
                </span>);
        }
        else if (syntax === ScriptingLanguage.CSharp) {
            return Summary.summary(<span>
                    A <strong>C#</strong> script has been defined
                </span>);
        }
        return Summary.summary(<span>
                A <strong>{syntax}</strong> script has been defined
            </span>);
    }
    scriptFileFromPackageSummary() {
        const packageContainingScript = GetPrimaryPackageReference(this.props.packages);
        const fileName = this.props.properties["Octopus.Action.Script.ScriptFileName"];
        if (!packageContainingScript || !packageContainingScript.PackageId) {
            return Summary.placeholder("Select the package containing the script");
        }
        if (!fileName) {
            return Summary.placeholder("Enter the path to the script file");
        }
        return Summary.summary(<ProcessFeedLookup feedId={packageContainingScript.FeedId}>
                {(feed) => (<span>
                        <strong>{fileName}</strong> in package <strong>{packageContainingScript.PackageId}</strong>{" "}
                        {packageContainingScript.Version && (<React.Fragment>
                                version <strong>{packageContainingScript.Version}</strong>
                            </React.Fragment>)}{" "}
                        from feed <strong>{!!feed ? feed.Name : packageContainingScript.FeedId}</strong>
                    </span>)}
            </ProcessFeedLookup>);
    }
    gitRepositorySourceSummary() {
        if (this.props.properties["Octopus.Action.GitRepository.Source"] == "Project") {
            return Summary.summary("The repository is configured in version control settings");
        }
        else {
            return Summary.summary("The repository is configured below");
        }
    }
    handleGitRepositorySourceChanged(val: string, initialise: boolean = false) {
        const properties: Partial<ScriptProperties> = { ["Octopus.Action.GitRepository.Source"]: val };
        this.props.setProperties(properties, initialise);
        if (val === "Project") {
            this.props.setGitDependencies?.(RemovePrimaryGitDependency(this.props.gitDependencies), false);
        }
        else {
            this.props.setGitDependencies?.(InitialisePrimaryGitDependency(this.props.gitDependencies), false);
        }
    }
    render() {
        const properties = this.props.properties;
        const runOnServer = this.props.properties && this.props.properties["Octopus.Action.RunOnServer"] ? this.props.properties["Octopus.Action.RunOnServer"].toLowerCase() === "true" : false;
        const localNames = _.concat(this.props.localNames ? this.props.localNames : [], this.packageVariableNames());
        const parameters = this.props.parameters || [];
        const editPackageReferenceDialog = (<DialogOpener open={!!this.state.editPackageReference} onClose={this.resetSelectedPackageReference}>
                <ScriptPackageReferenceDialog packageReference={this.state.editPackageReference!} runOn={this.props.runOn} feeds={this.props.feeds} localNames={localNames} projectId={this.props.projectId!} onChange={(packageReference) => this.savePackageReference(packageReference)} refreshFeeds={this.loadFeeds} parameters={parameters}/>
            </DialogOpener>);
        return (<div>
                {editPackageReferenceDialog}
                <FormSectionHeading title="Script"/>
                <ExpandableFormSection errorKey="scriptsource" isExpandedByDefault={this.props.expandedByDefault} title="Script Source" summary={this.scriptSourceSummary()} help="Select where the script is defined.">
                    <ScriptSourceSelectorComponent accessibleName={"Select where the script is defined"} scriptSourceValue={properties["Octopus.Action.Script.ScriptSource"]} onScriptSourceChanged={(val) => this.handleScriptSourceChanged(val, this.props.feeds)}></ScriptSourceSelectorComponent>
                </ExpandableFormSection>

                {properties["Octopus.Action.Script.ScriptSource"] === "Inline" && this.inlineScriptFormSections(runOnServer, localNames)}
                {properties["Octopus.Action.Script.ScriptSource"] === "Package" && this.packageScriptFormSections(localNames)}
                {properties["Octopus.Action.Script.ScriptSource"] === "GitRepository" && this.gitScriptFormSections(localNames)}
            </div>);
    }
    inlineScriptFormSections = (runOnServer: boolean, localNames: string[]) => {
        const properties = this.props.properties;
        const packageReferences = GetNamedPackageReferences(this.props.packages) as ScriptPackageReference[];
        const results = codeEditorVariablesList(this.state.serverNames, localNames ?? [], properties["Octopus.Action.Script.Syntax"], "");
        const supportedLanguages = this.props.supportedLanguages === SupportedLanguage.All
            ? [ScriptingLanguage.PowerShell, ScriptingLanguage.Bash, ScriptingLanguage.CSharp, ScriptingLanguage.FSharp, ScriptingLanguage.Python]
            : this.props.supportedLanguages === SupportedLanguage.PowerShellAndBash
                ? [ScriptingLanguage.PowerShell, ScriptingLanguage.Bash]
                : this.props.supportedLanguages === SupportedLanguage.PowerShell
                    ? [ScriptingLanguage.PowerShell]
                    : [ScriptingLanguage.PowerShell];
        return (<div>
                <ExpandableFormSection isExpandedByDefault={this.props.expandedByDefault} errorKey="Octopus.Action.Script.ScriptBody" title="Inline Source Code" fillCardWidth={CardFill.FillRight} summary={this.scriptBodySummary()} help={this.isPowershellOnly() ? "Enter the body of the script that will be executed." : "Select the script language and enter the body of the script that will be executed."} contextualHelp={<InlineScriptEditorContextualHelp isPowerShellOnly={this.isPowershellOnly()} syntax={properties["Octopus.Action.Script.Syntax"] as ScriptingLanguage}/>}>
                    {packageReferences.length > 0 && (<Note>
                            There are referenced packages. See the <ExternalLink href="ScriptStepPackageReferencesFromCustomScripts">documentation</ExternalLink> for information on consuming packages from your scripts.
                        </Note>)}

                    <CodeEditor localNames={localNames} autoComplete={results} syntax={properties["Octopus.Action.Script.Syntax"]} value={properties["Octopus.Action.Script.ScriptBody"]} language={properties["Octopus.Action.Script.Syntax"]} allowFullScreen={true} autoExpand={true} onChange={(value) => this.props.setProperties({ ["Octopus.Action.Script.ScriptBody"]: value })} scriptingLanguageSelectorOptions={{
                supportedLanguages: supportedLanguages,
                onScriptingLanguageChanged: (syntax: ScriptingLanguage | Language | TextFormat) => {
                    this.props.setProperties({ ["Octopus.Action.Script.Syntax"]: syntax as ScriptingLanguage });
                },
            }} showToolbar={true} showCopyButton={true} showInsertVariableButton={true}/>
                </ExpandableFormSection>
                {this.packagesFormSection()}
            </div>);
    };
    gitScriptFormSections = (localNames: string[]) => {
        const primaryGitDependencyFromProps = this.props.gitDependencies?.find((r) => r.Name === "");
        return (<>
                <GitRepositorySourceSelector properties={this.props.properties} gitDependencies={this.props.gitDependencies} expandedByDefault={this.props.expandedByDefault} getFieldError={this.props.getFieldError} setProperties={this.props.setProperties} setGitDependencies={this.props.setGitDependencies} gitCredentials={this.props.gitCredentials} processType={this.props.processType} project={this.props.project} refreshGitCredentials={this.props.refreshGitCredentials} localNames={this.props.localNames} relatedTriggerDependencies={buildRelatedTriggerDependencies(this.props.inputDependencies)} showAvailableTriggerCallout={!this.props.isNew}/>
                <ExpandableFormSection errorKey="Filename" title="Script File Path" summary={this.props.properties["Octopus.Action.Script.ScriptFileName"] ? Summary.summary(this.props.properties["Octopus.Action.Script.ScriptFileName"]) : Summary.placeholder("The path to the script file to use")} help={"Provide the path to your script file."} isExpandedByDefault={this.props.expandedByDefault}>
                    <VariableLookupText localNames={localNames} value={this.props.properties["Octopus.Action.Script.ScriptFileName"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.Script.ScriptFileName"]: x })} error={this.props.getFieldError("Octopus.Action.Script.ScriptFileName")} label="Script file path"/>
                    <ScriptFilePathExample isProjectGitRepositorySourced={this.props.properties["Octopus.Action.GitRepository.Source"] === "Project"}/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="scriptParameters" title="Script Parameters" summary={this.props.properties["Octopus.Action.Script.ScriptParameters"] ? Summary.summary("Script has parameters defined") : Summary.placeholder("No parameters defined")} help={"Provide the parameters expected by the script."} isExpandedByDefault={this.props.expandedByDefault}>
                    <VariableLookupText localNames={localNames} value={this.props.properties["Octopus.Action.Script.ScriptParameters"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.Script.ScriptParameters"]: x })} error={this.props.getFieldError("Octopus.Action.Script.ScriptParameters")} multiline={true} label="Script parameters"/>
                    {this.isPowershellOnly() ? (<Note>
                            Parameters expected by the script. Use platform specific calling convention.
                            <br />
                            e.g. <code>-Path #{`{VariableStoringPath}`}</code> for PowerShell
                        </Note>) : (<Note>
                            Parameters expected by the script. Use platform specific calling convention.
                            <br />
                            e.g. <code>-Path #{`{VariableStoringPath}`}</code> for PowerShell or <code>#{`{VariableStoringPath}`}</code> for bash
                        </Note>)}
                </ExpandableFormSection>
                {this.packagesFormSection()}
            </>);
    };
    packageScriptFormSections = (localNamesWithPackageVariables: string[]) => {
        const properties = this.props.properties;
        const primaryPackage = GetPrimaryPackageReference(this.props.packages);
        const primaryPackageFeed = primaryPackage ? _.find(this.props.feeds, (f) => f.Id === primaryPackage.FeedId) : null;
        return (<div>
                <ExpandableFormSection errorKey="Octopus.Action.Package.FeedId|Octopus.Action.Package.PackageId|Octopus.Action.Script.ScriptFileName|Octopus.Action.Script.ScriptParameters" isExpandedByDefault={this.props.expandedByDefault} title="Script File in Package" summary={this.scriptFileFromPackageSummary()} help={this.scriptInPackageHelp()}>
                    <PackageSelector packageId={primaryPackage?.PackageId} feedId={primaryPackage?.FeedId} packageVersion={primaryPackage?.Version} onPackageIdChange={(packageId) => this.props.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, this.props.packages))} onFeedIdChange={(feedId) => this.props.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, this.props.packages))} onPackageVersionChange={(version) => this.props.setPackages(SetPrimaryPackageReference({ Version: version }, this.props.packages))} packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")} feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")} projectId={this.props.projectId} feeds={this.props.feeds} localNames={this.props.localNames} refreshFeeds={this.loadFeeds} relatedTriggersDependencies={buildRelatedTriggerDependencies(this.props.inputDependencies)} modelDirty={this.props.modelDirty}/>
                    <PackageDownloadOptions packageAcquisitionLocation={primaryPackage.AcquisitionLocation} onPackageAcquisitionLocationChanged={(acquisitionLocation) => this.props.setPackages(SetPrimaryPackageReference({ AcquisitionLocation: acquisitionLocation }, this.props.packages))} feed={primaryPackageFeed!} runOn={this.props.runOn} projectId={this.props.projectId} localNames={this.props.localNames}/>
                    <VariableLookupText localNames={localNamesWithPackageVariables} value={properties["Octopus.Action.Script.ScriptFileName"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.Script.ScriptFileName"]: x })} error={this.props.getFieldError("Octopus.Action.Script.ScriptFileName")} label="Script file name"/>
                    <Note>
                        The relative path to the script file within the package.
                        <br />
                        e.g. <code>MyScript.ps1</code> or <code>Scripts\MyScript.ps1</code>
                    </Note>
                    <VariableLookupText localNames={localNamesWithPackageVariables} value={properties["Octopus.Action.Script.ScriptParameters"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.Script.ScriptParameters"]: x })} error={this.props.getFieldError("Octopus.Action.Script.ScriptParameters")} multiline={true} label="Script parameters"/>
                    {this.isPowershellOnly() ? (<Note>
                            Parameters expected by the script. Use platform specific calling convention.
                            <br />
                            e.g. <code>-Path #{`{VariableStoringPath}`}</code> for PowerShell
                        </Note>) : (<Note>
                            Parameters expected by the script. Use platform specific calling convention.
                            <br />
                            e.g. <code>-Path #{`{VariableStoringPath}`}</code> for PowerShell or <code>#{`{VariableStoringPath}`}</code> for bash
                        </Note>)}
                </ExpandableFormSection>
                {this.packagesFormSection()}
            </div>);
    };
    packagesFormSection = () => {
        const packageReferences = GetNamedPackageReferences(this.props.packages) as ScriptPackageReference[];
        const relatedTriggersDependencies = buildRelatedTriggerDependencies(this.props.inputDependencies);
        return (<ExpandableFormSection title={this.scriptSourceIsPackage() ? "Additional Packages" : "Referenced Packages"} isExpandedByDefault={this.props.expandedByDefault} errorKey="Octopus.Action.Script.Packages" summary={this.packageReferenceSummary()} help={this.scriptSourceIsPackage() ? "Add additional package references" : "Add packages to be referenced by your scripts at execution-time"}>
                <Note>
                    Learn more about <ExternalLink href="ScriptStepPackageReferences">package references</ExternalLink>.
                </Note>
                <PackageReferenceList listActions={[<ActionButton key="add" label="Add" onClick={() => this.addPackageReference()}/>]} data={packageReferences} onRow={(p) => this.packageReferenceListItem(p)} onRowTouch={(pkg) => this.editPackageReference(pkg)} onRemoveRow={(pkg) => this.removePackageReference(pkg)}/>

                {relatedTriggersDependencies && <CalloutReferencedPackageFeedTriggerConnections dependencies={relatedTriggersDependencies} packageReferences={packageReferences}/>}
                {relatedTriggersDependencies && <CalloutModifiedFeedTriggerConnections dependencies={relatedTriggersDependencies} packageReferences={packageReferences} modelDirty={this.props.modelDirty ?? false}/>}
            </ExpandableFormSection>);
    };
    packageReferenceSummary = () => {
        const namedPackageReferences = GetNamedPackageReferences(this.props.packages);
        const scriptSourceIsPackage = this.scriptSourceIsPackage();
        if (namedPackageReferences.length === 0) {
            return Summary.placeholder(scriptSourceIsPackage ? "No additional packages referenced" : "No packages referenced");
        }
        return Summary.summary(`${namedPackageReferences.length} package references`);
    };
    addPackageReference = () => {
        const additionalPackage: ScriptPackageReference = {
            Id: null!,
            Name: null!,
            FeedId: null!,
            PackageId: null!,
            AcquisitionLocation: PackageAcquisitionLocation.Server,
            Properties: { Extract: "True" },
        };
        this.setState({ editPackageReference: additionalPackage, editPackageReferenceIndex: null });
    };
    editPackageReference = (pkg: ScriptPackageReference) => {
        this.setState({
            editPackageReference: _.clone(pkg),
            editPackageReferenceIndex: this.props.packages.indexOf(pkg),
        });
    };
    removePackageReference = (pkg: ScriptPackageReference) => {
        const packages = [...this.props.packages];
        packages.splice(packages.indexOf(pkg), 1);
        this.props.setPackages(packages);
    };
    packageReferenceListItem = (pkg: ScriptPackageReference) => {
        return (<div>
                <ListTitle>{pkg.Name}</ListTitle>
                <div>
                    {pkg.Properties["SelectionMode"] === PackageSelectionMode.Immediate && (<ProcessFeedLookup feedId={pkg.FeedId}>
                            {(feed) => (<React.Fragment>
                                    <strong>{pkg.PackageId}</strong>{" "}
                                    {pkg.Version && (<React.Fragment>
                                            version <strong>{pkg.Version}</strong>
                                        </React.Fragment>)}{" "}
                                    from feed <strong>{!!feed ? feed.Name : pkg.FeedId}</strong>
                                </React.Fragment>)}
                        </ProcessFeedLookup>)}
                    {pkg.Properties["SelectionMode"] === PackageSelectionMode.Deferred && <React.Fragment>Package will be selected by the project</React.Fragment>}
                    <Note>
                        Extracted: <strong>{pkg.Properties.Extract}</strong>
                    </Note>
                </div>
            </div>);
    };
    savePackageReference(packageReference: ScriptPackageReference) {
        const packageReferences = [...this.props.packages];
        if (this.state.editPackageReferenceIndex === null) {
            packageReferences.push(packageReference);
        }
        else {
            packageReferences[this.state.editPackageReferenceIndex] = packageReference;
        }
        this.props.setPackages(packageReferences);
        this.resetSelectedPackageReference();
        return true;
    }
    resetSelectedPackageReference = () => {
        this.setState({
            editPackageReference: null,
            editPackageReferenceIndex: null,
        });
    };
    packageVariableNames = (): string[] => {
        return _.flatten(GetNamedPackageReferences(this.props.packages).map((pkg) => GetPackageVariableNames(pkg, this.props.feeds)));
    };
    private isPowershellOnly = () => {
        return this.props.supportedLanguages === SupportedLanguage.PowerShell;
    };
    private loadFeeds = async () => {
        await this.props.refreshFeeds();
    };
    private scriptInPackageHelp = () => {
        return <span>Select the package containing your script.</span>;
    };
    static displayName = "ScriptActionEditInternal";
}
export function ScriptActionEdit(props: React.PropsWithChildren<ScriptActionEditProps>) {
    const feeds = useFeedsFromContext();
    const refreshFeeds = useRefreshFeedsFromContext();
    const scriptActionContext = useScriptActionContext();
    const gitCredentials = useGitCredentialsFromContext();
    const refreshGitCredentials = useRefreshGitCredentialsFromContext();
    const projectContext = useOptionalProjectContext();
    const processContext = useOptionalProcessContext();
    return (<ScriptActionEditInternal {...props} feeds={feeds} refreshFeeds={refreshFeeds} scriptActionContext={scriptActionContext} project={projectContext?.state.model} processType={processContext?.selectors.getProcessType()} gitCredentials={gitCredentials} refreshGitCredentials={refreshGitCredentials} modelDirty={!_.isEqual(processContext?.state.model, processContext?.state.cleanModel)}/>);
}
pluginRegistry.registerAction({
    actionType: "Octopus.Script",
    summary: (properties, targetRolesAsCSV, packages, workerPool, workerPoolVariable, gitDependencies) => (<ScriptActionSummarySelector properties={properties} targetRolesAsCSV={targetRolesAsCSV} packages={packages} workerPool={workerPool} workerPoolVariable={workerPoolVariable} gitDependencies={gitDependencies}/>),
    editSections: {
        default: () => <></>,
        top: ScriptActionEdit,
    },
    canHaveChildren: (step) => !!step.Properties[SpecialVariables.Action.TargetRoles],
    canBeChild: true,
    executionLocation: ActionExecutionLocation.TargetOrServer,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => {
        return action.Properties && action.Properties["Octopus.Action.Script.ScriptSource"] === "Package";
    },
    getInitialProperties: () => {
        return {
            OctopusUseBundledTooling: "False",
        };
    },
    features: {
        optional: ["Octopus.Features.ConfigurationTransforms", "Octopus.Features.ConfigurationVariables", "Octopus.Features.JsonConfigurationVariables", "Octopus.Features.SubstituteInFiles", "Octopus.Features.SelectPowerShellEditionForWindows"],
    },
    preferredWorkerPool: (action) => {
        return {
            preferredOperatingSystem: action.Properties["Octopus.Action.Script.Syntax"] === ScriptingLanguage.Bash ? "Linux" : "Windows",
            requiredTools: [],
        };
    },
    docsLink: "runScript",
});
