import { XmarkIcon } from "@octopusdeploy/design-system-icons";
import { colorScales } from "@octopusdeploy/design-system-tokens";
import { ActivityLogEntryCategory, ActivityStatus } from "@octopusdeploy/octopus-server-client";
import type * as H from "history";
import * as React from "react";
import URI from "urijs";
import styles from "~/components/TaskLogLines/style.module.less";
import { withTheme } from "~/components/Theme";
export function toggleVerbose({ location, history }: {
    location: H.Location;
    history: H.History;
}, verbose: boolean) {
    const current = new URI(location.search);
    if (verbose) {
        current.setSearch({
            level: "verbose",
        });
    }
    else {
        current.removeSearch("level");
    }
    history.push({ ...location, search: current.search() });
}
export function isVerbose(props: {
    location: H.Location;
    history: H.History;
}) {
    const parsed = new URI(props.location.search).search(true);
    return parsed["level"] == "verbose";
}
export function goToLogElement({ location, history }: {
    location: H.Location;
    history: H.History;
}, elementUniqueId: string) {
    const current = new URI(location.search);
    current.setSearch({
        element: elementUniqueId,
        activeTab: "taskLog",
    });
    current.removeSearch("line");
    history.push({ ...location, search: current.search() });
}
export function goToLogLine({ location, history }: {
    location: H.Location;
    history: H.History;
}, elementUniqueId: string, line: number) {
    const current = new URI(location.search);
    current.setSearch({
        line: line,
        element: elementUniqueId,
        activeTab: "taskLog",
    });
    history.push({ ...location, search: current.search() });
}
export function clearLogLine({ location, history }: {
    location: H.Location;
    history: H.History;
}) {
    const current = new URI(location.search);
    current.removeSearch("element");
    current.removeSearch("line");
    history.push({ ...location, search: current.search() });
}
export function generateUriForElement({ location, history }: {
    location: H.Location;
    history: H.History;
}, elementUniqueId: string): string {
    const link = new URI(location.search);
    link.setSearch({
        element: elementUniqueId,
        activeTab: "taskLog",
    });
    link.removeSearch("line");
    return location.pathname + link.href();
}
export function createLinkGeneratorForElement(location: H.Location, elementUniqueId: string): (line: number) => string {
    const link = new URI(location.search);
    link.setSearch({
        line: "OCTO_LINE",
        element: elementUniqueId,
        activeTab: "taskLog",
    });
    const template = location.pathname + link.href();
    return (line) => template.replace("OCTO_LINE", "" + line);
}
export function generateUriForLine(props: {
    location: H.Location;
    history: H.History;
}, elementUniqueId: string, line: number) {
    return createLinkGeneratorForElement(props.location, elementUniqueId)(line);
}
export function toggleSelectedLine(props: {
    location: H.Location;
    history: H.History;
}, elementUniqueId: string, line?: number) {
    if (!line) {
        clearLogLine(props);
    }
    else if (isSelected(props, elementUniqueId, line)) {
        clearLogLine(props);
    }
    else {
        goToLogLine(props, elementUniqueId, line);
    }
}
export function isSelected(props: {
    location: H.Location;
    history: H.History;
}, elementUniqueId: string, line: number) {
    const selected = getSelectedLogLine(props);
    return selected && elementUniqueId == selected.elementUniqueId && line == selected.line;
}
export function getSelectedLogLine({ location, history }: {
    location: H.Location;
    history: H.History;
}): {
    elementUniqueId: string;
    line: number;
} | null {
    const parsed = new URI(location.search).search(true);
    const id = parsed["element"];
    const line = parseInt(parsed["line"]);
    if (!id)
        return null;
    return {
        elementUniqueId: id,
        line: isNaN(line) ? 0 : line,
    };
}
export function activityElementIdFromUniqueId(uniqueId: string) {
    return uniqueId.substring(uniqueId.indexOf("/") + 1);
}
export function activityElementUniqueIdFromId(id: string, n: number) {
    return `${n}/${id}`;
}
export function getActivityElementClass(category: ActivityLogEntryCategory) {
    switch (category) {
        case ActivityLogEntryCategory.Fatal:
        case ActivityLogEntryCategory.Error:
            return styles.elementError;
        case ActivityLogEntryCategory.Warning:
            return styles.elementWarning;
        case ActivityLogEntryCategory.Highlight:
            return styles.elementHighlight;
        case ActivityLogEntryCategory.Wait:
            return styles.elementWait;
        case ActivityLogEntryCategory.Verbose:
            return styles.elementVerbose;
        case ActivityLogEntryCategory.Retry:
            return styles.elementRetry;
        default:
            return styles.elementInfo;
    }
}
export function getStatusIcon(nodeStatus: string) {
    return withTheme((theme) => {
        switch (nodeStatus) {
            case ActivityStatus.Skipped:
                return (<span aria-label="Skipped Status" className="fa-stack" style={{ fontSize: "0.725em" }}>
                        <i className="fa-solid fa-circle fa-stack-2x" style={{ color: colorScales.grey["500"] }}></i>
                        <i className="fa-solid fa-minus fa-stack-1x fa-inverse"></i>
                    </span>);
            case ActivityStatus.SuccessWithWarning:
                return (<span aria-label="Success with Warnings Status" className="fa-stack" style={{ fontSize: "0.725em" }}>
                        <i className="fa-solid fa-circle fa-stack-2x" style={{ color: colorScales.green["500"] }}></i>
                        <i className="fa-solid fa-exclamation-triangle fa-stack-1x fa-inverse" style={{ color: "#fff" }}></i>
                    </span>);
            case ActivityStatus.Failed:
                return (<span aria-label="Failed Status" className="fa-stack" style={{ fontSize: "0.725em" }}>
                        <i className="fa-solid fa-circle fa-stack-2x" style={{ color: colorScales.red["500"] }}></i>
                        <i className="fa-solid fa-xmark fa-stack-1x fa-inverse"></i>
                    </span>);
            case ActivityStatus.Running:
            case "InProgress":
                return (<span aria-label="Running Status" className="fa-stack" style={{ fontSize: "0.725em" }}>
                        <i className="fa-solid fa-circle fa-stack-2x" style={{ color: colorScales.blue["500"] }}></i>
                        <i className="fa-solid fa-spinner fa-pulse fa-stack-1x fa-inverse"></i>
                    </span>);
            case ActivityStatus.Pending:
                return (<span aria-label="Pending Status" className="fa-stack" style={{ fontSize: "0.725em" }}>
                        <i className="fa-solid fa-circle fa-stack-2x" style={{ color: colorScales.grey["500"] }}></i>
                        <i className="fa-solid fa-hourglass-empty fa-stack-1x fa-inverse"></i>
                    </span>);
            case ActivityStatus.Success:
            case "Successful":
                return (<span aria-label="Success Status" className="fa-stack" style={{ fontSize: "0.725em" }}>
                        <i className="fa-solid fa-circle fa-stack-2x" style={{ color: colorScales.green["500"] }}></i>
                        <i className="fa-solid fa-check fa-stack-1x fa-inverse"></i>
                    </span>);
            case ActivityStatus.Canceled:
                return (<span aria-label="Canceled Status" className="fa-stack" style={{ fontSize: "0.725em" }}>
                        <i className="fa-solid fa-circle fa-stack-2x" style={{ color: colorScales.red["500"] }}></i>
                        <i className="fa-solid fa-xmark fa-stack-1x fa-inverse"></i>
                    </span>);
            default:
                return <XmarkIcon size={16}/>;
        }
    });
}
