/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { List } from "@octopusdeploy/design-system-components";
import cn from "classnames";
import * as React from "react";
import ActionList from "~/components/ActionList";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import BorderedListItem, { ListItemContentWithButtons } from "~/components/BorderedListItem";
import { IconButtonWithOptionalTooltip } from "~/components/IconButtonWithTooltip";
import styles from "./style.module.less";
interface RemoveItemsListProps<R> {
    empty?: React.ReactNode;
    data: R[];
    clearButtonToolTip?: string;
    listActions?: React.ReactElement[];
    onRow(item: R, idx: number): React.ReactNode;
    onRowTouch?(item: R): void;
    onRemoveRow?(item: R): void;
    removeButtonAccessibleName?(item: R, index: number): string;
    clearButtonAlignment?: "top" | "center";
    accessibleName?: string;
}
export abstract class RemoveItemsList<R> extends BaseComponent<RemoveItemsListProps<R>, {}> {
    private bottomActionVisibilityThreshold = 3;
    constructor(props: RemoveItemsListProps<R>) {
        super(props);
    }
    render() {
        const actions = (this.props.listActions || []).map((action, index) => React.cloneElement(action, { key: index }));
        const actionList = <ActionList actions={actions}/>;
        const renderLowerAction = this.props.data.length < this.bottomActionVisibilityThreshold;
        return (<div>
                {actionList}
                <List includeRowGaps includeVerticalPadding empty={<div className={styles.emptyList}>{this.props.empty}</div>} items={this.props.data} renderRow={({ item, index }) => this.buildItem(item, index)} accessibleName={this.props.accessibleName}/>
                <div className={cn({ [styles.hidden]: renderLowerAction, [styles.lowerActionPadding]: !renderLowerAction })}>{actionList}</div>
            </div>);
    }
    private buildItem(item: R, index: number) {
        const clearButton = this.props.onRemoveRow ? (<IconButtonWithOptionalTooltip toolTipContent={this.props.clearButtonToolTip} onClick={(e) => {
                e.stopPropagation();
                this.props.onRemoveRow!(item);
            }} icon={"XmarkIcon"} accessibleName={this.props.removeButtonAccessibleName === undefined ? "Cancel" : this.props.removeButtonAccessibleName(item, index)}/>) : null;
        return (<BorderedListItem key={this.getKey(item, index)} onClick={this.props.onRowTouch ? () => this.onTouch(item) : undefined}>
                <ListItemContentWithButtons content={this.props.onRow(item, index)} buttons={clearButton} buttonAlignment={this.props.clearButtonAlignment}/>
            </BorderedListItem>);
    }
    private getKey(item: R | {
        Id: number;
    }, index: number): string | number {
        const converted = item as object;
        return converted && converted.hasOwnProperty("Id") ? (item as {
            Id: number;
        }).Id : index;
    }
    private onTouch(item: R) {
        if (this.props.onRowTouch) {
            this.props.onRowTouch(item);
        }
    }
    static displayName = "RemoveItemsList";
}
