/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import { ExternalLink, PopoverBasicHelp } from "@octopusdeploy/design-system-components";
import { borderRadius, fontSize, fontWeight, letterSpacing, lineHeight, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { UsernamePasswordGitCredentialDetailsResource, GitCredentialResource, GitCredentialUsage, GitCredentialUsageProject, CreateGitCredentialResponse } from "@octopusdeploy/octopus-server-client";
import { GitCredentialAuthenticationType, Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { cloneDeep, sortBy } from "lodash";
import type { ReactNode } from "react";
import * as React from "react";
import type { ActionEvent, AnalyticTrackedActionDispatcher, AnalyticViewDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticViewDispatch, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import type { Errors } from "~/components/DataBaseComponent";
import type { FormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import { FormBaseComponent } from "~/components/FormBaseComponent/FormBaseComponent";
import type { PrimarySaveActionOptions, PrimarySavePageAction } from "~/components/FormPaperLayout/LegacyForm";
import { LegacyForm } from "~/components/FormPaperLayout/LegacyForm";
import Markdown from "~/components/Markdown";
import InternalLink from "~/components/Navigation/InternalLink";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { ConvertedOverflowMenuItems } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import { OverflowMenuConverterVNext } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import { PageContent } from "~/components/PageContent/PageContent";
import SimpleDataTable from "~/components/SimpleDataTable";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { Text, ExpandableFormSection, Summary, required, MarkdownEditor, Sensitive, Note } from "~/components/form";
import { DataTable, DataTableBody, DataTableHeader, DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import { TabItem, UrlNavigationTabsContainer } from "~/primitiveComponents/navigation/Tabs";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import StringHelper from "~/utils/StringHelper";
interface CreateGitCredentialPageProps {
    create: true;
}
interface SpecificGitCredentialPageProps {
    gitCredentialId: string;
}
type GitCredentialPageProps = (CreateGitCredentialPageProps | SpecificGitCredentialPageProps) & {
    spaceId: string;
};
type GitCredentialPropsInternal = (CreateGitCredentialPageProps | SpecificGitCredentialPageProps) & {
    spaceId: string;
    trackAction: AnalyticTrackedActionDispatcher;
    dispatchView: AnalyticViewDispatcher;
    onSave: (gitCredential: GitCredentialResource, isNew: boolean) => Promise<void>;
    onDelete: () => Promise<void>;
    resetOnSave?: boolean;
    children: (props: {
        formContent: ReactNode;
        createSaveAction: (options: PrimarySaveActionOptions) => PrimarySavePageAction;
        busy: Promise<void> | undefined;
        errors: Errors | undefined;
        title: string;
        overflowActions: ConvertedOverflowMenuItems;
    }) => ReactNode;
};
interface GitCredentialState extends FormBaseComponentState<GitCredentialResource> {
    deleted: boolean;
    newId?: string;
    usage?: GitCredentialUsage;
}
export class GitCredentialPageInternal extends FormBaseComponent<GitCredentialPropsInternal, GitCredentialState, GitCredentialResource> {
    constructor(props: GitCredentialPropsInternal) {
        super(props);
        this.state = {
            model: this.getEmptyModel(),
            cleanModel: this.getEmptyModel(),
            deleted: false,
            usage: undefined,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(() => this.load(), { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    private resetModel() {
        this.setState({
            model: this.getEmptyModel(),
            cleanModel: this.getEmptyModel(),
            deleted: false,
            usage: undefined,
        });
    }
    private getEmptyModel(): GitCredentialResource {
        return {
            Id: null!,
            SpaceId: "",
            Name: "",
            Description: "",
            Details: {
                // This is not a great way to do this, works fine for now because there
                // is only 1 type, but maybe look at how the version control settings does
                // this and might give us some hints.
                Type: GitCredentialAuthenticationType.UsernamePassword,
                Username: null!,
                Password: null!,
            },
            Links: null!,
        };
    }
    async load() {
        if (isCreateGitCredentialPageProps(this.props)) {
            const gitCredential = this.getEmptyModel();
            this.setState({
                model: gitCredential,
                cleanModel: cloneDeep(gitCredential),
            });
        }
        else {
            const gitCredential = await repository.GitCredentials.get(this.props.gitCredentialId);
            this.setState({
                model: gitCredential,
                cleanModel: cloneDeep(gitCredential),
            });
        }
    }
    descriptionSummary() {
        return this.state.model!.Description ? Summary.summary(<Markdown markup={this.state.model!.Description}/>) : Summary.placeholder("No credential description provided");
    }
    credentialSummary() {
        return Summary.summary("Username and password");
    }
    usageSummary() {
        if (!this.state.usage) {
            return null;
        }
        const visibleCount = this.state.usage.Projects.length;
        const otherCount = this.state.usage.OtherProjects;
        const totalCount = visibleCount + otherCount;
        if (totalCount > 0) {
            return Summary.summary(<span>
                    This credential is being used in <b>{totalCount}</b> project{totalCount > 1 ? "s" : ""}
                </span>);
        }
        return Summary.placeholder("This credential is not being used in any projects");
    }
    usageHelp() {
        if (!this.state.usage) {
            return null;
        }
        const totalCount = this.state.usage.Projects.length + this.state.usage.OtherProjects;
        if (totalCount > 0) {
            return `This credential being used in the following project${totalCount > 1 ? "s" : ""}`;
        }
        return "This credential is not being used in any projects";
    }
    usageAdditionalInformation(usage?: GitCredentialUsage) {
        if (!usage) {
            return null;
        }
        if (usage.OtherProjects > 0) {
            if (usage.Projects.length > 0) {
                const single = usage.Projects.length > 1;
                const isAre = single ? "is" : "are";
                const projectProjects = single ? "project" : "projects";
                return (<p>
                        There {isAre} {usage.OtherProjects} additional {projectProjects} (that you don't have permission to see) also using this credential.
                    </p>);
            }
            return <p>There are {usage.OtherProjects} projects (that you don't have permission to see) also using this credential.</p>;
        }
        return null;
    }
    setDetailsForUsernamePassword<K extends keyof UsernamePasswordGitCredentialDetailsResource>(state: Pick<UsernamePasswordGitCredentialDetailsResource, K>) {
        this.setChildState2("model", "Details", state);
    }
    handleSave = async () => {
        await this.doBusyTask(async () => {
            const isNew = this.state.model.Id === null;
            const ev: ActionEvent = {
                action: Action.Save,
                resource: "Git Credential",
                location: "Page",
                entityType: isNew ? "New" : "Existing",
            };
            await this.props.trackAction("Save Git Credential", ev, async () => {
                let id: string | undefined = undefined;
                if (isCreateGitCredentialPageProps(this.props)) {
                    const response: CreateGitCredentialResponse = await repository.GitCredentials.create(this.state.model);
                    id = response.Id;
                }
                else {
                    await repository.GitCredentials.modify(this.state.model);
                    id = this.props.gitCredentialId;
                }
                const newModel = await repository.GitCredentials.get(id);
                if (this.props.resetOnSave) {
                    this.resetModel();
                }
                else {
                    this.setState({
                        ...this.state,
                        model: newModel,
                        cleanModel: cloneDeep(newModel),
                        newId: isNew ? id : null!,
                        deleted: false,
                    });
                }
                await this.props.onSave(newModel, isNew);
            });
        });
        return true;
    };
    handleDeleteConfirm = async () => {
        await repository.GitCredentials.del(this.state.model);
        this.setState(() => {
            return {
                model: this.getEmptyModel(),
                cleanModel: this.getEmptyModel(), //reset model so that dirty state doesn't prevent navigation
                deleted: true,
            };
        });
        await this.props.onDelete();
        return true;
    };
    loadUsage = async () => {
        if (this.state.usage || isCreateGitCredentialPageProps(this.props)) {
            return;
        }
        await this.doBusyTask(async () => {
            const usage = await repository.GitCredentials.usage(this.state.model);
            usage.Projects = sortBy(usage.Projects, (p) => p.Name);
            this.setState({
                usage,
            });
        }, { timeOperationOptions: timeOperationOptions.for("Usage") });
    };
    render() {
        const isCreate = isCreateGitCredentialPageProps(this.props);
        const title = isCreate ? "Create Git Credential" : this.state.model ? this.state.model.Name : StringHelper.ellipsis;
        const saveText = this.state.newId ? "Git credential created" : "Git credential updated";
        const overFlowActions = isCreate ? [] : [OverflowMenuItems.deleteItemDefault("Git credential", this.handleDeleteConfirm, { permission: Permission.GitCredentialEdit })];
        return (<>
                <LegacyForm model={this.state.model} cleanModel={this.state.cleanModel} onSaveClick={() => this.handleSave()} savePermission={{ permission: Permission.GitCredentialEdit }} saveText={saveText}>
                    {({ FormContent, createSaveAction }) => (<>
                            {this.props.children({
                    formContent: (<FormContent expandAllOnMount={isCreate}>
                                        <TransitionAnimation>
                                            <UrlNavigationTabsContainer defaultValue={"details"}>
                                                <TabItem label="Details" value="details" onActive={() => this.props.dispatchView("View Git Credential Details", { resource: "Git Credential" })}>
                                                    <ExpandableFormSection errorKey="Name" title="Name" focusOnExpandAll summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Enter a name for your Git credential")} help="Enter a name for your Git credential">
                                                        <Text value={this.state.model.Name} onChange={(Name) => this.setModelState({ Name })} label="Git credential name" validate={required("Enter a Git credential name")} error={this.getFieldError("Name")} autoFocus/>
                                                    </ExpandableFormSection>
                                                    <ExpandableFormSection errorKey="description" title="Description" summary={this.descriptionSummary()} help="Enter a description for your Git credential">
                                                        <MarkdownEditor value={this.state.model.Description} label="Git credential description" onChange={(Description) => this.setModelState({ Description })}/>
                                                    </ExpandableFormSection>
                                                    <ExpandableFormSection errorKey="details" title="Credentials" summary={this.credentialSummary()} help="Enter your Git credential details">
                                                        <div>
                                                            We recommend using a personal access token with limited privilege <GitCredentialPopover />
                                                        </div>
                                                        <Text key="Username" value={this.state.model.Details.Username} onChange={(Username) => this.setDetailsForUsernamePassword({ Username })} label="Username" error={this.getFieldError("Username")} validate={required("Enter authentication details.")} disabled={!!this.state.busy}/>
                                                        <Sensitive key="Password" value={this.state.model.Details.Password} onChange={(Password) => this.setDetailsForUsernamePassword({ Password })} label="Personal Access Token or Password" error={this.getFieldError("Password")} disabled={!!this.state.busy}/>
                                                        <Note>
                                                            GitLab and GitHub <b>require</b> token-based authentication (excludes Github Enterprise Server).
                                                        </Note>
                                                    </ExpandableFormSection>
                                                </TabItem>
                                                {!isCreate && (<TabItem label="Usage" value="usage" onActive={() => {
                                this.props.dispatchView("View Git Credential Usage", { resource: "Git Credential" });
                                this.loadUsage();
                            }}>
                                                        {this.state.usage && (<ExpandableFormSection key="usageInProjects" errorKey="usageInProjects" title="Projects" expandable={this.state.usage && this.state.usage.Projects.length > 0} summary={this.usageSummary()} help={this.usageHelp()}>
                                                                <SimpleDataTable<GitCredentialUsageProject> data={this.state.usage?.Projects} headerColumns={["Project Name", "Repository URL"]} onRow={(usageEntry) => [
                                    <InternalLink to={links.projectRootRedirect.generateUrl({ spaceId: this.props.spaceId, projectSlug: usageEntry.Slug })}>{usageEntry.Name}</InternalLink>,
                                    usageEntry.RepositoryUrl,
                                ]}/>
                                                                <Note>{this.usageAdditionalInformation(this.state.usage)}</Note>
                                                            </ExpandableFormSection>)}
                                                    </TabItem>)}
                                            </UrlNavigationTabsContainer>
                                        </TransitionAnimation>
                                    </FormContent>),
                    createSaveAction,
                    busy: this.state.busy,
                    errors: this.errors,
                    title: title,
                    overflowActions: OverflowMenuConverterVNext.convertAll(overFlowActions),
                })}
                        </>)}
                </LegacyForm>
            </>);
    }
    static displayName = "GitCredentialPageInternal";
}
function GitCredentialPopover() {
    const description = (<div className={gitCredentialPopoverStyles.helpPopoverTableContainer}>
            <div>
                <DataTable>
                    <DataTableHeader>
                        <DataTableRow className={gitCredentialPopoverStyles.helpPopoverTableHeaderRow}>
                            <DataTableHeaderColumn>Set up docs</DataTableHeaderColumn>
                            <DataTableHeaderColumn>Required privilege</DataTableHeaderColumn>
                        </DataTableRow>
                    </DataTableHeader>
                    <DataTableBody className={gitCredentialPopoverStyles.helpPopoverTableBody}>
                        <DataTableRow>
                            <DataTableRowColumn>
                                <ExternalLink label="GitHub (fine-grained)" href="gitHubPersonalAccessTokenFineGrained"></ExternalLink>
                            </DataTableRowColumn>
                            <DataTableRowColumn>
                                Permission – Contents <code>Read and Write</code>
                            </DataTableRowColumn>
                        </DataTableRow>
                        <DataTableRow>
                            <DataTableRowColumn>
                                <ExternalLink label="GitHub (classic)" href="gitHubPersonalAccessTokenClassic"></ExternalLink>
                            </DataTableRowColumn>
                            <DataTableRowColumn>
                                Scope – <code>repo</code>
                            </DataTableRowColumn>
                        </DataTableRow>
                        <DataTableRow>
                            <DataTableRowColumn>
                                <ExternalLink label="Azure DevOps" href="azureDevopsPersonalAccessToken"></ExternalLink>
                            </DataTableRowColumn>
                            <DataTableRowColumn>
                                Scope – <code>vso.code_full</code>
                            </DataTableRowColumn>
                        </DataTableRow>
                        <DataTableRow>
                            <DataTableRowColumn>
                                <ExternalLink label="GitLab" href="gitLabPersonalAccessToken"></ExternalLink>
                            </DataTableRowColumn>
                            <DataTableRowColumn>
                                Scope – <code>write_repository</code>
                            </DataTableRowColumn>
                        </DataTableRow>
                        <DataTableRow>
                            <DataTableRowColumn>
                                <ExternalLink label="BitBucket Server" href="bitBucketPersonalAccessTokenServer"></ExternalLink>
                            </DataTableRowColumn>
                            <DataTableRowColumn>
                                Permission – Project <code>admin</code>
                            </DataTableRowColumn>
                        </DataTableRow>
                        <DataTableRow>
                            <DataTableRowColumn>
                                <ExternalLink label="BitBucket Cloud" href="bitBucketPersonalAccessTokenClould"></ExternalLink>
                            </DataTableRowColumn>
                            <DataTableRowColumn>
                                Permission – Repositories <code>Read & Write</code>
                            </DataTableRowColumn>
                        </DataTableRow>
                    </DataTableBody>
                </DataTable>
            </div>
        </div>);
    return <PopoverBasicHelp placement="top-end" width="extra-wide" description={description} link1={<ExternalLink label={"Learn more"} href="GitCredentialsAuth"></ExternalLink>}/>;
}
function isCreateGitCredentialPageProps(props: CreateGitCredentialPageProps | SpecificGitCredentialPageProps): props is CreateGitCredentialPageProps {
    return "create" in props;
}
export function GitCredentialPage(props: GitCredentialPageProps) {
    const trackAction = useAnalyticTrackedActionDispatch();
    const dispatchView = useAnalyticViewDispatch();
    const { navigate } = useSpaceAwareNavigation();
    async function onSave(gitCredential: GitCredentialResource, isNew: boolean) {
        if (isNew)
            navigate(links.editGitCredentialPage.generateUrl({ spaceId: gitCredential.SpaceId, gitCredentialId: gitCredential.Id }));
    }
    async function onDelete() {
        navigate(links.gitCredentialsPage.generateUrl({ spaceId: props.spaceId }));
    }
    return (<GitCredentialPageInternal {...props} onSave={onSave} onDelete={onDelete} trackAction={trackAction} dispatchView={dispatchView}>
            {({ formContent, createSaveAction, busy, errors, title, overflowActions }) => (<PageContent header={{
                primaryAction: createSaveAction({}),
                title,
                breadcrumbs: [{ label: "Git Credentials", pageUrl: links.gitCredentialsPage.generateUrl({ spaceId: props.spaceId }) }],
                overflowActions: overflowActions.menuItems,
            }} busy={busy} errors={errors}>
                    {overflowActions.dialogs}
                    {formContent}
                </PageContent>)}
        </GitCredentialPageInternal>);
}
const gitCredentialPopoverStyles = {
    helpPopoverTableContainer: css({
        border: `1px solid ${themeTokens.color.border.primary}`,
        borderRadius: borderRadius["small"],
        marginBottom: space["16"],
    }),
    helpPopoverTableHeaderRow: css({
        "&, & th": {
            fontSize: fontSize.xSmall,
            color: themeTokens.color.text.primary,
            fontWeight: 600,
            lineHeight: lineHeight.xSmall,
            letterSpacing: letterSpacing.wide,
            paddingTop: space[8],
            paddingBottom: space[8],
        },
        "& th, & th:hover": {
            backgroundColor: themeTokens.color.background.secondary.default,
        },
        "& th:first-child": {
            borderTopLeftRadius: borderRadius["small"],
        },
        "& th:last-child": {
            borderTopRightRadius: borderRadius["small"],
        },
    }),
    helpPopoverTableBody: css({
        "& tr td": {
            paddingTop: space[6],
            paddingBottom: space[6],
            verticalAlign: "middle",
            fontSize: fontSize.small,
            color: themeTokens.color.text.primary,
            fontWeight: fontWeight[400],
            lineHeight: lineHeight.xSmall,
            letterSpacing: letterSpacing.wide,
        },
    }),
};
