/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { WorkerPoolResource, StaticWorkerPoolResource, DynamicWorkerPoolResource } from "@octopusdeploy/octopus-server-client";
import { WorkerPoolType } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { repository } from "~/clientInstance";
import DataBaseComponent from "~/components/DataBaseComponent";
import FormPage from "~/components/FormPage/FormPage";
import { PageLoading } from "~/components/PageContent/PageLoading";
import DynamicWorkerPoolEdit from "./DynamicWorkerPoolEdit";
import StaticWorkerPoolEdit from "./StaticWorkerPoolEdit";
interface WorkerPoolPageProps {
    workerPoolId: string;
}
interface WorkerPoolProps {
    initialData: InitialData;
}
interface InitialData {
    workerPool: WorkerPoolResource;
}
const Title = "Worker Pool";
const WorkerPoolFormPage = FormPage<InitialData>();
export function WorkerPoolPage({ workerPoolId }: WorkerPoolPageProps) {
    return (<WorkerPoolFormPage title={Title} load={async () => {
            const workerPoolPromise = repository.WorkerPools.get(workerPoolId);
            return {
                workerPool: await workerPoolPromise,
            };
        }} renderWhenLoaded={(initialData) => <WorkerPoolEditInternal initialData={initialData}/>} renderAlternate={() => <PageLoading loadingTitle={Title}/>}/>);
}
class WorkerPoolEditInternal extends DataBaseComponent<WorkerPoolProps, {}> {
    constructor(props: WorkerPoolProps) {
        super(props);
    }
    render() {
        switch (this.props.initialData.workerPool.WorkerPoolType) {
            case WorkerPoolType.Static:
                return <StaticWorkerPoolEdit workerPool={this.props.initialData.workerPool as StaticWorkerPoolResource}/>;
            case WorkerPoolType.Dynamic:
                return <DynamicWorkerPoolEdit workerPool={this.props.initialData.workerPool as DynamicWorkerPoolResource}/>;
        }
    }
    static displayName = "WorkerPoolEditInternal";
}
