import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import RunbooksNavigationTabs from "~/areas/projects/components/Runbooks/RunbooksNavigationTabs";
import type { PageContentProps } from "~/components/PageContent/PageContent";
import { PageContent } from "~/components/PageContent/PageContent";
type RunbookPageContentProps = Exclude<PageContentProps, "tabs"> & {
    project: ProjectResource;
    children: React.ReactNode;
};
export function RunbookPageContent({ children, project, ...rest }: RunbookPageContentProps) {
    const mergedProps = {
        ...rest,
        header: {
            breadcrumbs: [{ label: "Runbooks", pageUrl: links.projectRunbooksPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }) }],
            ...rest.header,
        },
    } as const satisfies Omit<PageContentProps, "children">;
    return (<PageContent {...mergedProps} fullWidth={true} legacyTabs={<RunbooksNavigationTabs />}>
            {children}
        </PageContent>);
}
