import { css } from "@emotion/css";
import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import type { PageAction } from "@octopusdeploy/design-system-components";
import type { GetInsightsForReportBffResponse, InsightsReportResource, InsightsTrendKey, TagSetResource } from "@octopusdeploy/octopus-server-client";
import type { InsightsCadence, InsightsMetricsSplit } from "@octopusdeploy/portal-routes";
import { links, InsightsMetricsSplitValues } from "@octopusdeploy/portal-routes";
import type { ReportFilter, SetReportFilter } from "app/areas/insights/components/Reports/ReportMetricsLayout/useReportFilters";
import type { ReactNode } from "react";
import React from "react";
import { useHistory, useLocation } from "react-router";
import type { AnalyticInsightsDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticInsightsDispatch } from "~/analytics/Analytics";
import { insightsCadenceLookup, insightsCadenceOptions } from "~/areas/insights/insightsCadence";
import { repository } from "~/clientInstance";
import type { Errors } from "~/components/DataBaseComponent";
import DataBaseComponent from "~/components/DataBaseComponent";
import OnboardingPage from "~/components/GettingStarted/OnboardingPage";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { PageContent } from "~/components/PageContent/PageContent";
import { Select } from "~/components/form";
import type { DropdownMenuOption } from "~/primitiveComponents/form/Select/DropDownMenu";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { TrendIndicator } from "../TrendIndicator/TrendIndicator";
function getSplitOptions(report: InsightsReportResource, tagSets: TagSetResource[]) {
    const options: DropdownMenuOption[] = [
        {
            value: InsightsMetricsSplitValues.None,
            text: "None",
        },
        {
            value: InsightsMetricsSplitValues.Project,
            text: "Project",
        },
        {
            value: InsightsMetricsSplitValues.ProjectGroup,
            text: "Project Group",
        },
        {
            value: InsightsMetricsSplitValues.Environment,
            text: "Environment",
        },
        {
            value: InsightsMetricsSplitValues.EnvironmentGroup,
            text: "Environment group",
        },
    ];
    if (report.TenantIds.length > 0 || report.TenantTags.length > 0) {
        options.push({
            value: InsightsMetricsSplitValues.Tenant,
            text: "Tenant",
        });
        tagSets.forEach((tagSet) => options.push({
            value: tagSet.Id,
            text: `Tag Set - ${tagSet.Name}`,
        }));
    }
    return options;
}
export interface ReportMetricsPageProps {
    report: InsightsReportResource;
    bffResponse: GetInsightsForReportBffResponse;
    split: InsightsMetricsSplit;
    cadence: InsightsCadence;
    busy: Promise<void> | undefined;
}
interface ReportMetricsLayoutProps {
    title: string;
    report: InsightsReportResource;
    children: (props: ReportMetricsPageProps) => React.ReactNode;
    trendKey?: InsightsTrendKey;
    reportFilter: ReportFilter;
    setReportFilter: SetReportFilter;
}
interface ReportMetricsDataLoaderProps extends ReportMetricsLayoutProps {
    report: InsightsReportResource;
    location: ReturnType<typeof useLocation>;
    history: ReturnType<typeof useHistory>;
    dispatchAction: AnalyticInsightsDispatcher;
    reportFilter: ReportFilterWithDefaults;
    setReportFilter: SetReportFilter;
}
interface ReportFilterWithDefaults {
    cadence: InsightsCadence;
    split: InsightsMetricsSplit;
    tenantTagSetId: string | undefined;
}
interface ReportMetricDataLoaderState {
    bffResponse: GetInsightsForReportBffResponse | null;
    tagSets: TagSetResource[];
}
interface ReportMetricLayoutProps {
    title: string;
    busy: Promise<void> | undefined;
    errors: Errors | undefined;
    children: ReactNode;
    onSplitChange: (split: InsightsMetricsSplit | string) => void;
    onCadenceChange: (cadence: InsightsCadence) => void;
    report: InsightsReportResource;
    bffResponse: GetInsightsForReportBffResponse | null;
    trendKey?: InsightsTrendKey;
    split: InsightsMetricsSplit;
    cadence: InsightsCadence;
    tagSets: TagSetResource[];
    tenantTagSetId: string | undefined;
}
class ReportMetricsDataLoader extends DataBaseComponent<ReportMetricsDataLoaderProps, ReportMetricDataLoaderState> {
    constructor(props: ReportMetricsDataLoaderProps) {
        super(props);
        this.state = {
            bffResponse: null,
            tagSets: [],
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const tagSetRequest = repository.TagSets.all();
            const cadenceDefinition = insightsCadenceLookup[this.props.reportFilter.cadence];
            const bffResponseRequest = repository.InsightsReports.bff(this.props.report, this.props.reportFilter.split, cadenceDefinition.timeRange, cadenceDefinition.granularity, this.props.reportFilter.tenantTagSetId);
            this.setState({ bffResponse: await bffResponseRequest, tagSets: await tagSetRequest });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    async onSplitChange(split: string) {
        const validatedSplitArgs = this.getValidatedSplitArgs(split);
        this.props.dispatchAction("Select Split", { action: Action.Select, inputField: "Split", split: validatedSplitArgs.split });
        this.props.setReportFilter((prev) => ({ ...prev, split: validatedSplitArgs.split, tenantTagSetId: validatedSplitArgs.tenantTagSetId }));
        await this.getReportMetrics(validatedSplitArgs.split, this.props.reportFilter.cadence, validatedSplitArgs.tenantTagSetId);
    }
    getValidatedSplitArgs(selectedOption: string) {
        // The selected option was a valid split
        if (Object.values(InsightsMetricsSplitValues).some((s) => s === selectedOption))
            return {
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                split: selectedOption as InsightsMetricsSplit,
                tenantTagSetId: undefined,
            };
        // The selected option was a tagset
        if (this.state.tagSets.some((tagSet) => tagSet.Id === selectedOption))
            return {
                split: InsightsMetricsSplitValues.TenantTagSet,
                tenantTagSetId: selectedOption,
            };
        // Default case
        return {
            split: InsightsMetricsSplitValues.None,
            tenantTagSetId: undefined,
        };
    }
    async onCadenceChange(cadence: InsightsCadence) {
        this.props.dispatchAction("Select Cadence", { action: Action.Select, inputField: "Cadence", cadence });
        this.props.setReportFilter((prev) => ({ ...prev, cadence }));
        await this.getReportMetrics(this.props.reportFilter.split, cadence, this.props.reportFilter.tenantTagSetId);
    }
    async getReportMetrics(split: InsightsMetricsSplit, cadence: InsightsCadence, tenantTagSetId?: string) {
        const { report } = this.props;
        const cadenceDefinition = insightsCadenceLookup[cadence];
        await this.doBusyTask(async () => {
            const bffResponse = await repository.InsightsReports.bff(report, split, cadenceDefinition.timeRange, cadenceDefinition.granularity, tenantTagSetId);
            this.setState({ bffResponse });
        }, { timeOperationOptions: timeOperationOptions.forRefresh() });
    }
    render() {
        const { busy, bffResponse, tagSets } = this.state;
        const { reportFilter, title, report, trendKey } = this.props;
        return (<ReportMetricsLayoutInner title={title} busy={busy} errors={this.errors} split={reportFilter.split} cadence={reportFilter.cadence} onSplitChange={(s) => this.onSplitChange(s)} onCadenceChange={(c) => this.onCadenceChange(c)} report={report} bffResponse={bffResponse} trendKey={trendKey} tagSets={tagSets} tenantTagSetId={reportFilter.tenantTagSetId}>
                {bffResponse !== null && this.props.children({ report, bffResponse, cadence: reportFilter.cadence, busy, split: reportFilter.split })}
            </ReportMetricsLayoutInner>);
    }
    static displayName = "ReportMetricsDataLoader";
}
const checkMinimumSettings = (report: InsightsReportResource) => (report.ProjectIds.length > 0 || report.ProjectGroupIds.length > 0 || report.ChannelIds.length > 0) && report.EnvironmentGroups.some((g) => g.Environments.length > 0);
function ReportMetricsLayoutInner({ title, split, cadence, busy, errors, children, onCadenceChange, onSplitChange, report, bffResponse, tagSets, tenantTagSetId, trendKey }: ReportMetricLayoutProps) {
    if (!bffResponse) {
        return (<PageContent header={{ title }} busy={busy} errors={errors}>
                {null}
            </PageContent>);
    }
    const hasMinimumSettings = checkMinimumSettings(report);
    if (!hasMinimumSettings) {
        return (<PageContent header={{ title }} busy={busy} errors={errors}>
                <SetupReportOnboardingPage report={report}/>
            </PageContent>);
    }
    const hasData = bffResponse.Series.length !== 0;
    const splitOptions = getSplitOptions(report, tagSets);
    const splitValue = split === InsightsMetricsSplitValues.TenantTagSet ? tenantTagSetId : split;
    const pageActions: PageAction[] = trendKey
        ? [
            {
                type: "custom",
                key: "Trend Indicator",
                content: <TrendIndicator trend={bffResponse[trendKey].OverallTrend} trendKey={trendKey}/>,
            },
        ]
        : [];
    return (<PageContent header={{ title, pageActions }} busy={busy} errors={errors} filters={{
            inputs: [
                <div className={filterWrapperStyles}>
                        <Select label="Split by" value={splitValue} items={splitOptions} sortItems={false} onChange={(val) => {
                        if (val)
                            onSplitChange(val);
                    }}/>
                    </div>,
                <div className={filterWrapperStyles}>
                        <Select label="Date range" value={cadence} items={insightsCadenceOptions.map((o) => o)} sortItems={false} onChange={(val) => {
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        if (val)
                            onCadenceChange(val as InsightsCadence);
                    }}/>
                    </div>,
            ],
        }}>
            {hasData ? children : <NoDataOnboardingPage />}
        </PageContent>);
}
const filterWrapperStyles = css({
    width: 250,
});
interface GoToSettingsButtonProps {
    report: InsightsReportResource;
    type: ActionButtonType;
}
function GoToSettingsButton({ report, type }: GoToSettingsButtonProps) {
    const { navigate } = useSpaceAwareNavigation();
    const onSetupReport = () => {
        navigate(links.insightsReportSettingsPage.generateUrl({ spaceId: report.SpaceId, reportId: report.Id }));
    };
    return <ActionButton label="Set up report" onClick={onSetupReport} type={type}/>;
}
function NoDataOnboardingPage() {
    return <OnboardingPage title="Deploy releases to see Insights for this report" intro="There's no deployment data for the projects and environments configured for this report." learnMore={null}/>;
}
interface SetupReportOnboardingPageProps {
    report: InsightsReportResource;
}
function SetupReportOnboardingPage({ report }: SetupReportOnboardingPageProps) {
    return <OnboardingPage title="Set up this report to see data insights" intro="Set up this report by adding projects and environments." learnMore={null} actionButtons={<GoToSettingsButton report={report} type={ActionButtonType.Primary}/>}/>;
}
export function ReportMetricsLayout({ title, report, trendKey, children, setReportFilter, reportFilter }: ReportMetricsLayoutProps) {
    const location = useLocation();
    const history = useHistory();
    const dispatchAction = useAnalyticInsightsDispatch();
    const reportFilterWithDefaults: ReportFilterWithDefaults = {
        split: reportFilter.split ?? InsightsMetricsSplitValues.None,
        cadence: reportFilter.cadence ?? "lastQuarterWeekly",
        tenantTagSetId: reportFilter.tenantTagSetId,
    };
    return (<ReportMetricsDataLoader title={title} location={location} history={history} report={report} dispatchAction={dispatchAction} trendKey={trendKey} setReportFilter={setReportFilter} reportFilter={reportFilterWithDefaults}>
            {(props) => children(props)}
        </ReportMetricsDataLoader>);
}
