import { css, cx } from "@emotion/css";
import * as React from "react";
import type { IconProps, IconSize } from "./IconTypes";
type IconWrapperProps = IconProps & {
    children: React.ReactNode;
};
export function IconWrapper({ size, color, ariaLabel, children }: IconWrapperProps) {
    const iconStyles = getIconStyles(size, color);
    const ariaAttributes = ariaLabel ? { "aria-label": ariaLabel, role: "img" } : { "aria-hidden": true };
    return (<svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" className={iconStyles} {...ariaAttributes}>
            {children}
        </svg>);
}
function getIconStyles(size: IconSize, color?: string): string {
    const colorToken = color ?? "currentcolor";
    const iconBaseStyles = css({
        fill: colorToken,
        display: "block",
    });
    switch (size) {
        case 16:
            return cx(iconBaseStyles, smallIconStyles);
        case 20:
            return cx(iconBaseStyles, mediumIconStyles);
        case 24:
            return cx(iconBaseStyles, largeIconStyles);
    }
}
const smallIconStyles = css({
    height: "16px",
    width: "16px",
});
const mediumIconStyles = css({
    height: "20px",
    width: "20px",
});
const largeIconStyles = css({
    height: "24px",
    width: "24px",
});
