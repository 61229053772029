import type { SensitiveValue, SshKeyPairPlatformHubAccountResource } from "@octopusdeploy/octopus-server-client";
import { AccountType } from "@octopusdeploy/octopus-server-client";
import SensitiveFileUpload from "app/components/form/SensitiveFileUpload/SensitiveFileUpload";
import React from "react";
import type { PlatformHubAccountEditModel } from "~/areas/blueprints/accounts/PlatformHubAccountEditBase";
import PlatformHubAccountEditBase from "~/areas/blueprints/accounts/PlatformHubAccountEditBase";
import Sensitive, { ObfuscatedPlaceholder } from "~/components/form/Sensitive/Sensitive";
import type { SummaryNode } from "~/components/form/index";
import { ExpandableFormSection, FormSectionHeading, required, Summary } from "~/components/form/index";
import Text from "~/primitiveComponents/form/Text/Text";
interface SshKeyPairPlatformHubModel extends PlatformHubAccountEditModel {
    username: string;
    passphrase: SensitiveValue;
    privateKeyFile: SensitiveValue;
}
class SshKeyPairPlatformHubAccountEdit extends PlatformHubAccountEditBase<SshKeyPairPlatformHubAccountResource, SshKeyPairPlatformHubModel> {
    getPartialModel(account?: SshKeyPairPlatformHubAccountResource) {
        return getPartialModel(account);
    }
    getPartialResource() {
        return getPartialResource(this.state.model);
    }
    getPageActions() {
        return [];
    }
    getTestDialog() {
        return null;
    }
    customExpandableFormSections() {
        return customExpandableFormSections(this.state.model, (username) => this.setModelState({ username }), (privateKeyFile) => this.setModelState({ privateKeyFile }), (passphrase) => this.setModelState({ passphrase }), this.getFieldError);
    }
}
function getPartialResource(modelState: SshKeyPairPlatformHubModel): Partial<SshKeyPairPlatformHubAccountResource> & {
    AccountType: AccountType;
} {
    return {
        AccountType: AccountType.SshKeyPair,
        Username: modelState.username,
        PrivateKeyPassphrase: modelState.passphrase,
        PrivateKeyFile: modelState.privateKeyFile,
    };
}
function getPartialModel(account?: SshKeyPairPlatformHubAccountResource): Partial<SshKeyPairPlatformHubModel> {
    if (!account) {
        return {
            username: "",
            passphrase: {
                HasValue: false,
            },
            privateKeyFile: {
                HasValue: false,
            },
        };
    }
    return {
        username: account.Username,
        passphrase: account.PrivateKeyPassphrase,
        privateKeyFile: account.PrivateKeyFile,
    };
}
function usernameSummary(modelState: SshKeyPairPlatformHubModel): SummaryNode {
    return modelState.username ? Summary.summary(modelState.username) : Summary.placeholder("No username provided");
}
function passphraseSummary(modelState: SshKeyPairPlatformHubModel): SummaryNode {
    return modelState.passphrase && modelState.passphrase.HasValue ? Summary.summary(ObfuscatedPlaceholder) : Summary.placeholder("No passphrase provided");
}
function privateKeySummary(modelState: SshKeyPairPlatformHubModel): SummaryNode {
    if (modelState.privateKeyFile && modelState.privateKeyFile.HasValue) {
        return Summary.summary("A private key has been provided");
    }
    return Summary.placeholder("No private key provided");
}
function customExpandableFormSections(modelState: SshKeyPairPlatformHubModel, onUsernameChange: (newValue: string) => void, onPrivateKeyFileChange: (newValue: SensitiveValue) => void, onPassphraseChange: (newValue: SensitiveValue) => void, getFieldError: (field: string) => string): React.ReactElement[] {
    const baseElements: React.ReactElement[] = [];
    baseElements.push(<FormSectionHeading title="Ssh Key Pair Settings" key={"header"}/>, <ExpandableFormSection key="username" errorKey="username" title="Username" focusOnExpandAll summary={usernameSummary(modelState)} help="The username to use when authenticating against the remote host." containerKey="AccountEdit">
            <Text value={modelState.username} onChange={onUsernameChange} label="Username" validate={required("Please enter an username")} error={getFieldError("username")}/>
        </ExpandableFormSection>, <ExpandableFormSection key="privatekey" errorKey="privateKeyFile" title="Private Key File" summary={privateKeySummary(modelState)} help="Upload the private key file portion of the key pair." containerKey="AccountEdit">
            <SensitiveFileUpload label="Private key file" value={modelState.privateKeyFile} onChange={onPrivateKeyFileChange} error={getFieldError("privateKeyFile")}/>
        </ExpandableFormSection>, <ExpandableFormSection key="passphrase" errorKey="passphrase" title="Passphrase" summary={passphraseSummary(modelState)} help="The passphrase for the private key, if required." containerKey="AccountEdit">
            <Sensitive value={modelState.passphrase} onChange={onPassphraseChange} label="Passphrase"/>
        </ExpandableFormSection>);
    return baseElements;
}
export default SshKeyPairPlatformHubAccountEdit;
